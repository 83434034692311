import React, { Fragment } from "react";

import axios from "axios";
import {
  ActionsDropDown, ActionsDropDownItem,
} from "isuppli-react-components";

function FileOptions({
  uploadUrl,
  downloadUrl,
  fileName,
  onUploadProgress,
  onUploadDone,
  onUploadFileSizeExceeded,
  deleteDocument,
  readOnly,
  template,
  docExpires,
  expiryDate,
}) {
  const fileInput = React.createRef();

  const fileChangeHandler = async () => {
    try {
      const formData = new FormData();
      const file = fileInput.current.files[0];

      // clear input to allow file with same name to be uploaded again
      if (fileInput.current != null) {
        fileInput.current.value = "";
      }

      const fileSize = file.size / 1024 / 1024; // convert to MB
      if (fileSize > 10) {
        onUploadFileSizeExceeded(true);
      } else {
        onUploadFileSizeExceeded(false);
        formData.append(file.name, file);
        const result = await axios.post(uploadUrl, formData, {
          onUploadProgress: (progressEvent) => {
            let progressFraction = (progressEvent.loaded / file.size);
            if (progressFraction > 1) {
              progressFraction = 1;
            }
            onUploadProgress(progressFraction);
          },
        });

        onUploadDone(result.data);
      }
    } catch (ex) {
      onUploadDone(null);
    }
  };

  const downloadTemplateHandler = () => {
    window.open(`${downloadUrl}${template}`, "_blank");
  };

  const uploadHandler = () => {
    setTimeout(() => {
      fileInput.current.dispatchEvent(new MouseEvent("click"));
    });
  };

  const viewFileHandler = () => {
    window.open(`${downloadUrl}${fileName}`, "_blank");
  };

  const deleteFileHandler = () => {
    deleteDocument();
  };

  return (
    <Fragment>
      <form className="invisible position-absolute">
        <input type="file" ref={fileInput} onChange={fileChangeHandler} />
      </form>
      <ActionsDropDown disabled={!fileName && readOnly} label="Available actions">
        {fileName && (
          <ActionsDropDownItem onClick={viewFileHandler}>View Document</ActionsDropDownItem>
        )}
        {fileName && !readOnly && (
          <ActionsDropDownItem onClick={deleteFileHandler}>Delete Document</ActionsDropDownItem>
        )}
        {!readOnly && !fileName && template
          && <ActionsDropDownItem onClick={downloadTemplateHandler}>Download Template</ActionsDropDownItem>}
        {!readOnly && !fileName
          && <ActionsDropDownItem onClick={uploadHandler} disabled={docExpires && expiryDate === ""}>Upload New Document</ActionsDropDownItem>}
      </ActionsDropDown>
    </Fragment>
  );
}

export default FileOptions;
