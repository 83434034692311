import React, { Component } from "react";
import { ModalPopup, CheckboxInput } from "isuppli-react-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import supplierStatuses from "../../Util/Enumerators/supplierStatuses";

import { duplicateCompanyIntoManualSupplier, resolveDuplicateRequest } from "../../http";
import { showErrorDialog } from "../../Store/actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  showErrorDialog: () => {
    dispatch(showErrorDialog(true));
  },
});

class DuplicateRequestModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDuplicate: 0,
    };
  }

  onInputChange = (selected, index) => {
    this.setState({
      selectedDuplicate: selected ? index : -1,
    });
  };

  getCheckboxInput = (supplierStatus, i) => (
    <CheckboxInput
      className="justify-center"
      value={supplierStatus !== supplierStatuses.potential || i === this.state.selectedDuplicate}
      onChange={(selected) => this.onInputChange(selected, i)}
    />
  );

  getDuplicatesTable = () => {
    const rows = this.props.duplicates.map((request, i) => (
      <tr key={i}>
        <td>{this.getCheckboxInput(request.supplierStatus, i)}</td>

        <td>{request.registeredName}</td>

        <td>{supplierStatuses.toString(request.supplierStatus)}</td>

        <td>{request.vatNumber}</td>

        <td>{request.registrationNumber}</td>
      </tr>
    ));
    return (
      <table className="isuppli-table">
        <thead>
          <tr>
            <th>Select</th>
            <th>Supplier Registered Name</th>
            <th>Supplier status</th>
            <th>Vat number</th>
            <th>Registration number</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  };

  render() {
    const hasPotential = this.props.duplicates.some((x) => x.supplierStatus === supplierStatuses.potential);
    return (
      <ModalPopup
        onClose={this.props.onCloseCallback}
        heading="Supplier(s) found in the directory"
        subheading={hasPotential
          ? "This company already has a registered record. Do you want to adopt this record?\nAdd checkbox selected by default"
          : "This company has already been onboarded and is an exisiting supplier of yours.\nDo you wish to create a duplicate manual supplier record?"}
        hasCancel
        buttons={[
          hasPotential
            ? {
              key: "ok",
              label: "Adopt Selected Record",
              color: this.state.selectedDuplicate > -1 ? "primary" : "disabled",
              onClick: async () => {
                // send duplicate to resolve duplicate
                if (this.state.selectedDuplicate > -1) {
                  const newRequest = {
                    ...this.props.currentRequest,
                    supplierCompanyId: this.props.duplicates[this.state.selectedDuplicate].companyId,
                  };
                  const result = await resolveDuplicateRequest(newRequest);
                  this.props.onProceed(result);
                }
              },
            } : {
              key: "duplicate",
              label: "Create Manual Duplicate",
              color: "secondary",
              onClick: async () => {
                // create dupliacte company record
                try {
                  const result = await duplicateCompanyIntoManualSupplier(this.props.currentRequest);
                  this.props.onProceed(result);
                } catch {
                  this.props.showErrorDialog();
                }
              },
            },
        ]}
      >
        {this.getDuplicatesTable()}
      </ModalPopup>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DuplicateRequestModal)
);
