import React from "react";
import { useHistory } from "react-router";
// eslint-disable-next-line no-unused-vars
import { DashboardSummaryItem } from "../DashboardSummaryItem/DashboardSummaryItem";

const DashboardSuppliersSummaryTable = ({
  title,
  columns,
  rows,
}: {
    title:string,
    columns: string[],
    rows: DashboardSummaryItem[][],
}) => {
  const history = useHistory();

  return (
    <div className="p-3 d-flex flex-column rounded">
      <div>
        <p>{title}</p>
      </div>
      <div>
        <table className="col-lg-12">
          <thead>
            <tr>
              {columns.map((x, colIndex) => <th key={colIndex}>{x}</th>)}
            </tr>
          </thead>
          <tbody>
            {rows.map((x, rowIndex) => (
              <tr key={rowIndex}>
                {x.map((y, itemIndex) => (
                  <th key={itemIndex}>
                    <a
                      href={y.linkUrl}
                      onClick={(ev) => {
                        ev.preventDefault();
                        if (y.linkUrl !== "" && y.linkUrl != null) {
                          if (y.newTab) {
                            window.open(y.linkUrl);
                          } else {
                            y.redirect === true
                              ? window.location.href = y.linkUrl
                              : history.push(y.linkUrl ?? "");
                          }
                        }
                      }}
                    >
                      <h3>
                        <span>
                          {y.value}
                        </span>
                      </h3>
                    </a>
                  </th>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DashboardSuppliersSummaryTable;
