import React from "react";
import {
  Table,
  TableConfig, // eslint-disable-line no-unused-vars
  TableData, // eslint-disable-line no-unused-vars
  ActionsDropDown,
  ActionsDropDownItem, // eslint-disable-line no-unused-vars
} from "isuppli-react-components";

import {
  UsersMessage, // eslint-disable-line no-unused-vars
  UserMessage, // eslint-disable-line no-unused-vars
} from "../../../../http/UserManagement/userManagementApi";
import useStaticCompanyData from "../../../../Hooks/useStaticCompanyData";
import { getStaticValue } from "../../../../Util/valueFormatter";
import { StaticCompanyDataTypes } from "../../../../Util/Enumerators/staticCompanyDataTypes";

import Styles from "./UserSearchResults.module.scss";
import UserStatus, { userStatusToString } from "../../../../Util/Enumerators/userStatus";
import UserSortOption, { userSortOptionToString } from "../../../../Util/Enumerators/userSortOption";

const UserSearchResults = (
  {
    searchResults,
    limit,
    offset,
    onPageChange,
    onItemSelected,
    loading,
    onDeactivate,
    onActivate,
    onDelete,
    sortOption,
    setSortOption,
    filterOption,
    setFilterOption,
  }
  :
  {
    searchResults?: UsersMessage,
    limit: number,
    offset: number,
    onPageChange: (limit: number, offset: number) => void,
    onItemSelected: (item: UserMessage) => void,
    loading: boolean,
    onDeactivate: (userId: string) => void,
    onActivate: (userId: string) => void,
    onDelete: (userId: string) => void,
    sortOption: UserSortOption,
    setSortOption: (sortOption: UserSortOption) => void,
    filterOption: UserStatus,
    setFilterOption: (status: UserStatus) => void,
  }
) => {
  const tableData: TableData<UserMessage> = {
    limit,
    offset,
    totalItems: searchResults?.totalItems ?? 0,
    items: searchResults?.items ?? [],
  };
  const [[roles], isCompanyStaticDataReady] = useStaticCompanyData(
    [StaticCompanyDataTypes.roles]
  );

  const tableConfig: TableConfig<string, UserMessage> = {
    columns: [
      {
        key: "name",
        heading: "Full Name",
        size: "auto",
        align: "left",
        valueTemplate: (row) => (
          <button
            className={`btn btn-link w-100 text-left p-0 ${Styles}`}
            type="button"
            onClick={() => {
              onItemSelected(row);
            }}
          >
            {row.fullName}
          </button>
        ),
      },
      {
        key: "email",
        heading: "Email Address",
        size: "1fr",
        propName: "email",
      },
      {
        key: "role",
        heading: "Role",
        size: "1fr",
        valueTemplate: (row) => (
          <span>
            {isCompanyStaticDataReady
              ? row.roles.map((roleId) => getStaticValue(roleId, roles)).join(", ")
              : "..." }
          </span>
        ),
      },
      {
        key: "invite",
        heading: "User Status",
        size: "1fr",
        valueTemplate: (row) => {
          let message = "Active";
          if (!row.emailConfirmed) {
            message = "Invite Pending";
          }
          if (row.isDeactivated) {
            message = "Deactivated";
          }
          return (
            <span>
              {message}
            </span>
          );
        },
      },
      {
        key: "actions",
        heading: "User Actions",
        size: "1fr",
        valueTemplate: (row) => (
          <ActionsDropDown label="Available Actions" disabled={!row.allowDeactivation}>
            {row.isDeactivated
              ? (
                <ActionsDropDownItem onClick={() => {
                  onActivate(row.id);
                }}
                >
                  Activate user
                </ActionsDropDownItem>
              )
              : (
                <ActionsDropDownItem onClick={() => {
                  onDeactivate(row.id);
                }}
                >
                  Deactivate user
                </ActionsDropDownItem>
              )}
            <ActionsDropDownItem onClick={() => {
              onDelete(row.id);
            }}
            >
              Delete user
            </ActionsDropDownItem>
          </ActionsDropDown>
        ),
      },
    ],
    sortOptions: {
      selectedSortOption: sortOption,
      options: [
        {
          value: UserSortOption.Name,
          display: userSortOptionToString(UserSortOption.Name),
        },
        {
          value: UserSortOption.Email,
          display: userSortOptionToString(UserSortOption.Email),
        },
        {
          value: UserSortOption.Date,
          display: userSortOptionToString(UserSortOption.Date),
        },
      ],
    },
    statusFilterOptions: {
      selectedStatusFilterOption: filterOption,
      options: [
        {
          display: "All",
          value: UserStatus.None,
        },
        {
          display: userStatusToString(UserStatus.Active),
          value: UserStatus.Active,
        },
        {
          display: userStatusToString(UserStatus.Deactivated),
          value: UserStatus.Deactivated,
        },
        {
          display: userStatusToString(UserStatus.InvitePending),
          value: UserStatus.InvitePending,
        },
      ],
    },
  };
  return (
    <Table<string, UserMessage>
      onPageChange={onPageChange}
      tableConfig={tableConfig}
      tableStyle="basic"
      data={tableData}
      loading={loading}
      onStatusFilterChange={(newValue: UserStatus) => {
        setFilterOption(newValue);
      }}
      onSortOptionChange={(newValue: UserSortOption) => {
        setSortOption(newValue);
      }}
    />
  );
};

export default UserSearchResults;
