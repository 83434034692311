import React from "react";
import { Badge } from "reactstrap";
import Styles from "./ChatChannelCard.module.scss";

export default function chatChannelCard({
  active = false,
  companyName,
  messageDate = "",
  messageSnippet = "",
  profilePicture,
  onClickCallback,
  className = "",
  unreadMessageCount,
}:{
        active?:boolean;
        companyName:string;
        messageDate?:string;
        messageSnippet?:string;
        profilePicture?:string;
        onClickCallback: () => void;
        className? : string ;
        unreadMessageCount?: number | null;
    }) {
  const classActive = active ? Styles.active : Styles.inactive;
  const classProfilePicture = profilePicture == null ? Styles.withoutPicture : Styles.withPicture;

  return (
    <div className={[Styles.chatChannelCardItem, classActive, "clickable", className, classProfilePicture].join(" ")} onClick={onClickCallback}>
      <div className={Styles.channelImg}>{profilePicture}</div>
      <div className={Styles.channelTextContent}>
        <div className={Styles.channelCardHeading}>
          <div className={`${Styles.companyName} flex-fill`}>{companyName}</div>
          {
            unreadMessageCount != null && unreadMessageCount > 0 && !active && (
              <Badge color="primary" className="align-self-center mr-2" pill>
                {`${unreadMessageCount} new`}
              </Badge>
            )
          }
          <div className={Styles.msgDate}>{messageDate}</div>
        </div>
        <div className={Styles.msgSnippet}>{messageSnippet}</div>
      </div>
    </div>
  );
}
