import React from "react";
import { Badge } from "reactstrap";
import Styles from "./DropDownButton.module.scss";
import { ReactComponent as SvgTriangle } from "../../images/triangle.svg";

function DropDownButton({
  styleVal,
  label,
  iconSvg,
  onClick,
  up,
  className = "",
  labelClassName = "",
  badge,
  badgePlacement = "inside",
  disabled = false,
}: {
  styleVal?: "dark" | "light" | "default",
  label: string,
  iconSvg?: SvgrComponent,
  onClick?: () => void,
  up: boolean,
  className?: string | undefined,
  labelClassName?: string | undefined,
  badge: string | undefined,
  badgePlacement?: "inside" | "outside" | undefined,
  disabled?: boolean,
}) {
  let styleClass = "";
  switch (styleVal) {
    case "dark":
      styleClass = Styles.DropdownButtonDark;
      break;
    case "light":
      styleClass = Styles.DropdownButtonLight;
      break;
    default:
      styleClass = Styles.DropdownButtonDefault;
      break;
  }
  const useDarkTriangle = styleVal === "dark";
  const imgClass = up ? `${Styles.FlipVertical}` : "";
  let badgePlacementClass: string;
  switch (badgePlacement) {
    case "inside":
      badgePlacementClass = Styles.BadgeInside;
      break;
    case "outside":
      badgePlacementClass = Styles.BadgeOutside;
      break;
    default:
      badgePlacementClass = "";
  }

  const IconSvg = iconSvg;
  return (
    <button
      disabled={disabled}
      type="button"
      onClick={onClick}
      className={`btn btn-link ${Styles.DropdownButton} ${styleClass} align-items-center ${className}`}
    >
      {!!IconSvg && (
        <IconSvg
          className={`${Styles.DropdownButtonIconImage} mr-2`}
        />
      )}
      <span className={`${labelClassName} ${Styles.DropdownButtonLabel}`}>{label}</span>
      {
        badge && <Badge color="info" pill className={`${badgePlacementClass} ml-3`}>{badge}</Badge>
      }
      <SvgTriangle
        className={`${Styles.DropdownButtonTriangle} ${imgClass} ml-2 ${
          useDarkTriangle
            ? "text-dark"
            : "text-action"
        }`}
      />
    </button>
  );
}

export default DropDownButton;
