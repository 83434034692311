import React, { Component, Fragment } from "react";
import {
  ConfirmationModal, ModalPopup, BasicTable, SystemFeatures,
} from "isuppli-react-components";

import { connect } from "react-redux";
import { clearApiErrors, showErrorDialog } from "../Store/actions";

const mapStateToProps = (state) => ({
  showError: state.showError,
  apiErrors: state.apiErrors,
});

const mapDispatchToProps = (dispatch) => ({
  hideError: () => {
    dispatch(showErrorDialog(false));
  },
  clearApiErrors: () => {
    dispatch(clearApiErrors());
  },
});

class AppErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  closeErrorModal() {
    this.props.hideError();
    this.setState({
      hasError: false,
    });
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.error({ error, errorInfo });
  }

  render() {
    const errorModalHeader = this.props.apiErrors.errors.length > 1 ? "Something(s) went wrong" : "Something went wrong";
    const errorModal = (this.props.apiErrors.errors.length > 0
      ? (
        <div className="px-2 mx-2">
          <ModalPopup
            size="extra-large"
            heading={errorModalHeader}
            subheading="An error occurred. Please try again or contact your system administrator."
            image="error"
            hasCancel={false}
            buttons={[{
              key: "ok",
              label: "OK",
              onClick: () => {
                this.closeErrorModal();
                this.props.clearApiErrors();
              },
              color: "primary",
            }]}
          >
            <BasicTable
              viewSubRowFeature={SystemFeatures.None}
              emptyMessage="No errors available"
              columns={[
                "Status Code",
                "Error",
                "Message",
                "SubSystem",
              ]}
              rows={this.props.apiErrors?.errors?.map((error) => [
                {
                  key: `${error.traceId}_code`,
                  valueTemplate: () => (
                    <span
                      className="font-weight-bold text-danger"
                    >
                      {error.status}
                    </span>
                  ),
                },
                {
                  key: `${error.traceId}_error`,
                  valueTemplate: () => (
                    <span
                      className="font-weight-bold text-danger"
                    >
                      {error.title}
                    </span>
                  ),
                },
                {
                  key: `${error.traceId}_message`,

                  valueTemplate: () => (
                    <span
                      className="font-weight-bold text-danger"
                    >
                      {error.additionalDetails?.message?.toString()}
                    </span>
                  ),
                },
                {
                  key: `${error.traceId}_subSystem`,
                  value: error.additionalDetails?.subSystem,
                },
              ])}
              subRows={
                this.props.apiErrors?.errors?.map((error) => (
                  <div>
                    <p>
                      <b>Trace Id: </b>
                      {error.traceId}
                    </p>
                    <br />
                    <p>
                      <b>URL: </b>
                      {error.url}
                    </p>
                    <br />
                    <p>
                      <b>StackTrace:</b>
                      {error.additionalDetails?.stackStrace}
                    </p>
                  </div>
                ))
              }
            />
          </ModalPopup>
        </div>

      )
      : (
        <ConfirmationModal
          heading="Something went wrong"
          onProceedClick={() => this.closeErrorModal()}
          onToggleModal={() => this.closeErrorModal()}
          proceedButtonText="OK"
          hasCancel={false}
          description="An error occurred. Please try again or contact your system administrator."
        />
      )
    );

    return (
      <Fragment>
        {this.props.children}
        {this.state.hasError || this.props.showError ? (
          errorModal
        ) : null}
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppErrorBoundary);
