import {
  Line, ShadowCard, Spinner, UtilButton,
} from "isuppli-react-components";
import React, { Fragment, useEffect, useState } from "react";
import useShowError from "../../../Hooks/useShowError";
import { getBulletinBoardSummary } from "../../../http/Dashboard/dashboardApi";
// eslint-disable-next-line no-unused-vars
import { DashboardBulletinBoardSummary } from "../../../http/Dashboard/Models/Models";
// eslint-disable-next-line no-unused-vars
import DashboardSummaryCard, { DashboardSummaryItem } from "../DashboardSummaryItem/DashboardSummaryItem";

enum BulletinFilterField {
  Active = 0, // eslint-disable-line no-unused-vars
  All = 1, // eslint-disable-line no-unused-vars
  UserBids = 2, // eslint-disable-line no-unused-vars
  NotParticipating = 3, // eslint-disable-line no-unused-vars
  Participating = 4, // eslint-disable-line no-unused-vars
  QuoteRequired = 5, // eslint-disable-line no-unused-vars
  QuoteSubmitted = 6, // eslint-disable-line no-unused-vars
  DecisionPending = 7, // eslint-disable-line no-unused-vars
  Awarded = 8, // eslint-disable-line no-unused-vars
  Denied = 9, // eslint-disable-line no-unused-vars
  Closed = 10, // eslint-disable-line no-unused-vars
}

const BulletinBoardSummaryCard = () => {
  const [bulletinBoardData, setBulletinBoardData] = useState<
  DashboardBulletinBoardSummary>();
  const [loading, setLoading] = useState(false);
  const showError = useShowError();

  useEffect(() => {
    const loader = async () => {
      try {
        setLoading(true);
        const data = await getBulletinBoardSummary();
        setBulletinBoardData(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showError();
      }
    };
    loader();
  }, [showError]);

  const rfxUrl = `${window.location.origin}/redirect/bulletinboard`;
  const bulletinBoardSummary = [
    {
      key: "total",
      value: bulletinBoardData?.total,
    },
    {
      key: "active",
      title: "ACTIVE",
      value: bulletinBoardData?.new,
      linkUrl: `${rfxUrl}?opportunityFilter=${BulletinFilterField.Active}`,
      newTab: true,
    },
    {
      key: "closed",
      title: "CLOSED",
      value: bulletinBoardData?.old,
      linkUrl: `${rfxUrl}?opportunityFilter=${BulletinFilterField.Closed}`,
      newTab: true,
    },
    {
      key: "awarded",
      title: "AWARDED",
      value: bulletinBoardData?.won,
      linkUrl: `${rfxUrl}?opportunityFilter=${BulletinFilterField.Awarded}`,
      newTab: true,
    },
    {
      key: "denied",
      title: "DENIED",
      value: bulletinBoardData?.lost,
      linkUrl: `${rfxUrl}?opportunityFilter=${BulletinFilterField.Denied}`,
      newTab: true,
    },
    {
      key: "decisionPending",
      title: "DECISION PENDING",
      value: bulletinBoardData?.pending,
      linkUrl: `${rfxUrl}?opportunityFilter=${BulletinFilterField.DecisionPending}`,
      newTab: true,
    },
  ] as DashboardSummaryItem[];

  const handleViewMoreClick = () => {
    window.location.href = rfxUrl;
  };

  return (
    <Fragment>
      <div className="row mt-4">
        <div className="col-12">
          <h2>BULLETIN BOARD</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <ShadowCard>
            {loading
              ? (<Fragment><Spinner inline /></Fragment>)
              : (
                <DashboardSummaryCard
                  heading="Total Opportunites"
                  className="col-lg-12"
                  summaryItems={bulletinBoardSummary}
                />
              )}

            <Line color="primary" />
            <div className="d-flex justify-content-center pb-4">
              <UtilButton
                location="bottom"
                onClick={handleViewMoreClick}
              >
                VIEW MORE
              </UtilButton>
            </div>
          </ShadowCard>
        </div>
      </div>
    </Fragment>
  );
};

export default BulletinBoardSummaryCard;
