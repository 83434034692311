import React, { Fragment } from "react";
import { ReactComponent as CheckMarkSvg } from "../../Images/check-mark.svg";

function SupplierCheckStatus() {
  return (
    <Fragment>
      <CheckMarkSvg className="image-drop-shadow supplier-check-status text-primary" />
      Supplier Checks
    </Fragment>
  );
}

export default SupplierCheckStatus;
