import axios from "axios";
import { makeRequest, makeRequestAsync } from "./util";

export function saveSupplierInfo(supplierInfo, onSuccess, onError) {
  makeRequest(() => axios.post("/api/registration/supplierinfo", supplierInfo), onSuccess, onError);
}

export function saveSupplierInfoAsync(supplierInfo) {
  return makeRequestAsync(() => axios.post("/api/registration/supplierinfo", supplierInfo));
}

export function getSupplierInfoMatches(uniqueCompanyIdentifier, onSuccess, onError) {
  makeRequest(() => axios.post("/api/registration/supplierinfomatches", uniqueCompanyIdentifier), onSuccess, onError);
}

export function saveProductServiceOffering(productServiceOfferings, onSuccess, onError) {
  makeRequest(() => axios.post("/api/registration/offerings", productServiceOfferings), onSuccess, onError);
}

export async function saveBankingDetails(bankDetails, onSuccess, onError) {
  makeRequest(() => axios.post("/api/registration/bankdetails", bankDetails), onSuccess, onError);
}

export async function saveBankingDetailsAsync(bankDetails) {
  return makeRequestAsync(() => axios.post("/api/registration/bankdetails", bankDetails));
}

export function saveAddressInfo(addressInfo, onSuccess, onError) {
  makeRequest(() => axios.post("/api/registration/addressinfo", addressInfo), onSuccess, onError);
}

export function saveKeyStaffContactDetails(contactDetails, onSuccess, onError) {
  makeRequest(() => axios.post("/api/registration/keycontactstaff", contactDetails), onSuccess, onError);
}

export function saveBeeCertificationInfo(beeCertificationInfo, onSuccess, onError) {
  makeRequest(() => axios.post("/api/registration/beeratings", beeCertificationInfo), onSuccess, onError);
}

export function saveDocumentsInfo(documents, onSuccess, onError) {
  makeRequest(() => axios.post("/api/registration/documents", documents), onSuccess, onError);
}

export function saveCurrentSubscription(pt, onSuccess, onError) {
  makeRequest(() => axios.post("/api/registration/producttier", pt), onSuccess, onError);
}

export function saveTermsAndConditions(tcs, onSuccess, onError) {
  makeRequest(() => axios.post("/api/registration/termsconditions", tcs), onSuccess, onError);
}

export function saveCommunicationPreference(receiveMarketingInfo, onSuccess, onError) {
  return makeRequestAsync(() => axios.post(`/api/user/communicationpreferences?receiveMarketingInfo=${receiveMarketingInfo}`), onSuccess, onError);
}

export async function sendCompanyDuplicateEmail(contactInfo) {
  return makeRequestAsync(() => axios.post("/api/registration/sendcompanyduplicateemail", contactInfo));
}

export async function sendSubscriptionEnquirySupportEmail(selectedTierId) {
  makeRequestAsync(() => axios.post(`/api/registration/subscriptionenquiry/${selectedTierId}`));
}

export async function getOnboardingRequests(searchCriteria) {
  return makeRequestAsync(() => axios.post("/api/motivation/onboardingrequests/search", searchCriteria));
}

export async function saveOnboardingRequest(onboardingRequest, saveOnly = false) {
  return makeRequestAsync(() => axios.post(`/api/motivation/onboardingrequests?saveOnly=${saveOnly}`, onboardingRequest));
}

export async function submitOnboardingRequest(onboardingRequest) {
  return makeRequestAsync(() => axios.post("/api/motivation/submitonboarding", onboardingRequest));
}

export async function addNewSupplierOnboarding(newSupplier) {
  return makeRequestAsync(() => axios.post("/api/motivation/submitsupplieronboarding", newSupplier));
}

export async function createOnboardingRequestsForSuppliers(supplierIds) {
  return makeRequestAsync(() => axios.post("/api/motivation/new", supplierIds));
}

export async function sendCompanySearch(searchObject) {
  return makeRequestAsync(() => axios.post("/api/search/companies", searchObject));
}

export async function sendLargeCompanySearch(searchObject) {
  return makeRequestAsync(() => axios.post("/api/search/companies/largevolume", searchObject));
}

export async function submitSupplierExportRequest() {
  return makeRequestAsync(() => axios.post("/api/reporting/supplierExport/submitSupplierExportRequest"));
}

export async function sendComplianceCheckSearch(searchObject) {
  return makeRequestAsync(() => axios.post("/api/search/complianceChecks", searchObject));
}

export async function sendMyClientsSearch(searchObject) {
  return makeRequestAsync(() => axios.post("/api/search/supplierClients", searchObject));
}

export async function resolveDuplicateRequest(onboardingRequest) {
  return makeRequestAsync(() => axios.post("/api/motivation/duplicates/resolved", onboardingRequest));
}

export async function addBusinessUnitsForExistingSupplier(addBusinessUnitMessage) {
  return makeRequestAsync(() => axios.post("/api/motivation/addbusinessunits", addBusinessUnitMessage));
}

export async function addSubAccount(subAccountMessage) {
  return makeRequestAsync(() => axios.post("/api/registration/subaccount/new", subAccountMessage));
}

export async function editSubAccount(id, subAccountMessage) {
  return makeRequestAsync(() => axios.post(`/api/registration/subaccount/${id}`, subAccountMessage));
}

export async function duplicateCompanyIntoManualSupplier(onboardingRequest) {
  return makeRequestAsync(() => axios.post("/api/motivation/duplicate", onboardingRequest));
}

export async function addComment(comment) {
  return makeRequestAsync(() => axios.post("/api/motivation/addComment", comment));
}
