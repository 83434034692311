import React, { Component, Fragment } from "react";

import axios from "axios";

class FileUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasUploaded: false,
      fileName: "",
    };
  }

  onInputChange = (ev) => {
    const formData = new FormData();
    const file = ev.target.files[0];
    formData.append(file.name, file);
    axios
      .post(this.props.uploadUrl, formData, {
        onUploadProgress: (progEv) => {
          const progress = (() => {
            const val = Math.round((progEv.loaded / file.size) * 100);
            return val <= 100 ? val : 100;
          })();
          this.props.onFileUploadingCallback(this.props.index, progress);
        },
      })
      .then((res) => {
        this.setState({
          hasUploaded: true,
          fileName: file.name,
        });
        this.props.onFileUploadedCallback(this.props.doc, res, null);
      })
      .catch((err) => {
        this.props.onFileUploadedCallback(null, err);
      });
  };

  render() {
    return (
      <section>
        <form onSubmit={this.onSubmit}>
          <input
            className="file-input"
            type="file"
            id={`file-${this.props.index}`}
            name={`file-${this.props.index}`}
            onChange={this.onInputChange}
          />
          <label
            htmlFor={`file-${this.props.index}`}
            className={this.props.labelClass ? this.props.labelClass : ""}
          >
            {this.state.hasUploaded && <Fragment>{this.state.fileName}</Fragment>}
            {!this.state.hasUploaded && <Fragment>{this.props.labelText}</Fragment>}
          </label>
        </form>
      </section>
    );
  }
}

export default FileUploader;
