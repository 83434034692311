export const SHOW_LOADING_SCREEN = "SHOW_LOADING_SCREEN";

export const HIDE_LOADING_SCREEN = "HIDE_LOADING_SCREEN";

export const ON_STAFF_CONTACT_INPUT_CHANGE = "ON_STAFF_CONTACT_INPUT_CHANGE";

export const ON_ADDRESS_INFO_CHANGE = "ON_ADDRESS_INFO_CHENGE";

export const SHOW_INFO_POPUP = "SHOW_INFO_POPUP";

export const HIDE_INFO_POPUP = "HIDE_INFO_POPUP";

export const SET_STORE_PROPERTY = "SET_STORE_PROPERTY";

export const SET_LAST_STEP_COMPLETED = "SET_LAST_STEP_COMPLETED";

export const LOG_OUT = "LOG_OUT";

export const SET_USER_BASIC_DETAILS = "SET_USER_BASIC_DETAILS";

export const SET_COMPANY_CONFIGURATION = "SET_COMPANY_CONFIGURATION";

export const SET_COMPANY_OUTSTANDING_INFO = "SET_COMPANY_OUTSTANDING_INFO";

export const SET_DATA_FEATURES = "SET_DATA_FEATURES";

export const SET_SUBSCRIPTIONS_ALLOWED_FEATURES = "SET_SUBSCRIPTIONS_ALLOWED_FEATURES";

export const SET_BUYER_COMPANY_ID = "SET_BUYER_COMPANY_ID";

export const SHOW_ERROR = "SHOW_ERROR";

export const CLEAR_API_ERRORS = "CLEAR_API_ERRORS";

export const SET_STATIC_COMPANY_DATA = "SET_STATIC_COMPANY_DATA";

export const SET_STATIC_DATA = "SET_STATIC_DATA";

export const SET_SUBSCRIPTION_INFO = "SET_SUBSCRIPTION_INFO";

export const SET_NOT_AUTHENTICATED = "SET_NOT_AUTHENTICATED";

export const SET_FORCE_LOGOUT = "SET_FORCE_LOGOUT";

export const SET_TOKEN_EXPIRATION = "SET_TOKEN_EXPIRATION";

export const ADD_API_ERROR = "ADD_API_ERROR";

export function showLoadingScreen(loadingScreenMsg) {
  return {
    type: SHOW_LOADING_SCREEN,
    payload: {
      loadingScreenMsg,
    },
  };
}

export function hideLoadingScreen() {
  return {
    type: HIDE_LOADING_SCREEN,
  };
}

export function onStaffContactInfoChange(newEntry) {
  return {
    type: ON_STAFF_CONTACT_INPUT_CHANGE,
    payload: {
      newEntry,
    },
  };
}

export function onAddressInfoChange(category, ev) {
  return {
    type: ON_ADDRESS_INFO_CHANGE,
    payload: {
      category,
      name: ev.target.name,
      value: ev.target.value,
    },
  };
}

export function setStoreProperty(propName, value) {
  return {
    type: SET_STORE_PROPERTY,
    payload: {
      propName,
      value,
    },
  };
}

export function showInfoPopup(heading, subheading, msgs) {
  return {
    type: SHOW_INFO_POPUP,
    payload: {
      heading,
      subheading,
      msgs,
    },
  };
}

export function hideInfoPopup() {
  return {
    type: HIDE_INFO_POPUP,
  };
}

export function setLastStepCompleted(value) {
  return {
    type: SET_LAST_STEP_COMPLETED,
    payload: {
      value,
    },
  };
}

export function setUserBasicDetails(userBasicDetails) {
  return {
    type: SET_USER_BASIC_DETAILS,
    payload: userBasicDetails,
  };
}

export function setCompanyConfiguration(companyConfiguration) {
  return {
    type: SET_COMPANY_CONFIGURATION,
    payload: companyConfiguration,
  };
}

export function setCompanyOutstandingInfo(companyOutstandingInfo) {
  return {
    type: SET_COMPANY_OUTSTANDING_INFO,
    payload: companyOutstandingInfo,
  };
}

export function setDataFeatures(dataFeatures) {
  return {
    type: SET_DATA_FEATURES,
    payload: dataFeatures,
  };
}

export function setSubscriptionAllowedFeatures(subscriptionsAllowedFeatures) {
  return {
    type: SET_SUBSCRIPTIONS_ALLOWED_FEATURES,
    payload: subscriptionsAllowedFeatures,
  };
}

export function logOut() {
  return {
    type: LOG_OUT,
  };
}

export function showErrorDialog(value) {
  return {
    type: SHOW_ERROR,
    payload: value,
  };
}

export function clearApiErrors() {
  return {
    type: CLEAR_API_ERRORS,
  };
}

export function setStaticCompanyData(optionsToSet) {
  return {
    type: SET_STATIC_COMPANY_DATA,
    payload: optionsToSet,
  };
}

export function setStaticData(optionsToSet) {
  return {
    type: SET_STATIC_DATA,
    payload: optionsToSet,
  };
}

export function setSubscriptionInfo(payload) {
  return {
    type: SET_SUBSCRIPTION_INFO,
    payload,
  };
}

export function setNotAuthenticated(retryHandler) {
  return {
    type: SET_NOT_AUTHENTICATED,
    payload: {
      isAuthenticated: false,
      retryHandler,
    },
  };
}

export function forceLogout() {
  return {
    type: SET_FORCE_LOGOUT,
    payload: true,
  };
}

export function clearNotAuthenticated() {
  return {
    type: SET_NOT_AUTHENTICATED,
    payload: {
      isAuthenticated: true,
    },
  };
}

export function setTokenDates(createdAt, expiresAt) {
  return {
    type: SET_TOKEN_EXPIRATION,
    payload: {
      createdAt,
      expiresAt,
    },
  };
}

export function addApiError(apiError) {
  return {
    type: ADD_API_ERROR,
    payload: {
      apiError,
    },
  };
}
