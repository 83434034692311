import axios from "axios";

import {
  DashboardSupplierSummaryMessage, // eslint-disable-line no-unused-vars
  DashboardBeeClassificationSummary, // eslint-disable-line no-unused-vars
  DashboardRegulatoryComplianceSummaryMessage, // eslint-disable-line no-unused-vars
  DashboardBulletinBoardSummary, // eslint-disable-line no-unused-vars
  DashboardReportSummary, // eslint-disable-line no-unused-vars
  DashboardSupplierStatusSummary, // eslint-disable-line no-unused-vars
} from "./Models/Models";
import { makeRequestAsync } from "../util";

export async function getDashboardSuppliersSummary() {
  const url = "api/dashboard/suppliers";
  return makeRequestAsync(() => axios
    .get(url)) as Promise<DashboardSupplierSummaryMessage>;
}

export async function getDashboardBeeSummary() {
  const url = "api/dashboard/bee";
  return makeRequestAsync(() => axios
    .get(url)) as Promise<DashboardBeeClassificationSummary[]>;
}

export async function getDashboardComplianceSummary() {
  const url = "api/dashboard/compliance";
  return makeRequestAsync(() => axios
    .get(url)) as Promise<DashboardRegulatoryComplianceSummaryMessage>;
}

export async function getDashboardSupplierTypesSummary() {
  const url = "api/dashboard/suppliertypes";
  return makeRequestAsync(() => axios
    .get(url)) as Promise<DashboardReportSummary>;
}

export async function getBulletinBoardSummary() {
  const url = "api/opportunities/dashboard/bulletinBoardSummary";
  return makeRequestAsync(() => axios
    .get(url)) as Promise<DashboardBulletinBoardSummary>;
}

export async function getDashboardSupportingDocumentsSummary() {
  const url = "api/dashboard/documents";
  return makeRequestAsync(() => axios
    .get(url)) as Promise<DashboardReportSummary>;
}

export async function getDashboardSupplierPackagesSummary() {
  const url = "api/dashboard/packages";
  return makeRequestAsync(() => axios
    .get(url)) as Promise<DashboardReportSummary>;
}

export async function getDashboardSupplierStatusSummary() {
  const url = "api/dashboard/status";
  return makeRequestAsync(() => axios
    .get(url)) as Promise<DashboardSupplierStatusSummary>;
}

export async function getDashboardOnboardingStatusSummary() {
  const url = "api/dashboard/onboarding";
  return makeRequestAsync(() => axios
    .get(url)) as Promise<DashboardSupplierStatusSummary>;
}
