// eslint-disable-next-line no-unused-vars
import { UnspscSegment, UnspscFamily } from "isuppli-react-components";
// eslint-disable-next-line no-unused-vars
import { StaticOption } from "../Hooks/useStaticCompanyData";

export interface UnspscSegmentWithFamilies extends UnspscSegment {
  families: Array<UnspscFamily>,
}

export const createProductServiceSummary = (
  selectedSubCategoryIds: number[],
  categoriesStaticData: StaticOption[]
) => {
  if (!selectedSubCategoryIds) {
    return [];
  }

  const categories = categoriesStaticData.map((category) => ({
    heading: category.name,
    items: category.supplierSubCategories
      .filter(
        (subCategory: StaticOption) => selectedSubCategoryIds.includes(subCategory.value as number)
      )
      .map((c: StaticOption) => c.name),
  }));

  return categories.filter((c) => c.items.length > 0);
};

export const createGlobalProductServiceSummary = (
  selectedUnspscFamilyIds: number[],
  segmentsStaticData: StaticOption[]
) => {
  if (!selectedUnspscFamilyIds) {
    return [];
  }

  const segments = segmentsStaticData.map((segment) => ({
    heading: segment.display,
    items: segment.families
      .filter(
        (family: StaticOption) => selectedUnspscFamilyIds.includes(family.value as number)
      )
      .map((c: StaticOption) => c.name),
  }));

  return segments.filter((c) => c.items.length > 0);
};

export const getUnspscInfo = (
  unspscSegments: Array<UnspscSegment>,
  unspscFamilies: Array<UnspscFamily>
) => {
  // build segments array
  const segments = unspscSegments?.map((unspscSegment: any) => ({
    value: unspscSegment.id,
    display: unspscSegment.display,
    code: unspscSegment.code,
    families: [],
  } as UnspscSegmentWithFamilies));

  // assign families to appropriate segment
  unspscFamilies?.map((unspscFamily: any) => {
    segments.find((segment) => segment.value === unspscFamily.segmentId)
      ?.families.push(unspscFamily);
  });

  return segments;
};
