import React, { Fragment, useState, useEffect } from "react";
import {
  BasicTable,
  ActionsDropDown,
  ActionsDropDownItem,
  WithFeature, SystemFeatures,
  UtilButton, useToast, Spinner,
  selectedFlagEnumToString,
  DataFeatures,
  DataFeaturesDisplay,
  useSystemFeatureCheck,
} from "isuppli-react-components";
import { deleteConfigurationSuppilerType, getConfigurationSupplierTypes } from "../../../http/SelfConfiguration/SupplierTypesConfigApi";
// eslint-disable-next-line no-unused-vars
import { ConfigurationSupplierTypeMessage } from "../../../http/SelfConfiguration/ConfigurationSupplierTypeMessage";
import useShowError from "../../../Hooks/useShowError";
import AddOrEditSupplierTypeModal from "./AddOrEditSupplierTypeModal";
import OnboardingApproverLevels, { OnboardingApproverLevelsDisplay } from "../../../Util/Enumerators/onboardingApproverLevel";

const SupplierTypeConfig = (
  {
    isBankingGloballyRequired,
  }
    :
    {
      isBankingGloballyRequired: boolean
    }
) => {
  const [configSupplierTypes, setConfigSupplierTypes] = useState<Array<ConfigurationSupplierTypeMessage>>([]);
  const showError = useShowError();
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [showAddSupplierTypeModal, setShowAddSupplierTypeModal] = useState(false);
  const [editSupplierTypeId, setEditSupplierTypeId] = useState<number | undefined>(undefined);
  const hasFeature = useSystemFeatureCheck();
  const showToast = useToast();

  const editSupplierType = (supplierTypeId: number) => {
    setEditSupplierTypeId(supplierTypeId);
    setShowAddSupplierTypeModal(true);
  };

  useEffect(() => {
    setLoadingTable(true);
    const loader = async () => {
      try {
        const supplierTypesConfig = await getConfigurationSupplierTypes();
        setConfigSupplierTypes(supplierTypesConfig);
        setLoadingTable(false);
      } catch {
        setLoadingTable(false);
        showError();
      }
    };
    loader();
  }, [showError]);

  const addNewSupplierType = () => {
    setShowAddSupplierTypeModal(true);
  };

  const handleCloseSupplierTypeModal = () => {
    setShowAddSupplierTypeModal(false);
    setEditSupplierTypeId(undefined);
  };

  const handleSubmitSupplierType = (submitedSupplierTypeData: ConfigurationSupplierTypeMessage) => {
    const exisitingRecord = configSupplierTypes.find((x) => x.id === submitedSupplierTypeData.id);
    let toastMessage = "";
    if (exisitingRecord == null) {
      toastMessage = "New supplier type configuration added successfully";
      setConfigSupplierTypes((prevState) => [...prevState, submitedSupplierTypeData]);
    } else {
      toastMessage = "Supplier type configuration updated successfully";
      setConfigSupplierTypes((prevState) => prevState.map((st) => (st.id === exisitingRecord.id
        ? submitedSupplierTypeData
        : st)));
    }
    setShowAddSupplierTypeModal(false);
    showToast(toastMessage);
    setEditSupplierTypeId(undefined);
  };

  const handleDeleteSupplierType = async (supplierTypeId: number) => {
    setLoading(true);
    try {
      await deleteConfigurationSuppilerType(supplierTypeId);
      showToast("Supplier type configuration deleted successfully!");
      setConfigSupplierTypes((prevState) => prevState.filter((st) => st.id !== supplierTypeId));
      setLoading(false);
    } catch {
      showError();
      setLoading(false);
    }
  };

  return (
    <WithFeature feature={SystemFeatures.ConfigurationOnboardingSupplierTypes} keepVisible>
      <Fragment>
        {
          loading && <Spinner />
        }
        {
          showAddSupplierTypeModal && (
            <AddOrEditSupplierTypeModal
              supplierTypeId={editSupplierTypeId}
              onCloseCallback={handleCloseSupplierTypeModal}
              onSubmitCallback={handleSubmitSupplierType}
              isBankingGloballyRequired={isBankingGloballyRequired}
            />
          )
        }
        <h3>Configure My Supplier Types</h3>
        <BasicTable
          emptyMessage="Supplier types not yet configured"
          loading={loadingTable}
          columns={[{
            key: "supplierTypeName",
            text: "Supplier Type Name",
            align: "left",
            size: "1fr",
          },
          {
            key: "approvalLevels",
            align: "left",
            text: "Onboarding Approvals",
            size: "1fr",
          },
          {
            key: "additionalSupplierData",
            align: "left",
            text: "Additional Supplier Data",
            size: "1fr",
          },
          {
            key: "Actions",
            text: "Actions",
            size: "auto",
            align: "center",
          }]}
          rows={configSupplierTypes.map((st) => [
            {
              key: `Name_${st.id}`,
              value: st.name,
            },
            {
              key: `MTOApproverLevels_${st.id}`,
              value: selectedFlagEnumToString({
                value: st.onboardingApproverLevels,
                enumToUse: OnboardingApproverLevels,
                defaultEnumValueForEmtpySelection: OnboardingApproverLevels.None,
                enumDisplayValues: OnboardingApproverLevelsDisplay,
              }),
            },
            {
              key: `AdditionalSupplierData_${st.id}`,
              value: selectedFlagEnumToString({
                value: st.dataFeatures,
                enumToUse: DataFeatures,
                defaultEnumValueForEmtpySelection: DataFeatures.None,
                enumDisplayValues: DataFeaturesDisplay,
              }),
            },
            {
              key: `actions_${st.id}`,
              valueTemplate: () => (
                <ActionsDropDown label="Actions">
                  <ActionsDropDownItem
                    disabled={!hasFeature(SystemFeatures.ConfigurationOnboardingSupplierTypes)}
                    onClick={() => editSupplierType(st.id)}
                  >
                    Edit
                  </ActionsDropDownItem>
                  <ActionsDropDownItem
                    disabled={!hasFeature(SystemFeatures.ConfigurationOnboardingSupplierTypes)}
                    onClick={() => handleDeleteSupplierType(st.id)}
                  >
                    Delete
                  </ActionsDropDownItem>
                </ActionsDropDown>
              ),
            },
          ])}
        />
        <UtilButton
          className="my-4"
          color="primary"
          disabled={!hasFeature(SystemFeatures.ConfigurationOnboardingSupplierTypes)}
          onClick={addNewSupplierType}
        >
          ADD NEW SUPPLIER TYPE
        </UtilButton>
      </Fragment>
    </WithFeature>
  );
};

export default SupplierTypeConfig;
