import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import {
  ON_STAFF_CONTACT_INPUT_CHANGE,
  ON_ADDRESS_INFO_CHANGE,
  SET_STORE_PROPERTY,
  SHOW_LOADING_SCREEN,
  HIDE_LOADING_SCREEN,
  LOG_OUT,
  SHOW_INFO_POPUP,
  HIDE_INFO_POPUP,
  SET_LAST_STEP_COMPLETED,
  SET_USER_BASIC_DETAILS,
  SET_COMPANY_CONFIGURATION,
  SET_COMPANY_OUTSTANDING_INFO,
  SHOW_ERROR,
  SET_STATIC_COMPANY_DATA,
  SET_STATIC_DATA,
  SET_SUBSCRIPTION_INFO,
  SET_NOT_AUTHENTICATED,
  SET_TOKEN_EXPIRATION,
  SET_FORCE_LOGOUT,
  SET_DATA_FEATURES,
  SET_SUBSCRIPTIONS_ALLOWED_FEATURES,
  ADD_API_ERROR,
  CLEAR_API_ERRORS,
} from "./actions";
import {
  SET_REFERENCES,
  SET_REGISTRATION_INFO,
  SET_SUPPLIER_CATEGORIES,
  SET_UNSPSC_FAMILIES,
  SET_POSTAL_ADDRESS,
  ON_REGISTRATION_INPUT_CHANGE,
  SET_SHAREHOLDER_DETAILS,
} from "./registrationActions";
import { SET_OPTION } from "./optionActions";
import { PUSH_BREAD_CRUMB } from "./maintenanceActions";
import {
  SET_ONBOARDING_REQUEST_OBJECT,
  SET_ONBOARDING_REQUEST_OBJECT_PROPERTY,
} from "./onboardingRequestActions";

const initStore = {
  isValidStep1: true,
  isValidStep2: true,
  isValidStep3: true,
  isValidStep4: true,
  isValidStep5: true,
  isValidStep6: true,

  isVATNumberValid: true,

  showLoadingModal: false,
  loadingScreenMsg: "",
  showInfoPopup: false,
  infoPopupHeader: "This page cotains missing information.",
  infoPopupSubheading: "To continue please amend / complete the following:",
  infoPopupContent: ["full name", "role", "telephone"],

  currentCompanyTab: { index: 0 },

  breadCrumbList: [
    { path: "/home", display: "Home" },
    { path: "/mycompany/supinfo", display: "My Company" },
    { path: "/mycompany/supinfo", display: "Administration" },
  ],

  currentCompanyId: 0,
  loggedInUserDetails: {
    companyName: null,
    userGreeting: null,
  },
  lastStepCompleted: 0,

  companyConfiguration: {},

  companyOutstandingInfo: {},

  onboardingLastStepCompleted: 0,

  registration: {
    supplierInfo: {},
    addressInfo: {},
    beeInfo: {},
    documents: [],
    bankDetails: {},
    prdInfo: {},
    prtInfo: {},
  },

  onboardingRequest: {},

  options: {
    provinces: [],
    countries: [],
    sectors: [],
    turnoverBands: [],
    sectorTurnoverBands: [],
    sectorTurnoverBandStatuses: [],
    companyTypes: [],
    beeLvls: [],
    beeSectors: [],
    keyContactRoles: [],
    bankAccountTypes: [],
    banks: [],
    supplierCategories: [],
    subscriptions: [],
    documents: [],
    businessUnits: [],
    documentTypes: [],
    businessTypes: [],
  },
  staticCompanyData: [],
  staticData: [],

  stepProgression: {
    supplierInfo: [],
    addressInfo: [],
    beeInfo: [],
    bankDetails: [],
  },

  authentication: {
    isAuthenticated: true,
    retryHandlers: [],
    tokenExpirationDate: undefined,
    tokenCreationDate: undefined,
    forceLogout: false,
  },
  apiErrors: {
    errors: [],
  },
};

function reducer(state = initStore, action) {
  switch (action.type) {
    case ON_REGISTRATION_INPUT_CHANGE: {
      return {
        ...state,
        registration: {
          ...state.registration,
          [action.payload.stage]: {
            ...state.registration[action.payload.stage],
            [action.payload.fieldName]: action.payload.fieldValue,
          },
        },
      };
    }

    case ON_ADDRESS_INFO_CHANGE: {
      return {
        ...state,
        registration: {
          ...state.registration,
          addressInfo: {
            ...state.registration.addressInfo,
            [action.payload.category]: {
              ...state.registration.addressInfo[action.payload.category],
              [action.payload.name]: action.payload.value,
            },
          },
        },
      };
    }

    case ON_STAFF_CONTACT_INPUT_CHANGE: {
      const type = action.payload.newEntry.contactPersonTypeId;
      const value = action.payload.newEntry;

      const indexOfItemToChange = state.registration.addressInfo.contactPersons.findIndex(
        (c) => c.contactPersonTypeId === type
      );

      const newContactPersons = [...state.registration.addressInfo.contactPersons];
      newContactPersons[indexOfItemToChange] = { ...value };

      return {
        ...state,
        registration: {
          ...state.registration,
          addressInfo: {
            ...state.registration.addressInfo,
            contactPersons: newContactPersons,
          },
        },
      };
    }

    case SHOW_LOADING_SCREEN: {
      return {
        ...state,
        showLoadingModal: true,
        loadingScreenMsg: action.payload.loadingScreenMsg,
      };
    }

    case HIDE_LOADING_SCREEN: {
      return {
        ...state,
        showLoadingModal: false,
        loadingScreenMsg: "",
      };
    }

    case SET_SUPPLIER_CATEGORIES: {
      return {
        ...state,
        registration: {
          ...state.registration,
          prdInfo: {
            ...state.registration.prdInfo,
            selectedSupplierSubCategoryIds: action.payload.newSelection,
          },
        },
      };
    }

    case SET_UNSPSC_FAMILIES: {
      return {
        ...state,
        registration: {
          ...state.registration,
          prdInfo: {
            ...state.registration.prdInfo,
            selectedUnspscFamilyIds: action.payload.newSelection,
          },
        },
      };
    }

    case SET_POSTAL_ADDRESS: {
      return {
        ...state,
        registration: {
          ...state.registration,
          addressInfo: {
            ...state.registration.addressInfo,
            postalAddress: {
              ...action.payload.physicalAddress,
            },
          },
        },
      };
    }

    case SET_SHAREHOLDER_DETAILS: {
      return {
        ...state,
        registration: {
          ...state.registration,
          supplierInfo: {
            ...state.registration.supplierInfo,
            shareholders: {
              ...action.payload,
            },
          },
        },
      };
    }

    case SET_REFERENCES: {
      return {
        ...state,
        registration: {
          ...state.registration,
          prdInfo: {
            ...state.registration.prdInfo,
            references: action.payload.references,
          },
        },
      };
    }

    case SET_STORE_PROPERTY: {
      return {
        ...state,
        [action.payload.propName]: action.payload.value,
      };
    }

    case SET_REGISTRATION_INFO: {
      return {
        ...state,
        registration: {
          ...state.registration,
          [action.payload.objName]: action.payload.objVal,
        },
      };
    }

    case SET_OPTION: {
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.optionName]: action.payload.optionValues,
        },
      };
    }

    case SHOW_INFO_POPUP: {
      return {
        ...state,
        showInfoPopup: true,
        infoPopupHeader: action.payload.heading,
        infoPopupSubheading: action.payload.subheading,
        infoPopupContent: action.payload.msgs,
      };
    }

    case HIDE_INFO_POPUP: {
      return {
        ...state,
        showInfoPopup: false,
      };
    }

    case SET_LAST_STEP_COMPLETED: {
      const newValue = action.payload.value > state.lastStepCompleted
        ? action.payload.value
        : state.lastStepCompleted;
      return {
        ...state,
        lastStepCompleted: newValue,
      };
    }

    case PUSH_BREAD_CRUMB: {
      const newBreadCrumbList = state.breadCrumbList.concat(
        action.payload.newBreadCrumb
      );
      return {
        ...state,
        breadCrumbList: newBreadCrumbList,
      };
    }

    case SET_ONBOARDING_REQUEST_OBJECT: {
      return {
        ...state,
        onboardingRequest: action.payload,
      };
    }

    case SET_ONBOARDING_REQUEST_OBJECT_PROPERTY: {
      return {
        ...state,
        onboardingRequest: {
          ...state.onboardingRequest,
          [action.payload.fieldName]: action.payload.fieldValue,
        },
      };
    }

    case SET_USER_BASIC_DETAILS: {
      const newLoggedInUserDetails = {
        companyName: action.payload.companyName,
        userGreeting: action.payload.fullName,
        companyId: action.payload.companyId,
        subscriptionName: action.payload.subscriptionName,
        subscriptionTierId: action.payload.subscriptionTierId,
        allowedFeatures: action.payload.allowedFeatures,
        userLimit: action.payload.userLimit,
        searchLimit: action.payload.searchLimit,
        emailAddress: action.payload.email,
        businessUnitIds: action.payload.businessUnitIds,
        accountSuspended: action.payload.accountSuspended,
      };

      return {
        ...state,
        currentCompanyId: action.payload.companyId,
        loggedInUserDetails: newLoggedInUserDetails,
      };
    }

    case SET_COMPANY_CONFIGURATION: {
      const newCompanyConfiguration = {
        prioritiseClientOfferings: action.payload.prioritiseClientOfferings,
        allowedCompanyFeatures: action.payload.allowedCompanyFeatures,
      };

      return {
        ...state,
        companyConfiguration: newCompanyConfiguration,
      };
    }

    case SET_COMPANY_OUTSTANDING_INFO: {
      const newCompanyOutstandingInfo = {
        addressInformation: action.payload.addressInformation,
        bankInformation: action.payload.bankInformation,
        companyInformation: action.payload.companyInformation,
        supportingDocuments: action.payload.supportingDocuments,
        agreements: action.payload.agreements,
        contactPersons: action.payload.contactPersons,
        outstandingBuyerIds: action.payload.outstandingBuyerIds,
      };

      return {
        ...state,
        companyOutstandingInfo: newCompanyOutstandingInfo,
      };
    }

    case SET_DATA_FEATURES: {
      return {
        ...state,
        dataFeatures: action.payload,
      };
    }

    case SET_SUBSCRIPTIONS_ALLOWED_FEATURES: {
      return {
        ...state,
        subscriptionsAllowedFeatures: action.payload,
      };
    }

    case LOG_OUT: {
      window.location = "/Account/Logout";
      return { ...state };
    }

    case SHOW_ERROR: {
      return { ...state, showError: action.payload };
    }

    case CLEAR_API_ERRORS: {
      return { ...state, apiErrors: { ...state.apiErrors, errors: [] } };
    }

    case SET_STATIC_COMPANY_DATA: {
      const newStaticCompanyData = [...state.staticCompanyData];
      action.payload.forEach((option) => {
        newStaticCompanyData[option.type] = option.value;
      });
      return {
        ...state,
        staticCompanyData: newStaticCompanyData,
      };
    }

    case SET_STATIC_DATA: {
      const newStaticData = [...state.staticData];
      action.payload.forEach((option) => {
        newStaticData[option.type] = option.value;
      });
      return {
        ...state,
        staticData: newStaticData,
      };
    }

    case SET_SUBSCRIPTION_INFO: {
      return {
        ...state,
        loggedInUserDetails: {
          ...state.loggedInUserDetails,
          subscriptionTierId: action.payload.subscriptionTierId,
          subscriptionName: action.payload.subscriptionName,
          allowedFeatures: action.payload.allowedFeatures,
        },
      };
    }

    case SET_NOT_AUTHENTICATED: {
      return {
        ...state,
        authentication: {
          ...state.authentication,
          isAuthenticated: action.payload.isAuthenticated,
          retryHandlers: action.payload.isAuthenticated ? [] : [
            ...state.authentication.retryHandlers,
            action.payload.retryHandler],
        },
      };
    }

    case SET_TOKEN_EXPIRATION: {
      return {
        ...state,
        authentication: {
          ...state.authentication,
          tokenExpirationDate: action.payload.expiresAt,
          tokenCreationDate: action.payload.createdAt,
        },
      };
    }

    case SET_FORCE_LOGOUT: {
      return {
        ...state,
        authentication: {
          ...state.authentication,
          tokenExpirationDate: action.payload.expiresAt,
          tokenCreationDate: action.payload.createdAt,
          forceLogout: true,
        },
      };
    }

    case ADD_API_ERROR: {
      return {
        ...state,
        apiErrors: {
          errors: [...state.apiErrors.errors, action.payload.apiError],
        },
      };
    }

    default: {
      return { ...state };
    }
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
