enum UserSortOption {
  None = 0, // eslint-disable-line no-unused-vars
  Name = 1, // eslint-disable-line no-unused-vars
  Email = 2, // eslint-disable-line no-unused-vars
  Date = 3, // eslint-disable-line no-unused-vars
}

export const userSortOptionToString = (value: UserSortOption) => {
  switch (value) {
    case UserSortOption.None:
      return "None";
    case UserSortOption.Name:
      return "Full Name";
    case UserSortOption.Email:
      return "Email Address";
    case UserSortOption.Date:
      return "Last Login Date";
    default:
      return "Unknown Sort Option";
  }
};

export default UserSortOption;
