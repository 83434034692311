import React, { useEffect, useState } from "react";
import { StaticOption } from "../../Hooks/staticData/interfaces/StaticDataOption";// eslint-disable-line no-unused-vars
import { UnspscSegment, UnspscFamily } from "../../Hooks/staticData/useStaticData";// eslint-disable-line no-unused-vars
import { createGlobalProductServiceSummary, getUnspscInfo } from "../../utils/dataTransform";
import StaticDataTypes from "../../utils/enums/StaticDataTypes";
import ProductsAndServicesSummary from "../ProductsAndServicesSummary/ProductsAndServicesSummary";

const GlobalProductsAndServicesList = (
  {
    selectedUnspscFamilyIds,
    getStaticDataCallback,
  }
    :
  {
    selectedUnspscFamilyIds: number[],
    getStaticDataCallback: (optionsToLoad: StaticDataTypes[]) => [StaticOption[][], boolean]
  }
) => {
  const [[unspscSegments, unspscFamilies], isReady] = getStaticDataCallback(
    [StaticDataTypes.UnspscSegment, StaticDataTypes.UnspscFamily]
  );

  const [itemSummary, setItemSummary] = useState<Array<{ heading: string, items: string[] }>>([]);

  useEffect(() => {
    const info = getUnspscInfo(
      unspscSegments as any as Array<UnspscSegment>,
      unspscFamilies as any as Array<UnspscFamily>
    );

    const summary: Array<{ heading: string, items: string[] }> = isReady
      ? createGlobalProductServiceSummary(
        selectedUnspscFamilyIds,
        info ?? []
      )
      : [];
    setItemSummary(summary);
  }, [unspscSegments, unspscFamilies, selectedUnspscFamilyIds, isReady]);

  return (
    <ProductsAndServicesSummary
      itemSummary={itemSummary}
    />
  );
};

export default GlobalProductsAndServicesList;
