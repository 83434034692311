import React,
{
  Fragment,
  // eslint-disable-next-line no-unused-vars
  ReactNode,
} from "react";
import { Toast, ToastHeader } from "reactstrap";
import { useVisibleToasts } from "../../Hooks/useToast";

import Styles from "./ToastContainer.module.scss";

const ToastContainer = ({ children } : {children: ReactNode}) => {
  const toastMessages = useVisibleToasts();

  return (
    <Fragment>
      {children}
      <div className={Styles.ToastContainer}>
        {toastMessages.map((message) => (
          <Toast key={message.id} isOpen={message.visible}>
            <ToastHeader toggle={message.dismiss} icon="success">
              <span className="pr-2">{message.content}</span>
            </ToastHeader>
          </Toast>
        ))}
      </div>
    </Fragment>
  );
};

export default ToastContainer;
