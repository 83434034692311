import React from "react";

import { PageHeading, SearchInput } from "isuppli-react-components";
import navMenuItems from "../../Util/menuItems";

function MarketplaceSearch(props) {
  return (
    <div>
      <PageHeading
        heading="Find Companies"
        alertText="Explore the Virtual Marketplace to find and connect with other companies."
        menuItems={navMenuItems}
      />

      <SearchInput
        searchTerm={props.searchTerm}
        onSearch={props.onSearchCallback}
        onChange={props.onSearchChangeCallback}
        searchPlaceholder="Search by company, product or service description"
      />
    </div>
  );
}

export default MarketplaceSearch;
