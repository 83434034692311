import React, {
  useRef,
  useCallback,
  // eslint-disable-next-line no-unused-vars
  ForwardRefRenderFunction,
  useImperativeHandle,
  forwardRef,
} from "react";

export interface FileSelectorRef {
  selectFiles: () => void,
}
export interface FileSelectorProps{
  onFilesSelected: (fileNames: Array<string>,
    formData: FormData, uploadErrors: Array<string>) => void,
  allowMultiple?: boolean,
  allowedFileTypes?: string,
}

const FileSelector:ForwardRefRenderFunction<FileSelectorRef, FileSelectorProps> = (
  {
    onFilesSelected,
    allowMultiple = false,
    allowedFileTypes = "", // csv file types eg. : "".doc,.docx,.pdf"
  }: FileSelectorProps, ref
) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const triggerFileSelect = useCallback(() => {
    const currentInput = fileInput?.current ?? { dispatchEvent: () => {} };
    currentInput.dispatchEvent(new MouseEvent("click"));
  }, [fileInput]);

  useImperativeHandle(ref, () => ({
    selectFiles: triggerFileSelect,
  }));

  const fileChangeHandler = async () => {
    const fileCount = fileInput?.current?.files?.length ?? 0;
    if (fileCount === 0) {
      return;
    }
    const formData = new FormData();
    const fileNames:Array<string> = [];
    const uploadErrors: Array<string> = [];

    if (allowMultiple) {
      for (let i = 0; i < fileCount; i += 1) {
        const files = fileInput?.current?.files ?? [];
        const file = files[i];
        // check size
        const fileSize = file.size / 1024 / 1024; // convert to MB
        if (fileSize < 10) {
          formData.append(file.name, file);
          uploadErrors.push("");
        } else {
          uploadErrors.push("File size exceeds 10 MB");
        }
        fileNames.push(file.name);
      }
    } else {
      const file = (fileInput?.current?.files ?? [])[0];
      // check size
      const fileSize = file.size / 1024 / 1024; // convert to MB
      if (fileSize < 10) {
        formData.append(file.name, file);
        uploadErrors.push("");
      } else {
        uploadErrors.push("File size exceeds 10 MB");
      }
      fileNames.push(file.name);
    }

    // clear input to allow file with same name to be uploaded again
    if (fileInput.current != null) {
      fileInput.current.value = "";
    }

    onFilesSelected(fileNames, formData, uploadErrors);
  };

  return (
    <form className="invisible position-absolute">
      <input type="file" ref={fileInput} accept={allowedFileTypes} onChange={fileChangeHandler} multiple={allowMultiple} />
    </form>
  );
};

export default forwardRef<FileSelectorRef, FileSelectorProps>(FileSelector);
