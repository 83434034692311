import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { showErrorDialog } from "../Store/actions";

const useShowError = () => {
  const dispatch = useDispatch();
  const handler = useCallback(() => {
    dispatch(showErrorDialog(true));
  }, [dispatch]);

  return handler;
};

export default useShowError;
