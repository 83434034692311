import {
  Form, ModalPopup, FormRef, TextInput, ButtonInfo, Spinner, // eslint-disable-line no-unused-vars
} from "isuppli-react-components";
import React, { useEffect, useRef, useState } from "react";
import { getOnboardingRequest } from "../../http";
import { checkHttpStatus } from "../../http/httpHelpers";
import { addOrUpdateVendorNumber } from "../../http/Onboarding/OnboardingApi";

const UpdateVendorNumberModal = (
  {
    onboardingRequestId,
    onSubmitCallback,
    onCloseCallback,
  }:
    {
      onboardingRequestId: number,
      onSubmitCallback?: () => void,
      onCloseCallback: () => void
    }
) => {
  const formRef = useRef<FormRef>(null);
  const [vendorNumber, setVendorNumber] = useState("");
  const [onboardingRequest, setOnboardingRequest] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [vendorNumberInvalid, setVendorNumberInvalid] = useState(false);
  const [invalidInputMessage, setInvalidInputMessage] = useState<string>();

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      const data = await getOnboardingRequest(onboardingRequestId);
      setOnboardingRequest(data);
      setLoading(false);
    };

    loadData();
  }, [onboardingRequestId]);

  const saveData = async () => {
    setVendorNumberInvalid(false);
    setInvalidInputMessage(undefined);
    if (!(formRef.current?.isValid() ?? false)) {
      formRef.current?.showValidation();
    } else {
      try {
        await addOrUpdateVendorNumber({ vendorNumber, onboardingRequestId });
        if (onSubmitCallback != null) {
          onSubmitCallback();
          onCloseCallback();
        }
      } catch (error) {
        if (checkHttpStatus(error, 409)) {
          setInvalidInputMessage("Duplicate detected, this vendor number has already been allocated.");
        } else if (!checkHttpStatus(error, 400)) {
          setInvalidInputMessage("An Unexpected Error Occurred, Please contact your administrator if this continues");
        }
        setVendorNumberInvalid(true);
        formRef.current?.showValidation();
      }
    }
  };
  const buttons = [
    {
      key: "Submit",
      label: "Submit",
      onClick: saveData,
      color: "primary",
    },
  ] as ButtonInfo[];

  return (
    <ModalPopup
      onClose={onCloseCallback}
      buttons={buttons}
      size="extra-large"
      heading="Allocate vendor number"
      subheading="According to your company configuration settings, vendor numbers must be provided manually.
      To complete the onboarding request, please enter a valid vendor number."
    >
      <div className="p-3 mx-2">
        {
          loading ? <Spinner inline /> : (
            <Form className="row" ref={formRef} openValidationIn={document.getElementById("validation-root") ?? undefined}>
              <TextInput
                className="col-12"
                label={`Vendor number (${onboardingRequest.registeredName})`}
                placeholder="Please provide the vendor number you wish to assign to the supplier"
                required
                maxLength={20}
                value={vendorNumber}
                invalid={vendorNumberInvalid}
                onChange={(value) => setVendorNumber(value)}
                validationMessage={invalidInputMessage ?? "You have to enter a valid vendor number"}
              />
            </Form>
          )
        }
      </div>
    </ModalPopup>
  );
};

export default UpdateVendorNumberModal;
