import React, { Component } from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import {
  ModalPopup,
} from "isuppli-react-components";

import { Alert } from "reactstrap";
import RegistrationProgress from "./RegistrationProgress";
import Loading from "../Loading";

import BeeStatus from "../../Containers/Supplier/BeeStatus";
import BeeCertification from "../../Containers/Supplier/BeeCertification";
import InfoPopup from "../../Containers/InfoPopup";
import SaveAndContinueConfirm from "../../Containers/Supplier/SaveAndContinueConfirm";
import BlackOwnership from "../../Containers/Supplier/BlackOwnership";
import SectorTurnoverInfo from "../../Containers/Supplier/SectorTurnoverInfo";

import WizzardBtns from "../../Containers/WizzardBtns";

import validateStep from "../../Validation/onSubmitValidations";
import { requiredFieldsStep3 } from "../../Validation/validation";

import { setOption } from "../../Store/optionActions";
import {
  setRegistrationObject,
  onRegistrationInputChange,
} from "../../Store/registrationActions";
import {
  logOut,
  showLoadingScreen,
  hideLoadingScreen,
  hideInfoPopup,
  showInfoPopup,
  setLastStepCompleted,
} from "../../Store/actions";

import { saveBeeCertificationInfo } from "../../http/posts";

import { addDays, isBefore } from "../../Util/dateArith";
import { loadBeePracticeOptions } from "../../Util/optionsLoader";
import { loadBeePracticeData } from "../../Util/dataLoader";

const mapStateToProps = (state) => ({
  data: {
    ...state.registration.beeInfo,
    companyId: state.currentCompanyId,
  },
  isStepValid: state.isValidStep3,
  beeLvls: state.options.beeLvls,
  beeSectors: state.options.beeSectors,
  turnoverband: state.options.turnoverBands.find(
    (tob) => tob.id === state.registration.supplierInfo.turnoverBand
  ),
  documentTypes: state.options.documentTypes,
  sectors: state.options.sectors,
  turnoverBands: state.options.turnoverBands,
  sectorTurnoverBands: state.options.sectorTurnoverBands,
  sectorTurnoverBandStatuses: state.options.sectorTurnoverBandStatuses,
});

const mapDispatchToProps = (dispatch) => ({
  onInputChange: (ev) => {
    dispatch(onRegistrationInputChange("beeInfo", ev));
  },
  setOption: (optionName, optionValue) => {
    dispatch(setOption(optionName, optionValue));
  },
  setBeeInfo: (beeInfo) => {
    dispatch(setRegistrationObject("beeInfo", beeInfo));
  },
  showLoadingScreen: (msg) => {
    dispatch(showLoadingScreen(msg));
  },
  hideLoadingScreen: () => {
    dispatch(hideLoadingScreen());
  },
  showInfoMsg: (heading, subheading, msgs) => {
    dispatch(showInfoPopup(heading, subheading, msgs));
  },
  hideInfoMsg: () => {
    dispatch(hideInfoPopup());
  },
  setLastStepCompleted: (value) => {
    dispatch(setLastStepCompleted(value));
  },
  logOut: () => {
    dispatch(logOut());
  },
});

class RegistrationStep03 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msgs: {},
      showConfirmMsg: false,
      showTurnoverModal: false,
      isVatExempted: true,
    };
  }

  performSave = (onSuccess = null, stepNumber) => {
    this.props.showLoadingScreen("saving data...");

    saveBeeCertificationInfo(
      {
        ...this.props.data,
        boPercentage: Number(this.props.data.boPercentage),
        bwoPercentage: Number(this.props.data.bwoPercentage),
        registrationStepCompleted:
          this.props.data.registrationStepCompleted > stepNumber
            ? this.props.data.registrationStepCompleted
            : stepNumber,
      },
      () => {
        if (onSuccess) {
          onSuccess();
        }
        this.props.hideInfoMsg();
      },
      () => {
        this.props.hideLoadingScreen();
      }
    );
  };

  onNextClick = (arg) => {
    const validation = validateStep(this.props.data, requiredFieldsStep3);

    if (this.props.data.hasBeeCertificate === 1) {
      if (
        Number(this.props.data.boPercentage)
        < Number(this.props.data.bwoPercentage)
      ) {
        validation.isValid = false;
        validation.msgs = {
          ...validation.msgs,
          boPercentage:
            "Black ownership should be higher than black woman ownership",
          bwoPercentage:
            "Black woman ownership should be lower than black ownership",
        };
      }

      if (!this.props.data.beeStatusId || this.props.data.beeStatusId === 0) {
        validation.isValid = false;
        validation.msgs = {
          ...validation.msgs,
          beeStatusId: "Bee level is required",
        };
      }

      if (!this.props.data.certificateExpiryDate) {
        validation.isValid = false;
        validation.msgs = {
          ...validation.msgs,
          certificateExpiryDate: "Certificate expiry date is required",
        };
      } else {
        const newDate = addDays(365, new Date());
        const expiryDate = new Date(this.props.data.certificateExpiryDate);
        const isCertificateValid = this.props.data.certificateExpiryDate
          && isBefore(expiryDate, newDate);

        if (!isCertificateValid) {
          validation.isValid = false;
          validation.msgs = {
            ...validation.msgs,
            certificateExpiryDate:
            "Certificate expiry date is invalid",
          };
        }
      }

      if (this.props.data.sector == null || this.props.data.sector <= 0) {
        validation.isValid = false;
        validation.msgs = {
          ...validation.msgs,
          sector: "Sector is required",
        };
      } else if (this.props.data.turnoverBand == null || this.props.data.turnoverBand <= 0) {
        validation.isValid = false;
        validation.msgs = {
          ...validation.msgs,
          turnoverBand: "Turnover band is required",
        };
      }
    }
    this.setState({
      msgs: validation.msgs,
    });
    if (validation.isValid) {
      // check hasVatNumber if selected turnover band is not vat exempted

      if (!this.props.data.hasVatNumber && !this.state.isVatExempted) {
        // show modal
        this.setTurnoverModal(true);
      } else {
        this.saveAndContinueToStep(arg);
      }
    } else {
      window.scrollTo({ top: 0 });
      this.props.showInfoMsg(
        "This page contains missing information.",
        "To continue please amend/complete the following:",
        Object.keys(validation.msgs).map((key) => validation.msgs[key])
      );
    }
  };

  onHasBeeCertificateChangeHandler = (newVal) => {
    if (newVal === 0) {
      this.setState(() => ({
        isVatExempted: true,
      }));
    }
  }

  setTurnoverModal = (newValue) => {
    this.setState(() => ({
      showTurnoverModal: newValue,
    }));
  }

  toggleConfirmSaveAndContinue = () => {
    this.setState((prevSate) => ({
      showConfirmMsg: !prevSate.showConfirmMsg,
    }));
  };

  saveAndContinueLater = () => {
    this.performSave(() => {
      this.props.logOut();
      this.props.hideLoadingScreen();
    }, 2);
  };

  saveAndContinueToStep = (step) => {
    this.performSave(() => {
      this.props.history.push(step);
      this.props.hideLoadingScreen();
    }, 3);
  }

  componentDidMount() {
    if (this.props.data.companyId === 0) {
      this.props.history.push("/registration/supinfo");
      return;
    }
    loadBeePracticeOptions(this.props);
    loadBeePracticeData(this.props);
  }

  render() {
    return (
      <section id="isuppli-registration-step-03">
        <Loading />

        <InfoPopup />

        {this.state.showConfirmMsg && (
          <SaveAndContinueConfirm
            toggleModalCallback={this.toggleConfirmSaveAndContinue}
            saveAndContinueCallback={this.saveAndContinueLater}
          />
        )}

        {
          this.state.showTurnoverModal
          && (
            <ModalPopup
              heading="B-BBEE Turnover Band Selection"
              subheading="You have selected a B-BBEE turnover band in excess of R1m,
                while also indicating in the Company Information section that you are NOT registered for VAT.
                Would you like to return to the Company Information section to amend your VAT information,
                or would you like to proceed to the General Product Offering section ?"
              hasCancel
              onClose={
                () => {
                  this.setTurnoverModal(false);
                }
              }
              buttons={[{
                key: "saveAndReturn",
                label: "Save and return to company information",
                onClick: () => { this.saveAndContinueToStep("/registration/supinfo"); },
                color: "primary",
              },
              {
                key: "proceed",
                label: "Save and proceed to general product offering",
                onClick: () => { this.saveAndContinueToStep("/registration/prdinfo"); },
                color: "attention",
              }]}
            />
          )
        }

        <RegistrationProgress currentStep={3} />

        <div className="padding-lr-10vw">
          <div className="row top-margin-90px">
            <div className="col-12 col-lg-7">
              <div>
                <h3 className="margin-null">STEP 03</h3>
              </div>
              <div>
                <h1>B-BBEE PRACTICES & DOCUMENTATION</h1>
              </div>
            </div>

            <div
              className="col-12 col-lg-5 justify-center flex-dir-column"
              style={{ paddingTop: "20px" }}
            >
              <h3>
                We need a little bit more information about your business, to
                ensure we know what your company has to offer.
              </h3>
            </div>
          </div>

          <div className="row" style={{ paddingTop: "36px" }}>
            <div className="col-12 col-lg-9">
              <div>
                <Alert color="info">
                  Please consider uploading your B-BBEE Verification Document
                  as most corporate companies will use this information to claim
                  procurement spend against their own B-BBEE scorecard.
                  Some companies may overlook your services and instead look for
                  other B-BBEE compliant companies to transact with.
                  Get ahead of your competition and stay B-BBEE compliant
                </Alert>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="padding-lr-10vw" style={{ marginTop: "36px" }}>
            <BeeStatus
              hasBeeCertificate={this.props.data.hasBeeCertificate}
              validationStatus={this.props.data.validationStatus}
              hasBeeValidationMessage={this.state.msgs}
              onInputChange={this.props.onInputChange}
              onHasBeeCertificateChange={this.onHasBeeCertificateChangeHandler}
              readOnly={this.props.readOnly}
              companyGroupingType={this.props.data.companyGroupingType}
            />
          </div>
          <div className="padding-lr-5vw" style={{ marginTop: "36px" }}>
            <hr />
          </div>
        </div>

        {this.props.data.hasBeeCertificate === 1 && (
          <div>
            <div className="padding-lr-10vw" style={{ marginTop: "36px" }}>
              <SectorTurnoverInfo
                sector={this.props.data.sector}
                turnoverBand={this.props.data.turnoverBand}
                readOnly={this.props.readOnly}
                additional={this.state.msgs}
                onInputChange={this.props.onInputChange}
                onVatExemptedChange={(newValue) => this.setState({
                  isVatExempted: newValue,
                })}
              />
            </div>
            <div className="padding-lr-5vw" style={{ marginTop: "36px" }}>
              <hr />
            </div>
            <div className="padding-lr-10vw" style={{ marginTop: "36px" }}>
              <BlackOwnership
                data={this.props.data}
                additional={this.state.msgs}
                onInputChangeCallback={this.props.onInputChange}
              />
            </div>
            <div className="padding-lr-5vw" style={{ marginTop: "36px" }}>
              <hr />
            </div>
            <div className="padding-lr-10vw" style={{ marginTop: "36px" }}>
              <BeeCertification
                data={this.props.data}
                turnoverband={this.props.turnoverband}
                beeLvls={this.props.beeLvls}
                beeSectors={this.props.beeSectors}
                sectorTurnoverBands={this.props.sectorTurnoverBands}
                sectorTurnoverBandStatuses={this.props.sectorTurnoverBandStatuses}
                additional={this.state.msgs}
                onInputChangeCallback={this.props.onInputChange}
              />
            </div>
            <div className="padding-lr-5vw" style={{ marginTop: "36px" }}>
              <hr />
            </div>
          </div>
        )}

        <WizzardBtns
          history={this.props.history}
          backUrl="/registration/coninfo"
          nextUrl="/registration/prdinfo"
          nextText="GENERAL PRODUCT OFFERING"
          onClickSaveAndContinue={this.toggleConfirmSaveAndContinue}
          onClickNext={this.onNextClick}
        />
      </section>
    );
  }
}

const mappedRegistrationStep03 = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationStep03);
export default withRouter(mappedRegistrationStep03);
