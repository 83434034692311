/* eslint-disable no-unused-vars */
import axios, { AxiosError, AxiosResponse } from "axios";
import React, { ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ApiErrorCode, ProblemDetails } from "isuppli-react-components";
import { addApiError } from "../../Store/actions";

const AxiosInterceptor: React.FunctionComponent<{ children: ReactNode }> = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    axios.interceptors.response.use(
      (response: AxiosResponse<any>) => response,
      // eslint-disable-next-line consistent-return
      (error: AxiosError) => {
        const paramStart = error.config?.url?.indexOf("?");
        const endpointUrl = paramStart == null || paramStart <= 0 ? error.config?.url : error.config?.url?.substring(0, paramStart);

        const axiosErrorResponse = {
          code: error.response?.status,
          url: endpointUrl,
          message: error.response?.data,
        };

        let problemDetails: ProblemDetails = { ...error.response?.data, url: endpointUrl };
        if (problemDetails.code == null && axiosErrorResponse.code === 404) {
          problemDetails = {
            ...axiosErrorResponse,
            status: 404,
            code: ApiErrorCode.EntityNotFound,
            title: "Not Found",
            traceId: "n/a",
            additionalDetails: {
              message: axiosErrorResponse?.message,
              subSystem: "Api Gateway",
            },
          };
        }

        switch (problemDetails.code) {
          case ApiErrorCode.EntityNotFound:
            dispatch(addApiError(problemDetails));
            break;
          case ApiErrorCode.Unknown:
            dispatch(addApiError(problemDetails));
            break;
          case ApiErrorCode.ExistsExceptions:
            dispatch(addApiError(problemDetails));
            break;
          case ApiErrorCode.BadRequest:
            dispatch(addApiError(problemDetails));
            break;
          case ApiErrorCode.Conflict:
            dispatch(addApiError(problemDetails));
            break;
          default:
            // Do nothing and let specific custom business exception handling.
        }
        return Promise.reject(error);
      }
    );
  }, [dispatch]);

  return (
    <>
      {props.children}
    </>
  );
};

export default AxiosInterceptor;
