import React from "react";
import Styles from "./Line.module.scss";

const Line = ({ color, className } : {color: "primary" | "secondary", className?: string }) => {
  let colorStyle:string;
  switch (color) {
    case "primary":
      colorStyle = Styles.ColorPrimary;
      break;
    case "secondary":
      colorStyle = Styles.ColorSecondary;
      break;
    default:
      colorStyle = "";
  }
  return (<div className={`${Styles.Line} ${colorStyle} ${className}`} />);
};

export default Line;
