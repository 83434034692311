/* eslint-disable guard-for-in */
/* eslint-disable consistent-return */
import React, {
  Dispatch, // eslint-disable-line no-unused-vars
  Fragment,
  SetStateAction, // eslint-disable-line no-unused-vars
  useEffect, useState,
} from "react";
import {
  BasicTable, CheckboxInput,
  // eslint-disable-next-line no-unused-vars
} from "isuppli-react-components";

// eslint-disable-next-line no-unused-vars
import useShowError from "../../../../Hooks/useShowError";
import { PendingEventDetailMessage, ApprovedEventItemMessage } from "../../../../http/MyOffice/SupplierUpdates/Models/Models"; // eslint-disable-line no-unused-vars
import { getPendingEventDetails } from "../../../../http/MyOffice/SupplierUpdates/supplierUpdatesApi";
// eslint-disable-next-line no-unused-vars
import pendingVendorDetailChangeType, { pendingVendorDetailToString } from "../../../../Util/Enumerators/pendingVendorDetailChangeType";

const PendingEventDetailTable = ({
  deleteEvent,
  eventId,
  approvedChangeEvents,
  setApprovedChangeEvent,
}: {
  deleteEvent: boolean,
  eventId: number,
  approvedChangeEvents: Array<ApprovedEventItemMessage>,
  setApprovedChangeEvent: Dispatch<SetStateAction<Array<ApprovedEventItemMessage>>>
}) => {
  const showError = useShowError();
  const [loadingTable, setLoadingTable] = useState(true);
  const [eventDetails, setEventDetails] = useState<Array<PendingEventDetailMessage>>([]);

  useEffect(() => {
    const loader = async () => {
      setLoadingTable(true);

      try {
        if (eventId > 0) {
          const details = await getPendingEventDetails(eventId);
          setEventDetails(details);
          // by default all event detail are approved
          setApprovedChangeEvent(
            details.map((event) => (
              {
                detailChangeType: event.detailChangeType,
                primaryKey: event.uniqueIdentifier,
                propertyName: event.propertyName,
              } as ApprovedEventItemMessage
            ))
          );
        }
      } catch (error) {
        showError();
      }

      setLoadingTable(false);
    };

    loader();
  }, [eventId, showError, setApprovedChangeEvent]);

  const formatJsonString = (jsonString: string) => {
    const jsonObj = JSON.parse(jsonString);
    const outputString = jsonObj != null ? Object.entries(jsonObj).map((entry, index) => (
      <div className="col-12" key={index}>
        <span>
          <b>{`${entry[0]}:`}</b>
          {" "}
          {`${entry[1] !== null && entry[1] !== "" ? entry[1] : null}`}
          <br />
        </span>
      </div>
    )) : "";
    return (
      <Fragment>
        <div className="row">
          {outputString}
        </div>
      </Fragment>
    );
  };

  const toggleApprovedItem = (eventDetailItem: PendingEventDetailMessage) => {
    const itemIsApproved = approvedChangeEvents.find(
      (x) => x.detailChangeType
      === eventDetailItem.detailChangeType
      && x.primaryKey === eventDetailItem.uniqueIdentifier
      && x.propertyName === eventDetailItem.propertyName
    ) != null;

    if (itemIsApproved) {
      // remove item
      setApprovedChangeEvent((prev) => prev.filter((x) => !(
        x.detailChangeType === eventDetailItem.detailChangeType
        && x.primaryKey === eventDetailItem.uniqueIdentifier
        && x.propertyName === eventDetailItem.propertyName)));
    } else {
      // add item
      setApprovedChangeEvent((prev) => prev.concat({
        detailChangeType: eventDetailItem.detailChangeType,
        primaryKey: eventDetailItem.uniqueIdentifier,
        propertyName: eventDetailItem.propertyName,
      }));
    }
  };

  const displayResults = Object.values(pendingVendorDetailChangeType).map((changeType, index) => {
    const groupedDetails = eventDetails.filter((data) => data.detailChangeType === changeType);
    const displayDetailChangeType = pendingVendorDetailToString(
      changeType as pendingVendorDetailChangeType
    );
    if (typeof (changeType) === "string") { return null; }

    if (changeType !== pendingVendorDetailChangeType.BankAccounts
      && changeType !== pendingVendorDetailChangeType.ContactDetails) {
      return (
        <div className="container" key={index}>
          <h3 className="mt-3">
            <b>Changes: </b>
            {displayDetailChangeType}
          </h3>
          <BasicTable
            emptyMessage="No changes to view"
            loading={loadingTable}
            columns={deleteEvent
              ? [{
                key: "propName",
                text: "Property Name",
                align: "left",
                size: "1fr",
              },
              {
                key: "currentVal",
                text: "Current Value",
                size: "1fr",
                align: "left",
              },
              {
                key: "newVal",
                text: "New Value",
                size: "1fr",
                align: "left",
              }]
              : [{
                key: "propName",
                text: "Property Name",
                align: "left",
                size: "1fr",
              },
              {
                key: "currentVal",
                text: "Current Value",
                size: "1fr",
                align: "left",
              },
              {
                key: "newVal",
                text: "New Value",
                size: "1fr",
                align: "left",
              },
              {
                key: "approveChange",
                text: "Approve changes",
                size: "1fr",
                align: "left",
              }]}
            rows={groupedDetails?.map((row) => {
              if (deleteEvent) {
                return [{
                  key: "propName",
                  value: row.displayPropertyName,
                },
                {
                  key: "currentVal",
                  value: row.currentValue,
                },
                {
                  key: "newVal",
                  value: row.newValue,
                }];
              }
              return [
                {
                  key: "propName",
                  value: row.displayPropertyName,
                },
                {
                  key: "currentVal",
                  value: row.currentValue,
                },
                {
                  key: "newVal",
                  value: row.newValue,
                },
                {
                  key: "approveChange",
                  align: "center",
                  // eslint-disable-next-line react/button-has-type
                  value: <CheckboxInput
                    value={approvedChangeEvents?.find(
                      (x) => x.detailChangeType === row.detailChangeType
                      && x.propertyName === row.propertyName) != null}
                    onChange={() => toggleApprovedItem(row)}
                  />,
                },
              ];
            })}
          />
        </div>
      );
    }

    return (
      <div className="container" key={index}>
        <h3 className="mt-3">
          <b>Changes: </b>
          {displayDetailChangeType}
        </h3>
        <BasicTable
          emptyMessage="No changes to view"
          loading={loadingTable}
          columns={deleteEvent
            ? [{
              key: "propName",
              text: "Property Name",
              align: "left",
              size: "1fr",
            },
            {
              key: "currentVal",
              text: "Current Value",
              size: "1fr",
              align: "left",
            },
            {
              key: "newVal",
              text: "New Value",
              size: "1fr",
              align: "left",
            }]
            : [{
              key: "propName",
              text: "Property Name",
              align: "left",
              size: "1fr",
            },
            {
              key: "currentVal",
              text: "Current Value",
              size: "1fr",
              align: "left",
            },
            {
              key: "newVal",
              text: "New Value",
              size: "1fr",
              align: "left",
            },
            {
              key: "approveChange",
              text: "Approve change",
              size: "1fr",
              align: "left",
            }]}
          rows={groupedDetails?.map((row) => {
            if (deleteEvent) {
              return [
                {
                  key: "propName",
                  value: row.displayPropertyName,
                },
                {
                  key: "currentVal",
                  value: formatJsonString(row.currentValue ?? ""),
                },
                {
                  key: "newVal",
                  value: formatJsonString(row.newValue ?? ""),
                }];
            }
            return [
              {
                key: "propName",
                value: row.displayPropertyName,
              },
              {
                key: "currentVal",
                value: formatJsonString(row.currentValue ?? ""),
              },
              {
                key: "newVal",
                value: formatJsonString(row.newValue ?? ""),
              },
              {
                key: "approveChange",
                align: "center",
                // eslint-disable-next-line react/button-has-type
                value: <CheckboxInput
                  value={approvedChangeEvents?.
                    find((x) => x.detailChangeType === row.detailChangeType
                    && x.primaryKey === row.uniqueIdentifier) != null}
                  onChange={() => toggleApprovedItem(row)}
                />,
              },
            ];
          })}
        />
      </div>
    );
  });

  return (
    <Fragment>
      {!loadingTable && displayResults}
    </Fragment>
  );
};

export default PendingEventDetailTable;
