const staticDataTypes = {
  BeeClassification: 1,
  BeeStatus: 2,
  CompanyType: 3,
  Country: 4,
  Province: 5,
  Sector: 6,
  TurnoverBand: 7,
  SectorTurnoverBand: 8,
  Bank: 9,
  BankAccountType: 10,
  SubscriptionTier: 11,
  UnspscSegment: 12,
  UnspscFamily: 13,
  DocumentTypes: 14,
  BusinessTypes: 15,
  ContactPerson: 16,
  SectorTurnoverBandStatus: 17,
  PaymentTerms: 18,
  Gender: 19,
  Race: 20,
  Municipality: 21,
  CompanyStatus: 22,
};

export enum StaticDataTypes {
  BeeClassification = 1, // eslint-disable-line no-unused-vars
  BeeStatus = 2, // eslint-disable-line no-unused-vars
  CompanyType = 3, // eslint-disable-line no-unused-vars
  Country = 4, // eslint-disable-line no-unused-vars
  Province = 5, // eslint-disable-line no-unused-vars
  Sector = 6, // eslint-disable-line no-unused-vars
  TurnoverBand = 7, // eslint-disable-line no-unused-vars
  SectorTurnoverBand = 8, // eslint-disable-line no-unused-vars
  Bank = 9, // eslint-disable-line no-unused-vars
  BankAccountType = 10, // eslint-disable-line no-unused-vars
  SubscriptionTier = 11, // eslint-disable-line no-unused-vars
  UnspscSegment = 12, // eslint-disable-line no-unused-vars
  UnspscFamily = 13, // eslint-disable-line no-unused-vars
  DocumentTypes = 14, // eslint-disable-line no-unused-vars
  BusinessTypes = 15, // eslint-disable-line no-unused-vars
  ContactPerson = 16, // eslint-disable-line no-unused-vars
  SectorTurnoverBandStatus = 17, // eslint-disable-line no-unused-vars
  PaymentTerms = 18, // eslint-disable-line no-unused-vars
  Gender = 19, // eslint-disable-line no-unused-vars
  Race = 20, // eslint-disable-line no-unused-vars
  Municipality= 21, // eslint-disable-line no-unused-vars
  CompanyStatus= 22, // eslint-disable-line no-unused-vars
}

export default staticDataTypes;
