import { valueNullOrEmpty } from "./validation";

export default function validateStep(data, requiredFields) {
  const msgs = {};
  let isValid = true;

  requiredFields.forEach((field) => {
    const path = field.key.split(".");

    let value = data;
    for (let i = 0; i < path.length; i += 1) {
      if (value === undefined) {
        if (i < path.length - 1) { // if not the last one
          msgs[path.join("")] = `${field.display} is required`;
          isValid = false;
          return;
        }
      } else {
        value = value[path[i]];
      }
    }

    if (valueNullOrEmpty(value) || (field.checkPositive && Number(value) < 1)) {
      msgs[path.join("")] = `${field.display} is required`;
      isValid = false;
    }
  });

  return { isValid, msgs };
}
