/* eslint-disable no-bitwise */
import React, { Fragment } from "react";
import CheckBoxInput from "../CheckBoxInput/CheckBoxInput";

const FlagEnumSelectorInput = <T extends string, TEnumValue extends number >({
  label,
  value,
  onChange,
  enumToUse,
  enumValuesToFilter,
  enumDisplayValues,
  enumValuesToDisable,
} : {
  label: string,
  value: TEnumValue,
  onChange: (value: TEnumValue) => void,
  enumToUse: { [key in T]: TEnumValue },
  enumValuesToFilter? : TEnumValue
  enumDisplayValues?: { [index: number]: string; }
  enumValuesToDisable?: { [index: number] : string}
}) => {
  const enumLabels = enumDisplayValues == null
    ? Object.values(enumToUse)
      .filter((v) => typeof v === "string")
      .map((v) => v as string)
    : Object.values(enumToUse)
      .filter((v) => typeof v === "number")
      .map((v) => enumDisplayValues[v as TEnumValue]);

  const enumValues = Object.values(enumToUse)
    .filter((v) => typeof v === "number")
    .map((v) => v as TEnumValue);

  return (
    <Fragment>
      <span>{label}</span>
      {enumLabels.map((enumLabel, index) => {
        const enumValue = enumValues[index];
        if (enumValuesToFilter != null && (enumValue & enumValuesToFilter) === enumValue) {
          return null;
        }
        const isChecked = (enumValue & value) === enumValue;
        return (

          <Fragment>
            <CheckBoxInput
              disabled={enumValuesToDisable != null && enumValuesToDisable[enumValue] != null}
              key={`flagEnumCheckBox_${enumValue}`}
              label={enumLabel}
              checked={isChecked}
              onChange={() => {
                if (isChecked) {
                  const removedValue = value ^ enumValue;
                  onChange(removedValue as TEnumValue);
                } else {
                  const addedValue = value | enumValue;
                  onChange(addedValue as TEnumValue);
                }
              }}
            />
            <span>
              {enumValuesToDisable != null && enumValuesToDisable[enumValue] != null
                ? enumValuesToDisable[enumValue]
                : null}
            </span>
          </Fragment>
        );
      })}

    </Fragment>
  );
};

export default FlagEnumSelectorInput;
