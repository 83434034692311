import React from "react";
import { ConfirmationModal } from "isuppli-react-components";

const SendSubscriptionEnquiryModal = ({
  Onclose,
  selectedTierName,
}: {
  Onclose: () => void;
  selectedTierName: string;
}) => (
  <ConfirmationModal
    heading={`${selectedTierName.toUpperCase()} PACKAGE ENQUIRY`}
    size="large"
    onProceedClick={Onclose}
    onToggleModal={Onclose}
    hasCancel={false}
    proceedButtonText="OK"
    description={`Thank you. We acknowledge your interest in our ${selectedTierName} solution, 
        and a B1SA representative will be in touch with you shortly to discuss further.
        To complete your registration and access the platform at this stage, we would 
        suggest that you subscribe to an available package for now, which will allow you 
        access to a large amount of the platform functionality. 
        Alternatively, you can wait for us to discuss with you in more detail, 
        but will then not have the option to make use of the platform until then.`}
  />
);

export default SendSubscriptionEnquiryModal;
