import React from "react";
import {
  DropdownItem,
  // eslint-disable-next-line no-unused-vars
  DropdownItemProps,
} from "reactstrap";

import { useSystemFeatureCheck } from "../../../Hooks/useSystemFeatureCheck";
// eslint-disable-next-line no-unused-vars
import SystemFeatures from "../../../utils/enums/SystemFeatures";
import { useShowNotAllowedError } from "../../../Hooks/useNotAllowedError";

import Styles from "./ActionsDropDownItem.module.scss";

export interface ActionsDropDownItemProps extends Omit<DropdownItemProps, "onClick"> {
  onClick?: () => void
  children: React.ReactNode,
  feature?: SystemFeatures,
  disabled?: boolean,
}
const ActionsDropDownItem = ({
  feature,
  onClick,
  disabled,
  ...o // eslint-disable-line react/jsx-props-no-spreading
} : ActionsDropDownItemProps) => {
  const featureCheck = useSystemFeatureCheck();
  const showNotAllowedError = useShowNotAllowedError();
  const allowed = feature == null || featureCheck(feature);
  const appearDisabled = !allowed;

  return (
    <DropdownItem
      className={`${appearDisabled ? Styles.AppearDisabled : ""} ${Styles.DropDownItem}`}
      disabled={disabled}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...o}
      onClick={onClick == null ? undefined : () => {
        if (!allowed) {
          showNotAllowedError(feature);
          return;
        }
        onClick();
      }}
    />
  );
};

export default ActionsDropDownItem;
