import React from "react";
import { ProductsAndServicesSummary } from "isuppli-react-components";
import { createProductServiceSummary } from "../../Util/dataTransform";
import { StaticCompanyDataTypes } from "../../Util/Enumerators/staticCompanyDataTypes";
import useStaticCompanyData from "../../Hooks/useStaticCompanyData";

const ProductsAndServicesList = (
  { selectedSupplierSubCategoryIds }
  :
  {selectedSupplierSubCategoryIds : number[]}
) => {
  const [[supplierCategories], isReady] = useStaticCompanyData(
    [StaticCompanyDataTypes.supplierCategory]
  );

  const itemSummary: Array<{ heading : string, items : string[]}> = isReady
    ? createProductServiceSummary(
      selectedSupplierSubCategoryIds,
      supplierCategories ?? []
    )
    : [];

  return (
    <ProductsAndServicesSummary
      itemSummary={itemSummary}
    />
  );
};

export default ProductsAndServicesList;
