import React, { Component } from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import { Alert } from "reactstrap";
import { DataFeatures, hasFeature } from "isuppli-react-components";
import RegistrationProgress from "./RegistrationProgress";
import Loading from "../Loading";

import AddressInfo from "../../Containers/Supplier/AddressInfo";
import InfoPopup from "../../Containers/InfoPopup";
import SaveAndContinueConfirm from "../../Containers/Supplier/SaveAndContinueConfirm";
import KeyContactStaff from "../../Containers/Supplier/KeyContactStaff";
import WizzardBtns from "../../Containers/WizzardBtns";

import validateStep from "../../Validation/onSubmitValidations";
import { requiredFieldsStep2 } from "../../Validation/validation";

import addressTypes from "../../Util/Enumerators/addressTypes";

import { saveAddressInfo, saveKeyStaffContactDetails } from "../../http/posts";

import { setOption } from "../../Store/optionActions";

import StaticData from "../../Hooks/StaticData";
import { StaticDataTypes } from "../../Util/Enumerators/staticDataTypes";

import {
  logOut,
  onStaffContactInfoChange,
  onAddressInfoChange,
  showLoadingScreen,
  hideLoadingScreen,
  showInfoPopup,
  hideInfoPopup,
  setLastStepCompleted,
  setStoreProperty,
} from "../../Store/actions";

import {
  getCompanyDataFeatures,
} from "../../http/index";

import {
  setRegistrationObject,
  onRegistrationInputChange,
  setPostalAddress,
} from "../../Store/registrationActions";
import { loadAddressContactData } from "../../Util/dataLoader";
import { loadAddressContactOptions } from "../../Util/optionsLoader";

const mapStateToProps = (state) => ({
  data: {
    ...state.registration.addressInfo,
    companyId: state.currentCompanyId,
  },
  invitationBuyerCompanyId: state.registration.supplierInfo.invitationBuyerCompanyId,
  contactPersonTypes: state.staticData[StaticDataTypes.ContactPerson],
  provinceOptions: state.staticData[StaticDataTypes.Province] ?? [],
  countryOptions: state.staticData[StaticDataTypes.Country] ?? [],
  municipalityOptions: state.staticData[StaticDataTypes.Municipality] ?? [],
  isStepValid: state.isValidStep2,
});

const mapDispatchToProps = (dispatch) => ({
  onInputChange: (ev) => {
    dispatch(onRegistrationInputChange("addressInfo", ev));
  },
  setAddressInfo: (addressInfo) => {
    dispatch(setRegistrationObject("addressInfo", addressInfo));
  },
  onAddressInputChange: (category, ev) => {
    dispatch(onAddressInfoChange(category, ev));
  },
  setPostalAddress: (physicalAddress) => {
    dispatch(setPostalAddress(physicalAddress));
  },
  onStaffContactInfoChange: (newEntry) => {
    dispatch(onStaffContactInfoChange(newEntry));
  },
  setOption: (optionName, optionValues) => {
    dispatch(setOption(optionName, optionValues));
  },
  showLoadingScreen: (msg) => {
    dispatch(showLoadingScreen(msg));
  },
  hideLoadingScreen: () => {
    dispatch(hideLoadingScreen());
  },
  showInfoMsg: (heading, subheading, msgs) => {
    dispatch(showInfoPopup(heading, subheading, msgs));
  },
  hideInfoMsg: () => {
    dispatch(hideInfoPopup());
  },
  setLastStepCompleted: (value) => {
    dispatch(setLastStepCompleted(value));
  },
  setStepValid: (stepName) => (isValid) => {
    dispatch(setStoreProperty(stepName, isValid));
  },
  logOut: () => {
    dispatch(logOut());
  },
});

class RegistrationStep02 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msgs: {},
      showConfirmMsg: false,
    };
  }

  extractKeyContactStaffDetails = () => {
    const contactPersons = this.props.data.contactPersons.map((
      person
    ) => (person == null || (!person.fullName || !person.email || !person.contactNumber)
      ? null
      : person));

    return contactPersons;
  };

  performSave = (onSuccess = null, stepNumber) => {
    const physical = {
      ...this.props.data.physicalAddress,
      type: addressTypes.Physical,
    };
    const postal = {
      ...this.props.data.postalAddress,
      type: addressTypes.Postal,
    };
    const content = {
      companyId: this.props.data.companyId,
      addresses: [physical, postal],
    };
    this.props.showLoadingScreen("saving data...");
    saveAddressInfo(
      {
        ...content,
        registrationStepCompleted:
          this.props.data.registrationStepCompleted > stepNumber
            ? this.props.data.registrationStepCompleted
            : stepNumber,
      },
      () => {
        const keyContactStaff = this.extractKeyContactStaffDetails().filter(
          (i) => !!i
        );
        saveKeyStaffContactDetails(
          {
            companyId: this.props.data.companyId,
            contactPersons: keyContactStaff,
            registrationStepCompleted:
              this.props.data.registrationStepCompleted > stepNumber
                ? this.props.data.registrationStepCompleted
                : stepNumber,
          },
          () => {
            if (onSuccess) {
              onSuccess();
            }
            this.props.hideInfoMsg();
          },
          () => {
            this.props.hideLoadingScreen();
          }
        );
      },
      () => {
        this.props.hideLoadingScreen();
      }
    );
  };

  onNextClick = async (arg) => {
    const dataFeatures = await getCompanyDataFeatures();
    const validation = validateStep(this.props.data, requiredFieldsStep2);
    const physAdd = this.props.data.physicalAddress;
    const postAdd = this.props.data.postalAddress;
    if (physAdd !== undefined
      && physAdd.hasProvinces === true
      && (physAdd.province <= 0 || physAdd.province === undefined)) {
      validation.isValid = false;
      validation.msgs = {
        ...validation.msgs,
        physicalAddressProvince: "Physical address: province is required",
      };
    }

    if (postAdd !== undefined
      && postAdd.hasProvinces === true
      && (postAdd.province <= 0 || postAdd.province === undefined)) {
      validation.isValid = false;
      validation.msgs = {
        ...validation.msgs,
        postalAddressProvince: "Postal address: province is required",
      };
    }
    if (physAdd !== undefined && (physAdd.hasMunicipalities === true || hasFeature(dataFeatures.dataFeatures, DataFeatures.MandatoryMunicipality))
      && (physAdd.municipality <= 0 || physAdd.municipality === undefined)) {
      validation.isValid = false;
      validation.msgs = {
        ...validation.msgs,
        physicalAddressMunicipality: "Physical address: municipality is required",
      };
    }

    if (postAdd !== undefined && (postAdd.hasMunicipalities === true || hasFeature(dataFeatures.dataFeatures, DataFeatures.MandatoryMunicipality))
      && (postAdd.municipality <= 0 || postAdd.municipality === undefined)) {
      validation.isValid = false;
      validation.msgs = {
        ...validation.msgs,
        postalAddressMunicipality: "Postal address: municipality is required",
      };
    }

    if (physAdd !== undefined
      && physAdd.hasMunicipalities === true
      && hasFeature(dataFeatures.dataFeatures, DataFeatures.MandatoryMunicipality)
      && (physAdd.municipality <= 0 || physAdd.municipality === undefined)) {
      validation.isValid = false;
      validation.msgs = {
        ...validation.msgs,
        physicalAddressMunicipality: "Physical address: municipality is required",
      };
    }

    if (physAdd !== undefined
      && physAdd.hasMunicipalities === true
      && hasFeature(dataFeatures.dataFeatures, DataFeatures.MandatoryMunicipality)
      && (physAdd.municipality <= 0 || physAdd.municipality === undefined)) {
      validation.isValid = false;
      validation.msgs = {
        ...validation.msgs,
        physicalAddressMunicipality: "Physical address: municipality is required",
      };
    }

    if (physAdd !== undefined
      && physAdd.hasMunicipalities === true
      && hasFeature(dataFeatures.dataFeatures, DataFeatures.MandatoryMunicipality)
      && (physAdd.municipality <= 0 || physAdd.municipality === undefined)) {
      validation.isValid = false;
      validation.msgs = {
        ...validation.msgs,
        physicalAddressMunicipality: "Physical address: municipality is required",
      };
    }

    if (
      this.extractKeyContactStaffDetails().length
      !== this.extractKeyContactStaffDetails().filter((i) => !!i).length
    ) {
      validation.msgs.keycontactstaffdetails = "Key contact staff details is required";
      validation.isValid = false;
    }
    this.setState({
      msgs: validation.msgs,
    });
    if (validation.isValid && this.props.isStepValid) {
      this.performSave(() => {
        this.props.history.push(arg);
        this.props.hideLoadingScreen();
      }, 2);
    } else {
      window.scrollTo({ top: 0 });
      this.props.showInfoMsg(
        "This page contains missing information.",
        "To continue please amend/complete the following:",
        Object.keys(validation.msgs).map((key) => validation.msgs[key])
      );
    }
  };

  toggleConfirmSaveAndContinue = () => {
    this.setState((prevState) => ({
      showConfirmMsg: !prevState.showConfirmMsg,
    }));
  };

  saveAndContinueLater = () => {
    this.performSave(() => {
      this.props.logOut();
      this.props.hideLoadingScreen();
    }, 1);
  };

  componentDidMount() {
    loadAddressContactData(this.props, this.props.invitationBuyerCompanyId);
    loadAddressContactOptions(this.props);
  }

  render() {
    return (
      <section id="isuppli-registration-step-02">
        <StaticData
          optionsToLoad={[
            StaticDataTypes.Municipality,
            StaticDataTypes.Province,
            StaticDataTypes.Country,
          ]}
        />
        <Loading />

        <InfoPopup />

        {this.state.showConfirmMsg && (
          <SaveAndContinueConfirm
            toggleModalCallback={this.toggleConfirmSaveAndContinue}
            saveAndContinueCallback={this.saveAndContinueLater}
          />
        )}

        <RegistrationProgress currentStep={2} />

        <div className="padding-lr-10vw">
          <div className="row top-margin-90px">
            <div className="col-12 col-lg-5">
              <div>
                <h3 className="margin-null">STEP 02</h3>
              </div>
              <div>
                <h1>ADDRESS & CONTACT DETAILS</h1>
              </div>
            </div>

            <div
              className="col-12 col-lg-6 justify-center flex-dir-column"
              style={{ paddingTop: "20px" }}
            >
              <h3>
                We need a little bit more information about your business, to
                ensure we know what your company has to offer.
              </h3>
            </div>
          </div>

          <div className="row" style={{ paddingTop: "36px" }}>
            <div className="col-12 col-lg-7">
              <div>
                <Alert color="info">
                  Please fill in the required fields below. All fields are
                  mandatory unless stated otherwise.
                </Alert>
              </div>
            </div>
          </div>

          <AddressInfo
            data={this.props.data}
            additional={this.state.msgs}
            provinces={this.props.provinceOptions}
            countries={this.props.countryOptions}
            municipalities={this.props.municipalityOptions}
            setPostalAddress={this.props.setPostalAddress}
            onInputChangeCallback={this.props.onAddressInputChange}
            setStepValid={this.props.setStepValid("isValidStep2")}
          />
        </div>

        <div className="padding-lr-5vw" style={{ marginTop: "54px" }}>
          <hr />
        </div>

        <div className="padding-lr-10vw">
          <div style={{ paddingTop: "55px" }}>
            <h2 className="Main">KEY CONTACT STAFF DETAILS</h2>
          </div>

          <div className="row">
            <div className="col-12 col-lg-8">
              Please complete contact details for all roles in your
              organisation. If the same person fulfils more than 1 role, you may
              use the &quot;Options&quot; column to select which person&apos;s
              details you want to copy into that role.
            </div>
          </div>

          <div style={{ paddingTop: "36px" }} />

          <KeyContactStaff
            details={this.props.data.contactPersons || []}
            hasTelNumValidation={this.props.data.hasTelNumberValidation}
            onInputChangeCallback={this.props.onStaffContactInfoChange}
          />
        </div>

        <WizzardBtns
          history={this.props.history}
          backUrl="/registration/supinfo"
          nextUrl="/registration/beeinfo"
          nextText="B-BBEE PRACTICES"
          onClickSaveAndContinue={this.toggleConfirmSaveAndContinue}
          onClickNext={this.onNextClick}
        />
      </section>
    );
  }
}

const mappedRegistrationStep02 = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationStep02);
export default withRouter(mappedRegistrationStep02);
