import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  DataFeatures,
  withDataFeatureCheck,
  UtilButton,
  GlobalOfferingSelector,
  GlobalProductsAndServicesList,
} from "isuppli-react-components";

import { withRouter } from "react-router-dom";

import { Alert } from "reactstrap";
import RegistrationProgress from "./RegistrationProgress";
import Loading from "../Loading";

import OfferingSelectionSummary from "../../Containers/Supplier/OfferingSelectionSummary";

import SaveAndContinueConfirm from "../../Containers/Supplier/SaveAndContinueConfirm";
import WizzardBtns from "../../Containers/WizzardBtns";
import InfoPopup from "../../Containers/InfoPopup";

import validateStep from "../../Validation/onSubmitValidations";
import { requiredFieldsStep4 } from "../../Validation/validation";

import { setOption } from "../../Store/optionActions";
import {
  setRegistrationObject,
  onRegistrationInputChange,
  setSupplierCategories,
  setUnspscFamilies,
} from "../../Store/registrationActions";

import { saveProductServiceOffering } from "../../http/posts";

import {
  showLoadingScreen,
  hideLoadingScreen,
  hideInfoPopup,
  showInfoPopup,
  setLastStepCompleted,
  logOut,
} from "../../Store/actions";

import { loadProductServiceOptions } from "../../Util/optionsLoader";

import staticCompanyDataTypes from "../../Util/Enumerators/staticCompanyDataTypes";
import StaticCompanyData from "../../Hooks/StaticCompanyData";

import ClientOfferingSummary from "../../Containers/Supplier/ClientOfferingSummary/ClientOfferingSummary";
import BuyerOfferingSelector from "../../Containers/Supplier/BuyerOfferingSelector/BuyerOfferingSelector";
import { canSupplierEditClientSpecificProductCategories, getClientSupplierCategories } from "../../http/SupplierCategory/supplierCategoryAPI";
import ClientOfferingSummaryDisplay from "../../Containers/Supplier/ClientOfferingSummary/ClientOfferingSummaryDisplay";
import { SearchUsnpscFamilies } from "../../http/Search/searchApi";
import useStaticDataCustom from "../../Hooks/useStaticDataCustom";

const mapStateToProps = (state) => ({
  data: { ...state.registration.prdInfo, companyId: state.currentCompanyId },
  supplierCategories: state.staticCompanyData[staticCompanyDataTypes.supplierCategory] ?? [],
  invitationBuyerCompanyId: state.registration.supplierInfo.invitationBuyerCompanyId,
});

const mapDispatchToProps = (dispatch) => ({
  setOption: (optionName, optionValue) => {
    dispatch(setOption(optionName, optionValue));
  },
  setPrdInfo: (prdInfo) => {
    dispatch(setRegistrationObject("prdInfo", prdInfo));
  },
  onInputChange: (ev) => {
    dispatch(onRegistrationInputChange("prdInfo", ev));
  },
  showLoadingScreen: (msg) => {
    dispatch(showLoadingScreen(msg));
  },
  hideLoadingScreen: () => {
    dispatch(hideLoadingScreen());
  },
  showInfoMsg: (heading, subheading, msgs) => {
    dispatch(showInfoPopup(heading, subheading, msgs));
  },
  hideInfoMsg: () => {
    dispatch(hideInfoPopup());
  },
  setLastStepCompleted: (value) => {
    dispatch(setLastStepCompleted(value));
  },
  logOut: () => {
    dispatch(logOut());
  },
  setSelection: (newSelection) => {
    dispatch(setSupplierCategories(newSelection));
  },
  setGlobalSelection: (newSelection) => {
    dispatch(setUnspscFamilies(newSelection));
  },
});

class RegistrationStep04 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showGlobalProductsModal: false,
      showClientOfferingSelectorModal: false,
      buyerProductCategoriesSelection: [],
      supplierCanEditCategoryData: false,
    };
    this.maxSelection = 3;
  }

  toggleGlobalProductsModal = () => {
    this.setState((prevState) => ({
      showGlobalProductsModal: !prevState.showGlobalProductsModal,
    }));
  };

  toggleClientOfferingSelectorModal = () => {
    this.setState((prevState) => ({
      showClientOfferingSelectorModal: !prevState.showClientOfferingSelectorModal,
    }));
  };

  setBuyerProductSegmentSelection = (selectedProductCategories) => {
    this.setState((prevState) => ({
      ...prevState,
      buyerProductCategoriesSelection: selectedProductCategories,
    }));
  }

  performSave = (onSuccess = null, stepNumber) => {
    this.props.showLoadingScreen("saving data...");
    const productServiceOffering = {
      tradeDescription: this.props.data.tradeDescription,
      selectedUnspscFamilyIds: this.props.data.selectedUnspscFamilyIds,
      registrationStepCompleted:
        this.props.data.registrationStepCompleted > stepNumber
          ? this.props.data.registrationStepCompleted
          : stepNumber,
      notifyNewOpportunities: this.props.data.notifyNewOpportunities,
    };

    saveProductServiceOffering(
      productServiceOffering,
      () => {
        if (onSuccess) {
          onSuccess();
        }
        this.props.hideInfoMsg();
      },
      () => {
        this.props.hideLoadingScreen();
      }
    );
  };

  onNextClick = (arg) => {
    const validation = validateStep(this.props.data, requiredFieldsStep4);

    if (validation.isValid) {
      this.performSave(() => {
        this.props.history.push(arg);
        this.props.hideLoadingScreen();
      }, 4);
    } else {
      window.scrollTo({ top: 0 });
      this.props.showInfoMsg(
        "This page contains missing information.",
        "To continue please amend/complete the following:",
        Object.keys(validation.msgs).map((key) => validation.msgs[key])
      );
    }
  };

  toggleConfirmSaveAndContinue = () => {
    this.setState((prevState) => ({
      showConfirmMsg: !prevState.showConfirmMsg,
    }));
  };

  saveAndContinueLater = () => {
    this.performSave(() => {
      this.props.logOut();
      this.props.hideLoadingScreen();
    }, 3);
  };

  async componentDidMount() {
    loadProductServiceOptions(
      {
        ...this.props,
      }
    );

    const loadClientSupplierCategoriesData = async () => {
      const categorySelectionData = await getClientSupplierCategories(
        this.props.invitationBuyerCompanyId ?? 0
      );

      const supplierCanEditCategories = await
      canSupplierEditClientSpecificProductCategories(
        this.props.invitationBuyerCompanyId ?? 0
      );

      this.setState((prevState) => ({
        ...prevState,
        buyerProductCategoriesSelection: categorySelectionData,
        supplierCanEditCategories,
      }));
    };

    loadClientSupplierCategoriesData();
  }

  render() {
    return (
      <section id="isuppli-registration-step-04">
        <StaticCompanyData optionsToLoad={[staticCompanyDataTypes.supplierCategory]} />

        <Loading />

        <InfoPopup />

        {this.state.showConfirmMsg && (
          <SaveAndContinueConfirm
            toggleModalCallback={this.toggleConfirmSaveAndContinue}
            saveAndContinueCallback={this.saveAndContinueLater}
          />
        )}

        {this.state.showGlobalProductsModal && (
          <GlobalOfferingSelector
            setSelection={this.props.setGlobalSelection}
            selection={this.props.data.selectedUnspscFamilyIds ?? []}
            onCloseCallback={this.toggleGlobalProductsModal}
            onSearchUsnpscFamiliesCallback={SearchUsnpscFamilies}
            staticDataCallback={useStaticDataCustom}
          />
        )}

        <RegistrationProgress currentStep={4} />

        <div className="padding-lr-10vw">
          <div className="row top-margin-90px">
            <div className="col-12 col-lg-5">
              <div>
                <h3 className="margin-null">STEP 04</h3>
              </div>
              <div>
                <h1>PRODUCT OFFERINGS</h1>
              </div>
            </div>

            <div
              className="col-12 col-lg-5 justify-center flex-dir-column"
              style={{ paddingTop: "20px" }}
            >
              <h3>
                We need a little bit more information about your business, to
                ensure we know what your company has to offer.
              </h3>
            </div>
          </div>

          <div className="row" style={{ paddingTop: "36px" }}>
            <div className="col-12 col-lg-9">
              <div>
                <Alert color="info">
                  We need you to define your product offerings and services and
                  how you differ from other suppliers.
                </Alert>
              </div>
            </div>
          </div>

          <div className="mt-3" />

          <div>
            <h2 className="Main">GLOBAL PRODUCT OFFERINGS</h2>
          </div>

          <div className="mt-3" />

          <div>
            <div className="col-12 col-xl-5">
              <GlobalProductsAndServicesList
                selectedUnspscFamilyIds={this.props.data.selectedUnspscFamilyIds}
                getStaticDataCallback={useStaticDataCustom}
              />
              <UtilButton
                color="primary"
                onClick={() => this.toggleGlobalProductsModal()}
              >
                SELECT PRODUCTS AND/OR SERVICES
              </UtilButton>
            </div>
          </div>

          <div className="mt-5" />

          <OfferingSelectionSummary
            data={this.props.data}
            onInputChangeCallback={this.props.onInputChange}
          />

          {this.props.invitationBuyerCompanyId > 0
            && (
              this.state.supplierCanEditCategories
                ? (
                  <Fragment>
                    <div className="mt-5">
                      <h2 className="Main">CLIENT PRODUCT OFFERINGS</h2>
                    </div>
                    {this.state.showClientOfferingSelectorModal && (
                      <BuyerOfferingSelector
                        buyerCompanyId={this.props.invitationBuyerCompanyId}
                        selection={this.state.buyerProductCategoriesSelection ?? []}
                        setSelection={this.setBuyerProductSegmentSelection}
                        onCloseCallback={this.toggleClientOfferingSelectorModal}
                      />
                    )}

                    <ClientOfferingSummaryDisplay
                      clientProductOfferings={this.state.buyerProductCategoriesSelection ?? []}
                    />

                    <UtilButton
                      color="primary"
                      onClick={this.toggleClientOfferingSelectorModal}
                    >
                      EDIT CLIENT PRODUCT OFFERINGS
                    </UtilButton>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="mt-5">
                      <h2 className="Main">CLIENT PRODUCT OFFERINGS</h2>
                    </div>
                    <div className="mt-3" />
                    <ClientOfferingSummary
                      buyerCompanyId={this.props.invitationBuyerCompanyId}
                    />
                  </Fragment>
                )
            )}
        </div>

        <WizzardBtns
          history={this.props.history}
          backUrl="/registration/beeinfo"
          nextUrl={this.props.dataFeatureCheck(DataFeatures.BankingDetails)
            ? "/registration/bnkinfo"
            : "/registration/documents"}
          nextText={this.props.dataFeatureCheck(DataFeatures.BankingDetails)
            ? "BANK DETAILS"
            : "SUPPORTING DOCUMENTS"}
          onClickSaveAndContinue={this.toggleConfirmSaveAndContinue}
          onClickNext={this.onNextClick}
        />

        <div style={{ paddingTop: "44px" }} />
      </section>
    );
  }
}

const mappedRegistrationStep04 = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationStep04);
export default withDataFeatureCheck(withRouter(mappedRegistrationStep04));
