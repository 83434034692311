import React from "react";

function UtilButton({
  disabled = false,
  background,
  size = null,
  location,
  className = "",
  text,
  actionArg = null,
  actionCallback,
}) {
  const isEnabled = !disabled;
  const backgroundToUse = isEnabled ? background : "greyed";
  const sizeClassName = size ? `btn-util-${size}` : "";
  const bgClass = `clickable justify-center btn-util${
    location ? `-${location}` : ""
  } btn-util bg-${backgroundToUse} ${backgroundToUse}-text border-${backgroundToUse} align-center ${className} ${sizeClassName}`;
  return (
    <div
      className={bgClass}
      onClick={() => {
        if (isEnabled) {
          actionCallback(actionArg);
        }
      }}
    >
      {text}
    </div>
  );
}

export default UtilButton;
