const staticCompanyDataTypes = {
  supplierCategory: 1,
  roles: 2,
  supplierType: 3,
  contactPersonType: 4,
  businessUnit: 5,
  documentTypes: 6,
  paymentTerms: 7,
  businessTypes: 8,
  supplierTransactionStatuses: 9,
  supportingDocuments: 10,
};

export enum StaticCompanyDataTypes {
  supplierCategory = 1, // eslint-disable-line no-unused-vars
  roles = 2, // eslint-disable-line no-unused-vars
  supplierType = 3, // eslint-disable-line no-unused-vars
  contactPersonType = 4, // eslint-disable-line no-unused-vars
  businessUnit = 5, // eslint-disable-line no-unused-vars
  documentTypes = 6, // eslint-disable-line no-unused-vars
  paymentTerms = 7, // eslint-disable-line no-unused-vars
  businessTypes = 8, // eslint-disable-line no-unused-vars
  supplierTransactionStatuses = 9, // eslint-disable-line no-unused-vars
  supportingDocuments = 10, // eslint-disable-line no-unused-vars
  // not part of the API, but used internally
  contractTiers = 999, // eslint-disable-line no-unused-vars
}

export default staticCompanyDataTypes;
