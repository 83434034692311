import {
  ShadowCard, Spinner, SystemFeatures, WithFeature,
} from "isuppli-react-components";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Badge } from "reactstrap";
import BeeStatusBadge from "../../../Containers/Supplier/BeeStatusBadge";
// eslint-disable-next-line no-unused-vars
import { ReduxState } from "../../../Store/ReduxState";
import ComplianceStatus, { complianceStatusToString, getComplianceStatusBadgeColor } from "../../../Util/Enumerators/ComplianceStatus";
import Styles from "./ProfileStatusSummary.module.scss";
import { loadBeePracticeData } from "../../../Util/dataLoader";
// eslint-disable-next-line no-unused-vars
import { BeeRatingMessage } from "../../../http/ManualSupplier/Messages/Messages";
import {
  getCompanyCompliance,
} from "../../../http/Compliance/complianceApi";
import { getOnboardingRequestExceptionReason } from "../../../http/gets";

import useShowError from "../../../Hooks/useShowError";
import {
  // eslint-disable-next-line no-unused-vars
  DetailsDisplay,
  getFormattedComplianceDetails,
} from "../../../Util/complianceFormatter";
// eslint-disable-next-line no-unused-vars
import { OnboardingComplianceExceptionMessage } from "../../../http/Compliance/Models/Models";
import { checkHttpStatus } from "../../../http/httpHelpers";

const ProfileStatusSummary = () => {
  const [checkDetails, setCheckDetails] = useState<DetailsDisplay>();
  const [beeData, setBeeData] = useState<BeeRatingMessage>({} as BeeRatingMessage);
  const [loading, setLoading] = useState(false);
  const showError = useShowError();
  const userDetails = useSelector(
    (state: ReduxState) => state.loggedInUserDetails
  );
  useEffect(() => {
    const loader = async () => {
      try {
        await loadBeePracticeData({ data: userDetails, setBeeInfo: setBeeData });

        const details = await getCompanyCompliance(userDetails.companyId);
        const exceptionReason: OnboardingComplianceExceptionMessage = await
        getOnboardingRequestExceptionReason(userDetails.companyId);

        const formattedDetails = getFormattedComplianceDetails(details, exceptionReason);
        setCheckDetails(formattedDetails);
      } catch (getError) {
        if (checkHttpStatus(getError, 404)) {
          setCheckDetails({
            complianceCheckId: userDetails.companyId,
            company: {
              registeredName: "",
              registrationNumber: "",
              vatNumber: "",
              externalVendorId: "",
              registrationType: 0,
            },
            complianceProvider: 0,
            status: ComplianceStatus.AwaitingResults,
            complianceResults: [],
            error: "No compliance details available",
          });
          return;
        }
        showError();
      }
    };
    setLoading(true);
    loader();
    setLoading(false);
  }, [userDetails, showError]);
  return (
    <Fragment>
      <ShadowCard>
        <div className={`p-3 d-flex flex-column ${Styles.height}`}>
          <div className="mb-2">
            <p>Profile Status</p>
          </div>
          {
            loading ? <Spinner inline /> : (
              <Fragment>
                {/* Completion status */}
                <div className="row">
                  <div className="col-6">
                    <h3>
                      Completion:
                      {" "}
                      <b>100%</b>
                    </h3>
                  </div>
                </div>
                {/* Company BEE & Regulatory Compliance */}
                <WithFeature feature={SystemFeatures.DashboardOwnCompanyComplianceSummaries}>
                  <div className="row">
                    <div className="col-6">
                      <BeeStatusBadge
                        size="small"
                        hasBeeCertificate={
                            beeData.hasBeeCertificate as unknown as boolean ?? false
                        }
                        validationStatus={beeData.validationStatus}
                      />
                    </div>
                    <div className="col-6">
                      <h3>
                        Compliance
                        {" "}
                        <Badge
                          className="ml-2"
                          color={getComplianceStatusBadgeColor(checkDetails?.status
                            ?? ComplianceStatus.AwaitingResults)}
                        >
                          {complianceStatusToString(checkDetails?.status
                            ?? ComplianceStatus.AwaitingResults)}
                        </Badge>
                      </h3>
                    </div>
                  </div>
                </WithFeature>
              </Fragment>
            )
          }
        </div>
      </ShadowCard>
    </Fragment>
  );
};

export default ProfileStatusSummary;
