import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ConfirmationModal } from "isuppli-react-components";
import { useLocation } from "react-router";
import { getUserBasicDetails } from "../../http/index";
import { clearNotAuthenticated } from "../../Store/actions";
// eslint-disable-next-line no-unused-vars
import { ReduxState } from "../../Store/ReduxState";

const NotAuthenticatedHandler = ({ children }: {children: React.ReactChildren}) => {
  const authenticationState = useSelector((state: ReduxState) => state.authentication);
  const dispatch = useDispatch();
  const location = useLocation();
  const userBasicDetails = useSelector((state: ReduxState) => state.loggedInUserDetails);
  if (location.pathname === "/re-authenticate-callback") {
    window.close();
    window.opener.reAuthenticateCallback();
  }

  useEffect(() => {
    // setup child view callback
    (window as any).reAuthenticateCallback = async () => {
      // check if company has changed
      const newUserDetails = await getUserBasicDetails();
      if (userBasicDetails.companyId !== newUserDetails.companyId) {
        window.location.reload();
      } else {
        authenticationState.retryHandlers.forEach((retry) => {
          retry();
        });
      }

      dispatch(clearNotAuthenticated());
    };
  }, [authenticationState, dispatch, userBasicDetails.companyId]);
  return (
    <Fragment>
      {!authenticationState.isAuthenticated && (
        <ConfirmationModal
          hasCancel={false}
          image="error"
          heading="Session Expired"
          description="You have been automatically logged out due to inactivity. Do you want to log in again?"
          proceedButtonText="Log In"
          onToggleModal={() => { }}
          onProceedClick={() => {
            window.open("Account/ReAuthenticate", "_blank", "width=450, height=700");
          }}
        />
      )}
      {authenticationState.forceLogout && (
        <ConfirmationModal
          image="error"
          heading="Failed to log in"
          description="Something went wrong while trying to restore the session. Your session is closed."
          proceedButtonText="Back to home"
          onToggleModal={() => { }}
          onProceedClick={() => {
            window.location.href = "/account/logout";
          }}
        />
      )}
      {children}
    </Fragment>

  );
};

export default NotAuthenticatedHandler;
