import { ShadowCard, Spinner } from "isuppli-react-components";
import React, { Fragment, useEffect, useState } from "react";
import useShowError from "../../../Hooks/useShowError";
import { getDashboardSupplierStatusSummary } from "../../../http/Dashboard/dashboardApi";
// eslint-disable-next-line no-unused-vars
import { DashboardSupplierStatusSummary } from "../../../http/Dashboard/Models/Models";
// eslint-disable-next-line no-unused-vars
import DashboardSummaryCard, { DashboardSummaryItem } from "../DashboardSummaryItem/DashboardSummaryItem";

const SupplierStatusSummary = () => {
  const [SupplierPackagesSummaryData,
    setSupplierStatusSummaryData] = useState<DashboardSupplierStatusSummary>();
  const showError = useShowError();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loader = async () => {
      setLoading(true);
      try {
        // api calls
        const dashboardSupplierStatusSummaryData = await getDashboardSupplierStatusSummary();
        setSupplierStatusSummaryData(dashboardSupplierStatusSummaryData);
      } catch (error) {
        showError();
      }
      setLoading(false);
    };
    loader();
  }, [showError]);

  const SupplierStatus = SupplierPackagesSummaryData?.items?.map<DashboardSummaryItem>((i) => ({
    key: i.name,
    title: i.name,
    value: i.count,
    linkUrl: `/marketplace/search?f=[[],[],[],[],[],[],null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,${i.status}]&sf=1`,
  }) as DashboardSummaryItem) ?? [];

  return (
    <Fragment>
      {/* Suppliers */}
      <div className="row mt-4">
        <div className="col-12">
          <h2>MY SUPPLIERS BY STATUS</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <ShadowCard>

            {loading ? <Spinner inline /> : (
              <div className="row">
                <DashboardSummaryCard
                  heading=""
                  className="col-lg-12"
                  summaryItems={SupplierStatus}
                />
              </div>
            )}

          </ShadowCard>
        </div>
      </div>
    </Fragment>
  );
};

export default SupplierStatusSummary;
