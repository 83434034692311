import React, { Component } from "react";

import ValidationError from "./ValidationError/ValidationError";

class RadioSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msgs: [],
    };
    this.showError = true;
  }

  onOptionClick = (value) => {
    this.showError = false;
    const ev = {
      target: {
        name: this.props.name,
        value,
      },
    };
    this.props.onChangeCallback(ev);
  };

  render() {
    const btns = this.props.options.map((option, i) => {
      const isChecked = option.value === this.props.value;
      return (
        <div key={i} className="inline-block">
          <label className={`radio-container ${this.props.disabled ? "disabled" : ""}`}>
            <input
              disabled={this.props.disabled}
              type="radio"
              readOnly
              checked={`${isChecked ? "checked" : ""}`}
              onClick={() => this.onOptionClick(option.value)}
            />
            <span className="radio-checkmark" />
            {option.display}
          </label>
        </div>
      );
    });
    const additional = this.state.msgs.map((msg, i) => (
      <label key={i}>{msg}</label>
    ));
    return (
      <section className={this.props.className}>
        <div>
          <label>{this.props.label}</label>
        </div>
        <div
          className="flex-between"
          style={{ width: `${50 * this.props.options.length}px` }}
        >
          {btns}
        </div>
        {!!this.props.additional
          && this.props.additional.length > 0
          && this.showError && (
          <ValidationError>
            <label className="validation-label">
              {this.props.additional}
            </label>
          </ValidationError>
        )}
        {additional.length !== 0 && (
          <ValidationError>{additional}</ValidationError>
        )}
      </section>
    );
  }
}

export default RadioSelector;
