import React from "react";
import { ConfirmationModal } from "isuppli-react-components";

function SaveAndContinueConfirm(props) {
  return (
    <ConfirmationModal
      size="large"
      heading="Save & Continue later"
      description="You are about to save your progress but you can come back at any
          time by logging into your profile to complete your company
          registration."
      onProceedClick={props.saveAndContinueCallback}
      proceedButtonText="OK"
      hasCancel
      onToggleModal={props.toggleModalCallback}
    />
  );
}

export default SaveAndContinueConfirm;
