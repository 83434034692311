import React from "react";

const PPReport = () => (
  <iframe
    frameBorder={0}
    allowFullScreen
    className="w-100 h-100 position-absolute"
    src="/Redirect/PPReport"
    title="PP Report"
  />
);

export default PPReport;
