import React from "react";
import Styles from "./ChatMessageHeader.module.scss";

export default function ChatMessageHeader(
  {
    companyName = "",
  } :
    {
        companyName:string;
    }
) {
  return (
    <div className={Styles.headerContent}>
      <div className={Styles.currentChannelDetails}>
        <div className={Styles.companyName}>{companyName}</div>
      </div>
    </div>
  );
}
