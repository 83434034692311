import {
  useState,
  // eslint-disable-next-line no-unused-vars
  Dispatch,
  // eslint-disable-next-line no-unused-vars
  SetStateAction,
} from "react";
// eslint-disable-next-line no-unused-vars
import SystemFeatures, { hasFeature } from "../utils/enums/SystemFeatures";
// eslint-disable-next-line no-unused-vars
import { SubscriptionAllowedFeatures } from "../utils/interfaces/SubscriptionAllowedFeatures";

let setShowError: Dispatch<SetStateAction<boolean>> | undefined;
let setRequiredSystemFeature: Dispatch<SetStateAction<SystemFeatures>> | undefined;
let subscriptionFeaturesInfo: Array<SubscriptionAllowedFeatures> = [];

export const useShouldShowNotAllowedError = () => {
  const [currentValue, changeHandler] = useState(false);
  const [currentRequiredFeature, changeRequiredFeaturedHandler] = useState(SystemFeatures.None);
  setShowError = changeHandler;
  setRequiredSystemFeature = changeRequiredFeaturedHandler;

  const sortedSubscriptions = subscriptionFeaturesInfo?.sort(
    (a, b) => a.displayOrder - b.displayOrder);

  const minimumRequiredSubscription = sortedSubscriptions?.find(
    // eslint-disable-next-line no-undef
    (f) => hasFeature(BigInt(f.allowedFeatures), currentRequiredFeature)
  );
  return { currentValue, minimumRequiredSubscription };
};

export const useHideNotAllowedError = () => () => {
  if (setShowError == null) {
    return;
  }
  setShowError(false);
};

export const useShowNotAllowedError = () => (requiredSystemFeature: SystemFeatures | undefined) => {
  if (setShowError == null) {
    return;
  }
  setShowError(true);
  if (setRequiredSystemFeature != null) {
    requiredSystemFeature == null
      ? setRequiredSystemFeature(SystemFeatures.None)
      : setRequiredSystemFeature(requiredSystemFeature);
  }
};

export const setupSubscriptionFeaturesInfo = (newSubscriptionsFeaturesInfo:
  Array<SubscriptionAllowedFeatures>) => {
  subscriptionFeaturesInfo = newSubscriptionsFeaturesInfo;
};
