import React, { Component } from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import { Alert } from "reactstrap";
import { ConfirmationModal, DataFeatures, hasFeature } from "isuppli-react-components";
import DuplicateRegistrationModal from "./DuplicateRegistrationModal";

import RegistrationProgress from "./RegistrationProgress";
import OnboardingInvitationDetails from "./OnboardInvitationDetails";
import Loading from "../Loading";

import GeneralBusinessInformation from "../../Containers/Supplier/GeneralBusinessInformation";
import InfoPopup from "../../Containers/InfoPopup";
import SaveAndContinueConfirm from "../../Containers/Supplier/SaveAndContinueConfirm";
import RegistrationCompanyInfo from "../../Containers/Supplier/RegistrationCompanyInfo";
import WizzardBtns from "../../Containers/WizzardBtns";

import validateStep from "../../Validation/onSubmitValidations";
import {
  requiredFieldsStep1, isEmailInvalid, validIdNumber,
} from "../../Validation/validation";
import { rejectOnboardingRequest } from "../../http/puts";
import { saveSupplierInfo, saveSupplierInfoAsync, getSupplierInfoMatches } from "../../http/posts";

import {
  setRegistrationObject,
  onRegistrationInputChange,
} from "../../Store/registrationActions";
import { setOption } from "../../Store/optionActions";
import {
  logOut,
  showLoadingScreen,
  hideLoadingScreen,
  setStoreProperty,
  showInfoPopup,
  hideInfoPopup,
  setLastStepCompleted,
  setUserBasicDetails,
  setDataFeatures,
} from "../../Store/actions";

import { loadSupplierInfoOptions } from "../../Util/optionsLoader";

import {
  getSupplierInfoAsync,
  getUserBasicDetails,
  getOnboardingInvitation,
  refreshToken,
  getCompanyDataFeatures,
  getCompanyOptionsAsync,
} from "../../http/index";
import { onCompanyTypeChangeHandler } from "../../Validation/onChangeValidations";
import { saveShareholders } from "../../http/Shareholders/ShareholderApi";
import staticCompanyDataTypes from "../../Util/Enumerators/staticCompanyDataTypes";
import { objectToDisplayObject } from "../../Hooks/useStaticCompanyData";

const mapStateToProps = (state) => ({
  data: {
    ...state.registration.supplierInfo,
    companyId: state.currentCompanyId,
  },
  isStepValid: state.isValidStep1,
  saveAndContinue: false,
  isVATNumberValid: state.isVATNumberValid,
  companyTypes: state.options.companyTypes,
  businessTypes: state.options.businessTypes,
});

const mapDispatchToProps = (dispatch) => ({
  onInputChange: (ev) => {
    dispatch(onRegistrationInputChange("supplierInfo", ev));
  },
  setSupplierInfo: (supplierInfo) => {
    dispatch(setRegistrationObject("supplierInfo", supplierInfo));
  },
  showLoadingScreen: (msg) => {
    dispatch(showLoadingScreen(msg));
  },
  hideLoadingScreen: () => {
    dispatch(hideLoadingScreen());
  },
  setOption: (optionName, optionValue) => {
    dispatch(setOption(optionName, optionValue));
  },
  setUserBasicDetails: (userBasicDetails) => {
    dispatch(setUserBasicDetails(userBasicDetails));
  },
  showInfoMsg: (heading, subheading, msgs) => {
    dispatch(showInfoPopup(heading, subheading, msgs));
  },
  hideInfoMsg: () => {
    dispatch(hideInfoPopup());
  },
  setLastStepCompleted: (value) => {
    dispatch(setLastStepCompleted(value));
  },
  setStepValid: (stepName) => (isValid) => {
    dispatch(setStoreProperty(stepName, isValid));
  },
  setDataFeatures: (dataFeatures) => {
    dispatch(setDataFeatures(dataFeatures));
  },
  logOut: () => {
    dispatch(logOut());
  },
});

class RegistrationStep01 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msgs: {},
      showConfirmMsg: false,
      showDuplicatesMsg: false,
      duplicates: [],
      showOnboardingInvitationModal: false,
      showOnboardingInvitationAcceptedModal: false,
      saveAndContinueLater: false,
      invitation: {},
      buyerOptions: {},
    };
  }

  saveSupplierInfoWithoutDuplicatesCheck = async (supplierDetailsToSave, onSuccess) => {
    await saveSupplierInfoAsync({
      ...supplierDetailsToSave,
    });
    onSuccess(true);
  }

  performSave = (onSuccess = null, stepNumber, checkDuplicates) => {
    this.props.showLoadingScreen("saving data...");
    const supplierDetailsToSave = {
      ...this.props.data,
      vatNumber:
        this.props.data.hasVatNumber === 1 ? this.props.data.vatNumber : null,
      registrationStepCompleted:
        this.props.data.registrationStepCompleted > stepNumber
          ? this.props.data.registrationStepCompleted
          : stepNumber,
    };
    const shareholders = this.props.data.shareholders?.shareholderDetails ?? [];
    if (!checkDuplicates) {
      this.saveSupplierInfoWithoutDuplicatesCheck(supplierDetailsToSave, onSuccess);
    } else {
      this.getDuplicates((res) => {
        const duplicatesFound = res.length > 0;
        const saveInfo = duplicatesFound
          ? {
            ...supplierDetailsToSave,
            registrationNumber: null,
            registrationStepCompleted: 0,
          } : supplierDetailsToSave;

        // save data
        saveSupplierInfo(
          {
            ...saveInfo,
          },
          async () => {
            await saveShareholders(shareholders);

            // update user basic details after saving step 1
            if (stepNumber === 1) {
              const basicUserDetails = await getUserBasicDetails(true);
              await refreshToken();

              // eslint-disable-next-line no-undef
              this.props.setUserBasicDetails({ ...basicUserDetails, allowedFeatures: BigInt(basicUserDetails.allowedFeaturesString) });
              this.props.hideInfoMsg();
            } else {
              this.props.hideInfoMsg();
            }
            if (duplicatesFound) { onSuccess(false); } else {
              onSuccess(true);
            }
          },
          () => {
            this.props.hideLoadingScreen();
          }
        );
        // show modal if duplicates found
        if (duplicatesFound) { this.showDuplicateOptions(); }
      });
    }
  };

  getDuplicates = (onSuccess = null) => {
    getSupplierInfoMatches(
      {
        companyType: this.props.data.companyType,
        registrationNumber: this.props.data.registrationNumber,
      },
      (res) => {
        const duplicatesFound = res[0] == null ? []
          : res.filter((c) => c.companyId !== this.props.data.companyId);

        this.setState({ duplicates: duplicatesFound });
        onSuccess(duplicatesFound);
      },
      () => null
    );
  };

  onNextClick = async (arg) => {
    const dataFeatures = await getCompanyDataFeatures();
    let validation = validateStep(this.props.data, requiredFieldsStep1);

    if (this.props.data.vatExempted !== true) {
      // vat number
      if (this.props.data.hasVatNumber != null) {
        if (Number(this.props.data.hasVatNumber) === 1) {
          if (!this.props.data.vatNumber) {
            validation.isValid = false;
            validation.msgs = {
              ...validation.msgs,
              vatNumber: "VAT number is required",
            };
          } else if (!this.props.isVATNumberValid) {
            validation.isValid = false;
            validation.msgs = {
              ...validation.msgs,
              vatNumber: "VAT number is invalid",
            };
          }
        }
      }
    }

    // shareholders
    const shareholders = this.props.data.shareholders?.shareholderDetails ?? [];

    if (shareholders.length > 0) {
      let totalSharePercentageCount = 0;
      shareholders.forEach((sh) => {
        totalSharePercentageCount += sh.sharePercentage ?? 0;
      });
      let allShareholdersHaveDocs = false;
      allShareholdersHaveDocs = shareholders.every((shm) => shm.shareholderIdentityDocumentMessage
                                                  && shm.shareholderIdentityDocumentMessage.filename !== null);

      if (totalSharePercentageCount !== 100) {
        validation.isValid = false;
        validation.msgs = {
          ...validation.msgs,
          shareholders: "Shareholders' cumilative shares % must equal 100",
        };
      }

      if (allShareholdersHaveDocs === false) {
        validation.isValid = false;
        validation.msgs = {
          ...validation.msgs,
          shareholders: "All shareholders require Identity/Registration documents",
        };
      }
    } else if (hasFeature(dataFeatures.dataFeatures, DataFeatures.ShareholdersDetails)) {
      // if company has ShareholdersDetails feature,
      // shareholder details will be mandatory
      validation.isValid = false;
      validation.msgs = {
        ...validation.msgs,
        shareholders: "Please include a shareholder",
      };
    }

    if (hasFeature(dataFeatures.dataFeatures, DataFeatures.AdditionalCompanyData)) {
      // royalbafokeng
      const royalbafokeng = this.props.data.additionalCompanyData?.royalBafokengNationalResident ?? null;

      if (royalbafokeng === null) {
        validation.isValid = false;
        validation.msgs = {
          ...validation.msgs,
          royalBafokengNationalResident: "Please indicate whether the company is a resident of the Royal Bafokeng Nation.",
          numberOfEmployees: "Please indicate the Number of employees",
        };
      }
    }

    if (hasFeature(dataFeatures.dataFeatures, DataFeatures.BusinessRegions)) {
      const validBusinessRegions = this.props.data.businessRegionProvinceIds?.length > 0;
      if (!validBusinessRegions) {
        validation.isValid = false;
        validation.msgs = {
          ...validation.msgs,
          businessRegions: "Please indicate the Geographical Region(s) for conducting your primary business",
        };
      }
    }

    if (hasFeature(dataFeatures.dataFeatures, DataFeatures.BusinessTypes)) {
      const validBusinessType = this.props.data.businessTypeId > 0;
      if (!validBusinessType) {
        validation.isValid = false;
        validation.msgs = {
          ...validation.msgs,
          businessType: "Please select a valid business type",
        };
      }
    }

    if (isEmailInvalid(this.props.data.generalEmail)) {
      validation.isValid = false;
      validation.msgs = {
        ...validation.msgs,
        generalEmail: "General business email address is invalid",
      };
    }

    if (this.props.data.requiredIdNumValidation === true
      && !validIdNumber(this.props.data.registrationNumber)) {
      validation.isValid = false;
      validation.msgs = {
        ...validation.msgs,
        registrationNumber: "South African ID Number is invalid",
      };
    }

    if (this.props.data.companyType <= 0) {
      validation = {
        isValid: false,
        msgs: {
          ...validation.msgs,
          companyType: "Company type is required",
        },
      };
    }

    if (hasFeature(dataFeatures.dataFeatures, DataFeatures.MandatoryTaxClearancePinNumber)) {
      if (this.props.data.taxClearanceNumber === null) {
        validation.isValid = false;
        validation.msgs = {
          ...validation.msgs,
          taxClearanceNumber: "Tax Clearance Number is required",
        };
      }
      if (this.props.data.taxClearanceNumberExpiryDate === null) {
        validation.isValid = false;
        validation.msgs = {
          ...validation.msgs,
          taxClearanceNumberExpiryDate: "Tax Clearance Expiry Date is required",
        };
      }
    }

    // workman compensation
    if (this.props.data.additionalCompanyData?.workMan) {
      if ((this.props.data.additionalCompanyData?.workManCode?.length ?? 0) === 0) {
        validation.isValid = false;
        validation.msgs = {
          ...validation.msgs,
          workManCode: "Workman compensation certificate number is required.",
        };
      }
      if (new Date(this.props.data.additionalCompanyData?.workManExpiredDate) < new Date("2000-01-01")) { // bad way to check if date is empty
        validation.isValid = false;
        validation.msgs = {
          ...validation.msgs,
          workManExpiredDate: "Workman compensation expiry date is required.",
        };
      }
    }

    this.setState({
      msgs: validation.msgs,
    });

    if (validation.isValid && this.props.isStepValid) {
      this.performSave((onSuccess = null) => {
        if (onSuccess) {
          this.props.history.push(arg);
        }
        this.props.hideLoadingScreen();
      }, 1, true);
    } else {
      window.scrollTo({ top: 0 });
      this.props.showInfoMsg(
        "This page contains missing information.",
        "To continue please amend/complete the following:",
        Object.keys(validation.msgs).map((key) => validation.msgs[key])
      );
    }
  };

  toggleConfirmSaveAndContinue = () => {
    this.setState((prevSate) => ({
      showConfirmMsg: !prevSate.showConfirmMsg,
    }));
  };

  saveAndContinueLater = () => {
    this.setState(() => ({
      saveAndContinueLater: true,
    }));
    this.performSave((onSuccess = null) => {
      if (onSuccess) {
        this.props.logOut();
      }
      this.props.hideLoadingScreen();
    }, 0, false);
  };

  toggleDuplicates = () => {
    this.setState((prevSate) => ({
      showDuplicatesMsg: !prevSate.showDuplicatesMsg,
    }));
  };

  invitationModalOnCloseHandler() {
    this.setState({
      showOnboardingInvitationModal: false,
    });
  }

  invitationAcceptedModalOnCloseHandler() {
    this.setState({
      showOnboardingInvitationAcceptedModal: false,
    });
  }

  async onRejectOnboardingInvitation() {
    this.props.showLoadingScreen();
    try {
      await rejectOnboardingRequest(this.state.invitation.invitationId);
      const dataFeatures = await getCompanyDataFeatures();
      this.props.setDataFeatures(dataFeatures.dataFeatures);
    } catch (error) {
      this.props.hideLoadingScreen();
      throw error;
    }
    this.props.hideLoadingScreen();
  }

  onAcceptOnboardingInvitation() {
    // populate fields from invitation
    if (this.state.invitation.registeredName && !this.props.data.disableEditRegistrationInfo) {
      this.props.onInputChange({ target: { name: "registeredName", value: this.state.invitation.registeredName } });
    }

    if (this.state.invitation.tradingName) {
      this.props.onInputChange({ target: { name: "tradingName", value: this.state.invitation.tradingName } });
    }

    if (this.state.invitation.registrationNumber && !this.props.data.disableEditRegistrationInfo) {
      this.props.onInputChange({
        target: {
          name: "registrationNumber",
          value: this.state.invitation.registrationNumber,
        },
      });
    }

    if (this.state.invitation.vatNumber) {
      this.props.onInputChange({
        target: { name: "vatNumber", value: this.state.invitation.vatNumber },
      });
      this.props.onInputChange({ target: { name: "hasVatNumber", value: 1 } });
    }

    if (this.state.invitation.generalEmail) {
      this.props.onInputChange({
        target: {
          name: "generalEmail",
          value: this.state.invitation.generalEmail,
        },
      });
    }

    if (this.state.invitation.companyTypeId && !this.props.data.disableEditRegistrationInfo) {
      this.props.onInputChange({
        target: {
          name: "companyType",
          value: this.state.invitation.companyTypeId,
        },
      });
      // Populate vat exempted
      onCompanyTypeChangeHandler({ target: { value: this.state.invitation.companyTypeId } },
        this.props.companyTypes, this.props.onInputChange, false);
    }

    // store invitation state to send when saving
    this.props.onInputChange({
      target: {
        name: "onboadingRequestInvitationId",
        value: this.state.invitation.invitationId,
      },
    });

    // set buyer company ID to use throughout registration
    this.props.onInputChange({
      target: {
        name: "invitationBuyerCompanyId",
        value: this.state.invitation.buyerCompanyId,
      },
    });
  }

  showDuplicateOptions = () => {
    this.toggleDuplicates();
    this.props.hideLoadingScreen();
  };

  setNewDuplicates = (duplicates) => {
    this.setState({
      duplicates,
    });
  };

  saveDuplicateOptions = (arg) => {
    this.performSave(() => {
      this.props.history.push(arg);
      this.props.hideLoadingScreen();
    });
  };

  async componentDidMount() {
    this.props.showLoadingScreen("Loading data...");
    await loadSupplierInfoOptions(this.props);

    const res = await getSupplierInfoAsync(this.props.data.companyId);
    this.props.setSupplierInfo({ ...this.props.data, ...res });
    this.props.setLastStepCompleted(res.registrationStepCompleted);

    // Populate vat exempted
    onCompanyTypeChangeHandler({ target: { value: res.companyType } },
      this.props.companyTypes, this.props.onInputChange, false);

    try {
      const invitation = await getOnboardingInvitation();
      const autoAccept = invitation.autoAccept
        || !this.props.data.disableEditRegistrationInfo;

      if (invitation !== "") {
        this.setState((_, prevProps) => ({
          invitation,
          showOnboardingInvitationModal:
            autoAccept
              ? false
              : invitation.invitationId
              !== prevProps.data.onboadingRequestInvitationId,
          showOnboardingInvitationAcceptedModal:
            autoAccept
              ? invitation.invitationId
              !== prevProps.data.onboadingRequestInvitationId
              : false,
        }));

        if (autoAccept) {
          this.onAcceptOnboardingInvitation(invitation.invitationId);
        }
        const buyerOptions = await getCompanyOptionsAsync(
          [
            staticCompanyDataTypes.businessTypes,
          ],
           invitation?.buyerCompanyId
        );
        this.setState({ buyerOptions });
      }
    } catch (ex) {
      // error
    }
    this.props.hideLoadingScreen();
  }

  render() {
    const companyTypeName = (this.state.invitation.companyTypeId != null)
      ? this.props.companyTypes.find(
        (companyType) => companyType.id === this.state.invitation.companyTypeId
      )?.name
      : null;

    return (
      <section id="isuppli-registration-step-01">
        <Loading />

        <InfoPopup />
        {this.state.showOnboardingInvitationModal && (
          <OnboardingInvitationDetails
            invitation={{
              ...this.state.invitation,
              companyTypeName,
            }}
            onReject={() => this.onRejectOnboardingInvitation(this.state.invitation.invitationId)}
            onAccept={() => this.onAcceptOnboardingInvitation(this.state.invitation.invitationId)}
            onClose={() => this.invitationModalOnCloseHandler()}
          />
        )}

        {this.state.showOnboardingInvitationAcceptedModal && (
          <ConfirmationModal
            heading="ONBOARDING INVITATION ACCEPTED"
            description={"Thank you for accepting the invitation to register."
              + " Please complete your company profile to finalise the process,"
              + " and to enjoy the many benefits that B1LINK will bring to your company."}
            hasCancel={false}
            onProceedClick={() => this.invitationAcceptedModalOnCloseHandler()}
            proceedButtonText="Proceed"
            onToggleModal={() => { }}
          />
        )}

        {
          this.state.showConfirmMsg
                    && (
                      <SaveAndContinueConfirm
                        toggleModalCallback={this.toggleConfirmSaveAndContinue}
                        saveAndContinueCallback={this.saveAndContinueLater}
                      />
                    )
        }

        {
          this.state.showDuplicatesMsg && (
            <DuplicateRegistrationModal
              toggleModalCallback={this.toggleDuplicates}
              submissionSuccessCallback={() => (this.state.saveAndContinueLater
                ? this.props.logOut() : {})}
              duplicates={this.state.duplicates}
              companyTypes={this.props.companyTypes}
            />
          )
        }

        <RegistrationProgress currentStep={1} />

        <div className="padding-lr-10vw">
          <div className="row top-margin-90px">
            <div className="col-12 col-lg-4">
              <div>
                <h3 className="margin-null">STEP 01</h3>
              </div>
              <div>
                <h1>SUPPLIER INFORMATION</h1>
              </div>
            </div>

            <div
              className="col-12 col-lg-6 justify-center flex-column"
              style={{ paddingTop: "20px" }}
            >
              <h3>
                We need a little bit more information about your business, to
                ensure we know what your company has to offer.
              </h3>
            </div>
          </div>

          <div className="row" style={{ paddingTop: "36px" }}>
            <div className="col-12 col-lg-7">
              <div>
                <Alert color="info">
                  Please fill in the required fields below. All fields are
                  mandatory unless stated otherwise.
                </Alert>
              </div>
            </div>
          </div>

          <GeneralBusinessInformation
            data={
              {
                ...this.props.data,
                clientBase: this.props.data.clientBase,
              }
            }
            businessTypes={this.state.buyerOptions
              ?.businessTypes
              ?.map((businessType) => ({
                ...objectToDisplayObject(businessType),
              }))
              ?? this.props.businessTypes
              ?? []}
            additional={this.state.msgs}
            onInputChangeCallback={this.props.onInputChange}
            setStepValid={this.props.setStepValid("isValidStep1")}
            includeShareholders
          />
        </div>

        <div className="padding-lr-5vw" style={{ marginTop: "36px" }}>
          <hr />
        </div>

        <div className="padding-lr-10vw" style={{ paddingTop: "42px" }}>
          <RegistrationCompanyInfo
            data={this.props.data}
            companyTypes={this.props.companyTypes}
            additional={this.state.msgs}
            onInputChangeCallback={this.props.onInputChange}
            setStepValid={this.props.setStepValid("isVATNumberValid")}
            disableEditRegistrationName={this.props.data.disableEditRegistrationName}
            regNumberReadOnly={this.props.data.disableEditRegistrationInfo}
          />
        </div>

        <WizzardBtns
          history={this.props.history}
          backUrl={null}
          nextUrl="/registration/coninfo"
          nextText="CONTACT & ADDRESS DETAILS"
          onClickSaveAndContinue={this.toggleConfirmSaveAndContinue}
          onClickNext={this.onNextClick}
        />
      </section>
    );
  }
}

const mappedRegistrationStep01 = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationStep01);
export default withRouter(mappedRegistrationStep01);
