import React, { Fragment } from "react";
import ModalBackground from "../ModalBackground/ModalBackground";
import ModalContainer from "../ModalContainer/ModalContainer";
import UtilButton from "../../Components/UtilButton/UtilButton";
// eslint-disable-next-line no-unused-vars
import { ButtonInfo } from "../../utils/interfaces/ButtonInfo";
import ModalCloseButton from "../../Components/ModalCloseButton/ModalCloseButton";
import Line from "../../Components/Line/Line";

import Styles from "./ModalPopup.module.scss";
// eslint-disable-next-line no-unused-vars
import { ActionsDropDown } from "../../Components/ActionsDropDown";
import { useSystemFeatureCheck } from "../../Hooks/useSystemFeatureCheck";

import { ReactComponent as ErrorSvg } from "../../images/error.svg";
import { ReactComponent as LaptopSvg } from "../../images/laptop.svg";

const ModalPopup = ({
  onClose,
  hasCancel = true,
  cancelButtonText = "Cancel",
  heading = "",
  subheading = "",
  buttons = [],
  children,
  size = "full-width",
  stickyButtons = false,
  actionItems = [],
  image,
}: {
  onClose: () => void,
  hasCancel?: boolean,
  cancelButtonText?: string,
  heading?: string,
  subheading?: string,
  buttons? : Array<ButtonInfo>,
  children: React.ReactNode,
  size?: "small" | "medium" | "large" | "extra-large" | "full-width",
  stickyButtons?: boolean,
  actionItems?: Array<JSX.Element>,
  image?: "error" | "laptop",
}) => {
  const featureCheck = useSystemFeatureCheck();

  let imageElement = null;
  switch (image) {
    case "error":
      imageElement = <ErrorSvg className="text-danger" style={{ height: "76px", width: "auto" }} />;
      break;
    case "laptop":
      imageElement = <LaptopSvg className="text-primary" style={{ width: "auto" }} />;
      break;
    default:
      break;
  }

  return (
    <ModalBackground>
      <ModalContainer className={`container-fluid position-relative ${stickyButtons ? Styles.StickyContainer : ""}`} size={size}>

        <div className={`position-absolute ${Styles.CloseButton}`}>
          {hasCancel && <ModalCloseButton onClick={() => onClose()} />}
        </div>

        <div className="row px-2 pt-4">
          <div className="col-12 d-flex">
            <div className={imageElement != null ? "col-12 justify-content-center align-items-center text-center" : "flex-fill"}>
              {heading?.length > 0 ? <h1>{heading}</h1> : null}
              {imageElement != null
                && (
                  <div>
                    {imageElement}
                    <div className="mt-3" />
                  </div>
                )}
              {subheading?.length > 0 ? (
                <h3 className="Details">{subheading}</h3>
              ) : null}
            </div>
          </div>
        </div>
        {stickyButtons
          ? (
            <div className="flex-fill position-relative row">
              <div className="position-absolute h-100 w-100 overflow-auto container-fluid">
                {children}
              </div>
            </div>
          )
          : (
            <Fragment>
              {children}

            </Fragment>
          )}
        <div className={`row px-2 ${buttons.length === 0 ? "desktop-version" : ""}`}>
          <div className="col-12">
            <Line color="primary" className="mt-3" />
          </div>
        </div>
        <div className="row px-2 pb-xl-4 pb-2">
          <div className="col-12 d-flex justify-content-between align-items-center flex-column flex-xl-row">
            {/* Renders on the desktop version/view */}
            <div className="desktop-version">
              {hasCancel && (
                <UtilButton
                  color="primary"
                  location="bottom"
                  outline
                  onClick={() => onClose()}
                >
                  { cancelButtonText }
                </UtilButton>
              )}
            </div>
            <div className="ml-lg-4 d-flex desktop-version">
              { actionItems.length > 0 && (
                <ActionsDropDown label="Available actions" className="desktop-version">
                  {actionItems?.map((c) => c)}
                </ActionsDropDown>
              )}
              {buttons.map((button) => (
                <div key={button.key}>
                  <UtilButton
                    className="ml-2"
                    color={button.color}
                    location="bottom"
                    onClick={button.onClick}
                    disabled={button.feature != null
                      ? !featureCheck(button.feature)
                      : button.disabled}
                    feature={button.feature}
                  >
                    {button.label}
                  </UtilButton>
                </div>
              ))}
            </div>
            {/* Renders on the mobile version/view */}
            { actionItems.length > 0 && (
              <ActionsDropDown label="Available actions" className="mobile-version">
                {actionItems?.map((c) => c)}
              </ActionsDropDown>
            )}
            {buttons.map((button) => (
              <UtilButton
                key={button.key}
                className="mobile-version w-100 mb-2"
                color={button.color}
                location="bottom"
                onClick={button.onClick}
                disabled={button.feature != null
                  ? !featureCheck(button.feature)
                  : button.disabled}
                feature={button.feature}
              >
                {button.label}
              </UtilButton>
            ))}

          </div>
        </div>
      </ModalContainer>
    </ModalBackground>
  );
};

export default ModalPopup;
