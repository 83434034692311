import { Spinner } from "isuppli-react-components";
import React, { useEffect, useState } from "react";
import useShowError from "../../../Hooks/useShowError";
// eslint-disable-next-line no-unused-vars
import { ClientSupplierCategoryMessage } from "../../../http/SupplierCategory/Models/Models";
import { getClientSupplierCategories } from "../../../http/SupplierCategory/supplierCategoryAPI";
import ClientOfferingSummaryDisplay from "./ClientOfferingSummaryDisplay";

const ClientOfferingSummary = ({
  buyerCompanyId,
}: {
  buyerCompanyId: number,
}) => {
  const showError = useShowError();
  const [
    clientProductOfferings,
    setClientProductOfferings,
  ] = useState<Array<ClientSupplierCategoryMessage>>([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const result = await getClientSupplierCategories(
          buyerCompanyId
        );

        setClientProductOfferings(result);
      } catch (error) {
        showError();
      }
      setLoading(false);
    };

    loadData();
  }, [buyerCompanyId, setClientProductOfferings, setLoading, showError]);

  return (
    <div>
      {
        loading && <Spinner inline />
      }
      <ClientOfferingSummaryDisplay clientProductOfferings={clientProductOfferings} />
    </div>
  );
};

export default ClientOfferingSummary;
