import React, {
  useRef, useEffect, useState, useCallback, Fragment,
} from "react";
import Spinner from "../../Containers/Spinner/Spinner";
import Styles from "./InfiniteScroll.module.scss";

export interface LoadOptions {
  loadNext: boolean;
  loadPrevious: boolean;
}

const InfiniteScroll = (
  {
    hasMore,
    hasMoreBefore = false,
    loadMore,
    children,
  }
  :
  {
    hasMore: boolean,
    hasMoreBefore?: boolean,
    // promise with a return true if the loading is done
    loadMore: (loadOptions: LoadOptions) => Promise<boolean>,
    children: Array<React.ReactElement>,
  }
) => {
  const [loading, setLoading] = useState(false);

  const container = useRef<HTMLDivElement>(null);
  const beforeLoader = useRef<HTMLDivElement>(null);
  const scrollFiller = useRef<HTMLDivElement>(null);

  const lastScrollTop = useRef(0);

  // whether loading is in progress
  const loadMoreBusy = useRef<boolean>(false);

  // indicates that child change was detected and that
  // the next page should be loaded when possible
  const childrenChangedPending = useRef<boolean>(false);

  const latestLoadMore = useRef<(loadOptions: LoadOptions) => Promise<boolean>>(loadMore);
  useEffect(() => {
    latestLoadMore.current = loadMore;
  }, [loadMore]);

  const latestHasMore = useRef<boolean>(hasMore);
  useEffect(() => {
    latestHasMore.current = hasMore;
  }, [hasMore]);

  const onScrollHandler = useCallback(async (parent: HTMLElement | null) => {
    const parentHeight = parent?.clientHeight ?? 0;
    const scrollTop = parent?.scrollTop ?? 0;
    const contentHeight = container.current?.clientHeight ?? 0;

    const loadBeforeHeight = beforeLoader.current?.clientHeight ?? 0;

    if (!hasMore && !hasMoreBefore) {
      return;
    }

    const distanceFromBottom = contentHeight - parentHeight - scrollTop;

    if (hasMore && distanceFromBottom < 100) {
      if (loadMoreBusy.current) {
        return;
      }
      loadMoreBusy.current = true;
      setLoading(true);

      const success = await latestLoadMore.current({
        loadNext: true,
        loadPrevious: false,
      });

      if (success) {
        setLoading(false);
      }

      loadMoreBusy.current = false;

      if (childrenChangedPending.current) {
        childrenChangedPending.current = false;
        if (success && latestHasMore.current) {
          onScrollHandler(container.current?.parentElement ?? null);
        }
      }
    } else if (hasMoreBefore && loadBeforeHeight > 0
      && scrollTop < loadBeforeHeight / 2) {
      // load before
      if (loadMoreBusy.current) {
        return;
      }
      loadMoreBusy.current = true;

      await latestLoadMore.current({
        loadNext: false,
        loadPrevious: true,
      });

      loadMoreBusy.current = false;
    }
  }, [setLoading, hasMore, hasMoreBefore]);

  useEffect(() => {
    // children changed, assume loading is done or need to be reloaded
    if (loadMoreBusy.current) {
      // already loading
      childrenChangedPending.current = true;
    } else {
      onScrollHandler(container.current?.parentElement ?? null);
    }
  }, [children, onScrollHandler]);

  const eventHandler = useRef<(ev: Event) => void>();

  // register scroll event listener
  useEffect(() => {
    onScrollHandler(null);

    // add event listener
    const parent = container.current?.parentElement;
    if (parent != null) {
      if (eventHandler.current != null) {
        parent.removeEventListener("scroll", eventHandler.current);
      }

      eventHandler.current = (ev: Event) => {
        lastScrollTop.current = (ev.target as HTMLElement).scrollTop ?? 0;
        onScrollHandler(ev.target as HTMLElement);
      };
      parent.addEventListener("scroll", eventHandler.current);
    }
  }, [container, onScrollHandler]);

  const beforeLoaderHeight = useRef(0);
  const previousScrollFillerHeight = useRef(0);
  // set scroll top position so that before loader is out of the scroll
  useEffect(() => {
    beforeLoaderHeight.current = beforeLoader.current?.clientHeight ?? beforeLoaderHeight.current;

    if (hasMoreBefore
      && container.current?.parentElement != null
      && beforeLoader.current != null) {
      const parentScrollTop = container.current.parentElement.scrollTop;
      const parentHeight = container.current.parentElement.clientHeight;
      const contentHeight = container.current.clientHeight;

      const minContentHeight = parentHeight + beforeLoaderHeight.current;

      if (scrollFiller.current != null) {
        scrollFiller.current.style.height = `${
          minContentHeight > contentHeight
            ? minContentHeight - contentHeight
            : 0
        }px`;

        if (minContentHeight > contentHeight) {
          previousScrollFillerHeight.current = minContentHeight - contentHeight;
        }
      }

      if (parentScrollTop < beforeLoaderHeight.current) {
        container.current.parentElement.scrollTo({ top: beforeLoaderHeight.current });
      }
    }
  }, [hasMoreBefore, children, loading]);

  const previousChildren = useRef(children);
  const previousContentHeight = useRef(0);
  // set the scroll position after a page was loaded at the start
  useEffect(() => {
    const contentHeight = (container.current?.scrollHeight ?? 0);

    // when loading more children above, keep scroll position
    if (children.length > 0 && previousChildren.current.length > 0) {
      const firstChild = previousChildren.current[0];
      const firstItemIndexInNewList = children.findIndex((c) => c.key === firstChild.key);
      if (firstItemIndexInNewList > 0 && previousChildren.current.length < children.length) {
        // content was added at the top
        // keep current scroll position
        const parent = container.current?.parentElement;
        if (parent == null) {
          return;
        }

        const newScrollTop = contentHeight
           - previousContentHeight.current
           - beforeLoaderHeight.current
           + lastScrollTop.current;
        parent.scrollTo({ top: newScrollTop });
      }
    }

    previousChildren.current = children;
    previousContentHeight.current = contentHeight;
  }, [children]);

  return (
    <Fragment>
      <div ref={container} className={Styles.Container}>
        {
          hasMoreBefore && !loading
          && (
            <div className="py-5" ref={beforeLoader}>
              <Spinner inline />
            </div>
          )
        }

        {children}
        {loading && <Spinner inline />}
      </div>
      {
        !hasMore && !loading
        && (
          <div ref={scrollFiller} />
        )
      }
    </Fragment>

  );
};

export default InfiniteScroll;
