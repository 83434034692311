import React from "react";
import { NavLink } from "react-router-dom";
import { Badge, Button } from "reactstrap";
// eslint-disable-next-line no-unused-vars
import { MenuItem } from "../../../utils/interfaces/MenuItem";

import { ReactComponent as SvgTriangle } from "../../../images/triangle.svg";
import Styles from "./UserMaintenance.module.scss";
import
// eslint-disable-next-line no-unused-vars
SystemFeatures,
{ hasSomeFeature } from "../../../utils/enums/SystemFeatures";

const UserMaintenance = ({
  setOpenSubMenu,
  openSubMenu,
  userGreeting,
  companyName,
  userBadge,
  onLogout,
  menuItems,
  allowedFeatures,
} : {
  setOpenSubMenu : (menu: string) => void,
  openSubMenu: string,
  userGreeting: string,
  companyName: string,
  userBadge: string | null,
  onLogout: () => void,
  menuItems: Array<MenuItem>,
  allowedFeatures: SystemFeatures,
}) => {
  const userMenu = menuItems.find((c) => c.isUserMenu);

  return (
    <div className={`d-flex align-items-stretch h-100 ${Styles.UserNavItem} position-relative flex-column`}>
      <Button
        color="clear"
        className="d-flex h-100 align-items-center text-white ml-xl-2"
        onClick={() => {
          if (openSubMenu !== "user") {
            setOpenSubMenu("user");
          } else {
            setOpenSubMenu("");
          }
        }}
      >
        <div>
          <div className={Styles.Username}>{userGreeting}</div>
          <div className={`${Styles.Username} ${Styles.Company}`}>{companyName}</div>
          {userBadge && <Badge color="secondary">{userBadge}</Badge>}
        </div>

        <div style={{ paddingLeft: "15px" }}>
          <SvgTriangle />
        </div>
      </Button>
      {openSubMenu === "user" ? (
        <ul className={`${Styles.UserSubMenu} d-flex p-0`}>
          {userMenu?.children?.map((item) => (
            <li key={item.title + item.to} className="nav-item d-flex align-items-stretch">
              <NavLink
                className={`${!hasSomeFeature(allowedFeatures, item.feature) ? "disabled" : ""}`}
                to={`${userMenu.to}/${item.to}`}
                onClick={() => setOpenSubMenu("")}
              >
                {item.title}
              </NavLink>
            </li>
          ))}
          <li className="nav-item d-flex align-items-stretch">
            <NavLink
              to="Account/Logout"
              onClick={(e) => {
                e.preventDefault();
                onLogout();
              }}
            >
              Log Out
            </NavLink>
          </li>
        </ul>
      ) : null}
    </div>
  );
};

export default UserMaintenance;
