import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import { Alert } from "reactstrap";
import EnterpriseConfigProgress from "./EnterpriseConfigProgress";

import TermsAndConditionsUploader from "../../Containers/EnterpriseConfig/TermsAndConditionsUploader";

import UtilButton from "../../Controls/UtilButton";

class EnterpriseConfigStep03 extends Component {
  onNextClick = (arg) => {
    this.props.history.replace(arg);
  };

  render() {
    return (
      <section id="isuppli-enterpriseconfig-step-03">
        <EnterpriseConfigProgress currentStep={3} />

        <div className="padding-lr-10vw">
          <div className="row top-margin-90px">
            <div className="col-12 col-sm-5">
              <div>
                <h3 className="margin-null">STEP 03</h3>
              </div>
              <div>
                <h1>TERMS & CONDITIONS</h1>
              </div>
            </div>

            <div className="col-12 col-sm-7 justify-center flex-column">
              Information blurp about why they need to provide information for
              “terms and conditions”.
            </div>
          </div>

          <div className="top-margin-30px">
            <Alert color="info">
              Please fill in the required fields below. All fields are mandatory
              unless stated otherwise.
            </Alert>
          </div>

          <TermsAndConditionsUploader />

          <hr className="margin-null" />

          <div className="row">
            <div className="col-12 col-sm-5 block-sm justify-start">
              <UtilButton
                text="BACK"
                background="clear"
                actionArg="/mycompany/configuration/catinfo"
                location="bottom"
                actionCallback={(arg) => this.props.history.replace(arg)}
              />
            </div>

            <div className="col-12 col-sm-7 block-sm justify-end">
              <UtilButton
                text="SAVE AND CONTINUE LATER"
                background="active"
                actionArg="s"
                location="bottom"
                actionCallback={() => {}}
              />

              <div className="left-margin-10px" />

              <UtilButton
                text="NEXT - SUPPORTING DOCUMENTS"
                background="attention"
                actionArg="/mycompany/configuration/supinfo"
                location="bottom"
                actionCallback={this.onNextClick}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(EnterpriseConfigStep03);
