/* eslint-disable keyword-spacing */
import React, {
  useState, Fragment,
} from "react";

import {
  Spinner,
  ConfirmationModal,
} from "isuppli-react-components";
import {
  addNewSupplierOnboarding,
} from "../../../http/posts";
import { unlinkSupplierOnboarding } from "../../../http/deletes";

import useShowError from "../../../Hooks/useShowError";

const AddSupplierModal = ({
  onClose,
  onRefresh,
  supplierId,
  isEditing,
  registeredCompanyName,
}: {
  onRefresh: () => void;
  onClose: () => void;
  supplierId: number;
  isEditing: boolean;
  currentVendorNumber: string;
  registeredCompanyName: string;
}) => {
  const [loading, setLoading] = useState(false);

  const showError = useShowError();

  const onSaveHandler = async () => {
    setLoading(true);
    try {
      await addNewSupplierOnboarding({
        supplierCompanyId: supplierId,
      });
      onRefresh();
      onClose();
    } catch (error) {
      showError();
    }
    setLoading(false);
  };

  const unlinkHandler = async () => {
    setLoading(true);
    // unlink
    try {
      await unlinkSupplierOnboarding(supplierId);
      onRefresh();
      onClose();
    } catch (error) {
      showError();
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <ConfirmationModal
        heading={isEditing ? "Unlink supplier" : "Onboard supplier"}
        description={isEditing
          ? `You are about to unlink  ${registeredCompanyName} as supplier`
          : `You are about to onboard  ${registeredCompanyName} as supplier`}
        proceedButtonText={isEditing ? "Unlink" : "Onboard"}
        onToggleModal={onClose}
        onProceedClick={isEditing ? unlinkHandler : onSaveHandler}
      />
      {loading && <Spinner />}
    </Fragment>
  );
};

export default AddSupplierModal;
