import React from "react";

const Page = (
  { children, noPadding = false }
  :
  {children: React.ReactNode, noPadding?: boolean}
) => (
  <div className={`container-fluid ${noPadding ? "" : "pb-4"}`}>
    <div className="row">
      <div className="col-10 offset-1">
        {children}
      </div>
    </div>
  </div>
);

export default Page;
