import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import Styles from "./SupplierSearch.module.scss";

const SupplierSearchToolTip = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const tradeDescription = props.rowData.tradeDescription ?? "None";

  return (
    <div>
      <span
        id={`regName${props.rowData.id}`}
      >
        {props.rowData.registeredName}
      </span>
      <Tooltip className={Styles.Tooltip} placement="top" target={`regName${props.rowData.id}`} isOpen={tooltipOpen} toggle={toggle}>
        Services:
        <br />
        {`${tradeDescription}`}
      </Tooltip>
    </div>
  );
};

export default SupplierSearchToolTip;
