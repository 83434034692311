const supplierStatuses = {
  incomplete: 0,
  potential: 10,

  busyOnboarding: 20,

  existing: 50,
  blocked: 100,

  toString: (value) => {
    switch (value) {
      case supplierStatuses.incomplete:
        return "Incomplete";
      case supplierStatuses.potential:
        return "Potential";
      case supplierStatuses.busyOnboarding:
        return "Busy Onboarding";
      case supplierStatuses.existing:
        return "Existing";
      case supplierStatuses.blocked:
        return "Blocked";
      default:
        return `Supplier status ${value}`;
    }
  },
};

export default supplierStatuses;
