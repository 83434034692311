import React, { useState, Fragment } from "react";
import {
  Input, Label, FormGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from "reactstrap";
import Styles from "./DropDownInput.module.scss";

const DropDownInput = <T extends string | number> ({
  label,
  options,
  onChange,
  value,
  noPrompt,
  inline,
  className,
  inputClassName,
  required,
  validationMessage,
  disabled,
  canSearch,
  squareBorder = false,
}: {
  label?: string,
  options: Array<{ value: T, display: string }>,
  onChange: (value?: T) => void,
  value?: T,
  noPrompt?: boolean,
  inline?: boolean,
  className?: string,
  inputClassName?: string,
  required?: boolean,
  validationMessage?: string,
  disabled?: boolean,
  canSearch?: boolean,
  squareBorder?: boolean,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const selectOptions = [
    noPrompt ? null : (
      <option key="" value="">
        please select
      </option>
    ),
  ].concat(
    options.map((option) => (
      <option
        key={option.value}
        value={option.value}
        onClick={() => onChange(option.value)}
      >
        {option.display}
      </option>
    ))
  );

  let filteredOptions = options;

  if (canSearch && searchQuery) {
    filteredOptions = options
      .filter((c) => c.display.toLowerCase().includes(searchQuery));
  }

  const dropDownItems = filteredOptions.map((option) => (
    <DropdownItem
      key={option.value}
      value={option.value}
      onClick={() => onChange(option.value)}
    >
      {option.display}
    </DropdownItem>
  ));

  const [showOptions, setShowOptions] = useState(false);
  const inputId = `${label}-${Math.random().toString(36).substring(2)}`;
  return (
    <FormGroup className={`${inline ? "d-flex align-items-baseline" : ""} ${className}`}>
      {label && <Label for={inputId} className={inline ? "pr-4" : ""}>{label}</Label>}
      <Dropdown
        className="h-100"
        direction="down"
        disabled={disabled}
        isOpen={showOptions}
        toggle={() => setShowOptions((newValue) => !newValue)}
      >
        <DropdownToggle tag="div" className="h-100">
          <Input
            id={inputId}
            className={`${
              Styles.DropDownInput
            } ${
              inline ? Styles.DropDownInputInline : ""
            } ${
              squareBorder ? "rounded-0" : "rounded-full"
            } ${
              inputClassName ?? ""
            }`}
            type="select"
            name="select"
            value={value ?? ""}
            required={required}
            disabled={disabled}
            // empty onchange, change happens as part of drop down
            onChange={() => {}}
          >
            {selectOptions}
          </Input>
          {
            validationMessage && (
              <div className="invalid-feedback">
                {validationMessage}
              </div>
            )
          }
        </DropdownToggle>
        <DropdownMenu className={`${Styles.ScrollingMenu} w-100`}>
          {canSearch
            && (
              <Fragment>
                <Input
                  autoFocus
                  type="search"
                  placeholder="Search for an item"
                  className={`${Styles.SearchInput} border-0 rounded-0`}
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                />
                <DropdownItem divider />
              </Fragment>
            )}

          {dropDownItems}
        </DropdownMenu>
      </Dropdown>

    </FormGroup>
  );
};

export default DropDownInput;
