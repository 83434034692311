import React, {
  useState, useRef, useEffect,
  // eslint-disable-next-line no-unused-vars
  ForwardRefRenderFunction,
  forwardRef, useImperativeHandle,
} from "react";

import { Badge } from "reactstrap";

import UtilButton from "../UtilButton/UtilButton";
import MessageAttachment from "./MessageAttachment/MessageAttachment";

import
FileSelector,
{
  FileSelectorRef, // eslint-disable-line no-unused-vars
}
  from "../FileSelector/FileSelector";
import useDebounce from "../../Hooks/useDebounce";

import { ReactComponent as SvgPaperclip } from "./paperclip.svg";
import { ReactComponent as SvgTriangle } from "../../images/triangle.svg";
import Styles from "./MessageComposer.module.scss";
import ImageButton from "../ImageButton/ImageButton";

interface BusyAttachment extends Attachment, ProgressAttachment {
  done: boolean,
}

interface ProgressAttachment {
  fileId: number | null,
  progress: number,
  id: number,
  fileUrl: string,
  filename: string,
  uploadError?: string
}

export { default as MessageAttachment } from "./MessageAttachment/MessageAttachment";

export interface AttachmentUploadResult extends Attachment {
  success: boolean,
}

export interface Attachment {
  id: number,
  fileUrl: string,
  filename: string,
}

export interface Message {
  id: number,
  content: string,
  attachments: Array<Attachment>
}

export interface ApiCancelToken {
  cancel: () => void;
}

export interface MessageComposerRef {
  triggerSave: () => Promise<void>,
  triggerSend: () => Promise<void>,
}

export interface MessageComposerProps {
  downloadUrlBase: string,
  onCreateDraft: (config?: (cancelToken: ApiCancelToken) => void) => Promise<Message | undefined>,
  onUpdateDraft: (body: string) => Promise<void>,
  onSendMessage: () => Promise<void>,
  onDeleteAttachment: (fileId: number) => Promise<void>,
  onUploadAttachment: (
    formData: FormData,
    progressCallback: (progress: number) => void,
    doneCallback:(uploadResult: Array<AttachmentUploadResult> | null) => void,
    config: (
      cancelToken: ApiCancelToken
    ) => void) => void,
  onCancelUpload: () => void,
  alwaysOpen?: boolean,
  defaultOpen?: boolean,
  hideSendButton?: boolean,
  className?: string,
}

const MessageComposer: ForwardRefRenderFunction<MessageComposerRef, MessageComposerProps> = ({
  downloadUrlBase,
  onCreateDraft,
  onUpdateDraft,
  onSendMessage,
  onDeleteAttachment,
  onCancelUpload,
  onUploadAttachment,
  alwaysOpen = false,
  defaultOpen = false,
  hideSendButton = false,
  className,
}:MessageComposerProps, ref) => {
  const openInitValue = alwaysOpen || defaultOpen;
  const textArea = useRef<HTMLTextAreaElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  // whether the current state has been saved
  const [attachments, setAttachments] = useState<BusyAttachment[]>([]);
  const [body, setBody] = useState<string>("");
  const [isSending, setIsSending] = useState(false);
  const sendQue = useRef<boolean>(false);
  const getForceUpdatePromiseResolver = useRef<() => void>();
  const fileSelectRef = useRef<FileSelectorRef>(null);
  const draftCancelToken = useRef<ApiCancelToken>();
  const attachmentUploadCancelToken = useRef<ApiCancelToken>();

  // change openState of composer on open variables prop changes
  useEffect(() => {
    setIsOpen(openInitValue);
  }, [openInitValue]);

  // cancel uploads when component disposes
  useEffect(() => () => {
    attachmentUploadCancelToken.current?.cancel();
    attachmentUploadCancelToken.current = undefined;
    onCancelUpload();
  }, []);

  useEffect(() => {
    // create a draft
    const createDraft = async () => {
      draftCancelToken.current?.cancel();
      const newDraft = await onCreateDraft((cancelToken) => {
        draftCancelToken.current = cancelToken;
      });

      if (newDraft == null) {
        return;
      }
      setInitialLoading(false);
      setBody(newDraft.content);

      const draftAttachments: BusyAttachment[] = newDraft.attachments.map((a) => ({
        id: a.id,
        fileId: a.id,
        filename: a.filename,
        fileUrl: a.fileUrl,
        progress: 1,
        done: true,
      }));
      setAttachments(draftAttachments);
    };

    createDraft();
    return () => {
      // dispose component
      draftCancelToken.current?.cancel();
    };
  }, [setInitialLoading]);

  const isSendDisabled = () => (!body && attachments.length <= 0)
    || attachments.some((a) => !a.done || a.progress < 1);

  const updatePending = useRef<boolean>(false);

  let onSendHandler: () => Promise<void>;

  const [
    triggerUpdateDebounce,
    forceUpdateDebounce,
  ] = useDebounce<string>(async (latestBody) => {
    if (latestBody == null) {
      return;
    }
    setBody(latestBody);
    await onUpdateDraft(latestBody);
    updatePending.current = false;
    if (sendQue.current) {
      sendQue.current = false;
      await onSendHandler();
    }

    if (getForceUpdatePromiseResolver.current != null) {
      getForceUpdatePromiseResolver.current();
      getForceUpdatePromiseResolver.current = undefined;
    }
  },
  1000);

  const onBodyChangeHandler = (newValue: string) => {
    updatePending.current = true;
    setBody(newValue);
    triggerUpdateDebounce(newValue);
  };

  onSendHandler = async () => {
    setIsSending(true);
    if (updatePending.current) {
      sendQue.current = true;
      await forceUpdateDebounce();
      return;
    }

    await onSendMessage();

    const newDraftMessage = await onCreateDraft();
    if (newDraftMessage == null) {
      return;
    }
    setBody(newDraftMessage.content);
    setAttachments([]);
    setIsSending(false);
    setIsOpen(false);
  };

  useImperativeHandle(ref, () => ({
    triggerSave: () => new Promise<void>((resolver) => {
      if (updatePending.current) {
        getForceUpdatePromiseResolver.current = resolver;
        forceUpdateDebounce();
        return;
      }
      resolver();
    }),
    triggerSend: async () => {
      await onSendHandler();
    },
  }));

  const onTextFocusHandler = () => {
    setIsOpen(true);
  };

  const onToggleButtonClickHandler = () => {
    setIsOpen(!isOpen);
    setBody(body.trimStart());

    // eslint-disable-next-line no-unused-expressions
    textArea.current?.scrollTo(0, 0);
  };

  const onAttachmentDeleteHandler = async (id: number | null) => {
    const attachmentToDelete = attachments.find((c) => c.id === id);
    if (attachmentToDelete == null) {
      return;
    }
    setAttachments((prevAttachments) => prevAttachments.filter((c) => c.id !== id));

    if (attachmentToDelete.fileId != null) {
      // check for failed downloads
      await onDeleteAttachment(attachmentToDelete.fileId);
    }
  };

  const onAttachmentUploadedHandler = (uploadResult: AttachmentUploadResult[]) => {
    setAttachments((prevAttachments) => {
      const newAttachments = prevAttachments
        .map((file) => {
          const matchedUpdate = uploadResult.find((c) => c.id === file.id);
          return {
            ...file,
            fileUrl: (matchedUpdate?.fileUrl != null) ? matchedUpdate.fileUrl : file.fileUrl,
            progress: (matchedUpdate?.success ?? true) ? file.progress : -1,
            done: file.done || uploadResult.some((progressFile) => file.id === progressFile.id),
          };
        });
      return newAttachments;
    });
  };

  const updateProgress = (progress: ProgressAttachment[]) => {
    setAttachments((prevAttachments) => {
      const newAttachments = [...prevAttachments];
      progress.forEach((progressItem) => {
        const idMatch = newAttachments.find((c) => c.id === progressItem.id);

        if (idMatch != null) {
          idMatch.progress = progressItem.progress;
          idMatch.fileId = progressItem.fileId;
          idMatch.fileUrl = progressItem.fileUrl;
          return;
        }

        newAttachments.push({ ...progressItem, done: false });
      });
      return newAttachments;
    });
  };

  const classes = [Styles.MessageComposer, "bg-white", className ?? ""];
  if (isOpen) {
    classes.push(Styles.Expand);
  } else {
    classes.push(Styles.Collapse);
  }

  if (hideSendButton) {
    classes.push(Styles.HideSendButton);
  }

  if (alwaysOpen) {
    classes.push(Styles.HideToggleButton);
  }

  return (
    <div className={classes.join(" ")}>
      <textarea
        disabled={initialLoading}
        ref={textArea}
        rows={isOpen ? undefined : 1}
        className={`${Styles.TextArea} flex-fill align-self-center`}
        placeholder="Write a message..."
        value={body}
        onChange={(ev) => onBodyChangeHandler(ev.target.value)}
        onFocus={onTextFocusHandler}
      />
      {isOpen && attachments.length > 0
        ? (
          <div className={`${Styles.AttachmentsContainer} d-flex flex-wrap`}>
            {attachments.map((attachment) => {
              let { progress } = attachment;
              if (attachment.done) {
                progress = progress >= 1 ? 1 : -1;
              } else {
                progress = progress >= 1 ? 0.99 : progress;
              }
              return (
                <MessageAttachment
                  className="ml-2 mt-2"
                  key={attachment.id}
                  fileName={attachment.filename}
                  progress={progress}
                  onDelete={() => onAttachmentDeleteHandler(attachment.id)}
                  link={attachment.fileUrl ? `${downloadUrlBase}${attachment.fileUrl}` : ""}
                  canClose
                  uploadError={attachment.uploadError}
                />
              );
            })}
          </div>
        )
        : null}

      {!isOpen && attachments.length > 0
        ? <Badge className={Styles.AttachmentBadge} color={isSendDisabled() ? "secondary" : "info"}>{isSendDisabled() ? "Uploading.." : `${attachments.length}  Attachments`}</Badge> : null}
      {!alwaysOpen
        ? (
          <ImageButton
            className={`${Styles.ToggleButton} px-3 text-secondary`}
            onClick={onToggleButtonClickHandler}
            alt="Expand or collapse message editor"
            svg={SvgTriangle}
          />
        )
        : null}

      <FileSelector
        allowMultiple
        ref={fileSelectRef}
        onFilesSelected={(fileNames: string[], formData: FormData, uploadErrors: string[]) => {
          const newFiles = fileNames.map((fileName, i) => (
            {
              id: Date.now() + i,
              fileId: null,
              filename: fileName,
              progress: 0,
              fileUrl: "",
              uploadError: uploadErrors[i],
            }
          ));
          onUploadAttachment(
            formData,
            (progress) => {
              // progress handler
              const updatedProgress = newFiles.map((f) => ({ ...f, progress }));
              updateProgress(updatedProgress);
            },
            (uploadResult) => {
              // done handler
              const updatedProgress = newFiles.map((p) => ({
                ...p,
                fileId: uploadResult?.find(
                  (f) => f.filename === p.filename
                )?.id ?? null,
                fileUrl: `${uploadResult?.find(
                  (f) => f.filename === p.filename
                )?.fileUrl}` ?? null,
                success: uploadResult?.find(
                  (f) => f.filename === p.filename
                )?.success ?? false,
                progress: uploadResult?.find(
                  (f) => f.filename === p.filename
                )?.success === true ? 1 : -1,
              }));
              onAttachmentUploadedHandler(updatedProgress);
            },
            (cancelToken) => {
              attachmentUploadCancelToken.current = cancelToken;
            }
          );
        }}
      />

      <ImageButton
        alt="Add attachment"
        svg={SvgPaperclip}
        className={`${Styles.AttachButton} px-3 text-action`}
        onClick={() => {
          fileSelectRef.current?.selectFiles();
        }}
      />

      <UtilButton
        disabled={isSendDisabled() || isSending}
        color="primary"
        size="small"
        location="top"
        className={`ml-xl-2 ${Styles.SendButton}`}
        onClick={onSendHandler}
      >
        Send
      </UtilButton>
    </div>
  );
};

export default forwardRef<MessageComposerRef, MessageComposerProps>(MessageComposer);
