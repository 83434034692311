import React, { useCallback, useState } from "react";

// Time before a toast is dismissed automatically
const toastTimeout = 1500;

// Time from when a toast is dismissed before it is removed from the UI
// This leaved some time for the fade animation
const cleanupTimeout = 1000;

let addToast: (message: string) => void;

interface ToastMessage {
  id: string,
  content: string,
  visible: boolean,
  dismiss:() => void
}

// internal hook to read toast messages
export const useVisibleToasts = () => {
  const [toastMessages, setToastMessages] = useState<ToastMessage[]>([]);

  const cleanToast = (id: string) => {
    setToastMessages((prevValue) => [
      ...prevValue.filter((t) => t.id !== id),
    ]);
  };

  const dismissToast = (id: string) => {
    setToastMessages((prevValue) => [
      ...prevValue.map((t) => {
        if (t.id === id) {
          return { ...t, visible: false };
        }
        return t;
      }),
    ]);

    setTimeout(() => {
      cleanToast(id);
    }, cleanupTimeout);
  };

  addToast = (message) => {
    const newId = Math.random().toString(36);

    setTimeout(() => {
      dismissToast(newId);
    }, toastTimeout);

    setToastMessages((prevValue) => [...prevValue,
      {
        id: newId,
        content: message,
        visible: true,
        dismiss: () => { dismissToast(newId); },
      },
    ]);
  };
  return toastMessages;
};

const useToast = () => {
  const showToast = useCallback((message: string) => {
    addToast(message);
  }, []);

  return showToast;
};

// Higher order component for legacy components (class based components)
interface ToastComponentProps {
  showToast: (message: string) => void
}

export const withToast = (
  WrappedComponent: React.ComponentType<ToastComponentProps>
  // eslint-disable-next-line react/prefer-stateless-function
) => class extends React.Component {
  render() {
    return (
      <WrappedComponent
        showToast={(message: string) => addToast(message)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...this.props}
      />
    );
  }
};

export default useToast;
