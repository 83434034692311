import { useCallback } from "react";

let threadLock = false;
const que: Array<() => void> = [];
const useThreadSafe = (handler: () => Promise<void>) : () => void => {
  const checkQue = () => {
    if (que.length > 0) {
      const nextItem = que.pop();
      if (nextItem != null) {
        nextItem();
      }
    }
  };

  const run = useCallback(async () => {
    if (threadLock) {
      que.push(run);
      return;
    }

    threadLock = true;
    await handler();
    threadLock = false;
    checkQue();
  }, [handler]);

  const returnHandler = useCallback(async () => {
    run();
  }, [run]);
  return returnHandler;
};

export default useThreadSafe;
