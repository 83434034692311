import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { DataFeatures } from "isuppli-react-components";

import { newOnboardingRequest, getOnboardingRequest } from "../../http/index";

import {
  showLoadingScreen,
  hideLoadingScreen,
  showErrorDialog,
} from "../../Store/actions";
import { setOnboardingRequestObject } from "../../Store/onboardingRequestActions";

import MTPCompanyDetails from "./MTPCompanyDetails";
import MTPRestrictions from "./MTPRestrictions";
import MTPMotivation from "./MTPMotivation";
import Loading from "../Loading";
import MTPCategories from "./MTPCategories";
import MTPManualSupplierStep from "./MTPManualSupplierStep/MTPManualSupplierStep";
import CompanyConfigurationFeatures from "../../Util/Enumerators/CompanyConfigurationFeatures";
import { StaticCompanyDataTypes } from "../../Util/Enumerators/staticCompanyDataTypes";

const mapStateToProps = (state) => ({
  onboardingRequest: state.onboardingRequest,
  companyId: state.companyId,
  allowedCompanyFeatures: state.companyConfiguration.allowedCompanyFeatures,
  supplierTypes: state.staticCompanyData[StaticCompanyDataTypes.supplierType],
});

const mapDispatchToProps = (dispatch) => ({
  setOnboardingRequestObject: (onbordingRequest) => {
    dispatch(setOnboardingRequestObject(onbordingRequest));
  },
  showLoadingScreen: (msg) => {
    dispatch(showLoadingScreen(msg));
  },
  hideLoadingScreen: () => {
    dispatch(hideLoadingScreen());
  },
  showErrorDialog: () => {
    dispatch(showErrorDialog(true));
  },
});

class OnboardingRequestEdit extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const { id, step } = this.props.match.params;

    try {
      this.props.showLoadingScreen();

      // load onboarding request
      if (id === "new") {
        const newRequest = await newOnboardingRequest();
        this.props.setOnboardingRequestObject(newRequest);
      } else {
        const onboardingRequest = await getOnboardingRequest(id);
        this.props.setOnboardingRequestObject({
          ...onboardingRequest,
          isVatRegistered:
            !!onboardingRequest.vatNumber
            && onboardingRequest.vatNumber.length > 0
              ? 1
              : 0,
        });
      }
      this.props.hideLoadingScreen();
    } catch (error) {
      this.props.showErrorDialog();
      this.props.hideLoadingScreen();
      throw error;
    }

    if (this.state.loading) {
      this.setState({ loading: false });
    }

    if (step !== "1") {
      // we are reloading and we are not at the first step, force the browser to the fist step
      this.props.history.replace(`/myoffice/onboardingrequest/${id}/1`);
    }
  }

  render() {
    const supplierCompanyId = this.props.onboardingRequest.supplierCompanyId;

    const supplierTypeDataFeatures = this.props.supplierTypes
      ? this.props.supplierTypes
        .find((supplierType) => supplierType.id === this.props.onboardingRequest?.supplierTypeId)
            ?.dataFeatures
      : DataFeatures.None;

    // eslint-disable-next-line no-bitwise
    const hasBankStep = (supplierTypeDataFeatures & DataFeatures.BankingDetails);

    // Array of MTP Steps
    const MTPSteps = [];
    MTPSteps.push(<MTPCompanyDetails stepNumber={MTPSteps.length + 1} />);

    if ((
      // eslint-disable-next-line no-bitwise
      this.props.allowedCompanyFeatures & CompanyConfigurationFeatures.MTOProductAndSegments
    ) > 0) {
      MTPSteps.push(<MTPCategories stepNumber={MTPSteps.length + 1} />);
    }

    // eslint-disable-next-line no-bitwise
    if ((this.props.allowedCompanyFeatures & CompanyConfigurationFeatures.MTORestrictions) > 0) {
      MTPSteps.push(<MTPRestrictions stepNumber={MTPSteps.length + 1} />);
    }

    // eslint-disable-next-line no-bitwise
    if ((this.props.allowedCompanyFeatures & CompanyConfigurationFeatures.MTOMotivation) > 0) {
      MTPSteps.push(<MTPMotivation stepNumber={MTPSteps.length + 1} />);
    }

    // Generate manual some supplier steps from MTPSteps
    const ManualMTPSteps = MTPSteps.map((component, index) => ({
      ...component,
      props: {
        // adjust the step number
        stepNumber: hasBankStep ? index + 5 : index + 4,
      },
    }));

    const getComponentForStep = (step) => {
      switch (step) {
        case "m-1":
        case "1":
          return MTPSteps[0];
        case "2":
          return MTPSteps[1];
        case "3":
          return MTPSteps[2];
        case "4":
          return MTPSteps[3];
        case "m-2":
          return <MTPManualSupplierStep stepNumber={2} companyId={supplierCompanyId} />;
        case "m-3":
          return <MTPManualSupplierStep stepNumber={3} companyId={supplierCompanyId} />;
        case "m-4":
          return <MTPManualSupplierStep stepNumber={4} companyId={supplierCompanyId} />;
        case "m-5":
          if (hasBankStep) {
            return <MTPManualSupplierStep stepNumber={5} companyId={supplierCompanyId} />;
          }
          return ManualMTPSteps[1];
        case "m-6":
          if (hasBankStep) {
            return ManualMTPSteps[1];
          }
          return ManualMTPSteps[2];
        case "m-7":
          if (hasBankStep) {
            return ManualMTPSteps[2];
          }
          return ManualMTPSteps[3];
        case "m-8":
          if (hasBankStep) {
            return ManualMTPSteps[3];
          }
          return null;
        default:
          return null;
      }
    };

    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <div className="bg-white">
        {getComponentForStep(this.props.match.params.step)}
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OnboardingRequestEdit)
);
