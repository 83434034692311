import React, { Fragment } from "react";
// eslint-disable-next-line no-unused-vars
import BeeValidationStatus from "../../Util/Enumerators/beeValidationStatus";
import RadioSelector from "../../Controls/RadioSelector";
import BeeStatusBadge from "./BeeStatusBadge";

import {
  onHasBeeCertificateChangeHandler,
} from "../../Validation/onChangeValidations";
import CompanyGroupingType from "../../Util/Enumerators/CompanyGroupingType";

export interface Event {
  target: {
    name: string,
    value: any
  },
}

const BeeStatus = (
  {
    hasBeeCertificate,
    validationStatus,
    hasBeeValidationMessage = "",
    onInputChange,
    onHasBeeCertificateChange,
    readOnly = false,
    companyGroupingType,
  }
    :
    {
      hasBeeCertificate?: boolean
      validationStatus?: BeeValidationStatus,
      hasBeeValidationMessage?: string
      onInputChange: (ev: Event) => void,
      onHasBeeCertificateChange: (newVal: number) => void,
      readOnly?: boolean,
      companyGroupingType: CompanyGroupingType,
    }
) => (
  <Fragment>
    <BeeStatusBadge
      hasBeeCertificate={hasBeeCertificate}
      validationStatus={validationStatus}
    />
    <div className="row mt-4 mb-4">
      <div className="col-12">
        <RadioSelector
          name="hasBeeCertificate"
          label="Does your company have a B-BBEE Certificate/Affidavit?"
          value={hasBeeCertificate ?? 0}
          options={[
            { value: 1, display: "yes" },
            { value: 0, display: "no" },
          ]}
          additional={hasBeeValidationMessage}
          onChangeCallback={(ev: Event) => {
            onInputChange(ev);
            onHasBeeCertificateChangeHandler(
              ev.target.value,
              onInputChange
            );
            onHasBeeCertificateChange(ev.target.value);
          }}
          disabled={
            readOnly
              || companyGroupingType === CompanyGroupingType.Foreign
          }
        />
      </div>
      <div className="col-12 col-sm-2" />
    </div>
  </Fragment>
);

export default BeeStatus;
