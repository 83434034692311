import {
  BasicTable,
  ActionsDropDown,
  ActionsDropDownItem,
  // eslint-disable-next-line no-unused-vars
  BasicTableCell,
  // eslint-disable-next-line no-unused-vars
  BasicTableColumn,
  hasFeature,
  SystemFeatures,
} from "isuppli-react-components";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import useShowError from "../../Hooks/useShowError";
import {
  deleteShareholder,
  GetShareholders,
  getSupplierShareholders,
} from "../../http/Shareholders/ShareholderApi";
import { ShareholdersViewMessage } from "../../http/Shareholders/ShareholdersViewMessage"; // eslint-disable-line no-unused-vars
import { setShareholderDetails } from "../../Store/registrationActions";
import ShareholderDetailsModal from "../ShareholderDetailsModal/ShareholderDetailsModal";
// eslint-disable-next-line no-unused-vars
import { EditShareholderMessage } from "../../http/Shareholders/EditShareholderMessage";
// eslint-disable-next-line no-unused-vars
import { ReduxState } from "../../Store/ReduxState";

const ShareholderTable = ({
  supplierCompanyId = undefined,
  readOnly = false,
}: {
  supplierCompanyId?: number;
  readOnly?: boolean;
}) => {
  const [shareHolderData, setShareHolderData] = useState<
    Array<EditShareholderMessage>
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const [showAddEditModal, setShowAddEditModal] = useState(false); // Used to display the add/edit modal dialog for shareholders.
  const [modalReadOnly, setModalReadOnly] = useState(false);
  const [currentShareholderId, setCurrentShareHolderId] = useState<
    number | null
  >(null);

  const allowedFeatures = useSelector(
    (state: ReduxState) => state.loggedInUserDetails.allowedFeatures
  );

  const canView = hasFeature(
    allowedFeatures,
    SystemFeatures.ViewSupplierShareholderDetails
  );

  const showError = useShowError();
  const dispatch = useDispatch();

  useEffect(() => {
    const loader = async () => {
      setIsLoading(true);
      try {
        // Retrieve data when logged in as the company user (e.g registration & companyDetails)
        if (supplierCompanyId === undefined) {
          const data: Array<EditShareholderMessage> = await GetShareholders();

          setShareHolderData(data ?? []);
          dispatch(data);
        } else {
          const data: ShareholdersViewMessage = await getSupplierShareholders(
            supplierCompanyId
          );
          setShareHolderData(data?.shareholders ?? []);
        }
      } catch {
        setIsLoading(false);
      }
      setIsLoading(false);
    };

    loader();
  }, [supplierCompanyId, dispatch]);

  useEffect(() => {
    dispatch(setShareholderDetails(shareHolderData));
  }, [shareHolderData, dispatch]);

  const onUpdateData = (item: EditShareholderMessage) => {
    const updates = shareHolderData.map((c) => {
      if (c.id === item.id) {
        return {
          ...c,
          ...item,
        };
      }
      return { ...c };
    });

    setShareHolderData(updates);
    setShowAddEditModal((prevState) => !prevState);
  };

  const onViewRow = (shareHolderId: number, readOnlyModal: boolean) => {
    setCurrentShareHolderId(shareHolderId);
    setModalReadOnly(readOnlyModal);
    setShowAddEditModal((prevState) => !prevState);
  };

  const addNewRow = () => {
    const newRow = {
      id: Math.min(...shareHolderData.map((c) => c.id), 0) - 1,
    } as EditShareholderMessage;
    setShareHolderData([...shareHolderData, newRow]);

    onViewRow(newRow.id, false);
  };

  const onDeleteRow = (shareholderId: number) => {
    const deleteRow = async () => {
      await deleteShareholder(shareholderId);
    };

    try {
      deleteRow();

      const updates = shareHolderData.filter((x) => x.id !== shareholderId);
      dispatch(setShareholderDetails(updates));
      setShareHolderData(updates);
    } catch {
      showError();
    }
  };

  const columnConfig: Array<BasicTableColumn> = [
    {
      key: "",
      text: "*Shareholder Name",
      align: "left",
      size: "1fr",
    },
    {
      key: "Share",
      align: "left",
      text: "*Share %",
      size: "1fr",
    },
    {
      key: "Position",
      align: "left",
      text: "*Title/Position",
      size: "1fr",
    },
    {
      key: "Actions",
      text: "Actions",
      size: "auto",
      align: "center",
    },
  ];

  const rowConfig: BasicTableCell<{}>[][] = shareHolderData.length <= 0
    ? []
    : shareHolderData.map((sh, index) => [
      {
        key: `Name_${index}`,
        value: sh.fullName,
      },
      {
        key: `Share_${index}`,
        value: sh.sharePercentage,
      },
      {
        key: `Postion_${index}`,
        value: sh.title,
      },
      {
        key: `actions_${index}`,
        valueTemplate: () => (
          <>
            <ActionsDropDown label="Actions">
              {(supplierCompanyId === undefined
              || (supplierCompanyId !== undefined && canView)) && (
                <ActionsDropDownItem onClick={() => onViewRow(sh.id, true)}>
                  View
                </ActionsDropDownItem>
              )}
              {supplierCompanyId === undefined && (
                <>
                  <ActionsDropDownItem
                    onClick={() => onViewRow(sh.id, false)}
                  >
                    Edit
                  </ActionsDropDownItem>
                  <ActionsDropDownItem onClick={() => onDeleteRow(sh.id)}>
                    Delete
                  </ActionsDropDownItem>
                </>
              )}
            </ActionsDropDown>
          </>
        ),
      },
    ]);

  /*
    This method will check for any empty shareholders left behind after cancelling an add operation, and clean up
    the shareHolderData array, and table.
  */
  const removeEmptyShareholders = () => {
    if (
      shareHolderData.length > 0
      && shareHolderData[shareHolderData.length - 1].fullName === undefined
      && shareHolderData[shareHolderData.length - 1].idNumber === undefined
      && shareHolderData[shareHolderData.length - 1].title === undefined
    ) {
      shareHolderData.pop();
    }
  };

  return (
    <Fragment>
      {showAddEditModal && (
        <ShareholderDetailsModal
          shareholderMessage={
            shareHolderData.find((x) => x.id === currentShareholderId)
            ?? ({} as EditShareholderMessage)
          }
          readOnly={modalReadOnly}
          onClose={() => {
            removeEmptyShareholders();
            setShowAddEditModal((prevState) => !prevState);
          }}
          onUpdate={onUpdateData}
        />
      )}

      <div className="my-3 py-3">
        <div>
          <h2>SHAREHOLDERS</h2>
        </div>
        {!readOnly && (
          <h3>
            List the Shareholders/Directors/Members/Owners of your Enterprise
          </h3>
        )}
        <BasicTable
          emptyMessage="No Shareholders Entered"
          loading={isLoading}
          columns={columnConfig}
          rows={rowConfig}
        />
        {!readOnly && (
          <Button onClick={addNewRow} color="link">
            + Add new shareholder
          </Button>
        )}
      </div>
    </Fragment>
  );
};

export default ShareholderTable;
