import React, { useEffect } from "react";
import {
  Route,
  RouteProps, // eslint-disable-line no-unused-vars
} from "react-router-dom";

// eslint-disable-next-line no-unused-vars
import SystemFeatures from "../../utils/enums/SystemFeatures";

import { useSystemFeatureCheck } from "../../Hooks/useSystemFeatureCheck";
import { useShowNotAllowedError } from "../../Hooks/useNotAllowedError";

export interface FeatureRouteProps extends RouteProps {
  feature: SystemFeatures,
  strictFeatureCheck?: boolean,
}

const FeatureRoute = (
  {
    feature,
    location,
    component,
    render,
    path,
    exact,
    sensitive,
    strict,
    strictFeatureCheck = true,
    children,
  } : FeatureRouteProps
) => {
  const featureCheck = useSystemFeatureCheck(strictFeatureCheck);
  const showNotAllowedError = useShowNotAllowedError();

  useEffect(() => {
    if (feature != null && (!featureCheck(feature))) {
      showNotAllowedError(feature);
    }
  }, [feature, showNotAllowedError]);

  if (feature != null && (!featureCheck(feature))) {
    return <h1>NOT ALLOWED</h1>;
  }
  return (
    <Route
      location={location}
      component={component}
      render={render}
      path={path}
      exact={exact}
      sensitive={sensitive}
      strict={strict}
    >
      {children}
    </Route>
  );
};

export default FeatureRoute;
