import { useRef } from "react";
import {
  HubConnectionBuilder,
  // eslint-disable-next-line no-unused-vars
  HubConnection,
} from "@microsoft/signalr";
import {
  // eslint-disable-next-line no-unused-vars
  Message,
} from "../http/Messaging/messagesApi";

export enum SignalRNotifications {
  ChannelUpdates = "ChannelUpdates", // eslint-disable-line no-unused-vars
  UnreadNotifications = "UnreadNotifications", // eslint-disable-line no-unused-vars
  SubscriptionUpdates = "SubscriptionUpdates", // eslint-disable-line no-unused-vars
  SupplierImportProgressUpdates = "SupplierImportProgressUpdates", // eslint-disable-line no-unused-vars
  BulkMessageProgress = "BulkMessageProgress", // eslint-disable-line no-unused-vars
}

export interface NewMessageNotification {
  channelId : number;
  newMessage: Message;
}

export interface UnreadNotificationsUpdate {
  totalUnreadNotifications: number;
}

export enum SupplierImportStatus{
  None, // eslint-disable-line no-unused-vars
  Enqued, // eslint-disable-line no-unused-vars
  BusyProcessing, // eslint-disable-line no-unused-vars
  Complete, // eslint-disable-line no-unused-vars
  Finalized, // eslint-disable-line no-unused-vars
}

type handler<T> = (message: T) => void
let connection: HubConnection | null = null;
const registeredHandlers: {
  [key: string]: {
    activeIndexes: number[],
    [key: number]: handler<any> | undefined
  }
} = {};

const useSignalR = <T extends {}>(event: SignalRNotifications, handler: handler<T>) => {
  const registeredHandlerIndex = useRef<number>();

  if (connection == null) {
    connection = new HubConnectionBuilder()
      .withUrl("/hubs/notifications")
      .withAutomaticReconnect()
      .build();

    connection.start();
  }

  if (registeredHandlerIndex.current == null) {
    if (registeredHandlers[event] == null) {
      registeredHandlers[event] = { activeIndexes: [] };
      connection.on(event, (eventDetails) => {
        registeredHandlers[event].activeIndexes.forEach((index) => {
          const registeredHandler = registeredHandlers[event][index];
          if (registeredHandler != null) {
            registeredHandler(eventDetails);
          }
        });
      });
    }
    let newIndex = 0;
    if (registeredHandlers[event].activeIndexes.length > 0) {
      newIndex = Math.max(...registeredHandlers[event].activeIndexes) + 1;
    }
    registeredHandlers[event].activeIndexes.push(newIndex);
    registeredHandlers[event][newIndex] = handler;
    registeredHandlerIndex.current = newIndex;
  } else {
    // update handler
    registeredHandlers[event][registeredHandlerIndex.current] = handler;
  }

  const disconnectFunction = useRef(() => {
    // disconnect
    if (registeredHandlerIndex.current != null && connection != null) {
      // clear handler
      registeredHandlers[event][registeredHandlerIndex.current] = undefined;

      // update active indexes
      registeredHandlers[event].activeIndexes = [
        ...registeredHandlers[event]
          .activeIndexes
          .filter((c) => c !== registeredHandlerIndex.current),
      ];
    }
  });

  return disconnectFunction.current;
};

export default useSignalR;
