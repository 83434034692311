import React from "react";

import ActionsDropdown from "../ActionsDropDown/ActionsDropdown";
import DateInput from "../Forms/DateInput/DateInput";

import Styles from "./DateRangeDropDown.module.scss";

function DateRangeInput({
  label, onChange, className, values,
  buttonClassName,
} : {
  label: string,
  onChange: (dates: Date[]) => void,
  className?: string,
  buttonClassName?: string,
  values: Date[],
}) {
  const onChangeHandler = (index: number, newValue: Date) => {
    const dates = [...values];
    const [fromDate, toDate] = [...dates];
    dates[index] = newValue;

    // Check date restrictions
    if (fromDate > toDate) {
      dates[1] = fromDate;
    }

    onChange(dates);
  };

  const badgeValue = values?.some((dateValue) => dateValue !== null)
    ? "Selected"
    : undefined;

  return (
    <ActionsDropdown
      badge={badgeValue}
      label={label}
      styleVal="light"
      className={`${className} ${Styles.DateRangeDropdown}`}
      buttonClassName={buttonClassName}
    >
      <DateInput
        label="From"
        value={values != null ? values[0] : undefined}
        onChange={(newValue) => onChangeHandler(0, newValue)}
      />

      <DateInput
        label="To"
        value={values != null ? values[1] : undefined}
        onChange={(newValue) => onChangeHandler(1, newValue)}
      />
    </ActionsDropdown>
  );
}

export default DateRangeInput;
