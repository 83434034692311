// eslint-disable-next-line no-unused-vars
import { StaticCompanyDataTypes } from "../Util/Enumerators/staticCompanyDataTypes";
import useStaticCompanyData from "./useStaticCompanyData";

const StaticCompanyData = (
  { optionsToLoad }
  :
  {
    optionsToLoad: StaticCompanyDataTypes[]
  }
) => {
  useStaticCompanyData(optionsToLoad);
  return null;
};

export default StaticCompanyData;
