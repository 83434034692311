export const SET_ONBOARDING_REQUEST_OBJECT = "SET_ONBOARDING_REQUEST_OBJECT";

export const SET_ONBOARDING_REQUEST_OBJECT_PROPERTY = "SET_ONBOARDING_REQUEST_OBJECT_PROPERTY";

export function setOnboardingRequestObject(objectValue) {
  return {
    type: SET_ONBOARDING_REQUEST_OBJECT,
    payload: objectValue,
  };
}

export function setOnboardingReqeustAllowences(allowences) {
  return {
    type: SET_ONBOARDING_REQUEST_OBJECT_PROPERTY,
    payload: {
      fieldName: "supplierAllowancesList",
      fieldValue: allowences,
    },
  };
}

export function setOnboardingReqeustPruchaceValues(values) {
  return {
    type: SET_ONBOARDING_REQUEST_OBJECT_PROPERTY,
    payload: {
      fieldName: "supplierPurchaseValueGrowthList",
      fieldValue: values,
    },
  };
}

export function onOnboardingInputChange(ev) {
  return {
    type: SET_ONBOARDING_REQUEST_OBJECT_PROPERTY,
    payload: {
      fieldName: ev.target.name,
      fieldValue: ev.target.value,
    },
  };
}
