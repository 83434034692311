// eslint-disable-next-line no-unused-vars
import { SystemFeatures } from "isuppli-react-components";
import { useDispatch } from "react-redux";
import useSignalR, { SignalRNotifications } from "../../Hooks/useSignalR";
import { setSubscriptionInfo } from "../../Store/actions";

interface SubscriptionUpdateMessage {
  subscriptionTierId: number,
  subscriptionName: string,
  allowedFeatures: SystemFeatures,
}

const SubscriptionCheck = () => {
  const dispatch = useDispatch();

  useSignalR<SubscriptionUpdateMessage>(SignalRNotifications.SubscriptionUpdates, (message) => {
    dispatch(setSubscriptionInfo(message));
  });
  return null;
};

export default SubscriptionCheck;
