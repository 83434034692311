/* eslint-disable no-undef */
const SystemFeatures = {
  /* eslint-disable no-unused-vars */
  None: BigInt(0),
  ErpIntegration: BigInt(0x1),
  EnterpriseConfig: BigInt(0x2),
  SupplierSearch: BigInt(0x4),
  SupplierChecks: BigInt(0x8),
  RfxBulletinBoard: BigInt(0x10),
  RfxParticipate: BigInt(0x20),
  RfxCreateOpportunity: BigInt(0x40),
  OnboardingCreateRequest: BigInt(0x80),
  OnboardingApprove1: BigInt(0x100),
  OnboardingApprove2: BigInt(0x200),
  CompanyProfile: BigInt(0x400),
  CompanyProfileEdit: BigInt(0x800),
  Messaging: BigInt(0x1000),
  UserManagement: BigInt(0x2000),
  AuditTrails: BigInt(0x4000),
  Subscriptions: BigInt(0x8000),
  SubscriptionsEdit: BigInt(0x10000),
  OngoingCompliance: BigInt(0x20000),
  DashboardBulletinBoardSummary: BigInt(0x40000),
  DashboardSupplierCompaniesSummaries: BigInt(0x80000),
  PPReportDashboard: BigInt(0x200000),
  ViewSupplierBankDetails: BigInt(0x400000),
  BackofficeAdministrator: BigInt(0x800000),
  ServiceAdministrator: BigInt(0x100000),
  ManageErpEvents: BigInt(0x2000000),
  ManualSuppliers: BigInt(0x4000000),
  SubAccounts: BigInt(0x8000000),
  OnboardingAddSupplier: BigInt(0x10000000),
  MultipleSupplierSelect: BigInt(0x20000000),
  ViewMoreSupplierDetails: BigInt(0x40000000),
  OnboardingAddSupplierApproval: BigInt(0x80000000),
  UseAdvancedSearchFilters: BigInt(0x100000000),
  DashboardOwnCompanyComplianceSummaries: BigInt(0x200000000),
  ViewSupplierComplianceDetails: BigInt(0x400000000),
  ColourIndicators: BigInt(0x800000000),
  DetailedAuditTrails: BigInt(0x1000000000),
  BroadcastMessaging: BigInt(0x2000000000),
  SupplierExport: BigInt(0x4000000000),
  SupplierImport: BigInt(0x8000000000),
  Configuration: BigInt(0x10000000000),
  ConfigurationCompanyInfo: BigInt(0x20000000000),
  ConfigurationOnboarding: BigInt(0x40000000000),
  ConfigurationUsers: BigInt(0x80000000000),
  ConfigurationCompliance: BigInt(0x100000000000),
  ConfigurationOnboardingVendorNumber: BigInt(0x200000000000),
  ConfigurationOnboardingSupplierTypes: BigInt(0x400000000000),
  ConfigurationCompanyInfoOnboardingBee: BigInt(0x800000000000),
  RfxEvaluateOpportunity: BigInt(0x1000000000000),
  ConfigurationOnboardingBankDetails: BigInt(0x2000000000000),
  MTOSupplierSponsorship: BigInt(0x4000000000000),
  ConfigurationSupplierEditClientSpecificCategories: BigInt(0x8000000000000),
  ViewSupplierShareholderDetails: BigInt(0x10000000000000),
  OnboardingNewCompany: BigInt("0x20000000000000"),
  DelegateOnboardingRequestApproval2: BigInt("0x40000000000000"),
  ApproveDelegatedOnboardingRequestApproval2: BigInt("0x80000000000000"),
  AssignManualManualVendorNumber: BigInt("0x100000000000000"),
  OnboardingCompletionCommunication: BigInt("0x200000000000000"),
  CanSuspendMTO: BigInt("0x400000000000000"),
  CanExportCSV: BigInt("0x800000000000000"),
  SupplierComplianceFunding: BigInt("0x1000000000000000"),
  AdvancedMySuppliersFilter: BigInt("0x2000000000000000"),
  CanUpdatePaymentTerms: BigInt("0x4000000000000000"),
};

type SystemFeatures = bigint;

/* eslint-disable no-bitwise */

// Used to perform a bit wise AND and OR operations on a 64bit integer
// To-do: Delete this at a later stage
export const bitWiseOperation = (v1: bigint = BigInt(0), v2: bigint = BigInt(0), operationType: "AND" | "OR") => {
  const result = operationType === "AND" ? v1 & v2 : v2 | v1;
  return result;
};

export const hasFeature = (
  userAllowedFeature: SystemFeatures,
  feature: SystemFeatures = SystemFeatures.None
) => feature === SystemFeatures.None
    // eslint-disable-next-line eqeqeq
    || (bitWiseOperation(userAllowedFeature, feature, "AND")) == feature;

export const hasSomeFeature = (
  userAllowedFeature: SystemFeatures,
  feature: SystemFeatures = SystemFeatures.None
) => feature === SystemFeatures.None
    || (bitWiseOperation(userAllowedFeature, feature, "AND")) > 0;

/* eslint-disable no-bitwise */

export default SystemFeatures;
