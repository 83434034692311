import React from "react";
import { ShadowCard } from "isuppli-react-components";
import StarRatingInput from "../../../Controls/StarRatingInput/StarRatingInput";

const OverallRatingCard = (
  {
    rating,
    totalRatings,
  }
  :
  {
    rating: number,
    totalRatings: number,
  }
) => (
  <ShadowCard>
    <div className="mb-3 mx-3 p-4">
      <div className="row d-flex flex-column">
        <h3><b>My Overall Rating</b></h3>
        <div className="d-flex justify-content-between mt-2">
          <StarRatingInput
            value={rating}
            totalRatings={totalRatings}
            showTotalRatings
            readonly
            size="small"
          />
        </div>
      </div>
    </div>
  </ShadowCard>
);
export default OverallRatingCard;
