import React, {
  useEffect, useState, Fragment, useRef,
} from "react";
import {
  BasicTable,
  ActionsDropDown,
  ActionsDropDownItem,
  // eslint-disable-next-line no-unused-vars
  FileSelectorRef,
  Spinner,
  FileSelector,
  ConfirmationModal,
} from "isuppli-react-components";
import { Progress } from "reactstrap";

// eslint-disable-next-line no-unused-vars
import { AxiosRequestConfig } from "axios";
import {
  // eslint-disable-next-line no-unused-vars
  ContractMessage,
  getContracts,
  approveContract,
  uploadContract,
  uploadContractForTemplate,
  getContractTiers,
  getContractAgreements,
  // eslint-disable-next-line no-unused-vars
  ContractTierMessage,
} from "../../http/Contracting/contractingApi";

import useShowError from "../../Hooks/useShowError";
import { getSupplierContractInfoMessage } from "../../http/Marketplace/onboardingAPI";

interface ContractWithProgress extends ContractMessage{
  progress?: number,
  done?: boolean,
  error?: boolean,
  uploadError?: string
}
const AgreementsTable = ({
  signatoryCompanyId,
  currentUserIsBuyer,
  contractTiersList,
  onContractApproved,
} : {
  signatoryCompanyId : number,
  currentUserIsBuyer: boolean,
  contractTiersList?: Array<ContractTierMessage>,
  onContractApproved?: (approvals: Array<boolean>) => void,
}) => {
  const showError = useShowError();
  const [loadingTable, setLoadingTable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [selectedContract, setSelectedContract] = useState<ContractWithProgress | null>();
  const [agreements, setAgreements] = useState<ContractWithProgress[]>([]);
  const [contractTierId, setContractTierId] = useState(0);

  useEffect(() => {
    const loader = async () => {
      setLoadingTable(true);

      try {
        // make sure contracts are populated if the user is the supplier. This will happen
        // during registration or after invite acceptance
        if (signatoryCompanyId > 0) {
          const contracts = await getContracts(signatoryCompanyId, !currentUserIsBuyer);
          const newAgreements = contracts.items;
          onContractApproved?.(newAgreements.map((a) => a.approved || a.noConfirmationRequired));
          const supplierContractInfo = await getSupplierContractInfoMessage(signatoryCompanyId);
          if (supplierContractInfo.supplierTypeId != null
            && supplierContractInfo.externalContractTierId != null) {
            // to do, load contract tier static data in a better manner
            const contractTiers = contractTiersList ?? await getContractTiers();
            // use external contract tier id to get contract tier id
            const newContractTierId = contractTiers
              .find((tier) => (
                tier.externalContractTierId === supplierContractInfo.externalContractTierId
                && tier.supplierTypeId === supplierContractInfo.supplierTypeId))?.id ?? 0;
            setContractTierId(newContractTierId);

            // call get agreements api using contract tier id
            if (newContractTierId > 0) {
              const contractTemplates = await getContractAgreements(newContractTierId);

              // add missing templates
              contractTemplates.forEach((contractTemplate) => {
                if (!contracts.items.map((item) => item.contractTemplateId)
                  .includes(contractTemplate.id)) {
                  const agreement: ContractMessage = {
                    contractTemplateId: contractTemplate.id,
                    agreementName: contractTemplate.agreementName,
                    approved: false,
                    approvedByOtherSignatories: false,
                    fileName: "",
                    id: 0,
                    noConfirmationRequired: contractTemplate.noConfirmationRequired,
                    allowNewVersions: true,
                  };
                  newAgreements.push(agreement);
                }
              });
            }
          }

          setAgreements(newAgreements);
        }
      } catch (error) {
        showError();
      }

      setLoadingTable(false);
    };

    loader();
  }, [signatoryCompanyId, showError, currentUserIsBuyer, contractTiersList]);

  const updateContractInTable = (newContract: ContractWithProgress) => {
    setAgreements((oldvalue: ContractWithProgress[]) => {
      const newAgreements = oldvalue.map(
        (c) => {
          if (c.id !== 0) {
            if (c.id === newContract.id) return newContract;
          } else if (c.contractTemplateId === newContract.contractTemplateId) return newContract;
          return c;
        }
      );
      onContractApproved?.(newAgreements.map((a) => a.approved || a.noConfirmationRequired));
      return newAgreements;
    });
  };

  const getViewAction = (contract: ContractWithProgress) => (
    (contract.done ?? true) && !(contract.error ?? false) && (contract.id !== 0)
      ? (
        <ActionsDropDownItem
          tag="a"
          href={`/api/contracting/contracts/${contract.id}/download`}
          download={contract.fileName}
          target="_blank"
          rel="noopener noreferrer"
        >
          View
        </ActionsDropDownItem>
      )
      : null
  );

  const [uploadingContract, setUploadingContract] = useState<ContractWithProgress>();
  const fileSelector = useRef<FileSelectorRef>(null);
  const getUploadAction = (contract: ContractWithProgress) => (
    !contract.approved
    && (contract.done ?? true)
    && contract.allowNewVersions
    && currentUserIsBuyer
      ? (
        <ActionsDropDownItem onClick={() => {
          // timeout makes sure that drop down closed before modal is opened
          setTimeout(() => {
            setUploadingContract(contract);
            fileSelector.current?.selectFiles();
          });
        }}
        >
          {
            (contract.id !== 0)
              ? "Upload New Version"
              : "Upload"
          }
        </ActionsDropDownItem>
      )
      : null
  );

  const getUploadCancelAction = (contract: ContractWithProgress) => (
    contract.error === true
      ? (
        <ActionsDropDownItem onClick={() => {
        // timeout makes sure that drop down closed before modal is opened
          setTimeout(() => {
            updateContractInTable({
              ...contract,
              error: undefined,
              done: undefined,
              progress: undefined,
            });
          });
        }}
        >
          Cancel Upload
        </ActionsDropDownItem>
      )
      : null);

  const onFilesSelectedHandler = async (fileNames: Array<string>,
    formData: FormData, uploadErrors: Array<string>) => {
    if (uploadingContract == null) {
      return;
    }
    const contractIdToUse = uploadingContract.id;

    updateContractInTable({
      ...uploadingContract,
      done: false,
      progress: 0,
      error: false,
    });

    const config: AxiosRequestConfig = {
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded * 100) / progressEvent.total;
        updateContractInTable({
          ...uploadingContract,
          done: false,
          progress,
          error: false,
        });
      },
    };

    try {
      if (contractIdToUse === 0) {
        const contractId = await uploadContractForTemplate(
          signatoryCompanyId,
          uploadingContract.contractTemplateId,
          contractTierId,
          formData,
          config
        );

        const newContract = {
          ...uploadingContract,
          id: contractId,
        };
        updateContractInTable({
          ...newContract,
          fileName: fileNames[0],
          approved: false,
          approvedByOtherSignatories: false,
          done: true,
          progress: 1,
          error: false,
        });
      } else {
        await uploadContract(
          contractIdToUse,
          formData,
          config
        );

        updateContractInTable({
          ...uploadingContract,
          fileName: fileNames[0],
          approved: false,
          approvedByOtherSignatories: false,
          done: true,
          progress: 1,
          error: false,
        });
      }
    } catch (error) {
      updateContractInTable({
        ...uploadingContract,
        done: true,
        progress: 1,
        error: true,
        uploadError: uploadErrors[0],
      });
    }
  };

  const handleContractApproval = async (contract: ContractWithProgress | null | undefined) => {
    setLoading(true);
    if (contract != null) {
      try {
        await approveContract(contract.id);
        // set to approved in table
        updateContractInTable({ ...contract, approved: true });
        setShowApproveModal(false);
        setSelectedContract(null);
      } catch (error) {
        showError();
        setShowApproveModal(false);
        setSelectedContract(null);
      }
    }
    setLoading(false);
  };

  const getApproveAction = (contract: ContractWithProgress): JSX.Element | null => (
    !contract.approved
    && (contract.done ?? true)
    && !(contract.error ?? false)
    && (contract.id !== 0)
      ? (
        <ActionsDropDownItem onClick={() => {
          if (currentUserIsBuyer) {
            handleContractApproval(contract);
          } else {
            setSelectedContract(contract);
            setShowApproveModal(true);
          }
        }}
        >
          Approve
        </ActionsDropDownItem>
      )
      : null);

  return (
    <Fragment>
      {loading && <Spinner />}
      {
        showApproveModal
        && !currentUserIsBuyer
        && (
          <ConfirmationModal
            heading="Agreement Approval"
            subHeading={selectedContract?.agreementName}
            description="By accepting this agreement, I confirm that I have read, understood and accept the conditions stipulated therein.
            I confirm that I am a duly authorised representative of my company with the requisite permissions to accept this contract on its behalf, and to be held accountable thereto.
            I acknowledge that this agreement will remain suspensively conditional upon the requisite approval of the same by Kaap Agri, and the satisfactory conclusion of the compliance management and onboarding processes."
            hasCancel
            onToggleModal={
              () => {
                setSelectedContract(null);
                setShowApproveModal(false);
              }
            }
            proceedButtonText="continue"
            onProceedClick={() => handleContractApproval(selectedContract)}
          />
        )
      }
      <FileSelector ref={fileSelector} onFilesSelected={onFilesSelectedHandler} allowedFileTypes=".docx,.doc,.pdf" />

      <BasicTable
        emptyMessage="No agreements to view"
        loading={loadingTable}
        columns={[{
          key: "Name",
          text: "Agreement",
          align: "left",
          size: "1fr",
        },
        {
          key: "allStatus",
          text: currentUserIsBuyer ? "Supplier approval status" : "Buyer approval status",
          size: "1fr",
          align: "left",
        },
        {
          key: "status",
          text: "Your approval status",
          size: "1fr",
          align: "left",
        },
        {
          key: "Actions",
          text: "Actions",
          size: "auto",
          align: "center",
        }]}
        rows={agreements.map((contract) => [
          {
            key: `agreementName_${contract.id}`,
            value: contract.agreementName,
          },
          {
            key: `allStatus_${contract.id}`,
            valueTemplate: () => (
              <span>
                {contract.approvedByOtherSignatories && "Approved"}
                {!contract.approvedByOtherSignatories
                  && (contract.id === 0
                    ? "N/A"
                    : "Approval Required")}
              </span>
            ),
          },
          {
            key: `status_${contract.id}`,
            valueTemplate: () => {
              if (contract.done == null) {
                return (
                  <span>
                    {contract.approved && "Approved"}
                    {!contract.approved
                      && (contract.id === 0
                        ? "Not Uploaded"
                        : "Approval Required")}
                  </span>
                );
              }
              if (contract.error === true) {
                return (
                  <span className="text-danger">
                    {contract.uploadError !== ""
                      ? contract.uploadError
                      : "Upload Failed. Please try again."}
                  </span>
                );
              }
              if (contract.done === true) {
                return (
                  <span>
                    {contract.approved
                      ? "Approved"
                      : "Approval Required"}
                  </span>
                );
              }
              return <Progress className="w-100" value={contract.progress} />;
            },
          },
          {
            key: `actions_${contract.id}`,
            valueTemplate: () => (
              <ActionsDropDown label="Actions">
                { getApproveAction(contract) }
                { getUploadAction(contract) }
                { getViewAction(contract) }
                { getUploadCancelAction(contract) }
              </ActionsDropDown>
            ),
          },
        ])}
      />
    </Fragment>
  );
};

export default AgreementsTable;
