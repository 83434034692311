import React, { useState, Fragment } from "react";
import {
  ModalPopup,
  CheckboxInput,
  TextAreaInput,
  Spinner,
} from "isuppli-react-components";
import { useHistory } from "react-router";
import OptionSelector from "../../../Controls/OptionSelector";
import {
  updateSubscription,
} from "../../../http/Billing/billingApi";
import {
  convertToNonDirectoryListing,
  unsubscribeEntirely,
  saveCloseAccountFeedback,
} from "../../../http/Subscription/subscriptionAPI";
import useShowError from "../../../Hooks/useShowError";
import {
  CloseAccountFeedbackMessage, // eslint-disable-line no-unused-vars
} from "../../../http/Subscription/Models/Models";

import CloseAccountReasons, {
  closeAccountReasonToString,
} from "../../../Util/Enumerators/CloseAccountReasons";
import { checkHttpStatus } from "../../../http/httpHelpers";

const CancelSubscriptionModal = (
  {
    showDowngradeToFreeOption,
    freeTierId,
    buyerCompanyNames,
    onClose,
  }
    :
    {
      showDowngradeToFreeOption: boolean,
      freeTierId?: number,
      buyerCompanyNames: string[],
      onClose: () => void,
    }
) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [cancellationOption, setCancellationOption] = useState(-1);
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(true);
  const [showUnsubscribeSuccessModal, setShowUnsubscribeSuccessModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [unsubscribeSuccessModalInfo, setUnsubscribeSuccessModalInfo] = useState({
    heading: "",
    subheading: "",
    body: "",
  });
  const [checkboxValues, setCheckboxValues] = useState([
    {
      display: closeAccountReasonToString(CloseAccountReasons.NoBusinessValue),
      selected: false,
      value: CloseAccountReasons.NoBusinessValue,
    },
    {
      display: closeAccountReasonToString(CloseAccountReasons.ManagingProfileFrustrating),
      selected: false,
      value: CloseAccountReasons.ManagingProfileFrustrating,
    },
    {
      display: closeAccountReasonToString(CloseAccountReasons.BusinessNoLongerTrading),
      selected: false,
      value: CloseAccountReasons.BusinessNoLongerTrading,
    },
    {
      display: closeAccountReasonToString(CloseAccountReasons.FoundBetterSolution),
      selected: false,
      value: CloseAccountReasons.FoundBetterSolution,
    },
    {
      display: closeAccountReasonToString(CloseAccountReasons.Other),
      selected: false,
      value: CloseAccountReasons.Other,
    },
  ]);
  const [optionalInput, setOptionalInput] = useState("");
  const showError = useShowError();

  const downgradeToFreeHandler = async () => {
    setLoading(true);
    try {
      const model = {
        newSubscriptionTierId: freeTierId ?? -1,
      };

      const response = await updateSubscription(model);
      if (typeof response === "string" && response.trim()) {
        history.push(response);
      } else {
        history.push("/mycompany/subscription");
      }
    } catch (getError) {
      if (!checkHttpStatus(getError, 404)) {
        // Break
        showError();
        setLoading(false);
      }
    }
  };

  const removeFromDirectoryHandler = async () => {
    setLoading(true);

    try {
      // cancel subscription on billing
      const model = {
        newSubscriptionTierId: freeTierId ?? -1,
      };
      await updateSubscription(model);
    } catch (getError) {
      if (!checkHttpStatus(getError, 404)) {
        // Break
        showError();
        setLoading(false);
        return;
      }
    }

    try {
      // make api call
      await convertToNonDirectoryListing();

      // set success modal info
      setUnsubscribeSuccessModalInfo({
        heading: "CONVERSION TO NON-DIRECTORY LISTING SUCCESSFUL",
        subheading: "As requested, you have been successfully converted to a non-directory listing.",
        body: "Your company profile will no longer be visible to the public Directory,"
          + "and only your current clients will still be able to see your profile. "
          + "You will not have access to any platform functionality, and will not incur"
          + "any charges. You will, however, still be able to access your profile in the"
          + "future, should you wish to re- subscribe. We're sorry to see you go, and hope"
          + "to welcome you back to B1LINK again in the future. "
          + "You are welcome to re - register at any time.",
      });

      // hide original modal, show success modal
      setShowCancelSubscriptionModal(false);
      setShowUnsubscribeSuccessModal(true);
    } catch (error) {
      showError();
    }
    setLoading(false);
  };

  const unsubscribeEntirelyHandler = async () => {
    setLoading(true);

    try {
      // cancel subscription on billing
      const model = {
        newSubscriptionTierId: freeTierId ?? -1,
      };
      await updateSubscription(model);
    } catch (getError) {
      if (!checkHttpStatus(getError, 404)) {
        // Break
        showError();
        setLoading(false);
        return;
      }
    }

    try {
      // make api call
      await unsubscribeEntirely();

      // set success modal info
      setUnsubscribeSuccessModalInfo({
        heading: "UNSUBSCRIBE SUCCESSFUL",
        subheading: "You have successfully unsubscribed and will be removed from our database "
          + "in accordance with our terms and conditions.",
        body: "We're sorry to see you go, and hope to welcome you back to B1LINK again in the future. "
          + "You are welcome to re-register at any time.",
      });

      // hide original modal, show success modal
      setShowCancelSubscriptionModal(false);
      setShowUnsubscribeSuccessModal(true);
    } catch (error) {
      showError();
    }
    setLoading(false);
  };

  const confirmChoiceHandler = async () => {
    switch (cancellationOption) {
      case 1:
        await removeFromDirectoryHandler();
        break;
      case 2:
        await unsubscribeEntirelyHandler();
        break;
      default:
    }
  };

  const saveFeedbackHandler = async () => {
    setLoading(true);
    try {
      // build model
      const selectedValues = checkboxValues
        .filter((checkboxValue) => checkboxValue.selected === true
          && checkboxValue.value !== CloseAccountReasons.Other)
        .map((filteredValue) => filteredValue.value);

      const reasons = selectedValues.reduce((a, b) => a + b);

      const closeAccountFeedbackMessage: CloseAccountFeedbackMessage = {
        reasons,
        optionalInput,
      };

      // make api call
      await saveCloseAccountFeedback(closeAccountFeedbackMessage);
      window.location.href = "/account/logout";
    } catch (error) {
      showError();
      setLoading(false);
    }
  };

  // downgrade options
  const cancellationOptions = [];

  if (showDowngradeToFreeOption) {
    cancellationOptions.push({
      heading: "I'd like to downgrade my package to the free subscription.",
      value: 0,
    });
  }

  if (buyerCompanyNames.length > 0) {
    cancellationOptions.push({
      heading: "Please remove me from the directory, but retain my profile for my corporate customers only.",
      value: 1,
    });
  }

  cancellationOptions.push({
    heading: "Please unsubscribe me entirely.",
    value: 2,
  });

  const optionalCheckboxSelected = checkboxValues?.
    find((checkbox) => (checkbox.value === CloseAccountReasons.Other))?.selected;

  return (
    <Fragment>
      {
        showConfirmationModal && (
          <ModalPopup
            onClose={() => { }}
            hasCancel={false}
            heading="Are you sure you want to proceed?"
            size="extra-large"
            buttons={[
              {
                key: "back",
                label: "Back",
                color: "secondary",
                onClick: () => {
                  setShowConfirmationModal(false);
                  setShowCancelSubscriptionModal(true);
                },
              },
              {
                key: "proceed",
                label: "Proceed",
                color: "primary",
                onClick: async () => {
                  setShowConfirmationModal(false);
                  await confirmChoiceHandler();
                },
              },
            ]}
          >
            <div className="px-2">
              This action cannot be undone.
            </div>
          </ModalPopup>
        )
      }

      {
        showUnsubscribeSuccessModal && (
          <ModalPopup
            image="laptop"
            onClose={() => { }}
            hasCancel={false}
            heading={unsubscribeSuccessModalInfo.heading}
            subheading={unsubscribeSuccessModalInfo.subheading}
            size="extra-large"
            buttons={[
              {
                key: "submit",
                label: "Submit",
                color: "primary",
                onClick: async () => {
                  await saveFeedbackHandler();
                },
              },
            ]}
          >
            <div className="px-2">
              <div>
                {
                  unsubscribeSuccessModalInfo.body
                }
              </div>
              <div className="mt-3" />
              <div>
                Please take a moment to let us know why you have chosen to leave us (optional).
              </div>
              <div className="mt-3" />
              {
                checkboxValues.map((checkboxValue, i) => (
                  <CheckboxInput
                    className="py-1"
                    key={i}
                    label={checkboxValue.display}
                    value={checkboxValue.selected}
                    onChange={(newValue) => {
                      const newCheckboxes = checkboxValues.map((newCheckbox, j) => {
                        if (i === j) {
                          return {
                            ...newCheckbox,
                            selected: newValue,
                          };
                        }
                        return newCheckbox;
                      });
                      setCheckboxValues(newCheckboxes);
                    }}
                  />
                ))
              }
              {
                optionalCheckboxSelected && (
                  <div>
                    <div className="mt-3" />
                    <TextAreaInput
                      className=""
                      label=""
                      placeholder="Optional input"
                      maxLength={1000}
                      value={optionalInput}
                      onChange={(newVal) => { setOptionalInput(newVal); }}
                    />
                  </div>
                )
              }
            </div>
            {loading && <Spinner />}
          </ModalPopup>
        )
      }

      {
        showCancelSubscriptionModal && (
          <ModalPopup
            image="laptop"
            onClose={onClose}
            hasCancel
            heading="UNSUBSCRIBE"
            subheading="You have selected to unsubscribe from the B1LINK platform."
            size="extra-large"
            buttons={[
              {
                key: "submit",
                label: "Submit Request",
                color: "primary",
                disabled: cancellationOption < 0,
                onClick: async () => {
                  switch (cancellationOption) {
                    case 0:
                      await downgradeToFreeHandler();
                      break;
                    default:
                      setShowCancelSubscriptionModal(false);
                      setShowConfirmationModal(true);
                  }
                },
              },
            ]}
          >
            <div className="px-2">
              <div>
                Do you know that you can downgrade to our B1LINK package, and
                continue to enjoy (limited) functionality on the platform, AT NO COST.
                If you have any concerns regarding the security and privacy of your data,
                please refer to our Terms and Conditions, or alternatively
                contact us at
                {" "}
                <a href="mailto:support@b1link.co.za">support@b1link.co.za</a>
              </div>
              {
                buyerCompanyNames.length > 0
              && (
                <div>
                  <div className="mt-3" />
                  <div>
                    We notice that you are connected as a supplier to the following
                    Corporate clients, who may utilize your information for their own
                    purchasing and reporting purposes. By unsubscribing, B1LINK will
                    be unable to provide them with this information.
                  </div>
                  <div className="mt-2" />
                  <ul>
                    {
                      buyerCompanyNames.map((buyerName, i) => (
                        <li key={i}>
                          {buyerName}
                        </li>
                      ))
                    }
                  </ul>
                </div>
              )
              }
              <div className="mt-3" />
              <OptionSelector
                name="downgrade"
                label="Please select an option"
                options={cancellationOptions}
                value={cancellationOption}
                onChangeCallback={(ev: Event) => {
                  const newVal = Number((ev?.target as HTMLInputElement)?.value);
                  setCancellationOption(newVal);
                }}
              />
            </div>
            {loading && <Spinner />}
          </ModalPopup>
        )
      }
      {loading && <Spinner />}
    </Fragment>
  );
};

export default CancelSubscriptionModal;
