import React from "react";
import { MessageAttachment } from "isuppli-react-components";
import Styles from "./MessageItem.module.scss";
import { dateTimeLabelFormat } from "../../../Util/valueFormatter";

function MessageItem({
  messageId,
  userDisplayName,
  userDisplayEmail,
  groupName,
  files,
  messageText,
  createdDate,
}:
{
    messageId: number,
    userDisplayName: string,
    userDisplayEmail:string,
    groupName:string,
    files?: {filename:string, link:string}[],
    messageText: string,
    createdDate: Date,
}) {
  // date time label formatting
  const datetimeFormat = dateTimeLabelFormat(createdDate);

  const fileList = files?.map((file, index) => {
    if (file !== undefined) {
      const url = `api/messaging${file.link}`;
      return (
        <MessageAttachment
          className="ml-2 mt-2"
          key={index}
          fileName={file?.filename}
          progress={1}
          link={url}
          canClose={false}
        />
      );
    }
    return (
      <div> </div>
    );
  });

  const attachments = fileList?.length ? (
    <div className="mt-3">
      <b>Attachments:</b>
      <div className="d-flex flex-wrap">
        {fileList}
      </div>
    </div>
  ) : "";

  return (
    <div>
      <div className="d-flex flex-column mb-3 mx-3">
        <div className={Styles.dateLine}>{datetimeFormat}</div>
        <div className={[Styles.name, "mt-3"].join(" ")}>
          {userDisplayName}
        </div>
        <div className={Styles.email}>
          {`${userDisplayEmail} ( ${groupName} )`}
        </div>
        {messageText && (
          <div id={`message_${messageId}`} className={[Styles.messageText, "mt-3"].join(" ")}>
            {messageText}
          </div>
        )}
        {attachments}
      </div>
    </div>
  );
}
export default MessageItem;
