import React, {
  useState, useRef, useEffect, useMemo,
} from "react";
import axios, {
  CancelTokenSource, // eslint-disable-line no-unused-vars
} from "axios";
import { Badge } from "reactstrap";
import { MessageAttachment, UtilButton, useDebounce } from "isuppli-react-components";
import Styles from "./MessageComposer.module.scss";
import { ReactComponent as PaperClipSvg } from "./paperclip.svg";
import { ReactComponent as TriangleSvg } from "./triangle-primary.svg";

import {
  uploadAttachments,
  createDraftMessage,
  updateDraftMessage,
  sendMessage,
  Message, // eslint-disable-line no-unused-vars
  deleteMessageAttachment,
  DraftMessage, // eslint-disable-line no-unused-vars
} from "../../../http/Messaging/messagesApi";

import
FileInput,
{
  Attachment, // eslint-disable-line no-unused-vars
}
  from "../../../Controls/FileInput/FileInput";
import useShowError from "../../../Hooks/useShowError";

 interface BusyAttachment extends Attachment {
    done: boolean,
  }

const MessageComposer = ({
  channelId,
  onMessageSent,
  alwaysOpen = false,
  defaultOpen = false,
  hideSendButton = false,
  className,
  onMessageIdChange,
  getForceUpdate,
  onDisableSendChanged,
  onDraftChanged,
}: {
  channelId: number,
  onMessageSent?: (message: Message) => void;
  alwaysOpen? : boolean,
  defaultOpen? : boolean,
  hideSendButton?: boolean,
  className? : string,
  onMessageIdChange?: (id: number) => void;
  getForceUpdate?: (forceUpdater: () => Promise<void>) => void,
  onDisableSendChanged?: (disable: boolean) => void,
  onDraftChanged?: (message: DraftMessage) => void,
}) => {
  const openInitValue = alwaysOpen || defaultOpen;
  const textArea = useRef<HTMLTextAreaElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const showError = useShowError();
  // whether the current state has been saved
  const [attachments, setAttachments] = useState<BusyAttachment[]>([]);
  const [messageId, setMessageId] = useState<number | null>(null);
  const [body, setBody] = useState<string>("");
  const [isSending, setIsSending] = useState(false);
  const sendQue = useRef<boolean>(false);
  const getForceUpdatePromiseResolver = useRef<() => void>();
  const fileSelectHandler = useRef<() => void>();
  const draftCancelToken = useRef<CancelTokenSource>(axios.CancelToken.source());
  const attachmentUploadCancelToken = useRef<CancelTokenSource>(axios.CancelToken.source());

  // change openState of composer on open variables prop changes
  useEffect(() => {
    setIsOpen(openInitValue);
  }, [openInitValue]);

  // cancel uploads when component disposes
  useEffect(() => {
    attachmentUploadCancelToken.current.cancel();
    attachmentUploadCancelToken.current = axios.CancelToken.source();
    return () => {
      attachmentUploadCancelToken.current.cancel();
    };
  }, [channelId]);

  useEffect(() => {
    if (onMessageIdChange == null || messageId == null) {
      return;
    }
    onMessageIdChange(messageId);
  }, [messageId, onMessageIdChange]);

  useEffect(() => {
    // create a draft
    const createDraft = async () => {
      try {
        draftCancelToken.current.cancel();
        draftCancelToken.current = axios.CancelToken.source();
        const newDraft = await createDraftMessage(channelId, {
          cancelToken: draftCancelToken.current.token,
        });
        setMessageId(newDraft.id);
        setBody(newDraft.content);
        if (onDraftChanged != null) {
          onDraftChanged(newDraft);
        }

        const draftAttachments: BusyAttachment[] = newDraft.attachments.map((a) => ({
          id: a.id,
          fileId: a.id,
          filename: a.filename,
          fileUrl: a.fileUrl,
          progress: 1,
          done: true,
        }));
        setAttachments(draftAttachments);
      } catch (error) {
        if (!axios.isCancel(error)) {
          showError();
        }
      }
    };

    createDraft();
    return () => {
      // dispose component
      draftCancelToken.current.cancel();
    };
  }, [channelId, showError, onDraftChanged]);

  const isSendDisabled = useMemo(() => (!body && attachments.length <= 0)
      || attachments.some((a) => !a.done || a.progress < 1),
  [body, attachments]);

  const updatePending = useRef<boolean>(false);

  let onSendHandler: () => Promise<void>;

  const [
    triggerUpdateDebounce,
    forceUpdateDebounce,
  ] = useDebounce<string>(async (latestBody) => {
    if (latestBody == null || messageId == null) {
      return;
    }
    setBody(latestBody);
    await updateDraftMessage(channelId, messageId, latestBody);
    updatePending.current = false;
    if (sendQue.current) {
      sendQue.current = false;
      onSendHandler();
    }

    if (getForceUpdatePromiseResolver.current != null) {
      getForceUpdatePromiseResolver.current();
      getForceUpdatePromiseResolver.current = undefined;
    }
  },
  1000);

  const onBodyChangeHandler = (newValue: string) => {
    updatePending.current = true;
    setBody(newValue);
    triggerUpdateDebounce(newValue);
  };

  onSendHandler = async () => {
    setIsSending(true);
    if (updatePending.current) {
      sendQue.current = true;
      forceUpdateDebounce();
      return;
    }

    if (messageId == null) {
      return;
    }
    const sentMessage = await sendMessage(channelId, messageId);
    if (onMessageSent != null) {
      onMessageSent(sentMessage);
    }

    const newDraftMessage = await createDraftMessage(channelId);
    setBody(newDraftMessage.content);
    setAttachments([]);
    setMessageId(newDraftMessage.id);
    setIsSending(false);
    setIsOpen(false);
  };

  useEffect(() => {
    if (getForceUpdate != null) {
      const forceUpdater = () => new Promise<void>((resolver) => {
        if (updatePending.current) {
          getForceUpdatePromiseResolver.current = resolver;
          forceUpdateDebounce();
          return;
        }
        resolver();
      });
      getForceUpdate(forceUpdater);
    }
  }, [getForceUpdate, forceUpdateDebounce]);

  const onTextFocusHandler = () => {
    setIsOpen(true);
  };

  const onToggleButtonClickHandler = () => {
    setIsOpen(!isOpen);
    setBody(body.trimStart());

    // eslint-disable-next-line no-unused-expressions
    textArea.current?.scrollTo(0, 0);
  };

  const onAttachmentDeleteHandler = async (id: number | null) => {
    if (messageId !== null) {
      const attachmentToDelete = attachments.find((c) => c.id === id);
      if (attachmentToDelete == null) {
        return;
      }
      setAttachments((prevAttachments) => prevAttachments.filter((c) => c.id !== id));

      if (attachmentToDelete.fileId != null) {
        // check for failed downloads
        await deleteMessageAttachment(channelId, messageId, attachmentToDelete.fileId);
      }
    }
  };

  const onAttachmentUploadedHandler = (uploadResult : Attachment[]) => {
    setAttachments((prevAttachments) => {
      const newAttachments = prevAttachments
        .map((file) => ({
          ...file,
          fileUrl: file.fileUrl,
          done: file.done || uploadResult.some((progressFile) => file.id === progressFile.id),
        }));
      return newAttachments;
    });
  };

  const updateProgress = (progress : Attachment[]) => {
    setAttachments((prevAttachments) => {
      const newAttachments = [...prevAttachments];
      progress.forEach((progressItem) => {
        const idMatch = newAttachments.find((c) => c.id === progressItem.id);

        if (idMatch != null) {
          idMatch.progress = progressItem.progress;
          idMatch.fileId = progressItem.fileId;
          idMatch.fileUrl = progressItem.fileUrl;
          return;
        }

        newAttachments.push({ ...progressItem, done: false });
      });
      return newAttachments;
    });
  };

  useEffect(() => {
    if (onDisableSendChanged != null) {
      onDisableSendChanged(isSendDisabled);
    }
  }, [isSendDisabled, onDisableSendChanged]);

  const classes = [Styles.MessageComposer, "bg-white", className ?? ""];
  if (isOpen) {
    classes.push(Styles.Expand);
  } else {
    classes.push(Styles.Collapse);
  }

  if (hideSendButton) {
    classes.push(Styles.HideSendButton);
  }

  if (alwaysOpen) {
    classes.push(Styles.HideToggleButton);
  }

  return (
    <div className={classes.join(" ")}>
      <textarea
        disabled={messageId == null}
        ref={textArea}
        rows={isOpen ? undefined : 1}
        className={`${Styles.TextArea} flex-fill align-self-center`}
        placeholder="Write a message..."
        value={body}
        onChange={(ev) => onBodyChangeHandler(ev.target.value)}
        onFocus={onTextFocusHandler}
      />
      {isOpen && attachments.length > 0
        ? (
          <div className={`${Styles.AttachmentsContainer} d-flex flex-wrap`}>
            {attachments.map((attachment) => {
              let progress = attachment.progress;
              if (attachment.done) {
                progress = progress >= 1 ? 1 : -1;
              } else {
                progress = progress >= 1 ? 0.99 : progress;
              }
              return (
                <MessageAttachment
                  className="ml-2 mt-2"
                  key={attachment.id}
                  fileName={attachment.filename}
                  progress={progress}
                  onDelete={() => onAttachmentDeleteHandler(attachment.id)}
                  link={`api/messaging/${attachment.fileUrl}`}
                  canClose
                  uploadError={attachment.uploadError}
                />
              );
            })}
          </div>
        )
        : null}

      {!isOpen && attachments.length > 0
        ? <Badge className={Styles.AttachmentBadge} color={isSendDisabled ? "secondary" : "info"}>{isSendDisabled ? "Uploading.." : `${attachments.length}  Attachments`}</Badge> : null}
      {!alwaysOpen
        ? (
          <button
            type="button"
            className={`${Styles.ToggleButton} px-3`}
            onClick={onToggleButtonClickHandler}
          >
            <TriangleSvg className="text-action" />
          </button>
        )
        : null}

      <FileInput
        allowMultiple
        uploadHandler={(formData, config) => uploadAttachments(
          channelId,
          messageId ?? -1,
          formData,
          {
            ...config,
            cancelToken: attachmentUploadCancelToken.current.token,
          }
        )}
        getFileSelectHandler={(handler) => { fileSelectHandler.current = handler; }}
        onUploadProgress={(files) => {
          updateProgress(files);
        }}
        onUploadDone={(files) => {
          onAttachmentUploadedHandler(files);
        }}
      />
      <button
        type="button"
        className={`${Styles.AttachButton} px-3`}
        onClick={() => {
          const handler = fileSelectHandler?.current ?? (() => {});
          handler();
        }}
      >
        <PaperClipSvg className="text-primary" />
      </button>
      <UtilButton
        disabled={isSendDisabled || isSending}
        color="primary"
        size="small"
        location="top"
        className={`ml-xl-2 ${Styles.SendButton}`}
        onClick={onSendHandler}
      >
        Send
      </UtilButton>
    </div>
  );
};

export default MessageComposer;
