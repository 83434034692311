import React, { Fragment } from "react";
// eslint-disable-next-line no-unused-vars
import { SupplierImportMessage } from "../../http/SupplierImport/Models/SupplierImportMessage";
import { ReactComponent as DownloadSvg } from "../../Images/download.svg";

const ImportCompleteScreen = ({ message }: { message: SupplierImportMessage}) => (
  <Fragment>
    <div>
      <h3>
        Thank you. All qualifying uploaded supplier records will now be contacted to register.
        <br />
        {
          message?.errorFileGuid
          && "Please review the error report for details on rejected records."
        }
      </h3>
    </div>
    <div className="row mt-4">
      <div className="col-3"><h3><b>Supplier invitations sent:</b></h3></div>
      <div className="col-2"><h3>{message.processedRecords}</h3></div>
    </div>
    <div className="row">
      <div className="col-3"><h3><b>Rejected records: </b></h3></div>
      <div className="col-2"><h3>{message.failedRecords}</h3></div>
      {
        message?.errorFileGuid
        && (
          <div className="col-3">
            <a
              href={`api/documents/${message?.errorFileGuid}`}
              download="SupplierImport_FailedRecords.xlsx"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-link text-right p-0 m-0"
              onClick={() => { }}
            >
              <DownloadSvg className="mr-1" />
              DOWNLOAD ERROR FILE
            </a>
          </div>
        )
      }
    </div>
  </Fragment>
);

export default ImportCompleteScreen;
