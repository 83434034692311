import React, { Fragment } from "react";
import RadioSelector from "../../Controls/RadioSelector";
import TextInput from "../../Controls/TextInput/TextInput";
import { filterOutLetters, filterOutSpecialCharacters } from "../../Validation/onChangeValidations";

interface AdditionalCompanyDataMessage {
  royalBafokengNationalResident: boolean,
  localMunicipality: string,
  numberOfEmployees: number,

  workMan: boolean,
  workManCode: string,
  workManExpiredDate: string,
}

const AdditionalCompanyData = (props:
  {
    updateClientSpecificDataHandler: (ev: { target: { name: string; value: AdditionalCompanyDataMessage } }) => void,
    readOnly: boolean,
    data: any,
  }) => {
  const onUpdateDataHandler = (e: { target: { name: string | number; value: any; }; }) => {
    const updatedAdditionalData = props.data.additionalCompanyData ?? {};
    updatedAdditionalData[e.target.name] = e.target.value;
    props.updateClientSpecificDataHandler({
      target: {
        name: "additionalCompanyData",
        value: updatedAdditionalData,
      },
    });
  };

  return (
  // eslint-disable-next-line react/jsx-indent
    <Fragment>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <RadioSelector
            className="mt-4 mb-3"
            name="royalBafokengNationalResident"
            label="Is your company a resident of the Royal Bafokeng Nation ?"
            value={props.data.additionalCompanyData?.royalBafokengNationalResident ?? null}
            options={[
              { value: 1, display: "yes" },
              { value: 0, display: "no" },
            ]}
            onChangeCallback={onUpdateDataHandler}
            disabled={false}
            required
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 col-md-12">
          <TextInput
            label="Number of employees"
            filters={[filterOutSpecialCharacters, filterOutLetters]}
            placeholder="How many employees are currently employed by your company?"
            name="numberOfEmployees"
            value={parseInt(props.data.additionalCompanyData?.numberOfEmployees ?? "0", 10)}
            onChangeCallback={onUpdateDataHandler}
            disabled={false}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default AdditionalCompanyData;
