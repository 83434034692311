import axios from "axios";
import { makeRequestAsync } from "./util";

export async function deleteOnboardingRequest(onboardingId) {
  return makeRequestAsync(() => axios.delete(`/api/motivation/deleteonboarding/${onboardingId}`));
}

export async function dismissNotification(notificationId) {
  return makeRequestAsync(() => axios.delete(`/api/notifications/${notificationId}`));
}

export async function unlinkSupplierOnboarding(supplierCompanyId) {
  return makeRequestAsync(() => axios.delete(`/api/motivation/unlinksupplieronboarding/${supplierCompanyId}`));
}
