import React, { useEffect, useState } from "react";
import { FormGroup, Label, Input } from "reactstrap";

import Styles from "./DateTimeInput.module.scss";

const DateTimeInput = (
  {
    className,
    value,
    onChange,
    label,
    min,
    required,
    validationMessage,
  }
  :
  {
    value?: Date,
    onChange: (newValue: Date) => void,
    className: string,
    label?: string,
    min?: Date,
    required?: boolean,
    validationMessage?: string,
  }
) => {
  const inputId = `${label}-${Math.random().toString(36).substring(2)}`;
  const [localDateValue, setLocalDateValue] = useState("");
  const [localTimeValue, setLocalTimeValue] = useState("");
  const [focussed, setFocussed] = useState(false);

  const getDateString = (dateTimeValue: Date | undefined) => {
    if (dateTimeValue == null) {
      return "";
    }

    const monthNumber = Number.isNaN(dateTimeValue.getMonth())
      ? (new Date()).getMonth()
      : dateTimeValue.getMonth();

    const dayNumber = Number.isNaN(dateTimeValue.getDate())
      ? (new Date()).getDate()
      : dateTimeValue.getDate();

    const year = Number.isNaN(dateTimeValue.getFullYear())
      ? (new Date()).getFullYear()
      : dateTimeValue.getFullYear();

    const month = `0${monthNumber + 1}`.slice(-2);
    const day = `0${dayNumber}`.slice(-2);
    return `${year}-${month}-${day}`;
  };
  const getTimeString = (dateTimeValue: Date | undefined) => {
    if (dateTimeValue == null) {
      return "";
    }
    const hours = `0${dateTimeValue.getHours()}`.slice(-2);
    const minutes = `0${dateTimeValue.getMinutes()}`.slice(-2);
    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    if (!focussed) {
      const dateValue = getDateString(value);
      const timeValue = getTimeString(value);
      setLocalDateValue(dateValue);
      setLocalTimeValue(timeValue);
    }
  }, [value, focussed, setLocalDateValue, setLocalTimeValue]);

  const onDateChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newDateString = e.target.value;
    newDateString = newDateString.replaceAll("/", "-");
    const newDateValue = new Date(newDateString);

    // only call onchange when the date is valid. This stops the value
    // for clearing while entering using the keyboard
    if (!Number.isNaN(newDateValue.getTime())) {
      let newDateTimeValue = new Date(newDateValue.setHours(value?.getHours() ?? 0));
      newDateTimeValue = new Date(newDateTimeValue.setMinutes(value?.getMinutes() ?? 0));

      onChange(newDateTimeValue);
    }

    setLocalDateValue(newDateString);
  };

  const onTimeChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTimeString = e.target.value;
    const hours = Number.parseInt(newTimeString.split(":")[0], 10);
    const minutes = Number.parseInt(newTimeString.split(":")[1], 10);

    // only call onchange when the date is valid. This stops the value
    // for clearing while entering using the keyboard
    if (!Number.isNaN(minutes) && !Number.isNaN(hours)) {
      let newDateTimeValue = value == null ? new Date() : new Date(getDateString(value));
      newDateTimeValue = new Date(newDateTimeValue.setHours(hours));
      newDateTimeValue = new Date(newDateTimeValue.setMinutes(minutes));
      onChange(newDateTimeValue);
    }

    setLocalTimeValue(newTimeString);
  };

  const minDateValue = min == null ? undefined : getDateString(min);

  let minTimeValue: string | undefined;
  // only validate time if current date is the min date
  if (minDateValue != null && value != null && minDateValue === localDateValue) {
    minTimeValue = min == null ? undefined : getTimeString(min);
  }

  return (
    <FormGroup className={`${Styles.DateTimeGroup} ${className}`}>
      <Label for={`${inputId}-date`}>{label}</Label>
      <Input
        placeholder="yyyy-mm-dd"
        required={required}
        min={minDateValue}
        type="date"
        name={`${inputId}-date`}
        id={`${inputId}-date`}
        value={localDateValue}
        onChange={onDateChangeHandler}
        onFocus={() => { setFocussed(true); }}
        onBlur={() => {
          setFocussed(false);
        }}
      />
      <Input
        placeholder="hh:mm"
        required={required}
        min={minTimeValue}
        type="time"
        name={`${inputId}-time`}
        id={`${inputId}-time`}
        value={localTimeValue}
        onChange={onTimeChangeHandler}
        onFocus={() => { setFocussed(true); }}
        onBlur={() => {
          setFocussed(false);
        }}
      />
      {
        validationMessage && (
          <div className="invalid-feedback">
            {validationMessage}
          </div>
        )
      }
    </FormGroup>
  );
};

export default DateTimeInput;
