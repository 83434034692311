import React from "react";

const DetailsItem = (
  {
    loading = false,
    label,
    value,
    badge,
  }
  :
  {
    loading?: boolean,
    label: string,
    value: string,
    badge?: React.Component,
  }
) => (
  <div className="d-flex">
    <div className="w-100 pr-3 pt-2 font-weight-bold">{label}</div>
    <div className="w-75 pt-2">
      {loading ? "..." : value}
      {badge ?? null}
    </div>
  </div>
);

export default DetailsItem;
