import React, { Component } from "react";
import { Badge } from "reactstrap";
import { connect } from "react-redux";
import { UtilButton, withDataFeatureCheck, DataFeatures } from "isuppli-react-components";

import { withRouter } from "react-router-dom";

import { setStoreProperty, hideInfoPopup } from "../../Store/actions";

const mapStateToProps = (state) => ({
  currentCompanyTab: state.currentCompanyTab,
  companyOutstandingInfo: state.companyOutstandingInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentCompanyTab: (currentCompanyTab) => {
    dispatch(setStoreProperty("currentCompanyTab", currentCompanyTab));
  },
  hideInfoMsg: () => {
    dispatch(hideInfoPopup());
  },
});

const badge = <Badge color="danger" className="mx-2">Outstanding</Badge>;

class NavTabs extends Component {
  onTabClick = (i, arg) => {
    this.props.history.push(arg);
    this.props.setCurrentCompanyTab({ index: i, path: arg });
    this.props.hideInfoMsg();
  };

  async componentDidMount() {
    const tabs = this.buildTabs();
    const currentPath = window.location.pathname;
    // check tabs
    for (let i = 0; i < tabs.length; i += 1) {
      if (currentPath.toUpperCase() === tabs[i].path.toUpperCase()) {
        this.props.setCurrentCompanyTab({ index: i, path: tabs[i].path });
      }
    }
  }

  buildTabs = () => ([
    {
      path: "/mycompany/profile/supplier",
      display: "COMPANY INFORMATION",
      badge: this.props.companyOutstandingInfo?.companyInformation
        ? badge
        : null,
    },
    {
      path: "/mycompany/profile/contact",
      display: "ADDRESS & CONTACT DETAILS",
      // The only contact details which will ever be
      // outstanding are the client specific contact details because the mandatory
      // (Primary and Secondary are required during registration)
      badge: this.props.companyOutstandingInfo?.addressInformation
        ? badge
        : null,
    },
    {
      path: "/mycompany/profile/bee",
      display: "B-BBEE",
    },
    {
      path: "/mycompany/profile/agreement",
      display: "SUPPORTING DOCUMENTS & AGREEMENTS",
      badge: this.props.companyOutstandingInfo?.supportingDocuments
        ? badge
        : null,
    },
    {
      path: "/mycompany/profile/products",
      display: "PRODUCTS & SERVICES",
    },
    {
      path: "/mycompany/profile/bank",
      display: "BANK DETAILS",
      badge: this.props.companyOutstandingInfo?.bankInformation
        ? badge
        : null,
    },
    {
      path: "/mycompany/profile/clients",
      display: "MY CLIENTS",
      badge:
          this.props.companyOutstandingInfo?.agreements
          || this.props.companyOutstandingInfo?.contactPersons
            ? badge
            : null,
    },
  ])

  render() {
    let tabs = this.buildTabs();

    if (!this.props.dataFeatureCheck(DataFeatures.BankingDetails)) {
      tabs = tabs.filter((c) => c.path !== "/mycompany/profile/bank");
    }

    const buttons = tabs.map((tab, i) => (
      <div key={i}>
        <UtilButton
          text={tab.display}
          color="primary"
          outline={i !== this.props.currentCompanyTab.index}
          location="top"
          onClick={() => this.onTabClick(i, tab.path)}
          size="extra-small"
        >
          {tab.display}
          {tab.badge}
        </UtilButton>
      </div>
    ));
    return (
      <section className="container-fluid">
        <div className="row justify-center">{buttons}</div>
      </section>
    );
  }
}

export default withDataFeatureCheck(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(NavTabs)
  )
);
