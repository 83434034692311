import React from "react";

import { Progress } from "reactstrap";
import ProgressButton from "../ProgressButton/ProgressButton";
import Styles from "./ProgressIndicator.module.scss";

const ProgressIndicator = (
  {
    steps,
    currentStep,
    stepsCompleted,
    onClick,
  }
    :
    {
      steps: Array<string>,
      currentStep: number,
      stepsCompleted: number,
      onClick: (step: number) => void,
    }
) => {
  const indicators = steps.map((stepName, i) => (
    <ProgressButton
      key={i} // eslint-disable-line react/no-array-index-key
      stepName={stepName}
      stepIndex={i}
      active={currentStep === i}
      disabled={stepsCompleted < i}
      inProgress={stepsCompleted === i}
      totalSteps={steps.length}
      onClick={() => onClick(i)}
    />
  ));
  return (
    <div className="mb-4">
      <div className="desktop-version">
        <div className={`d-flex justify-content-between align-items-start ${Styles.IndicatorContainer}`}>
          <div className={Styles.ProgressBarContainer}>
            {steps.map((_, i) => (
              <Progress
                key={i} // eslint-disable-line react/no-array-index-key
                value={stepsCompleted >= i ? 1 : 0}
                max={1}
                className={`${Styles.ProgressBar}`}
                animated={false}
              />
            ))}

            <Progress
              value={stepsCompleted === steps.length ? 1 : 0}
              max={1}
              className={`${Styles.ProgressBar} ${Styles.ProgressBarEnd}`}
            />
          </div>
          {indicators}
        </div>
      </div>

      <div className="mobile-version d-flex">

        <ProgressButton
          stepName={steps[currentStep]}
          stepIndex={currentStep}
          active
          disabled={false}
          inProgress={stepsCompleted === currentStep}
          totalSteps={steps.length}
          onClick={() => onClick(currentStep)}
        />
        <div className="flex-fill align-items-end d-flex flex-column">
          <h4 className={`${Styles.CurrentStepLabel} text-uppercase m-0`}>
            {steps[currentStep]}
          </h4>
          {(currentStep < steps.length - 1) && (
            <h4 className={`${Styles.NextStepLabel} text-uppercase mt-auto m-0`}>
              {`NEXT: ${steps[currentStep + 1]}`}
            </h4>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProgressIndicator;
