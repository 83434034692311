import React from "react";
import StarRatingInput from "../../Controls/StarRatingInput/StarRatingInput";

const SupplierSearchRating = (
  {
    rating,
    totalRatings,
  }
  :
  {
    rating: number,
    totalRatings: number,
  }
) => (
  <div className="row  pt-5 px-2">
    <h2 className="col-12">COMPANY RATING</h2>
    <div className="col-12 col-sm-4">
      <h3 className="Details">Overall Rating</h3>
      <StarRatingInput
        value={rating}
        totalRatings={totalRatings}
        readonly
        showTotalRatings
        size="small"
      />
    </div>
  </div>
);

export default SupplierSearchRating;
