enum DocumentClassification {
  /* eslint-disable no-unused-vars */
  None = 0,
  BeeCertificate = 1,
  BeeAffidavit = 2,
  BankAccountDocument = 4,
  ShareholderIdentityDocument = 7,
  /* eslint-enable no-unused-vars */
}

export default DocumentClassification;
