import React from "react";
import Styles from "./Spinner.module.scss";

function Spinner({ inline }: { inline?:boolean }) {
  const spinnerType = inline ? Styles.loading_inline : "";
  return (
    <div className={[Styles.Loading, spinnerType].join(" ")} />
  );
}
export default Spinner;
