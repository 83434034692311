import React, { Component } from "react";

import InfoIcon from "../../Controls/InfoIcon";

class ExistingVendorImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
  }

  toggleIsActive = () => {
    this.setState((prevState) => ({
      isActive: !prevState.isActive,
    }));
  };

  render() {
    const imageName = this.state.isActive
      ? "/svg/checked.svg"
      : "/svg/unchecked.svg";
    return (
      <section>
        <div className="row">
          <div className="row col-12 col-sm-5">
            <div className="col-1">
              <img src={imageName} alt="" onClick={this.toggleIsActive} />
            </div>
            <div className="col-10">
              <h2>IMPORTING EXISTING VENDOR?</h2>
            </div>
            <div className="col-1">
              <InfoIcon msg="some msg" />
            </div>
          </div>
        </div>
        <div>
          <img src="/svg/download.svg" alt="" />
          <span className="text-primary clickable font-underline">
            Download Import Template
          </span>
        </div>
        <div>
          <table className="isuppli-table import-existing-vendor-table">
            <thead>
              <tr>
                <th>Template</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Please complete and upload the importing template with your
                  list of vendores
                </td>
                <td>
                  <img src="/svg/upload.svg" alt="" />
                  <span className="text-primary clickable font-underline">
                    Upload Vendor List
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    );
  }
}

export default ExistingVendorImport;
