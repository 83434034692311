import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  ShadowCard,
  Line,
  UtilButton,
  Spinner,
} from "isuppli-react-components";
import useShowError from "../../../Hooks/useShowError";
// eslint-disable-next-line no-unused-vars
import { ChannelMessage, getChannelList, PageTypes } from "../../../http/Messaging/messagesApi";
import ChatChannelListSection from "../../Messaging/ChatChannelListSection/ChatChannelListSection";

const MessageSummaryCard = () => {
  const [messageSummary, setMessageSummary] = useState<ChannelMessage[]>([]);
  const [loading, setLoading] = useState(false);
  const showError = useShowError();
  const history = useHistory();

  useEffect(() => {
    const loader = async () => {
      setLoading(true);
      try {
        // api calls
        const messageChannelList = await getChannelList({
          limit: 5,
          pageType: PageTypes.From,
        });
        setMessageSummary(messageChannelList);
      } catch (error) {
        showError();
      }
      setLoading(false);
    };
    loader();
  }, [setMessageSummary, setLoading, showError, history.location]);

  return (
    <Fragment>
      <h2>MESSAGES</h2>
      <ShadowCard>

        {loading ? <Spinner inline /> : (
          <div className="mb-4 pb-4">
            <div>
              {ChatChannelListSection({ messageCardList: messageSummary, timestampFormat: "dd MMM-hh:mm" })}
            </div>
            <Line color="primary" />
            <div className="d-flex justify-content-center">
              <UtilButton
                location="bottom"
                onClick={() => { history.push("/marketplace/messaging"); }}
              >
                VIEW MORE
              </UtilButton>
            </div>
          </div>
        )}

      </ShadowCard>
    </Fragment>
  );
};

export default MessageSummaryCard;
