import React from "react";
import {
  DataFeatures,
  DateInput,
  MultiSelectDropDownInput,
  WithDataFeature,
} from "isuppli-react-components";
import TextInput from "../../Controls/TextInput/TextInput";
import DropdownInput from "../../Controls/DropdownInput";
import ClientBaseTable from "./ClientBaseTable";
import ShareholderTable from "../ShareholdersTable/ShareholderTable";
import {
  isValidEmail,
  filterOutLetters,
  formatTelNumber,
  filterOutSpecialCharacters,
  maxLength,
} from "../../Validation/onChangeValidations";
import useStaticData from "../../Hooks/useStaticData";
import { StaticDataTypes } from "../../Util/Enumerators/staticDataTypes";
import CompanyGroupingType from "../../Util/Enumerators/CompanyGroupingType";
import AdditionalCompanyData from "../AdditionalCompanyData/AdditionalCompanyData";

const GeneralBusinessInformation = (props: any) => {
  const [[companyTypes, provinces], isReady] = useStaticData([StaticDataTypes.CompanyType, StaticDataTypes.Province]);
  let isSouthAfricanCompany = true;
  if (isReady) {
    const selectedCompanyType = companyTypes
      .find((companyType) => companyType.value === props.data.companyType);
    if (selectedCompanyType != null) {
      isSouthAfricanCompany = selectedCompanyType?.companyGroupingType
        === CompanyGroupingType.SouthAfrican;
    }
  }
  const telephoneValidation = isSouthAfricanCompany
    ? [filterOutLetters, formatTelNumber]
    : [filterOutLetters];
  return (
    <section id="isuppli-registration-contact-info">
      <div style={{ paddingTop: "53px" }}>
        <h2>COMPANY INFORMATION</h2>
      </div>

      <div className="row" style={{ paddingTop: "46px" }}>
        <div className="col-12 col-md-5">
          <TextInput
            label="Full name of person submitting the information"
            placeholder="enter your full name"
            name="submitterFullName"
            value={props.data.submitterFullName}
            iconName="user-bg-dark"
            additional={props.additional.submitterFullName}
            onChangeCallback={props.onInputChangeCallback}
            filters={[filterOutSpecialCharacters]}
            disabled={props.readOnly}
            maxLength={40}
            validations={[maxLength(40, props.setStepValid)]}
          />
        </div>

        <div className="col-md-2" />

        <div className="col-12 col-md-5">
          <TextInput
            label="Role of person submitting the information"
            placeholder="enter your business role"
            name="submitterRole"
            value={props.data.submitterRole}
            iconName="user-group"
            additional={props.additional.submitterRole}
            onChangeCallback={props.onInputChangeCallback}
            filters={[filterOutSpecialCharacters]}
            disabled={props.readOnly}
            maxLength={40}
            validations={[maxLength(40, props.setStepValid)]}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-5">
          <TextInput
            label="General Company Email Address"
            placeholder="company email address"
            name="generalEmail"
            value={props.data.generalEmail}
            iconName="email"
            additional={props.additional.generalEmail}
            validations={[isValidEmail(props.setStepValid)]}
            onChangeCallback={props.onInputChangeCallback}
            disabled={props.readOnly}
          />
        </div>

        <div className="col-md-2" />

        <div className="col-12 col-md-5">
          <TextInput
            label="Company Telephone Number"
            placeholder="phone number"
            name="generalPhone"
            value={props.data.generalPhone}
            iconName="user-bg-dark"
            additional={props.additional.generalPhone}
            filters={telephoneValidation}
            onChangeCallback={props.onInputChangeCallback}
            disabled={props.readOnly}
            maxLength={40}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-5">
          <TextInput
            label="Company Fax Number (optional)"
            placeholder="fax number"
            name="generalFax"
            value={props.data.generalFax}
            iconName="user-group"
            filters={[filterOutLetters, formatTelNumber]}
            onChangeCallback={props.onInputChangeCallback}
            disabled={props.readOnly}
          />
        </div>

        <div className="col-md-2" />

        <div className="col-12 col-md-5">
          <TextInput
            label="Company Website (optional)"
            placeholder="website url"
            name="generalWebsite"
            value={props.data.generalWebsite}
            iconName="planet"
            onChangeCallback={props.onInputChangeCallback}
            disabled={props.readOnly}
          />
        </div>
      </div>

      <div className="row">
        {
          props.businessTypes
          && ( // this will only render on the registration page
            <WithDataFeature dataFeature={DataFeatures.BusinessTypes}>
              <>
                <div className="col-12 col-md-5">
                  <DropdownInput
                    label="Business Type"
                    name="businessTypeId"
                    value={props.data.businessTypeId ?? -1}
                    additional={props.data.businessTypeId}
                    options={props.businessTypes}
                    onChangeCallback={props.onInputChangeCallback}
                    disabled={props.readOnly}
                  />
                </div>
                <div className="col-md-2" />
              </>
            </WithDataFeature>
          )
        }

        <WithDataFeature dataFeature={DataFeatures.DirectorNames}>
          <div className="col-12 col-md-5">
            <TextInput
              label="Director Names"
              placeholder="Please enter director names ( seperated by comma's )"
              name="directorsNames"
              value={props.data.directorsNames}
              iconName="user-bg-dark"
              onChangeCallback={props.onInputChangeCallback}
              disabled={props.readOnly}
            />
          </div>
        </WithDataFeature>
      </div>
      <div className="row">
        <WithDataFeature dataFeature={DataFeatures.BusinessRegions}>
          <div className="col-12 col-sm-5">
            <MultiSelectDropDownInput
              label="Geographical location/region(s) for conducting of primary business"
              value={props.data.businessRegionProvinceIds || []}
              options={provinces}
              validationMessage={props.additional.businessRegionProvinceIds}
              onChange={(selectedItems) => {
                const newEvent = {
                  target: {
                    name: "businessRegionProvinceIds",
                    value: selectedItems,
                  },
                };
                props.onInputChangeCallback(newEvent);
              }}
            />
          </div>
        </WithDataFeature>
      </div>
      <WithDataFeature dataFeature={DataFeatures.ClientBase}>
        <div className="mt-3">
          <ClientBaseTable
            data={props.data}
            updateClientBaseHandler={props.onInputChangeCallback}
            readOnly={props.readOnly}
          />
        </div>
      </WithDataFeature>
      <WithDataFeature dataFeature={DataFeatures.AdditionalCompanyData}>
        <div className="mt-3">
          <AdditionalCompanyData
            updateClientSpecificDataHandler={props.onInputChangeCallback}
            readOnly={props.readOnly}
            data={props.data}
          />
        </div>
      </WithDataFeature>
      {
        props.includeShareholders
        && (
          <WithDataFeature dataFeature={DataFeatures.ShareholdersDetails}>
            <ShareholderTable />
          </WithDataFeature>
        )
      }
      {props.data.additionalCompanyData?.workMan && (
        <div className="mt-3">
          <h4>Workmen&apos;s Compensation Document Details</h4>
          <h3>Please capture the following details found on your Workmn&apos;s Companesation Document</h3>

          <div className="row">
            <div className="col-lg-6 col-md-12">
              <TextInput
                label="Workmen's Compensation Certificate Number"
                placeholder="Enter your workman compensation number"
                name="workManCode"
                value={props.data.additionalCompanyData?.workManCode}
                onChangeCallback={(e: { target: { name: string | number; value: any; }; }) => {
                  const updatedAdditionalData = props.data.additionalCompanyData ?? {};
                  updatedAdditionalData[e.target.name] = e.target.value;
                  props.onInputChangeCallback({
                    target: {
                      name: "additionalCompanyData",
                      value: updatedAdditionalData,
                    },
                  });
                }}
                disabled={false}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-12">
              <DateInput
                label="Expiry Date"
                value={new Date(props.data.additionalCompanyData?.workManExpiredDate ?? "")}
                onChange={(value: Date) => {
                  const updatedAdditionalData = props.data.additionalCompanyData ?? {};
                  updatedAdditionalData.workManExpiredDate = value;
                  props.onInputChangeCallback({
                    target: {
                      name: "additionalCompanyData",
                      value: updatedAdditionalData,
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
      )}

    </section>
  );
};

export default GeneralBusinessInformation;
