import React, {
  useState, Fragment, useRef, useCallback, useEffect,
} from "react";
import axios, {
  // eslint-disable-next-line no-unused-vars
  CancelTokenSource,
} from "axios";
import { useHistory } from "react-router-dom";
import { ModalPopup, useDebounce, ListEmptyState } from "isuppli-react-components";
import InfiniteScroll from "../../../Controls/InfiniteScroll/InfiniteScroll";

import MessageComposer from "../../../Sections/Messaging/MessageComposer/MessageComposer";
import Styles from "./NewMessageModal.module.scss";
import SecondarySearchInput from "../../../Controls/SecondarySearchInput/SecondarySearchInput";
import ChatChannelCard from "../ChatChannelCard/ChatChannelCard";
import {
  getChannelList,
  sendMessage,
  Message, // eslint-disable-line no-unused-vars
} from "../../../http/Messaging/messagesApi";

// delay before executing search on input change
const inputDebounce = 500;

type Recipient = {
  name: string,
  channelId: number,
}

const NewMessageModal = (
  {
    onClose,
    onMessageSent,
  }
    : {
      onClose: () => void
      onMessageSent: (message: Message, channelId: number) => void
    }
) => {
  const history = useHistory();

  const [channel, setChannel] = useState<Recipient>();
  const [searchTerm, setSearchTerm] = useState("");
  const [draftMessageId, setDraftMessageId] = useState<number>();
  const [channelSearchResult, setChannelSearchResult] = useState<Recipient[]>([]);
  const [hasMoreSearch, setHasMoreSearch] = useState(true);
  const [forceUpdate, setForceUpdate] = useState<() => Promise<void>>();
  const [sending, setSending] = useState(false);
  const debouncing = useRef(false);
  const httpCancelToken = useRef<CancelTokenSource>();

  // cancel request when disposing
  useEffect(() => () => {
    if (httpCancelToken.current != null) {
      httpCancelToken.current.cancel();
    }
  }, []);

  const loadSearchResults = async () => {
    if (httpCancelToken.current != null) {
      httpCancelToken.current.cancel();
    }
    if (debouncing.current) {
      return false;
    }

    try {
      const limit = 20;
      httpCancelToken.current = axios.CancelToken.source();
      const newItems = await getChannelList({
        pageId: channelSearchResult[channelSearchResult.length - 1]?.channelId,
        limit,
        nameFilter: searchTerm,
        config: {
          cancelToken: httpCancelToken.current.token,
        },
      });
      httpCancelToken.current = undefined;

      if (newItems.length < limit) {
        setHasMoreSearch(false);
      }

      setChannelSearchResult((prevResults) => [
        ...prevResults,
        ...newItems.map((c) => ({ name: c.name, channelId: c.id })),
      ]);
      return true;
    } catch (error) {
      httpCancelToken.current = undefined;
      if (axios.isCancel(error)) {
        return false;
      }
      throw error;
    }
  };

  const [
    queSearchDebounce,
  ] = useDebounce(async () => {
    debouncing.current = false;

    // trigger infinite scroll to load
    setHasMoreSearch(false);
    setHasMoreSearch(true);
  },
  inputDebounce);

  const onSearchTermChangeHandler = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
    queSearchDebounce();
    // set list into loading state
    setHasMoreSearch(true);
    setChannelSearchResult([]);
    debouncing.current = true;
  };

  const onChannelClickHandler = async (selectedChannel: Recipient) => {
    setChannel(selectedChannel);
  };

  const onSendHandler = async () => {
    if (channel == null || draftMessageId == null) {
      onClose();
      return;
    }
    setSending(true);

    if (forceUpdate != null) {
      await forceUpdate();
    }

    const sentMessage = await sendMessage(channel.channelId, draftMessageId ?? -1);
    onMessageSent(sentMessage, channel.channelId);
    onClose();
  };

  const getForceUpdateHandler = useCallback((handler: () => Promise<void>) => {
    setForceUpdate(() => handler);
  }, [setForceUpdate]);

  return (
    <ModalPopup
      buttons={channel == null ? [] : [{
        key: "send",
        label: "Send",
        color: "primary",
        onClick: () => {
          onSendHandler();
        },
        disabled: sending,
      }]}
      heading="New Message"
      subheading={channel == null ? "Select a recipient." : `Sending a message to ${channel.name}`}
      onClose={onClose}
      hasCancel
    >
      <div className="row px-2">
        <div className={` ${Styles.NewMessageModal} col-12 overflow-auto`}>
          {
            channel == null
              ? (
                <Fragment>
                  <div>
                    <SecondarySearchInput
                      placeholder="Search existing recipients"
                      value={searchTerm}
                      onChange={onSearchTermChangeHandler}
                      hideFilter
                    />
                  </div>
                  <InfiniteScroll
                    loadMore={loadSearchResults}
                    hasMore={hasMoreSearch}
                  >
                    {
                      channelSearchResult
                        .map((chanelResultItem) => (
                          <ChatChannelCard
                            key={chanelResultItem.channelId}
                            companyName={chanelResultItem.name}
                            onClickCallback={() => onChannelClickHandler(chanelResultItem)}
                          />
                        ))
                    }
                  </InfiniteScroll>
                  {channelSearchResult.length === 0 && !hasMoreSearch
                    ? (
                      <ListEmptyState
                        heading="NO COMPANIES FOUND"
                        button={
                          {
                            text: "Search Supplier Database",
                            onClick: () => history.push(`/marketplace/search?limit=20&offset=0&q=${searchTerm}&s=0&sf=0`),
                          }
                        }
                      >
                        No recipients were found for existing conversations.
                        Search the supplier database by clicking the button below to
                        start a new conversation with a company.
                      </ListEmptyState>
                    )
                    : null}
                </Fragment>
              )
              : (
                <div className="w-100 h-100 position-relative">
                  <MessageComposer
                    onMessageIdChange={setDraftMessageId}
                    channelId={channel.channelId}
                    alwaysOpen
                    hideSendButton
                    getForceUpdate={getForceUpdateHandler}
                  />
                </div>
              )
          }
        </div>
      </div>
    </ModalPopup>
  );
};

export default NewMessageModal;
