/* eslint-disable no-nested-ternary */
import React, { Fragment } from "react";
import { Badge, Alert } from "reactstrap";
import {
  WithFeature, SystemFeatures, DataFeatures, hasFeature,
} from "isuppli-react-components";
import addressTypes from "../../Util/Enumerators/addressTypes";
import DetailsItem from "../../Controls/DetailsItem";
import {
  getStaticValue,
  formatDate,
  formatPercentage,
} from "../../Util/valueFormatter";

import { complianceCheckTypeToString } from "../../Util/Enumerators/ComplianceCheckType";
import BeeValidationStatus,
{
  getBeeValidationStatusBadgeColor,
  beeValidationStatusToString,
} from "../../Util/Enumerators/beeValidationStatus";
import ComplianceStatus from "../../Util/Enumerators/ComplianceStatus";
import ProductsAndServicesList from "../Supplier/ProductsAndServicesList";

function SupplierSearchDetails({
  loading,
  companyDetails,
  clientSpecificInfo,
  provinces,
  beeLevels,
  countries,
  companyTypes,
  sectors,
  turnoverBands,
  beeSectors,
  complianceDetails,
  supplierTransactionStatuses,
  municipalities,
  supplierSubCategories,
}) {
  const item = (itemName, itemValue, badge) => (
    <DetailsItem loading={loading} label={itemName} value={itemValue} badge={badge} />
  );

  const referencedItem = (itemName, itemValue, options) => item(itemName, getStaticValue(itemValue, options));

  const processBadge = (date) => {
    if (date !== null && new Date(date) < new Date()) {
      return (
        <Badge
          className="ml-2"
          color="danger"
        >
          Expired
        </Badge>
      );
    }
    return null;
  };

  const physicalAddress = companyDetails.addresses != null
    ? companyDetails.addresses.find((a) => a.type === addressTypes.Physical) ?? {}
    : {};
  const postalAddress = companyDetails.addresses != null
    ? companyDetails.addresses.find((a) => a.type === addressTypes.Physical) ?? {}
    : {};

  // object destructuring
  const {
    supplierType,
    paymentTerms,
    businessUnits,
    categoryManagerUser,
    contractTier,
    businessType,
  } = clientSpecificInfo;

  const { businessRegionProvinceIds } = companyDetails;

  const beeInfo = companyDetails.beeInfo || {};

  // use empty string instead of null so that isBeeExpired is false if no date provided
  const isBeeExpired = new Date(beeInfo.certificateExpiryDate ?? "") < new Date();

  const businessUnitsDisplay = businessUnits?.length > 0
    ? businessUnits.map((x, index) => (index + 1 < businessUnits.length
      ? `${x.shortName}, `
      : `${x.shortName}`
    )) : "N/A";

  const businessRegionsDisplay = businessRegionProvinceIds?.length > 0
    ? businessRegionProvinceIds?.map((id, index) => (
      index + 1 < businessRegionProvinceIds.length
        ? `${getStaticValue(id, provinces)}, `
        : `${getStaticValue(id, provinces)}`
    ))
    : "N/A";

  return (
    <div className="bg-light row p-5">
      <div className="col-12 col-md-6">
        {referencedItem(
          "Company Type",
          companyDetails.companyType,
          companyTypes
        )}
        {item("Registered Company Name", companyDetails.registeredName)}
        {item("Company Trading Name", companyDetails.tradingName)}
        {item(
          "Company Registration Number",
          companyDetails.registrationNumber
        )}
        {item("Tax Clearance Pin Number", companyDetails.taxClearanceNumber)}
        {item(
          "TCC Expiry Date",
          companyDetails.taxClearanceNumberExpiryDate !== null
            ? formatDate(new Date(companyDetails.taxClearanceNumberExpiryDate))
            : null,
          processBadge(companyDetails.taxClearanceNumberExpiryDate)
        )}
        {companyDetails.additionalCompanyData?.workMan && item(
          "Workmen’s Compensation Certificate Number",
          companyDetails.additionalCompanyData?.workManCode ?? "N/A"
        )}
        {companyDetails.additionalCompanyData?.workMan && item(
          "WCD Expiry Date",
          companyDetails.additionalCompanyData?.workManExpiredDate !== null
            ? formatDate(new Date(companyDetails.additionalCompanyData?.workManExpiredDate))
            : null
        )}
      </div>

      <div className="col-12 col-md-6 mt-md-0 mt-5">
        {item("VAT Registered", companyDetails.hasVatNumber ? "Yes" : "No")}
        {item("Company Telephone Number", companyDetails.generalPhone)}
        <div className="d-flex">
          <div className="w-100 pr-3 pt-2 font-weight-bold">General Company Email Address</div>
          <div className="w-75 pt-2 ">
            {loading
              ? "..."
              : <a href={`mailto:${companyDetails.generalEmail}`}>{companyDetails.generalEmail}</a>}
          </div>
        </div>
        {companyDetails.transactionStatusId > 0 && (
          <>
            {item("Transaction Status:", supplierTransactionStatuses.find((status) => (
              status.id === companyDetails.transactionStatusId))?.name ?? "")}
            {item("Order Type", companyDetails.orderType ? "Yes" : "No")}
            {item("Exception Flag", companyDetails.exceptionFlag ?? "")}
          </>
        )}
      </div>

      <div className="col-12 col-md-6 mt-5">
        <h3 className="Details">PHYSICAL ADDRESS DETAILS</h3>
        {loading ? (
          <div>...</div>
        ) : (
          <Fragment>
            <div>{physicalAddress.unitNumber}</div>
            <div>{physicalAddress.streetAddressL1}</div>
            <div>{physicalAddress.streetAddressL2}</div>
            <div>{physicalAddress.city}</div>
            <div>{getStaticValue(physicalAddress.province, provinces)}</div>
            <div>{getStaticValue(physicalAddress.country, countries)}</div>
            <div>{physicalAddress.postalCode}</div>
          </Fragment>
        )}
      </div>

      <div className="col-12 col-md-6 mt-5">
        <h3 className="Details">POSTAL ADDRESS DETAILS</h3>
        {loading ? (
          <div>...</div>
        ) : (
          <Fragment>
            <div>{postalAddress.unitNumber}</div>
            <div>{postalAddress.streetAddressL1}</div>
            <div>{postalAddress.streetAddressL2}</div>
            <div>{postalAddress.city}</div>
            <div>{getStaticValue(postalAddress.province, provinces)}</div>
            <div>{getStaticValue(postalAddress.country, countries)}</div>
            <div>{postalAddress.postalCode}</div>
          </Fragment>
        )}
      </div>

      <div className="col-12 col-md-6 mt-5">
        <h3 className="Details">
          B-BBEE CERTIFICATION
          {beeInfo.validationStatus != null
            && beeInfo.validationStatus !== BeeValidationStatus.None
            ? (
              <Badge
                className="ml-2"
                color={isBeeExpired ? "danger" : getBeeValidationStatusBadgeColor(beeInfo.validationStatus)}
              >
                {isBeeExpired ? "Expired" : beeValidationStatusToString(beeInfo.validationStatus)}
              </Badge>
            )
            : null}
        </h3>
        {item(
          "B-BBEE Certificate/Affidavit",
          beeInfo.hasBeeCertificate ? "Yes" : "No"
        )}
        {beeInfo.hasBeeCertificate === 1
          && referencedItem("B-BBEE Level", beeInfo.beeStatusId, beeLevels)}
        {beeInfo.hasBeeCertificate === 1 && item(
          "Expiry Date of Certificate",
          formatDate(beeInfo.certificateExpiryDate)
        )}
        {beeInfo.hasBeeCertificate === 1
          && referencedItem(
            "B-BBEE Classification",
            beeInfo.beeClassificationId,
            beeSectors
          )}
        {beeInfo.hasBeeCertificate === 1
          && item("Black Ownership", formatPercentage(beeInfo.boPercentage))}
        {beeInfo.hasBeeCertificate === 1
          && item("Black Woman Ownership", formatPercentage(beeInfo.bwoPercentage))}
        {beeInfo.hasBeeCertificate === 1
          && referencedItem(
            "B-BBEE Sector",
            beeInfo.sector,
            sectors
          )}
        {beeInfo.hasBeeCertificate === 1
          && referencedItem(
            "Annual Turnover",
            beeInfo.turnoverBand,
            turnoverBands
          )}
      </div>

      {
        !hasFeature(supplierType?.dataFeatures ?? 0, DataFeatures.ShareholdersDetails) // bad way of removing it for FA
        && (
          <div className="col-12 mt-5">
            <h3 className="Details">PRODUCT OR SERVICE DESCRIPTION</h3>
            {loading ? (
              <div>...</div>
            ) : (
              <div>
                {companyDetails.tradeDescription}
              </div>
            )}
          </div>
        )
      }

      <WithFeature feature={SystemFeatures.SupplierChecks}>
        <div className="col-12 mt-5">
          <h3 className="Details">REGULATORY COMPLIANCE</h3>
        </div>
        {
          complianceDetails == null
          && (
            <div className="col-12">
              Loading
            </div>
          )
        }
        {
          complianceDetails != null
            && complianceDetails.complianceResults != null
            && complianceDetails.status !== ComplianceStatus.Error
            && complianceDetails.complianceResults != null
            && complianceDetails.complianceResults.length > 0
            ? complianceDetails.complianceResults.map((check) => {
              let status = "";
              let statusClass = "Black";
              if (check.totalFailedChecks > 0) {
                status = "Failed";
                statusClass = "Failed";
              } else if (check.totalPassedChecks > 0) {
                status = "Passed";
                statusClass = "Success";
              } else {
                status = "Outstanding";
              }
              return (
                <div className="col-4 col-md-2" key={check.verificationTypeId}>
                  <h3 className="Details">{complianceCheckTypeToString(check.verificationTypeId)}</h3>
                  <h3 className={statusClass}>{status}</h3>
                </div>
              );
            })
            : (
              complianceDetails != null
              && (
                <Alert className="col-12" color={complianceDetails.status === ComplianceStatus.Error ? "danger" : "info"}>
                  {complianceDetails.error || "No compliance details available"}
                </Alert>
              )
            )
        }
      </WithFeature>
      {
        clientSpecificInfo && (
          <Fragment>
            <h3 className="Details w-100 mx-2 mt-2 px-2 pt-2">CLIENT SPECIFIC INFO</h3>
            <div className="col-12 col-md-6">
              {loading ? (
                <div>...</div>
              ) : (
                <Fragment>
                  {item("Business Units", businessUnitsDisplay)}
                  {item("Supplier Type", supplierType?.name ?? "N/A")}
                  {contractTier && (item("Contract Tier", contractTier?.name ?? "N/A"))}
                  {paymentTerms && item("Payment Terms", paymentTerms?.name ?? "N/A")}
                  {categoryManagerUser && item("Category Manager", categoryManagerUser.fullName ?? "N/A")}
                  {categoryManagerUser && item("Category Manager Code", categoryManagerUser.categoryManagerCode ?? "N/A")}
                </Fragment>
              )}
            </div>
            <div className="col-12 col-md-6 mt-md-0 mt-10">
              {loading ? (
                <div>...</div>
              ) : (
                <Fragment>
                  {
                    hasFeature(supplierType?.dataFeatures ?? 0, DataFeatures.DirectorNames)
                    && item("Director Names", companyDetails.directorsNames ?? "N/A")
                  }
                  {
                    hasFeature(supplierType?.dataFeatures ?? 0, DataFeatures.AdditionalCompanyData)
                    && (
                      <>
                        {item("Number of Employees", companyDetails.additionalCompanyData?.numberOfEmployees ?? "N/A")}
                        {item("Royal Bofokeng Resident", companyDetails.additionalCompanyData?.royalBafokengNationalResident == null ? "N/A"
                          : companyDetails.additionalCompanyData?.royalBafokengNationalResident === 1 ? "Yes" : "No")}
                      </>
                    )
                  }
                  {
                    hasFeature(supplierType?.dataFeatures ?? 0, DataFeatures.BusinessTypes)
                      && item("Business Type", businessType?.name ?? "N/A")
                  }
                  {
                    // conditionally render if:
                    // dataFeature applies to supplierType OR royal bafokeng question !== undefined
                    (hasFeature(supplierType?.dataFeatures ?? 0, DataFeatures.MandatoryMunicipality)
                     || companyDetails.additionalCompanyData?.royalBafokengNationalResident != null)
                    && (physicalAddress.municipality
                      ? referencedItem("Municipality", physicalAddress.municipality, municipalities)
                      : item("Municipality", "N/A"))
                  }
                  {
                    hasFeature(supplierType?.dataFeatures ?? 0, DataFeatures.BusinessRegions)
                      && item("Region(s) for conducting of primary business",
                        businessRegionsDisplay)
                  }
                </Fragment>
              )}
            </div>
          </Fragment>
        )
      }
      {
        hasFeature(supplierType?.dataFeatures ?? 0, DataFeatures.ShareholdersDetails) // bad way of only showing to FA
          && (
            <Fragment>
              <h2 className="Details w-100 mx-2 mt-2 px-2 pt-2">PRODUCT SEGMENTS & FAMILIES</h2>
              <div className="col-12 col-md-6">
                <ProductsAndServicesList
                  selectedSupplierSubCategoryIds={supplierSubCategories}
                />
              </div>
            </Fragment>
          )
      }
    </div>
  );
}

export default SupplierSearchDetails;
