import React, { useState } from "react";
import Styles from "./MultiSelectDropdown.module.scss";
import ActionsDropdown from "../ActionsDropdown/ActionsDropdown";
import CheckboxInput from "../CheckboxInput/CheckboxInput";

type ItemInfo = {
  display: string,
  value: number | string,
}

const MultiSelectDropDown = ({
  label,
  items,
  values,
  onChange,
  maxSelectedItems,
  className,
  buttonClassName,
  disabled,
  scrollingMenu,
  canSearch,
}: {
    label: string,
    items: ItemInfo[],
    values: Array<string | number>,
    onChange: (listOfSelectedValues: Array<string | number>) => void,
    maxSelectedItems?: number,
    className: string,
    buttonClassName?: string,
    disabled?: boolean,
    scrollingMenu?: boolean,
    canSearch?: boolean,
  }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const onChangeHandler = (itemValue: number | string, newValue: boolean) => {
    let listOfSelectedValues = [...values];

    if (newValue && !listOfSelectedValues.includes(itemValue)) {
      listOfSelectedValues.push(itemValue);

      if (
        maxSelectedItems != null && maxSelectedItems > 0
        && listOfSelectedValues.length > maxSelectedItems
      ) {
        listOfSelectedValues.shift();
      }
    }

    if (!newValue && listOfSelectedValues.includes(itemValue)) {
      listOfSelectedValues = listOfSelectedValues.filter((c) => c !== itemValue);
    }

    onChange(listOfSelectedValues);
  };

  let badgeValue;
  if (values.length > 0) {
    if (maxSelectedItems === 1) {
      badgeValue = "Selected";
    } else {
      badgeValue = values.length.toString();
    }
  }

  let listItems = items ?? [];
  if (canSearch && searchQuery) {
    listItems = listItems.filter((item) => item.display.toLowerCase().includes(searchQuery));
  }

  return (
    <ActionsDropdown
      badge={badgeValue}
      label={label}
      styleVal="light"
      className={`${className}`}
      buttonClassName={buttonClassName}
      disabled={items == null || disabled}
      scrollingMenu={scrollingMenu}
      searchQuery={canSearch ? searchQuery : undefined}
      onSearchQueryChange={
        canSearch
          ? (newSearchQuery) => setSearchQuery(newSearchQuery)
          : undefined
      }
    >
      {listItems.map((item) => {
        const isSelected = values.includes(item.value);
        return (
          <CheckboxInput
            key={item.value}
            label={item.display}
            value={isSelected}
            onChange={(newValue: boolean) => onChangeHandler(item.value, newValue)}
            size="small"
            className={`${Styles.CheckboxInput}`}
          />
        );
      })}
    </ActionsDropdown>
  );
};

export default MultiSelectDropDown;
