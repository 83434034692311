import React, { Fragment } from "react";
import { useLocation, Link } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import { MenuItem } from "../../utils/interfaces/MenuItem";
import Styles from "./BreadCrumbs.module.scss";

interface BreadCrumbItem {
  key: string,
  title: string,
  to?: string
}

const BreadCrumbs = ({
  additional,
  menuItems,
}: {
    additional?: string,
    menuItems: Array<MenuItem>
}) => {
  const getBreadCrumbs = () => {
    const location = useLocation();
    const [, firstItem, child] = location.pathname.toLowerCase().split("/");
    const breadCrumbItems: BreadCrumbItem[] = [];
    // eslint-disable-next-line react/prop-types
    const menuItem = menuItems.find((i) => i.to.toLowerCase() === `/${firstItem}`);

    if (menuItem != null) {
      breadCrumbItems.push(
        {
          ...menuItem,
          key: "current",
          to: undefined,
        }
      );

      if (menuItem.children != null) {
        const childItem: BreadCrumbItem = { key: "", ...menuItem.children.find((c) => c.to.toLowerCase() === child) } as BreadCrumbItem;
        if (childItem != null) {
          childItem.to = `${menuItem.to}/${childItem.to}`;
          childItem.key = childItem.to;
          breadCrumbItems.push(childItem);
        }
      }

      if (additional != null) {
        const additionalItem: BreadCrumbItem = { key: "additional", title: additional, to: undefined };
        breadCrumbItems.push(additionalItem);
      }
    }

    return (
      <Fragment>
        {breadCrumbItems.map((routeItem, i) => (
          <Fragment
            key={routeItem.key}
          >
            {i !== 0 ? " > " : ""}
            {routeItem.to == null || (i === (breadCrumbItems.length - 1))
              ? <span>{routeItem.title}</span>
              : <Link className={`${Styles.BreadCrumbs}`} to={routeItem.to}>{routeItem.title}</Link>}
          </Fragment>
        ))}
      </Fragment>
    );
  };

  return (
    <div>
      {getBreadCrumbs()}
    </div>
  );
};
export default BreadCrumbs;
