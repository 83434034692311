import React from "react";
import Styles from "./Footer.module.scss";

const Footer = () => (
  <section className={`${Styles.Footer} d-flex flex-column justify-content-center align-items-center align-items-md-start`}>
    <div className={`${Styles.Links} px-4`}>
      <a
        href="/Home/TermsAndConditions"
        target="_blank"
        className="ml-2 py-3 d-inline-block"
        rel="noreferrer"
      >
        Terms & Conditions
      </a>
    </div>

    <div className={`${Styles.Rights} mb-3 mb-md-0`}>
      {`B1LINK® | All Rights Reserved ${(new Date().getFullYear())}`}
    </div>
  </section>
);

export default Footer;
