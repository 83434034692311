import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setStaticData } from "../Store/actions";
import { getOptionsAsync } from "../http/index";
import {
  StaticDataTypes, // eslint-disable-line no-unused-vars
} from "../Util/Enumerators/staticDataTypes";
import {
  ReduxState, // eslint-disable-line no-unused-vars
} from "../Store/ReduxState";

import useThreadSafe from "./useThreadSafe";

export interface staticOption {
  value: number,
   display: string,
   [key: string]: any,
}

const useStaticData = (optionsToLoad: StaticDataTypes[]) : [staticOption[][], boolean] => {
  const [isReady, setIsReady] = useState(false);
  const storeOptions = useSelector((state: ReduxState) => state.staticData) ?? [];
  const cachedOptions = optionsToLoad.map((option) => storeOptions[option]);
  const dispatch = useDispatch();

  const currentStoreOptions = useRef(storeOptions);
  useEffect(() => {
    currentStoreOptions.current = storeOptions;
  }, [storeOptions]);

  const loader = useThreadSafe(async () => {
    // which options should I load
    const optionsToGetFromApi = optionsToLoad.filter(
      (option) => currentStoreOptions.current[option] == null
    );

    if (optionsToGetFromApi.length === 0) {
      setIsReady(true);
      return;
    }

    // call api
    const loadedOptions: {
      [key: string]: {
        id: number,
        name: string,
        [key: string]: any
      }[]
    } = await getOptionsAsync(optionsToGetFromApi);
    const dispatchPayload: {
      type: StaticDataTypes,
      value: staticOption[],
    }[] = [];

    const objectToDisplayObject = (object: { id: number, name: string }) => ({
      ...object,
      value: object.id,
      display: object.name,
    });

    optionsToGetFromApi.forEach((option) => {
      switch (option) {
        case StaticDataTypes.SubscriptionTier:
          const data = loadedOptions.subscriptionTierData;
          dispatchPayload.push({
            type: option,
            value: data.map((category) => ({
              ...objectToDisplayObject(category),
            })),
          });
          break;
        case StaticDataTypes.BeeClassification:
          const bcData = loadedOptions.beeClassificationData;
          dispatchPayload.push({
            type: option,
            value: bcData.map((category) => ({
              ...objectToDisplayObject(category),
            })),
          });
          break;
        case StaticDataTypes.DocumentTypes:
          const documentTypeData = loadedOptions.documentTypes;
          dispatchPayload.push({
            type: option,
            value: documentTypeData.map((d) => ({
              ...objectToDisplayObject(d),
            })),
          });
          break;
        case StaticDataTypes.SectorTurnoverBand:
          const sectorTurnoverData = loadedOptions.sectorTurnoverBandData;
          dispatchPayload.push({
            type: option,
            value: sectorTurnoverData.map((d) => ({
              ...objectToDisplayObject(d),
            })),
          });
          break;
        case StaticDataTypes.SectorTurnoverBandStatus:
          const sectorTurnoverStatusData = loadedOptions.sectorTurnoverBandStatusData;
          dispatchPayload.push({
            type: option,
            value: sectorTurnoverStatusData.map((d) => ({
              ...objectToDisplayObject(d),
            })),
          });
          break;
        case StaticDataTypes.Sector:
          const sectorData = loadedOptions.sectorData;
          dispatchPayload.push({
            type: option,
            value: sectorData.map((d) => ({
              ...objectToDisplayObject(d),
            })),
          });
          break;
        case StaticDataTypes.TurnoverBand:
          const turnoverBandData = loadedOptions.turnoverBandData;
          dispatchPayload.push({
            type: option,
            value: turnoverBandData.map((d) => ({
              ...objectToDisplayObject(d),
            })),
          });
          break;
        case StaticDataTypes.ContactPerson:
          const contractPersons = loadedOptions.contactPersonTypes;
          dispatchPayload.push({
            type: option,
            value: contractPersons.map((d) => ({
              ...objectToDisplayObject(d),
            })),
          });
          break;
        case StaticDataTypes.Bank:
          const banks = loadedOptions.bankData;
          dispatchPayload.push({
            type: option,
            value: banks.map((d) => ({
              ...objectToDisplayObject(d),
            })),
          });
          break;
        case StaticDataTypes.BankAccountType:
          const bankAccounts = loadedOptions.bankAccountTypeData;
          dispatchPayload.push({
            type: option,
            value: bankAccounts.map((d) => ({
              ...objectToDisplayObject(d),
            })),
          });
          break;
        case StaticDataTypes.CompanyType:
          const companyTypes = loadedOptions.companyTypeData;
          dispatchPayload.push({
            type: option,
            value: companyTypes.map((d) => ({
              ...objectToDisplayObject(d),
            })),
          });
          break;
        case StaticDataTypes.BusinessTypes:
          const businessTypes = loadedOptions.businessTypeData;
          dispatchPayload.push({
            type: option,
            value: businessTypes.map((d) => ({
              ...objectToDisplayObject(d),
            })),
          });
          break;
        case StaticDataTypes.Country:
          const countries = loadedOptions.countryData;
          dispatchPayload.push({
            type: option,
            value: countries.map((c) => ({
              ...objectToDisplayObject(c),
            })),
          });
          break;
        case StaticDataTypes.Province:
          const province = loadedOptions.provinceData;
          dispatchPayload.push({
            type: option,
            value: province.map((p) => ({
              ...objectToDisplayObject(p),
            })),
          });
          break;
        case StaticDataTypes.BeeStatus:
          const beeStatuses = loadedOptions.beeStatusData;
          dispatchPayload.push({
            type: option,
            value: beeStatuses.map((p) => ({
              ...objectToDisplayObject(p),
            })),
          });
          break;
        case StaticDataTypes.UnspscSegment:
          const unspscSegments = loadedOptions.unspscSegmentData;
          dispatchPayload.push({
            type: option,
            value: unspscSegments.map((d) => ({
              ...objectToDisplayObject(d),
            })),
          });
          break;
        case StaticDataTypes.UnspscFamily:
          const unspscFamilyData = loadedOptions.unspscFamilyData;
          dispatchPayload.push({
            type: option,
            value: unspscFamilyData.map((d) => ({
              ...objectToDisplayObject(d),
            })),
          });
          break;

        case StaticDataTypes.Gender:
          const genderData = loadedOptions.genderData;
          dispatchPayload.push({
            type: option,
            value: genderData.map((g) => ({
              ...objectToDisplayObject(g),
            })),
          });
          break;

        case StaticDataTypes.Race:
          const raceData = loadedOptions.raceData;

          dispatchPayload.push({
            type: option,
            value: raceData.map((r) => ({
              ...objectToDisplayObject(r),
            })),
          });
          break;

        case StaticDataTypes.Municipality:
          const municipalityData = loadedOptions.municipalityData;

          dispatchPayload.push({
            type: option,
            value: municipalityData.map((m) => ({
              ...objectToDisplayObject(m),
            })),
          });
          break;

        case StaticDataTypes.CompanyStatus:
          const companyStatuses = loadedOptions.companyStatusData;

          dispatchPayload.push({
            type: option,
            value: companyStatuses.map((m) => ({
              ...objectToDisplayObject(m),
            })),
          });
          break;

        default:
          break;
      }
    });

    dispatch(setStaticData(dispatchPayload));
    setIsReady(true);
  });

  useEffect(() => {
    loader();
  }, [dispatch, optionsToLoad, loader]);

  return [cachedOptions, isReady];
};

export default useStaticData;
