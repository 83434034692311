import axios, {
  // eslint-disable-next-line no-unused-vars
  AxiosRequestConfig,
} from "axios";

import { makeRequestAsync } from "../util";

// eslint-disable-next-line import/prefer-default-export
export const SearchUsnpscFamilies = async (
  searchTerm: string,
  config : AxiosRequestConfig | undefined
) => {
  const url = `api/search/unspsc?searchTerm=${searchTerm}`;
  return makeRequestAsync(() => axios
    .get(url, config)) as Promise<Array<number>>;
};
