/* eslint-disable no-unused-vars */
enum BeeStateFilter {
  None = 0,
  Valid = 1,
  Invalid = 2,
  Expired = 3,
  PendingValidation = 4,
  Outstanding = 5,
  Exclusion = 6,
  ValidForClient = 7,
}
/* eslint-enable no-unused-vars */

export const beeStateFilterToString = (value: BeeStateFilter) => {
  switch (value) {
    case BeeStateFilter.None:
      return "None";
    case BeeStateFilter.Valid:
      return "Valid";
    case BeeStateFilter.Invalid:
      return "Invalid";
    case BeeStateFilter.Expired:
      return "Expired";
    case BeeStateFilter.PendingValidation:
      return "Pending Validation";
    case BeeStateFilter.Outstanding:
      return "Outstanding";
    case BeeStateFilter.Exclusion:
      return "Exclusion";
    case BeeStateFilter.ValidForClient:
      return "Valid For Client";
    default:
      return "Unknown";
  }
};

export default BeeStateFilter;
