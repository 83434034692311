import React, { useEffect, useState, useRef } from "react";
import {
  CheckboxInput,
  ModalPopup,
  DropDownInput,
  formatTelephoneNumber,
  TextInput,
  // eslint-disable-next-line no-unused-vars
  ButtonInfo,
  Form,
  // eslint-disable-next-line no-unused-vars
  FormRef,
} from "isuppli-react-components";
import { FormGroup, Input } from "reactstrap";
import { StaticDataTypes } from "../../Util/Enumerators/staticDataTypes";
import useStaticData from "../../Hooks/useStaticData";
// eslint-disable-next-line no-unused-vars
import { EditShareholderMessage } from "../../http/Shareholders/EditShareholderMessage";
// eslint-disable-next-line no-unused-vars
import { Document } from "../../http/ManualSupplier/Models/Document";
import DocumentUploader from "../Supplier/DocumentUploader";
import DocumentClassification from "../../Util/Enumerators/DocumentClassification";

const ShareholderDetailsModal = ({
  shareholderMessage,
  readOnly,
  onClose,
  onUpdate,
}: {
  shareholderMessage: EditShareholderMessage;
  readOnly: boolean;
  onClose: () => void;
  onUpdate: (item: EditShareholderMessage) => void;
}) => {
  const [[genderData, raceData, documentTypes], isStaticDataReady] = useStaticData([
    StaticDataTypes.Gender,
    StaticDataTypes.Race,
    StaticDataTypes.DocumentTypes,
  ]);

  const formRef = useRef<FormRef>(null);
  const [modalValues, setModalValues] = useState<EditShareholderMessage>(
    {} as EditShareholderMessage
  );
  const [shareholderPercentage, setShareholderPercentage] = useState("");

  // Set the initial modalValues state, and keep it up to date.
  useEffect(() => {
    setModalValues(shareholderMessage);
    setShareholderPercentage(shareholderMessage.sharePercentage?.toString() ?? "");
  }, [shareholderMessage]);

  const getDefaultGender = () => (isStaticDataReady ? genderData.find((g) => g.name === "Other")?.id : 0);

  const getDefaultRace = () => (isStaticDataReady ? raceData.find((r) => r.name === "Other")?.id : 0);

  // Validate the form, and if all is well, update the shareholders
  const submitHandler = () => {
    if (!formRef.current?.isValid()) {
      formRef.current?.showValidation();
      return;
    }

    // Added this because if a control is set to disabled, it's values are not sent during a form submit.
    if (modalValues.shareholderType === 1) {
      modalValues.genderId = getDefaultGender();
      modalValues.raceId = getDefaultRace();
    }

    // use separate value for percentage
    // work around to handle decimal point
    modalValues.sharePercentage = Number(shareholderPercentage.replace(/[^0-9a-z.]/gi, ""));

    onUpdate(modalValues);
  };

  // Update the modalValues document message object based on what has been loaded
  // by the DocumentUploader.
  const documentChangeHandler = (docMessage: Array<Document>) => {
    setModalValues({
      ...modalValues,
      shareholderIdentityDocumentMessage: docMessage[0] ?? [],
    });
  };

  // Get the required DocumentType from static data based on the ShareholderIdentityDocument classification.
  const documentType = () => (isStaticDataReady ? documentTypes.find((d) => d.documentClassification === DocumentClassification.ShareholderIdentityDocument)?.id : "");

  // Load up either the document present on the EditShareholderMessage object, or
  // load up a new document with the specific document type.
  const setupDocuments = (shareholderMsg: EditShareholderMessage) => (
    [shareholderMsg.shareholderIdentityDocumentMessage ?? { documentTypeId: documentType() }]);

  return (
    <>
      <ModalPopup
        key="shareholderModal"
        size="large"
        onClose={onClose}
        buttons={
          readOnly
            ? []
            : ([
              {
                key: "submitButton",
                label: "Submit",
                onClick: () => {
                  submitHandler();
                },
              },
            ] as Array<ButtonInfo>)
        }
        hasCancel
        cancelButtonText="Close"
        heading="Shareholder Details"
        subheading="This information is required for the purposes of onboarding to this client, and will be visible only to this client, and its internal users deemed by this client to be necessary."
      >
        <>
          <Form ref={formRef} className="row px-2" key="shareholderModalForm">
            <div className="row px-3 py-3">
              <div className="col-12 col-sm-8 col-md-9 col-lg-12 col-xl-12">
                <DropDownInput
                  key="shareholderType"
                  disabled={readOnly}
                  label="Shareholder Type"
                  value={modalValues.shareholderType ?? -1}
                  options={[
                    { value: 1, display: "Legal Entity" },
                    { value: 2, display: "Individual" },
                  ]}
                  onChange={(selectedType) => {
                    setModalValues({
                      ...modalValues,
                      shareholderType: selectedType,
                    });
                  }}
                  required
                  validationMessage="A shareholder type is required"
                />
              </div>

              <div className="col-12 col-sm-8 col-md-9 col-lg-12 col-xl-12">
                <TextInput
                  key="shareholderName"
                  disabled={readOnly}
                  label="Shareholder Name"
                  type="text"
                  value={modalValues.fullName ?? ""}
                  placeholder="Enter a shareholder name"
                  required
                  validationMessage="A shareholder name is required"
                  formatValue={(nameValue) => (nameValue.trim() ? nameValue : "")}
                  onChange={(nameValue) => {
                    setModalValues({ ...modalValues, fullName: nameValue });
                  }}
                />
              </div>

              <div className="col-12 col-sm-8 col-md-9 col-lg-12 col-xl-12">
                <TextInput
                  key="shareholderIdRegNumber"
                  disabled={readOnly}
                  label={
                    modalValues?.shareholderType === 1
                      ? "Company Registration Number"
                      : "ID Number"
                  }
                  type="text"
                  value={modalValues.idNumber ?? ""}
                  placeholder={
                    modalValues?.shareholderType === 1
                      ? "Enter a company registration number"
                      : "Enter a valid ID number"
                  }
                  required
                  minLength={5}
                  maxLength={20}
                  formatValue={(nameValue) => (nameValue.trim() ? nameValue : "")}
                  validationMessage="A valid entry is required here"
                  onChange={(idValue) => {
                    setModalValues({ ...modalValues, idNumber: idValue });
                  }}
                />
              </div>

              <div className="col-12 col-sm-8 col-md-9 col-lg-12 col-xl-12">
                <DropDownInput
                  key="shareholderRace"
                  disabled={modalValues.shareholderType === 1 || readOnly}
                  label="Race (For South African B-BBEE statistics)"
                  value={
                    modalValues.shareholderType === 1
                      ? getDefaultRace()
                      : modalValues.raceId ?? -1
                  }
                  options={raceData ?? []}
                  required={modalValues.shareholderType !== 1}
                  validationMessage="Select a race"
                  onChange={(selectedRace) => {
                    setModalValues({
                      ...modalValues,
                      raceId: !selectedRace ? -1 : selectedRace,
                    });
                  }}
                />
              </div>

              <div className="col-12 col-sm-8 col-md-9 col-lg-12 col-xl-12 pb-3 pt-2">
                <CheckboxInput
                  key="shareholderDisability"
                  disabled={modalValues.shareholderType === 1 || readOnly}
                  label="Disability?"
                  value={modalValues.disability ?? false}
                  onChange={(disabilityToggle) => {
                    setModalValues({
                      ...modalValues,
                      disability: disabilityToggle,
                    });
                  }}
                />
              </div>

              <div className="col-12 col-sm-8 col-md-9 col-lg-12 col-xl-12">
                <DropDownInput
                  key="shareholderGender"
                  disabled={modalValues.shareholderType === 1 || readOnly}
                  label="Gender"
                  value={
                    modalValues.shareholderType === 1
                      ? getDefaultGender()
                      : modalValues.genderId ?? -1
                  }
                  options={genderData ?? []}
                  required={modalValues.shareholderType !== 1}
                  validationMessage="Select a gender"
                  onChange={(selectedGender) => {
                    setModalValues({
                      ...modalValues,
                      genderId:
                        !selectedGender ? -1 : selectedGender,
                    });
                  }}
                />
              </div>

              <div className="col-12 col-sm-8 col-md-9 col-lg-12 col-xl-12">
                <TextInput
                  key="shareholderPercentage"
                  disabled={readOnly}
                  label="Share %"
                  value={shareholderPercentage ?? ""}
                  placeholder="Enter an ownership percentage"
                  required
                  validationMessage="Please enter a shareholder percentage between 0x x` and 100"
                  onChange={(sharePercentageValue) => {
                    setShareholderPercentage(sharePercentageValue);
                  }}
                />
              </div>

              <div className="col-12 col-sm-8 col-md-9 col-lg-12 col-xl-12">
                <TextInput
                  key="shareholderContactNumber"
                  disabled={readOnly}
                  label="Contact Number"
                  type="tel"
                  value={modalValues.contactNumber ?? ""}
                  maxLength={20}
                  placeholder="Enter a contact number"
                  required
                  formatValue={formatTelephoneNumber}
                  validationMessage="Please enter a valid contact number"
                  onChange={(contactNumberValue) => {
                    setModalValues({
                      ...modalValues,
                      contactNumber: contactNumberValue,
                    });
                  }}
                />
              </div>

              <div className="col-12 col-sm-8 col-md-9 col-lg-12 col-xl-12">
                <TextInput
                  key="shareholderTitlePosition"
                  disabled={readOnly}
                  label="Title/Position"
                  type="text"
                  value={modalValues.title ?? ""}
                  placeholder="Enter the shareholder title/position"
                  required
                  validationMessage="Please enter a shareholder title/position"
                  formatValue={(nameValue) => (nameValue.trim() ? nameValue : "")}
                  onChange={(titleValue) => {
                    setModalValues({ ...modalValues, title: titleValue });
                  }}
                />
              </div>
              <FormGroup>
                <div className="col-12 col-sm-8 col-md-9 col-lg-12 col-xl-12">
                  <DocumentUploader
                    key="shareholderModalFileOptions"
                    header="Upload shareholder identity/registration document"
                    uploadUrl="api/documents/"
                    downloadUrl="api/documents/"
                    documentTypes={documentTypes ?? []}
                    readOnly={readOnly}
                    documents={setupDocuments(modalValues)}
                    onDocumentsChanges={documentChangeHandler}
                  />
                </div>
                {/* quick solution for adding validation */}
                <Input required hidden type="number" value={modalValues.shareholderIdentityDocumentMessage?.filename?.length ?? 0} min={1} onChange={() => { }} />
                <div className="col-12 col-sm-8 col-md-9 col-lg-12 col-xl-12 invalid-feedback">
                  Please upload a shareholder identity/registration document.
                </div>
              </FormGroup>
            </div>
          </Form>
        </>
      </ModalPopup>
    </>
  );
};

export default ShareholderDetailsModal;
