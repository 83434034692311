const onboardingFilterOptions = {
  Active: 0,
  Declined: 1,
  All: 2,
  Completed: 3,
  Suspended: 4,
  New: 5,
  AwaitingSupplierInvitationAcceptance: 6,
  AwaitingSupplierRegistration: 7,
  ProfileIncomplete: 8,
  AwaitingComplianceChecks: 9,
  ComplianceFailure: 10,
  AwaitingContractApproval: 11,
  PendingApproval1: 12,
  PendingApproval2: 13,
  PendingDelegatedReview: 14,
  DelegatedApproved: 15,
  DelegatedDeclined: 16,
  AwaitingVendorNumber: 17,

  toString: (value) => {
    switch (value) {
      case null:
        return "";
      case onboardingFilterOptions.Active:
        return "Active";
      case onboardingFilterOptions.Declined:
        return "Declined";
      case onboardingFilterOptions.Suspended:
        return "Suspended";
      case onboardingFilterOptions.All:
        return "All";
      case onboardingFilterOptions.Completed:
        return "Completed";
      case onboardingFilterOptions.New:
        return "New";
      case onboardingFilterOptions.AwaitingSupplierInvitationAcceptance:
        return "Awaiting Supplier Invitation Acceptance";
      case onboardingFilterOptions.AwaitingSupplierRegistration:
        return "Awaiting Supplier Registration";
      case onboardingFilterOptions.ProfileIncomplete:
        return "Profile Incomplete";
      case onboardingFilterOptions.AwaitingComplianceChecks:
        return "Awaiting Compliance Checks";
      case onboardingFilterOptions.ComplianceFailure:
        return "Compliance Failure";
      case onboardingFilterOptions.AwaitingContractApproval:
        return "Awaiting Contract Approval";
      case onboardingFilterOptions.PendingApproval1:
        return "Pending Approval 1";
      case onboardingFilterOptions.PendingApproval2:
        return "Pending Approval 2";
      case onboardingFilterOptions.PendingDelegatedReview:
        return "Pending Delegation Review";
      case onboardingFilterOptions.DelegatedApproved:
        return "Delegated Approved";
      case onboardingFilterOptions.DelegatedDeclined:
        return "Delegated Declined";
      case onboardingFilterOptions.AwaitingVendorNumber:
        return "Awaiting Vendor Number";
      default:
        return `${value}`;
    }
  },
};
export default onboardingFilterOptions;
