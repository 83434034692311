import React from "react";

import DropdownInput from "../../../Forms/DropDownInput/DropDownInput";
import ActionsDropdown from "../../../ActionsDropDown/ActionsDropdown";
import CheckboxInput from "../../../CheckboxInput/CheckboxInput";
import TablePageSelector from "../TablePageSelector/TablePageSelector";

import Styles from "./TableFooter.module.scss";
// eslint-disable-next-line no-unused-vars
import { SystemFeatures } from "../../../..";

function TableFooter({
  totalItems,
  firstItem,
  lastItem,
  currentPageIndex,
  totalPages,
  onPageChange,
  actionOptions,
  isAllSelected,
  onSelectAll,
  pageSizes,
  selectedPageSize,
  onPageSizeChange,
  compact,
  disableCheckBox = false,
  requiredCheckBoxFeature,
}:
{
  totalItems : number,
  firstItem: number,
  lastItem: number,
  currentPageIndex: number,
  totalPages: number,
  onPageChange: (page: number) => void,
  actionOptions?: Array<React.ReactNode>,
  isAllSelected: boolean,
  onSelectAll: (value: boolean) => void,
  pageSizes: Array<number>,
  selectedPageSize: number,
  onPageSizeChange: (value: number) => void,
  compact: boolean,
  disableCheckBox ?: boolean,
  requiredCheckBoxFeature ?: SystemFeatures,
}) {
  const hasActionsDropDown = actionOptions && actionOptions.length > 0;
  return (
    <div className={Styles.SearchTableBottom}>
      {!compact && hasActionsDropDown && (
        <div className={`${Styles.RowActions} align-items-center mt-2`}>
          <CheckboxInput
            disabled={disableCheckBox}
            value={isAllSelected}
            label="Select All On Page"
            onChange={(newValue) => onSelectAll(newValue)}
            feature={requiredCheckBoxFeature}
          />
          <div className="pr-4" />
          <ActionsDropdown label="Available actions">
            {actionOptions}
          </ActionsDropdown>
        </div>
      )}

      <div className={`${Styles.Paging} ${hasActionsDropDown ? "mt-4" : ""}`}>
        <label className="mb-1" htmlFor="nothing">
          {totalItems != null
            ? `${totalItems} records found`
            : ""}
        </label>
        <TablePageSelector
          onPageChange={onPageChange}
          currentPageIndex={currentPageIndex}
          totalPages={totalPages}
        />
      </div>

      <div className={`${Styles.PageSize} align-items-end justify-content-end desktop-version`}>
        <DropdownInput
          className="m-0"
          label={totalItems != null && totalItems !== 0
            ? `Showing ${firstItem}-${lastItem} of ${totalItems} results`
            : ""}
          options={pageSizes.map((p) => ({ value: p, display: `${p}` }))}
          onChange={(value) => onPageSizeChange(value ?? pageSizes[0])}
          value={selectedPageSize}
          inline
          noPrompt
        />
      </div>
    </div>
  );
}

export default TableFooter;
