import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { ConfirmationModal } from "isuppli-react-components";
import {
  showErrorDialog,
  showLoadingScreen,
  hideLoadingScreen,
  setDataFeatures,
} from "../Store/actions";

import {
  getOnboardingInvitation,
  linkOnboardingRequestToUserCompany,
  rejectOnboardingRequest,
  getCompanyDataFeatures,
} from "../http/index";

import OnboardingInvitationDetails from "../Sections/Registration/OnboardInvitationDetails";
import { checkHttpStatus } from "../http/httpHelpers";

const mapDispatchToProps = (dispatch) => ({
  showErrorDialog: () => {
    dispatch(showErrorDialog(true));
  },
  showLoadingScreen: (msg) => {
    dispatch(showLoadingScreen(msg));
  },
  hideLoadingScreen: () => {
    dispatch(hideLoadingScreen());
  },
  setDataFeatures: (dataFeatures) => {
    dispatch(setDataFeatures(dataFeatures));
  },
});

class OnboardingInvitationCheck extends Component {
  constructor() {
    super();
    this.state = {
      invitation: {},
      showOnboardingInvitationModal: false,
      showOboardingIvitationFailure: false,
    };
  }

  async componentDidMount() {
    const invitation = await getOnboardingInvitation();
    if (invitation.length === 0) {
      return;
    }

    this.setState({
      invitation,
      showOnboardingInvitationModal: true,
    });
  }

  async onRejectOnboardingInvitation() {
    this.props.showLoadingScreen();
    try {
      await rejectOnboardingRequest(this.state.invitation.invitationId);
      const dataFeatures = await getCompanyDataFeatures();
      this.props.setDataFeatures(dataFeatures.dataFeatures);
    } catch (error) {
      this.props.hideLoadingScreen();
      throw error;
    }
    this.props.hideLoadingScreen();
  }

  async onAcceptOnboardingInvitation(invitationId) {
    try {
      await linkOnboardingRequestToUserCompany(invitationId);
    } catch (error) {
      if (checkHttpStatus(error, 403)) {
        // The invitation did not match the company details
        this.setState({
          showOboardingIvitationFailure: true,
        });
        return;
      }

      this.props.showErrorDialog();
    }
  }

  hideError() {
    this.setState({
      showOboardingIvitationFailure: false,
    });
  }

  onCloseHandler() {
    this.setState({
      showOnboardingInvitationModal: false,
    });
  }

  render() {
    return (
      <Fragment>
        {this.state.showOnboardingInvitationModal && (
          <OnboardingInvitationDetails
            invitation={this.state.invitation}
            onAccept={() => this.onAcceptOnboardingInvitation(
              this.state.invitation.invitationId
            )}
            onReject={() => this.onRejectOnboardingInvitation(this.state.invitation.invitationId)}
            onClose={() => this.onCloseHandler()}
          />
        )}
        {this.state.showOboardingIvitationFailure && (
          <ConfirmationModal
            hasCancel={false}
            image="error"
            heading="Invitation cannot be accepted"
            description="The invitation could not be accepted because your company's registration and VAT numbers don't match the invitation. Please create a new account to accept the invitation."
            proceedButtonText="OK"
            onToggleModal={() => this.hideError()}
            onProceedClick={() => {}}
          />
        )}
      </Fragment>
    );
  }
}

export default connect(null, mapDispatchToProps)(OnboardingInvitationCheck);
