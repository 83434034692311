import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  // eslint-disable-next-line no-unused-vars
  TableConfig,
  // eslint-disable-next-line no-unused-vars
  TableData,
  PageHeading,
  useRouteState,
  UtilButton,
  SearchInput,
} from "isuppli-react-components";

import { useHistory } from "react-router";

import navMenuItems from "../../../Util/menuItems";
import ComplianceStatus, {
  complianceStatusToString,
  getComplianceStatusTextClass,
} from "../../../Util/Enumerators/ComplianceStatus";

import { StaticCompanyDataTypes } from "../../../Util/Enumerators/staticCompanyDataTypes";

import { sendComplianceCheckSearch } from "../../../http/index";

import { formatDate } from "../../../Util/valueFormatter";
import useStaticCompanyData from "../../../Hooks/useStaticCompanyData";
import SupplierCheckModal from "../../../Containers/Marketplace/SupplierChecksModal/SupplierCheckModal";

interface SupplierCheckTableItem {
  id: number;
  dateModified: string;
  registeredName: string;
  supplierCategories: string;
  checkStatus: JSX.Element;
  vendorNumber: string;
}

interface SupplierCheckResultItem {
  companyId: number;
  dateModified: Date;
  registeredName: string;
  supplierCategories: number[];
  complianceStatus: ComplianceStatus;
  vendorNumber: string,
}

interface SearchResultMessage {
  totalItems: number;
  items: SupplierCheckResultItem[];
}

interface SupplierCheckSearchCriteria {
  limit: number;
  offset: number;
  registeredName?: string;
  sortingDesc: boolean;
  statusFilter: ComplianceStatus;
}

const SupplierChecks = () => {
  const [
    [supplierCategories],
    staticDataReady,
  ] = useStaticCompanyData([StaticCompanyDataTypes.supplierCategory]);

  const [searchTerm, setSearchTerm] = useRouteState("q", "");
  const [currentSearchTerm, setCurrentSearchTerm] = useState(searchTerm);
  const [pageInfo, setPageInfo] = useRouteState("p", { l: 20, o: 0 });
  const [sortOption, setSortOption] = useRouteState("s", 0);
  const [filterOption, setFilterOption] = useRouteState(
    "f",
    ComplianceStatus.None
  );

  const [searchResults, setSearchResults] = useState<{
    totalItems: number,
    items: SupplierCheckTableItem[]
  } | null>(null);
  const [loading, setLoading] = useState(true);
  const [showCheckDetailsModal, setShowCheckDetailsModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<SupplierCheckTableItem>();
  const history = useHistory();

  useEffect(() => {
    setCurrentSearchTerm(searchTerm);
  }, [searchTerm]);

  const loadData = useCallback(async (searchInfo: {
    sortOption: number;
    filterOption: ComplianceStatus;
    searchTerm: string;
    pageInfo: { l: number; o: number };
  }) => {
    setLoading(true);
    const searchCriteria: SupplierCheckSearchCriteria = {
      sortingDesc: searchInfo.sortOption === 0,
      statusFilter: searchInfo.filterOption,
      registeredName: searchInfo.searchTerm,
      limit: searchInfo.pageInfo.l,
      offset: searchInfo.pageInfo.o,
    };
    const results: SearchResultMessage = await sendComplianceCheckSearch(searchCriteria);

    const getClassFromStatus = (status: ComplianceStatus) => {
      const classes = ["font-weight-bold"];
      classes.push(getComplianceStatusTextClass(status));
      return classes.join(" ");
    };

    // format results
    const formattedItems = results.items.map((c) => ({
      id: c.companyId,
      checkStatus: (
        <span className={getClassFromStatus(c.complianceStatus)}>
          {complianceStatusToString(c.complianceStatus)}
        </span>),
      dateModified: formatDate(c.dateModified),
      supplierCategories: c.supplierCategories
        .map((categoryId) => supplierCategories
          .find((category) => category.value === categoryId)?.display)
        .join(", "),
      registeredName: c.registeredName,
      vendorNumber: c.vendorNumber,
    } as SupplierCheckTableItem));

    setSearchResults({
      totalItems: results.totalItems,
      items: formattedItems,
    });

    setLoading(false);
  }, [setLoading, supplierCategories]);

  useEffect(() => {
    if (!staticDataReady) {
      return;
    }

    loadData({
      sortOption,
      filterOption,
      searchTerm,
      pageInfo,
    });
  },
  [searchTerm, sortOption, filterOption, pageInfo, staticDataReady, loadData, history.location]);

  const onSearchHandler = () => {
    setPageInfo({ l: pageInfo.l, o: 0 });
    setSearchTerm(currentSearchTerm);
  };

  const tableConfig: TableConfig<number, SupplierCheckTableItem> = {
    columns: [
      {
        key: "dateModified",
        heading: "Date Checked",
        propName: "dateModified",
        size: "auto",
        align: "left",
      },
      {
        key: "supName",
        heading: "Supplier Name",
        size: "auto",
        fullSize: true,
        align: "left",
        valueTemplate: (row) => (
          <button
            className="btn btn-link w-100 text-left text-capitalize"
            type="button"
            onClick={() => {
              setSelectedCompany(row);
              setShowCheckDetailsModal(true);
            }}
          >
            {row.registeredName}
          </button>
        ),
      },
      {
        key: "checkStatus",
        heading: "Check Status",
        propName: "checkStatus",
        size: "auto",
        align: "left",
      },
    ],
    moreButtonText: "View Supplier Checks",
    sortOptions: {
      selectedSortOption: sortOption,
      options: [
        { value: 0, display: "Newest first" },
        { value: 1, display: "Oldest first" },
      ],
    },
    statusFilterOptions: {
      selectedStatusFilterOption: filterOption,
      options: [
        {
          value: ComplianceStatus.None,
          display: "All",
        },
        {
          value: ComplianceStatus.Complete,
          display: complianceStatusToString(ComplianceStatus.Complete),
        },
        {
          value: ComplianceStatus.AwaitingResults,
          display: complianceStatusToString(ComplianceStatus.AwaitingResults),
        },
        {
          value: ComplianceStatus.Error,
          display: complianceStatusToString(ComplianceStatus.Error),
        },
      ],
    },
  };

  const tableData: TableData<SupplierCheckTableItem> = {
    limit: pageInfo.l,
    offset: pageInfo.o,
    totalItems: searchResults?.totalItems ?? 0,
    items: searchResults?.items ?? [],
  };

  return (
    <div className="container-fluid">
      {showCheckDetailsModal
        && (
          <SupplierCheckModal
            onClose={() => setShowCheckDetailsModal(false)}
            company={{
              id: selectedCompany?.id ?? 0,
              name: selectedCompany?.registeredName ?? "",
              vendorNumber: selectedCompany?.vendorNumber ?? "",
            }}
          />
        )}

      <div className="row">
        <div className="col-10 offset-1">
          <PageHeading
            heading="Compliance Management"
            alertText="Review company regulatory compliance."
            menuItems={navMenuItems}
          />
          <SearchInput
            searchTerm={currentSearchTerm}
            onSearch={onSearchHandler}
            onChange={setCurrentSearchTerm}
            searchPlaceholder="Search by company name"
          />
          <div className="justify-end mb-4">
            <UtilButton
              color="primary"
              location="bottom"
              onClick={onSearchHandler}
              size="small"
            >
              Search
            </UtilButton>

          </div>
          <Table
            loading={loading}
            tableConfig={tableConfig}
            data={tableData}
            onPageChange={(l: number, o: number) => setPageInfo({ l, o })}
            tableStyle="basic"
            onSortOptionChange={(newValue: number) => {
              setSortOption(newValue);
              setSearchTerm(currentSearchTerm);
            }}
            onStatusFilterChange={(newValue: ComplianceStatus) => {
              setPageInfo({ l: pageInfo.l, o: 0 });
              setFilterOption(newValue);
              setSearchTerm(currentSearchTerm);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SupplierChecks;
