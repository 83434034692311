/* eslint-disable no-undef */
const userMarketingInfoSubscriptionEnum = {
  /// <summary>
  /// Indicated that the user has not yet set their information
  /// </summary>
  /// <summary>
  /// Indicated that the user has not yet set their information
  /// </summary>
  None: 0,
  /// <summary>
  /// Indicates that the users has subscribed to marketing emails
  /// </summary>
  Subscribed: 1,
  /// <summary>
  /// Indicates that the users has not subscribed to marketing emails
  /// </summary>
  Unsubscribed: 2,
};

export default userMarketingInfoSubscriptionEnum;
