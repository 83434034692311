import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { BasicTable, ConfirmationModal, ModalPopup } from "isuppli-react-components";

import DropdownInput from "../../Controls/DropdownInput";
import { sendCompanyDuplicateEmail } from "../../http/posts";
import duplicateCompanyEmailType from "../../Util/Enumerators/duplicateCompanyEmailType";
import {
  showErrorDialog,
} from "../../Store/actions";

const mapDispatchToProps = (dispatch) => ({
  showErrorDialog: () => {
    dispatch(showErrorDialog(true));
  },
});

class DuplicateRegistrationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
      showSelectionTable: true,
      showMyCompanyTable: false,
      showRequestContactTable: false,
      showSuccessModal: false,
      contactInfo: {
        companyIds: [],
        name: "",
        surname: "",
        email: "",
        phonenumber: "",
        emailType: duplicateCompanyEmailType.None,
      },
    };
  }

  getNoRelationshipRow() {
    return [
      [{
        key: "name_row",
        fullSize: true,
        valueTemplate: () => (
          <input
            name="name"
            placeholder="First Name"
            onChange={(ev) => this.onNameChangeHandler(ev.target.value)}
          />
        ),
      },
      {
        key: "surname_row",
        fullSize: true,
        valueTemplate: () => (
          <input
            name="surname"
            placeholder="Last Name"
            onChange={(ev) => this.onSurnameChangeHandler(ev.target.value)}
          />
        ),
      },
      {
        key: "email_row",
        fullSize: true,
        valueTemplate: () => (
          <input
            name="email"
            placeholder="Email Address"
            onChange={(ev) => this.onEmailChangeHandler(ev.target.value)}
          />
        ),
      },
      {
        key: "phone_row",
        fullSize: true,
        valueTemplate: () => (
          <input
            name="phonenumber"
            placeholder="Phone Number"
            onChange={(ev) => this.onPhoneChangeHandler(ev.target.value)}
          />
        ),
      }],
    ];
  }

  proceedHandler() {
    // Check dropdown values

    // Contact duplicate company administrator
    const contactDuplicateCompanyAdminSelected = (this.state.values).includes(1);
    if (contactDuplicateCompanyAdminSelected) {
      // get company ids that have "contact b1sa support action" option selected
      const matches = this.props.duplicates.filter((_, index) => (this.state.values[index] === 1));
      const companyIds = matches.map((duplicate) => duplicate.companyId);
      const emailType = duplicateCompanyEmailType.CompanyAdmistrator;

      // update company ids in state
      this.setState((prevState) => {
        const contactInfo = prevState.contactInfo;
        const newContactInfo = { ...contactInfo, companyIds, emailType };
        return {
          contactInfo: newContactInfo,
          showSelectionTable: false,
          showMyCompanyTable: true,
          showRequestContactTable: false,
        };
      });
      return;
    }

    // Contact B1SA Support
    const contactB1SASupport = (this.state.values).includes(2);
    if (contactB1SASupport) {
      // get company ids that have "contact b1sa support action" option selected
      const matches = this.props.duplicates.filter((_, index) => (this.state.values[index] === 2));
      const companyIds = matches.map((duplicate) => duplicate.companyId);
      const emailType = duplicateCompanyEmailType.B1SA_Support;

      // update company ids in state
      this.setState((prevState) => {
        const contactInfo = prevState.contactInfo;
        const newContactInfo = { ...contactInfo, companyIds, emailType };
        return {
          contactInfo: newContactInfo,
          showSelectionTable: false,
          showMyCompanyTable: false,
          showRequestContactTable: true,
        };
      });
    }
  }

  onChangeHandler(value, index) {
    this.setState((previousState) => {
      const newValues = [...previousState.values];
      newValues[index] = value;
      return {
        values: newValues,
      };
    });
  }

  onNameChangeHandler(value) {
    // Update value in state
    this.setState((prevState) => {
      const contactInfo = prevState.contactInfo;
      const newContactInfo = { ...contactInfo, name: value };
      return {
        contactInfo: newContactInfo,
      };
    });
  }

  onSurnameChangeHandler(value) {
    // Update value in state
    this.setState((prevState) => {
      const contactInfo = prevState.contactInfo;
      const newContactInfo = { ...contactInfo, surname: value };
      return {
        contactInfo: newContactInfo,
      };
    });
  }

  onEmailChangeHandler(value) {
    // Update value in state
    this.setState((prevState) => {
      const contactInfo = prevState.contactInfo;
      const newContactInfo = { ...contactInfo, email: value };
      return {
        contactInfo: newContactInfo,
      };
    });
  }

  onPhoneChangeHandler(value) {
    // Update value in state
    this.setState((prevState) => {
      const contactInfo = prevState.contactInfo;
      const newContactInfo = { ...contactInfo, phonenumber: value };
      return {
        contactInfo: newContactInfo,
      };
    });
  }

    validateContactInfo = (contactInfo) => {
      if (
        contactInfo.name.length > 0
            && contactInfo.surname.length > 0
            && contactInfo.email.length > 0
            && contactInfo.phonenumber.length > 0
      ) {
        return true;
      }
      return false;
    };

    submitContactInfo = async () => {
      try {
        const contactInfo = this.state.contactInfo;
        if (this.validateContactInfo(contactInfo)) {
          await sendCompanyDuplicateEmail(contactInfo);
        }

        this.setState((prevState) => ({
          contactInfo: prevState.contactInfo,
          showSelectionTable: false,
          showMyCompanyTable: false,
          showRequestContactTable: false,
          showSuccessModal: true,
        }));
      } catch (error) {
        this.props.showErrorDialog();
      }
    };

    render() {
      const selectionRows = this.props.duplicates.map((duplicate, index) => [
        {
          key: `${duplicate.companyId}_name`,
          value: duplicate.registeredName,
        },
        {
          key: `${duplicate.companyId}_type`,
          value: (this.props.companyTypes != null)
            ? this.props.companyTypes.find(
              (companyType) => companyType.id === duplicate.companyType.id
            )?.name
            : null,
        },
        {
          key: `${duplicate.companyId}_number`,
          value: duplicate.registrationNumber,
        },
        {
          key: `${duplicate.companyId}_option`,
          valueTemplate: () => (
            <DropdownInput
              name="option"
              options={[
                { value: 1, display: "Contact duplicate company administrator" },
                { value: 2, display: "Contact B1SA support desk" },
              ]}
              value={this.state.values[index]}
              onChangeCallback={(ev) => {
                this.onChangeHandler(ev.target.value, index);
              }}
            />
          ),
        },
      ]);

      let modalHeading;
      let modalSubheading;
      let modalButtons;

      if (this.state.showSelectionTable) {
        modalHeading = "Supplier(s) found in the directory";
        modalSubheading = "The following company(s) with the same registration type and registration number is/are already registered on the Network.";
        modalButtons = [
          {
            key: "Proceed",
            label: "Proceed",
            color: "primary",
            onClick: () => this.proceedHandler(),
          },
        ];
      } else if (this.state.showMyCompanyTable) {
        modalHeading = "CONTACT COMPANY ADMINISTRATION";
        modalSubheading = "Please provide your contact information in the following fields and submit. A request will be sent to the admistrator of duplicate company(s) to contact you.";
        modalButtons = [
          {
            key: "submit",
            label: "Submit",
            color: "primary",
            onClick: () => this.submitContactInfo(), // Send Email to company
          },
        ];
      } else if (this.state.showRequestContactTable) {
        modalHeading = "CONTACT B1SA SUPPORT";
        modalSubheading = "Please provide your contact information in the following fields and submit. B1SA will contact you for further information.";
        modalButtons = [
          {
            key: "submit",
            label: "Submit",
            color: "primary",
            onClick: () => this.submitContactInfo(), // Send Email to B1SA support
          },
        ];
      }

      return (
        <>
          {
            (this.state.showMyCompanyTable
              || this.state.showSelectionTable
              || this.state.showRequestContactTable)
            && (
              <ModalPopup
                onClose={this.props.toggleModalCallback}
                heading={modalHeading}
                subheading={modalSubheading}
                hasCancel
                buttons={modalButtons}
              >
                <Fragment>
                  {
                    this.state.showSelectionTable
                  && (
                    <BasicTable
                      columns={[
                        "Registered Name",
                        "Company Type",
                        "Registration Number",
                        "Actions",
                      ]}
                      rows={selectionRows}
                    />
                  )
                  }

                  {
                    this.state.showMyCompanyTable
                  && (
                    <BasicTable
                      columns={[
                        "First Name",
                        "Last Name",
                        "Email Address",
                        "Phone Number",
                      ]}
                      rows={this.getNoRelationshipRow()}
                    />
                  )
                  }

                  {
                    this.state.showRequestContactTable
                  && (
                    <BasicTable
                      columns={[
                        "First Name",
                        "Last Name",
                        "Email Address",
                        "Phone Number",
                      ]}
                      rows={this.getNoRelationshipRow()}
                    />
                  )
                  }
                </Fragment>
              </ModalPopup>
            )
          }

          {
            this.state.showSuccessModal && (
              <ConfirmationModal
                heading="Request Submitted"
                hasCancel={false}
                description="Your request has submitted successfully and you will be contacted as soon as possible."
                proceedButtonText="continue"
                onProceedClick={() => this.props.submissionSuccessCallback()}
                onToggleModal={() => this.props.toggleModalCallback()}
              />
            )
          }
        </>
      );
    }
}

export default withRouter(
  connect(mapDispatchToProps)(DuplicateRegistrationModal)
);
