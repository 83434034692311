import axios from "axios";
import { makeRequestAsync } from "./util";

export async function acceptOnboardingRequest(onboardingId, reason) {
  let queryParameter = "";
  if (reason != null) {
    queryParameter = `?reason=${reason}`;
  }
  return makeRequestAsync(() => axios.put(`/api/motivation/acceptboarding/${onboardingId}${queryParameter}`));
}

export async function approveOnboardingRequest(onboardingId, reason) {
  return makeRequestAsync(() => axios.put(`/api/motivation/approveonboarding/${onboardingId}?reason=${reason}`));
}

export async function declineOnboardingRequest(onboardingId, reason) {
  let queryParameter = "";
  if (reason != null) {
    queryParameter = `?reason=${reason}`;
  }
  return makeRequestAsync(() => axios.put(`/api/motivation/declineonboarding/${onboardingId}${queryParameter}`));
}

export async function sendOnboardingRequestEmail(onboardingId) {
  return makeRequestAsync(() => axios.put(`/api/motivation/sendonboardingemail/${onboardingId}`));
}

export async function linkOnboardingRequestToUserCompany(invitationId) {
  return makeRequestAsync(() => axios.put(`/api/motivation/invitation/${invitationId}`));
}

export async function rejectOnboardingRequest(invitationId) {
  return makeRequestAsync(() => axios.put(`/api/motivation/invitation/${invitationId}/reject`));
}

export async function retractOnboardingRequest(onboardingId) {
  return makeRequestAsync(() => axios.put(`/api/motivation/retractonboarding/${onboardingId}`));
}

export async function updateOnboardingRequestDelegationStatus(onboardingId, delegationStatus, reason) {
  const queryParameters = `?delegationStatus=${delegationStatus}&reason=${reason}`;
  return makeRequestAsync(() => axios.put(`/api/motivation/delegation/${onboardingId}${queryParameters}`));
}

export async function suspendOnboardingRequest(onboardingId, reason) {
  let queryParameter = "";
  if (reason !== null) {
    queryParameter = `?reason=${reason}`;
  }
  return makeRequestAsync(() => axios.put(`/api/motivation/suspendonboarding/${onboardingId}${queryParameter}`));
}
