import React, { useState, useCallback, useEffect } from "react";
import { ModalPopup, Spinner } from "isuppli-react-components";
import StarRatingInput from "../../Controls/StarRatingInput/StarRatingInput";
import { getCompanyRating, saveCompanyRating } from "../../http/MyCompany/myCompanyAPI";
import useShowError from "../../Hooks/useShowError";
import { CompanyEditRatingMessage, CompanyTotalRatingMessage } from "../../http/MyCompany/Models/Models"; // eslint-disable-line no-unused-vars
import Styles from "./RateCompanyModal.module.scss";

const RateCompanyModal = (
  {
    onClose,
    onRate,
    companyName,
    companyId,
  }
  :
  {
    onClose: () => void,
    onRate: (newRating: CompanyTotalRatingMessage) => void,
    companyName?: string,
    companyId: number,
  }
) => {
  const [rating, setRating] = useState(0);
  const [loading, setLoading] = useState(false);
  const showError = useShowError();

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getCompanyRating(companyId);
      setRating(result?.rating ?? 0);
    } catch (error) {
      showError();
    }
    setLoading(false);
  }, [companyId, showError]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onSaveHandler = async () => {
    setLoading(true);
    try {
      const message: CompanyEditRatingMessage = {
        companyId,
        newRating: rating,
      };
      const result = await saveCompanyRating(message);
      onRate(result);
      onClose();
    } catch (error) {
      showError();
    }
    setLoading(false);
  };

  const descriptionText = [
    "Very Poor",
    "Poor",
    "Average",
    "Good",
    "Excellent",
  ];

  return (
    <ModalPopup
      heading="RATE COMPANY"
      subheading={companyName ?? ""}
      onClose={onClose}
      size="large"
      buttons={[
        {
          key: "submit",
          label: "Submit Rating",
          color: "primary",
          onClick: onSaveHandler,
        },
      ]}
    >
      <div className={Styles.Rating}>
        <StarRatingInput
          value={rating}
          onChange={setRating}
          size="large"
          showToolTips
        />
        <div className="pt-3">
          <h3>{descriptionText[rating - 1] ?? "No rating"}</h3>
        </div>
      </div>
      {loading && <Spinner />}
    </ModalPopup>
  );
};
export default RateCompanyModal;
