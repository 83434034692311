import React from "react";
import Styles from "./ImageButton.module.scss";

const ImageButton = (
  {
    disabled = false,
    className = "",
    onClick,
    svg,
    alt,
  }
    :
    {
      disabled?: boolean,
      className?: string,
      onClick: () => void,
      svg: SvgrComponent,
      alt?: string,
    }
) => {
  const SvgImage = svg;
  return (
    <button
      type="button"
      disabled={disabled}
      className={`btn ${className} ${Styles.Button}`}
      onClick={onClick}
      aria-label={alt}
    >
      <SvgImage className={Styles.Image} />
    </button>
  );
};

export default ImageButton;
