import React, {
  // eslint-disable-next-line no-unused-vars
  useState, useEffect, useImperativeHandle, ForwardRefRenderFunction, forwardRef, Fragment,
} from "react";
import { isMandatory, MTOMandatoryField, Spinner } from "isuppli-react-components";
import AddressInfo from "../../Supplier/AddressInfo";
import useShowError from "../../../Hooks/useShowError";
import { loadAddressDataAsync } from "../../../Util/dataLoader";
import useStaticData from "../../../Hooks/useStaticData";
import { StaticDataTypes } from "../../../Util/Enumerators/staticDataTypes";
import KeyContactStaff from "../../Supplier/KeyContactStaff";
import { getSupplierKeyContactDetailsAsync } from "../../../http";

import {
  ContactPerson, // eslint-disable-line no-unused-vars
  CompanyAddress, // eslint-disable-line no-unused-vars
} from "../../../http/ManualSupplier/Models/Models";
import {
  CompanyContactsMessage, // eslint-disable-line no-unused-vars
  CompanyAddressesMessage, // eslint-disable-line no-unused-vars
  AddressContactsMessage, // eslint-disable-line no-unused-vars
  AddressMessage, // eslint-disable-line no-unused-vars
} from "../../../http/ManualSupplier/Messages/Messages";
import { saveAddressContactsInfo } from "../../../http/ManualSupplier/manualSupplierApi";
import addressTypes from "../../../Util/Enumerators/addressTypes";
// eslint-disable-next-line no-unused-vars
import { ValidationModel } from "../../../Sections/Marketplace/MTPManualSupplierStep/MTPManualSupplierStep";
import ContactPersonClassificationTypes from "../../../Util/Enumerators/ContactPersonClassificationTypes";
import { requiredFieldsStep2, requiredMandatoryFieldsStep2 } from "../../../Validation/validation";
import validateStep from "../../../Validation/onSubmitValidations";

interface Addresses {
  physicalAddress: CompanyAddress,
  postalAddress: CompanyAddress
}

export interface AddressDetailsModuleRef {
  save: () => Promise<boolean>;
  validateStep: () => ValidationModel;
}

export interface AddressDetailsModuleProps {
  companyId: number,
  containerClass?: string,
  manualSupplier?: boolean,
  readOnly?: boolean,
  mtoMandatoryField?: number,
}

const AddressDetailsModule:
  ForwardRefRenderFunction<AddressDetailsModuleRef, AddressDetailsModuleProps> = (
    {
      companyId = 0, containerClass = "", manualSupplier = true, readOnly = false, mtoMandatoryField = 0,
    }: AddressDetailsModuleProps, ref
  ) => {
    // == states & hooks
    const showError = useShowError();
    const [[provinces, countries, municipalities, contactPersonTypes], staticDataReady] = useStaticData(
      [StaticDataTypes.Province, StaticDataTypes.Country, StaticDataTypes.Municipality, StaticDataTypes.ContactPerson]
    );
    const [addressData, setAddressData] = useState<Addresses>({} as Addresses);
    const [keyContactsDetailData, setKeyContactDetailsData] = useState<ContactPerson[]>([]);
    const [loading, setLoading] = useState(false);

    // == functions
    const onAddressInputChange = (
      category: "physicalAddress" | "postalAddress", ev: any
    ) => {
      setAddressData((prevState) => ({
        ...prevState,
        [category]: {
          ...prevState[category],
          [ev.target.name]: ev.target.value,
          type: category === "physicalAddress" ? addressTypes.Physical : addressTypes.Postal,
        },
      }));
    };

    // when "same postal address as physical address" checkbox is checked
    const onSamePostalAddressAsPhysicalChange = (address: any) => {
      setAddressData((prevState) => ({
        ...prevState,
        postalAddress: {
          ...prevState.postalAddress, ...address,
        },
      }));
    };

    const onContactDetailsInputChange = (
      contactPerson: any
    ) => {
      const type = contactPerson.contactPersonTypeId;
      const indexOfItemToChange = keyContactsDetailData.findIndex(
        (c) => c.contactPersonTypeId === type
      );

      const newContactPersons = [...keyContactsDetailData];
      newContactPersons[indexOfItemToChange] = { ...contactPerson };
      setKeyContactDetailsData(newContactPersons);
    };

    // save changes
    const stepValidation = () => {
      const result: ValidationModel = {
        isStepValid: true,
        msgs: {},
      };

      if (mtoMandatoryField === null) {
        // validate addresses
        const addressValidation = validateStep(addressData, requiredFieldsStep2);
        result.isStepValid = addressValidation.isValid;
        result.msgs = { ...addressValidation.msgs };

        // if country is selected and unselect
        if (addressData.physicalAddress.country <= 0) {
          result.isStepValid = false;
          result.msgs.physicalAddressCountry = "Physical address: country is required";
        }

        if (addressData.postalAddress.country <= 0) {
          result.isStepValid = false;
          result.msgs.postalAddressCountry = "Postal address: country is required";
        }

        // validate provices
        const physicalAddressCountryHasProvinces = provinces.filter(
          (prov) => prov.countryId === addressData.physicalAddress.country
        ).length > 0;

        if (physicalAddressCountryHasProvinces
          && (addressData.physicalAddress.province <= 0
            || addressData.physicalAddress.province === undefined)) {
          result.isStepValid = false;
          result.msgs.physicalAddressProvince = "Physical address: province is required";
        }

        const postalAddressCountryHasProvinces = provinces.filter(
          (prov) => prov.countryId === addressData.postalAddress.country
        ).length > 0;

        if (postalAddressCountryHasProvinces
          && (addressData.postalAddress.province <= 0
            || addressData.postalAddress.province === undefined)) {
          result.isStepValid = false;
          result.msgs.postalAddressProvince = "Postal address: province is required";
        }

        // validate contact persons
        if (manualSupplier === true) {
          const primaryContactPerson = keyContactsDetailData.find(
            (c) => (c.contactPersonClassification) === ContactPersonClassificationTypes.PrimaryContact
          );
          const contactPersonType = contactPersonTypes.find(
            (c) => c.id === primaryContactPerson?.contactPersonTypeId
          );
          // ensure that the required contacts details (primaryContactPerson) are completed
          if ((!(primaryContactPerson?.contactNumber?.trim())
            || !(primaryContactPerson.email?.trim())
            || !(primaryContactPerson.fullName?.trim()))
          ) {
            result.msgs[`contactPersonType${contactPersonType?.value}`] = `${contactPersonType?.display} Person details are required`;
            result.isStepValid = false;
          }
        }
      } else {
        // validate addresses
        const addressValidation = validateStep(
          addressData,
          requiredMandatoryFieldsStep2.filter((x) => isMandatory(mtoMandatoryField, x.field))
        );
        result.isStepValid = addressValidation.isValid;
        result.msgs = { ...addressValidation.msgs };
        // mandatory physical address
        if (isMandatory(mtoMandatoryField, MTOMandatoryField.PhysicalAddress)) {
          // country
          if (addressData.physicalAddress.country <= 0) {
            result.isStepValid = false;
            result.msgs.physicalAddressCountry = "Physical address: country is required";
          }
          // provices
          const physicalAddressCountryHasProvinces = provinces.filter(
            (prov) => prov.countryId === addressData.physicalAddress.country
          ).length > 0;
          if (physicalAddressCountryHasProvinces
            && (addressData.physicalAddress.province <= 0
              || addressData.physicalAddress.province === undefined)) {
            result.isStepValid = false;
            result.msgs.physicalAddressProvince = "Physical address: province is required";
          }
        }
        // mandatory postal address
        if (isMandatory(mtoMandatoryField, MTOMandatoryField.PostalAddress)) {
          if (addressData.postalAddress.country <= 0) {
            result.isStepValid = false;
            result.msgs.postalAddressCountry = "Postal address: country is required";
          }
          const postalAddressCountryHasProvinces = provinces.filter(
            (prov) => prov.countryId === addressData.postalAddress.country
          ).length > 0;

          if (postalAddressCountryHasProvinces
            && (addressData.postalAddress.province <= 0
              || addressData.postalAddress.province === undefined)) {
            result.isStepValid = false;
            result.msgs.postalAddressProvince = "Postal address: province is required";
          }
        }
        // primary contact
        if (isMandatory(mtoMandatoryField, MTOMandatoryField.PrimaryContactPerson)) {
          const primaryContactPerson = keyContactsDetailData.find(
            (c) => (c.contactPersonClassification) === ContactPersonClassificationTypes.PrimaryContact
          );
          const contactPersonType = contactPersonTypes.find(
            (c) => c.id === primaryContactPerson?.contactPersonTypeId
          );

          if ((!(primaryContactPerson?.contactNumber?.trim())
            || !(primaryContactPerson.email?.trim())
            || !(primaryContactPerson.fullName?.trim()))
          ) {
            result.msgs[`contactPersonType${contactPersonType?.value}`] = `${contactPersonType?.display} Person details are required`;
            result.isStepValid = false;
          }
        }
        // all contacts
        if (isMandatory(mtoMandatoryField, MTOMandatoryField.AllContactPersons)) {
          keyContactsDetailData.forEach((contactPerson) => {
            const contactPersonType = contactPersonTypes.find(
              (c) => c.id === contactPerson?.contactPersonTypeId
            );
            if ((!(contactPerson?.contactNumber?.trim())
              || !(contactPerson.email?.trim())
              || !(contactPerson.fullName?.trim()))
            ) {
              result.msgs[`contactPersonType${contactPersonType?.value}`] = `${contactPersonType?.display} Person details are required`;
              result.isStepValid = false;
            }
          });
        }
      }
      return result;
    };
    const onSaveAddressAndContactDetails = async () => {
      const contactPersonsMessage: CompanyContactsMessage = {
        CompanyId: companyId,
        ContactPersons: keyContactsDetailData,
      };

      const physicalAdd: AddressMessage = {
        addressId: addressData.physicalAddress?.addressId ?? 0,
        isPrimary: true,
        line1: addressData.physicalAddress?.streetAddressL1,
        unitNumber: "",
        line2: addressData.physicalAddress?.streetAddressL2,
        city: addressData.physicalAddress?.city,
        countryId: addressData.physicalAddress?.country,
        provinceId: addressData.physicalAddress?.province,
        municipalityId: addressData.physicalAddress?.municipality,
        type: addressData.physicalAddress?.type,
        postalCode: addressData.physicalAddress?.postalCode,
      };

      const postalAdd: AddressMessage = {
        addressId: addressData.postalAddress?.addressId ?? 0,
        isPrimary: true,
        line1: addressData.postalAddress?.streetAddressL1,
        unitNumber: "",
        line2: addressData.postalAddress?.streetAddressL2,
        city: addressData.postalAddress?.city,
        countryId: addressData.postalAddress?.country,
        provinceId: addressData.postalAddress?.province,
        municipalityId: addressData.postalAddress?.municipality,
        type: addressData.postalAddress?.type,
        postalCode: addressData.postalAddress?.postalCode,
      };

      const addressesMessage: CompanyAddressesMessage = {
        CompanyId: companyId,
        Addresses: [physicalAdd, postalAdd],
      };

      const addressContactInfo: AddressContactsMessage = {
        CompanyAddressesMessage: addressesMessage,
        CompanyContactsMessage: contactPersonsMessage,
      };
      try {
        setLoading(true);
        await saveAddressContactsInfo(addressContactInfo);
        setLoading(false);
        return true;
      } catch {
        setLoading(false);
        return false;
      }
    };

    useImperativeHandle(ref, () => ({
      save: onSaveAddressAndContactDetails,
      validateStep: stepValidation,
    }));

    useEffect(() => {
      const loader = async () => {
        try {
          setLoading(true);
          const addressInfo = await loadAddressDataAsync(companyId);
          setAddressData(addressInfo as Addresses);

          const keyContactDetails = await getSupplierKeyContactDetailsAsync(companyId);
          setKeyContactDetailsData(keyContactDetails as ContactPerson[]);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          showError();
        }
      };
      loader();
    }, [companyId, showError, staticDataReady, countries, provinces, municipalities]);

    return (
      <Fragment>
        <div className={containerClass ?? ""}>
          {loading && <Spinner />}
          <AddressInfo
            readOnly={readOnly}
            data={addressData}
            setPostalAddress={onSamePostalAddressAsPhysicalChange}
            provinces={staticDataReady ? provinces : []}
            countries={staticDataReady ? countries : []}
            municipalities={staticDataReady ? municipalities : []}
            onInputChangeCallback={onAddressInputChange}
            setStepValid
            mandatoryPhysicalAddress={isMandatory(mtoMandatoryField, MTOMandatoryField.PhysicalAddress)}
            mandatoryPostalAddress={isMandatory(mtoMandatoryField, MTOMandatoryField.PostalAddress)}
          />
          <div>
            <h2 className="Main">KEY CONTACT DETAILS</h2>
          </div>

          <div className="row">
            <div className="col-12 col-lg-8">
              Please capture the information for the following key contacts within your company.
            </div>
          </div>
          <KeyContactStaff
            readOnly={readOnly}
            details={keyContactsDetailData}
            hasTelNumValidation={false}
            onInputChangeCallback={onContactDetailsInputChange}
            primaryContactMandatory={isMandatory(mtoMandatoryField, MTOMandatoryField.PrimaryContactPerson)}
            allContactsMandatory={isMandatory(mtoMandatoryField, MTOMandatoryField.AllContactPersons)}
          />
        </div>
      </Fragment>
    );
  };

export default forwardRef<AddressDetailsModuleRef, AddressDetailsModuleProps>(AddressDetailsModule);
