import React from "react";
// eslint-disable-next-line no-unused-vars
import PageButtons, { PageButton } from "../../Components/PageButtons/PageButtons";

const PageContentWithButtons = (
  {
    children,
    buttons,
    hasBack,
    onBackClick,
  }
  :
  {
    children: React.ReactNode
    buttons: Array<PageButton>;
    hasBack?: boolean | undefined;
    onBackClick?: (() => void) | undefined;
  }
) => (
  <div className="d-flex flex-column flex-fill bg-white pt-5">
    <div className="container-fluid flex-fill d-flex flex-column">
      <div className="row flex-fill pb-4">
        <div className="col-10 offset-1">
          {children}
        </div>
      </div>

      <PageButtons
        buttons={buttons}
        hasBack={hasBack}
        onBackClick={onBackClick}
      />
    </div>
  </div>
);

export default PageContentWithButtons;
