/* eslint-disable no-case-declarations */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import Styles from "./SubmitInput.module.scss";
import { ReactComponent as PencilSvg } from "./pencil-alt-solid.svg";

const currencyFormat = (stringValue: string): string => {
  const withThousandSeparator = stringValue.replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );

  return `R${withThousandSeparator}`;
};

const percentageFormat = (stringValue: string): string => `${stringValue}%`;

const valueToFixed = (value: number, decimals: number): string => {
  const valueToUse = value == null ? 0 : value;
  const isNumber = typeof valueToUse === "number";
  return isNumber ? valueToUse.toFixed(decimals) : `${valueToUse}`;
};

export enum FormatType {
  Percentage = "percentage", // eslint-disable-line no-unused-vars
  Currency = "currency", // eslint-disable-line no-unused-vars
  PercentageRange = "percentageRange", // eslint-disable-line no-unused-vars
}

const formatStringValue = (type: FormatType, stringValue: string): string => {
  switch (type) {
    case FormatType.Percentage:
      return percentageFormat(stringValue);
    case FormatType.Currency:
      return currencyFormat(stringValue);
    case FormatType.PercentageRange:
      const parts = stringValue.split(";");
      return parts
        .map((part) => formatStringValue(FormatType.Percentage, part))
        .join(" - ");
    default:
      return stringValue;
  }
};

const stringToTruncatedNumber = (
  stringValue: string,
  decimals: number,
  defaultValue: number | null
): number | null => {
  if (!stringValue) {
    return null;
  }
  // make sure number conforms to the decimal requirements by rounding it
  // https://www.jacklmoore.com/notes/rounding-in-javascript/
  let numberValue: number | null = Number(
    `${Math.trunc(Number(`${stringValue}e${decimals}`))}e-${decimals}`
  );

  if (Number.isNaN(numberValue)) {
    numberValue = defaultValue;
  }
  return numberValue;
};

const formatInputPropsValue = (
  type: FormatType,
  decimals: number,
  value: number | number[]
): string => {
  if (value == null) {
    return "";
  }

  const defaultValue = `${value}`;
  switch (type) {
    case FormatType.Percentage:
    case FormatType.Currency:
      if (typeof value !== "number") {
        return defaultValue;
      }

      return formatStringValue(type, valueToFixed(value, decimals));
    case FormatType.PercentageRange:
      if (!Array.isArray(value)) {
        return defaultValue;
      }
      const startFormattedValue = formatStringValue(
        FormatType.Percentage,
        valueToFixed(value[0], decimals)
      );
      const endFormattedValue = formatStringValue(
        FormatType.Percentage,
        valueToFixed(value[1], decimals)
      );
      return `${startFormattedValue} - ${endFormattedValue}`;
    default:
      return defaultValue;
  }
};

const SubmitInput = <T extends number | (number | null)[]>({
  type,
  decimals = 0,
  value,
  onChange,
}: {
  type: FormatType;
  decimals?: number;
  value: T | null;
  onChange: <T>(newValue: T | null) => void;
}): JSX.Element => {
  const input = useRef<HTMLInputElement>(null);

  const [active, setActive] = useState(false);

  const [editingValue, setEditingValue] = useState("");

  const [enteredText, setEnteredText] = useState("");
  const [caretPosition, setCaretPosition] = useState(0);

  const prevEditingValue = useRef<string>(editingValue);
  const prevEnteredText = useRef<string>(enteredText);
  const caretPositionRef = useRef<number>(caretPosition);

  useEffect(() => {
    caretPositionRef.current = caretPosition;
  }, [caretPosition]);

  const getEnteredTextFromEditingValue = useCallback(
    (currentEditingValue: string, defaultValue: string) => (currentEditingValue === ""
      ? ""
      : formatStringValue(type, defaultValue)),
    [type]
  );

  useEffect(() => {
    const currentCaretPosition = caretPositionRef.current;
    let newCaretPosition = currentCaretPosition;

    const nextEnteredText = getEnteredTextFromEditingValue(
      editingValue,
      editingValue
    );
    const editingValueChanged = editingValue !== prevEditingValue.current;
    const enteredTextChanged = enteredText !== nextEnteredText;

    // determine the new caret position
    switch (type) {
      case FormatType.Percentage:
        // if the  caret is after the percentage, move it up
        if (
          currentCaretPosition >= enteredText.length
          && enteredText.includes("%")
        ) {
          newCaretPosition = currentCaretPosition - 1;
        }

        // check if the user has entered invalid characters
        if (!enteredTextChanged && !editingValueChanged) {
          const stringDelta = enteredText.length - prevEnteredText.current.length;

          if (stringDelta < 0) {
            // the user added an invalid character
            newCaretPosition = currentCaretPosition + stringDelta;
          }
        }
        break;
      case FormatType.Currency:
        // Extra formatting was added (R and ,s)
        if (
          enteredTextChanged
          && editingValueChanged
          && nextEnteredText.length > enteredText.length
        ) {
          const delta = nextEnteredText.length - enteredText.length;
          newCaretPosition = currentCaretPosition + delta;
        }

        // check for invalid characters
        if (!enteredTextChanged && !editingValueChanged) {
          const stringDelta = enteredText.length - prevEnteredText.current.length;

          if (stringDelta < 0) {
            // the user added an invalid character
            newCaretPosition = currentCaretPosition + stringDelta;
          }
        }
        break;

      default:
        break;
    }

    if (newCaretPosition != null && input?.current != null) {
      setCaretPosition(newCaretPosition);
      input.current.selectionStart = newCaretPosition;
      input.current.selectionEnd = newCaretPosition;
    }

    if (editingValueChanged) {
      prevEditingValue.current = editingValue;
    }
    if (enteredTextChanged) {
      prevEnteredText.current = enteredText;
    }
  }, [enteredText, editingValue, type, getEnteredTextFromEditingValue]);

  useEffect(() => {
    // update textbox when editing value changes
    setEnteredText(getEnteredTextFromEditingValue(editingValue, editingValue));
  }, [editingValue, getEnteredTextFromEditingValue]);

  useEffect(() => {
    let newFormattedValue = enteredText;
    switch (type) {
      case FormatType.PercentageRange:
        const parts = enteredText.split("-");

        if (parts.length < 2) {
          parts.push("0");
        }

        const startClean = parts[0].replace(/(?!([.]|(\d)))./g, "");
        const endClean = parts[1].replace(/(?!([.]|(\d)))./g, "");

        newFormattedValue = `${startClean.length === 0 ? "0" : startClean};${
          endClean.length === 0 ? "0" : endClean
        }`;

        break;
      default:
        // remove everything that is not a number or a full stop
        newFormattedValue = enteredText.replace(/(?!([.]|(\d)))./g, "");
    }

    setEditingValue((currentValue) => {
      if (currentValue === newFormattedValue) {
        // the value did not change, which means the user entered invalid text,
        // then reset the text box
        setEnteredText(
          getEnteredTextFromEditingValue(currentValue, currentValue)
        );
      }

      // trigger editing value changes
      return newFormattedValue;
    });
  }, [enteredText, type, getEnteredTextFromEditingValue]);

  const startEditingHandler = (): void => {
    let newInputValue = "";

    if (value == null) {
      newInputValue = "";
    } else {
      switch (type) {
        case FormatType.Currency:
        case FormatType.Percentage:
          if (typeof value !== "number") {
            break;
          }
          newInputValue = valueToFixed(value, decimals);
          break;
        case FormatType.PercentageRange:
          if (!Array.isArray(value)) {
            break;
          }
          const startValue = valueToFixed(value[0], decimals);
          const endValue = valueToFixed(value[1], decimals);
          newInputValue = `${startValue};${endValue}`;
          break;
        default:
          break;
      }
    }
    setActive(true);
    setEditingValue(newInputValue);
  };

  const onValueChangeHandler = (
    enteredString: string,
    target: HTMLInputElement
  ): void => {
    const caretPositionFromInput = target.selectionStart ?? 0;
    setCaretPosition(caretPositionFromInput);
    setEnteredText(enteredString);
  };

  const onCancelHandler = (): void => {
    setActive(false);
  };

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    switch (type) {
      case FormatType.PercentageRange:
        const parts = editingValue.split(";");
        if (!Array.isArray(value)) {
          break;
        }
        onChange([
          stringToTruncatedNumber(parts[0], decimals, value[0]),
          stringToTruncatedNumber(parts[1], decimals, value[1]),
        ]);
        break;
      default:
        if (value != null && typeof value !== "number") {
          break;
        }

        const newValue = stringToTruncatedNumber(
          editingValue,
          decimals,
          value as number
        );

        onChange<number>(newValue);
        break;
    }

    setActive(false);
  };

  let emptyValue: number | number[];
  switch (type) {
    case FormatType.PercentageRange:
      emptyValue = [0, 0];
      break;
    default:
      emptyValue = 0;
  }

  const valueToDisplay = (value ?? emptyValue) as number | number[];

  const classes = [];

  classes.push(Styles.SubmitInput);
  classes.push(active ? Styles.active : Styles.inactive);

  const inActiveContent = (
    <div className={[Styles.SubmitInput, Styles.inactive].join(" ")}>
      <span>{formatInputPropsValue(type, decimals, valueToDisplay)}</span>

      <button
        type="button"
        className="btn btn-link"
        onClick={() => startEditingHandler()}
      >
        <PencilSvg />
        edit
      </button>
    </div>
  );
  const activeContent = (
    <div className={[Styles.SubmitInput, Styles.active].join(" ")}>
      <form onSubmit={(ev): void => onSubmitHandler(ev)}>
        <input
          className="border-0"
          ref={input}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          placeholder={formatInputPropsValue(type, decimals, emptyValue)}
          value={enteredText}
          onChange={(ev): void => onValueChangeHandler(ev.target.value, ev.target)}
        />
        <input
          onClick={(): void => onCancelHandler()}
          type="button"
          value="Cancel"
        />
        <input type="submit" value="OK" />
      </form>
    </div>
  );
  return active ? activeContent : inActiveContent;
};

export default SubmitInput;
