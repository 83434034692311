import React from "react";

import {
  BasicTable,
  ActionsDropDown,
  TableActionsItem,
  DropDownInput,
} from "isuppli-react-components";

import {
  Button, Input,
} from "reactstrap";

import {
  filterOutLetters,
  filterOutSpecialCharacters,
} from "../../Validation/onChangeValidations";
import useStaticData from "../../Hooks/useStaticData";
import { StaticDataTypes } from "../../Util/Enumerators/staticDataTypes";
// eslint-disable-next-line no-unused-vars
import { BankAccountDetails } from "../../http/CompanyDetails/BankAccountDetails";

const BankingDetails = (props:
  {
    onInputChangeCallback: (ev: { target: { name: string; value: BankAccountDetails[] } }) => void,
    additional: BankAccountDetails
    readOnly: boolean,
    data: {
      useCustomFields: boolean,
      bankAccounts: BankAccountDetails[]
    },
  }) => {
  const [[banks, bankAccountTypes]] = useStaticData(
    [StaticDataTypes.Bank, StaticDataTypes.BankAccountType]
  );

  const setBankAccounts = (bankAccounts: BankAccountDetails[]) => {
    props.onInputChangeCallback({
      target: {
        name: "bankAccounts",
        value: bankAccounts,
      },
    });
  };

  const bankAccounts = props.data.bankAccounts || [];

  const doAdd = () => {
    setBankAccounts([
      ...bankAccounts,
      {
        bankAccountDetailsId: 0,
        bankId: 0,
        customBank: "",
        bankAccountTypeId: 0,
        customBankAccountType: "",
        accountName: "",
        accountNumber: "",
        branchCode: "",
        branchName: "",
      },
    ]);
  };

  const doUpdate = (account: BankAccountDetails, index: number) => {
    const newValues = bankAccounts.map((a, i) => {
      if (i === index) return { ...account };
      return { ...a };
    });
    setBankAccounts(newValues);
  };

  const doDelete = (index: number) => {
    const newValues = [];
    for (let i = 0; i < bankAccounts.length; i += 1) {
      if (i !== index) {
        newValues.push(bankAccounts[i]);
      }
    }
    setBankAccounts(newValues);
  };

  return (
    <div>
      <BasicTable
        tableStyle="mobile"
        columns={[
          {
            key: "bankName",
            text: "Bank Name",
            size: "1fr",
            align: "left",
          },
          {
            key: "accountType",
            text: "Bank Account Type",
            size: "1fr",
            align: "left",
          },
          {
            key: "accountName",
            text: "Bank Account Name",
            size: "1fr",
            align: "left",
          },
          {
            key: "accountNumber",
            text: "Bank Account Number",
            size: "1fr",
            align: "left",
          },
          {
            key: "branchName",
            text: "Branch Name",
            size: "1fr",
            align: "left",
          },
          {
            key: "branchCode",
            text: "Branch Code",
            size: "1fr",
            align: "left",
          },
          {
            key: "actions",
            text: "Actions",
            size: "auto",
          },
        ]}
        rows={
          bankAccounts.map((account, index) => [
            {
              key: `bankName_${index}`,
              fullSize: true,
              valueTemplate: () => (
                props.data.useCustomFields
                  ? (
                    <Input
                      className="rounded-0 h-100 border-0"
                      type="text"
                      name={`customBank_${index}`}
                      placeholder="Enter the bank's name"
                      disabled={props.readOnly}
                      value={account.customBank ?? ""}
                      onChange={(e) => doUpdate(
                        { ...account, customBank: e.target.value }, index
                      )}
                      maxLength={40}
                    />
                  )
                  : (
                    <DropDownInput
                      squareBorder
                      className="h-100"
                      inputClassName="rounded-0 h-100 border-0"
                      value={account.bankId}
                      disabled={props.readOnly}
                      onChange={(value) => doUpdate(
                        { ...account, bankId: Number(value) }, index
                      )}
                      options={banks ?? []}
                    />
                  )
              ),
            },
            {
              key: `accountType_${index}`,
              fullSize: true,
              valueTemplate: () => (
                props.data.useCustomFields
                  ? (
                    <Input
                      className="rounded-0 h-100 border-0"
                      type="text"
                      name={`customBankAccountType_${index}`}
                      placeholder="Enter the bank's account type"
                      disabled={props.readOnly}
                      value={account.customBankAccountType ?? ""}
                      onChange={(e) => doUpdate(
                        { ...account, customBankAccountType: e.target.value }, index
                      )}
                      maxLength={40}
                    />
                  )
                  : (
                    <DropDownInput
                      className="h-100"
                      squareBorder
                      inputClassName="h-100 border-0"
                      value={account.bankAccountTypeId}
                      disabled={props.readOnly}
                      onChange={(value) => doUpdate(
                        { ...account, bankAccountTypeId: Number(value) }, index
                      )}
                      options={bankAccountTypes ?? []}
                    />
                  )
              ),
            },
            {
              key: `accountName_${index}`,
              fullSize: true,
              valueTemplate: () => (
                <Input
                  className="rounded-0 h-100 border-0"
                  type="text"
                  name={`accountName_${index}`}
                  placeholder="Enter the account's name"
                  disabled={props.readOnly}
                  value={account.accountName}
                  onChange={(e) => doUpdate(
                    { ...account, accountName: e.target.value }, index
                  )}
                  maxLength={40}
                />
              ),
            },
            {
              key: `accountNumber_${index}`,
              fullSize: true,
              valueTemplate: () => (
                <Input
                  className="rounded-0 h-100 border-0"
                  type="text"
                  name={`accountNumber_${index}`}
                  placeholder="Enter the account's number"
                  disabled={props.readOnly}
                  value={account.accountNumber}
                  onChange={(e) => doUpdate(
                    { ...account, accountNumber: filterOutLetters(e.target.value) }, index
                  )}
                  maxLength={15}
                />
              ),
            },
            {
              key: `branchName_${index}`,
              fullSize: true,
              valueTemplate: () => (
                <Input
                  className="rounded-0 h-100 border-0"
                  type="text"
                  name={`branchName_${index}`}
                  placeholder="Enter the branch name"
                  disabled={props.readOnly}
                  value={account.branchName}
                  onChange={(e) => doUpdate(
                    { ...account, branchName: filterOutSpecialCharacters(e.target.value) }, index
                  )}
                  maxLength={40}
                />
              ),
            },
            {
              key: `branchCode_${index}`,
              fullSize: true,
              valueTemplate: () => (
                <Input
                  className="rounded-0 h-100 border-0"
                  type="text"
                  name={`branchCode_${index}`}
                  placeholder="Enter the account's branch code"
                  value={account.branchCode}
                  disabled={props.readOnly}
                  onChange={(e) => doUpdate(
                    { ...account, branchCode: filterOutLetters(e.target.value) }, index
                  )}
                  maxLength={10}
                />
              ),
            },
            {
              key: `actions_${index}`,
              valueTemplate: () => (
                <ActionsDropDown
                  label="Available Actions"
                  className="text-nowrap px-2"
                >
                  <TableActionsItem onClick={() => doDelete(index)}>
                    Delete
                  </TableActionsItem>
                </ActionsDropDown>
              ),
            },
          ])
        }
        emptyMessage="Use the Add button below to add a bank account"
      />
      <Button onClick={doAdd} color="link">+ Add new bank account</Button>
    </div>
  );
};

export default BankingDetails;
