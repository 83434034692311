import axios from "axios";
import { makeRequestAsync } from "../util";
import { SupplierImportMessage } from "./Models/SupplierImportMessage";// eslint-disable-line no-unused-vars

export const getSupplierImport = async () => {
  const url = "/api/supplierImport";
  return makeRequestAsync(
    () => axios.get(url)
  ) as Promise<SupplierImportMessage>;
};

export const resolveSupplierImport = async () => {
  const url = "/api/supplierImport";
  return makeRequestAsync(
    () => axios.put(url)
  ) as Promise<void>;
};
