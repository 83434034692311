import React, {
  Fragment,
  useEffect,
  useState,
} from "react";
import {
  UtilButton,
  Line,
  useToast,
  Spinner,
} from "isuppli-react-components";
import RadioSelector from "../../Controls/RadioSelector";
import { saveCommunicationPreference } from "../../http/posts";
import { getCommunicationPreference } from "../../http/gets";
import useShowError from "../../Hooks/useShowError";
import receiveMarketingInfoEnum from "../../Util/Enumerators/userMarketingInfoSubscriptionEnum";

const CommunicationPreferencesSummary = () => {
  const [receiveMarketingInfo, setReceiveMarketingInfo] = useState(-1);
  const [loading, setLoading] = useState(false);
  const showError = useShowError();
  const showToast = useToast();

  useEffect(() => {
    setLoading(true);
    const loader = async () => {
      const result = await getCommunicationPreference();
      setReceiveMarketingInfo(result === receiveMarketingInfoEnum.Subscribed ? 1 : 0);
    };
    loader();
    setLoading(false);
  }, [setReceiveMarketingInfo]);

  const onInputChange = (ev: Event) => {
    setReceiveMarketingInfo(Number((ev?.target as HTMLInputElement)?.value));
  };

  const onClickSave = async () => {
    setLoading(true);
    try {
      await saveCommunicationPreference(
        receiveMarketingInfo === 1
          ? receiveMarketingInfoEnum.Subscribed
          : receiveMarketingInfoEnum.Unsubscribed
      );
      setLoading(false);
      showToast("Communication Preferences Saved");
    } catch (error) {
      setLoading(false);
      showError();
      throw error;
    }
  };

  return (
    <Fragment>
      {
        loading && <Spinner />
      }
      <section className="padding-lr-10vw">
        <RadioSelector
          name="receiveMarketingInfo"
          label="I would like to receive marketing information from B1Link"
          value={receiveMarketingInfo || 0}
          options={[
            { value: 1, display: "yes" },
            { value: 0, display: "no" },
          ]}
          onChangeCallback={onInputChange}
        />
      </section>
      <div
        className="padding-lr-5vw"
        style={{ paddingBottom: "76px", marginTop: "60px" }}
      >
        <div className="justify-end row">
          <Line color="secondary" />
          <UtilButton
            color="primary"
            location="bottom"
            onClick={onClickSave}
          >
            SAVE
          </UtilButton>
        </div>
      </div>
    </Fragment>
  );
};

export default CommunicationPreferencesSummary;
