import React from "react";
import { InputGroupAddon, InputGroupText } from "reactstrap";

import Styles from "./InputGroupImageAddon.module.scss";

import { ReactComponent as SvgBuilding } from "./input-icon-building.svg";
import { ReactComponent as SvgPhone } from "./input-icon-phone.svg";
import { ReactComponent as SvgEnvelope } from "./input-icon-envelope.svg";
import { ReactComponent as SvgPerson } from "./input-icon-person.svg";
import { ReactComponent as SvgCalendar } from "./input-icon-calendar.svg";

const InputGroupImageAddon = (
  {
    icon,
    addonType,
  }
  :
  {
    icon: "building" | "envelope" | "phone" | "person" | "calendar",
    addonType: "prepend" | "append"
  }
) => {
  let image = null;
  switch (icon) {
    case "building":
      image = <SvgBuilding className={Styles.Image} />;
      break;
    case "envelope":
      image = <SvgEnvelope className={Styles.Image} />;
      break;
    case "phone":
      image = <SvgPhone className={Styles.Image} />;
      break;
    case "person":
      image = <SvgPerson className={Styles.Image} />;
      break;
    case "calendar":
      image = <SvgCalendar className={Styles.Image} />;
      break;
    default:
      break;
  }
  return image == null ? null : (
    <InputGroupAddon addonType={addonType}>
      <InputGroupText>{image}</InputGroupText>
    </InputGroupAddon>
  );
};

export default InputGroupImageAddon;
