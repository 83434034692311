import {
  ShadowCard, Spinner,
} from "isuppli-react-components";
import React, { useState, useEffect, Fragment } from "react";
import useShowError from "../../../Hooks/useShowError";
import { getDashboardBeeSummary } from "../../../http/Dashboard/dashboardApi";
// eslint-disable-next-line no-unused-vars
import { DashboardBeeClassificationSummary } from "../../../http/Dashboard/Models/Models";
// eslint-disable-next-line no-unused-vars
import { DashboardSummaryItem } from "../DashboardSummaryItem/DashboardSummaryItem";
import DashboardSuppliersSummaryTable from "../DashboardSuppliersSummaryTable/DashboardSuppliersSummaryTable";

/* eslint-disable no-unused-vars */
enum BeeStateFilter {
    None = 0,
    Valid = 1,
    Invalid = 2,
    Expired = 3,
    PendingValidation = 4,
    ValidForClient =7,
}
/* eslint-enable no-unused-vars */

const SupplierBEEComplianceSummary = () => {
  const [beeSummaryData, setBeeSummaryData] = useState<DashboardBeeClassificationSummary[]>([]);
  const [loading, setLoading] = useState(true);

  const showError = useShowError();

  useEffect(() => {
    const loader = async () => {
      setLoading(true);
      try {
        // api calls
        const dashboardBeeSummaryData = await getDashboardBeeSummary();
        setBeeSummaryData(dashboardBeeSummaryData);
      } catch (error) {
        showError();
      }
      setLoading(false);
    };
    loader();
  }, [setLoading, showError]);

  const columns = [
    "",
    "Total",
    "Valid",
    "Invalid",
    "Expired",
    "In Validation",
    "Valid in Period",
  ];

  const rows = beeSummaryData?.map((data) => [
    { value: data.name },
    {
      value: data.total >= 0 ? data.total : null,
      linkUrl: `/marketplace/search?f=[[],[],[],[],[],[],null,null,null,null,null,null,null,${data.beeClassificationId},${BeeStateFilter.None},null,null,null,null,null,null,null,null,null]&sf=1`,
    },
    {
      value: data.valid >= 0 ? data.valid : null,
      linkUrl: `/marketplace/search?f=[[],[],[],[],[],[],null,null,null,null,null,null,null,${data.beeClassificationId},${BeeStateFilter.Valid},null,null,null,null,null,null,null,null,null]&sf=1`,
    },
    {
      value: data.invalid >= 0 ? data.invalid : null,
      linkUrl: `/marketplace/search?f=[[],[],[],[],[],[],null,null,null,null,null,null,null,${data.beeClassificationId},${BeeStateFilter.Invalid},null,null,null,null,null,null,null,null,null]&sf=1`,
    },
    {
      value: data.expired >= 0 ? data.expired : null,
      linkUrl: `/marketplace/search?f=[[],[],[],[],[],[],null,null,null,null,null,null,null,${data.beeClassificationId},${BeeStateFilter.Expired},null,null,null,null,null,null,null,null,null]&sf=1`,
    },
    {
      value: data.pendingValidation >= 0 ? data.pendingValidation : null,
      linkUrl: `/marketplace/search?f=[[],[],[],[],[],[],null,null,null,null,null,null,null,${data.beeClassificationId},${BeeStateFilter.PendingValidation},null,null,null,null,null,null,null,null,null]&sf=1`,
    },
    {
      value: data.validForClient >= 0 ? data.validForClient : null,
      linkUrl: `/marketplace/search?f=[[],[],[],[],[],[],null,null,null,null,null,null,null,${data.beeClassificationId},${BeeStateFilter.ValidForClient},null,null,null,null,null,null,null,null,null]&sf=1`,
    },
  ] as DashboardSummaryItem[]) ?? [[]];

  return (
    <Fragment>
      {/* BEE */}
      <div className="row mt-4">
        <div className="col-12">
          <h2>MY SUPPLIERS BY BEE COMPLIANCE</h2>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12">
          <ShadowCard>
            {loading ? <Spinner inline /> : (
              <DashboardSuppliersSummaryTable
                title=""
                columns={columns}
                rows={rows}
              />
            )}
          </ShadowCard>
        </div>
      </div>
    </Fragment>
  );
};

export default SupplierBEEComplianceSummary;
