import React from "react";
import { SystemFeatures, FeatureRoute } from "isuppli-react-components";
import { Redirect, Switch } from "react-router-dom";

import AddressContactInfo from "../Sections/MyCompany/AddressContactInfo";
import SupplierInfo from "../Sections/MyCompany/SupplierInfo";
import Compliance from "../Sections/MyCompany/Compliance";
import BeePractice from "../Sections/MyCompany/BeePractice";
import MyClients from "../Sections/MyCompany/MyClients";
import ProductService from "../Sections/MyCompany/ProductService";
import BankInfo from "../Sections/MyCompany/BankInfo";
import EnterpriseConfigView from "./EnterpriseConfigView";
import Agreements from "../Sections/MyCompany/Agreements";

import UserManagement from "../Sections/Administration/UserManagement/UserManagement";

import Subscription from "../Sections/Subscription/Subscription";
import EditSubscription from "../Sections/Subscription/EditSubscription/EditSubscription";
import Configuration from "../Sections/Administration/Configuration/Configuration";

function MyCompanyView() {
  return (
    <section>
      <Switch>
        <FeatureRoute
          path="/mycompany/profile/supplier"
          feature={SystemFeatures.CompanyProfile}
          component={() => <SupplierInfo />}
        />
        <FeatureRoute
          path="/mycompany/profile/clients"
          feature={SystemFeatures.CompanyProfile}
          component={() => <MyClients />}
        />
        <FeatureRoute
          path="/mycompany/compliance"
          feature={SystemFeatures.OngoingCompliance}
          component={() => <Compliance />}
        />
        <FeatureRoute
          path="/mycompany/profile/contact"
          feature={SystemFeatures.CompanyProfile}
          component={() => <AddressContactInfo />}
        />
        <FeatureRoute
          path="/mycompany/profile/bee"
          feature={SystemFeatures.CompanyProfile}
          component={() => <BeePractice />}
        />
        <FeatureRoute
          path="/mycompany/profile/agreement"
          feature={SystemFeatures.CompanyProfile}
          component={() => <Agreements />}
        />
        <FeatureRoute
          path="/mycompany/profile/products"
          feature={SystemFeatures.CompanyProfile}
          component={() => <ProductService />}
        />
        <FeatureRoute
          path="/mycompany/profile/bank"
          feature={SystemFeatures.CompanyProfile}
          component={() => <BankInfo />}
        />
        <FeatureRoute
          path="/mycompany/configuration"
          feature={SystemFeatures.Configuration}
          component={() => <Configuration />}
        />
        <FeatureRoute
          path="/mycompany/enterprise-configuration"
          feature={SystemFeatures.EnterpriseConfig}
          component={() => <EnterpriseConfigView />}
        />

        <FeatureRoute
          feature={SystemFeatures.UserManagement}
          path="/mycompany/user-management"
          component={UserManagement}
        />

        <FeatureRoute
          feature={SystemFeatures.SubscriptionsEdit}
          path="/mycompany/subscription/edit"
          component={() => <EditSubscription />}
        />
        <FeatureRoute
          feature={SystemFeatures.Subscriptions}
          path="/mycompany/subscription"
          component={() => <Subscription />}
        />
        <Redirect to="/mycompany/profile/supplier" />
      </Switch>
    </section>
  );
}

export default MyCompanyView;
