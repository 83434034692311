import {
  isMandatory,
  MTOMandatoryField,
  Spinner,
  useToast,
} from "isuppli-react-components";
import React, {
  // eslint-disable-next-line no-unused-vars
  forwardRef, ForwardRefRenderFunction, Fragment, useEffect, useImperativeHandle, useState,
} from "react";
import { Alert } from "reactstrap";
import BankingDetails from "../../../Containers/Supplier/BankingDetails";
import useShowError from "../../../Hooks/useShowError";
import { getBankingDetails, saveBankingDetailsAsync } from "../../../http";
import { BankAccountDetails } from "../../../http/CompanyDetails/BankAccountDetails";// eslint-disable-line no-unused-vars
import { BankDetailsMessage } from "../../../http/ManualSupplier/Messages/Messages";// eslint-disable-line no-unused-vars
import { ValidationModel } from "../../../Sections/Marketplace/MTPManualSupplierStep/MTPManualSupplierStep";// eslint-disable-line no-unused-vars

export interface BankDetailsModuleRef{
  save: () => Promise<boolean> ;
}

export interface BankDetailsModuleProps{
  companyId: number
  containerClass?: string
  hideTitle?:boolean,
  readOnly?:boolean,
  mtoMandatoryField?:number
}

const BankDetailsModule:
ForwardRefRenderFunction<BankDetailsModuleRef, BankDetailsModuleProps> = (
  {
    companyId = 0, containerClass = "", hideTitle = false, readOnly = false, mtoMandatoryField = 0,
  }: BankDetailsModuleProps, ref
) => {
  const [loading, setLoading] = useState(false);
  const [bankDetails, setBankingDetails] = useState<BankAccountDetails[]>([]);
  const [customFieldsApply, setCustomFieldsApply] = useState(false);
  const showToast = useToast();
  const showError = useShowError();

  const saveBankDetails = async () => {
    const saveData : BankDetailsMessage = {
      companyId,
      useCustomFields: customFieldsApply,
      bankAccounts: bankDetails,
    };

    setLoading(true);
    try {
      const result = await saveBankingDetailsAsync(saveData);
      setLoading(false);
      setBankingDetails(result.bankAccounts);
      showToast("Banking information saved");
      return true;
    } catch (error) {
      setLoading(false);
      return false;
    }
  };

  // save changes
  const stepValidation = () => {
    const result: ValidationModel = {
      isStepValid: true,
      msgs: {},
    };

    if (mtoMandatoryField !== null) {
      if (isMandatory(mtoMandatoryField, MTOMandatoryField.Banking)) {
        if (bankDetails.length === 0) {
          result.isStepValid = false;
          result.msgs.banking = "Banking details are required";
        }
      }
    }
    return result;
  };

  useImperativeHandle(ref, () => ({
    save: saveBankDetails,
    validateStep: stepValidation,
  }));

  useEffect(() => {
    const loader = () => {
      setLoading(true);
      try {
        setLoading(false);
        getBankingDetails(companyId, (res: BankDetailsMessage) => {
          setBankingDetails(res.bankAccounts);
          setCustomFieldsApply(res.useCustomFields);
        });
      } catch {
        showError();
        setLoading(false);
      }
    };
    loader();
  }, [companyId, showError]);

  return (
    <Fragment>
      {loading && <Spinner />}
      <div className={containerClass}>
        {hideTitle === false && <h1>BANK DETAILS</h1>}
        {isMandatory(mtoMandatoryField, MTOMandatoryField.SupportingDocument) && "*required"}
        <div className="row" style={{ paddingTop: "36px" }}>
          <div className="col-12 col-lg-9">
            <div>
              <Alert color="info">
                Your banking information will only be visible to the relevant users
                within the companies you have been onboarded to via B1LINK. These users
                cannot edit your information in any way. If you would like further
                information regarding the security of your banking information,
                please contact
                {" "}
                <u>support@b1link.co.za</u>
                {" "}
                or contact 011 455 0033
              </Alert>
            </div>
          </div>
        </div>
        <BankingDetails
          readOnly={readOnly}
          data={{
            useCustomFields: customFieldsApply,
            bankAccounts: bankDetails,
          }}
          additional={{} as BankAccountDetails}
          onInputChangeCallback={(ev) => { setBankingDetails(ev.target.value); }}
        />
      </div>
    </Fragment>
  );
};

export default forwardRef<BankDetailsModuleRef, BankDetailsModuleProps>(BankDetailsModule);
