import { Spinner, useToast, UtilButton } from "isuppli-react-components";
import React, {
  // eslint-disable-next-line no-unused-vars
  forwardRef, ForwardRefRenderFunction, Fragment, useEffect, useImperativeHandle, useState,
} from "react";
import { useSelector } from "react-redux";
import { Alert } from "reactstrap";
import useShowError from "../../../Hooks/useShowError";
import { getSupplierCategories, saveSupplierCategories } from "../../../http/ManualSupplier/manualSupplierApi";
// eslint-disable-next-line no-unused-vars
import { ReduxState } from "../../../Store/ReduxState";
import CompanyConfigurationFeatures from "../../../Util/Enumerators/CompanyConfigurationFeatures";
import OfferingSelector from "../../Supplier/OfferingSelector/OfferingSelector";
import ProductsAndServicesList from "../../Supplier/ProductsAndServicesList";

export interface ProductSegmentsModuleRef{
    save: () => Promise<boolean>;
  }

export interface ProductSegmentsModuleProps{
    companyId: number,
    readOnly?: boolean,
  }

const ProductSegmentsModule:
ForwardRefRenderFunction<ProductSegmentsModuleRef, ProductSegmentsModuleProps> = (
  { companyId = 0, readOnly = false }:ProductSegmentsModuleProps, ref
) => {
  const [showSelectionModal, setShowSelectionModal] = useState(false);
  const [supplierSubCategoryIds, setSupplierCategoryIds] = useState<Array<number>>([]);
  const [loading, setLoading] = useState(false);
  const showToast = useToast();
  const showError = useShowError();

  const allowedCompanyFeatures = useSelector(
    (state: ReduxState) => (state.companyConfiguration.allowedCompanyFeatures)
  );

  const saveProductSegmentDetails = async () => {
    setLoading(true);
    try {
      await saveSupplierCategories(companyId, supplierSubCategoryIds);
      setLoading(false);
      showToast("Product and services saved");
      return true;
    } catch {
      setLoading(false);
      return false;
    }
  };

  useImperativeHandle(ref, () => ({
    save: saveProductSegmentDetails,
  }));

  useEffect(() => {
    const loader = async () => {
      setLoading(true);

      try {
        const supplierOffering = await getSupplierCategories(companyId);
        setSupplierCategoryIds(supplierOffering);
        setLoading(false);
      } catch {
        setLoading(false);
        showError();
      }
    };
    loader();
  }, [companyId, showError]);

  // eslint-disable-next-line no-bitwise
  const titleHeading = ((allowedCompanyFeatures & CompanyConfigurationFeatures.MTOCompanyProductsAndFamilies) > 0 // eslint-disable-line max-len
  ) ? "PRODUCT SEGMENTS & FAMILIES" : "COMPANY PRODUCTS & FAMILIES";

  return (
    <Fragment>
      {loading && <Spinner />}
      <div className="p-5">
        <div className="row">
          <div className="col-12 col-lg-7">
            <h2>{titleHeading}</h2>
            <div>
              <Alert color="info">
                Please add your most relevant products and/or services.
              </Alert>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "20px" }} />

        {showSelectionModal && (
          <OfferingSelector
            setSelection={(selection: Array<number>) => { setSupplierCategoryIds(selection); }}
            selection={supplierSubCategoryIds}
            onCloseCallback={() => { setShowSelectionModal(false); }}
          />
        )}

        <div className="row">
          <div className="col-12">
            <ProductsAndServicesList
              selectedSupplierSubCategoryIds={supplierSubCategoryIds}
            />
          </div>
          <div className="col-12 col-xl-5">
            <UtilButton
              disabled={readOnly}
              color="primary"
              onClick={() => setShowSelectionModal(true)}
            >
              SELECT PRODUCTS AND/OR SERVICES
            </UtilButton>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default forwardRef<ProductSegmentsModuleRef,
ProductSegmentsModuleProps>(ProductSegmentsModule);
