import axios from "axios";
import { makeRequestAsync } from "../util";
import { DocumentSummaryMessage } from "./Messages/DocumentSummaryMessage";// eslint-disable-line no-unused-vars
import {
  AddressContactsMessage, // eslint-disable-line no-unused-vars
  BeeRatingMessage, // eslint-disable-line no-unused-vars
} from "./Messages/Messages";

// eslint-disable-next-line import/prefer-default-export
export const saveAddressContactsInfo = async (message: AddressContactsMessage) => {
  const url = "api/manualSupplier/addresscontactinfo";
  return makeRequestAsync(() => axios
    .post(url, message)) as Promise<void>;
};

export const saveSupplierDocuments = async (message: DocumentSummaryMessage) => {
  const url = "/api/manualSupplier/documents";
  return makeRequestAsync(() => axios.post(url, message)) as Promise<void>;
};

export const getSupplierCategories = async (companyId: number) => {
  const url = `/api/manualSupplier/${companyId}/offerings`;
  return makeRequestAsync(() => axios.get(url)) as Promise<Array<number>>;
};

export const saveSupplierCategories = async (companyId: number, categories: Array<number>) => {
  const url = `/api/manualSupplier/${companyId}/offerings`;
  return makeRequestAsync(() => axios.post(url, categories)) as Promise<void>;
};

export const getBeeRating = async (companyId: number) => {
  const url = `/api/manualSupplier/${companyId}/beeratings`;
  return makeRequestAsync(() => axios.get(url)) as Promise<BeeRatingMessage>;
};

export const saveBeeRating = async (companyId: number, categories: BeeRatingMessage) => {
  const url = `/api/manualSupplier/${companyId}/beeratings`;
  return makeRequestAsync(() => axios.post(url, categories)) as Promise<void>;
};
