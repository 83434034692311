import React, { useState } from "react";

import DropDownButton from "../../DropDownButton/DropDownButton";
import TextInput from "../../Forms/TextInput/TextInput";
import DateInput from "../../Forms/DateInput/DateInput";

import DropDownInput from "../../Forms/DropDownInput/DropDownInput";

// eslint-disable-next-line no-unused-vars
import { AdvancedFilterInfo } from "../Interfaces/AdvancedFilterInfo";
import AdvancedFilterTypes from "../../../utils/enums/AdvancedFilterTypes";
// eslint-disable-next-line no-unused-vars
import { FilterValue } from "../SearchFiltersContainer/SearchFiltersContainer";
import Styles from "./AdvancedFilters.module.scss";
import MultiSelectDropDownInput from "../../Forms/MultiSelectDropDownInput/MultiSelectDropDownInput";
// eslint-disable-next-line no-unused-vars
import SystemFeatures from "../../../utils/enums/SystemFeatures";
import { useShowNotAllowedError } from "../../../Hooks/useNotAllowedError";
import { useSystemFeatureCheck } from "../../../Hooks/useSystemFeatureCheck";

const AdvancedSearch = (
  {
    filters = [],
    filterValues = [],
    onChangeCallback,
    className = "",
    advancedFiltersSystemFeature,
  }
:
  {
    filters?: AdvancedFilterInfo[],
    filterValues?: FilterValue[],
    onChangeCallback: (newValues : FilterValue[]) => void,
    className?: string,
    advancedFiltersSystemFeature?: SystemFeatures
  }
) => {
  const [isOpen, setIsOpen] = useState(false);

  const showNotAllowedError = useShowNotAllowedError();
  const featureCheck = useSystemFeatureCheck();

  const onShowAdvanceSearchToggleHandler = () => {
    if (advancedFiltersSystemFeature != null) {
      if (featureCheck(advancedFiltersSystemFeature)) {
        setIsOpen((currentValue) => !currentValue);
      } else {
        showNotAllowedError(advancedFiltersSystemFeature);
      }
    }
  };

  const onValueChangeCallback = (value: FilterValue, index: number) => {
    const newValues = [...filterValues];
    newValues[index] = value;
    onChangeCallback(newValues);
  };

  const buttonClasses = ["w-auto", Styles.AdvancedSearchButton];
  if (isOpen) {
    buttonClasses.push(Styles.Open);
  } else {
    buttonClasses.push(Styles.Closed);
  }

  const containerDivClasses = [Styles.AdvancedSearchInternalContainer];
  if (isOpen) {
    containerDivClasses.push(Styles.Open);
  } else {
    containerDivClasses.push(Styles.Closed);
  }

  const advanceFiltersWithValues = filterValues.filter((value) => value).length;
  const advanceFilterBadge = advanceFiltersWithValues > 0 ? `${advanceFiltersWithValues} filters` : undefined;

  const onChangeHandler = (index: number, newValue: FilterValue) => {
    const newValues = [...filterValues];
    newValues[index] = newValue;
    onChangeCallback(newValues);
  };

  return (
    <div className={`${Styles.AdvancedSearchContainer} ${className}`}>
      <div className={containerDivClasses.join(" ")}>
        <DropDownButton
          className={buttonClasses.join(" ")}
          label="Advanced search"
          onClick={() => onShowAdvanceSearchToggleHandler()}
          up={isOpen}
          badge={isOpen ? undefined : advanceFilterBadge}
          badgePlacement="outside"
          styleVal={advancedFiltersSystemFeature != null && !featureCheck(advancedFiltersSystemFeature) ? "dark" : "default"}
        />

        {isOpen ? (
          <div className={Styles.AdvancedSearch}>
            {filters.map((filter, index) => {
              switch (filter.filterType) {
                case AdvancedFilterTypes.Text:
                  return (
                    <TextInput
                    // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      label={filter.filterName}
                      value={filterValues[index] == null ? "" : filterValues[index] as string}
                      placeholder={filter.placeholder}
                      onChange={(value) => onValueChangeCallback(value, index)}
                    />
                  );
                case AdvancedFilterTypes.DropDown:
                  return (
                    <DropDownInput
                    // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      label={filter.filterName}
                      options={filter.options}
                      value={filterValues[index] == null
                        ? undefined
                        : filterValues[index] as number}
                      onChange={(value) => onValueChangeCallback(value, index)}
                    />
                  );
                case AdvancedFilterTypes.Date:
                  return (
                    <DateInput
                    // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      label={filter.filterName}
                      min={new Date(1900, 1, 1)}
                      value={filterValues[index] == null ? undefined : filterValues[index] as Date}
                      onChange={(value) => onValueChangeCallback(value, index)}
                    />
                  );
                case AdvancedFilterTypes.MultiSelectDropdown:
                  return (
                    <MultiSelectDropDownInput
                      key={index} // eslint-disable-line react/no-array-index-key
                      label={filter.filterName}
                      options={filter.options}
                      value={filterValues[index] as number[] || []}
                      onChange={(newValue: number[]) => onChangeHandler(index, newValue)}
                      disabled={filter.disabled}
                    />
                  );
                default:
                  return null;
              }
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AdvancedSearch;
