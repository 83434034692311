import React, { Component } from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import { ConfirmationModal, MTOMandatoryField, isMandatory } from "isuppli-react-components";
import { Alert } from "reactstrap";
import OnboardingWizard from "../../Containers/Marketplace/OnboardingWizard/OnboardingWizard";
import CompanyDetails from "../../Containers/Marketplace/CompanyDetails";

import { saveOnboardingRequest } from "../../http/posts";
import { getCompanyCategoryManagers, getDuplicateRequests } from "../../http/gets";

import {
  setStoreProperty,
  showLoadingScreen,
  hideLoadingScreen,
  showInfoPopup,
  hideInfoPopup,
  showErrorDialog,
} from "../../Store/actions";
import {
  setOnboardingRequestObject,
  onOnboardingInputChange,
} from "../../Store/onboardingRequestActions";
import { setOption } from "../../Store/optionActions";

import { loadAddOnboardingRequestOptions } from "../../Util/optionsLoader";
import validateStep from "../../Validation/onSubmitValidations";
import { requiredFieldsMTP1, requiredFieldsManualSupplierMTP1, requiredMandatoryFieldsStep1 } from "../../Validation/validation";
import Loading from "../Loading";
import InfoPopup from "../../Containers/InfoPopup";
import DuplicateRequestModal from "./DuplicateRequestModal";

import {
  onCompanyTypeChangeHandler,
} from "../../Validation/onChangeValidations";
import {
  StaticCompanyDataTypes, // eslint-disable-line no-unused-vars
} from "../../Util/Enumerators/staticCompanyDataTypes";
import StaticCompanyData from "../../Hooks/StaticCompanyData";
import CompanyConfigurationFeatures from "../../Util/Enumerators/CompanyConfigurationFeatures";

import SuspensionMessageBanner from "./SuspensionMessageBanner/SuspensionMessageBanner";
import { checkHttpStatus } from "../../http/httpHelpers";

const mapStateToProps = (state) => ({
  data: state.onboardingRequest,
  companyTypes: state.options.companyTypes,
  businessUnits: (state.staticCompanyData[StaticCompanyDataTypes.businessUnit] ?? []).filter(
    (businessUnit) => state.loggedInUserDetails.businessUnitIds.includes(businessUnit.id)
  ),
  allowedCompanyFeatures: state.companyConfiguration.allowedCompanyFeatures,
  contractTiers: state.staticCompanyData[StaticCompanyDataTypes.contractTiers] ?? [],
  supplierTypes: state.staticCompanyData[StaticCompanyDataTypes.supplierType] ?? [],
  paymentTerms: state.staticCompanyData[StaticCompanyDataTypes.paymentTerms] ?? [],
});

const mapDisatchToProps = (dispatch) => ({
  setMTPLastStep: (onboardingLastStepCompleted) => {
    dispatch(
      setStoreProperty(
        "onboardingLastStepCompleted",
        onboardingLastStepCompleted
      )
    );
  },
  updateOnboardingRequest: (onbordingRequest) => {
    dispatch(setOnboardingRequestObject(onbordingRequest));
  },
  onInputChange: (ev) => {
    dispatch(onOnboardingInputChange(ev));
  },
  showLoadingScreen: (msg) => {
    dispatch(showLoadingScreen(msg));
  },
  hideLoadingScreen: () => {
    dispatch(hideLoadingScreen());
  },
  showInfoMsg: (heading, subheading, msgs) => {
    dispatch(showInfoPopup(heading, subheading, msgs));
  },
  hideInfoMsg: () => {
    dispatch(hideInfoPopup());
  },
  setOption: (optionName, optionValues) => {
    dispatch(setOption(optionName, optionValues));
  },
  showErrorDialog: () => {
    dispatch(showErrorDialog(true));
  },
});

class MTPCompanyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msgs: [],
      isStepValid: true,
      showConfirmMsg: false,
      showDuplicateModal: false,
      duplicates: [],
      categoryManagers: [],
      categoryManagerInitiator: false,
    };
  }

  performSave = async (onboardingRequest, saveOnly) => {
    try {
      this.props.showLoadingScreen("saving data...");

      const updatedOnboardingRequest = await saveOnboardingRequest(
        onboardingRequest,
        saveOnly
      );

      this.props.updateOnboardingRequest(updatedOnboardingRequest);

      this.props.hideLoadingScreen();
    } catch (error) {
      if (checkHttpStatus(error, 409)) {
        const duplicates = await getDuplicateRequests(
          this.props.data.companyTypeId,
          this.props.data.registrationNumber,
          this.props.data.onboardingId
        );
        this.toggleDuplicateModal(duplicates);
      } else {
        this.props.showErrorDialog();
      }
      this.props.hideLoadingScreen();
      throw error;
    }
  };

  onNextClick = async (nextUrl) => {
    let validation = {};
    const mtoMandatoryField = this.props.data.manualSupplier ? this.props.data.manualMTOMandatoryField : this.props.data.mtoMandatoryField;
    if (mtoMandatoryField === null) {
      // manual supplier MTO required fields
      if (this.props.data.manualSupplier === true) {
        validation = validateStep(this.props.data, requiredFieldsManualSupplierMTP1);
      } else { // required field for normal MTO
        validation = validateStep(this.props.data, requiredFieldsMTP1);
        if (this.props.supplierTypes.length > 0 && (this.props.data.supplierTypeId <= 0
          || this.props.data.supplierTypeId == null)) {
          validation.isValid = false;
          validation.msgs.supplierTypeId = "Supplier type is required";
        }

        if (
          this.props.data.isVatRegistered
          && (!this.props.data.vatNumber || this.props.data.vatNumber.length === 0)
        ) {
          validation.isValid = false;
          validation.msgs.vatNumber = "Vat number required";
        }

        // eslint-disable-next-line no-bitwise
        if ((this.props.allowedCompanyFeatures & CompanyConfigurationFeatures.Contracting) > 0) {
          if ((this.props.data.contractingTierId == null
            || this.props.data.contractingTierId <= 0)) {
            validation.isValid = false;
            validation.msgs.contractingTierId = "Contracting tier is required";
          }
        }
      }
      // required field for both manual supplier and normal MTO
      if (this.props.businessUnits.length > 0 && (this.props.data.businessUnitIds == null
        || this.props.data.businessUnitIds.length <= 0)) {
        validation.isValid = false;
        validation.msgs.businessUnitIds = "Business unit is required";
      }

      if (this.state.categoryManagers.length > 0
        && (this.props.data.categoryManagerUserId == null)) {
        validation.isValid = false;
        validation.msgs.categoryManagerUserId = "Category manager is required";
      }

      if (this.state.paymentTerms?.length > 0 && this.props.data.paymentTermsId == null) {
        validation.isValid = false;
        validation.msgs.paymentTermsId = "Payment terms are required";
      }
    } else {
      // Handle mandatory MTO fields

      // registrationNumber, registeredName, tradingName, companyType
      validation = validateStep(
        this.props.data,
        requiredMandatoryFieldsStep1.filter((x) => isMandatory(mtoMandatoryField, x.field))
      );

      // Vat number is required if company is vat registered
      if (
        this.props.data.isVatRegistered
        && (!this.props.data.vatNumber || this.props.data.vatNumber.length === 0)
      ) {
        validation.isValid = false;
        validation.msgs.vatNumber = "Vat number is required";
      }

      // manual supplier
      if (isMandatory(mtoMandatoryField, MTOMandatoryField.ManualSupplier)
        && !this.props.data.manualSupplier) {
        validation.isValid = false;
        validation.msgs.manualSupplier = "Manual supplier is required";
      }

      // business units
      if (isMandatory(mtoMandatoryField, MTOMandatoryField.BusinessUnit)
        && (this.props.businessUnits.length > 0
        && (this.props.data.businessUnitIds == null
          || this.props.data.businessUnitIds.length <= 0))) {
        validation.isValid = false;
        validation.msgs.businessUnitIds = "Business unit is required";
      }

      // catergory manager
      if (isMandatory(mtoMandatoryField, MTOMandatoryField.CategoryManager)
        && (this.state.categoryManagers.length > 0
        && (this.props.data.categoryManagerUserId == null
          || this.props.data.categoryManagerUserId <= 0))) {
        validation.isValid = false;
        validation.msgs.categoryManagerUserId = "Category manager is required";
      }

      // supplier type
      if (isMandatory(mtoMandatoryField, MTOMandatoryField.SupplierType)
        && this.props.supplierTypes.length > 0
        && (this.props.data.supplierTypeId <= 0
          || this.props.data.supplierTypeId == null)) {
        validation.isValid = false;
        validation.msgs.supplierTypeId = "Supplier type is required";
      }

      // contracting tier
      if (isMandatory(mtoMandatoryField, MTOMandatoryField.ContractingTier)
        && this.props.contractTiers.length > 0
        && (this.props.data.contractingTierId == null
          || this.props.data.contractingTierId <= 0)) {
        validation.isValid = false;
        validation.msgs.contractingTierId = "Contracting tier is required";
      }

      // payment terms
      if (isMandatory(mtoMandatoryField, MTOMandatoryField.PaymentTerms)
        && this.props.paymentTerms.length > 0
        && (this.props.data.paymentTermsId == null || this.props.data.paymentTermsId <= 0)) {
        validation.isValid = false;
        validation.msgs.paymentTermsId = "Payment terms are required";
      }
    }

    this.setState({
      msgs: validation.msgs,
    });

    if (validation.isValid) {
      if (this.state.isStepValid) {
        const onboardingRequest = {
          ...this.props.data,
          lastStepCompleted:
            this.props.stepNumber > this.props.data.lastStepCompleted
              ? this.props.stepNumber
              : this.props.data.lastStepCompleted,
        };

        await this.performSave(onboardingRequest, false);

        // next step
        if (nextUrl.length > 0) {
          this.props.history.push(nextUrl);
        }
      }
    } else {
      window.scrollTo({ top: 0 });
      this.props.showInfoMsg(
        "This page contains missing information.",
        "To continue please amend/complete the following:",
        Object.keys(validation.msgs).map((key) => validation.msgs[key])
      );
    }
  };

  toggleConfirmSaveAndContinue = () => {
    this.setState((prevState) => ({
      showConfirmMsg: !prevState.showConfirmMsg,
    }));
  };

  saveAndContinueLater = async () => {
    const onboardingRequest = {
      ...this.props.data,
    };

    if (await this.performSave(onboardingRequest, true)) {
      this.props.history.length = 1;
      this.props.history.replace("/myoffice/onboardingrequest");
    }
  };

  async componentDidMount() {
    const catmans = await getCompanyCategoryManagers();
    this.setState({
      categoryManagers: catmans.categoryManagers,
      categoryManagerInitiator: catmans.categoryManagerInitiator,
    });

    // auto select categoryManagerUserId (if applicable)
    if (this.props.data.categoryManagerUserId == null && catmans.categoryManagerInitiator != null) {
      const setDefaultCategoryManagerEvent = {
        target: {
          name: "categoryManagerUserId",
          value: catmans.categoryManagerInitiator,
        },
      };
      this.props.onInputChange(setDefaultCategoryManagerEvent);
    }

    this.props.hideInfoMsg();
    await loadAddOnboardingRequestOptions(
      this.props.companyTypes,
      this.props.setOption
    );

    if (this.props.data.onboardingId !== 0) {
      // Populate vat exempted
      onCompanyTypeChangeHandler({ target: { value: this.props.data.companyTypeId } },
        this.props.companyTypes, this.props.onInputChange, false);
    }
  }

  toggleDuplicateModal = (duplicates = []) => {
    this.setState({
      showDuplicateModal: duplicates.length > 0,
      duplicates,
    });
  };

  onDuplicateResolve = (result) => {
    this.props.updateOnboardingRequest(result);
    this.props.history.push(
      `/myoffice/onboardingrequest/${result.onboardingId}/${result.manualSupplier ? "m-2" : "2"}`
    );
  };

  closeDuplicateModal = () => {
    this.setState({
      showDuplicateModal: false,
      duplicates: [],
    });
  };

  render() {
    return (
      <section id="isuppli-marketplace-companydetails">
        <StaticCompanyData
          optionsToLoad={[StaticCompanyDataTypes.supplierType,
            StaticCompanyDataTypes.businessUnit,
            StaticCompanyDataTypes.paymentTerms]}
        />
        {this.state.showConfirmMsg && (
          <ConfirmationModal
            size="large"
            heading="Save & Continue later"
            description="You are about to save your progress but you can come back at any
            time by to continue with the motivation to onboard."
            onProceedClick={this.saveAndContinueLater}
            proceedButtonText="OK"
            hasCancel
            onToggleModal={this.toggleConfirmSaveAndContinue}
          />
        )}

        {this.state.showDuplicateModal && (
          <DuplicateRequestModal
            currentRequest={this.props.data}
            duplicates={this.state.duplicates}
            onCloseCallback={this.closeDuplicateModal}
            onProceed={this.onDuplicateResolve}
          />
        )}

        <Loading />

        <InfoPopup />

        <OnboardingWizard
          isManualSupplier={this.props.data.manualSupplier}
          currentStep={this.props.stepNumber - 1}
          lastStepCompleted={this.props.data.lastStepCompleted}
          onClickSaveAndContinue={this.toggleConfirmSaveAndContinue}
          onClickNext={this.onNextClick}
          onboardingId={this.props.data.onboardingId}
        >
          <div className="padding-lr-10vw">
            {this.props.data?.suspensionReason
              && (
                <SuspensionMessageBanner suspensionReason={this.props.data?.suspensionReason} />
              )}
            <div className="row top-margin-90px">
              <div className="col-12 col-lg-4">
                <div>
                  <h3 className="margin-null">STEP 01</h3>
                </div>
                <div>
                  <h1>COMPANY INFORMATION</h1>
                </div>
              </div>
            </div>

            <div className="row" style={{ paddingTop: "36px" }}>
              <div className="col-12 col-lg-7">
                <div>
                  <Alert color="info">
                    Please fill in the required fields below. All fields are
                    mandatory unless stated otherwise.
                  </Alert>
                </div>
              </div>
            </div>
          </div>

          <div style={{ marginTop: "20px" }} />

          <CompanyDetails
            data={this.props.data}
            onInputChangeCallback={this.props.onInputChange}
            companyTypes={this.props.companyTypes}
            supplierCategoryManagers={this.state.categoryManagers}
            categoryManagerInitiator={this.state.categoryManagerInitiator}
            additional={this.state.msgs}
            businessUnits={this.props.businessUnits}
            setStepValid={(isStepValid) => this.setState({ isStepValid })}
          />
        </OnboardingWizard>
      </section>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDisatchToProps)(MTPCompanyDetails)
);
