import React, { Fragment, useState } from "react";
import {
  ModalPopup,
  Spinner,
} from "isuppli-react-components";

import { bulkEmailUpdate } from "../../../http/Onboarding/OnboardingApi";

import Styles from "./BulkEmailUpdate.module.scss";

const BulkEmailUpdate = ({
  onboardingRequestId,
  onCloseCallback,
  onUpdatedCallback,
}: {
  onboardingRequestId: number;
  onCloseCallback: () => void;
  onUpdatedCallback: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState<string>("");

  return (
    <Fragment>
      <ModalPopup
        onClose={() => onCloseCallback()}
        hasCancel
        heading="Update Email"
        subheading=""
        size="medium"
        buttons={[
          {
            key: "ok",
            label: "Update",
            onClick: () => {
              // regex to check if email is valid
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (!emailRegex.test(text)) {
                alert("Please enter a valid email address");
                return;
              }
              setLoading(true);
              bulkEmailUpdate(onboardingRequestId, text.trim())
                .then(() => {
                  onUpdatedCallback();
                })
                .catch((err) => {
                  console.log(err);
                })
                .finally(() => {
                  setLoading(false);
                });
            },
            color: "primary",
          },
        ]}
      >
        {loading && <Spinner />}
        {!loading
        && (
          <div className={Styles.Container}>
            <p className="mx-2">
              Update Email for suppliers
            </p>
            <div className="row pt-3 px-2">
              <div className="col-12">
                <div className="row py-3 px-4">
                  <div className="col-12">
                    <textarea
                      className={`${Styles.TextArea} w-100 p-2`}
                      value={text}
                      onChange={(ev) => setText(ev.target.value)}
                      placeholder="New Email"
                      rows={1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </ModalPopup>
    </Fragment>
  );
};

export default BulkEmailUpdate;
