/* eslint-disable no-unused-vars */
import axios from "axios";

import {
  NewIntegrationKeyMessage,
  IntegrationKeysMessage,
  OnboardingConfigurationMessage,
  CompanyInformationConfigurationMessage,
} from "./Models/Models";
import { makeRequestAsync } from "../util";

export * from "./Models/Models";

export async function getNewKey() {
  const url = "/api/enterprise/apiIntegrationKeys/";
  return makeRequestAsync(() => axios
    .post(url)) as Promise<NewIntegrationKeyMessage>;
}

export async function getKeys() {
  const url = "/api/enterprise/apiIntegrationKeys/";
  return makeRequestAsync(() => axios
    .get(url)) as Promise<IntegrationKeysMessage>;
}

export async function deleteKey(clientId: string) {
  const url = `/api/enterprise/apiIntegrationKeys/${clientId}`;
  return makeRequestAsync(() => axios
    .delete(url)) as Promise<void>;
}

export async function saveOnboardingConfiguration(message: OnboardingConfigurationMessage) {
  const url = "/api/enterprise/onboarding/";
  return makeRequestAsync(() => axios
    .post(url, message)) as Promise<void>;
}

export async function getOnboardingConfiguration() {
  const url = "/api/enterprise/onboarding/";
  return makeRequestAsync(() => axios
    .get(url)) as Promise<OnboardingConfigurationMessage>;
}

export async function getCompanyInformationConfiguration() {
  const url = "/api/enterprise/companyInformation/";
  return makeRequestAsync(() => axios
    .get(url)) as Promise<CompanyInformationConfigurationMessage>;
}

export async function saveCompanyInformationConfiguration(message: CompanyInformationConfigurationMessage) {
  const url = "/api/enterprise/companyInformation/";
  return makeRequestAsync(() => axios
    .post(url, message)) as Promise<void>;
}
