import React, { Fragment } from "react";
// eslint-disable-next-line no-unused-vars
import DataFeatures from "../../utils/enums/DataFeature";
import { setupAllowedDataFeatures } from "../../Hooks/useDataFeatureCheck";

const ISuppliDataAccess = (
  {
    allowedDataFeatures,
    children,
  }
    :
    {
      allowedDataFeatures: DataFeatures,
      children: React.ReactNode,
    }
) => {
  setupAllowedDataFeatures(allowedDataFeatures);

  return (
    <Fragment>
      {children}
    </Fragment>
  );
};

export default ISuppliDataAccess;
