import {
  Spinner,
  Form,
  // eslint-disable-next-line no-unused-vars
  FormRef,
  RadioButtonInput,
  WithFeature,
  SystemFeatures,
  UtilButton,
  useSystemFeatureCheck,
  ConfirmationModal,
} from "isuppli-react-components";
import React, {
  forwardRef,
  // eslint-disable-next-line no-unused-vars
  ForwardRefRenderFunction,
  Fragment, useEffect, useImperativeHandle, useRef, useState,
} from "react";
import { FormGroup } from "reactstrap";
import SupplierTypeConfig from "../../../../Containers/CompanyConfiguration/Onboarding/SupplierTypesConfig";
import SupplierImportModal from "../../../../Containers/SupplierImportModal/SupplierImportModal";

import useShowError from "../../../../Hooks/useShowError";
import { getOnboardingConfiguration, saveOnboardingConfiguration } from "../../../../http/EnterpriseConfiguration/enterpriseConfiguration";
import VendorNumberOrigin from "../../../../Util/Enumerators/vendorNumberOriginEnum";

export interface OnboardingTabRef {
  save: () => Promise<void>
}

export interface OnboardingTabProps {

}

const OnboardingTab: ForwardRefRenderFunction<OnboardingTabRef, OnboardingTabProps> = (
  _: OnboardingTabProps, ref
) => {
  const showError = useShowError();
  const featureCheck = useSystemFeatureCheck();
  const [loading, setLoading] = useState(true);
  const formRef = useRef<FormRef>(null);
  const [vendorNumberOrigin, setVendorNumberOrigin] = useState<VendorNumberOrigin>();
  const [isBankingDetailsRequired, setIsBankingDetailsRequired] = useState<boolean>();
  const [canSuppliersEditProductSegments, setCanSuppliersEditProductSegments] = useState<boolean>();
  const [showSupplierImportModal, setShowSupplierImportModal] = useState(false);
  const [showVendorNoConfigConfirmationModal, setShowVendorNoConfigConfirmationModal] = useState(false);

  useEffect(() => {
    const loader = async () => {
      setLoading(true);

      try {
        const apiData = await getOnboardingConfiguration();
        setVendorNumberOrigin(apiData.vendorNumberOrigin);
        setIsBankingDetailsRequired(apiData.isBankingDetailsRequired);
        setCanSuppliersEditProductSegments(apiData.supplierCanEditClientSpecificCategories);
      } catch (error) {
        showError();
      }

      setLoading(false);
    };
    loader();
  }, [showError, setLoading]);

  useImperativeHandle(ref, () => ({
    save: async () => {
      if (!(formRef.current?.isValid())) {
        formRef.current?.showValidation();
        return;
      }

      setLoading(true);
      try {
        await saveOnboardingConfiguration({
          vendorNumberOrigin: vendorNumberOrigin ?? VendorNumberOrigin.Autogenerated,
          isBankingDetailsRequired: isBankingDetailsRequired === true,
          supplierCanEditClientSpecificCategories: canSuppliersEditProductSegments === true,
        });
      } catch (error) {
        showError();
      }

      setLoading(false);
    },
  }));

  return (
    <Fragment>
      <h2 className="mt-5 text-uppercase">Onboarding</h2>
      {loading && <Spinner />}
      {
        showVendorNoConfigConfirmationModal
        && (
          <ConfirmationModal
            heading="Vendor number config"
            subHeading="Do you want to continue ?"
            proceedButtonText="Confirm"
            image="laptop"
            hasCancel
            onToggleModal={() => setShowVendorNoConfigConfirmationModal(false)}
            onProceedClick={() => setVendorNumberOrigin(VendorNumberOrigin.ManualAssignment)}
            description="It is important to note that once you save this configuration, it cannot be reverted.
          In order to adjust this at a later time, you will need to contact B1SA Support for configuration advice"
          />
        )
      }
      <Form ref={formRef}>
        <WithFeature feature={SystemFeatures.ConfigurationOnboardingVendorNumber} keepVisible>
          <FormGroup
            disabled={vendorNumberOrigin === VendorNumberOrigin.ManualAssignment}
            tag="fieldset"
            className="mt-5"
          >
            <legend>How are your suppliers&apos; vendor number&apos;s created?</legend>
            <RadioButtonInput
              checked={vendorNumberOrigin === VendorNumberOrigin.Autogenerated}
              onChange={() => { setVendorNumberOrigin(VendorNumberOrigin.Autogenerated); }}
              name="vendorNumberOrigin"
              label="Automatically assign the company's B1 LINK URN as my vendor number."
            />
            <RadioButtonInput
              checked={vendorNumberOrigin === VendorNumberOrigin.ERPIntegration}
              onChange={() => { setVendorNumberOrigin(VendorNumberOrigin.ERPIntegration); }}
              name="vendorNumberOrigin"
              label="I will provide my own ERP vendor number through integration."
            />
            <RadioButtonInput
              checked={vendorNumberOrigin === VendorNumberOrigin.ManualAssignment}
              onChange={() => { setShowVendorNoConfigConfirmationModal(true); }}
              name="vendorNumberOrigin"
              label="I will manually submit my own vendor number."
            />
          </FormGroup>
        </WithFeature>
        <WithFeature feature={SystemFeatures.ConfigurationOnboardingBankDetails} keepVisible>
          <FormGroup tag="fieldset" className="mt-5">
            <legend>Is banking details required for suppliers?</legend>
            <RadioButtonInput
              checked={isBankingDetailsRequired === true}
              onChange={() => { setIsBankingDetailsRequired(true); }}
              name="bankingRequired"
              label="Yes"
              inline
            />
            <RadioButtonInput
              checked={isBankingDetailsRequired === false}
              onChange={() => { setIsBankingDetailsRequired(false); }}
              name="bankingRequired"
              label="No"
              inline
            />
          </FormGroup>
        </WithFeature>
        <WithFeature feature={SystemFeatures.ConfigurationSupplierEditClientSpecificCategories} keepVisible>
          <FormGroup tag="fieldset" className="mt-5">
            <legend>Should suppliers be allowed to edit their MTO Company Products and Segments ?</legend>
            <RadioButtonInput
              checked={canSuppliersEditProductSegments === true}
              onChange={() => { setCanSuppliersEditProductSegments(true); }}
              name="supplierCanEditProductSegments"
              label="Yes"
              inline
            />
            <RadioButtonInput
              checked={canSuppliersEditProductSegments === false}
              onChange={() => { setCanSuppliersEditProductSegments(false); }}
              name="supplierCanEditProductSegments"
              label="No"
              inline
            />
          </FormGroup>
        </WithFeature>
      </Form>

      <UtilButton
        disabled={!featureCheck(SystemFeatures.SupplierImport)}
        feature={SystemFeatures.SupplierImport}
        className="my-4"
        color="primary"
        onClick={() => setShowSupplierImportModal(true)}
      >
        UPLOAD MY SUPPLIERS
      </UtilButton>
      <div>
        {
          showSupplierImportModal
          && (
            <SupplierImportModal
              onCloseModal={() => {
                setShowSupplierImportModal(false);
              }}
            />
          )
        }
      </div>
      <hr />
      <div className="my-4">
        <SupplierTypeConfig
          isBankingGloballyRequired={isBankingDetailsRequired === true}
        />
      </div>
    </Fragment>
  );
};
export default forwardRef<OnboardingTabRef, OnboardingTabProps>(OnboardingTab);
