// eslint-disable-next-line no-unused-vars
import React, { FormEvent, ChangeEvent } from "react";
import Styles from "./SearchInput.module.scss";
import { ReactComponent as SvgMagnifyingGlass } from "../../../images/magnifying-glass.svg";
import UtilButton from "../../UtilButton/UtilButton";
// eslint-disable-next-line no-unused-vars
import { ButtonInfo } from "../../../utils/interfaces/ButtonInfo";

const SearchInput = ({
  searchTerm,
  onSearch,
  onChange,
  searchPlaceholder,
  buttons = [],
  className,
}: {
  searchTerm: string,
  onSearch?: () => void,
  onChange?: (value: string) => void,
  searchPlaceholder: string,
  buttons?: ButtonInfo[],
  className?: string,
  }) => {
  const onInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    if (onChange != null) {
      onChange(value);
    }
  };

  const onSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (onSearch != null) {
      onSearch();
    }
  };

  const buttonComponents = buttons.map((button: ButtonInfo) => (
    <UtilButton
      key={button.key}
      color={button.color}
      location="bottom"
      onClick={button.onClick}
      size="small"
    >
      {button.label}
    </UtilButton>
  ));

  return (
    <div className={`${className}`}>
      <div className={`${Styles.SearchInput} ${Styles.ShadowCard}`}>
        <form className="w-100" onSubmit={onSubmit}>
          <SvgMagnifyingGlass />
          <input
            placeholder={searchPlaceholder}
            name="searchTerm"
            value={searchTerm}
            onChange={onInputChange}
          />
        </form>
      </div>
      <div className={`${Styles.SearchInputButtons}`}>
        {buttonComponents}
      </div>
    </div>
  );
};

export default SearchInput;
