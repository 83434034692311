import {
  PageHeading,
  Table,
  SearchInput,
  UtilButton,
  useRouteState,
  // eslint-disable-next-line no-unused-vars
  TableConfig,
  // eslint-disable-next-line no-unused-vars
  TableData,
} from "isuppli-react-components";
import React, {
  // eslint-disable-next-line no-unused-vars
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { Badge } from "reactstrap";
import { sendMyClientsSearch } from "../../http/posts";
import navMenuItems from "../../Util/menuItems";
import NavTabs from "./NavTabs";
import SupplierClientDetailsModal from "../../Containers/Supplier/SupplierClientDetailsModal";
import useOutstandingInformationLoader from "../../Hooks/useOutstandingInformationLoader";

interface MyClientsTableItem {
  id: number;
  registeredName: string;
  vendorNumber: string;
}

interface MyClientsSearchCriteria {
  limit: number;
  offset: number;
  registeredName?: string;
  sortingDesc: boolean;
}

interface MyClientsResultItem {
  companyId: number;
  registeredName: string;
  vendorNumber: string;
}

interface SearchResultMessage {
  totalItems: number;
  items: MyClientsResultItem[];
}

const MyClients = () => {
  const [showSupplierDetailsModal, setShowSupplierDetailsModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<MyClientsTableItem>();
  const [searchTerm, setSearchTerm] = useRouteState("q", "");
  const [currentSearchTerm, setCurrentSearchTerm] = useState(searchTerm);
  const [loading, setLoading] = useState(true);
  const [pageInfo, setPageInfo] = useRouteState("p", { l: 20, o: 0 });
  const [sortOption, setSortOption] = useRouteState("s", 0);
  const history = useHistory();
  const companyOutstandingInfo = useSelector((state: any) => (state.companyOutstandingInfo));

  const [searchResults, setSearchResults] = useState<{
    totalItems: number;
    items: MyClientsTableItem[];
  } | null>(null);

  useEffect(() => {
    setCurrentSearchTerm(searchTerm);
  }, [searchTerm]);

  const loadData = useCallback(
    async (searchInfo: {
      sortOption: number;
      searchTerm: string;
      pageInfo: { l: number; o: number };
    }) => {
      setLoading(true);
      const searchCriteria: MyClientsSearchCriteria = {
        sortingDesc: searchInfo.sortOption === 0,
        registeredName: searchInfo.searchTerm,
        limit: searchInfo.pageInfo.l,
        offset: searchInfo.pageInfo.o,
      };

      const results: SearchResultMessage = await sendMyClientsSearch(
        searchCriteria
      );

      // format results
      const formattedItems = results.items.map(
        (c) => ({
          id: c.companyId,
          registeredName: c.registeredName,
          vendorNumber: c.vendorNumber,
        } as MyClientsTableItem)
      );

      setSearchResults({
        totalItems: results.totalItems,
        items: formattedItems,
      });

      setLoading(false);
    },
    [setLoading]
  );

  useEffect(() => {
    loadData({
      sortOption,
      searchTerm,
      pageInfo,
    });
  }, [searchTerm, sortOption, pageInfo, loadData, history.location]);

  const reloadOutstandingInfo = useOutstandingInformationLoader();

  const toggleSupplierDetailsModal = () => {
    if (showSupplierDetailsModal) {
      reloadOutstandingInfo();
    }
    setShowSupplierDetailsModal(!showSupplierDetailsModal);
  };

  const tableConfig: TableConfig<number, MyClientsTableItem> = {
    columns: [
      {
        key: "name",
        heading: "Registered Name",
        size: "1fr",
        valueTemplate: (row) => (
          <p>
            {row.registeredName}
            {
              companyOutstandingInfo.outstandingBuyerIds.includes(row.id)
                ? <Badge color="danger" className="mx-2">Outstanding</Badge>
                : null
            }
          </p>
        ),
      },
      {
        key: "vendorNumber",
        heading: "Vendor Number",
        size: "1fr",
        valueTemplate: (row) => <span>{row.vendorNumber}</span>,
      },
      {
        key: "supDetails",
        heading: "Action",
        size: "auto",
        fullSize: true,
        align: "left",
        valueTemplate: (row) => (
          <button
            className="btn btn-link w-100 text-left text-capitalize"
            type="button"
            onClick={() => {
              setSelectedCompany(row);
              toggleSupplierDetailsModal();
            }}
          >
            Edit details
          </button>
        ),
      },
    ],
    sortOptions: {
      selectedSortOption: sortOption,
      options: [
        { value: 0, display: "Newest first" },
        { value: 1, display: "Oldest first" },
      ],
    },
  };

  const onSearchHandler = () => {
    setPageInfo({ l: pageInfo.l, o: 0 });
    setSearchTerm(currentSearchTerm);
  };

  const tableData: TableData<MyClientsTableItem> = {
    limit: pageInfo.l,
    offset: pageInfo.o,
    totalItems: searchResults?.totalItems ?? 0,
    items: searchResults?.items ?? [],
  };

  return (
    <section id="isuppli-supplier-info">
      {showSupplierDetailsModal ? (
        <SupplierClientDetailsModal
          onClose={toggleSupplierDetailsModal}
          selectedCompanyId={
            selectedCompany !== undefined ? selectedCompany.id : 0
          }
        />
      ) : null}

      <div className="padding-lr-10vw" style={{ paddingTop: "20px" }}>
        <PageHeading
          heading="MY CLIENTS"
          alertText="It is important to keep your information up to date. Not updating your information may result in your business being removed from the portal."
          menuItems={navMenuItems}
        />

        <NavTabs />

        <div className="mt-5" />
        <SearchInput
          searchTerm={currentSearchTerm}
          onSearch={onSearchHandler}
          onChange={setCurrentSearchTerm}
          searchPlaceholder="Search by company name"
        />
        <div className="justify-end mb-4">
          <UtilButton
            color="primary"
            location="bottom"
            onClick={onSearchHandler}
            size="small"
          >
            Search
          </UtilButton>
        </div>
        <Table
          loading={loading}
          tableConfig={tableConfig}
          tableStyle="basic"
          data={tableData}
          onPageChange={(l: number, o: number) => setPageInfo({ l, o })}
          onSortOptionChange={(newValue: number) => {
            setSortOption(newValue);
            setSearchTerm(currentSearchTerm);
          }}
        />
      </div>
    </section>
  );
};

export default MyClients;
