import React from "react";
import { dateTimeLabelFormat } from "../../Util/valueFormatter";
import { ReactComponent as CloseSvg } from "./close.svg";

function Notification(props) {
  return (
    <section
      className={`notification-message clickable pl-2 mt-2 ${props.isNew ? "new" : ""}`}
      onClick={props.onClick}
    >
      <div className="notification-message-content pt-2 pb-2">
        <div className="notification-message-datetime mb-2">{dateTimeLabelFormat(props.date)}</div>
        {
          props.isNew
            && <div className="notification-message-body text-primary">NEW</div>
        }
        <div>{props.message}</div>
      </div>
      <div className="notification-message-close pr-1 pt-1">
        <button
          type="button"
          alt="close"
          className="clickable btn btn-link text-decoration-none m-0 p-0"
          onClick={(event) => {
            event.stopPropagation();
            props.onCloseCallback();
          }}
        >
          <CloseSvg className="text-action" />
        </button>
      </div>
    </section>
  );
}

export default Notification;
