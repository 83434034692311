import React, { Fragment, useEffect, useRef } from "react";

import OptionSelector from "../../Controls/OptionSelector";
import DropdownInput from "../../Controls/DropdownInput";
import { StaticDataTypes } from "../../Util/Enumerators/staticDataTypes";
import useStaticData, { staticOption } from "../../Hooks/useStaticData"; // eslint-disable-line no-unused-vars

export interface Event {
  target: {
    name: string,
    value: any
  },
}

export interface TurnoverBand extends staticOption {
  vatExempted: boolean
}

const SectorTurnoverInfo = (
  {
    sector,
    turnoverBand,
    readOnly,
    onInputChange,
    onVatExemptedChange,
    additional,
  }
  :
  {
    sector?: number,
    turnoverBand?: number,
    readOnly: boolean,
    onInputChange: (event: Event) => void,
    onVatExemptedChange: (vatExempted: boolean) => void,
    additional: { sector: string, turnoverBand: string},
  }
) => {
  const [[
    sectorTurnoverBands,
    turnoverBands,
    sectors,
    beeClassifications,
  ], staticDataReady] = useStaticData([
    StaticDataTypes.SectorTurnoverBand,
    StaticDataTypes.TurnoverBand,
    StaticDataTypes.Sector,
    StaticDataTypes.BeeClassification,
  ]);

  const onTurnoverChangeHandler = (selectedBand: TurnoverBand) => {
    onVatExemptedChange(selectedBand.vatExempted);
  };

  const onVatExemptedChangeReference = useRef(onVatExemptedChange);

  useEffect(() => {
    onVatExemptedChangeReference.current = onVatExemptedChange;
  }, [onVatExemptedChange]);

  useEffect(() => {
    if (staticDataReady) {
      const selectedItems = turnoverBands.filter(
        (c) => c.value === turnoverBand
      );

      if (selectedItems.length === 1) {
        onVatExemptedChangeReference.current(selectedItems[0].vatExempted);
      }
    }
  }, [staticDataReady, turnoverBand, turnoverBands]);

  let annualTurnover = null;

  if (staticDataReady) {
    const currentSectorTurnoverBandIds = sectorTurnoverBands
      .filter((entry) => entry.sectorId === sector)
      .map((entry) => entry.turnoverBandId);

    const filteredTurnoverBands = turnoverBands.filter(
      (entry) => currentSectorTurnoverBandIds.includes(entry.value)
    ).map((entry) => ({
      ...entry,
      heading: beeClassifications
        .find((beeClassification) => beeClassification.id === (sectorTurnoverBands
          .find((stb) => stb.turnoverBandId === entry.id))?.beeClassificationId)?.display,
    }));

    annualTurnover = !sector ? (
      <span>Make a sector selection</span>
    ) : (
      <OptionSelector
        name="turnoverBand"
        label="Annual Turnover"
        options={filteredTurnoverBands}
        value={turnoverBand}
        additional={additional.turnoverBand}
        disabled={readOnly}
        onChangeCallback={(ev: Event) => {
          onInputChange(ev);

          const selectedTurnoverBand = turnoverBands.find(
            (c) => c.value === ev.target.value
          );

          if (selectedTurnoverBand != null) {
            onTurnoverChangeHandler(selectedTurnoverBand as TurnoverBand);
          }

          // set bee classification based on turn over band selection
          const selectedSectorTurnoverBand = sectorTurnoverBands
            .find((sectorTurnoverBand) => sectorTurnoverBand.sectorId === sector
              && sectorTurnoverBand.turnoverBandId === selectedTurnoverBand?.value);

          if (selectedSectorTurnoverBand != null) {
            const beeClassification = selectedSectorTurnoverBand.beeClassificationId;
            onInputChange({
              target: {
                name: "beeClassificationId",
                value: beeClassification,
              },
            });
          }
        }}
      />
    );
  }

  return (
    <Fragment>
      <div>
        <h2 className="Main">SECTOR INFORMATION</h2>
      </div>
      <section
        id="isuppli-registration-company-info"
        className="top-margin-30px"
      >
        <div className="row">
          <div className="col-12 col-md-5">
            <DropdownInput
              label="B-BBEE Sector"
              name="sector"
              value={sector ?? -1}
              options={sectors ?? []}
              additional={additional.sector}
              onChangeCallback={onInputChange}
              disabled={readOnly}
            />
          </div>
        </div>

        {annualTurnover}

      </section>
    </Fragment>
  );
};

export default SectorTurnoverInfo;
