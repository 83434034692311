import React from "react";

const SuspensionMessageBanner = ({
  suspensionReason = "",
}
:
{
  suspensionReason: string
}) => (
  <>
    <div className="col-12 col-lg-12 text-center alert alert-danger">
      <p>This onboarding request was suspended. Below is the reason:</p>
      <strong><p>{suspensionReason}</p></strong>
      <p>Please address the issues raised above and resubmit the request.</p>
    </div>
  </>
);

export default SuspensionMessageBanner;
