import React, {
  // eslint-disable-next-line no-unused-vars
  forwardRef, ForwardRefRenderFunction, Fragment, useCallback, useEffect, useImperativeHandle, useState,
} from "react";
import {
  getStaticValue, isMandatory, MTOMandatoryField, Spinner, useToast,
} from "isuppli-react-components";
import useStaticData from "../../../Hooks/useStaticData";
import { StaticDataTypes } from "../../../Util/Enumerators/staticDataTypes";
import { getDocumentsAsync } from "../../../http";
import DocumentUploader from "../../Supplier/DocumentUploader";
import { Document } from "../../../http/ManualSupplier/Models/Document";// eslint-disable-line no-unused-vars
import useShowError from "../../../Hooks/useShowError";
import { DocumentSummaryMessage } from "../../../http/ManualSupplier/Messages/DocumentSummaryMessage";// eslint-disable-line no-unused-vars
import { saveSupplierDocuments } from "../../../http/ManualSupplier/manualSupplierApi";
import { ValidationModel } from "../../../Sections/Marketplace/MTPManualSupplierStep/MTPManualSupplierStep";// eslint-disable-line no-unused-vars

export interface SupportingDocumentsModuleRef{
  save: () => Promise<boolean>;
  refreshDocuments: () => void;
}

export interface SupportingDocumentsModuleProps{
  companyId: number
  containerClass?:string,
  readOnly?: boolean,
  mtoMandatoryField?: number,
  // triggers a refersh of the required supporting documents based
}
const SupportingDocumentsModule:

ForwardRefRenderFunction<SupportingDocumentsModuleRef, SupportingDocumentsModuleProps> = (
  {
    companyId = 0, containerClass = "", readOnly = false, mtoMandatoryField = 0,
  }:
  SupportingDocumentsModuleProps, ref
) => {
  const [[documentTypes], isReady] = useStaticData([StaticDataTypes.DocumentTypes]);
  const [documents, setDocuments] = useState<Array<Document>>([] as Array<Document>);
  const [loading, setLoading] = useState(false);
  const showError = useShowError();
  const showToast = useToast();

  const loadSupportingDocuments = useCallback(
    async () => {
      const res = await getDocumentsAsync(companyId, true);
      setDocuments(res.companyDocuments as Array<Document>);
    },
    [companyId]
  );

  useEffect(() => {
    const loader = async () => {
      await loadSupportingDocuments();
    };

    try {
      setLoading(true);
      loader();
      setLoading(false);
    } catch {
      showError();
      setLoading(false);
    }
  }, [companyId, documentTypes, showError, loadSupportingDocuments]);

  const updateDocuments = (docs : Array<Document>) => {
    setDocuments(docs);
  };

  const onSaveDocuments = async () => {
    setLoading(true);
    const documentSummary : DocumentSummaryMessage = {
      companyId,
      companyDocuments: documents.filter((doc) => doc.filename != null),
    };
    try {
      await saveSupplierDocuments(documentSummary);
      setLoading(false);
      showToast("Supporting documents saved");
      return true;
    } catch {
      setLoading(false);
      return false;
    }
  };

  // save changes
  const stepValidation = () => {
    const result: ValidationModel = {
      isStepValid: true,
      msgs: {},
    };
    if (mtoMandatoryField !== null) {
      if (isMandatory(mtoMandatoryField, MTOMandatoryField.SupportingDocument)) {
        documents.forEach((document) => {
          if (document.filename === null && !document.optional) {
            result.isStepValid = false;
            result.msgs[`documentType${document?.documentTypeId}`] = `${getStaticValue(document.documentTypeId, documentTypes, document.filename)} document is required`;
          }
        });
      }
    }
    return result;
  };

  useImperativeHandle(ref, () => ({
    save: onSaveDocuments,
    refreshDocuments: loadSupportingDocuments,
    validateStep: stepValidation,
  }));

  return (

    <Fragment>
      {loading && <Spinner />}
      <div className={containerClass}>
        <DocumentUploader
          documentTypes={isReady ? documentTypes : []}
          uploadUrl="api/manualSupplier/"
          downloadUrl="api/documents/"
          documents={documents ?? []}
          manualSupplierId={companyId}
          onDocumentsChanges={updateDocuments}
          readOnly={readOnly}
        />
      </div>
    </Fragment>
  );
};

export default forwardRef<SupportingDocumentsModuleRef, SupportingDocumentsModuleProps>(SupportingDocumentsModule);
