import { ShadowCard, Spinner } from "isuppli-react-components";
import React, { Fragment, useEffect, useState } from "react";
import useShowError from "../../../Hooks/useShowError";
import { getDashboardComplianceSummary } from "../../../http/Dashboard/dashboardApi";
// eslint-disable-next-line no-unused-vars
import { DashboardRegulatoryComplianceSummaryMessage } from "../../../http/Dashboard/Models/Models";
// eslint-disable-next-line no-unused-vars
import DashboardSummaryCard, { DashboardSummaryItem } from "../DashboardSummaryItem/DashboardSummaryItem";
import {
  ComplianceCheckType,
  ComplianceCheckStatus,
} from "../../../Util/Enumerators/ComplianceCheck";

const SupplierRegulatoryComplianceSummary = () => {
  const [complianceSummaryData,
    setComplianceSummaryData] = useState<DashboardRegulatoryComplianceSummaryMessage>();
  const showError = useShowError();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loader = async () => {
      setLoading(true);
      try {
        // api calls
        const dashboardComplianceSummaryData = await getDashboardComplianceSummary();
        setComplianceSummaryData(dashboardComplianceSummaryData);
      } catch (error) {
        showError();
      }
      setLoading(false);
    };
    loader();
  }, [showError]);

  const cipcComplianceItems = [
    {
      title: "Valid",
      key: "cipcvalid",
      value: complianceSummaryData?.cipcValid,
      linkUrl: `/marketplace/search?f=[[],[],[],[],[],[],null,null,null,null,null,null,null,null,null,${ComplianceCheckType.CIPC},${ComplianceCheckStatus.Valid}]&sf=1`,
    },
    {
      title: "Invalid",
      key: "cipcinvalid",
      value: complianceSummaryData?.cipcInvalid,
      linkUrl: `/marketplace/search?f=[[],[],[],[],[],[],null,null,null,null,null,null,null,null,null,${ComplianceCheckType.CIPC},${ComplianceCheckStatus.Invalid}]&sf=1`,
    },
    {
      title: "Pending",
      key: "cipcpending",
      value: complianceSummaryData?.cipcPending,
      linkUrl: `/marketplace/search?f=[[],[],[],[],[],[],null,null,null,null,null,null,null,null,null,${ComplianceCheckType.CIPC},${ComplianceCheckStatus.Pending}]&sf=1`,
    },
    {
      title: "N/A",
      key: "cipcna",
      value: complianceSummaryData?.cipcna,
      linkUrl: `/marketplace/search?f=[[],[],[],[],[],[],null,null,null,null,null,null,null,null,null,${ComplianceCheckType.CIPC},${ComplianceCheckStatus.NA}]&sf=1`,
    },
  ] as DashboardSummaryItem[];

  const sarsComplianceItems = [
    {
      title: "Valid",
      key: "sarsvalid",
      value: complianceSummaryData?.sarsValid,
      linkUrl: `/marketplace/search?f=[[],[],[],[],[],[],null,null,null,null,null,null,null,null,null,${ComplianceCheckType.SARS},${ComplianceCheckStatus.Valid}]&sf=1`,
    },
    {
      title: "Invalid",
      key: "sarsinvalid",
      value: complianceSummaryData?.sarsInvalid,
      linkUrl: `/marketplace/search?f=[[],[],[],[],[],[],null,null,null,null,null,null,null,null,null,${ComplianceCheckType.SARS},${ComplianceCheckStatus.Invalid}]&sf=1`,
    },
    {
      title: "Pending",
      key: "sarspending",
      value: complianceSummaryData?.sarsPending,
      linkUrl: `/marketplace/search?f=[[],[],[],[],[],[],null,null,null,null,null,null,null,null,null,${ComplianceCheckType.SARS},${ComplianceCheckStatus.Pending}]&sf=1`,
    },
    {
      title: "N/A",
      key: "sarsna",
      value: complianceSummaryData?.sarsna,
      linkUrl: `/marketplace/search?f=[[],[],[],[],[],[],null,null,null,null,null,null,null,null,null,${ComplianceCheckType.SARS},${ComplianceCheckStatus.NA}]&sf=1`,
    },
  ] as DashboardSummaryItem[];

  return (
    <Fragment>
      {/* Regulatory */}
      <div className="row mt-4">
        <div className="col-12">
          <h2>MY SUPPLIERS BY REGULATORY COMPLIANCE</h2>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12">
          <ShadowCard>

            {loading ? <Spinner inline /> : (
              <div className="row">
                <DashboardSummaryCard
                  heading="CIPC"
                  className="col-lg-12"
                  summaryItems={cipcComplianceItems}
                  borderBottom
                />
                <DashboardSummaryCard
                  heading="SARS"
                  className="col-lg-12"
                  summaryItems={sarsComplianceItems}
                />
              </div>
            )}

          </ShadowCard>
        </div>
      </div>
    </Fragment>
  );
};

export default SupplierRegulatoryComplianceSummary;
