enum BeeCertificateType {
  /* eslint-disable no-unused-vars */
  None = 0,
  Affidavit = 1,
  Certificate = 2,
  BasedOnBlackOwnership = 3
  /* eslint-enable no-unused-vars */
}

export default BeeCertificateType;
