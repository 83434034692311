import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import {
  // eslint-disable-next-line no-unused-vars
  ButtonInfo, ConfirmationModal, ModalPopup,
} from "isuppli-react-components";

import {
  TabContent, TabPane, Nav, NavItem, NavLink,
} from "reactstrap";
import classnames from "classnames";
import AddressDetailsModule, { AddressDetailsModuleRef } from "./AddressDetailsModule"; // eslint-disable-line no-unused-vars
import BankDetailsModule, { BankDetailsModuleRef } from "./BankDetailsModule";// eslint-disable-line no-unused-vars
import BeeDetailsModule, { BeeDetailsModuleRef } from "./BeeDetailsModule";// eslint-disable-line no-unused-vars
import useShowError from "../../../Hooks/useShowError";
import SupportingDocumentsModule, { SupportingDocumentsModuleRef } from "./SupportingDocumentsModule";// eslint-disable-line no-unused-vars
import ProductSegmentsModule, { ProductSegmentsModuleRef } from "./ProductSegmentsModule"; // eslint-disable-line no-unused-vars
import CompanyDetailsModule, { CompanyDetailsModuleRef } from "./CompanyDetailsModule"; // eslint-disable-line no-unused-vars
import { getSupplierInfoAsync } from "../../../http";

interface TabConfig {
  tabIndex :number,
  tabTitle :string,
  tabRef?: React.RefObject<any>,
  children?: React.ReactNode
}

interface TabComponentRef{
  save: () => Promise<boolean>
}

const EditManualSupplier = (
  {
    companyId = 0,
    onCloseCallBack,
  }
  :
  {
    companyId : number,
    onCloseCallBack : () => {},
  }
) => {
  // hooks & states
  const showError = useShowError();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isSubAccount, setIsSubAccount] = useState(false);

  useEffect(() => {
    const loader = async () => {
      const supplierDetails = await getSupplierInfoAsync(companyId);
      setIsSubAccount(supplierDetails.parentAccountId != null);
    };
    loader();
  }, [companyId]);

  // tab references
  const companyDetailsModuleRef = useRef<CompanyDetailsModuleRef>(null);
  const addressDetailModuleRef = useRef<AddressDetailsModuleRef>(null);
  const beeDetailsModuleRef = useRef<BeeDetailsModuleRef>(null);
  const bankDetailsModuleRef = useRef<BankDetailsModuleRef>(null);
  const supportingDocumentsRef = useRef<SupportingDocumentsModuleRef>(null);
  const productSegmentsRef = useRef<ProductSegmentsModuleRef>(null);
  const tabsConfig : Array<TabConfig> = [
    {
      tabIndex: 1,
      tabTitle: "COMPANY INFORMATION",
      tabRef: companyDetailsModuleRef,
      children: <CompanyDetailsModule
        companyId={companyId}
        ref={companyDetailsModuleRef}
        readOnly={isSubAccount}
        isSubAccount={isSubAccount}
      />,
    },
    {
      tabIndex: 2,
      tabTitle: "ADDRESS & CONTACT DETAILS",
      tabRef: addressDetailModuleRef,
      children: <AddressDetailsModule
        companyId={companyId}
        ref={addressDetailModuleRef}
        containerClass="px-5 pb-5"
        readOnly={isSubAccount}
      />,
    },
    {
      tabIndex: 3,
      tabTitle: "B-BBEEE PRACTICES & CERTIFICATION",
      tabRef: beeDetailsModuleRef,
      children: <BeeDetailsModule
        companyId={companyId}
        ref={beeDetailsModuleRef}
        containerClass="pb-5 padding-lr-10vw"
        readOnly={isSubAccount}
      />,
    },
    {
      tabIndex: 4,
      tabTitle: "BANK DETAILS",
      tabRef: bankDetailsModuleRef,
      children: <BankDetailsModule
        companyId={companyId}
        ref={bankDetailsModuleRef}
        containerClass="p-5"
        readOnly={isSubAccount}
      />,
    },
    {
      tabIndex: 5,
      tabTitle: "SUPPORTING DOCUMENTS & AGREEMENTS",
      tabRef: supportingDocumentsRef,
      children: <SupportingDocumentsModule
        companyId={companyId}
        ref={supportingDocumentsRef}
        containerClass="p-5"
        readOnly={isSubAccount}
      />,
    },
    {
      tabIndex: 6,
      tabTitle: "PRODUCTS SEGMENTS & FAMILIES",
      tabRef: productSegmentsRef,
      children: <ProductSegmentsModule
        companyId={companyId}
        ref={productSegmentsRef}
        readOnly={isSubAccount}
      />,
    },
  ];

  // tab controls
  const [activeTab, setActiveTab] = useState(1);
  const tabRefInit = tabsConfig[0].tabRef as React.RefObject<TabComponentRef>;
  const [currentTabRef, setCurrentTabRef] = useState<React.RefObject<TabComponentRef>>(tabRefInit);

  const toggle = (tab : number) => {
    if (activeTab !== tab) {
      const selectedTabRef = tabsConfig.find((t) => t.tabIndex === tab)?.tabRef;
      setActiveTab(tab);
      if (selectedTabRef != null) {
        setCurrentTabRef(selectedTabRef);
      } else {
        showError(); // if tab has no reference
      }
    }
  };

  const onPeformSave = async () => {
    const savedSuccessfully = await currentTabRef?.current?.save();
    supportingDocumentsRef.current?.refreshDocuments();
    if (!savedSuccessfully) { showError(); }
  };

  const onSaveButtonClick = {
    key: "save",
    label: "Save",
    color: "primary",
    onClick: () => { setShowConfirmationModal(true); },
  } as ButtonInfo;

  return (
    <ModalPopup hasCancel onClose={onCloseCallBack} buttons={[onSaveButtonClick]}>
      {showConfirmationModal && (
        <ConfirmationModal
          size="large"
          heading="Save Details"
          description="You are about to save the changes you have made to this manual supplier.
          Do you wish to persist ?"
          onProceedClick={onPeformSave}
          proceedButtonText="OK"
          hasCancel
          onToggleModal={() => { setShowConfirmationModal((prevState) => !prevState); }}
        />
      )}
      <Nav tabs>
        {
          tabsConfig.map((c, index) => (
            <NavItem key={index}>
              <NavLink
                className={classnames({ active: activeTab === c.tabIndex })}
                onClick={() => { toggle(c.tabIndex); }}
              >
                {c.tabTitle}
              </NavLink>
            </NavItem>
          ))
        }
      </Nav>
      <TabContent activeTab={activeTab}>
        {
          tabsConfig.map((c, index) => (
            <TabPane tabId={c.tabIndex} key={index}>
              {c.children}
            </TabPane>
          ))
        }
      </TabContent>
    </ModalPopup>
  );
};

export default EditManualSupplier;
