import React from "react";
import Styles from "./ProgressButton.module.scss";
import { ReactComponent as SvgProgressCircle } from "./progress-circle-threequarter.svg";

function ProgressButton(
  {
    stepName,
    stepIndex,
    totalSteps,
    active,
    inProgress,
    onClick,
    disabled,
  }
  :
  {
    stepName: string,
    stepIndex: number,
    totalSteps: number,
    active: boolean,
    inProgress: boolean,
    onClick: () => void,
    disabled: boolean,
  }
) {
  return (
    <button onClick={onClick} type="button" className={`${Styles.ProgressButton}`} disabled={disabled}>
      {inProgress && (
        <div className={Styles.StepCircleBorder}>
          <SvgProgressCircle className="text-secondary" />
        </div>
      )}

      <div className="desktop-version d-flex flex-column align-items-center text-uppercase">
        <div className={Styles.StepCircle}>
          {stepIndex + 1}
        </div>

        <div className={`${Styles.StepLabel} d-flex flex-column mt-4 ${active ? Styles.StepLabelActive : ""}`}>
          <span className={Styles.StepNumber}>
            {`STEP ${stepIndex + 1}`}
          </span>

          <span className={Styles.StepDescription}>
            {stepName}
          </span>
        </div>
      </div>

      <div className="mobile-version">
        <div className={Styles.StepCircle}>
          {`${stepIndex + 1} of ${totalSteps}`}
        </div>
      </div>
    </button>
  );
}

export default ProgressButton;
