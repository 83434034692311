import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert } from "reactstrap";

import { withRouter } from "react-router-dom";

import RegistrationProgress from "./RegistrationProgress";
import Loading from "../Loading";

import BankingDetails from "../../Containers/Supplier/BankingDetails";
import InfoPopup from "../../Containers/InfoPopup";
import SaveAndContinueConfirm from "../../Containers/Supplier/SaveAndContinueConfirm";
import WizzardBtns from "../../Containers/WizzardBtns";

import { saveBankingDetails } from "../../http/posts";

import { requiredFieldsStep5, getRequiredBankAccountFields } from "../../Validation/validation";
import validateStep from "../../Validation/onSubmitValidations";

import { setOption } from "../../Store/optionActions";
import {
  setRegistrationObject,
  onRegistrationInputChange,
} from "../../Store/registrationActions";
import {
  logOut,
  showLoadingScreen,
  hideLoadingScreen,
  hideInfoPopup,
  showInfoPopup,
  setLastStepCompleted,
} from "../../Store/actions";

import { loadBankingDetailsData } from "../../Util/dataLoader";
import { loadBankingDetailOptions } from "../../Util/optionsLoader";

const mapStateToProps = (state) => ({
  data: {
    ...state.registration.bankDetails,
    companyId: state.currentCompanyId,
  },
  bankAccountTypes: state.options.bankAccountTypes,
  banks: state.options.banks,
});

const mapDispatchToProps = (dispatch) => ({
  onInputChange: (ev) => {
    dispatch(onRegistrationInputChange("bankDetails", ev));
  },
  setBankingDetails: (bankDetails) => {
    dispatch(setRegistrationObject("bankDetails", bankDetails));
  },
  setOption: (optionName, optionValue) => {
    dispatch(setOption(optionName, optionValue));
  },
  showLoadingScreen: (msg) => {
    dispatch(showLoadingScreen(msg));
  },
  hideLoadingScreen: () => {
    dispatch(hideLoadingScreen());
  },
  showInfoMsg: (heading, subheading, msgs) => {
    dispatch(showInfoPopup(heading, subheading, msgs));
  },
  hideInfoMsg: () => {
    dispatch(hideInfoPopup());
  },
  setLastStepCompleted: (value) => {
    dispatch(setLastStepCompleted(value));
  },
  logOut: () => {
    dispatch(logOut());
  },
});

class RegistrationStep05 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msgs: [],
      showConfirmMsg: false,
    };
  }

  performSave = (onSuccess = null, stepNumber) => {
    this.props.showLoadingScreen();
    saveBankingDetails(
      {
        ...this.props.data,
        registrationStepCompleted:
          this.props.data.registrationStepCompleted > stepNumber
            ? this.props.data.registrationStepCompleted
            : stepNumber,
      },
      () => {
        if (onSuccess) {
          onSuccess();
        }
        this.props.hideInfoMsg();
      },
      () => {
        this.props.hideLoadingScreen();
      }
    );
  };

  onNextClick = (arg) => {
    const validation = validateStep(this.props.data, requiredFieldsStep5);
    if (validation.isValid) {
      this.props.data.bankAccounts.forEach((account, index) => {
        const requiredFields = getRequiredBankAccountFields(this.props.data.useCustomFields, index);
        const validate = validateStep(account, requiredFields);

        if (!validate.isValid) {
          const messages = {};
          // make each message unique for the specific bank account
          Object.keys(validate.msgs)
            .forEach((a) => {
              messages[`${a}${index}`] = validate.msgs[a];
            });

          validation.isValid = false;
          validation.msgs = {
            ...validation.msgs,
            ...messages,
          };
        }
      });
    }
    this.setState({
      msgs: validation.msgs,
    });
    if (validation.isValid) {
      this.performSave(() => {
        this.props.history.push(arg);
        this.props.hideLoadingScreen();
      }, 5);
    } else {
      window.scrollTo({ top: 0 });
      this.props.showInfoMsg(
        "This page contains missing information.",
        "To continue please amend/complete the following:",
        Object.keys(validation.msgs).map((key) => validation.msgs[key])
      );
    }
  };

  toggleConfirmSaveAndContinue = () => {
    this.setState((prevState) => ({
      showConfirmMsg: !prevState.showConfirmMsg,
    }));
  };

  saveAndContinueLater = () => {
    this.performSave(() => {
      this.props.logOut();
      this.props.hideLoadingScreen();
    }, 4);
  };

  componentDidMount() {
    loadBankingDetailOptions(this.props);
    loadBankingDetailsData(this.props);
  }

  render() {
    return (
      <section id="isuppli-registration-step-05">
        <Loading />

        <InfoPopup />

        {this.state.showConfirmMsg && (
          <SaveAndContinueConfirm
            toggleModalCallback={this.toggleConfirmSaveAndContinue}
            saveAndContinueCallback={this.saveAndContinueLater}
          />
        )}

        <RegistrationProgress currentStep={5} />

        <div className="padding-lr-10vw">
          <div className="row top-margin-90px">
            <div className="col-12 col-lg-7">
              <div>
                <h3 className="margin-null">STEP 05</h3>
              </div>
              <div>
                <h1>BANK DETAILS</h1>
              </div>
            </div>
            <div
              className="col-12 col-lg-5 justify-center flex-dir-column"
              style={{ paddingTop: "20px" }}
            >
              <h3>
                We need a little bit more information about your business, to
                ensure we know what your company has to offer.
              </h3>
            </div>
          </div>
          <div className="row" style={{ paddingTop: "36px" }}>
            <div className="col-12 col-lg-9">
              <div>
                <Alert color="info">
                  Your banking information will only be visible to the relevant users
                  within the companies you have been onboarded to via B1LINK. These users
                  cannot edit your information in any way. If you would like further
                  information regarding the security of your banking information,
                  please contact
                  {" "}
                  <u>support@b1link.co.za</u>
                  {" "}
                  or contact 011 455 0033
                </Alert>
              </div>
            </div>
          </div>
          <div className="top-margin-30px">&nbsp;</div>

          <BankingDetails
            data={this.props.data}
            additional={this.state.msgs}
            onInputChangeCallback={this.props.onInputChange}
          />
        </div>

        <WizzardBtns
          history={this.props.history}
          backUrl="/registration/prdinfo"
          nextUrl="/registration/documents"
          nextText="SUPPORTING DOCUMENTS & AGREEMENTS"
          onClickSaveAndContinue={this.toggleConfirmSaveAndContinue}
          onClickNext={this.onNextClick}
        />

        <div style={{ paddingTop: "44px" }} />
      </section>
    );
  }
}

const mappedRegistrationStep05 = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationStep05);
export default withRouter(mappedRegistrationStep05);
