import { BasicTable } from "isuppli-react-components";
import React from "react";
import useStaticData from "../../Hooks/useStaticData";
import { StaticDataTypes } from "../../Util/Enumerators/staticDataTypes";
import { getStaticValue } from "../../Util/valueFormatter";

function KeyContactsTable({ keyContacts }) {
  const [[contactPersonTypes], isReady] = useStaticData([StaticDataTypes.ContactPerson]);

  return (
    <BasicTable
      loading={!isReady}
      columns={[
        {
          key: "Designation",
          text: "Designation",
          align: "left",
        },
        {
          key: "Name",
          text: "Name",
          align: "left",
        },
        {
          key: "ID Number",
          text: "ID Number",
          align: "left",
        },
        {
          key: "Tel. Number",
          text: "Tel. Number",
          align: "left",
        },
        {
          key: "Email Address",
          text: "Email Address",
          align: "left",
        },
      ]}
      rows={!isReady ? [] : keyContacts.map((contactPerson) => {
        const id = contactPerson.contactPersonTypeId;
        return [
          {
            key: `${id}_designation`,
            value: getStaticValue(contactPerson.contactPersonTypeId, contactPersonTypes),
          },
          {
            key: `${id}_name`,
            value: `${contactPerson?.fullName ?? ""}`,
          },
          {
            key: `${id}_id`,
            value: `${contactPerson?.idNumber ?? ""}`,
          },
          {
            key: `${id}_tel`,
            value: `${contactPerson?.contactNumber ?? ""}`,
          },
          {
            key: `${id}_email`,
            valueTemplate: () => (
              <a href={`mailto:${contactPerson?.email ?? ""}`}>{contactPerson?.email ?? ""}</a>
            ),
          },
        ];
      })}
      emptyMessage="No contact persons"
    />
  );
}

export default KeyContactsTable;
