import React from "react";
import { useSystemFeatureCheck } from "../../Hooks/useSystemFeatureCheck";
import { useShowNotAllowedError } from "../../Hooks/useNotAllowedError";
// eslint-disable-next-line no-unused-vars
import SystemFeatures from "../../utils/enums/SystemFeatures";
import Styles from "./WithFeature.module.scss";

const WithFeature = (
  {
    children,
    feature,
    keepVisible = false,
  } :
  {
    children: JSX.Element,
    feature: SystemFeatures
    keepVisible?: boolean
  }
) => {
  const featureCheck = useSystemFeatureCheck();
  const showNotAllowedError = useShowNotAllowedError();

  if (featureCheck(feature)) {
    return children;
  }

  if (keepVisible) {
    return (
      <div
        aria-hidden="true"
        className={Styles.Overlay}
        onClick={(e) => {
          e.preventDefault();
          showNotAllowedError(feature);
        }}
      >
        { children }
      </div>
    );
  }

  return null;
};
export default WithFeature;
