import { isMandatory, MTOMandatoryField, Spinner } from "isuppli-react-components";
import React, {
  forwardRef,
  // eslint-disable-next-line no-unused-vars
  ForwardRefRenderFunction, Fragment, useEffect, useImperativeHandle, useState,
} from "react";
import useShowError from "../../../Hooks/useShowError";
import useStaticData from "../../../Hooks/useStaticData";
import { getDocumentsAsync } from "../../../http";
import BeeCertificateType from "../../../Util/Enumerators/BeeCertificateType";

import DocumentClassification from "../../../Util/Enumerators/DocumentClassification";
import { StaticDataTypes } from "../../../Util/Enumerators/staticDataTypes";
import BeeCertification from "../../Supplier/BeeCertification";
import BeeStatus from "../../Supplier/BeeStatus";
import BlackOwnership from "../../Supplier/BlackOwnership";
import DocumentUploader from "../../Supplier/DocumentUploader";
import SectorTurnoverInfo from "../../Supplier/SectorTurnoverInfo";
import { getBeeRating, saveBeeRating, saveSupplierDocuments } from "../../../http/ManualSupplier/manualSupplierApi";
import { BeeRatingMessage } from "../../../http/ManualSupplier/Messages/Messages";// eslint-disable-line no-unused-vars
import { Document } from "../../../http/ManualSupplier/Models/Models"; // eslint-disable-line no-unused-vars
import { DocumentSummaryMessage } from "../../../http/ManualSupplier/Messages/DocumentSummaryMessage"; // eslint-disable-line no-unused-vars
import CompanyGroupingType from "../../../Util/Enumerators/CompanyGroupingType";
import { ValidationModel } from "../../../Sections/Marketplace/MTPManualSupplierStep/MTPManualSupplierStep"; // eslint-disable-line no-unused-vars

export interface BeeDetailsModuleRef{
  save: () => Promise<boolean>;
}

export interface BeeDetailsModuleProps{
  companyId: number
  containerClass?:string
  readOnly?:boolean,
  mtoMandatoryField?:number,
}

const BeeDetailsModule:
  ForwardRefRenderFunction<BeeDetailsModuleRef, BeeDetailsModuleProps> = (
    {
      companyId = 0, containerClass = "", readOnly = false, mtoMandatoryField = 0,
    }: BeeDetailsModuleProps, ref
  ) => {
  // == states and hooks
    const showError = useShowError();
    const [[beeStatuses, sectorTurnoverBands, sectorTurnoverBandStatuses, documentTypes],
      staticDataReady] = useStaticData([StaticDataTypes.BeeStatus,
      StaticDataTypes.SectorTurnoverBand,
      StaticDataTypes.SectorTurnoverBandStatus,
      StaticDataTypes.DocumentTypes]);
    const [beeData, setBeeData] = useState<BeeRatingMessage>({} as BeeRatingMessage);
    const [beeDocuments, setBeeDocuments] = useState <Array<Document>>([] as Array<Document>);
    const [loading, setLoading] = useState(false);

    const onInputChangeHandler = (ev : any) => {
      setBeeData((prevState) => ({
        ...prevState,
        [ev.target.name]: ev.target.value,
      }));
    };

    function updateDocuments(documents: Array<Document>) {
      // add back any missing documents
      const consolidatedDocuments = beeDocuments
        .map((c) => documents
          .find((d) => d.documentTypeId === c.documentTypeId)
          ?? c);
      setBeeDocuments(consolidatedDocuments);
    }

    // functions section
    const filterDocumentsBasedOnBee = (documents : Array<Document>) => {
      const sectorTurnoverBand = sectorTurnoverBands?.find(
      (entry) => entry.sectorId === beeData.sector
      && entry.turnoverBandId === beeData.turnoverBand);

      if (sectorTurnoverBand == null) {
        return [];
      }

      let certificateType : BeeCertificateType;
      if (sectorTurnoverBand.beeCertificateType === BeeCertificateType.Affidavit) {
        certificateType = BeeCertificateType.Affidavit;
      } else if (sectorTurnoverBand.beeCertificateType === BeeCertificateType.Certificate) {
        certificateType = BeeCertificateType.Certificate;
      } else {
        certificateType = (beeData.boPercentage >= 51)
          ? BeeCertificateType.Certificate
          : BeeCertificateType.Affidavit;
      }

      return documents
        .filter((document : any) => {
          const documentClassification = documentTypes
            .find((d) => d.id === document.documentTypeId)
          ?.documentClassification;

          switch (certificateType) {
            case BeeCertificateType.Affidavit:
              if (documentClassification === DocumentClassification.BeeAffidavit) {
                return true;
              }
              return false;
            case BeeCertificateType.Certificate:
              if (documentClassification === DocumentClassification.BeeCertificate) {
                return true;
              }
              return false;
            default:
              return false;
          }
        });
    };

    const onSaveBeeInfo = async () => {
      try {
        setLoading(true);
        const boPercentage = Number(beeData.boPercentage);
        const bwoPercentage = Number(beeData.bwoPercentage);
        const saveInfo = { ...beeData, boPercentage, bwoPercentage };

        await saveBeeRating(companyId, saveInfo);

        // save document
        const documentSummary : DocumentSummaryMessage = {
          companyId,
          companyDocuments: beeDocuments.filter((doc) => doc.filename != null),
        };
        await saveSupplierDocuments(documentSummary);
        setLoading(false);
        return true;
      } catch {
        setLoading(false);
        return false;
      }
    };

    // save changes
    const stepValidation = () => {
      const result: ValidationModel = {
        isStepValid: true,
        msgs: {},
      };

      if (mtoMandatoryField !== null) {
        if (!beeData.hasBeeCertificate && isMandatory(mtoMandatoryField, MTOMandatoryField.BEE)) {
          result.isStepValid = false;
          result.msgs.bee = "BEE is required";
        }
      }
      return result;
    };

    useImperativeHandle(ref, () => ({
      save: onSaveBeeInfo,
      validateStep: stepValidation,
    }));
    // end of functions section

    useEffect(() => {
      const loader = async () => {
        try {
          setLoading(true);

          const beePracticeData = await getBeeRating(companyId);
          const expiryDateString = beePracticeData.certificateExpiryDate as any as string;
          const date = expiryDateString === "0001-01-01T00:00:00+00:00"
            ? null
            : new Date(expiryDateString);

          beePracticeData.certificateExpiryDate = date;

          setBeeData({
            ...beePracticeData,
            companyId,
            hasBeeCertificate: beePracticeData.companyGroupingType === CompanyGroupingType.Foreign
              ? 0
              : beePracticeData.hasBeeCertificate,
          });

          const beeDocs = await getDocumentsAsync(companyId, false);
          setBeeDocuments(beeDocs.companyDocuments as Array<Document>);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          showError();
        }
      };
      loader();
    }, [companyId, showError, staticDataReady, beeStatuses]);

    return (
      <Fragment>
        {loading && <Spinner />}
        <div className={containerClass} style={{ marginTop: "36px" }}>
          <BeeStatus
            hasBeeCertificate={beeData.hasBeeCertificate as unknown as boolean}
            validationStatus={beeData.validationStatus}
            hasBeeValidationMessage=""
            onInputChange={onInputChangeHandler}
            onHasBeeCertificateChange={() => {}}
            readOnly={readOnly}
            companyGroupingType={beeData.companyGroupingType}
          />
          {
            beeData.hasBeeCertificate === 1 && (
              <div className="beeContent">
                <div>
                  <hr />
                  <SectorTurnoverInfo
                    sector={beeData.sector}
                    turnoverBand={beeData.turnoverBand}
                    readOnly={readOnly}
                    additional={{ sector: "", turnoverBand: "" }}
                    onInputChange={onInputChangeHandler}
                    onVatExemptedChange={() => {}}
                  />
                  <hr />
                  <BlackOwnership
                    readOnly={readOnly}
                    data={beeData}
                    additional={null}
                    onInputChangeCallback={onInputChangeHandler}
                  />
                  <hr />
                  <BeeCertification
                    readOnly={readOnly}
                    data={beeData}
                    turnoverband={beeData.turnoverBand}
                    beeLvls={staticDataReady ? beeStatuses : []}
                    sectorTurnoverBands={staticDataReady ? sectorTurnoverBands : []}
                    sectorTurnoverBandStatuses={staticDataReady ? sectorTurnoverBandStatuses : []}
                    additional={null}
                    onInputChangeCallback={onInputChangeHandler}
                  />
                </div>
                <Fragment>
                  <hr />
                  <DocumentUploader
                    header="UPLOAD BEE DOCUMENTS"
                    readOnly={readOnly}
                    uploadUrl="api/manualSupplier/"
                    downloadUrl="api/documents/"
                    manualSupplierId={companyId}
                    documentTypes={staticDataReady ? documentTypes : []}
                    documents={filterDocumentsBasedOnBee(beeDocuments)}
                    onDocumentsChanges={updateDocuments}
                  />
                </Fragment>
              </div>
            )
          }
        </div>
      </Fragment>
    );
  };

export default forwardRef<BeeDetailsModuleRef, BeeDetailsModuleProps>(BeeDetailsModule);
