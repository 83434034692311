enum DataFeatures {
/* eslint-disable no-unused-vars */
  None = 0,
  BusinessTypes = 0x1,
  DirectorNames = 0x2,
  ClientBase = 0x4,
  BankingDetails = 0x8,
  AdditionalCompanyData = 0x10,
  ShareholdersDetails = 0x20,
  MandatoryMunicipality = 0x40,
  MandatoryTaxClearancePinNumber = 0x80,
  BusinessRegions = 0x100,
/* eslint-enable no-unused-vars */
}

export const hasDataFeature = (
  companyAllowedData: DataFeatures,
  feature: DataFeatures = DataFeatures.None
) => feature === DataFeatures.None
  // eslint-disable-next-line no-bitwise
  || (companyAllowedData & feature) === feature;

export const hasSomeDataFeature = (
  companyAllowedData: DataFeatures,
  feature: DataFeatures = DataFeatures.None
) => feature === DataFeatures.None
  // eslint-disable-next-line no-bitwise
  || (companyAllowedData & feature) > 0;

export const DataFeaturesDisplay: { [index: number]: string } = {};
DataFeaturesDisplay[DataFeatures.None] = "None";
DataFeaturesDisplay[DataFeatures.BusinessTypes] = "Business Types";
DataFeaturesDisplay[DataFeatures.DirectorNames] = "Director Names";
DataFeaturesDisplay[DataFeatures.ClientBase] = "Client Base";
DataFeaturesDisplay[DataFeatures.BankingDetails] = "Banking Details";
DataFeaturesDisplay[DataFeatures.ShareholdersDetails] = "Shareholder Details";
DataFeaturesDisplay[DataFeatures.AdditionalCompanyData] = "No. of Employees, Royal Bofokeng Resident";
DataFeaturesDisplay[DataFeatures.MandatoryMunicipality] = "Municipality (required as a mandatory field)";
DataFeaturesDisplay[DataFeatures.MandatoryTaxClearancePinNumber] = "Tax Clearance Number and Expiry Date (required as a mandatory field)";
DataFeaturesDisplay[DataFeatures.BusinessRegions] = "Geographical Location/Region(s) for conducting your primary business";

export default DataFeatures;
