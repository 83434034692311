import React from "react";
import { Switch, Route } from "react-router-dom";
import CommunicationPreference from "../Sections/User/CommunicationPreference";

import SettingsView from "./SettingsView";

function UserView() {
  return (
    <Switch>
      <Route path="/user/profile">
        <CommunicationPreference />
      </Route>
      <Route
        path="/user/settings"
        component={() => <SettingsView />}
      />
    </Switch>
  );
}

export default UserView;
