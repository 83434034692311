import {
  BasicTable,
  // eslint-disable-next-line no-unused-vars
  BasicTableCell,
  ImageText,
  SystemFeatures,
  UtilButton,
} from "isuppli-react-components";
import React, { Fragment, useEffect, useState } from "react";
import Alert from "reactstrap/lib/Alert";
import { useSelector } from "react-redux";
import useShowError from "../../../Hooks/useShowError";
import { getCompanyCompliance } from "../../../http/Compliance/complianceApi";
import { modifyCompanyName } from "../../../http/MyCompany/myCompanyAPI";
import {
  // eslint-disable-next-line no-unused-vars
  OnboardingComplianceExceptionMessage,
} from "../../../http/Compliance/Models/Models";
import { getOnboardingRequestExceptionReason } from "../../../http/gets";
import { checkHttpStatus } from "../../../http/httpHelpers";
import { complianceCheckTypeToString } from "../../../Util/Enumerators/ComplianceCheckType";
import ComplianceStatus, {
  complianceStatusToString,
  getComplianceStatusTextClass,
} from "../../../Util/Enumerators/ComplianceStatus";
import { subCheckTypeToString } from "../../../Util/Enumerators/SubCheckType";
import {
  // eslint-disable-next-line no-unused-vars
  DetailsDisplay,
  getFormattedComplianceDetails,
  getCheckStatus,
} from "../../../Util/complianceFormatter";
import Styles from "./SupplierCheckDetails.module.scss";
// eslint-disable-next-line no-unused-vars
import { ReduxState } from "../../../Store/ReduxState";

import { ReactComponent as TimesSvg } from "./times.svg";
import { ReactComponent as CheckMarkSvg } from "./check-mark.svg";

const SupplierCheckDetails = (
  {
    companyId,
    vendorNumber,
  }:
  {
    companyId: number,
    vendorNumber?: string,
  }
) => {
  const [loading, setLoading] = useState(true);
  const [checkDetails, setCheckDetails] = useState<DetailsDisplay>();
  const myCompanyId = useSelector((state: ReduxState) => state.loggedInUserDetails.companyId);
  const showError = useShowError();
  const [correctingValuesSent, setValuesSent] = useState(false);
  const [correctingValue, setCorrectingValue] = useState<string>("");

  useEffect(() => {
    const loader = async () => {
      try {
        const details = await getCompanyCompliance(companyId);

        const exceptionReason: OnboardingComplianceExceptionMessage = await
        getOnboardingRequestExceptionReason(companyId);

        const formattedDetails = getFormattedComplianceDetails(details, exceptionReason);

        setCheckDetails(formattedDetails);
        formattedDetails?.complianceResults?.forEach((result) => {
          result?.procureCheckResultItems?.forEach((procureCheckItem) => {
            if (procureCheckItem.correctingValue ?? false) {
              setCorrectingValue(procureCheckItem.correctingValue);
            }
          });
        });
        setLoading(false);
      } catch (getError) {
        if (checkHttpStatus(getError, 404)) {
          setCheckDetails({
            complianceCheckId: companyId,
            company: {
              registeredName: "",
              registrationNumber: "",
              vatNumber: "",
              externalVendorId: "",
              registrationType: 0,
            },
            complianceProvider: 0,
            status: ComplianceStatus.AwaitingResults,
            complianceResults: [],
            error: "No compliance details available",
          });
          setLoading(false);
          return;
        }

        showError();
      }
    };

    loader();
  }, [showError, companyId]);
  return (
    <div>
      {!loading && checkDetails != null && checkDetails.error != null && (
        <Alert color="danger">{checkDetails.error}</Alert>
      )}

      <div className={`row px-2 pt-2 ${Styles.Label} mb-4`}>
        <div className="col-12">
          <span className="font-weight-bold pr-2">Company registration number:</span>
          <span>{loading ? "..." : checkDetails?.company.registrationNumber ?? ""}</span>
        </div>
        {vendorNumber
          && (
            <div className="col-12">
              <span className="font-weight-bold pr-2">Vendor number:</span>
              <span>{loading ? "..." : vendorNumber ?? ""}</span>
            </div>
          )}
      </div>
      {
        (myCompanyId === companyId && checkDetails?.status === 100 && !correctingValuesSent)
          ? (
            <div>
              {
                checkDetails.complianceResults.some((result) => (
                  result.procureCheckResultItems?.some((procureCheck) => procureCheck.correctingValue ?? false)
                ))
                  && (
                    <div>
                      <span className="font-weight-bold pr-2">The following details can be ammended and compliance checks resubmitted:</span>
                    </div>
                  )
              }
              <div>
                {
            checkDetails?.complianceResults?.map((complianceResultItem) => {
              const items = complianceResultItem.procureCheckResultItems?.map((procureCheckItem, index) => {
                if (procureCheckItem?.correctingValue ?? false) {
                  return (
                    <div>
                      <ImageText
                        key={index}
                        svg={TimesSvg}
                        className="text-danger"
                        text={`Suggested new company name: ${procureCheckItem.correctingValue}`}
                      />
                    </div>
                  );
                }
                return null;
              });
              return items;
            })
                }
              </div>
              <div>
                {

                  checkDetails.complianceResults.some((result) => (
                    result.procureCheckResultItems?.some((procureCheck) => procureCheck.correctingValue ?? false)
                  ))
                    && (
                      <UtilButton
                        size="extra-small"
                        // eslint-disable-next-line react/no-children-prop
                        children="accept changes"
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={
                          () => {
                            setLoading(true);
                            if (correctingValue !== "" && (correctingValue ?? false)) {
                              modifyCompanyName(correctingValue);
                              setValuesSent(true);
                            } else {
                              setValuesSent(false);
                            }
                            setLoading(false);
                          }
                        }
                      />
                    )
                }
              </div>
            </div>
          )
          : correctingValuesSent && <span className="font-weight-bold pr-2">New values accepted, Compliance will be resubmitted.</span>
      }
      <br />
      {
        checkDetails?.status === ComplianceStatus.Disabled && (
          <Alert className="mt-3" color="info">
            <p className="mb-0">Note: This supplier is not eligable for ongoing compliance checks.</p>
          </Alert>
        )
      }
      {checkDetails?.error == null && checkDetails?.status !== ComplianceStatus.Disabled && (
        <BasicTable
          loading={loading}
          viewSubRowFeature={myCompanyId === companyId
            ? SystemFeatures.OngoingCompliance
            : SystemFeatures.ViewSupplierComplianceDetails}
          emptyMessage="No compliance management results available"
          columns={[
            { key: "date", text: "Date checked", align: "left" },
            { key: "type", text: "Verification Type", align: "left" },
            "Number of checks",
            "Passed",
            "Failed",
            "Outstanding",
            { key: "res", text: "Overall Result", align: "left" },
          ]}
          rows={checkDetails?.complianceResults?.map((check) => [
            {
              key: `${check.verificationTypeId}_date`,
              value: check.dateCheckedDisplay,
            } as BasicTableCell<string>,
            {
              key: `${check.verificationTypeId}_type`,
              value: complianceCheckTypeToString(check.verificationTypeId),
            } as BasicTableCell<string>,
            {
              key: `${check.verificationTypeId}_run`,
              value: `${
                check.procureCheckResultItems?.length
                ?? check.totalFailedChecks + check.totalPassedChecks
              }`,
              align: "center",
            },
            {
              key: `${check.verificationTypeId}_passed`,
              value: `${check.totalPassedChecks}`,
              align: "center",
            },
            {
              key: `${check.verificationTypeId}_failed`,
              value: `${check.totalFailedChecks}`,
              align: "center",
            },
            {
              key: `${check.verificationTypeId}_outstanding`,
              value: `${
                (check.procureCheckResultItems?.length
                ?? check.totalFailedChecks)
                  + check.totalPassedChecks
                  - check.totalFailedChecks
                  - check.totalPassedChecks
              }`,
              align: "center",
            },
            {
              key: `${check.verificationTypeId}_status`,
              valueTemplate: () => {
                const status = getCheckStatus(check);
                return (
                  <span
                    className={`font-weight-bold ${getComplianceStatusTextClass(
                      status
                    )}`}
                  >
                    {complianceStatusToString(status)}
                  </span>
                );
              },
            },
          ])}
          subRows={checkDetails?.complianceResults?.map((check) => {
            const items = (
              <Fragment key={`${check.verificationTypeId}_subRows`}>
                {check.procureCheckResultItems?.map((item, index) => (
                  <div>
                    <div>
                      <ImageText
                        key={index}
                        svg={
                          item.result === true
                            ? CheckMarkSvg
                            : TimesSvg
                        }
                        className={item.result ? "text-success" : "text-danger"}
                        text={subCheckTypeToString(item.subCheckType)}
                      />
                    </div>
                    <p>
                      {item.message && <i>{` *${item.message}`}</i>}
                    </p>
                  </div>
                ))}
              </Fragment>
            );
            return items;
          })}
        />
      )}

      {checkDetails?.error == null
        && (
          <div className={`row px-2 ${Styles.Label} mt-4`}>
            <div className="col-12">
              <span className="font-weight-bold pr-2">Overall Supplier Vetting Status:</span>
              <span className={`font-weight-bold ${getComplianceStatusTextClass(checkDetails?.status)}`}>
                {
                  checkDetails?.status == null
                    ? "..."
                    : complianceStatusToString(checkDetails.status)
                }
              </span>
            </div>
          </div>
        )}

      {checkDetails?.reason != null && checkDetails?.reason !== ""
        && (
          <div className={`row px-2 ${Styles.Label} mt-4`}>
            <div className="col-12">
              <span className="font-weight-bold pr-2">Compliance Exception Reason:</span>
              <span>{checkDetails?.reason}</span>
            </div>
          </div>
        )}
    </div>
  );
};

export default SupplierCheckDetails;
