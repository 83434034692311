enum OnboardingApproverLevels {
  None = 0, // eslint-disable-line no-unused-vars
  Approver1 = 0x1, // eslint-disable-line no-unused-vars
  Approver2 = 0x2, // eslint-disable-line no-unused-vars
}
export default OnboardingApproverLevels;

export const OnboardingApproverLevelsDisplay: { [index: number]: string } = {};
OnboardingApproverLevelsDisplay[OnboardingApproverLevels.None] = "None";
OnboardingApproverLevelsDisplay[OnboardingApproverLevels.Approver1] = "Approver 1";
OnboardingApproverLevelsDisplay[OnboardingApproverLevels.Approver2] = "Approver 2";
