/* eslint-disable space-before-blocks */
import React, { useState, useEffect } from "react";
import {
  ConfirmationModal, isMandatory, MTOMandatoryField, Spinner, UtilButton,
} from "isuppli-react-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Alert } from "reactstrap";
import InfoPopup from "../../Containers/InfoPopup";
import OnboardingWizard from "../../Containers/Marketplace/OnboardingWizard/OnboardingWizard";
// eslint-disable-next-line no-unused-vars
import { ReduxState } from "../../Store/ReduxState";
import OfferingSelector from "../../Containers/Supplier/OfferingSelector/OfferingSelector";
import ProductsAndServicesList from "../../Containers/Supplier/ProductsAndServicesList";
import { hideInfoPopup, showInfoPopup } from "../../Store/actions";
import { onOnboardingInputChange, setOnboardingRequestObject } from "../../Store/onboardingRequestActions";
import useStaticCompanyData from "../../Hooks/useStaticCompanyData";
import { StaticCompanyDataTypes } from "../../Util/Enumerators/staticCompanyDataTypes";
import { saveOnboardingRequest } from "../../http";
import useShowError from "../../Hooks/useShowError";
import CompanyConfigurationFeatures from "../../Util/Enumerators/CompanyConfigurationFeatures";
import SuspensionMessageBanner from "./SuspensionMessageBanner/SuspensionMessageBanner";

const MTPCategories = ({ stepNumber } : {stepNumber: number}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const showError = useShowError();
  const [isReady] = useStaticCompanyData(
    [StaticCompanyDataTypes.supplierCategory]
  );
  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSelectionModal, setShowSelectionModal] = useState(false);

  const data = useSelector((state: ReduxState) => state.onboardingRequest);
  const allowedCompanyFeatures = useSelector(
    (state: ReduxState) => (state.companyConfiguration.allowedCompanyFeatures)
  );

  const setSelection = (newSelection: number[]) => {
    dispatch(onOnboardingInputChange(
      {
        target: {
          name: "supplierSubCategoryIds",
          value: newSelection,
        },
      }
    ));
  };

  useEffect(() => {
    dispatch(hideInfoPopup());
  }, [dispatch]);

  const performSave = async (saveOnly: boolean) : Promise<void> => {
    setLoading(true);
    try {
      const onboardingRequest = data;
      if (!saveOnly) {
        onboardingRequest.lastStepCompleted = stepNumber > data.lastStepCompleted
          ? stepNumber
          : data.lastStepCompleted;
      }
      const updatedOnboardingRequest = await saveOnboardingRequest(
        onboardingRequest,
        saveOnly
      );
      dispatch(setOnboardingRequestObject(updatedOnboardingRequest));
    } catch (error) {
      showError();
    }

    setLoading(false);
  };

  const saveAndContinueLaterHandler = async () => {
    await performSave(true);
    history.push("../../");
  };

  const onNextClick = async (nextUrl: string) : Promise<void> => {
    if (!isReady) {
      return;
    }
    let isValid = true;
    const msgs : {[key: string]: string} = {};
    if (data.supplierSubCategoryIds.length === 0
      && (data.mtoMandatoryField === null || isMandatory(data.mtoMandatoryField, MTOMandatoryField.ProductSegmentAndFamilies))
      && (data.manualMTOMandatoryField === null || isMandatory(data.mtoMandatoryField, MTOMandatoryField.ProductSegmentAndFamilies))) {
      isValid = false;
      msgs.selection = "Please make at least one selection";
    }

    if (isValid) {
      await performSave(true);
      // next step
      if (nextUrl.length > 0) {
        history.push(nextUrl);
      }
    } else {
      window.scrollTo({ top: 0 });
      dispatch(showInfoPopup(
        "This page contains missing information.",
        "To continue please amend/complete the following:",
        Object.keys(msgs).map((key) => msgs[key])
      ));
    }
  };
  // eslint-disable-next-line no-bitwise
  const titleHeading = ((allowedCompanyFeatures & CompanyConfigurationFeatures.MTOProductAndSegments) > 0) // eslint-disable-line max-len
    ? "PRODUCT SEGMENTS & FAMILIES"
    : "COMPANY PRODUCTS & FAMILIES";
  return (
    <section>
      {showConfirm && (
        <ConfirmationModal
          size="large"
          heading="Save & Continue later"
          description="You are about to save your progress but you can come back at any
          time by to continue with the motivation to onboard."
          onProceedClick={saveAndContinueLaterHandler}
          proceedButtonText="OK"
          hasCancel
          onToggleModal={() => setShowConfirm(false)}
        />
      )}

      {loading && <Spinner />}

      <InfoPopup />

      <OnboardingWizard
        isManualSupplier={data.manualSupplier}
        currentStep={stepNumber - 1}
        lastStepCompleted={data.lastStepCompleted}
        onClickSaveAndContinue={() => { setShowConfirm(true); }}
        onClickNext={onNextClick}
        onboardingId={data.onboardingId}
      >

        <div className="padding-lr-10vw">
          {data?.suspensionReason
            && (
              <SuspensionMessageBanner suspensionReason={data?.suspensionReason} />
            )}
          <div className="row top-margin-90px">
            <div className="col-12 col-lg-4">
              <div>
                <h3 className="margin-null">{`STEP 0${stepNumber}`}</h3>
              </div>
              <div>
                <h1>
                  {titleHeading}
                </h1>
              </div>
            </div>
          </div>

          <p>
            {isMandatory(data.mtoMandatoryField, MTOMandatoryField.ProductSegmentAndFamilies) && "*required"}
          </p>

          <div className="row" style={{ paddingTop: "36px" }}>
            <div className="col-12 col-lg-7">
              <div>
                <Alert color="info">
                  Please add your most relevant products and/or services.
                </Alert>
              </div>
            </div>
          </div>

          <div style={{ marginTop: "20px" }} />

          {showSelectionModal && (
            <OfferingSelector
              setSelection={(newSelection: any) => setSelection(newSelection)}
              selection={data.supplierSubCategoryIds}
              onCloseCallback={() => { setShowSelectionModal(false); }}
            />
          )}

          <div className="row">
            <div className="col-12">
              <ProductsAndServicesList
                selectedSupplierSubCategoryIds={data.supplierSubCategoryIds}
              />
            </div>
            <div className="col-12 col-xl-5">
              <UtilButton
                color="primary"
                onClick={() => setShowSelectionModal(true)}
              >
                SELECT PRODUCTS AND/OR SERVICES
              </UtilButton>
            </div>
          </div>
        </div>

      </OnboardingWizard>
    </section>
  );
};

export default MTPCategories;
