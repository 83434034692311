import React, { useState, useEffect, Fragment } from "react";
import { BasicTable } from "isuppli-react-components";

import { Button, Alert } from "reactstrap";
import {
  getNewKey,
  getKeys,
  deleteKey,
  NewIntegrationKeyMessage, // eslint-disable-line no-unused-vars
} from "../../../http/EnterpriseConfiguration/enterpriseConfiguration";

import useShowError from "../../../Hooks/useShowError";

const ERPIntegration = () => {
  const [loading, setLoading] = useState(true);
  const [generatingKey, setGeneratingKey] = useState(false);
  const [keysBeingDeleted, setKeysBeingDeleted] = useState<Array<string>>([]);
  const [keys, setKeys] = useState<Array<NewIntegrationKeyMessage>>([]);

  const showError = useShowError();
  useEffect(() => {
    const loader = async () => {
      try {
        const keysFromApi = await getKeys();
        setKeys(keysFromApi.keys.map((c) => ({ ...c, clientSecret: "" })));
      } catch (error) {
        showError();
      }

      setLoading(false);
    };
    loader();
  }, [setKeys, setLoading, showError]);

  const onNewKeyHandler = async () => {
    setGeneratingKey(true);
    try {
      const newKey = await getNewKey();
      setKeys((prevKeys) => [...prevKeys, newKey]);
    } catch (error) {
      showError();
    }
    setGeneratingKey(false);
  };

  const onDeleteKeyHandler = async (clientId: string) => {
    setKeysBeingDeleted((prev) => [...prev, clientId]);
    try {
      await deleteKey(clientId);
      setKeys((prevKeys) => [...prevKeys.filter((c) => c.clientId !== clientId)]);
    } catch (error) {
      showError();
    }

    setKeysBeingDeleted((prev) => prev.filter((c) => c !== clientId));
  };

  return (
    <div>
      <h2>ERP Integration Keys</h2>
      {loading
        ? <p>Loading...</p>
        : (
          <Fragment>
            <BasicTable
              columns={[
                {
                  key: "clientId", text: "Client ID", align: "left", size: "1fr",
                },
                { key: "option", text: "Option", align: "center" },
              ]}
              rows={keys.map((c) => ([
                {
                  key: `${c.clientId}_id`,
                  valueTemplate: () => (
                    <div className="">
                      <span>{c.clientId}</span>
                      {c.clientSecret.length > 0
                        ? (
                          <Alert className="mt-3" color="info">
                            <p className="h4 text-break">{c.clientSecret}</p>
                            <p className="mb-0">
                              Note: Please copy this secret now. You will not be able to retrieve it later.
                            </p>
                          </Alert>
                        )
                        : null}
                    </div>
                  ),
                },
                {
                  key: `${c.clientId}_button`,
                  align: "center",
                  valueTemplate: () => (
                    <Button
                      color="danger"
                      onClick={() => onDeleteKeyHandler(c.clientId)}
                      disabled={keysBeingDeleted.some((d) => d === c.clientId)}
                    >
                      {keysBeingDeleted.some((d) => d === c.clientId) ? "Deleting..." : "Delete"}
                    </Button>
                  ),
                },
              ]))}
              emptyMessage="No active integration keys"
              loading={loading}
            />
            <Button onClick={onNewKeyHandler} className="mt-2" color="attention" disabled={generatingKey}>
              { generatingKey ? "Generating..." : "Generate new Key"}
            </Button>
          </Fragment>
        ) }

    </div>
  );
};

export default ERPIntegration;
