import React, { useState } from "react";
import {
  Input, Label, FormGroup, Dropdown, DropdownToggle, DropdownMenu,
} from "reactstrap";
import CheckboxInput from "../../CheckboxInput/CheckboxInput";
import Styles from "./MultiSelectDropDownInput.module.scss";

const MultiSelectDropDownInput = <T extends string | number>({
  label,
  options,
  onChange,
  value,
  className,
  required,
  disabled,
  validationMessage,
  dropdownDirection,
  dropdownClassName,
  hideSelectedItemsList,
}: {
  label?: string,
  options: Array<{ value: T, display: string }>,
  onChange: (value: T[]) => void,
  value: T[],
  className?: string,
  required?: boolean,
  disabled? : boolean,
  validationMessage?: string,
  dropdownDirection?: "up" | "down" | "left" | "right"
  dropdownClassName?: string
  hideSelectedItemsList?: boolean,
}) => {
  const onChangeHandler = (itemValue: T, newValue: boolean) => {
    let listOfSelectedValues = [...value];

    if (newValue && !listOfSelectedValues.includes(itemValue)) {
      listOfSelectedValues.push(itemValue);
    }

    if (!newValue && listOfSelectedValues.includes(itemValue)) {
      listOfSelectedValues = listOfSelectedValues.filter((c) => c !== itemValue);
    }

    onChange(listOfSelectedValues);
  };

  const dropDownItems = options?.map((item) => {
    const isSelected = value.includes(item.value);

    return (
      <CheckboxInput
        key={item.value}
        label={item.display}
        value={isSelected}
        onChange={(newValue: boolean) => onChangeHandler(item.value, newValue)}
        size="small"
        className={`${Styles.CheckboxInput}`}
      />
    );
  });

  const [showOptions, setShowOptions] = useState(false);
  const numItemsSelected = value.length;

  const inputId = `${label}-${Math.random().toString(36).substring(2)}`;
  return (
    <FormGroup className={className}>
      { label && <Label for={inputId}>{label}</Label> }
      <Dropdown
        direction={dropdownDirection ?? "down"}
        disabled={disabled}
        isOpen={showOptions}
        toggle={() => setShowOptions((newValue) => !newValue)}
      >
        <DropdownToggle tag="div">
          <Input
            id={inputId}
            className={`${Styles.DropDownInput} rounded-full`}
            type="select"
            name="select"
            value={value.length === 0 ? -1 : 1}
            required={required}
            disabled={disabled}
            // empty onchange, change happens as part of drop down
            onChange={() => {}}
          >
            <option key="" value="">
              please select
            </option>
            <option key="selected" value={1}>
              {`${value.length} items selected`}
            </option>
          </Input>
          {
            validationMessage && (
              <div className="invalid-feedback">
                {validationMessage}
              </div>
            )
          }
        </DropdownToggle>
        <DropdownMenu className={`${Styles.ScrollingMenu} ${dropdownClassName} w-100`}>
          {dropDownItems}
        </DropdownMenu>
      </Dropdown>
      {
        !hideSelectedItemsList
        && (
          <div className="py-2">
            {
              value.map((item, index) => (
                <span key={item}>
                  {options.find((option) => option.value === item)?.display}
                  {(index + 1) !== numItemsSelected ? ", " : ""}
                </span>
              ))
            }
          </div>
        )
      }

    </FormGroup>
  );
};

export default MultiSelectDropDownInput;
