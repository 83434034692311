import axios from "axios";
import { ClientSpecificInfoMessage } from "./ClientSpecificInfoMessage"; // eslint-disable-line no-unused-vars
import { makeRequestAsync } from "../util";
import { ClientSpecificInfoUpdateMessage } from "./ClientSpecificInfoUpdateMessage";// eslint-disable-line no-unused-vars

export function getClientSpecificInfoAsync(companyId : number) {
  const url = `api/client/clientSpecificInfo/${companyId}`;
  return makeRequestAsync(() => axios.get(url)) as Promise<ClientSpecificInfoMessage>;
}

export function getClientSpecificInfoAsTheSupplierAsync(buyerCompanyId: number) {
  const url = `api/client/clientSpecificInfo/${buyerCompanyId}/supplier`;
  return makeRequestAsync(() => axios.get(url)) as Promise<ClientSpecificInfoMessage>;
}

export function saveClientSpecificInfoAsTheSupplierAsync(message : ClientSpecificInfoUpdateMessage) {
  const url = "api/client/clientSpecificInfo";
  return makeRequestAsync(() => axios.post(url, message)) as Promise<void>;
}
