import React from "react";
import TextInput from "../../Controls/TextInput/TextInput";

import {
  filterOutSpecialCharactersExceptFullstops,
  clampNumber,
} from "../../Validation/onChangeValidations";

const BlackOwnership = (props) => (
  <section>
    <div>
      <h2>BLACK OWNERSHIP (%)</h2>
    </div>
    <div className="row">
      <div className="col-12 col-sm-5">
        <TextInput
          label="Please specify Black Ownership % (BO)"
          name="boPercentage"
          value={props.data.boPercentage}
          additional={props.additional?.boPercentage || null}
          placeholder="e.g. 26"
          filters={[filterOutSpecialCharactersExceptFullstops, clampNumber(0, 100, 2)]}
          onChangeCallback={props.onInputChangeCallback}
          disabled={props.readOnly}
        />
      </div>
      <div className="col-12 col-sm-2" />
      <div className="col-12 col-sm-5">
        <TextInput
          label="Please specify Black Woman Ownership % (BWO)"
          name="bwoPercentage"
          value={props.data.bwoPercentage}
          additional={props.additional?.bwoPercentage || null}
          placeholder="e.g. 26"
          filters={[filterOutSpecialCharactersExceptFullstops, clampNumber(0, 100, 2)]}
          onChangeCallback={props.onInputChangeCallback}
          disabled={props.readOnly}
        />
      </div>
    </div>
  </section>
);

export default BlackOwnership;
