import React from "react";
import {
  FilterTypes,
  AdvancedFilterTypes,
  hasFeature,
  SystemFeatures,
} from "isuppli-react-components";
import { Badge } from "reactstrap";

import StarRatingInput from "../../Controls/StarRatingInput/StarRatingInput";
import searchBeeStatuses from "../../Util/Enumerators/searchBeeStatusCriteria";
import supplierStatuses from "../../Util/Enumerators/supplierStatuses";
import onboardingStatuses from "../../Util/Enumerators/onboardingStatuses";
import supplierFilterOptions from "../../Util/Enumerators/supplierFilterOptions";
import {
  getComplianceStatusTextClass,
  complianceStatusToString,
} from "../../Util/Enumerators/ComplianceStatus";
import {
  ComplianceCheckType,
  ComplianceCheckStatus,
  complianceCheckTypeToString,
  complianceCheckStatusToString,
} from "../../Util/Enumerators/ComplianceCheck";
import BeeValidationStatus from "../../Util/Enumerators/beeValidationStatus";
import BeeStateFilter, {
  beeStateFilterToString,
} from "../../Util/Enumerators/beeStateFilter";

import BeeOwnership, {
  beeOwnershipToString,
} from "../../Util/Enumerators/beeOwnership";

import SupplierSearchToolTip from "./SupplierSearchToolTip";

const getBeeTextColorClass = (row, allowedFeatures) => {
  // use empty string instead of null so that isBeeExpired is false if no date provided
  const isBeeExpired = new Date(row.certificateExpiryDate ?? "") < new Date();
  if (!hasFeature(allowedFeatures, SystemFeatures.ColourIndicators)) {
    return "";
  }
  if (isBeeExpired) {
    return "text-danger font-weight-bold";
  }
  switch (row.beeValidationStatus) {
    case BeeValidationStatus.Validated:
      return "text-success font-weight-bold";
    case BeeValidationStatus.Revoked:
      return "text-danger font-weight-bold";
    default:
      return "";
  }
};

export const sortingValues = {
  relevance: 0,
  newestFirst: 1,
  oldestFirst: 2,
  registeredName: 3,
  rating: 4,
};

export const getTableConfig = (
  actionOptions, editVendorNumber, allowedFeatures, transactionStatuses
) => ({
  columns: [
    {
      key: "name",
      heading: "Registered Name",
      size: "1fr",
      valueTemplate: (row) => (
        <SupplierSearchToolTip rowData={row} />
      ),
    },
    {
      key: "tradingName",
      heading: "Trading Name",
      size: "1fr",
      propName: "tradingName",
    },
    {
      key: "regNo",
      heading: "Reg No.",
      size: "1fr",
      propName: "registrationNumber",
    },
    {
      key: "erpNo",
      heading: "ERP No.",
      size: "1fr",
      valueTemplate: (row) => (
        <span
          onClick={() => editVendorNumber(row.id, row.vendorNumber, row.registeredName)}
        >
          {row.vendorNumber}
        </span>
      ),
    },
    {
      key: "bee",
      heading: "B-BBEE",
      size: "0.8fr",
      valueTemplate: (row) => (
        <span className={getBeeTextColorClass(row, allowedFeatures)}>
          {row.beeClassification}
        </span>
      ),
    },
    {
      key: "bo",
      heading: "BO",
      size: "0.8fr",
      valueTemplate: (row) => (
        <span className={getBeeTextColorClass(row, allowedFeatures)}>{row.boPercentage}</span>
      ),
    },
    {
      key: "bwo",
      heading: "BWO",
      size: "0.8fr",
      valueTemplate: (row) => (
        <span className={getBeeTextColorClass(row, allowedFeatures)}>{row.bwoPercentage}</span>
      ),
    },
    {
      key: "beerating",
      heading: "B-BBEE RATING",
      size: "0.8fr",
      valueTemplate: (row) => (
        <span className={getBeeTextColorClass(row, allowedFeatures)}>{row.beeStatus}</span>
      ),
    },
  ],
  footerItems: [
    {
      key: "manualSupplier",
      label: null,
      valueTemplate: (row) => (row.manualSupplier ? (
        <h6 className="m-0">
          <Badge color="primary">Manual Supplier</Badge>
        </h6>
      ) : null),
    },
    {
      key: "supplierRating",
      label: "Supplier Rating",
      valueTemplate: (row) => (
        <StarRatingInput
          value={row.companyRating}
          totalRatings={row.totalCompanyRatings}
          readonly
          showTotalRatings
          size="small"
        />
      ),
    },
    {
      key: "status",
      label: "Supplier Status",
      valueTemplate: (row) => `${supplierStatuses.toString(row.supplierStatus)}
         ${row.supplierStatus === supplierStatuses.busyOnboarding
    ? ` - ${onboardingStatuses.toString(row.onboardingStatus)}`
    : ""
  }`,
    },
    ...(transactionStatuses ? [
      {
        key: "transactionStatus",
        label: "Transactional Status",
        valueTemplate: (row) => (
          `${(transactionStatuses && row.supplierTransactionStatusValue)
            ? transactionStatuses.find((status) => row.supplierTransactionStatusValue === status.uniqueUiValue)?.name
            : "N/A"
          }`
        ),
      }] : []),
    ...(transactionStatuses ? [
      {
        key: "orderType",
        label: "Order Type",
        valueTemplate: (row) => (
          `${row.orderType ? "Yes" : "No"}`
        ),
      }] : []),
    {
      key: "supplierType",
      label: "Supplier Type",
      propName: "supplierType",
    },
    {
      key: "checks",
      label: "Compliance Management",
      valueTemplate: (row) => (
        <span className={getComplianceStatusTextClass(row.complianceStatus, allowedFeatures)}>
          {complianceStatusToString(row.complianceStatus)}
        </span>
      ),
    },
    {
      key: "email",
      label: "Email",
      propName: "generalEmail",
    },
    {
      key: "telephone",
      label: "Telephone Number",
      propName: "generalPhone",
    },
  ],
  actionOptions,

  sortOptions: {
    selectedSortOption: 0,
    options:
      [{
        display: "Relevance",
        value: sortingValues.relevance,
      },
      {
        display: "Newest first",
        value: sortingValues.newestFirst,
      },
      {
        display: "Oldest first",
        value: sortingValues.oldestFirst,
      },
      {
        display: "Registered name",
        value: sortingValues.registeredName,
      },
      {
        display: "Highest rated",
        value: sortingValues.rating,
      },
      ],
  },

  statusFilterOptions: {
    selectedStatusFilterOption: 0,
    options: [
      {
        display: supplierFilterOptions.toString(supplierFilterOptions.All), // all suppliers
        value: supplierFilterOptions.All,
      },
      {
        display: supplierFilterOptions
          .toString(supplierFilterOptions.Existing), // existing suppliers
        value: supplierFilterOptions.Existing,
      },
      {
        display: supplierFilterOptions.toString(
          supplierFilterOptions.Potential
        ), // potential suppliers
        value: supplierFilterOptions.Potential,
      },
    ],
  },

  // Set up the additional status filter options (transaction statuses)
  // but make sure it's conditionally rendered
  ...(transactionStatuses ? {
    additionalStatusFilterOptions: {
      selectedStatusFilterOption: 0,
      options: transactionStatuses.map((status) => (
        {
          display: status.name,
          value: status.uniqueUiValue,
        })),
    },
  } : {}),
});

const ownershipOptions = [
  {
    display: "0% - 29%",
    value: searchBeeStatuses.lt30,
  },
  {
    display: "30% - 50%",
    value: searchBeeStatuses.lt51,
  },
  {
    display: "51% - 100%",
    value: searchBeeStatuses.lte100,
  },
  {
    display: "100%",
    value: searchBeeStatuses.eq100,
  },
];

export const getFilterProps = ({
  provinces,
  supplierCategories,
  unspscSegments,
  beeLevels,
  selectedOption,
  prioritiseClientOfferings,
}) => {
  const filters = [
    {
      filterName: "Province(s)",
      options: provinces,
      filterType: FilterTypes.MultiSelectDropDown,
    },
  ];

  if (prioritiseClientOfferings === true && supplierCategories.length > 0) {
    filters.push(
      {
        filterName: "Company Product Segment",
        options: supplierCategories,
        filterType: FilterTypes.MultiSelectDropDown,
      },
      {
        filterName: "Company Product Family",
        options: supplierCategories?.find((c) => c.value === selectedOption)
          ?.supplierSubCategories,
        filterType: FilterTypes.MultiSelectDropDown,
      }
    );
  } else if (prioritiseClientOfferings === false && unspscSegments.length > 0) {
    filters.push(
      {
        filterName: "Global Product Segment",
        options: unspscSegments,
        filterType: FilterTypes.MultiSelectDropDown,
      },
      {
        filterName: "Global Product Family",
        options: unspscSegments?.find((c) => c.value === selectedOption)
          ?.families,
        filterType: FilterTypes.MultiSelectDropDown,
      }
    );
  }

  filters.push(
    {
      filterName: "B-BBEE Level",
      options: beeLevels,
      filterType: FilterTypes.MultiSelectDropDown,
    },
    {
      filterName: "Black Ownership",
      options: ownershipOptions,
      filterType: FilterTypes.MultiSelectDropDown,
    },
    {
      filterName: "Black Women Ownership",
      options: ownershipOptions,
      filterType: FilterTypes.MultiSelectDropDown,
    }
  );

  return filters;
};

const beeStateFilterOptions = [
  {
    value: BeeStateFilter.None,
    display: beeStateFilterToString(BeeStateFilter.None),
  },
  {
    value: BeeStateFilter.Valid,
    display: beeStateFilterToString(BeeStateFilter.Valid),
  },
  {
    value: BeeStateFilter.Invalid,
    display: beeStateFilterToString(BeeStateFilter.Invalid),
  },
  {
    value: BeeStateFilter.Expired,
    display: beeStateFilterToString(BeeStateFilter.Expired),
  },
  {
    value: BeeStateFilter.PendingValidation,
    display: beeStateFilterToString(BeeStateFilter.PendingValidation),
  },
  {
    value: BeeStateFilter.Outstanding,
    display: beeStateFilterToString(BeeStateFilter.Outstanding),
  },
  {
    value: BeeStateFilter.Exclusion,
    display: beeStateFilterToString(BeeStateFilter.Exclusion),
  },
  {
    value: BeeStateFilter.ValidForClient,
    display: beeStateFilterToString(BeeStateFilter.ValidForClient),
  },
];

const complianceCheckTypeOptions = [
  {
    value: ComplianceCheckType.None,
    display: complianceCheckTypeToString(
      ComplianceCheckType.None
    ),
  },
  {
    value: ComplianceCheckType.CIPC,
    display: complianceCheckTypeToString(
      ComplianceCheckType.CIPC
    ),
  },
  {
    value: ComplianceCheckType.SARS,
    display: complianceCheckTypeToString(
      ComplianceCheckType.SARS
    ),
  },
];

const complianceCheckStatusOptions = [
  {
    value: ComplianceCheckStatus.None,
    display: complianceCheckStatusToString(
      ComplianceCheckStatus.None
    ),
  },
  {
    value: ComplianceCheckStatus.Valid,
    display: complianceCheckStatusToString(
      ComplianceCheckStatus.Valid
    ),
  },
  {
    value: ComplianceCheckStatus.Invalid,
    display: complianceCheckStatusToString(
      ComplianceCheckStatus.Invalid
    ),
  },
  {
    value: ComplianceCheckStatus.Pending,
    display: complianceCheckStatusToString(
      ComplianceCheckStatus.Pending
    ),
  },
  {
    value: ComplianceCheckStatus.NA,
    display: complianceCheckStatusToString(
      ComplianceCheckStatus.NA
    ),
  },
];

const designatedOwnerOptions = [
  {
    value: true,
    display: "Yes",
  },
  {
    value: false,
    display: "No",
  },
];

const beeOwnershipOptions = [
  {
    value: BeeOwnership.Flowthrough,
    display: beeOwnershipToString(BeeOwnership.Flowthrough),
  },
  {
    value: BeeOwnership.Modified,
    display: beeOwnershipToString(BeeOwnership.Modified),
  },
];

export const getAdvancedFilterProps = ({
  beeClassifications,
  supplierCategories,
  unspscSegments,
  selectedOption,
  prioritiseClientOfferings,
  supplierTypes,
  subscriptionTiers,
  companyStatuses,
  supportingDocuments,
  allowedFeatures,
}) => {
  const advancedFilters = [
    {
      filterName: "Registration No",
      placeholder: "enter registration number",
      filterType: AdvancedFilterTypes.Text,
    },
    {
      filterName: "VAT Number",
      placeholder: "enter VAT number",
      filterType: AdvancedFilterTypes.Text,
    },
    {
      filterName: "Vendor Number",
      placeholder: "enter vendor number",
      filterType: AdvancedFilterTypes.Text,
    },
    {
      filterName: "Contact Person",
      placeholder: "enter contact person name",
      filterType: AdvancedFilterTypes.Text,
    },
    {
      filterName: "Email Address",
      placeholder: "enter contact person email address",
      filterType: AdvancedFilterTypes.Text,
    },
    {
      filterName: "Added after",
      filterType: AdvancedFilterTypes.Date,
    },
    {
      filterName: "Supplier Type",
      options: supplierTypes,
      filterType: AdvancedFilterTypes.DropDown,
    },
    {
      filterName: "BBBEE Classification",
      options: beeClassifications.map((bc) => ({
        display: bc.display,
        value: bc.value,
      })),
      filterType: AdvancedFilterTypes.DropDown,
    },
    {
      filterName: "BBBEE Status",
      options: beeStateFilterOptions,
      filterType: AdvancedFilterTypes.DropDown,
    },
    {
      filterName: "Compliance Check Type",
      options: complianceCheckTypeOptions,
      filterType: AdvancedFilterTypes.DropDown,
    },
    {
      filterName: "Compliance Check Status",
      options: complianceCheckStatusOptions,
      filterType: AdvancedFilterTypes.DropDown,
    },
    {
      filterName: "BBBEE Ownership",
      options: beeOwnershipOptions,
      filterType: AdvancedFilterTypes.DropDown,
    },
    {
      filterName: "Designated Owner",
      options: designatedOwnerOptions,
      filterType: AdvancedFilterTypes.DropDown,
    },
  ];

  if (prioritiseClientOfferings === false && supplierCategories.length > 0) {
    advancedFilters.push(
      {
        filterName: "Company Product Segment",
        options: supplierCategories,
        filterType: AdvancedFilterTypes.MultiSelectDropdown,
      },
      {
        filterName: "Company Product Family",
        options: supplierCategories?.find((c) => c.value === selectedOption)
          ?.supplierSubCategories,
        filterType: AdvancedFilterTypes.MultiSelectDropdown,
      }
    );
  } else if (prioritiseClientOfferings === true && unspscSegments.length > 0) {
    advancedFilters.push(
      {
        filterName: "Global Product Segment",
        options: unspscSegments,
        filterType: AdvancedFilterTypes.MultiSelectDropdown,
      },
      {
        filterName: "Global Product Family",
        options: unspscSegments?.find((c) => c.value === selectedOption)
          ?.families,
        filterType: AdvancedFilterTypes.MultiSelectDropdown,
      }
    );
  }

  // added after to not mess with the ordering
  advancedFilters.push({
    filterName: "Subscription Tier",
    options: subscriptionTiers,
    filterType: AdvancedFilterTypes.DropDown,
  });
  advancedFilters.push({
    filterName: "Supplier Status",
    options: companyStatuses,
    filterType: AdvancedFilterTypes.DropDown,
  });
  advancedFilters.push({
    filterName: "Supporting Documents",
    options: supportingDocuments,
    filterType: AdvancedFilterTypes.DropDown,
  });

  if (hasFeature(allowedFeatures, SystemFeatures.AdvancedMySuppliersFilter)) {
    advancedFilters.push(
      {
        filterName: "My Suppliers",
        options: [
          {
            value: supplierFilterOptions.Existing,
            display: supplierFilterOptions.toString(supplierFilterOptions.Existing),
          },
          {
            value: supplierFilterOptions.Potential,
            display: supplierFilterOptions.toString(supplierFilterOptions.Potential),
          },
        ],
        filterType: AdvancedFilterTypes.MultiSelectDropdown,
      }
    );
  }
  return advancedFilters;
};
