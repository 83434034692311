import React, { useEffect, useState } from "react";
import {
  FormGroup, Label, Input, InputGroup,
} from "reactstrap";
import InputGroupImageAddon from "../InputGroupImageAddon/InputGroupImageAddon";

const DateInput = (
  {
    className = "",
    value,
    onChange,
    label,
    min,
    required,
    validationMessage,
  }
  :
  {
    value?: Date,
    onChange: (newValue: Date) => void,
    className?: string,
    label?: string,
    min?: Date,
    required?: boolean,
    validationMessage?: string,
  }
) => {
  const [localValue, setLocalValue] = useState("");
  const [focussed, setFocussed] = useState(false);

  const inputId = `${label}-${Math.random().toString(36).substring(2)}`;

  const getDateString = (dateTimeValue: Date | undefined) => {
    if (dateTimeValue == null) {
      return "";
    }

    const monthNumber = Number.isNaN(dateTimeValue.getMonth())
      ? (new Date()).getMonth()
      : dateTimeValue.getMonth();

    const dayNumber = Number.isNaN(dateTimeValue.getDate())
      ? (new Date()).getDate()
      : dateTimeValue.getDate();

    const year = Number.isNaN(dateTimeValue.getFullYear())
      ? (new Date()).getFullYear()
      : dateTimeValue.getFullYear();

    const month = `0${monthNumber + 1}`.slice(-2);
    const day = `0${dayNumber}`.slice(-2);
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (!focussed) {
      setLocalValue(getDateString(value));
    }
  }, [value, focussed, setLocalValue]);

  const onDateChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newDateString = e.target.value;
    newDateString = newDateString.replaceAll("/", "-");
    const newDateValue = new Date(newDateString);

    if (Number.isNaN(newDateValue.getTime())) {
      // invalid date. Don't call parent onchange
    } else {
      // valid date
      onChange(newDateValue);
    }

    setLocalValue(newDateString);
  };

  const minDateValue = min == null ? undefined : getDateString(min);

  return (
    <FormGroup className={className}>
      <Label for={`${inputId}-date`}>{label}</Label>
      <InputGroup>
        <InputGroupImageAddon icon="calendar" addonType="prepend" />
        <Input
          placeholder="yyyy-mm-dd"
          required={required}
          min={minDateValue}
          type="date"
          name={`${inputId}-date`}
          id={`${inputId}-date`}
          value={localValue}
          onChange={onDateChangeHandler}
          onFocus={() => { setFocussed(true); }}
          onBlur={() => {
            setFocussed(false);
          }}
        />
      </InputGroup>
      {
        validationMessage && (
          <div className="invalid-feedback">
            {validationMessage}
          </div>
        )
      }
    </FormGroup>
  );
};

export default DateInput;
