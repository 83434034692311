const supplierFilterOptions = {
  All: 0,
  Existing: 1,
  Potential: 2,

  toString: (value) => {
    switch (value) {
      case null:
        return "";
      case supplierFilterOptions.All:
        return "All Suppliers";
      case supplierFilterOptions.Existing:
        return "Existing Suppliers";
      case supplierFilterOptions.Potential:
        return "Potential Suppliers";
      default:
        return `${value}`;
    }
  },
};
export default supplierFilterOptions;
