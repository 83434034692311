const millisPerDay = 1000 * 60 * 60 * 24;

export function isBefore(considered, compared) {
  const d1Time = considered.getTime();
  const d2Time = compared.getTime();
  return d1Time < d2Time;
}

export function addDays(days, date) {
  const timeDays = days * millisPerDay;
  const timeDate = date.getTime();
  return new Date(timeDays + timeDate);
}
