import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert } from "reactstrap";
import { ModalPopup, Spinner } from "isuppli-react-components";
import { saveTermsAndConditions } from "../../http/posts";
import { getTermsAndConditionsAsync } from "../../http/gets";

import {
  showErrorDialog,
} from "../../Store/actions";

const mapStateToProps = (state) => ({
  data: { companyId: state.currentCompanyId, ...state.registration.prtInfo },
  invitationId: state.registration.supplierInfo.onboadingRequestInvitationId,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorDialog: () => {
    dispatch(showErrorDialog(true));
  },
});

class TermsConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sponsored: this.props?.sponsored ?? false,
      freeTier: this.props?.freeTier ?? false,
      agreeTC: true,
      loading: false,
      buyerList: [],
    };
    if (this.props.sponsored === null) {
      this.props.sponsored = false;
    }
  }

  async componentDidMount() {
    const termsAndConditions = await getTermsAndConditionsAsync(
      this.props.data.companyId
    );

    this.setState({
      buyerList: termsAndConditions.buyerList,
    });
  }

  onInputChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };

  onNextClick = () => {
    this.setState({
      loading: true,
    });
    saveTermsAndConditions(
      {
        ...this.state,
        ...this.props.data,
        registrationStepCompleted: 7,
        onboadingRequestInvitationId: this.props.invitationId,
      },
      () => {
        this.props.onAccepted();
      },
      () => {
        this.setState({
          loading: false,
        });
        this.props.showErrorDialog();
      }
    );
  };

  render() {
    return (
      <ModalPopup
        heading="FINALISE REGISTRATION"
        hasCancel
        size="large"
        onClose={this.props.toggleModalCallback}
        buttons={[
          {
            key: "submit",
            // eslint-disable-next-line no-nested-ternary
            label: this.state.sponsored ? "ACCEPT SPONSORSHIP & CONTINUE" : this.state.freeTier ? "SUBMIT & CONTINUE" : "SUBMIT & MAKE PAYMENT",
            onClick: () => this.onNextClick(),
            disabled: !this.state.agreeTC,
            color: "primary",
          },
        ]}
      >
        <Alert color="info">
          {this.state.buyerList.length === 0 ? "You are about to submit your company details to B1Link." : ""}
          {this.state.buyerList.length >= 1 ? `You are about to submit your company details to B1Link, ${this.state.buyerList.join(", ")}` : ""}
        </Alert>

        <hr />
        {this.state.loading && <Spinner />}
      </ModalPopup>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsConditions);
