import React, { useState, Fragment } from "react";
import { Navbar, NavbarToggler, Collapse } from "reactstrap";

// eslint-disable-next-line no-unused-vars
import { MenuItem } from "../../utils/interfaces/MenuItem";

import HeaderNavBar from "./HeaderNavBar/HeaderNavBar";

import { ReactComponent as SvgLogo } from "./logo.svg";
import { ReactComponent as SvgHamburger } from "./hamburger.svg";
import Styles from "./Header.module.scss";
import UserMaintenance from "./UserMaintenance/UserMaintenance";
import SystemFeatures from "../../utils/enums/SystemFeatures";

const Header = (
  {
    userGreeting = "",
    companyName = "",
    userBadge,
    isAuthenticated,
    allowedFeatures = SystemFeatures.None,
    menuItems,
    children = null,
    onLogout,
    onHeaderLogoClick,
    helpLink,
  }
  :
  {
    menuItems: Array<MenuItem>,
    allowedFeatures: SystemFeatures,
    userGreeting?: string,
    companyName?: string,
    userBadge: string | null,
    isAuthenticated: boolean,
    children?: React.ReactNode,
    onLogout: () => void,
    onHeaderLogoClick?: () => void,
    helpLink: string
  }
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState("");

  const toggle = () => setIsOpen(!isOpen);
  const setOpenSubMenuHandler = (newMenuValue: string) => {
    setOpenSubMenu((value) => (value === newMenuValue ? "" : newMenuValue));
  };

  const headerClasses = [Styles.Header];
  if (openSubMenu !== "") {
    headerClasses.push(Styles.Open);
  }
  return (
    <Fragment>
      <header className={headerClasses.join(" ")}>
        <div className={`${Styles.MainHeader} container-fluid d-flex px-4 align-items-center`}>
          <div>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                if (onHeaderLogoClick) {
                  onHeaderLogoClick();
                }
              }}
            >
              <SvgLogo className={`${Styles.Logo} text-white`} />
            </a>
          </div>
          <Navbar expand="lg" className="flex-fill align-self-stretch py-0">
            <div className="desktop-version h-100 w-100">
              <HeaderNavBar
                setOpenSubMenu={setOpenSubMenuHandler}
                openSubMenu={openSubMenu}
                allowedFeatures={allowedFeatures}
                menuItems={menuItems}
                onLogout={onLogout}
                userGreeting={userGreeting}
                companyName={companyName}
                userBadge={userBadge}
                isAuthenticated={isAuthenticated}
                helpLink={helpLink}
              />
            </div>
          </Navbar>
          { children }
          <div className="desktop-version align-self-stretch">
            {isAuthenticated && (
              <UserMaintenance
                allowedFeatures={allowedFeatures}
                setOpenSubMenu={setOpenSubMenu}
                openSubMenu={openSubMenu}
                menuItems={menuItems}
                onLogout={onLogout}
                userGreeting={userGreeting}
                companyName={companyName}
                userBadge={userBadge}
              />
            )}
          </div>

          <NavbarToggler
            className="mobile-version navbar-toggler custom-toggler"
            onClick={toggle}
          >
            <span className="navbar-toggler-icon">
              <SvgHamburger className="w-100 text-white" />
            </span>
          </NavbarToggler>
        </div>
        <Collapse isOpen={isOpen} navbar className="navbar mobile-version">
          <HeaderNavBar
            setOpenSubMenu={setOpenSubMenu}
            openSubMenu={openSubMenu}
            allowedFeatures={allowedFeatures}
            menuItems={menuItems}
            onLogout={onLogout}
            userGreeting={userGreeting}
            companyName={companyName}
            userBadge={userBadge}
            isAuthenticated={isAuthenticated}
            helpLink={helpLink}
          />
        </Collapse>
      </header>
    </Fragment>
  );
};

export default Header;
