import { Spinner, useToast } from "isuppli-react-components";
import React, {
  forwardRef,
  ForwardRefRenderFunction, // eslint-disable-line no-unused-vars
  Fragment,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import useShowError from "../../../Hooks/useShowError";
import useStaticData from "../../../Hooks/useStaticData";
import { getSupplierInfo, saveSupplierInfoAsync } from "../../../http";
import { StaticDataTypes } from "../../../Util/Enumerators/staticDataTypes";
import { WrappedRegistrationCompanyInfo as RegistrationCompanyInfo } from "../../Supplier/RegistrationCompanyInfo";

export interface CompanyDetailsModuleRef{
    save: () => Promise<boolean>;
  }

export interface CompanyDetailsModuleProps{
    companyId: number,
    readOnly?:boolean,
    isSubAccount?:boolean,
  }

const CompanyDetailsModule:
ForwardRefRenderFunction<CompanyDetailsModuleRef, CompanyDetailsModuleProps> = (
  { companyId = 0, readOnly = false, isSubAccount = false }: CompanyDetailsModuleProps, ref
) => {
  const [companyData, setCompanyData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [[companyTypes], isReady] = useStaticData([StaticDataTypes.CompanyType]);
  const showError = useShowError();
  const showToast = useToast();

  const onChangeInputHandler = (ev : any) => {
    setCompanyData((prevState: any) => ({
      ...prevState,
      [ev.target.name]: ev.target.value,
    }));
  };

  const saveCompanyDetails = async () => {
    setLoading(true);
    try {
      await saveSupplierInfoAsync({ ...companyData });
      setLoading(false);
      showToast("Company information saved");
      return true;
    } catch {
      setLoading(false);
      return false;
    }
  };

  useImperativeHandle(ref, () => ({
    save: saveCompanyDetails,
  }));

  useEffect(() => {
    const loader = async () => {
      setLoading(true);

      // api call
      try {
        getSupplierInfo(companyId, (res:any) => {
          setCompanyData(res);
        });
        setLoading(false);
      } catch {
        setLoading(false);
        showError();
      }
    };
    loader();
  }, [companyId, showError]);
  return (
    <Fragment>
      { loading && <Spinner />}
      <div className="p-5">
        <RegistrationCompanyInfo
          data={companyData}
          companyTypes={isReady ? companyTypes : []}
          readOnly={readOnly}
          isSubAccount={isSubAccount}
          additional={{}}
          onInputChangeCallback={onChangeInputHandler}
          setStepValid={() => {}}
          disableEditRegistrationName={companyData.disableEditRegistrationName}
          regNumberReadOnly={companyData.disableEditRegistrationInfo}
        />
      </div>
    </Fragment>
  );
};

export default forwardRef<CompanyDetailsModuleRef, CompanyDetailsModuleProps>(CompanyDetailsModule);
