import React from "react";

import Notification from "./Notification";
import Spinner from "../Spinner/Spinner";

function Notifications(props) {
  const notificationMessages = props.notifications.map((notification, i) => (
    <Notification
      key={i}
      isNew={!notification.seen}
      message={notification.message}
      date={notification.sentDate}
      onClick={() => { props.onItemClick(notification.notificationType, notification.linkUrl); }}
      onCloseCallback={() => { props.onDismiss(notification.id); }}
    />
  ));

  return (
    <section className="notifications-container p-3">
      <div className="notifications-title">
        <h2 className="Notifications">Notifications</h2>
      </div>
      {props.showLoading ? (
        <div>
          <Spinner inline />
        </div>
      ) : (
        <div>
          {
            props.notifications.length > 0
              ? notificationMessages
              : (
                <div className="pt-2">
                  <p className="m-0">You have no notifications at the moment.</p>
                </div>
              )
          }

        </div>
      )}
    </section>
  );
}

export default Notifications;
