import React from "react";
import { SystemFeatures, FeatureRoute } from "isuppli-react-components";
import { Route, Switch, Redirect } from "react-router-dom";

import OnboardingRequest from "../Sections/Marketplace/OnboardingRequest";
import OnboardingRequestEdit from "../Sections/Marketplace/OnboardingRequestEdit";
import SupplierChecks from "../Sections/Marketplace/SupplierChecks/SupplierChecks";
import PPReport from "../Sections/MyOffice/PPReport/PPReport";
import SupplierUpdates from "../Sections/MyOffice/SupplierUpdates/SupplierUpdates";

function MyOfficeView() {
  return (
    <section className="h-100">
      <Switch>
        <FeatureRoute
          feature={
            // eslint-disable-next-line no-bitwise
            SystemFeatures.OnboardingApprove1
            | SystemFeatures.OnboardingApprove2
            | SystemFeatures.OnboardingCreateRequest
            | SystemFeatures.OnboardingAddSupplierApproval
            | SystemFeatures.AssignManualManualVendorNumber
            | SystemFeatures.ApproveDelegatedOnboardingRequestApproval2
            | SystemFeatures.OnboardingCompletionCommunication
          }
          path="/myoffice/onboardingrequest"
          exact
          strictFeatureCheck={false}
          component={OnboardingRequest}
        />
        <FeatureRoute
          path="/myoffice/onboardingrequest/:id"
          feature={SystemFeatures.OnboardingCreateRequest}
        >
          <Switch>
            <Route path="/myoffice/onboardingrequest/:id/:step">
              <OnboardingRequestEdit />
            </Route>
            <Route>
              <OnboardingRequestEdit />
            </Route>
          </Switch>
        </FeatureRoute>
        <FeatureRoute
          path="/myoffice/checks"
          feature={SystemFeatures.SupplierChecks}
          component={SupplierChecks}
        />
        <FeatureRoute
          path="/myoffice/ppreport"
          feature={SystemFeatures.PPReportDashboard}
          component={PPReport}
        />
        <FeatureRoute
          path="/myoffice/supplierupdates"
          component={SupplierUpdates}
        />
        <Redirect to="/myoffice/onboardingrequest" />
      </Switch>
    </section>
  );
}

export default MyOfficeView;
