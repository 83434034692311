import React from "react";
import Styles from "./ImageText.module.scss";

const ImageText = (
  {
    className = "",
    svg,
    text,
  }
    :
    {
      className?: string,
      svg: SvgrComponent,
      text: string,
    }
) => {
  const SvgImage = svg;
  return (
    <div
      className={`${className} ${Styles.ImageText}`}
    >
      <SvgImage className={Styles.Image} />
      {text}
    </div>
  );
};

export default ImageText;
