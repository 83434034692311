import React, { useRef } from "react";
import Styles from "./ModalBackground.module.scss";

const ModalBackground = ({
  children,
  transparent = false,
  onClick,
}: {
  children: JSX.Element,
  transparent?: boolean,
  onClick?: (() => void),
}) => {
  const background = useRef<HTMLDivElement>(null);

  const clickHandler = (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => {
    // make sure the target is the background
    if (event.target === background.current && onClick != null) {
      onClick();
    }
  };

  return (
    <div
      tabIndex={0}
      onKeyDown={clickHandler}
      role="button"
      ref={background}
      className={`${Styles.ModalBackground} ${transparent ? Styles.ModalBackgroundTransparent : null}`}
      onClick={clickHandler}
    >
      {children}
    </div>
  );
};

export default ModalBackground;
