import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import {
  Table,
  TableActionsItem,
  SystemFeatures,
  SearchFiltersContainer,
  ConfirmationModal,
  hasFeature,
  withToast,
  ListEmptyState,
} from "isuppli-react-components";
import { setOption } from "../../Store/optionActions";
import {
  showLoadingScreen,
  hideLoadingScreen,
  showErrorDialog,
} from "../../Store/actions";
import { setOnboardingRequestObject } from "../../Store/onboardingRequestActions";

import {
  sendCompanySearch,
  createOnboardingRequestsForSuppliers,
  addBusinessUnitsForExistingSupplier,
  submitSupplierExportRequest,
  sendLargeCompanySearch,
  getSupplierExportStatus,
} from "../../http/index";

import {
  initiateOpportunity,
  exportCSV,
} from "../../http/SupplierSearch/supplierSearchApi";

import { getStaticValue, formatPercentage } from "../../Util/valueFormatter";
import searchSupplierSortingFields from "../../Util/Enumerators/searchSupplierSortingFields";
import supplierStatuses from "../../Util/Enumerators/supplierStatuses";
import SupplierExportStatus from "../../Util/Enumerators/SupplierExportStatus";

import Loading from "../Loading";
import SupplierDetailsModal from "../SupplierDetailsModal";

import MarketplaceSearch from "../../Containers/Marketplace/MarketplaceSearch";
import ComposeMessageModal from "../../Containers/SupplierSearch/CompseMessageModal/ComposeMessageModal";
import AddSupplierModal from "../Marketplace/AddSupplierModal/AddSupplierModal";

import {
  getTableConfig,
  sortingValues,
  getFilterProps,
  getAdvancedFilterProps,
} from "./SupplierSearchConfiguration";

import staticCompanyDataTypes from "../../Util/Enumerators/staticCompanyDataTypes";
import StaticCompanyData from "../../Hooks/StaticCompanyData";

import { StaticDataTypes } from "../../Util/Enumerators/staticDataTypes";
import StaticData from "../../Hooks/StaticData";

import EditManualSupplier from "../../Containers/ManualSupplier/EditManualSupplier/EditManualSupplier";
import SubAccount, { SubAccountOperation } from "../../Containers/SubAccount/SubAccount";
import RateCompanyModal from "../../Containers/RateCompanyModal/RateCompanyModal";
import { getUnspscInfo } from "../../Util/dataTransform";
import OfferingSelector from "../../Containers/Supplier/OfferingSelector/OfferingSelector";
import { getSupplierCategoriesSelectionAsBuyer, saveClientSpecificProductCategories } from "../../http/SupplierCategory/supplierCategoryAPI";
import supplierFilterOptions from "../../Util/Enumerators/supplierFilterOptions";

const mapStateToProps = (state) => ({
  companyId: state.currentCompanyId,
  provinces: state.staticData[StaticDataTypes.Province] ?? [],
  beeLevels: state.staticData[StaticDataTypes.BeeStatus] ?? [],
  supplierCategories:
    state.staticCompanyData[staticCompanyDataTypes.supplierCategory] ?? [],
  businessUnits: (
    state.staticCompanyData[staticCompanyDataTypes.businessUnit] ?? []
  ).filter((businessUnit) => state.loggedInUserDetails.businessUnitIds.includes(businessUnit.id)),
  beeSectors: state.staticData[StaticDataTypes.BeeClassification] ?? [],
  unspscSegments: state.staticData[StaticDataTypes.UnspscSegment] ?? [],
  unspscFamilies: state.staticData[StaticDataTypes.UnspscFamily] ?? [],
  subscriptionTiers: state.staticData[StaticDataTypes.SubscriptionTier] ?? [],
  companyConfiguration: state.companyConfiguration,
  searchLimit: state.loggedInUserDetails.searchLimit,
  allowedFeatures: state.loggedInUserDetails.allowedFeatures,
  supplierTypes: state.staticCompanyData[staticCompanyDataTypes.supplierType] ?? [],
  supplierTransactionStatuses: state.staticCompanyData[staticCompanyDataTypes.supplierTransactionStatuses] ?? [],
  companyStatuses: state.staticData[StaticDataTypes.CompanyStatus] ?? [],
  supportingDocuments: state.staticCompanyData[staticCompanyDataTypes.supportingDocuments] ?? [],
});

const mapDispatchToProps = (dispatch) => ({
  setOption: (optionName, optionValues) => {
    dispatch(setOption(optionName, optionValues));
  },
  setOnboardingRequestObject: (onboardingRequest) => {
    dispatch(setOnboardingRequestObject(onboardingRequest));
  },
  showLoadingScreen: (msg) => {
    dispatch(showLoadingScreen(msg));
  },
  hideLoadingScreen: () => {
    dispatch(hideLoadingScreen());
  },
  showErrorDialog: () => {
    dispatch(showErrorDialog(true));
  },
});

const buildSearchString = ({
  limit,
  offset,
  searchTerm,
  filters,
  sortOption,
  statusFilterOption,
  additionalStatusFilterOption,
}) => {
  const searchTermQueryPart = searchTerm == null || searchTerm === "" ? "" : `&q=${searchTerm}`;

  const filtersQueryPart = filters == null || filters.length === 0
    ? ""
    : `&f=${JSON.stringify(filters)}`;

  const sortQueryPart = sortOption == null ? "" : `&s=${sortOption}`;
  const mySupplierFilter = filters?.length >= 24 && filters[24].length > 0 && (
    // eslint-disable-next-line no-nested-ternary
    filters[24].length > 1 ? supplierFilterOptions.All
      : filters[24].length === 1 ? filters[24][0]
        : null
  );
  const statusFilterPart = sortOption == null ? "" : `&sf=${mySupplierFilter ?? statusFilterOption}`;
  const additionalStatusFilterPart = sortOption === null ? "" : `&asf=${additionalStatusFilterOption}`;
  return `?limit=${limit}&offset=${offset}${searchTermQueryPart}${filtersQueryPart}${sortQueryPart}${statusFilterPart}${additionalStatusFilterPart}`;
};

const getCompanyModalDetails = (props) => {
  const params = new URLSearchParams(props.location.search);
  const companyId = parseInt(params.get("companyId"), 10);
  const registeredName = params.get("registeredName");

  if (companyId == null || Number.isNaN(companyId) || registeredName == null) {
    return null;
  }
  return {
    companyId,
    registeredName,
  };
};

const getEditManualSupplierId = (props) => {
  const params = new URLSearchParams(props.location.search);
  const manualSupplierId = parseInt(params.get("manualSupplierId"), 10);

  if (manualSupplierId == null || Number.isNaN(manualSupplierId)) {
    return null;
  }
  return manualSupplierId;
};

const readSearchString = (props) => {
  const params = new URLSearchParams(props.location.search);
  let limit = parseInt(params.get("limit"), 10);
  if (limit == null || Number.isNaN(limit)) {
    limit = 20;
  }

  let offset = parseInt(params.get("offset"), 10);
  if (offset == null || Number.isNaN(offset)) {
    offset = 0;
  }

  let searchTerm = params.get("q");
  if (searchTerm == null) {
    searchTerm = "";
  }

  let filters = JSON.parse(params.get("f"));
  if (filters == null) {
    filters = [];
  }

  let sortOption = parseInt(params.get("s"), 10);
  if (sortOption == null || Number.isNaN(sortOption)) {
    sortOption = sortingValues.relevance;
  }

  let statusFilterOption = parseInt(params.get("sf"), 10);
  if (statusFilterOption == null || Number.isNaN(statusFilterOption)) {
    statusFilterOption = 0;
  }

  let additionalStatusFilterOption = parseInt(params.get("asf"), 10);
  if (additionalStatusFilterOption == null || Number.isNaN(additionalStatusFilterOption)) {
    additionalStatusFilterOption = 0;
  }

  return {
    limit,
    offset,
    searchTerm,
    filters,
    sortOption,
    statusFilterOption,
    additionalStatusFilterOption,
  };
};

class SupplierSearch extends Component {
  async componentDidMount() {
    const hasOnboardSupplierFeature = hasFeature(
      this.props.allowedFeatures,
      SystemFeatures.OnboardingAddSupplier
    );
    const hasOnboardingCreateRequestFeature = hasFeature(
      this.props.allowedFeatures,
      SystemFeatures.OnboardingCreateRequest
    );
    const hasSupplierExportFeature = hasFeature(
      this.props.allowedFeatures,
      SystemFeatures.SupplierExport
    );
    if (hasOnboardSupplierFeature) {
      this.setState({
        canAddSupplierOnboarding: true,
      });
    }

    if (hasOnboardingCreateRequestFeature) {
      this.setState({
        canAddOnboardingRequest: true,
        canAddSupplierOnboarding: false,
      });
    }
    if (hasSupplierExportFeature) {
      this.setState({
        canRequestSupplierExport: true,
      });
    }

    const exportStatus = await getSupplierExportStatus();
    this.setState({ supplierExportStatus: exportStatus });

    await this.performSearch();
  }

  showUnlinkVendorModal = (
    supplierId,
    registeredCompanyName
  ) => {
    if (this.state.canAddSupplierOnboarding) {
      this.setState({
        showAddSupplierModal: true,
        companyIdToPerformAction: supplierId,
        isEditing: true,
        registeredCompanyName,
      });
    }
  };

  createTableConfig = () => getTableConfig(
    [
      <TableActionsItem
        hideInTableHeader
        feature={SystemFeatures.OnboardingCreateRequest}
        key="onBoard"
        availableWhenAllSelected={false}
        shouldDisplay={(item) => this.state.canAddOnboardingRequest
          && item.supplierStatus === supplierStatuses.potential}
        onClick={(items, allSelected) => {
          this.checkOnBoardSupplierDuplicates(items, allSelected);
        }}
      >
        Onboard Company
      </TableActionsItem>,
      <TableActionsItem
        hideInTableHeader
        feature={SystemFeatures.OnboardingAddSupplier}
        key="onBoardSupplier"
        availableWhenAllSelected={false}
        shouldDisplay={(item) => this.state.canAddSupplierOnboarding
          && item.supplierStatus === supplierStatuses.potential}
        onClick={(items) => {
          this.setState((prevState) => {
            const companyName = prevState.searchResults.items.find(
              (x) => x.id === items[0]
            ).registeredName;
            return {
              showAddSupplierModal: true,
              companyIdToPerformAction: items[0],
              isEditing: false,
              registeredCompanyName: companyName,
            };
          });
        }}
      >
        Onboard Company
      </TableActionsItem>,
      <TableActionsItem
        hideInTableHeader
        feature={SystemFeatures.OnboardingAddSupplier}
        key="unlinkCompany"
        availableWhenAllSelected={false}
        shouldDisplay={(item) => this.state.canAddSupplierOnboarding
          && item.supplierStatus === supplierStatuses.existing}
        onClick={(items) => {
          const companyName = this.state.searchResults.items.find(
            (x) => x.id === items[0]
          ).registeredName;
          this.showUnlinkVendorModal(items[0], companyName);
        }}
      >
        Unlink supplier
      </TableActionsItem>,
      <TableActionsItem
        hideInTableHeader
        disabled={this.props.businessUnits?.length <= 0}
        feature={SystemFeatures.OnboardingCreateRequest}
        key="addBusinessUnit"
        availableWhenAllSelected={false}
        shouldDisplay={(item) => item.supplierStatus === supplierStatuses.existing
          && item.parentAccountId == null}
        onClick={(items) => {
          this.checkAddBusinessUnits(items);
        }}
      >
        Add business unit for supplier
      </TableActionsItem>,
      <TableActionsItem
        hideInTableHeader={!hasFeature(
          this.props.allowedFeatures,
          SystemFeatures.BroadcastMessaging
        )}
        availableWhenAllSelected={hasFeature(
          this.props.allowedFeatures,
          SystemFeatures.BroadcastMessaging
        )}
        availableWhenPageSelected={hasFeature(
          this.props.allowedFeatures,
          SystemFeatures.BroadcastMessaging
        )}
        key="message"
        feature={SystemFeatures.Messaging}
        shouldDisplay={(item) => !item.manualSupplier}
        onClick={(items, allSelected) => {
          this.broadcastSupplierMessages(items, allSelected);
        }}
      >
        Message company
      </TableActionsItem>,
      <TableActionsItem
        hideInTableHeader
        feature={SystemFeatures.SubAccounts}
        key="addSubAccount"
        availableWhenAllSelected={false}
        shouldDisplay={(item) => item.supplierStatus === supplierStatuses.existing
        && item.parentAccountId == null && !item.manualSupplier}
        onClick={(items) => {
          this.setState({
            companyIdToPerformAction: items[0],
            subAccountOperation: SubAccountOperation.Add,
          });
          this.toggleShowSubAccountModal();
        }}
      >
        Add Sub-Account
      </TableActionsItem>,
      <TableActionsItem
        hideInTableHeader
        feature={SystemFeatures.SubAccounts}
        key="editTradingName"
        availableWhenAllSelected={false}
        shouldDisplay={(item) => item.parentAccountId != null}
        onClick={(items) => {
          this.setState((prevState) => ({
            companyIdToPerformAction: items[0],
            subAccountOperation: SubAccountOperation.Update,
            subAccountTradingName: prevState.searchResults.items
              .find((c) => c.id === items[0]).tradingName,
          }));
          this.toggleShowSubAccountModal();
        }}
      >
        Edit Trading Name
      </TableActionsItem>,
      <TableActionsItem
        hideInTableHeader
        feature={SystemFeatures.ConfigurationSupplierEditClientSpecificCategories}
        key="editCompanyProductOfferings"
        availableWhenAllSelected={false}
        shouldDisplay={(item) => item.supplierStatus === supplierStatuses.existing
          && item.parentAccountId == null}
        onClick={(item) => {
          this.toggleShowEditProductOfferingModal(item[0]);
        }}
      >
        Edit Company Product Offerings
      </TableActionsItem>,
      <TableActionsItem
        hideInTableHeader
        feature={SystemFeatures.manualSupplier}
        key="editManualSupplier"
        availableWhenAllSelected={false}
        shouldDisplay={(item) => item.manualSupplier && item.parentAccountId == null}
        onClick={(items) => {
          this.toggleEditManualSupplierModal(items[0]);
        }}
      >
        Edit Manual Supplier
      </TableActionsItem>,
      <TableActionsItem
        feature={SystemFeatures.RfxCreateOpportunity}
        key="initiateERFXOpportunity"
        availableWhenPageSelected
        availableWhenAllSelected
        shouldDisplay={(item) => item.parentAccountId == null}
        onClick={(items, allSelected, itemObjects) => {
          this.initiateOpportunityConfirmationHandler(items, allSelected, itemObjects);
        }}
      >
        Create eRFX Opportunity
      </TableActionsItem>,
      <TableActionsItem
        hideInTableHeader
        key="rateCompany"
        availableWhenAllSelected={false}
        shouldDisplay={(item) => item.isBuyer === true
          || item.supplierStatus === supplierStatuses.existing}
        onClick={(items) => {
          this.setState((prevState) => {
            const companyName = prevState.searchResults.items.find(
              (x) => x.id === items[0]
            ).registeredName;
            return {
              showRateCompanyModal: true,
              companyIdToPerformAction: items[0],
              registeredCompanyName: companyName,
            };
          });
        }}
      >
        Rate Company
      </TableActionsItem>,
      <TableActionsItem
        hideInTableHeader={!hasFeature(
          this.props.allowedFeatures,
          SystemFeatures.CanExportCSV
        )}
        availableWhenAllSelected={hasFeature(
          this.props.allowedFeatures,
          SystemFeatures.CanExportCSV
        )}
        availableWhenPageSelected={hasFeature(
          this.props.allowedFeatures,
          SystemFeatures.CanExportCSV
        )}
        feature={SystemFeatures.CanExportCSV}
        key="exportCSV"
        shouldDisplay={() => false}
        onClick={this.exportCSVHandler}
      >
        Export CSV
      </TableActionsItem>,
    ],
    this.showUnlinkVendorModal,
    this.props.allowedFeatures,
    this.props.supplierTransactionStatuses
  );

  constructor(props) {
    super(props);
    this.state = {
      searchResults: {},
      loading: false,
      previousSearchString: "",
      currentSearchTerm: "",
      showDuplicateOnboardingConfirmModal: false,
      showAddBusinessUnitsConfirmModal: false,
      showAddedBusinessUnitsModal: false,
      showAllBusinessUnitsAlreadyAddedModal: false,
      showInitiateOpportunityConfirmationModal: false,
      showCsvExportWarningModal: false,
      showSupplierExportModal: false,
      showSubAccountModal: false,
      showRateCompanyModal: false,
      showAddSupplierModal: false,
      showEditProductOffering: false,
      supplierStatus: 0,
      isEditing: false,
      registeredCompanyName: "",
      canAddSupplierOnboarding: false,
      canAddOnboardingRequest: false,
      canRequestSupplierExport: false,
      onboardingIds: [],
      showMessageComposeModal: false,
      messageRecipients: { recipients: [], sendToAll: false },
      filters: [],
      availableBusinessUnits: [],
      companyIdToPerformAction: 0,
      subAccountOperation: SubAccountOperation.Add,
      initiateOpportunityCompanyIds: [],
      initiateOpportunityModalDescription: "",
      disableSupplierExportRequest: false,
      currentSupplierCategoriesList: [],
      csvExportLimit: 0,
      showSearchLimitModal: false,
      supplierExportStatus: 0,
    };
  }

  checkOnBoardSupplierDuplicates = (supplierIds, allSelected) => {
    if (allSelected) {
      return;
    }
    if (supplierIds.length > 1) {
      // multiple suppliers selected
      const potentialSupplierIds = this.state.searchResults.items
        .filter(
          (c) => supplierIds.includes(c.id)
            && c.supplierStatus === supplierStatuses.potential
        )
        .map((c) => c.id);

      if (potentialSupplierIds.length !== supplierIds.length) {
        // show warning
        this.setState({
          onboardingIds: potentialSupplierIds,
        });
        this.toggleDuplicateOnboardingConfirmModal();
        return;
      }
    }

    this.onBoardSuppliers(supplierIds);
  };

  checkAddBusinessUnits = (items) => {
    // check business units
    const supplierId = items[0];
    const businessUnitIds = this.state.searchResults.items.find(
      (c) => c.id === supplierId
    ).businessUnitIds;

    const unselectedBusinessUnits = this.props.businessUnits.filter(
      (businessUnit) => !businessUnitIds.includes(businessUnit.id)
    );

    if (unselectedBusinessUnits.length > 0) {
      this.setState({
        companyIdToPerformAction: supplierId,
        availableBusinessUnits: unselectedBusinessUnits,
      });
      this.toggleAddBusinessUnitsConfirmModal();
    } else {
      this.toggleAllBusinessUnitsAlreadyAddedModal();
    }
  };

  onBoardSuppliers = async (supplierIds) => {
    this.props.showLoadingScreen();

    try {
      const createdRequestIds = await createOnboardingRequestsForSuppliers(
        supplierIds
      );
      if (createdRequestIds.length === 1) {
        this.props.history.push(
          `/myoffice/onboardingrequest/${createdRequestIds[0]}`
        );
      } else {
        this.props.history.push("/myoffice/onboardingrequest/");
      }
    } catch (error) {
      this.props.showErrorDialog();
    }

    this.props.hideLoadingScreen();
  };

  broadcastSupplierMessages = (selectedIds, allSelected) => {
    this.setState((prevState) => ({
      messageRecipients: {
        recipients: allSelected
          ? []
          : prevState.searchResults?.items
              ?.filter((c) => selectedIds.includes(c.id))
              ?.map((c) => ({
                name: c.registeredName,
                id: c.id,
              })),
        sendToAll: allSelected,
      },
      showMessageComposeModal: true,
    }));
  };

  toggleViewMoreModal = (company) => {
    const searchParams = new URLSearchParams(this.props.location.search);

    if (company == null) {
      searchParams.delete("companyId");
      searchParams.delete("registeredName");
    } else {
      searchParams.set("companyId", company.companyId);
      searchParams.set("registeredName", company.registeredName);
    }
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: searchParams.toString(),
    });
  };

  toggleEditManualSupplierModal = (companyId) => {
    const searchParams = new URLSearchParams(this.props.location.search);

    if (companyId == null) {
      searchParams.delete("manualSupplierId");
    } else {
      searchParams.set("manualSupplierId", companyId);
    }
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: searchParams.toString(),
    });
  };

  continueOnboardingMultiple() {
    this.onBoardSuppliers(this.state.onboardingIds);
  }

  async addBusinessUnits(newValue) {
    const businessUnitMessage = {
      supplierCompanyId: this.state.companyIdToPerformAction,
      businessUnitIds: newValue,
    };

    try {
      // api call
      await addBusinessUnitsForExistingSupplier(businessUnitMessage);

      // add newly added business unit(s) to search item in state
      this.setState((prevState) => ({
        ...prevState,
        searchResults: {
          ...prevState.searchResults,
          items: prevState.searchResults.items.map((item) => ({
            ...item,
            businessUnitIds:
              item.id === prevState.companyIdToPerformAction
                ? [...item.businessUnitIds, ...newValue]
                : [...item.businessUnitIds],
          })),
        },
      }));

      this.toggleAddedBusinessUnitsModal();
    } catch (error) {
      this.props.showErrorDialog();
    }
  }

  changeSearchRoute({
    limit,
    offset,
    searchTerm,
    filters,
    sortOption,
    statusFilterOption,
    additionalStatusFilterOption,
  }) {
    const currentSearchProps = readSearchString(this.props);

    const newSearchProps = { ...currentSearchProps };
    if (limit != null) {
      newSearchProps.limit = limit;
    }

    if (offset != null) {
      newSearchProps.offset = offset;
    }
    if (searchTerm != null) {
      newSearchProps.searchTerm = encodeURIComponent(searchTerm);
    }

    if (filters != null) {
      newSearchProps.filters = filters;
    }

    if (sortOption != null) {
      newSearchProps.sortOption = sortOption;
    }

    if (statusFilterOption != null) {
      newSearchProps.statusFilterOption = statusFilterOption;
    }

    if (additionalStatusFilterOption !== null) {
      newSearchProps.additionalStatusFilterOption = additionalStatusFilterOption;
    }

    // only change route if the search is different
    const searchString = buildSearchString(newSearchProps);

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: searchString,
    });
  }

  onSearchHandler = () => {
    // Force searching again, even if the value did not change
    this.setState({
      previousSearchString: "",
    });
    this.changeSearchRoute({
      searchTerm: this.state.currentSearchTerm,
      offset: 0,
    });
  };

  onSearchTermChangeHander(searchTerm) {
    this.setState({
      currentSearchTerm: searchTerm,
    });
  }

  onPerformSearch() {
    // Force searching again, even if the value did not change
    this.setState({
      previousSearchString: "",
      searchResults: {}, // Clear search results
    });
    this.changeSearchRoute({
      filters: this.state.filters,
      searchTerm: this.state.currentSearchTerm,
      offset: 0,
    });
  }

  onFiltersClearHandler() {
    // clear search values
    this.setState({
      currentSearchTerm: "",
      filters: [],
    });

    // go back to initial route
    this.props.history.push({
      pathname: this.props.location.pathname,
    });
  }

  onPageChangeHandler(newLimit, newOffset) {
    this.changeSearchRoute({
      limit: newLimit,
      offset: newOffset,
    });
  }

  onSortChangeHandler(sortOption) {
    this.changeSearchRoute({
      sortOption,
    });
  }

  onStatusFilterChangeHandler(statusFilterOption) {
    this.changeSearchRoute({
      statusFilterOption,
      offset: 0,
    });
  }

  onAdditionalStatusFilterChangeHandler(additionalStatusFilterOption) {
    this.changeSearchRoute({
      additionalStatusFilterOption,
      offset: 0,
    });
  }

  initiateOpportunityConfirmationHandler = (companyIds, isAllSelected, companyObjects) => {
    // Added this variable due to eslint getting upset about no-param-reassign.
    let companyObjectsToProcess = companyObjects;

    if (!companyObjectsToProcess) {
      companyObjectsToProcess = this.state.searchResults.items.filter((io) => io.id === companyIds[0]);
    }

    // check companyIds for invalid suppliers
    let invalidSupplierFound = false;
    const invalidSuppliers = [];

    companyIds.forEach((companyId) => {
      const company = companyObjectsToProcess.find(
        (c) => c.id === companyId
      );

      if (company.parentAccountId !== null) {
        invalidSupplierFound = true;
        invalidSuppliers.push(company.id);
      }
    });

    const suppliersToSend = companyIds.filter((i) => !invalidSuppliers.includes(i));

    let modalDescription = "Would you like to initiate opportunity for these suppliers?";
    if (invalidSupplierFound) {
      modalDescription = `Invalid suppliers detected in the supplier selection.
        ${invalidSuppliers.length} supplier/s flagged as sub-accounts were removed.
        Sub-accounts are not eligible to be sent eRFx opportunities.`;
    }

    // save companyIds and modal description in state
    this.setState({
      initiateOpportunityCompanyIds: suppliersToSend,
      initiateOpportunityModalDescription: modalDescription,
    });

    this.toggleInitiateOpportunityConfirmationModal();
  }

  initiateOpportunityHandler = async () => {
    // Guard statement to prevent sending eRFx empty requests.
    if (this.state.initiateOpportunityCompanyIds <= 0) return;

    this.props.showLoadingScreen();
    const erfxWindow = window.open(`${window.location.origin}/redirect/LoadingERfx`);
    let url = "";
    try {
      // api call
      url = await initiateOpportunity(this.state.initiateOpportunityCompanyIds);
    } catch (error) {
      this.props.showErrorDialog();
    }
    this.props.hideLoadingScreen();
    erfxWindow.location.href = url;
  };

  exportCSVHandler = async (items) => {
    if (items === null || items <= 0) return;

    if (this.state.csvExportLimit === null || Number.isNaN(this.state.csvExportLimit)) {
      this.setState({
        csvExportLimit: 3500, // Setting to the value as per the User Story 659.
      });
    }

    if (items.length > this.state.csvExportLimit) {
      this.toggleCsvExportWarningModal();
      return;
    }

    this.props.showLoadingScreen();
    try {
      // api call
      const content = await exportCSV(items);
      // setup and download csv file
      const csvContent = `data:text/csv;charset=utf-8,${content}`;
      const encodedUri = encodeURI(csvContent);
      // create hyperlink
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "supplier_export.csv");
      document.body.appendChild(link); // Required for FF
      // force click to download file
      link.click();
    } catch (error) {
      this.props.showErrorDialog();
    }
    this.props.hideLoadingScreen();
  };

  setNewTradingNameHandler(newTradingName) {
    this.setState((prevState) => {
      const newItems = prevState.searchResults.items.map((item) => {
        if (item.id === prevState.companyIdToPerformAction) {
          return {
            ...item,
            tradingName: newTradingName,
          };
        }
        return { ...item };
      });
      return {
        searchResults: {
          ...prevState.searchResults,
          items: newItems,
        },
      };
    });
  }

  formatValues(searchResults) {
    return {
      ...searchResults,
      items:
        searchResults.items == null
          ? []
          : searchResults.items.map((company) => ({
            ...company,
            beeClassification:
                company.beeClassification !== 0
                  ? getStaticValue(
                    company.beeClassification,
                    this.props.beeSectors
                  )
                  : "n/a",

            beeStatus: company.beeStatus
              ? getStaticValue(company.beeStatus, this.props.beeLevels)
              : "n/a",
            boPercentage: company.boPercentage != null && company.boPercentage >= 0
              ? formatPercentage(company.boPercentage)
              : "n/a",
            bwoPercentage: company.bwoPercentage != null && company.bwoPercentage >= 0
              ? formatPercentage(company.bwoPercentage)
              : "n/a",
          })),
    };
  }

  buildSearchQueryModel = ({
    limit,
    offset,
    searchTerm,
    filters,
    sortOption,
    statusFilterOption,
    additionalStatusFilterOption,
  }) => {
    // advanced search
    const registrationNumber = filters[6];
    const vatNumber = filters[7];
    const vendorNumber = filters[8];
    const contactPerson = filters[9];
    const emailAddress = filters[10];
    const dateAdded = filters[11] === null ? undefined : filters[11];
    const supplierType = filters[12] === null ? undefined : filters[12];
    const beeClassification = filters[13] === null ? undefined : filters[13];
    const beeStateFilter = filters[14] || 0;
    const complianceFilter = filters[15] || 0;
    const complianceStatus = filters[16] || 0;
    const beeOwnership = filters[17] || 0;
    const designatedGroup = filters[18] || null;
    const companyUnspscSegments = this.props.companyConfiguration
      .prioritiseClientOfferings
      ? filters[19]
      : filters[1];
    const companyUnspscFamilies = this.props.companyConfiguration
      .prioritiseClientOfferings
      ? filters[20]
      : filters[2];
    const subscriptionTier = filters[21] || null;
    const companyStatus = filters[22] || null;
    const supportingDocument = filters[23] || null;
    const mySuppliersFilter = filters[24] || null;
    const contractAgreement = filters[25] || null;
    const contractAgreementApproved = filters[26] || null;
    const onboardingStatusSummary = filters[27] || null;

    // sorting
    let sortField;
    let sortDescending;
    switch (sortOption) {
      case sortingValues.relevance:
        sortField = searchSupplierSortingFields.relevance;
        sortDescending = false;
        break;
      case sortingValues.newestFirst:
        sortField = searchSupplierSortingFields.dateModified;
        sortDescending = true;
        break;
      case sortingValues.oldestFirst:
        sortField = searchSupplierSortingFields.dateModified;
        sortDescending = false;
        break;
      case sortingValues.registeredName:
        sortField = searchSupplierSortingFields.registeredName;
        sortDescending = false;
        break;
      case sortingValues.rating:
        sortField = searchSupplierSortingFields.supplierRating;
        sortDescending = true;
        break;
      default:
        break;
    }

    // eslint-disable-next-line no-return-assign
    return {
      // page
      limit,
      offset,
      // filters
      searchText: searchTerm,
      provinceIDs: filters[0],
      supplierCategories: this.props.companyConfiguration
        .prioritiseClientOfferings
        ? filters[1]
        : filters[18],
      supplierSubCategories: this.props.companyConfiguration
        .prioritiseClientOfferings
        ? filters[2]
        : filters[19],
      beeStatusIDs: filters[3],
      boPercentages: filters[4],
      bwoPercentages: filters[5],
      // advanced search
      RegistrationNumber: registrationNumber,
      VatNumber: vatNumber,
      VendorNumber: vendorNumber,
      ContactPerson: contactPerson,
      EmailAddress: emailAddress,
      DateAdded: dateAdded,
      BeeClassification: beeClassification,
      BeeStateFilter: beeStateFilter,
      ComplianceFilter: complianceFilter,
      ComplianceStatus: complianceStatus,
      DesignatedGroup: designatedGroup,
      BeeOwnership: beeOwnership,
      CompanyUnspscSegments: companyUnspscSegments,
      CompanyUnspscFamilies: companyUnspscFamilies,
      SupplierType: supplierType,
      SubscriptionTier: subscriptionTier,
      SupportingDocument: supportingDocument,
      CompanyStatus: companyStatus,
      ContractAgreement: contractAgreement,
      ContractAgreementApproved: contractAgreementApproved,
      MySuppliersFilter: mySuppliersFilter,
      // sorting
      sortingField: sortField,
      sortingDesc: sortDescending,
      supplierSearchFilter: mySuppliersFilter?.length === 1 ? mySuppliersFilter[0] : statusFilterOption,
      additionalStatusSearchFilter: additionalStatusFilterOption,
      OnboardingStatusSummary: onboardingStatusSummary,
    };
  }

  getSearchQueryModel({
    limit,
    offset,
    searchTerm,
    filters,
    sortOption,
    statusFilterOption,
    additionalStatusFilterOption,
  }) {
    return this.buildSearchQueryModel({
      limit,
      offset,
      searchTerm,
      filters,
      sortOption,
      statusFilterOption,
      additionalStatusFilterOption,
    });
  }

  async getSearchResults(searchQueryModel) {
    try {
      const results = await sendCompanySearch(searchQueryModel);

      return this.formatValues(results);
    } catch (error) {
      this.props.showErrorDialog();
      return null;
    }
  }

  async getSearchResultsForLargeVolumeQuery(searchQueryModel) {
    try {
      const results = await sendLargeCompanySearch(searchQueryModel);
      return results;
    } catch (error) {
      this.props.showErrorDialog();
      return null;
    }
  }

  async performSearch() {
    const searchQueries = this.props.location.search;
    if (this.props.location.search === "") {
      // reset the state if required
      if (this.state.searchResults.items != null) {
        this.setState({
          searchResults: {},
          filters: [],
          previousSearchString: this.props.location.search,
        });
      }

      return;
    }

    if (this.props.location.search === this.state.previousSearchString) {
      // search has already been executed for this query string
      return;
    }

    if (this.props.location.search !== this.state.previousSearchString) {
      const prevSearchParams = new URLSearchParams(
        this.state.previousSearchString
      );

      this.setState({
        previousSearchString: this.props.location.search,
      });
      const prevSearchCompanyId = parseInt(
        prevSearchParams.get("companyId"),
        10
      );
      const prevSearchRegisteredName = prevSearchParams.get("registeredName");

      const searchParams = new URLSearchParams(this.props.location.search);
      const searchCompanyId = parseInt(searchParams.get("companyId"), 10);
      const searchRegisteredName = searchParams.get("registeredName");

      if (
        !Number.isNaN(prevSearchCompanyId)
        && prevSearchRegisteredName != null
        && Number.isNaN(searchCompanyId)
        && searchRegisteredName == null
      ) {
        // ViewMoreDetails modal is closed
        return;
      }

      if (
        !Number.isNaN(searchCompanyId)
        && searchRegisteredName != null
        && Number.isNaN(prevSearchCompanyId)
        && prevSearchRegisteredName == null
        && this.state.searchResults.items != null
      ) {
        // ViewMoreDetails modal is opened, but will still perform search if
        // reloading page with modal open
        return;
      }
    }

    const searchProps = readSearchString(this.props);
    const searchFilterIsEmpty = searchProps.filters == null || searchProps.length === 0
    || searchProps.filters.every((x) => x == null || x.length === 0);
    if (!searchFilterIsEmpty || searchProps.searchTerm.length >= 3) {
      // execute the search
      this.setState({
        loading: true,
        filters: searchProps.filters,
        previousSearchString: this.props.location.search,
      });

      const searchResults = await this.getSearchResults(this.getSearchQueryModel(searchProps));
      if (searchResults == null) {
        return;
      }

      if (searchQueries !== this.props.location.search) {
        // The results is not for the current location, discard them
        return;
      }

      this.setState({
        loading: false,
        searchResults,
        currentSearchTerm: searchProps.searchTerm,
        csvExportLimit: searchResults.csvExportLimit,
      });
    } else {
      this.setState({
        showSearchLimitModal: true,
      });
    }
  }

  // This function runs the "Select All {x} Items" logic on the front-end.
  selectAllReturnedSearchResults = async () => {
    const searchQueries = this.props.location.search;
    const searchProps = readSearchString(this.props);

    // execute the search
    this.setState({
      loading: true,
      filters: searchProps.filters,
      previousSearchString: this.props.location.search,
    });

    const searchResults = await this.getSearchResultsForLargeVolumeQuery(this.getSearchQueryModel(searchProps));

    if (searchResults === null) {
      return {};
    }

    if (searchQueries !== this.props.location.search) {
      // The results is not for the current location, discard them
      return {};
    }

    // Hide the spinner once the search is done and dusted.
    this.setState({
      loading: false,
    });

    this.setState({
      csvExportLimit: searchResults.csvExportLimit,
    });

    // Return the array of items retrieved from the database.
    return searchResults.items;
  }

  async submitSupplierExportRequest() {
    this.setState({
      disableSupplierExportRequest: true,
    });

    // api call
    await submitSupplierExportRequest();
  }

  async componentDidUpdate() {
    await this.performSearch();
  }

  toggleDuplicateOnboardingConfirmModal() {
    this.setState((prevState) => ({
      ...prevState,
      showDuplicateOnboardingConfirmModal: !prevState.showDuplicateOnboardingConfirmModal,
    }));
  }

  toggleAddBusinessUnitsConfirmModal() {
    this.setState((prevState) => ({
      ...prevState,
      showAddBusinessUnitsConfirmModal: !prevState.showAddBusinessUnitsConfirmModal,
    }));
  }

  toggleAddedBusinessUnitsModal() {
    this.setState((prevState) => ({
      ...prevState,
      showAddedBusinessUnitsModal: !prevState.showAddedBusinessUnitsModal,
    }));
  }

  toggleAllBusinessUnitsAlreadyAddedModal() {
    this.setState((prevState) => ({
      ...prevState,
      showAllBusinessUnitsAlreadyAddedModal: !prevState.showAllBusinessUnitsAlreadyAddedModal,
    }));
  }

  toggleMessageComposeModal() {
    this.setState((prevState) => ({
      ...prevState,
      showMessageComposeModal: !prevState.showMessageComposeModal,
    }));
  }

  toggleShowSubAccountModal() {
    this.setState((prevState) => ({
      ...prevState,
      showSubAccountModal: !prevState.showSubAccountModal,
    }));
  }

  toggleShowRateCompanyModal() {
    this.setState((prevState) => ({
      ...prevState,
      showRateCompanyModal: !prevState.showRateCompanyModal,
    }));
  }

  toggleInitiateOpportunityConfirmationModal() {
    this.setState((prevState) => ({
      ...prevState,
      showInitiateOpportunityConfirmationModal: !prevState.showInitiateOpportunityConfirmationModal,
    }));
  }

  toggleShowEditProductOfferingModal(companyId) {
    const loadSupplierCategories = async () => {
      const currentSupplierCategoriesList = await getSupplierCategoriesSelectionAsBuyer(companyId);
      this.setState((prevState) => ({
        ...prevState,
        showEditProductOffering: !prevState.showEditProductOffering,
        currentSupplierCategoriesList,
        companyIdToPerformAction: companyId,
      }));
    };

    if (companyId > 0) {
      loadSupplierCategories();
    } else {
      this.setState((prevState) => ({
        ...prevState,
        showEditProductOffering: !prevState.showEditProductOffering,
      }));
    }
  }

  toggleCsvExportWarningModal() {
    this.setState((prevState) => ({
      ...prevState,
      showCsvExportWarningModal: !prevState.showCsvExportWarningModal,
    }));
  }

  toggleSupplierExportModal() {
    this.setState((prevState) => ({
      ...prevState,
      showSupplierExportModal: !prevState.showSupplierExportModal,
    }));
  }

  saveSupplierProductCategorySelection = (productCategorySelectionIdList) => {
    const saveSupplierSubCategories = async () => {
      this.setState({ loading: true });
      try {
        // save updates
        await saveClientSpecificProductCategories({
          selectedSubCategoryIdList: productCategorySelectionIdList,
          supplierCompanyId: this.state.companyIdToPerformAction,
        });
        this.setState({ loading: false });
        this.props.showToast("Updated suppplier products segments!");
      } catch (error) {
        this.setState({ loading: false });
        this.props.showErrorDialog();
      }
    };

    saveSupplierSubCategories();
  }

  updateRating = (newRating, companyId) => {
    this.setState((prevState) => ({
      searchResults: {
        ...prevState.searchResults,
        items: prevState.searchResults.items.map((item) => ({
          ...item,
          companyRating: companyId === item.id ? newRating.companyRating : item.companyRating,
          totalCompanyRatings: companyId === item.id
            ? newRating.totalCompanyRatings
            : item.totalCompanyRatings,
        })),
      },
    }));
  }

  render() {
    const {
      limit,
      offset,
      sortOption,
      statusFilterOption,
      additionalStatusFilterOption,
    } = readSearchString(
      this.props
    );

    const preventMultipleSupplierSelection = !hasFeature(
      this.props.allowedFeatures,
      SystemFeatures.MultipleSupplierSelect
    );

    const canViewMoreDetails = () => (
      !hasFeature(
        this.props.allowedFeatures,
        SystemFeatures.ViewMoreSupplierDetails
      )
    );

    const viewMoreModalDetails = getCompanyModalDetails(this.props);
    const editManualSupplierId = getEditManualSupplierId(this.props);
    const tableConfig = this.createTableConfig();
    tableConfig.sortOptions.selectedSortOption = sortOption;
    tableConfig.searchLimit = this.props.searchLimit;
    tableConfig.statusFilterOptions.selectedStatusFilterOption = statusFilterOption;
    tableConfig.preventMultipleItemSelect = preventMultipleSupplierSelection;
    tableConfig.additionalStatusFilterOptions.selectedStatusFilterOption = additionalStatusFilterOption;

    const unspscInfo = getUnspscInfo(
      this.props.unspscSegments,
      this.props.unspscFamilies
    );

    const selectedOptionsAdvanced = this.state.filters[18] ?? [];
    let selectedOptionAdvanced = null;
    if (selectedOptionsAdvanced.length === 1) {
      selectedOptionAdvanced = selectedOptionsAdvanced[0];
    }

    const advancedFilterPropsConfig = getAdvancedFilterProps({
      beeClassifications: this.props.beeSectors,
      supplierCategories: this.props.supplierCategories,
      unspscSegments: unspscInfo,
      prioritiseClientOfferings: this.props.companyConfiguration
        .prioritiseClientOfferings,
      selectedOption: selectedOptionAdvanced,
      supplierTypes: this.props.supplierTypes,
      subscriptionTiers: this.props.subscriptionTiers,
      companyStatuses: this.props.companyStatuses,
      supportingDocuments: this.props.supportingDocuments,
      allowedFeatures: this.props.allowedFeatures,
    });

    const data = this.state.searchResults;
    data.limit = limit;
    data.offset = offset;

    const selectedOptions = this.state.filters[1] ?? [];
    let selectedOption = null;
    if (selectedOptions.length === 1) {
      selectedOption = selectedOptions[0];
    }

    const filterProps = getFilterProps({
      provinces: this.props.provinces,
      supplierCategories: this.props.supplierCategories,
      unspscSegments: unspscInfo,
      beeLevels: this.props.beeLevels,
      prioritiseClientOfferings: this.props.companyConfiguration
        .prioritiseClientOfferings,
      selectedOption,
    });

    const hasSearchResults = data.items != null;

    let composeMessageModalSendTo = "multiple";
    if (this.state.messageRecipients.sendToAll) {
      composeMessageModalSendTo = "searchQuery";
    } else if (this.state.messageRecipients.recipients.length === 1) {
      composeMessageModalSendTo = "single";
    }

    const composeMessageModal = this.state.showMessageComposeModal && (
      <ComposeMessageModal
        sendTo={composeMessageModalSendTo}
        recipientDetails={this.state.messageRecipients.recipients}
        searchQuery={this.buildSearchQueryModel(readSearchString(this.props))}
        searchQueryResultCount={data.totalItems}
        onClose={() => this.toggleMessageComposeModal()}
      />
    );

    return (
      <section id="isuppli-supplier-search" className="container-fluid">
        <StaticCompanyData
          optionsToLoad={[
            staticCompanyDataTypes.supplierCategory,
            staticCompanyDataTypes.businessUnit,
            staticCompanyDataTypes.supplierType,
            staticCompanyDataTypes.supplierTransactionStatuses,
            staticCompanyDataTypes.supportingDocuments,
          ]}
        />
        <StaticData
          optionsToLoad={[
            StaticDataTypes.UnspscSegment,
            StaticDataTypes.UnspscFamily,
            StaticDataTypes.BeeStatus,
            StaticDataTypes.BeeClassification,
            StaticDataTypes.Province,
            StaticDataTypes.SubscriptionTier,
            StaticDataTypes.CompanyStatus,
            StaticDataTypes.Municipality,
          ]}
        />
        <Loading />

        {this.state.showDuplicateOnboardingConfirmModal && (
          <ConfirmationModal
            hasCancel
            heading="EXISTING SUPPLIERS"
            description="Some of the selected suppliers have already been onboarded or are being onboarded. Do you want to continue to create onboarding requests for only the potential suppliers?"
            proceedButtonText="Continue"
            onToggleModal={() => this.toggleDuplicateOnboardingConfirmModal()}
            onProceedClick={() => this.continueOnboardingMultiple()}
          />
        )}

        {this.state.showAddSupplierModal && (
          <AddSupplierModal
            registeredCompanyName={this.state.registeredCompanyName}
            supplierId={this.state.companyIdToPerformAction}
            isEditing={this.state.isEditing}
            onRefresh={() => {
              this.onPerformSearch();
            }}
            onClose={() => {
              this.setState({
                showAddSupplierModal: false,
              });
            }}
          />
        )}

        {this.state.showAddBusinessUnitsConfirmModal && (
          <ConfirmationModal
            hasCancel
            heading="ADD BUSINESS UNIT(S)"
            description="Select additional business unit(s) for this existing supplier."
            proceedButtonText="Confirm"
            captureType="multiselectdropdown"
            multiDropdownOptions={this.state.availableBusinessUnits}
            onToggleModal={() => this.toggleAddBusinessUnitsConfirmModal()}
            onProceedClick={(newValue) => {
              this.addBusinessUnits(newValue);
            }}
          />
        )}

        {this.state.showAddedBusinessUnitsModal && (
          <ConfirmationModal
            hasCancel={false}
            heading="SUCCESSFULLY ADDED BUSINESS UNIT(S)"
            description="The supplier has been successfully onboarded to the selected business unit(s)"
            proceedButtonText="Ok"
            onToggleModal={() => {}}
            onProceedClick={() => {
              this.toggleAddedBusinessUnitsModal();
            }}
          />
        )}

        {editManualSupplierId && (
          <EditManualSupplier
            companyId={editManualSupplierId}
            onCloseCallBack={() => {
              this.toggleEditManualSupplierModal();
            }}
          />
        )}

        {this.state.showAllBusinessUnitsAlreadyAddedModal && (
          <ConfirmationModal
            hasCancel={false}
            heading="ONBOARDED TO ALL BUSINESS UNITS"
            description="The supplier has already been onboarded to all available business units"
            proceedButtonText="Ok"
            onToggleModal={() => {}}
            onProceedClick={() => this.toggleAllBusinessUnitsAlreadyAddedModal()}
          />
        )}

        {this.state.showInitiateOpportunityConfirmationModal && (
          <ConfirmationModal
            hasCancel
            heading="INITIATE OPPORTUNITY"
            description={this.state.initiateOpportunityModalDescription}
            proceedButtonText="Ok"
            onToggleModal={() => this.toggleInitiateOpportunityConfirmationModal()}
            onProceedClick={() => this.initiateOpportunityHandler()}
          />
        )}

        {this.state.showCsvExportWarningModal && (
          <ConfirmationModal
            hasCancel={false}
            heading="RECORD LIMIT REACHED"
            description={`A maximum of ${this.state.csvExportLimit} records can be exported at one time.`}
            proceedButtonText="Ok"
            onToggleModal={() => {}}
            onProceedClick={() => this.toggleCsvExportWarningModal()}
          />
        )}

        {
          this.state.showSupplierExportModal && (
            <ConfirmationModal
              hasCancel={false}
              size="medium"
              heading="PREPARING EXTRACT"
              description="Your extract is being generated and will be emailed to you shortly"
              onToggleModal={() => { }}
              onProceedClick={() => this.toggleSupplierExportModal()}
              proceedButtonText="OK"
            />
          )
        }

        {
          this.state.showSearchLimitModal && (
            <ConfirmationModal
              hasCancel={false}
              size="medium"
              heading="NOT ALLOWED"
              description="Please enter a minimum of 3 characters in the search bar or select a filter to apply to your search"
              onToggleModal={() => { }}
              onProceedClick={() => this.setState({ showSearchLimitModal: false })}
              proceedButtonText="OK"
            />
          )
        }

        {composeMessageModal}

        {viewMoreModalDetails && (
          <SupplierDetailsModal
            hasActionItems
            onCloseCallback={() => this.toggleViewMoreModal()}
            isPotentialSupplier={this.state.searchResults?.items?.find(
                (c) => c.id === viewMoreModalDetails.companyId
              )?.supplierStatus === supplierStatuses.potential}
            isSupplierSearch
            company={viewMoreModalDetails}
            onboardSupplierCallback={() => this.checkOnBoardSupplierDuplicates([
              viewMoreModalDetails.companyId,
            ])}
          />
        )}

        {this.state.showSubAccountModal && (
          <SubAccount
            operation={this.state.subAccountOperation}
            companyId={this.state.companyIdToPerformAction}
            originalTradingName={this.state.subAccountTradingName}
            setNewTradingName={(newTradingName) => this.setNewTradingNameHandler(newTradingName)}
            onClose={() => this.toggleShowSubAccountModal()}
          />
        )}

        {this.state.showRateCompanyModal && (
          <RateCompanyModal
            onClose={() => this.toggleShowRateCompanyModal()}
            onRate={(newRating) => this.updateRating(
              newRating,
              this.state.companyIdToPerformAction
            )}
            companyName={this.state.registeredCompanyName}
            companyId={this.state.companyIdToPerformAction}
          />
        )}

        {this.state.showEditProductOffering && (
          <OfferingSelector
            selection={this.state.currentSupplierCategoriesList}
            setSelection={(selectionIdList) => this.saveSupplierProductCategorySelection(selectionIdList)}
            onCloseCallback={() => this.toggleShowEditProductOfferingModal()}
          />
        )}

        <div className="row">
          <div className="col-10 offset-1">
            <MarketplaceSearch
              searchTerm={this.state.currentSearchTerm}
              onSearchCallback={() => this.onSearchHandler()}
              onSearchChangeCallback={(searchTerm) => this.onSearchTermChangeHander(searchTerm)}
            />
            <div className="pb-4" />

            <SearchFiltersContainer
              filterValues={this.state.filters}
              buttons={[
                {
                  key: "clear",
                  label: "Clear",
                  onClick: () => this.onFiltersClearHandler(),
                  color: "secondary",
                },
                {
                  key: "search",
                  label: "Search",
                  onClick: () => this.onPerformSearch(),
                  color: "primary",
                },
              ]}
              onChange={(f) => this.setState({ filters: f })}
              filters={filterProps}
              advancedFilters={advancedFilterPropsConfig}
              advancedFiltersSystemFeature={SystemFeatures.UseAdvancedSearchFilters}
            />

            {this.state.canRequestSupplierExport
              ? (
                <Button
                  className="mt-4"
                  color="primary"
                  onClick={() => {
                    this.submitSupplierExportRequest();
                    this.toggleSupplierExportModal();
                  }}
                  disabled={this.state.supplierExportStatus === SupplierExportStatus.Failed}
                >
                  Export Supplier List
                </Button>
              )
              : null}

            {this.state.supplierExportStatus === SupplierExportStatus.Failed
              ? <p className="mt-2">Supplier export request submitted, export will be emailed to you when complete</p>
              : ""}
            <div className="pb-4" />

            {hasSearchResults || this.state.loading ? (
              <Table
                loading={this.state.loading}
                onViewMore={(company) => {
                  this.toggleViewMoreModal({
                    ...company,
                    companyId: company.id,
                  });
                }}
                onViewMoreDisabled={canViewMoreDetails}
                tableConfig={tableConfig}
                data={data}
                onPageChange={(l, o) => this.onPageChangeHandler(l, o)}
                onSortOptionChange={(newSortOption) => this.onSortChangeHandler(newSortOption)}
                onStatusFilterChange={
                  (newStatusFilterOption) => this.onStatusFilterChangeHandler(newStatusFilterOption)
                }
                onAdditionalStatusFilterChange={
                  (newAdditionalStatusFilterOption) => this.onAdditionalStatusFilterChangeHandler(newAdditionalStatusFilterOption)
                }
                additionalStatusFilterName={(
                  this.props.supplierTransactionStatuses.length > 0 ? "Supplier Transaction Statuses" : null
                )}
                additionalStatusCondRenderExclusions={[supplierFilterOptions.Potential]}
                requiredMutipleItemSelectFeature={SystemFeatures.MultipleSupplierSelect}
                requiredViewMoreFeature={SystemFeatures.ViewMoreSupplierDetails}
                onSelectAllResultsCallback={this.selectAllReturnedSearchResults}
              />
            ) : null}

            {!hasSearchResults && !this.state.loading ? (
              <ListEmptyState heading="Nothing yet to display">
                <h5>Please enter a minimum of 3 characters in the search bar or select a filter to apply to your search</h5>
                <br />
              </ListEmptyState>
            ) : null}
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(withToast(
  connect(mapStateToProps, mapDispatchToProps)(SupplierSearch)
));
