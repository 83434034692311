import React from "react";
import Styles from "./TablePageSelector.module.scss";
import { ReactComponent as SvgTriangle } from ".../../../../images/triangle.svg";
import ImageButton from "../../../ImageButton/ImageButton";

const TablePageSelector = (
  {
    currentPageIndex,
    totalPages,
    onPageChange,
  } : {
    currentPageIndex: number,
    totalPages: number,
    onPageChange: (page: number) => void,
   }
) => {
  const numberOfPagesToShow = 5;
  let firstPageIndexToShow = currentPageIndex - Math.floor(numberOfPagesToShow / 2);
  if (firstPageIndexToShow < 0) {
    firstPageIndexToShow = 0;
  }
  let lastPageIndexToShow = firstPageIndexToShow + numberOfPagesToShow - 1;
  if (lastPageIndexToShow >= totalPages) {
    lastPageIndexToShow = totalPages - 1;
  }

  const startWithEllipsis = firstPageIndexToShow > 0;
  const endWithEllipsis = lastPageIndexToShow < totalPages - 1;

  const pageButtons = Array(lastPageIndexToShow - firstPageIndexToShow + 1)
    .fill(0)
    .map((_, index) => {
      const pageIndex = firstPageIndexToShow + index;
      const isCurrentPage = pageIndex === currentPageIndex;
      return (
        <button
          type="button"
          onClick={() => (isCurrentPage ? null : onPageChange(pageIndex))}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={`${isCurrentPage ? "active" : null} btn btn-link rounded-circle`}
        >
          {pageIndex + 1}
        </button>
      );
    });

  return (
    <div className={Styles.PageSelector}>
      <ImageButton
        svg={SvgTriangle}
        alt="prev"
        className={`${Styles.PageButtonBack} rounded-circle btn-outline-primary d-flex align-items-center justify-content-center`}
        disabled={currentPageIndex === 0}
        onClick={() => onPageChange(currentPageIndex - 1)}
      />
      <div className={Styles.PageNumberButtons}>
        {startWithEllipsis ? (
          <button
            type="button"
            className="btn btn-link rounded-circle"
            onClick={() => onPageChange(firstPageIndexToShow - 1)}
          >
            ...
          </button>
        ) : null}
        {pageButtons}
        {endWithEllipsis ? (
          <button
            type="button"
            className="btn btn-link rounded-circle"
            onClick={() => onPageChange(lastPageIndexToShow + 1)}
          >
            ...
          </button>
        ) : null}
      </div>
      <ImageButton
        svg={SvgTriangle}
        alt="next"
        className={`${Styles.PageButtonNext} rounded-circle btn-outline-primary d-flex align-items-center justify-content-center`}
        disabled={!(currentPageIndex < totalPages - 1)}
        onClick={() => onPageChange(currentPageIndex + 1)}
      />
    </div>
  );
};

export default TablePageSelector;
