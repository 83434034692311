import { useDataFeatureCheck } from "../../Hooks/useDataFeatureCheck";
// eslint-disable-next-line no-unused-vars
import DataFeatures from "../../utils/enums/DataFeature";

const WithDataFeature = (
  {
    children, dataFeature,
  }:
    {
      children: JSX.Element,
      dataFeature: DataFeatures
    }
) => {
  const dataCheck = useDataFeatureCheck();

  if (dataCheck(dataFeature)) {
    return children;
  }

  return null;
};
export default WithDataFeature;
