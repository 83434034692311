enum AdvancedFilterTypes {
  /* eslint-disable no-unused-vars */
  Text = 1,
  DropDown = 2,
  Date = 3,
  MultiSelectDropdown = 4,
  /* eslint-enable no-unused-vars */
}

export default AdvancedFilterTypes;
