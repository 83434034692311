import React, { Component } from "react";

import {
  CheckboxInput, Line, UtilButton, HelpIcon,
} from "isuppli-react-components";
import TextInput from "../../Controls/TextInput/TextInput";
import DropdownInput from "../../Controls/DropdownInput";

import {
  filterOutLetters,
  filterOutSpecialCharacters,
} from "../../Validation/onChangeValidations";

import addressTypes from "../../Util/Enumerators/addressTypes";

class AddressInfo extends Component {
  constructor(props) {
    super(props);
    this.physicalOption = "physicalAddress";
    this.postalOption = "postalAddress";
    this.state = {
      currentSelection: this.physicalOption,
      postalIsPhysical: false,
    };
  }

  changeSelection = (newSelection) => {
    this.setState({
      currentSelection: newSelection,
    });
  };

  updateAccordingToSelection = (name, value) => {
    const to = this.state.currentSelection === this.physicalOption
      ? this.postalOption
      : this.physicalOption;
    if (this.state.postalIsPhysical) {
      const event = {
        target: { name, value },
      };
      this.props.onInputChangeCallback(to, event);
    }
  };

  onPostalIsPhysicalChange = () => {
    if (!this.state.postalIsPhysical) {
      const newPostalAddress = {
        ...this.props.data.physicalAddress,
        addressId: (this.props.data.postalAddress || {}).addressId || 0,
        type: addressTypes.Postal,
      };
      this.props.setPostalAddress(newPostalAddress);
    }
    this.setState((prevState) => ({
      postalIsPhysical: !prevState.postalIsPhysical,
    }));
  };

  onInputChange = (ev) => {
    const event = {
      target: { name: ev.target.name, value: ev.target.value },
    };
    this.props.onInputChangeCallback(this.state.currentSelection, event);
    this.updateAccordingToSelection(ev.target.name, ev.target.value);
  };

  onCountryChange = (ev) => {
    const provinces = this.props.provinces.filter(
      (prov) => prov.countryId === ev.target.value
    );
    const hasProvinces = provinces.length > 0;
    this.props.data.hasProvinces = hasProvinces;

    const event = {
      target: { name: "hasProvinces", value: hasProvinces },
    };
    this.props.onInputChangeCallback(this.state.currentSelection, event);
  }

  onProvinceChange = (ev) => {
    const municipalities = this.props.municipalities.filter(
      (municipality) => municipality.provinceId === ev.target.value
    );
    const hasMunicipalities = municipalities.length > 0;
    this.props.data.hasMunicipalities = hasMunicipalities;

    const event = {
      target: { name: "hasMunicipalities", value: hasMunicipalities },
    };
    this.props.onInputChangeCallback(this.state.currentSelection, event);
  }

  render() {
    const physicalBtn = (
      <UtilButton
        className="full-width"
        color="secondary"
        outline={this.state.currentSelection !== this.physicalOption}
        location="top"
        onClick={() => this.changeSelection(this.physicalOption)}
      >
        {this.props.mandatoryPhysicalAddress ? "*PHYSICAL ADDRESS" : "PHYSICAL ADDRESS"}
      </UtilButton>
    );
    const postalBtn = (
      <UtilButton
        text="POSTAL ADDRESS"
        className="full-width"
        color="secondary"
        outline={this.state.currentSelection !== this.postalOption}
        location="top"
        onClick={() => this.changeSelection(this.postalOption)}
      >
        {this.props.mandatoryPostalAddress ? "*POSTAL ADDRESS" : "POSTAL ADDRESS"}
      </UtilButton>
    );

    const currentSelection = this.props.data[this.state.currentSelection];
    const filteredProvices = this.props.provinces.filter(
      (prov) => prov.countryId === (currentSelection || {}).country
    );
    const hasProvinces = filteredProvices.length > 0;
    const filteredMunicipalities = this.props.municipalities.filter(
      (municipality) => municipality.provinceId === (currentSelection || {}).province
    );
    const hasMunicipalities = filteredMunicipalities.length > 0;
    const isSameChecked = this.state.postalIsPhysical;
    return (
      <section>
        <div style={{ paddingTop: "53px" }}>
          <h2>ADDRESS DETAILS</h2>
        </div>

        <div>
          <div className="row">
            <div className="col-12 col-sm-8 flex-around">
              {physicalBtn}

              {postalBtn}
            </div>
          </div>

          <Line color="secondary" />

          <div className="top-margin-10px">&nbsp;</div>

          <div>
            <CheckboxInput
              disabled={this.props.readOnly}
              value={isSameChecked}
              label="Postal Address same as Physical Address"
              onChange={this.onPostalIsPhysicalChange}
            />
          </div>

          <div className="top-margin-10px">&nbsp;</div>
        </div>

        <div>
          <div className="row">

            <div className="col-12 col-md-1" />

            <div className="col-12 col-md-4" />
          </div>

          <div className="row">
            <div className="col-12 col-md-5">
              <TextInput
                label="Street Address"
                name="streetAddressL1"
                value={
                  currentSelection ? currentSelection.streetAddressL1 || "" : ""
                }
                iconName="document"
                additional={
                  this.props.additional
                    ? this.props.additional[
                      `${this.state.currentSelection}streetAddressL1`
                    ] : null
                }
                onChangeCallback={this.onInputChange}
                filters={[filterOutSpecialCharacters]}
                maxLength={40}
                disabled={this.props.readOnly}
              />
            </div>

            <div className="col-2" />

            <div className="col-5" />
          </div>

          <div className="row">
            <div className="col-12 col-md-5">
              <TextInput
                name="streetAddressL2"
                value={
                  currentSelection ? currentSelection.streetAddressL2 || "" : ""
                }
                iconName="document"
                additional={
                  this.props.additional
                    ? this.props.additional[
                      `${this.state.currentSelection}streetAddressL2`
                    ] : null
                }
                onChangeCallback={this.onInputChange}
                filters={[filterOutSpecialCharacters]}
                maxLength={40}
                disabled={this.props.readOnly}
              />
            </div>

            <div className="col-2" />

            <div className="col-5" />
          </div>

          <div className="row">
            <div className="col-12 col-md-5">
              <TextInput
                label="City"
                name="city"
                value={currentSelection ? currentSelection.city || "" : ""}
                iconName="document"
                additional={
                  this.props.additional
                    ? this.props.additional[
                      `${this.state.currentSelection}city`
                    ]
                    : null
                }
                onChangeCallback={this.onInputChange}
                filters={[filterOutSpecialCharacters]}
                maxLength={40}
                disabled={this.props.readOnly}
              />
            </div>

            <div className="col-md-2" />

            <div className="col-12 col-md-5">
              <DropdownInput
                label="Country"
                name="country"
                value={currentSelection?.country ?? -1}
                options={this.props.countries}
                additional={
                  this.props.additional
                    ? this.props.additional[
                      `${this.state.currentSelection}country`
                    ] : null
                }
                onChangeCallback={(ev) => {
                  this.onInputChange(ev);
                  this.onCountryChange(ev);
                }}
                disabled={this.props.readOnly}
              />
            </div>
          </div>

          <div className="row">
            {
              hasProvinces && (
                <div className="col-12 col-md-5">
                  <DropdownInput
                    label="Province"
                    name="province"
                    value={currentSelection?.province ?? -1}
                    options={filteredProvices}
                    additional={
                      this.props.additional
                        ? this.props.additional[
                          `${this.state.currentSelection}province`
                        ] : null
                    }
                    onChangeCallback={(ev) => {
                      this.onInputChange(ev);
                      this.onProvinceChange(ev);
                    }}
                    disabled={this.props.readOnly}
                  />
                </div>
              )
            }
            {
              hasProvinces && (
                <div className="col-md-2" />
              )
            }

            {
              hasMunicipalities && hasProvinces && (
                <div className="col-md-4 pr-0">
                  <DropdownInput
                    label="Municipality"
                    name="municipality"
                    value={currentSelection?.municipality ?? -1}
                    options={filteredMunicipalities}
                    additional={
                      this.props.additional
                        ? this.props.additional[
                          `${this.state.currentSelection}municipality`
                        ] : null
                    }
                    onChangeCallback={this.onInputChange}
                    disabled={this.props.readOnly}
                  />
                </div>
              )
            }
            {
              hasMunicipalities && hasProvinces && (
                <div className="col-md-1 px-0 mx-0">
                  <HelpIcon
                    url="https://b1sa.helpscoutdocs.com/contact"
                    tooltipMessage="If you cannot find your municipality, click here to contact our support team."
                  />
                </div>
              )
            }
          </div>
          <div className="row">
            <div className="col-12 col-md-5">
              <TextInput
                label="Postal Code / Zip"
                name="postalCode"
                value={
                  currentSelection ? currentSelection.postalCode || "" : ""
                }
                iconName="document"
                additional={
                  this.props.additional
                    ? this.props.additional[
                      `${this.state.currentSelection}postalCode`
                    ] : null
                }
                onChangeCallback={this.onInputChange}
                filters={[filterOutLetters]}
                maxLength={4}
                disabled={this.props.readOnly}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default AddressInfo;
