import React, { Fragment } from "react";

import { DropdownItem, Alert, Button } from "reactstrap";

import Styles from "./TableHeader.module.scss";

import DropdownInput from "../../../Forms/DropDownInput/DropDownInput";
import ActionsDropdown from "../../../ActionsDropDown/ActionsDropdown";
import CheckboxInput from "../../../CheckboxInput/CheckboxInput";

import { ReactComponent as SvgSortAmountDown } from "./sort-amount-down.svg";
// eslint-disable-next-line no-unused-vars
import { SystemFeatures } from "../../../..";

function TableHeader({
  totalItems,
  firstItem,
  lastItem,
  actionOptions,
  isAllOnPageSelected,
  onSelectAllOnPage,
  isAllSelected,
  onSelectAll,
  pageSizes,
  selectedPageSize,
  onPageSizeChange,
  sortOptions,
  onSortOptionChange,
  statusFilterOptions,
  onStatusFilterChange,
  additionalStatusFilterOptions,
  additionalStatusCondRenderExclusions,
  onAdditionalStatusFilterChange,
  additionalStatusFilterName,
  compact,
  title,
  disableCheckBox = false,
  requiredCheckBoxFeature,
} : {
  totalItems : number,
  firstItem : number,
  lastItem : number,
  actionOptions?: Array<React.ReactNode>,
  isAllOnPageSelected : boolean,
  onSelectAllOnPage : (value: boolean) => void,
  isAllSelected: boolean,
  onSelectAll: (value: boolean) => void,
  pageSizes: Array<number>,
  selectedPageSize : number,
  onPageSizeChange: (value: number) => void,
  sortOptions?: {
    selectedSortOption: number,
    options: Array<{value: number, display: string}>,
  },
  onSortOptionChange?: (value: number) => void,
  statusFilterOptions? : {
    selectedStatusFilterOption : number,
    options: Array<{value: number, display: string}>,
  },
  onStatusFilterChange?: (value: number) => void,
  additionalStatusFilterOptions? : {
    selectedStatusFilterOption: number,
    options: Array<{value: number, display: string}>,
  },
  additionalStatusCondRenderExclusions?: Array<number>,
  onAdditionalStatusFilterChange?: (value: number) => void,
  additionalStatusFilterName?: string,
  compact : boolean,
  title?: string,
  disableCheckBox ?: boolean,
  requiredCheckBoxFeature ?: SystemFeatures,
}) {
  return (
    <div className={`${Styles.SearchTableTop}`}>
      {!title && (
        <h2 className="mt-2">
          {totalItems != null
            ? `Total Records: ${totalItems}`
            : ""}
        </h2>
      )}
      {title && <h2 className="mt-2">{title}</h2>}

      <div className={Styles.PageSize}>
        <DropdownInput
          className="desktop-version m-0"
          label={totalItems != null && totalItems !== 0
            ? `Showing ${firstItem}-${lastItem} of ${totalItems} results`
            : ""}
          options={pageSizes.map((p) => ({ value: p, display: `${p}` }))}
          onChange={(value) => onPageSizeChange(value ?? pageSizes[0])}
          value={selectedPageSize}
          inline
          noPrompt
        />
        <span className="mobile-version">
          {totalItems != null && totalItems !== 0
            ? `Showing ${firstItem}-${lastItem} of ${totalItems} results`
            : ""}
        </span>
      </div>

      <Fragment>
        {!compact && actionOptions && actionOptions.length > 0 && (
          <div className={`${Styles.RowActions} align-items-center`}>
            <CheckboxInput
              disabled={disableCheckBox}
              value={isAllOnPageSelected}
              label="Select All On Page"
              onChange={(newValue) => onSelectAllOnPage(newValue)}
              feature={requiredCheckBoxFeature}
            />

            <div className="pr-4" />

            <ActionsDropdown label="Available actions">
              {actionOptions}
            </ActionsDropdown>

          </div>
        )}
        {
          isAllOnPageSelected && totalItems > selectedPageSize && (
            <Alert fade={false} color="secondary" className={Styles.SelectAllAlert}>
              {isAllSelected
                ? (
                  <Fragment>
                    {`All ${totalItems} selected `}
                    <Button color="link" onClick={() => onSelectAll(false)} className="py-0 my-0">
                      Clear Selection
                    </Button>
                  </Fragment>
                )
                : (
                  <Fragment>
                    {`You have selected ${selectedPageSize} items on this page.`}
                    <Button color="link" onClick={() => onSelectAll(true)} className="py-0 my-0">
                      {`Select all ${totalItems} items`}
                    </Button>
                  </Fragment>
                )}
            </Alert>
          )
        }
      </Fragment>

      <div className={`${Styles.SortControls} align-items-center justify-content-end`}>
        {sortOptions != null && (
          <ActionsDropdown
            label="Sort"
            styleVal="dark"
            iconSvg={SvgSortAmountDown}
          >
            {sortOptions.options.map((option) => (
              <DropdownItem
                className={
                  sortOptions.selectedSortOption === option.value
                    ? "selected"
                    : ""
                }
                key={option.value}
                onClick={() => {
                  if (onSortOptionChange != null) {
                    onSortOptionChange(option.value);
                  }
                }}
              >
                {option.display}
              </DropdownItem>
            ))}
          </ActionsDropdown>
        )}

        { statusFilterOptions != null && (
          <Fragment>
            <div className="pr-4 " />
            <ActionsDropdown
              label={statusFilterOptions?.options?.find(
                (option) => option.value === statusFilterOptions.selectedStatusFilterOption
              )?.display ?? ""}
              className=""
            >
              {statusFilterOptions.options.map((option) => (
                <DropdownItem
                  className={
                    statusFilterOptions.selectedStatusFilterOption === option.value
                      ? "selected"
                      : ""
                  }
                  key={option.value}
                  onClick={() => {
                    if (onStatusFilterChange != null) {
                      onStatusFilterChange(option.value);
                    }
                  }}
                >
                  {option.display}
                </DropdownItem>
              ))}
            </ActionsDropdown>
          </Fragment>
        )}
        { (statusFilterOptions && statusFilterOptions.selectedStatusFilterOption > 0
          && !additionalStatusCondRenderExclusions?.includes(
            statusFilterOptions.selectedStatusFilterOption
          ))
          && (additionalStatusFilterOptions != null
              && additionalStatusFilterOptions.options.length > 0)
          && (
            <Fragment>
              <div className="pr-4 " />
              <ActionsDropdown
                label={additionalStatusFilterOptions?.options?.find(
                  (option) => (
                    option.value === additionalStatusFilterOptions.selectedStatusFilterOption
                  ))?.display ?? (!additionalStatusFilterName ? "Additional Filter" : additionalStatusFilterName)}
                className=""
              >
                {additionalStatusFilterOptions?.options.map((option) => (
                  <DropdownItem
                    className={
                      additionalStatusFilterOptions?.selectedStatusFilterOption === option.value
                        ? "selected"
                        : ""
                    }
                    key={option.value}
                    onClick={() => {
                      if (onAdditionalStatusFilterChange != null) {
                        onAdditionalStatusFilterChange(option.value);
                      }
                    }}
                  >
                    {option.display}
                  </DropdownItem>
                ))}
              </ActionsDropdown>
            </Fragment>
          )}
      </div>
    </div>
  );
}

export default TableHeader;
