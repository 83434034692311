import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setStaticCompanyData } from "../Store/actions";
import { getCompanyOptionsAsync } from "../http/index";

import {
  StaticCompanyDataTypes, // eslint-disable-line no-unused-vars
} from "../Util/Enumerators/staticCompanyDataTypes";
import {
  ReduxState, // eslint-disable-line no-unused-vars
} from "../Store/ReduxState";
import useThreadSafe from "./useThreadSafe";

export interface StaticOption extends TypedStaticOption<number | string> {

}

export interface TypedStaticOption<T> {
  value: T,
  display: string,
  [key: string]: any,
}

export const objectToDisplayObject = (object: { id: number, name: string }) => ({
  ...object,
  value: object.id,
  display: object.name,
});

const useStaticCompanyData = (
  optionsToLoad: StaticCompanyDataTypes[]
)
  : [StaticOption[][], boolean] => {
  const [isReady, setIsReady] = useState(false);

  const storeOptions = useSelector((state: ReduxState) => (state.staticCompanyData ?? []));
  const cachedOptions = optionsToLoad.map((option) => storeOptions[option]);

  const currentStoreOptions = useRef(storeOptions);
  useEffect(() => {
    currentStoreOptions.current = storeOptions;
  }, [storeOptions]);

  const dispatch = useDispatch();

  const loader = useThreadSafe(async () => {
    // which options should I load

    const optionsToGetFromApi = optionsToLoad.filter(
      (option) => currentStoreOptions.current[option] == null
    );

    if (optionsToGetFromApi.length === 0) {
      setIsReady(true);
      return;
    }

    // call api

    const loadedOptions: {
      [key: string]: {
        id: number,
        name: string,
        [key: string]: any,
      }[]
    } = await getCompanyOptionsAsync(optionsToGetFromApi);
    const dispatchPayload: {
      type: StaticCompanyDataTypes,
      value: StaticOption[],
    }[] = [];

    optionsToGetFromApi.forEach((option) => {
      switch (option) {
        case StaticCompanyDataTypes.supplierCategory:
          const data = loadedOptions.supplierCategories;
          dispatchPayload.push({
            type: option,
            value: data.map((category) => ({
              ...objectToDisplayObject(category),
              supplierSubCategories: category.supplierSubCategories.map(
                (subCategory: { id: number, name: string }) => objectToDisplayObject(subCategory)
              ),
            })),
          });
          break;
        case StaticCompanyDataTypes.roles:
          const roles = loadedOptions.roles;
          dispatchPayload.push({
            type: option,
            value: roles.map((role) => ({
              ...objectToDisplayObject(role),
              id: role.roleId,
              value: role.roleId,
            })),
          });
          break;
        case StaticCompanyDataTypes.supplierType:
          const supplierTypes = loadedOptions.supplierTypes;
          dispatchPayload.push({
            type: option,
            value: supplierTypes.map((type) => ({
              ...objectToDisplayObject(type),
            })),
          });
          break;
        case StaticCompanyDataTypes.contactPersonType:
          const contactPersonTypes = loadedOptions.contactPersonTypes;
          dispatchPayload.push({
            type: option,
            value: contactPersonTypes.map((type) => ({
              ...objectToDisplayObject(type),
            })),
          });
          break;
        case StaticCompanyDataTypes.businessUnit:
          const buData = loadedOptions.businessUnits;
          dispatchPayload.push({
            type: option,
            value: buData.map((category) => ({
              ...objectToDisplayObject(category),
            })),
          });
          break;
        case StaticCompanyDataTypes.paymentTerms:
          const paymentTerms = loadedOptions.paymentTerms;
          dispatchPayload.push({
            type: option,
            value: paymentTerms.map((paymentTerm) => ({
              ...objectToDisplayObject(paymentTerm),
            })),
          });
          break;
        case StaticCompanyDataTypes.businessTypes:
          const businessTypes = loadedOptions.businessTypes;
          dispatchPayload.push({
            type: option,
            value: businessTypes.map((businessType) => ({
              ...objectToDisplayObject(businessType),
            })),
          });
          break;
        case StaticCompanyDataTypes.supplierTransactionStatuses:
          const supplierTransactionStatuses = loadedOptions.supplierTransactionStatuses;
          dispatchPayload.push({
            type: option,
            value: supplierTransactionStatuses.map((supplierTransactionStatus) => ({
              ...objectToDisplayObject(supplierTransactionStatus),
            })),
          });
          break;
        case StaticCompanyDataTypes.supportingDocuments:
          const supportingDocuments = loadedOptions.supportingDocuments;
          dispatchPayload.push({
            type: option,
            value: supportingDocuments.map((supportingDocument) => ({
              ...objectToDisplayObject(supportingDocument),
            })),
          });
          break;
        default:
          break;
      }
    });

    dispatch(setStaticCompanyData(dispatchPayload));
    setIsReady(true);
  });
  useEffect(() => {
    loader();
  }, [dispatch, optionsToLoad, loader]);

  return [cachedOptions, isReady];
};

export default useStaticCompanyData;
