import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ConfirmationModal, DataFeatures } from "isuppli-react-components";
import OnboardingWizard from "../../../Containers/Marketplace/OnboardingWizard/OnboardingWizard";
// eslint-disable-next-line no-unused-vars
import { ReduxState } from "../../../Store/ReduxState";
import {
  onOnboardingInputChange,
} from "../../../Store/onboardingRequestActions";
import AddressDetailsModule, { AddressDetailsModuleRef } from "../../../Containers/ManualSupplier/EditManualSupplier/AddressDetailsModule";// eslint-disable-line no-unused-vars
import BeeDetailsModule, { BeeDetailsModuleRef } from "../../../Containers/ManualSupplier/EditManualSupplier/BeeDetailsModule";// eslint-disable-line no-unused-vars
import BankDetailsModule, { BankDetailsModuleRef } from "../../../Containers/ManualSupplier/EditManualSupplier/BankDetailsModule";// eslint-disable-line no-unused-vars
import SupportingDocumentsModule, { SupportingDocumentsModuleRef } from "../../../Containers/ManualSupplier/EditManualSupplier/SupportingDocumentsModule";// eslint-disable-line no-unused-vars
import useShowError from "../../../Hooks/useShowError";
import { hideInfoPopup, showInfoPopup } from "../../../Store/actions";
import InfoPopup from "../../../Containers/InfoPopup";
import useStaticCompanyData from "../../../Hooks/useStaticCompanyData";
import { StaticCompanyDataTypes } from "../../../Util/Enumerators/staticCompanyDataTypes";

type Dict = { [key: string]: string };

export type ValidationModel = {
  isStepValid: boolean,
  msgs: Dict
};

interface StepComponentRef{
  save: () => Promise<boolean>
  validateStep?: () => ValidationModel
}

const MTPManualSupplierStep = (
  {
    stepNumber,
    companyId,
  } : {
    stepNumber: number,
    companyId: number}
) => {
  const onboardingData = useSelector((state: ReduxState) => state.onboardingRequest);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const showError = useShowError();

  const [[supplierTypes], isReady] = useStaticCompanyData(
    [StaticCompanyDataTypes.supplierType]
  );

  const supplierTypeDataFeatures: DataFeatures = isReady
    ? supplierTypes
      .find((supplierType) => supplierType.id === onboardingData?.supplierTypeId)?.dataFeatures
    : DataFeatures.None;

  // eslint-disable-next-line no-bitwise
  const hasBankStep = (isReady && (supplierTypeDataFeatures & DataFeatures.BankingDetails));

  const mtoMandatoryField = onboardingData.manualSupplier ? onboardingData.manualMTOMandatoryField : onboardingData.mtoMandatoryField;

  useEffect(() => {
    dispatch(hideInfoPopup());
  }, [stepNumber, dispatch]);

  // tab references
  const addressDetailModuleRef = useRef<AddressDetailsModuleRef>(null);
  const beeDetailsModuleRef = useRef<BeeDetailsModuleRef>(null);
  const bankDetailsModuleRef = useRef<BankDetailsModuleRef>(null);
  const supportingDocumentsRef = useRef<SupportingDocumentsModuleRef>(null);

  // tab controls
  let currentStepRef : React.RefObject<StepComponentRef> | null = null;

  const steps = [
    {
      heading: "ADDRESS & CONTACT DETAILS",
      children: (
        <AddressDetailsModule
          companyId={companyId}
          ref={addressDetailModuleRef}
          mtoMandatoryField={mtoMandatoryField}
        />
      ),
      currentStepRef: addressDetailModuleRef,
    },
    {
      heading: "B-BBEE PRACTICES & CERTIFICATION",
      children: (
        <BeeDetailsModule
          companyId={companyId}
          ref={beeDetailsModuleRef}
          mtoMandatoryField={mtoMandatoryField}
        />
      ),
      currentStepRef: beeDetailsModuleRef,
    },
  ];

  if (hasBankStep) {
    steps.push(
      {
        heading: "BANK DETAILS",
        children: (
          <BankDetailsModule
            companyId={companyId}
            containerClass="pb-5"
            hideTitle
            ref={bankDetailsModuleRef}
            mtoMandatoryField={mtoMandatoryField}
          />
        ),
        currentStepRef: bankDetailsModuleRef,
      }
    );
  }

  steps.push({
    heading: "SUPPORTING DOCUMENTS & AGREEMENTS",
    children: (
      <SupportingDocumentsModule
        companyId={companyId}
        ref={supportingDocumentsRef}
        mtoMandatoryField={mtoMandatoryField}
      />
    ),
    currentStepRef: supportingDocumentsRef,
  });

  let heading = "";
  let children: React.ReactNode = null;

  const stepIndex = stepNumber - 2;

  heading = steps[stepIndex]?.heading;
  children = steps[stepIndex]?.children;
  currentStepRef = steps[stepIndex]?.currentStepRef;

  // save details
  const onSaveClick = () => {
    setShowConfirmationModal(true);
  };

  const isCurrentStepValid = () => {
    const stepValidation = currentStepRef?.current?.validateStep != null
      ? currentStepRef?.current?.validateStep() : null;

    // Check that validation is passed
    if (stepValidation?.isStepValid === false) {
      window.scrollTo({ top: 0 });
      dispatch(showInfoPopup(
        "This page contains missing information.",
        "To continue please amend/complete the following:",
        Object.keys(stepValidation.msgs).map((key) => stepValidation.msgs[key])
      ));
    }
    return stepValidation?.isStepValid ?? true;
  };

  const saveAndContinueLater = async () => {
    dispatch(hideInfoPopup());
    const savedSuccessfully = await currentStepRef?.current?.save();
    if (savedSuccessfully) {
      history.length = 1;
      history.replace("/myoffice/onboardingrequest");
    } else {
      showError();
    }
  };

  const onClickNextHandler = async (nextUrl:string) => {
    if (isCurrentStepValid()) {
      dispatch(hideInfoPopup());
      // Save step details
      const savedSuccessfully = await currentStepRef?.current?.save();
      if (savedSuccessfully) {
        dispatch(onOnboardingInputChange({
          target: {
            name: "lastStepCompleted",
            value: onboardingData.lastStepCompleted + 1,
          },
        }));
        history.push(nextUrl);
      } else { showError(); }
    }
  };

  return (
    <OnboardingWizard
      currentStep={stepNumber - 1}
      lastStepCompleted={onboardingData.lastStepCompleted}
      isManualSupplier={onboardingData.manualSupplier}
      onClickNext={onClickNextHandler}
      onClickSaveAndContinue={() => { onSaveClick(); }}
      onboardingId={onboardingData.onboardingId}
    >
      <InfoPopup />
      {showConfirmationModal && (
        <ConfirmationModal
          size="large"
          heading="Save & Continue later"
          description="You are about to save your progress but you can come back at any
          time by to continue with the motivation to onboard."
          onProceedClick={saveAndContinueLater}
          proceedButtonText="OK"
          hasCancel
          onToggleModal={() => { setShowConfirmationModal((prevState) => !prevState); }}
        />
      )}
      <div className="padding-lr-10vw">
        {onboardingData?.suspensionReason
          && (
            <div className="col-12 col-lg-12 text-center alert alert-danger">
              <p>This onboarding request was suspended. Below is the reason:</p>
              <strong><p>{onboardingData?.suspensionReason}</p></strong>
              <p>Please address the issues raised above and resubmit the request.</p>
            </div>
          )}
        <div className="row top-margin-90px">
          <div className="col-12 col-lg-4">
            <div>
              <h3 className="margin-null">{`STEP 0${stepNumber}`}</h3>
            </div>
            <div>
              <h1>{heading}</h1>
            </div>
          </div>
        </div>
        {children}
      </div>
    </OnboardingWizard>
  );
};

export default MTPManualSupplierStep;
