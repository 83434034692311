const onboardingDelegationStatus = {
  None: 0,
  PendingReview: 1,
  Accepted: 2,
  Declined: 3,

  toString: (value: number, delegatedUserRoleName: string) => {
    switch (value) {
      case onboardingDelegationStatus.None:
        return "None";
      case onboardingDelegationStatus.PendingReview:
        return `Pending [${delegatedUserRoleName}] Review`;
      case onboardingDelegationStatus.Accepted:
        return `[${delegatedUserRoleName}] Approved`;
      case onboardingDelegationStatus.Declined:
        return `[${delegatedUserRoleName}] Declined`;
      default:
        return `Delegation status: ${value}`;
    }
  },
};

export default onboardingDelegationStatus;
