import { MenuItem, SystemFeatures } from "isuppli-react-components"; // eslint-disable-line no-unused-vars

const navMenuItems: Array<MenuItem> = [
  {
    title: "Home",
    to: "/dashboard",
  },
  {
    title: "Virtual Marketplace",
    to: "/marketplace",
    children: [
      {
        title: "Find Companies",
        to: "search",
        feature: SystemFeatures.SupplierSearch,
      },
      {
        title: "Bulletin board (eRFx)",
        to: "/redirect/bulletinboard",
        redirect: true,
        feature:
          SystemFeatures.None, // eslint-disable-line no-bitwise
      },
      {
        title: "Messaging",
        to: "messaging",
        feature: SystemFeatures.Messaging,
      },
    ],
  },
  {
    title: "My Company",
    to: "/mycompany",
    subMenuOffset: 2,
    children: [
      {
        title: "Company Profile",
        to: "profile",
        feature: SystemFeatures.CompanyProfile,
      },
      {
        title: "My Compliance",
        to: "compliance",
        feature: SystemFeatures.OngoingCompliance,
      },
      // {
      //  title: "Enterprise Configuration",
      //  to: "configuration",
      //  feature: SystemFeatures.EnterpriseConfig,
      // },
      {
        title: "Configuration",
        to: "configuration",
        feature: SystemFeatures.Configuration,
      },
      {
        title: "Manage Users",
        to: "user-management",
        feature: SystemFeatures.UserManagement,
      },
      {
        title: "My Subscription",
        to: "subscription",
        feature: SystemFeatures.Subscriptions,
      },
      {
        title: "Audit Trail",
        to: "/redirect/audit",
        redirect: true,
        feature: SystemFeatures.AuditTrails,
      },
    ],
  },
  {
    title: "My Office",
    to: "/myoffice",
    subMenuOffset: 2,
    children: [
      {
        title: "Onboarding",
        to: "onboardingrequest",
        // eslint-disable-next-line no-bitwise
        feature: SystemFeatures.OnboardingApprove1
          | SystemFeatures.OnboardingApprove2
          | SystemFeatures.OnboardingCreateRequest
          | SystemFeatures.OnboardingAddSupplierApproval
          | SystemFeatures.AssignManualManualVendorNumber
          | SystemFeatures.ApproveDelegatedOnboardingRequestApproval2
          | SystemFeatures.OnboardingCompletionCommunication,
      },
      {
        title: "Compliance Management",
        to: "checks",
        feature: SystemFeatures.SupplierChecks,
      },
      {
        title: "Manage Opportunities (eRFx)",
        to: "/redirect/manageopportunities",
        redirect: true,
        feature: SystemFeatures.RfxCreateOpportunity,
      },
      {
        title: "PP Report",
        to: "ppreport",
        feature: SystemFeatures.PPReportDashboard,
      },
      {
        title: "Supplier Updates",
        to: "supplierupdates",
        feature: SystemFeatures.ManageErpEvents,
      },
    ],
  },
  {
    isUserMenu: true,
    title: "User",
    to: "/user",
    children: [
      {
        title: "Profile",
        to: "profile",
      },
    ],
  },
];

export default navMenuItems;
