import React, { Fragment, useEffect, useState } from "react";
import { CarouselItem, CarouselIndicators, Carousel } from "reactstrap";
import { StaticDataTypes } from "../../Util/Enumerators/staticDataTypes";
import useStaticData from "../../Hooks/useStaticData";
import PlanOption from "../Supplier/PlanOption/PlanOption";

import Styles from "./ProductTierSelector.module.scss";

const ProductTierSelector = (
  {
    value,
    onChange,
    onEnquire,
    sponsored,
    sponsorName,
    fundCompliance,
    sponsoredSubscriptionTierId,
    disabledSubscriptionTierIds,
    recommendedSubscriptionTierId,
  }:
  {
    value: number,
    onChange: (value: number) => void,
    onEnquire: (value: number) => void,
    sponsored: boolean,
    sponsorName: string,
    fundCompliance: boolean,
    sponsoredSubscriptionTierId: number,
    disabledSubscriptionTierIds?: Array<number>,
    recommendedSubscriptionTierId: number,
  }
) => {
  const [[
    subscriptionTiers,
  ], staticDataReady] = useStaticData([StaticDataTypes.SubscriptionTier]);

  // if sponsored, filter out all subscription tiers that have a lower price
  const sponsoredTier = subscriptionTiers?.find((tier) => tier.id === sponsoredSubscriptionTierId);
  const filteredSubscriptionTiers = sponsored
    ? subscriptionTiers
      ?.filter((tier) => tier.monthlyPriceInclVAT >= sponsoredTier?.monthlyPriceInclVAT
      || tier.monthlyPriceInclVAT == null)
    : subscriptionTiers;

  const [activeIndex, setActiveIndex] = useState(value);
  const [animating, setAnimating] = useState(false);

  useEffect(() => {
    if (!staticDataReady) {
      return;
    }
    const newIndex = filteredSubscriptionTiers.findIndex((c) => c.value === value);
    setActiveIndex(newIndex);
  }, [value, setActiveIndex, staticDataReady, filteredSubscriptionTiers]);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === filteredSubscriptionTiers.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? filteredSubscriptionTiers.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: number) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    if (staticDataReady && value <= 0) {
      const recommended = filteredSubscriptionTiers.filter((c) => c.value === recommendedSubscriptionTierId)[0].value;
      onChange(recommended);
    }
  }, [staticDataReady, value, recommendedSubscriptionTierId, filteredSubscriptionTiers, onChange]);

  const mobileOptions = !staticDataReady ? [] : filteredSubscriptionTiers.map((option, i) => (
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={i}
      className={`px-3
       ${Styles.CarouselItem}
       ${option.value === recommendedSubscriptionTierId ? Styles.Recommended : ""}
      `}
    >
      <div className={`${Styles.RecommendedMessage}`}>Recommended</div>

      <PlanOption
        className="flex-fill h-100"
        config={option}
        onEnquire={() => onEnquire(option.id)}
        onActionCallback={() => onChange(option.value)}
        isSelected={option.id === value}
        sponsored={sponsored && sponsoredSubscriptionTierId === option.value}
        sponsorName={sponsorName}
        fundCompliance={fundCompliance}
        disabled={
          disabledSubscriptionTierIds?.includes(option.id)
          || option.onEnquiry
        }
      />
    </CarouselItem>
  ));

  const desktopOptions = !staticDataReady ? [] : filteredSubscriptionTiers.map((option, i) => (
    <div key={i} className={`${i === 0 ? "" : "ml-4"} d-flex flex-column ${option.value === recommendedSubscriptionTierId ? Styles.Recommended : ""} ${Styles.DesktopPlanOption}`}>
      <div className={Styles.RecommendedMessage}>Recommended</div>
      <PlanOption
        className="flex-fill"
        config={option}
        onEnquire={() => onEnquire(option.id)}
        onActionCallback={() => onChange(option.value)}
        isSelected={option.id === value}
        sponsored={sponsored && sponsoredSubscriptionTierId === option.value}
        sponsorName={sponsorName}
        fundCompliance={fundCompliance}
        disabled={
          disabledSubscriptionTierIds?.includes(option.id)
          || option.onEnquiry
        }
      />
    </div>
  ));

  return (
    <Fragment>
      <div className={`${Styles.ProductTierContainer} desktop-version`}>
        {desktopOptions}
      </div>
      { staticDataReady && value && (
        <div className="mobile-version row">
          <Carousel
            className={Styles.Carousel}
            interval={0}
            activeIndex={activeIndex}
            next={next}
            previous={previous}
          >
            {mobileOptions}

            <CarouselIndicators
              items={filteredSubscriptionTiers}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            />
          </Carousel>

        </div>
      ) }
      <p className="mt-xl-3">* Limited functionality applies</p>
      <p>
        {"(B-BBEE Compliance and Regulatory Compliance is only "
          + "applicable to South African registered companies)"}
      </p>
    </Fragment>
  );
};

export default ProductTierSelector;
