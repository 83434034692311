import React, { useState, useEffect, useRef } from "react";
import axios, {
  CancelTokenSource, // eslint-disable-line no-unused-vars
} from "axios";
import { Line } from "isuppli-react-components";
import InfiniteScroll, {
  LoadOptions, // eslint-disable-line no-unused-vars
} from "../../../Controls/InfiniteScroll/InfiniteScroll";
import Styles from "./ChatMessageContent.module.scss";
import MessageItem from "../../Messaging/MessageItem/MessageItem";
// eslint-disable-next-line no-unused-vars
import { Message, PageTypes } from "../../../http/Messaging/Models/Models";
import {
  getChannelMessages, readMessage,
}
  from "../../../http/Messaging/messagesApi";
// eslint-disable-next-line no-unused-vars
import MessageComposer from "../MessageComposer/MessageComposer";
import useSignalR, {
  SignalRNotifications,
  NewMessageNotification, // eslint-disable-line no-unused-vars
} from "../../../Hooks/useSignalR";
import useShowError from "../../../Hooks/useShowError";

function ChatMessageContent({
  selected,
  onMessageSent,
}:{
    selected: {
      channelId:number;
      // the messageId to start at. If not specified, will start at the latest message
      messageId?: number;
    }
    onMessageSent: (lastMessage:string) => void;
}) {
  // -- use state messages
  const [messagesList, setMessageList] = useState<Message[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [hasMoreBefore, setHasMoreBefore] = useState(false);
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [sentMessageId, setSentMessageId] = useState<number>();

  const httpCancelToken = useRef<CancelTokenSource>();
  const showError = useShowError();

  const updateReadMessage = async (messageId : number) => {
    await readMessage(selected.channelId, messageId);
  };

  const disconnect = useSignalR<NewMessageNotification>(
    SignalRNotifications.ChannelUpdates,
    (data) => {
      if (data.channelId === selected.channelId && !hasMoreBefore) {
        setMessageList((prevList) => [data.newMessage, ...prevList]);
        updateReadMessage(data.newMessage.id);
      }
    }
  );

  // cleanup when component is removed
  useEffect(() => () => {
    if (httpCancelToken.current != null) {
      httpCancelToken.current.cancel();
    }
    disconnect();
  }, [disconnect]);

  // infinite scroll loading
  const loadMoreFunction = async (loadOptions: LoadOptions) => {
    try {
      if (httpCancelToken.current != null) {
        httpCancelToken.current.cancel();
      }

      const pageOptions : {
      pageId? : number,
      pageType? : PageTypes,
    } = {
      pageType: PageTypes.After,
      pageId: undefined,
    };

      if (messagesList.length > 0) {
        if (loadOptions.loadNext) {
          pageOptions.pageId = messagesList.length > 0
            ? messagesList[messagesList.length - 1].id
            : undefined;

          pageOptions.pageType = PageTypes.After;
        } else {
          pageOptions.pageId = messagesList.length > 0
            ? messagesList[0].id
            : undefined;

          pageOptions.pageType = PageTypes.Before;
        }
      } else if (selected.messageId != null) {
      // start loading at the selected message
        setHasMoreBefore(true);
        pageOptions.pageId = selected.messageId;
        pageOptions.pageType = PageTypes.From;
      }

      const limit = 20;
      httpCancelToken.current = axios.CancelToken.source();
      const moreMessages = await getChannelMessages(
        {
          ...pageOptions,
          channelId: selected.channelId,
          limit,
          config: {
            cancelToken: httpCancelToken.current.token,
          },
        }
      );

      // updating messages state
      switch (pageOptions.pageType) {
        case PageTypes.After:
        case PageTypes.From:
          if (moreMessages.messages.length < limit) {
            setHasMore(false);
          }

          setMessageList((prevState) => [...prevState, ...moreMessages.messages]);
          break;
        case PageTypes.Before:
          if (moreMessages.messages.length < limit) {
            setHasMoreBefore(false);
          }

          setMessageList((prevState) => [...moreMessages.messages, ...prevState]);
          break;
        default:
          break;
      }

      setInitialLoadDone(true);
      return true;
    } catch (error) {
      if (!axios.isCancel(error)) {
        showError();
      }
      return false;
    }
  };

  // refreshing page on channel change
  useEffect(() => {
    if (httpCancelToken.current != null) {
      httpCancelToken.current.cancel();
    }
    // did it change
    setMessageList([]);
    setHasMore(true);
    setHasMoreBefore(false);
  }, [selected]);

  // scrolling to top of messages list
  const scrollToRef = () => {
    if (scrollRef.current != null) {
      scrollRef.current.scrollIntoView({ block: "start" });
    }
    setSentMessageId(0);
  };

  const onMessageSentHandler = (newMessage: Message) => {
    setMessageList([newMessage, ...messagesList]);
    setSentMessageId(newMessage.id);
    setTimeout(() => { // this timeout is set in order to allow for scrollToRef to complete
      onMessageSent(newMessage.content);
    }, 200);
    scrollToRef();
  };

  // mapping messageList (Object) to messageItems components
  const messageItems = messagesList.map((message) => {
    const fileList = message.attachments?.map((file) => (
      {
        filename: file.filename,
        link: file.fileUrl,
      }
    ));

    const sentMessageRef = sentMessageId === message.id ? scrollRef : null;
    return (
      <div key={message.id} ref={sentMessageRef}>
        <MessageItem
          messageId={message.id}
          userDisplayName={message.displayName}
          userDisplayEmail={message.displayEmail}
          groupName={message.groupName}
          files={fileList}
          messageText={message.content}
          createdDate={message.sentDate}
        />
      </div>
    );
  });

  return (
    <div className="position-relative d-flex flex-column h-100">
      <div className={`${Styles.messageHistory} flex-fill`}>
        <InfiniteScroll
          hasMoreBefore={hasMoreBefore}
          loadMore={loadMoreFunction}
          hasMore={hasMore}
        >
          {messageItems}
        </InfiniteScroll>
      </div>
      <Line color="secondary" className="position-relative" />
      {initialLoadDone && (
        <MessageComposer
          className="pl-4"
          defaultOpen={messagesList?.length === 0}
          channelId={selected.channelId}
          onMessageSent={onMessageSentHandler}
        />
      )}
    </div>
  );
}

export default ChatMessageContent;
