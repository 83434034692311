import axios from "axios";

import {
  SubscriptionMessage, // eslint-disable-line no-unused-vars
  InvoicesMessage, // eslint-disable-line no-unused-vars
  CompanySubscriptionChangeMessage, // eslint-disable-line no-unused-vars
  BillingSubscriptionStatusMessage, // eslint-disable-line no-unused-vars,
} from "./Models/Models";
import { makeRequestAsync } from "../util";

// eslint-disable-next-line import/prefer-default-export
export async function getInvoices(limit: number, offset: number = 0) {
  const url = `/api/billing/invoices?limit=${limit}&offset=${offset}`;
  const invoicesMessage = makeRequestAsync(() => axios.get(url)) as Promise<InvoicesMessage>;
  return invoicesMessage;
}

// eslint-disable-next-line import/prefer-default-export
export async function addNewSubscription(
  message: SubscriptionMessage
) {
  const url = "/api/billing/subscription";
  const paymentRedirectUrl = makeRequestAsync(() => axios.post(url, message)) as Promise<string>;
  return paymentRedirectUrl;
}

export async function updateSubscription(
  message: CompanySubscriptionChangeMessage
) {
  const url = "/api/billing/subscription";
  const paymentRedirectUrl = makeRequestAsync(
    () => axios.put(url, message)
  ) as Promise<string | boolean>;
  return paymentRedirectUrl;
}

export async function retrySubscriptionPayment(
  pendingSubscriptionTierId: number,
  subscriptionId: number
) {
  const url = `/api/billing/subscription/retry?pendingSubscriptionTierId=${pendingSubscriptionTierId}&subscriptionId=${subscriptionId}`;
  const paymentRedirectUrl = makeRequestAsync(() => axios.post(url)) as Promise<string>;
  return paymentRedirectUrl;
}

export async function getSubscriptionStatus() {
  const invoicesMessage = makeRequestAsync(() => axios.get("/api/billing/subscription")) as Promise<BillingSubscriptionStatusMessage>;
  return invoicesMessage;
}
