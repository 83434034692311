import React, { useMemo, useState } from "react";
import { Tooltip } from "reactstrap";
import Styles from "./HelpIcon.module.scss";
import { ReactComponent as SvgQuestion } from "./question-circle-solid.svg";

const HelpIcon = (
  {
    url,
    tooltipMessage,
  }
    :
    {
        url: string,
        tooltipMessage: string,
    }
) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  // generate random id to ensure the popup is unique
  const id = useMemo(() => `help-icon-${Math.random().toString(36).substring(2)}`, []);

  return (
    <div className={Styles.Icon}>
      <a
        href={url}
        id={id}
        target="_blank"
        rel="noopener noreferrer"
      >
        <SvgQuestion className={`${Styles.IconImage} text-action`} />
      </a>
      <Tooltip placement="top" target={id} isOpen={tooltipOpen} toggle={toggle}>
        {tooltipMessage}
      </Tooltip>
    </div>
  );
};

export default HelpIcon;
