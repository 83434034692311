import {
  ISuppliDataRequirements,
  ISuppliAuthentication,
  ToastContainer,
} from "isuppli-react-components";
import React from "react";
import { useSelector } from "react-redux";

import AppErrorBoundary from "./Containers/AppErrorBoundry";
import NotAuthenticatedHandler from "./Containers/NotAuthenticatedHandler/NotAuthenticatedHandler";
import AxiosInterceptor from "./Hooks/useAxiosError/AxiosInterceptor";
// eslint-disable-next-line no-unused-vars
import { ReduxState } from "./Store/ReduxState";

const AppContent = ({ children }:{
  children: React.ReactChildren
}) => {
  const dataFeatures = useSelector((state: ReduxState) => (state.dataFeatures));
  const allowedDataFeatures = useSelector((state: ReduxState) => (
    state.loggedInUserDetails.allowedFeatures));
  const subscriptionsAllowedFeaturesInfo = useSelector(
    (state: ReduxState) => (state.subscriptionsAllowedFeatures)
  );

  return (
    <ISuppliDataRequirements allowedDataFeatures={dataFeatures}>
      <ISuppliAuthentication
        allowedFeatures={allowedDataFeatures}
        subscriptionEditUrl="/myCompany/subscription"
        subscriptionFeaturesInfo={subscriptionsAllowedFeaturesInfo}
      >
        <AxiosInterceptor>
          <AppErrorBoundary>
            <ToastContainer>
              <NotAuthenticatedHandler>
                {children}
              </NotAuthenticatedHandler>
            </ToastContainer>
          </AppErrorBoundary>
        </AxiosInterceptor>
      </ISuppliAuthentication>
    </ISuppliDataRequirements>
  );
};

export default AppContent;
