import React, {
  useState,
  // eslint-disable-next-line no-unused-vars
  CSSProperties,
  Fragment,
  useEffect,
} from "react";

import { Alert } from "reactstrap";
import Styles from "./Table.module.scss";

import TableHeader from "./TableHeader/TableHeader";
import TableFooter from "./TableFooter/TableFooter";
import TableRowItem from "./TableRowItem/TableRowItem";
import Spinner from "../../Spinner/Spinner";
import Line from "../../Line/Line";
import BasicTable from "../BasicTable/BasicTable";
import NoResultsCard from "../NoResultCard/NoResultsCard";
import {
  // eslint-disable-next-line no-unused-vars
  TableConfig,
} from "./Interfaces/TableConfig";
import {
  // eslint-disable-next-line no-unused-vars
  TableData,
} from "./Interfaces/TableData";

// eslint-disable-next-line no-unused-vars
import { TableItem } from "./Interfaces/TableItem";
// eslint-disable-next-line no-unused-vars
import { SystemFeatures } from "../../..";

const pageSizes = [20, 50, 100, 200];
const Table = <TIdType, TDataType extends TableItem<TIdType>>({
  onPageChange,
  tableConfig,
  tableStyle,
  title,
  onSortOptionChange,
  onStatusFilterChange,
  onAdditionalStatusFilterChange,
  additionalStatusFilterName,
  additionalStatusCondRenderExclusions,
  loading,
  data,
  onViewMore,
  onViewMoreDisabled,
  requiredMutipleItemSelectFeature,
  requiredViewMoreFeature,
  onSelectAllResultsCallback,
}:
  {
    onPageChange: (limit: number, offset: number) => void
    tableConfig: TableConfig<TIdType, TDataType>,
    tableStyle: "basic" | "compact" | "default",
    title?: string,
    onSortOptionChange?: (newValue: number) => void,
    onStatusFilterChange?: (newValue: number) => void,
    onAdditionalStatusFilterChange?: (newValue: number) => void,
    additionalStatusFilterName?: string,
    additionalStatusCondRenderExclusions?: Array<number>,
    loading: boolean,
    data: TableData<TDataType>,
    onViewMore?: (data: TDataType) => void,
    onViewMoreDisabled?: (data: TDataType) => boolean,
    requiredMutipleItemSelectFeature?: SystemFeatures,
    requiredViewMoreFeature?: SystemFeatures,
    onSelectAllResultsCallback?: () => Promise<Array<TDataType>>,
  }) => {
  const [selectedItems, setSelectedItems] = useState<Array<TIdType>>([]);
  const [selectedItemObjects, setSelectedItemObjects] = useState<Array<TDataType>>([]);
  const [isAllOnPageSelected, setIsAllOnPageSelected] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);

  useEffect(() => {
    if (data.items) {
      const pageSelectedRows = data.items.filter((element) => selectedItems.includes(element.id));
      setIsAllOnPageSelected(pageSelectedRows.length === data.limit);
    }
  }, [data]);

  const onSelectedChangedHandler = (
    id: TIdType,
    row: TDataType,
    isSelected: boolean,
    totalNumberOnPage: number
  ) => {
    setSelectedItems((currentItems) => {
      const newSelectedItems = [...currentItems];
      if (isSelected && !newSelectedItems.includes(id)) {
        newSelectedItems.push(id);
        setIsAllOnPageSelected(newSelectedItems.length === totalNumberOnPage);
        setIsAllSelected(false);
        setSelectedItemObjects([...selectedItemObjects, row]);
        return newSelectedItems;
      }

      if (!isSelected && newSelectedItems.includes(id)) {
        setIsAllOnPageSelected(false);
        setIsAllSelected(false);
        setSelectedItemObjects(() => selectedItemObjects.filter((i) => i.id !== row.id));
        return newSelectedItems.filter((c) => c !== id);
      }

      return currentItems;
    });
  };

  const onSelectAllOnPageHandler = (
    selectAllOnPage: boolean,
    allIds: Array<TIdType>,
    allObjs: Array<TDataType>
  ) => {
    if (selectAllOnPage) {
      setIsAllOnPageSelected(true);
      setSelectedItems([...selectedItems, ...allIds]);
      setSelectedItemObjects([...selectedItemObjects, ...allObjs]);
    } else {
      setIsAllOnPageSelected(false);
      setIsAllSelected(false);
      setSelectedItems(allIds);
      setSelectedItemObjects(allObjs);
    }
  };

  const pageSizeChangeHandler = (newPageSize: number) => {
    onPageChange(newPageSize, 0);
  };

  const pageChangeHandler = (newPageIndex: number) => {
    const { limit } = data;
    const offset = limit * newPageIndex;
    onPageChange(limit, offset);
  };

  const {
    columns,
    footerItems,
    actionOptions,
    sortOptions,
    statusFilterOptions,
    additionalStatusFilterOptions,
    moreButtonText,
    searchLimit,
    preventMultipleItemSelect,
  } = tableConfig;

  const compact = tableStyle === "compact";
  const useBasicTable = tableStyle === "basic";

  const dataItemsStart = data.offset + 1;
  let dataItemsEnd = data.offset + data.limit;
  if (dataItemsEnd > data.totalItems) {
    dataItemsEnd = data.totalItems;
  }

  const totalPages = Math.ceil(data.totalItems / data.limit);
  let currentPageIndex = Math.floor(dataItemsStart / data.limit);
  if (currentPageIndex >= totalPages) {
    currentPageIndex = totalPages - 1;
  }

  const headerAndFooterActionOptions = actionOptions?.filter(
    (c) => c.props.hideInTableHeader !== true
  ).map((item) => React.cloneElement(item, {
    shouldDisable: () => selectedItems.length === 0
          || (isAllSelected && item.props.availableWhenAllSelected === false)
          || (isAllOnPageSelected && item.props.availableWhenPageSelected === false)
          || (item.props.shouldDisable != null && item.props.shouldDisable()),
    onClick: () => {
      if (!item.props.onClick) {
        return;
      }
      item.props.onClick(selectedItems, isAllSelected, selectedItemObjects);
    },
  }));

  const canSelectRows = (headerAndFooterActionOptions?.length ?? 0) > 0;

  let gridTemplateColumns: CSSProperties;
  if (compact) {
    // first item is on its own row
    const filteredColumns = columns.filter((_, i) => i !== 0);
    gridTemplateColumns = {
      gridTemplateColumns: `${filteredColumns.map((c) => c.size).join(" ")}`,
    };
  } else if (canSelectRows) {
    // add column for checkbox
    gridTemplateColumns = {
      gridTemplateColumns: `20px ${columns.map((c) => c.size).join(" ")}`,
    };
  } else {
    gridTemplateColumns = {
      gridTemplateColumns: `${columns.map((c) => c.size).join(" ")}`,
    };
  }

  const tableClassName = compact
    ? `${Styles.SearchTable} ${Styles.SearchTableCompact}`
    : Styles.SearchTable;

  const exceededSearchLimit = searchLimit != null
  && data.totalItems > searchLimit
  && (data.limit > searchLimit
    || data.offset + 1 > searchLimit
    || data.limit + data.offset > searchLimit);

  const generateTableRows = () => {
    const tableRowsItems = data?.items.map((row) => (
      <TableRowItem
        selected={selectedItems.includes(row.id)}
        onSelectedChanged={(isSelected) => onSelectedChangedHandler(
          row.id,
          row,
          isSelected,
          data?.items.length
        )}
        key={`${row.id}`}
        columns={columns}
        footerItems={footerItems}
        canSelect={!compact && canSelectRows}
        actionOptions={actionOptions}
        data={row}
        gridTemplateColumns={gridTemplateColumns}
        onViewMore={onViewMore}
        onViewMoreDisabled={onViewMoreDisabled != null ? onViewMoreDisabled(row) : false}
        compact={compact}
        moreButtonText={moreButtonText}
        disabled={preventMultipleItemSelect}
        requiredItemSelectFeature={requiredMutipleItemSelectFeature}
        requiredViewMoreFeature={requiredViewMoreFeature}
      />
    ));

    if (exceededSearchLimit) {
      return (
        <Fragment>
          {tableRowsItems}
          <Alert color="info" className="p-5 border rounded">
            <h5 className="alert-heading"><b>Search Result Limit Reached</b></h5>
            Your current subscription package allows you to view a maximum of
            {" "}
            <b>{searchLimit}</b>
            {" "}
            search results. You will need to upgrade your subscription package in
            order to increase this limit.
            <hr />
            Please review your subscription for more details.
          </Alert>
        </Fragment>
      );
    }
    return tableRowsItems;
  };

  return (
    <div className={tableClassName}>
      <Line color="secondary" className="my-xl-3" />

      <TableHeader
        totalItems={data.totalItems}
        firstItem={dataItemsStart}
        lastItem={dataItemsEnd}
        actionOptions={headerAndFooterActionOptions}
        isAllOnPageSelected={isAllOnPageSelected}
        isAllSelected={isAllSelected}
        onSelectAllOnPage={(value) => onSelectAllOnPageHandler(
          value,
          data.items.map((c) => c.id),
          data.items
        )}
        onSelectAll={async (value) => {
          if (onSelectAllResultsCallback && value) {
            const results = await onSelectAllResultsCallback();
            const allIds = results.map((element) => element.id);

            onSelectAllOnPageHandler(isAllOnPageSelected, allIds, results);
          }
          setIsAllSelected(value);

          // clear all selections
          if (value === false) {
            onSelectAllOnPageHandler(false, [], []);
          }
        }}
        pageSizes={pageSizes}
        selectedPageSize={data.limit}
        onPageSizeChange={(newPageSize) => pageSizeChangeHandler(newPageSize)}
        compact={compact}
        title={title}
        sortOptions={sortOptions}
        onSortOptionChange={onSortOptionChange}
        statusFilterOptions={statusFilterOptions}
        onStatusFilterChange={onStatusFilterChange}
        additionalStatusFilterOptions={additionalStatusFilterOptions}
        additionalStatusCondRenderExclusions={additionalStatusCondRenderExclusions}
        onAdditionalStatusFilterChange={onAdditionalStatusFilterChange}
        additionalStatusFilterName={additionalStatusFilterName}
        disableCheckBox={preventMultipleItemSelect}
        requiredCheckBoxFeature={requiredMutipleItemSelectFeature}
      />

      {!compact && !useBasicTable && (
        <div className={Styles.SearchTableHeader} style={gridTemplateColumns}>
          {canSelectRows && <div />}
          {columns.map((column) => {
            let className = "";
            switch (column.align) {
              case "center":
                className = "text-center";
                break;
              case "right":
                className = "text-right";
                break;
              default:
                className = "text-left";
                break;
            }
            return (
              <label className={className} key={column.key} htmlFor="nothing">{column.heading}</label>
            );
          })}
        </div>
      )}

      {useBasicTable && (
        <BasicTable
          loading={loading}
          emptyMessage="No results found"
          columns={columns.map((c) => ({
            key: c.key,
            text: c.heading,
            align: c.align,
            size: c.size,
          }))}
          rows={data.items.map((dataItem) => columns
            .map((column) => (
              {
                key: `${column.key}-${dataItem.id}`,
                value: column.propName != null
                  ? dataItem[column.propName]
                  : "",
                valueTemplate: column.valueTemplate != null
                  ? () => {
                    if (column.valueTemplate != null) {
                      return column.valueTemplate(dataItem);
                    }
                    return <p />;
                  }
                  : undefined,
                fullSize: column.fullSize,
              }
            )))}
        />
      )}

      {/* No Results message */
        !loading && !useBasicTable && !!data.items && data.items.length === 0 ? (
          <NoResultsCard>
            No results found
          </NoResultsCard>
        ) : null
      }

      {/* Loading spinner */
        loading && !useBasicTable && (
          <Spinner inline />
        )
      }

      {/* Data is available (not loading) */
        loading || useBasicTable
        || generateTableRows()
      }

      <TableFooter
        totalItems={data.totalItems}
        firstItem={dataItemsStart}
        lastItem={dataItemsEnd}
        totalPages={totalPages}
        currentPageIndex={currentPageIndex}
        onPageChange={(newPage) => pageChangeHandler(newPage)}
        actionOptions={headerAndFooterActionOptions}
        isAllSelected={isAllOnPageSelected}
        onSelectAll={(value) => onSelectAllOnPageHandler(
          value,
          data.items.map((c) => c.id),
          data.items
        )}
        pageSizes={pageSizes}
        selectedPageSize={data.limit}
        onPageSizeChange={(newPageSize) => pageSizeChangeHandler(newPageSize)}
        compact={compact}
        disableCheckBox={preventMultipleItemSelect}
        requiredCheckBoxFeature={requiredMutipleItemSelectFeature}
      />
    </div>
  );
};

export default Table;
export * from "./Interfaces/TableConfig";
export * from "./Interfaces/FooterItem";
export * from "./Interfaces/TableConfig";
export * from "./Interfaces/TableData";
