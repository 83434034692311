import React, { Fragment, useState } from "react";
import { Collapse, CardBody, Card } from "reactstrap";
import Spinner from "../../Spinner/Spinner";
import NoResultsCard from "../NoResultCard/NoResultsCard";

import {
  BasicTableGroupCell, // eslint-disable-line no-unused-vars
} from "./Interfaces/BasicTableGroupCell";
import {
  BasicTableColumn, // eslint-disable-line no-unused-vars
} from "./Interfaces/BasicTableColumn";
import {
  BasicTableCell, // eslint-disable-line no-unused-vars
} from "./Interfaces/BasicTableCell";
import {
  BasicTableTotalCell, // eslint-disable-line no-unused-vars
} from "./Interfaces/BasicTableTotalCell";
// eslint-disable-next-line no-unused-vars
import { SystemFeatures } from "../../..";

import Styles from "./BasicTable.module.scss";
import DropDownButton from "../../DropDownButton/DropDownButton";
import { useSystemFeatureCheck } from "../../../Hooks/useSystemFeatureCheck";
import { useShowNotAllowedError } from "../../../Hooks/useNotAllowedError";

const BasicTable = <T extends {}>(
  {
    columns = [],
    rows = [],
    subRows = [],
    groupCells = [],
    emptyMessage,
    loading,
    viewSubRowFeature,
    totalCell,
    tableStyle = "default",
  }
  :
  {
    columns: (BasicTableColumn | string)[],
    rows?: BasicTableCell<T>[][],
    subRows?: JSX.Element[],
    groupCells?: BasicTableGroupCell<T>[],
    totalCell?: BasicTableTotalCell,
    emptyMessage?: string | undefined,
    viewSubRowFeature?: SystemFeatures,
    loading?: boolean | undefined,
    tableStyle?: "default" | "light" | "mobile",
  }
) => {
  const [isOpen, setIsOpen] = useState<Array<boolean>>([]);

  const toggle = (index: number) => setIsOpen((currentIsOpen) => {
    const newIsOpen = [...currentIsOpen];
    newIsOpen[index] = !newIsOpen[index];
    return newIsOpen;
  });

  const featureCheck = useSystemFeatureCheck();
  const showNotAllowedError = useShowNotAllowedError();

  const columnsToRender = [...columns];
  if (subRows.length !== 0) {
    columnsToRender.push({
      key: "expandRows",
    });
  }

  const gridStyle = {
    gridTemplateColumns: new Array(groupCells.length + columnsToRender.length)
      .fill("auto")
      .map((_, i) => {
        const column = columnsToRender[i];
        if (column == null || typeof column === "string") {
          return "auto";
        }
        return column.size ?? "auto";
      })
      .join(" "),
  };

  const groupCellContents = groupCells.map((cell) => {
    const groupCellStyle = {
      gridRow: `span ${rows.length}`,
    };

    const classes = [Styles.BasicTableCell];
    if (cell.fullSize) {
      classes.push(Styles.FullSizeCell);
    }

    return (
      <div key={cell.key} style={groupCellStyle} className={classes.join(" ")}>
        {cell.valueTemplate != null ? cell.valueTemplate() : cell.value}
      </div>
    );
  });

  const getColumnContent = (column: string | BasicTableColumn) => {
    if (typeof column === "string") {
      return column;
    }

    let className = "";
    switch (column.align) {
      case "left":
        className = "text-left";
        break;
      case "right":
        className = "text-right";
        break;
      default:
        className = "text-center";
        break;
    }

    return (
      <div className={className}>
        <div>
          {column.text}
        </div>
        <div>
          {column.subText ? column.subText : ""}
        </div>
      </div>
    );
  };

  const getColumnKey = (column: string | BasicTableColumn) => {
    if (typeof column === "string") {
      return column;
    }
    return column.key;
  };

  const getTextAlignmentClass = (align: string) => {
    let className = "";
    switch (align) {
      case "left":
        className = "justify-content-xl-start";
        break;
      case "right":
        className = "justify-content-xl-end";
        break;
      case "center":
        className = "justify-content-xl-center";
        break;
      default:
        break;
    }

    return className;
  };

  const desktopVersionClass = tableStyle === "mobile" ? "d-none" : "desktop-version";
  const mobileVersionClass = tableStyle === "mobile" ? "" : "mobile-version";

  const cells = rows.map((row, rowIndex) => {
    const rowContent = row.map((cell, cellIndex) => {
      const classes = [cell.className, Styles.BasicTableCell];
      if (cell.fullSize && cell.valueTemplate != null) {
        classes.push(Styles.FullSizeCell);
      }

      classes.push(getTextAlignmentClass(cell.align ?? ""));

      return (
        <Fragment key={cell.key}>
          <div className={`${mobileVersionClass} ${Styles.BasicTableCell}`}>
            {getColumnContent(columnsToRender[cellIndex])}
          </div>
          <div className={classes.join(" ")}>
            {cell.valueTemplate != null ? cell.valueTemplate() : cell.value}
          </div>
        </Fragment>
      );
    });

    if (subRows.length !== 0) {
      const subRowButton = (
        <div
          key="expandRows"
          className={`${Styles.ExpandRows} ${Styles.BasicTableCell}`}
        >
          <DropDownButton
            up={isOpen[rowIndex]}
            label="Details"
            badge=""
            onClick={() => {
              if (viewSubRowFeature == null || featureCheck(viewSubRowFeature)) {
                toggle(rowIndex);
              } else {
                showNotAllowedError(viewSubRowFeature);
              }
            }}
          />
        </div>
      );
      rowContent.push(subRowButton);
    }

    const subRowContent = (subRows.length !== 0)
      ? (
        <div className={Styles.SubRowContent} style={{ gridColumn: `span ${columnsToRender.length}` }}>
          <Collapse className={Styles.SubRowContentCard} isOpen={isOpen[rowIndex]}>
            <Card>
              <CardBody>
                {subRows[rowIndex]}
              </CardBody>
            </Card>
          </Collapse>
        </div>
      )
      : null;

    const finalRowContent = (
      <Fragment>
        {rowContent}
        {subRowContent}
      </Fragment>
    );

    return finalRowContent;
  });

  return (
    <div className="d-flex flex-column">
      <div
        className={`${
          Styles.BasicTable
        } ${
          tableStyle === "light" ? Styles.BasicTableLight : ""
        } ${
          tableStyle === "mobile" ? Styles.BasicTableMobileStyle : ""
        }`}
        style={gridStyle}
      >
        {groupCells.map((cell) => (
          <div className={`${Styles.BasicTableColumnHeader} ${desktopVersionClass}`} key={cell.key}>
            {cell.columnHeading}
          </div>
        ))}

        {columnsToRender.map((column) => (
          <div className={`${Styles.BasicTableColumnHeader} ${desktopVersionClass}`} key={getColumnKey(column)}>
            {getColumnContent(column)}
          </div>
        ))}

        {/* Mobile cells */}
        {!loading && groupCellContents.map((cell, index) => (
          <div
            className={`${mobileVersionClass} ${Styles.BasicTableRow} ${Styles.BasicTableGroupRow}`}
            key={cell.key ?? undefined}
          >
            <div className={Styles.BasicTableCell}>
              {groupCells[index].columnHeading}
            </div>
            {cell}
          </div>
        ))}
        {!loading && cells.map((c, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={`${mobileVersionClass} ${Styles.BasicTableRow}`} key={i}>
            {c}
          </div>
        ))}

        {/* Desktop cells */}
        {!loading && cells.map((c, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={i}>
            {i === 0 ? groupCellContents : null}
            {c}
          </Fragment>
        ))}

        {/* Total cells */}
        {!loading && cells.length > 0
        && totalCell != null
        && (
          <Fragment>
            {/* Desktop cells */}
            <div
              style={{ gridColumnStart: totalCell.colIndex }}
              className={`${Styles.BasicTableCell} ${Styles.FooterCell}`}
            >
              {totalCell.label}
            </div>
            <div
              className={`
              ${Styles.BasicTableCell} 
              ${Styles.FooterCell}
              ${getTextAlignmentClass(totalCell.align ?? "")}
              `}
            >
              {totalCell.valueTemplate()}
            </div>

            {/* Mobile Footer cells */}
            <div className={`${mobileVersionClass} ${Styles.BasicTableRow}`}>
              <div className={`${mobileVersionClass} ${Styles.BasicTableCell}`}>
                {totalCell.label}
              </div>
              <div className={`${mobileVersionClass} ${Styles.BasicTableCell}`}>
                {totalCell.valueTemplate()}
              </div>
            </div>
          </Fragment>
        )}
      </div>
      {emptyMessage != null && !loading && cells.length === 0
        && (
          <NoResultsCard>
            {emptyMessage}
          </NoResultsCard>
        )}

      {loading && <Spinner inline />}
    </div>
  );
};

export default BasicTable;
export * from "./Interfaces/BasicTableCell";
export * from "./Interfaces/BasicTableGroupCell";
export * from "./Interfaces/BasicTableColumn";
export * from "./Interfaces/BasicTableTotalCell";
