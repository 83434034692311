import React from "react";

import { Route, Switch, Redirect } from "react-router-dom";

import EnterpriseConfigStep01 from "../Sections/EnterpriseConfig/EnterpriseConfigStep01";
import EnterpriseConfigStep02 from "../Sections/EnterpriseConfig/EnterpriseConfigStep02";
import EnterpriseConfigStep03 from "../Sections/EnterpriseConfig/EnterpriseConfigStep03";
import EnterpriseConfigStep04 from "../Sections/EnterpriseConfig/EnterpriseConfigStep04";

function EnterpriseConfigView() {
  return (
    <section>
      <Switch>
        <Route
          path="/mycompany/enterprise-configuration/geninfo"
          component={() => <EnterpriseConfigStep01 />}
        />
        <Route
          path="/mycompany/enterprise-configuration/catinfo"
          component={() => <EnterpriseConfigStep02 />}
        />
        <Route
          path="/mycompany/enterprise-configuration/tacinfo"
          component={() => <EnterpriseConfigStep03 />}
        />
        <Route
          path="/mycompany/enterprise-configuration/supinfo"
          component={() => <EnterpriseConfigStep04 />}
        />
        <Redirect to="/mycompany/enterprise-configuration/geninfo" />
      </Switch>
    </section>
  );
}

export default EnterpriseConfigView;
