import React from "react";
import { ReactComponent as SvgClose } from "../../images/close.svg";

import ImageButton from "../ImageButton/ImageButton";

const ModalCloseButton = ({ onClick } : {onClick: () => void}) => (
  <div className="d-flex justify-content-end">
    <ImageButton svg={SvgClose} onClick={onClick} alt="Close" className="text-action" />
  </div>
);

export default ModalCloseButton;
