import React from "react";

import { ProgressIndicator, PageHeading } from "isuppli-react-components";

import navMenuItems from "../../Util/menuItems";

function EnterpriseConfigProgress({ currentStep }) {
  return (
    <section id="isuppli-enterpriseconfig-progress" className="bg-light">
      <div className="padding-lr-10vw">
        <PageHeading
          heading="Enterprise Configuration"
          alertText="Please help us understand your business by providing the information required on each of the steps."
          menuItems={navMenuItems}
        />

        <ProgressIndicator
          stepsCompleted={currentStep - 1}
          onClick={() => {}}
          currentStep={currentStep}
          steps={[
            "GENERAL SETTINGS",
            "SUPPLIER CATEGORIES",
            "TERMS & CONDITIONS",
            "SUPPORTING DOCUMENTS",
          ]}
        />
      </div>
    </section>
  );
}

export default EnterpriseConfigProgress;
