import React, { Component } from "react";
import { connect } from "react-redux";

import {
  PageHeading, SystemFeatures, hasFeature, withToast,
  UtilButton, Line, DataFeatures,
} from "isuppli-react-components";
import NavTabs from "./NavTabs";
import Loading from "../Loading";

import navMenuItems from "../../Util/menuItems";
import AddressInfo from "../../Containers/Supplier/AddressInfo";
import KeyContactStaff from "../../Containers/Supplier/KeyContactStaff";

import { loadAddressContactData } from "../../Util/dataLoader";

import {
  onAddressInfoChange,
  onStaffContactInfoChange,
  showLoadingScreen,
  hideLoadingScreen,
  showInfoPopup,
  hideInfoPopup,
  setStoreProperty,
  showErrorDialog,
  setCompanyOutstandingInfo,
} from "../../Store/actions";
import { setOption } from "../../Store/optionActions";
import { setRegistrationObject, setPostalAddress } from "../../Store/registrationActions";

import { saveAddressInfo, saveKeyStaffContactDetails } from "../../http/posts";

import addressTypes from "../../Util/Enumerators/addressTypes";

import validateStep from "../../Validation/onSubmitValidations";
import { requiredFieldsStep2 } from "../../Validation/validation";
import InfoPopup from "../../Containers/InfoPopup";
import { StaticDataTypes } from "../../Util/Enumerators/staticDataTypes";
import StaticData from "../../Hooks/StaticData";
import {
  getCompanyDataFeatures,
  // eslint-disable-next-line import/named
  getCompanyOutstandingInformation,
} from "../../http";

const mapStateToProps = (state) => ({
  data: {
    ...state.registration.addressInfo,
    companyId: state.currentCompanyId,
  },
  provinceOptions: state.staticData[StaticDataTypes.Province] ?? [],
  countryOptions: state.staticData[StaticDataTypes.Country] ?? [],
  municipalityOptions: state.staticData[StaticDataTypes.Municipality] ?? [],
  contactPersonTypes: state.staticData[StaticDataTypes.ContactPerson],
  isStepValid: state.isValidStep2,
  allowedFeatures: state.loggedInUserDetails.allowedFeatures,
});

const mapDispatchToProps = (dispatch) => ({
  setPostalAddress: (physicalAddress) => {
    dispatch(setPostalAddress(physicalAddress));
  },
  onAddressInputChange: (category, ev) => {
    dispatch(onAddressInfoChange(category, ev));
  },
  setOption: (optionName, optionValues) => {
    dispatch(setOption(optionName, optionValues));
  },
  setAddressInfo: (addressInfo) => {
    dispatch(setRegistrationObject("addressInfo", addressInfo));
  },
  onStaffContactInfoChange: (newEntry) => {
    dispatch(onStaffContactInfoChange(newEntry));
  },
  showLoadingScreen: (msg) => {
    dispatch(showLoadingScreen(msg));
  },
  hideLoadingScreen: () => {
    dispatch(hideLoadingScreen());
  },
  setStepValid: (stepName) => (isValid) => {
    dispatch(setStoreProperty(stepName, isValid));
  },
  showInfoMsg: (heading, subheading, msgs) => {
    dispatch(showInfoPopup(heading, subheading, msgs));
  },
  hideInfoMsg: () => {
    dispatch(hideInfoPopup());
  },
  showErrorDialog: () => {
    dispatch(showErrorDialog(true));
  },
  updateOutstandingInformation: (outstatndingInformation) => {
    dispatch(setCompanyOutstandingInfo(outstatndingInformation));
  },
});

class AddressContactInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msgs: {},
    };
  }

  extractKeyContactStaffDetails = () => {
    const contactPersons = this.props.data.contactPersons.map((
      person
    ) => (person == null || (!person.fullName || !person.email || !person.contactNumber)
      ? null
      : person));

    return contactPersons;
  };

  performSave() {
    const physical = {
      ...this.props.data.physicalAddress,
      type: addressTypes.Physical,
    };
    const postal = {
      ...this.props.data.postalAddress,
      type: addressTypes.Postal,
    };

    const content = {
      companyId: this.props.data.companyId,
      addresses: [physical, postal],
    };
    this.props.showLoadingScreen("saving data...");
    saveAddressInfo(
      {
        ...content,
        registrationStepCompleted: this.props.data.registrationStepCompleted,
      },
      () => {
        const keyContactStaff = this.extractKeyContactStaffDetails().filter(
          (i) => !!i
        );

        saveKeyStaffContactDetails(
          {
            companyId: this.props.data.companyId,
            contactPersons: keyContactStaff,
            registrationStepCompleted: this.props.data.registrationStepCompleted,
          },
          async () => {
            this.props.hideLoadingScreen();
            this.props.showToast("Address information saved");
            const outstatndingInformation = await getCompanyOutstandingInformation();
            this.props.updateOutstandingInformation(outstatndingInformation);
          },
          () => {
            this.props.hideLoadingScreen();
            this.props.showErrorDialog();
          }
        );
      },
      () => {
        this.props.hideLoadingScreen();
        this.props.showErrorDialog();
      }
    );
  }

  onClickSave = async () => {
    const dataFeatures = await getCompanyDataFeatures();
    const validation = validateStep(this.props.data, requiredFieldsStep2);
    const physAdd = this.props.data.physicalAddress;
    const postAdd = this.props.data.postalAddress;
    const hasMunicipalities = this.props?.municipalityOptions.filter((prov) => prov.provinceId === (physAdd?.province ?? -1)).length > 0;

    if (physAdd !== undefined && physAdd.country <= 0) {
      validation.isValid = false;
      validation.msgs = {
        ...validation.msgs,
        physicalAddressCountry: "Physical address: country is required",
      };
    }

    if (physAdd !== undefined
      && physAdd.hasProvinces === true
      && (physAdd.province === undefined || physAdd.province <= 0)) {
      validation.isValid = false;
      validation.msgs = {
        ...validation.msgs,
        physicalAddressProvince: "Physical address: province is required",
      };
    }

    if (physAdd !== undefined
      && hasMunicipalities
      && hasFeature(dataFeatures.dataFeatures, DataFeatures.MandatoryMunicipality)
      && (physAdd.municipality === undefined || physAdd.municipality <= 0)) {
      validation.isValid = false;
      validation.msgs = {
        ...validation.msgs,
        physicalAddressMunicipality: "Physical address: municipality is required",
      };
    }

    if (postAdd !== undefined && postAdd.country <= 0) {
      validation.isValid = false;
      validation.msgs = {
        ...validation.msgs,
        postalAddressProvince: "Postal address: country is required",
      };
    }

    if (postAdd !== undefined
      && postAdd.hasProvinces === true
      && (postAdd.province === undefined || postAdd.province <= 0)) {
      validation.isValid = false;
      validation.msgs = {
        ...validation.msgs,
        postalAddressCountry: "Postal address: province is required",
      };
    }

    if (
      this.extractKeyContactStaffDetails().length
      !== this.extractKeyContactStaffDetails().filter((i) => !!i).length
    ) {
      validation.msgs.keycontactstaffdetails = "Key contact staff details is required";
      validation.isValid = false;
    }

    this.setState({
      msgs: validation.msgs,
    });

    if (validation.isValid && this.props.isStepValid) {
      this.props.hideInfoMsg();
      this.performSave();
    } else {
      window.scrollTo({ top: 0 });
      this.props.showInfoMsg(
        "This page contains missing information.",
        "To continue please amend/complete the following:",
        Object.keys(validation.msgs).map((key) => validation.msgs[key])
      );
    }
  };

  componentDidMount() {
    loadAddressContactData(this.props);
  }

  render() {
    const canEdit = hasFeature(
      this.props.allowedFeatures,
      SystemFeatures.CompanyProfileEdit
    );

    return (
      <section id="isuppli-supplier-info">
        <StaticData
          optionsToLoad={[
            StaticDataTypes.Municipality,
            StaticDataTypes.Province,
            StaticDataTypes.Country,
          ]}
        />
        <Loading />
        <InfoPopup />

        <div className="padding-lr-10vw" style={{ paddingTop: "20px" }}>
          <PageHeading
            heading="Summary of your business information"
            alertText="It is important to keep your information up to date. Not updating your information may result in your business being removed from the portal."
            menuItems={navMenuItems}
          />

          <NavTabs />

          <AddressInfo
            readOnly={!canEdit}
            data={this.props.data}
            provinces={this.props.provinceOptions}
            countries={this.props.countryOptions}
            municipalities={this.props.municipalityOptions}
            setPostalAddress={this.props.setPostalAddress}
            additional={this.state.msgs}
            onInputChangeCallback={this.props.onAddressInputChange}
            setStepValid={this.props.setStepValid("isValidStep2")}
          />
        </div>

        <div
          className="padding-lr-5vw"
          style={{ marginTop: "36px", marginBottom: "36px" }}
        >
          <hr />
        </div>

        <div className="padding-lr-10vw">
          <div>
            <h2 className="Main">KEY CONTACT DETAILS</h2>
          </div>

          <div className="row">
            <div className="col-12 col-lg-8">
              Please capture the information for the following key contacts within your company.
            </div>
          </div>

          <div style={{ paddingTop: "36px" }} />

          <KeyContactStaff
            readOnly={!canEdit}
            details={this.props.data.contactPersons || []}
            hasTelNumValidation={this.props.data.hasTelNumberValidation}
            onInputChangeCallback={this.props.onStaffContactInfoChange}
          />
        </div>

        <div
          className="padding-lr-5vw"
          style={{ paddingBottom: "76px", marginTop: "60px" }}
        >
          <div className="justify-end row">
            <Line color="secondary" />
            {canEdit
            && (
              <UtilButton
                color="primary"
                location="bottom"
                onClick={this.onClickSave}
              >
                SAVE
              </UtilButton>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default withToast(connect(mapStateToProps, mapDispatchToProps)(AddressContactInfo));
