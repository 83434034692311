import React, { Fragment, useState, useEffect } from "react";
import {
  ModalPopup,
  Spinner,
} from "isuppli-react-components";

import StaticCompanyData from "../../../Hooks/StaticCompanyData";
import staticCompanyDataTypes, {
  StaticCompanyDataTypes,
} from "../../../Util/Enumerators/staticCompanyDataTypes";
import useStaticCompanyData from "../../../Hooks/useStaticCompanyData";
import { getSupplierPaymentTermsId, updateSupplierPaymentTerms } from "../../../http/Onboarding/OnboardingApi";

import Styles from "./PaymentTermsUpdate.module.scss";

const PaymentTermsUpdate = ({
  companyId,
  onCloseCallback,
}: {
  companyId: number;
  onCloseCallback: () => void;
}) => {
  const [loading, setLoading] = useState(true);
  const [internalValue, setInternalValue] = useState<number>();
  const [text, setText] = useState<string>("");

  useEffect(() => {
    getSupplierPaymentTermsId(companyId)
      .then((value) => {
        setInternalValue(value);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [companyId]);

  const [[paymentTerms], isReady] = useStaticCompanyData([
    StaticCompanyDataTypes.paymentTerms,
  ]);

  // dropdown options for payment terms
  const buildSelection = () => {
    const optionsToShow = paymentTerms.map((terms, i) => <option key={i} value={terms.id}>{terms.name}</option>);
    return (
      <select
        className="form-control"
        value={internalValue}
        onChange={(e) => setInternalValue(parseInt(e.target.value, 10))}
      >
        {optionsToShow}
      </select>
    );
  };

  return (
    <Fragment>
      <StaticCompanyData
        optionsToLoad={[staticCompanyDataTypes.paymentTerms]}
      />
      <ModalPopup
        onClose={() => onCloseCallback()}
        hasCancel
        heading="Update Payment Terms"
        subheading=""
        size="medium"
        buttons={[
          {
            key: "ok",
            label: "Save",
            onClick: () => {
              updateSupplierPaymentTerms(companyId, internalValue as number, text)
                .then(() => {
                  onCloseCallback();
                })
                .catch((err) => {
                  console.log(err);
                })
                .finally(() => {
                  setLoading(false);
                });
            },
            color: "primary",
          },
        ]}
      >
        <div className={Styles.Container}>
          <p className="mx-2">
            Update payment terms to reflect your current business
          </p>
          <div className="row pt-3 px-2">
            <div className="col-12">
              {isReady && !loading ? buildSelection() : <Spinner inline />}

              <div className="row py-3 px-4">
                <div className="col-12">
                  <textarea
                    className={`${Styles.TextArea} w-100 p-2`}
                    value={text}
                    onChange={(ev) => setText(ev.target.value)}
                    placeholder="COMMENTS"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalPopup>
    </Fragment>
  );
};

export default PaymentTermsUpdate;
