import React, { Component } from "react";
import { getStaticValue, HelpIcon } from "isuppli-react-components";
import FileOptions from "./FileOptions";
import DocumentClassification from "../../Util/Enumerators/DocumentClassification";
import DateInput from "../../Controls/DateInput/DateInput";

class DocumentUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadProgress: [],
      uploadFailed: [],
      fileSizeExceeded: [],
    };
  }

  onDateInputChangeCallback = (ev, doc) => {
    const newDocuments = this.props.documents.map(
      (d) => (d.documentTypeId !== doc.documentTypeId
        ? d
        : {
          ...doc,
          expiryDate: ev.target.value,
        })
    );
    this.props.onDocumentsChanges(newDocuments);
  }

  onDocumentUploading = (index, progress) => {
    this.setState((currentState) => {
      const progresses = [...currentState.uploadProgress];
      progresses[index] = progress;
      return {
        uploadProgress: progresses,
      };
    });
  };

  onDocumentUploaded = (doc, index, uploadDocumentMessage) => {
    const newDocuments = [...this.props.documents];
    newDocuments[index] = {
      ...doc,
      documentId: uploadDocumentMessage?.documentId,
      filename: uploadDocumentMessage?.filename,
      originalFilename: uploadDocumentMessage?.originalFilename,
    };

    this.props.onDocumentsChanges(newDocuments);

    const failed = uploadDocumentMessage?.filename == null;
    this.setState((currentState) => {
      const failedUploads = [...currentState.uploadFailed];
      failedUploads[index] = failed;
      return {
        uploadFailed: failedUploads,
      };
    });

    // clear progress
    this.onDocumentUploading(index, null);
  };

  onUploadFileSizeExceeded = (index, newVal) => {
    this.setState((currentState) => {
      const newFileSizeExceeded = [...currentState.fileSizeExceeded];
      newFileSizeExceeded[index] = newVal;
      return {
        fileSizeExceeded: newFileSizeExceeded,
      };
    });
  }

  deleteDocument = (doc) => {
    const newDocuments = this.props.documents.map(
      (d) => (d.documentId !== doc.documentId
        ? d
        : {
          ...doc,
          filename: null,
          originalFilename: null,
        })
    );
    this.props.onDocumentsChanges(newDocuments);
  }

  isBeeAffidavit = (documentTypeId) => {
    const documentType = this.props.documentTypes.find((x) => x.id === documentTypeId);
    if (documentType !== undefined
        && documentType?.documentClassification === DocumentClassification.BeeAffidavit) {
      return true;
    }
    return false;
  }

  render() {
    const rows = this.props.documents.map((doc, i) => {
      const uploadProgress = this.state.uploadProgress[i];
      const failed = this.state.uploadFailed[i];
      const fileSizeExceeded = this.state.fileSizeExceeded[i];
      let statusContent = "Document not uploaded";
      let statusClassName = "";
      const contextQuery = `${doc.contextId != null ? `?contextId=${doc.contextId}` : ""}`;
      const queryPrefix = contextQuery === "" ? "?" : "&";
      const manualSupplierQuery = `${this.props.manualSupplierId != null ? `${queryPrefix}manualSupplierId=${this.props.manualSupplierId}` : ""}`;
      const documentType = this.props.documentTypes.find((x) => x.id === doc.documentTypeId);
      const hasTemplate = (documentType?.documentTemplate ?? null) != null;

      if (doc.filename != null) {
        statusContent = doc.originalFilename ?? doc.filename;
      } else if (hasTemplate) {
        statusContent = "Download Document Template";
      }

      if (fileSizeExceeded) {
        statusContent = "File size exceeds 10 MB";
        statusClassName = "text-danger";
      }

      if (failed) {
        statusContent = "Upload Failed. Please try again.";
        statusClassName = "text-danger";
      }

      if (uploadProgress != null) {
        if (uploadProgress < 0) {
          statusContent = "Upload Failed. Please try again.";
          statusClassName = "text-danger";
        } else {
          statusContent = `Uploading: ${Math.round(uploadProgress * 100)}%`;
        }
      }

      return (
        <tr key={i}>
          <td>
            {doc.displayPrefix ? `${doc.displayPrefix} - ` : ""}
            <p>
              <span
                style={{ color: hasTemplate ? "blue" : null }}
                onClick={(e) => {
                  e.preventDefault();
                  if (hasTemplate) window.open(`${this.props.downloadUrl}${documentType?.documentTemplate}`, "_blank");
                }}
              >
                {getStaticValue(doc.documentTypeId, this.props.documentTypes, doc.filename)}
                {doc.optional ? <b><i> (optional)</i></b> : ""}
              </span>
              {this.isBeeAffidavit(doc.documentTypeId) && (
                <HelpIcon
                  url="http://b1sa.helpscoutdocs.com/category/38-affidavit-templates"
                  tooltipMessage="View B-BBEE affidavit requirements and templates"
                />
              )}
            </p>
          </td>
          <td className={statusClassName}>{statusContent}</td>
          <td>
            {doc.expires ? (
              <DateInput
                min="1900-01-01"
                name="DocumentExpiryDate"
                value={doc.expiryDate}
                onChangeCallback={(ev) => this.onDateInputChangeCallback(ev, doc)}
              />
            ) : <p>N/A</p>}
          </td>
          <td>
            <FileOptions
              uploadUrl={`${this.props.uploadUrl}${doc.documentTypeId}${contextQuery}${manualSupplierQuery}`}
              downloadUrl={this.props.downloadUrl}
              fileName={doc.filename}
              onUploadProgress={(progress) => this.onDocumentUploading(i, progress)}
              onUploadDone={
                (uploadDocumentMessage) => this.onDocumentUploaded(doc, i, uploadDocumentMessage)
              }
              onUploadFileSizeExceeded={(newVal) => this.onUploadFileSizeExceeded(i, newVal)}
              deleteDocument={() => this.deleteDocument(doc)}
              readOnly={this.props.readOnly}
              template={documentType?.documentTemplate}
              docExpires={doc.expires}
              expiryDate={doc.expiryDate}
            />
          </td>
        </tr>
      );
    });
    return (
      <section>
        <div>
          <h2>{this.props.header || "UPLOAD SUPPORTING DOCUMENTS"}</h2>
        </div>

        <div className="row">
          <div className="col-12 col-lg-9">
            Please ensure that all documents uploaded are the latest documents, and valid.
            Supported files include .pdf, .jpg and .png no larger than 10MB.
          </div>
        </div>

        <div style={{ paddingTop: "36px" }} />

        <table className="isuppli-table">
          <thead>
            <tr>
              <th>Document name</th>
              <th>Status</th>
              <th>Expires</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </section>
    );
  }
}

export default DocumentUploader;
