import React, { Component } from "react";

import FileUploader from "../../Controls/FileUploader";

class LogoUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: "",
    };
  }

  onDocumentUploading = () => {};

  onDocumentUploaded = () => {};

  render() {
    return (
      <section>
        <div className="row">
          <div className="col-12 col-sm-4">
            <h2>COMPANY LOGO</h2>
            {this.state.fileName.length === 0 && (
              <div className="justify-start">
                <span className="right-margin-10px">No Image</span>

                <span className="orange-text">
                  <FileUploader
                    index={0}
                    labelText="Upload image"
                    uploadUrl="/api/logos"
                    onFileUploadingCallback={this.onDocumentUploading}
                    onFileUploadedCallback={this.onDocumentUploaded}
                  />
                </span>
              </div>
            )}
            {this.state.fileName.length !== 0 && <div />}
          </div>
          <div className="col-12 col-sm-8" />
        </div>
      </section>
    );
  }
}

export default LogoUploader;
