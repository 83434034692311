/* eslint-disable no-restricted-properties */
/* eslint-disable no-undef */
import "./Styles/General.css";
import "./Styles/Sections.css";
import "./Styles/Containers.css";
import "./Styles/Controls.css";
import "./Styles/LoadingIndicator.css";
import "./Styles/Overrides.css";
import "./Styles/isuppli-table.css";
import {
  Header,
  FeatureRoute,
  Spinner,
  Footer,
} from "isuppli-react-components";

import React, { Component, Fragment } from "react";
import { withRouter, Redirect, Switch } from "react-router-dom";
import { Provider } from "react-redux";

import NotificationMenuItem from "./Containers/Header/NotificationMenuItem";
import navMenuItems from "./Util/menuItems";

import RegistrationView from "./Views/RegistrationView";
import MyOfficeView from "./Views/MyOfficeView";
import MarketPlaceView from "./Views/MarketPlaceView";
import MyCompanyView from "./Views/MyCompanyView";
import DashboardView from "./Views/DashboardView";

import OnboardingInvitationCheck from "./Containers/OnboardingInvitationCheck";
import SubscriptionCheck from "./Containers/SubscriptionCheck/SubscriptionCheck";
import ScrollToTop from "./Containers/ScrollToTop";
import store from "./Store";
import AppContent from "./AppContent";

import {
  setUserBasicDetails,
  setCompanyConfiguration,
  setCompanyOutstandingInfo,
  setTokenDates,
  setDataFeatures,
  setSubscriptionAllowedFeatures,
} from "./Store/actions";

import {
  getUserBasicDetails,
  getTermsAndConditionsAsync,
  getTokenExpiry,
  getCompanyDataFeatures,
  getCompanyConfiguration,
  getAllSubscriptionsAllowedFeatures,
} from "./http/index";

import {
  getCompanyOutstandingInformation,
} from "./http/MyCompany/myCompanyAPI";

import { checkHttpStatus } from "./http/httpHelpers";
import UserView from "./Views/UserView";

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      registrationDone: false,
    };
  }

  async componentDidMount() {
    try {
      if (!this.state.loading) {
        this.setState({ loading: true });
      }

      const expiresAt = await getTokenExpiry({ bypassAuthCheck: true });

      const companyDataFeatures = await getCompanyDataFeatures();
      store.dispatch(setDataFeatures(companyDataFeatures.dataFeatures));

      const subscriptionAllowedFeatures = await getAllSubscriptionsAllowedFeatures();
      store.dispatch(setSubscriptionAllowedFeatures(subscriptionAllowedFeatures));

      // This API call will fail if it's a new registration without
      // a company being associated with the user
      const userDetails = await getUserBasicDetails();
      // 1. Allowed features must be a BigInt
      // 2. JavaScript fails to convert with greater than 53 bits with enough precision. see: https://shorturl.at/hpD58
      userDetails.allowedFeatures = BigInt(userDetails.allowedFeaturesString);
      store.dispatch(setUserBasicDetails(userDetails));
      store.dispatch(setTokenDates(new Date(), new Date(expiresAt)));

      const companyConfiguration = await getCompanyConfiguration();
      store.dispatch(setCompanyConfiguration(companyConfiguration));

      if (!userDetails.accountSuspended) {
        const companyOutstandingInfo = await getCompanyOutstandingInformation();
        store.dispatch(setCompanyOutstandingInfo(companyOutstandingInfo));
      }

      const termsAndConditions = await getTermsAndConditionsAsync(
        userDetails.companyId
      );

      if (termsAndConditions.agreeTC) {
        // Registration is done, keep current route
        this.setState({ registrationDone: true });
        if (userDetails.accountSuspended) {
          // Redirect to edit subscription
          this.props.history.replace("/mycompany/subscription/edit");
        }
      } else {
        // Redirect to registration
        this.props.history.replace("/registration/supinfo");
      }
    } catch (err) {
      if (checkHttpStatus(err, 404)) {
        // the user does not have a company, therefore he has not registered
        this.props.history.replace("/registration/supinfo");
        this.setState({ loading: false });
      }
      if (checkHttpStatus(err, 401)) {
        // user is not authenticated, redirect to login
        window.location = `/account/login?returnUrl=${encodeURIComponent(window.location.href)}`;
      }
    }
    if (this.state.loading) {
      this.setState({ loading: false });
    }
  }

  render() {
    const state = store.getState();
    const content = this.state.registrationDone ? (
      <Fragment>
        <SubscriptionCheck />
        <OnboardingInvitationCheck />
        <Header
          userGreeting={state.loggedInUserDetails.userGreeting}
          companyName={state.loggedInUserDetails.companyName}
          userBadge={state.loggedInUserDetails.subscriptionName}
          isAuthenticated={this.state.registrationDone}
          allowedFeatures={state.loggedInUserDetails.allowedFeatures}
          menuItems={navMenuItems}
          onLogout={() => { window.location.href = "/account/logout"; }}
          onHeaderLogoClick={() => this.props.history.push("/dashboard")}
          helpLink="/redirect/helpandsupport"
        >
          <NotificationMenuItem />
        </Header>
        <div className="isuppli-content-body position-relative flex-fill">
          <Switch>
            {/* Removes trailing slashes from urls */}
            <FeatureRoute
              path="/:url*(/+)"
              exact
              strict
              render={({ location }) => (
                <Redirect to={location.pathname.replace(/\/+$/, "")} />
              )}
            />
            <FeatureRoute path="/dashboard" component={DashboardView} />
            <FeatureRoute path="/marketplace" component={MarketPlaceView} />
            <FeatureRoute path="/mycompany" component={MyCompanyView} />
            <FeatureRoute path="/myoffice" component={MyOfficeView} />
            <FeatureRoute path="/user" component={UserView} />
            <Redirect to="/dashboard" />
          </Switch>
        </div>
      </Fragment>
    ) : (
      <Fragment>
        <Header
          userGreeting={state.loggedInUserDetails.userGreeting ?? "System Administrator"}
          companyName={state.loggedInUserDetails.companyName ?? "New company"}
          userBadge={state.loggedInUserDetails.subscriptionName ?? "PENDING..."}
          isAuthenticated
          allowedFeatures={state.loggedInUserDetails.allowedFeatures}
          menuItems={navMenuItems.filter((x) => x.isUserMenu === true)}
          onLogout={() => { window.location.href = "/account/logout"; }}
          onHeaderLogoClick={() => this.props.history.push("/registration/supinfo")}
          helpLink="/redirect/helpandsupport"
        />
        <FeatureRoute path="/registration" component={RegistrationView} />
        <FeatureRoute path="/user" component={UserView} />
      </Fragment>
    );

    return (
      <Provider store={store}>
        <AppContent>
          <ScrollToTop>
            {this.state.loading ? (
              <div>
                <Spinner />
              </div>
            ) : (
              content
            )}
            <Footer />
          </ScrollToTop>
        </AppContent>
      </Provider>
    );
  }
}

export default withRouter(App);
