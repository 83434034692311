import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  PageHeading, SystemFeatures, hasFeature, ModalPopup, withToast,
  UtilButton, Line,
} from "isuppli-react-components";
import NavTabs from "./NavTabs";
import Loading from "../Loading";

import navMenuItems from "../../Util/menuItems";

import BeeStatus from "../../Containers/Supplier/BeeStatus";
import BeeCertification from "../../Containers/Supplier/BeeCertification";
import DocumentUploader from "../../Containers/Supplier/DocumentUploader";
import BlackOwnership from "../../Containers/Supplier/BlackOwnership";
import SectorTurnoverInfo from "../../Containers/Supplier/SectorTurnoverInfo";

import InfoPopup from "../../Containers/InfoPopup";

import {
  showLoadingScreen,
  hideLoadingScreen,
  showInfoPopup,
  hideInfoPopup,
  showErrorDialog,
} from "../../Store/actions";
import { setOption } from "../../Store/optionActions";
import {
  setRegistrationObject,
  onRegistrationInputChange,
} from "../../Store/registrationActions";

import { loadBeePracticeOptions } from "../../Util/optionsLoader";
import { loadBeePracticeData, loadDocuments } from "../../Util/dataLoader";
import { saveBeeCertificationInfo, saveDocumentsInfo } from "../../http/posts";
import { addDays, isBefore } from "../../Util/dateArith";

import BeeValidationStatus from "../../Util/Enumerators/beeValidationStatus";
import DocumentClassification from "../../Util/Enumerators/DocumentClassification";
import BeeCertificateType from "../../Util/Enumerators/BeeCertificateType";

const mapStateToProps = (state) => ({
  data: {
    ...state.registration.beeInfo,
    documents: state.registration.documents,
    companyId: state.currentCompanyId,
  },
  documents: state.registration.documents,
  turnoverband: state.options.turnoverBands.find(
    (tob) => tob.id === state.registration.supplierInfo.turnoverBand
  ),
  beeCerificateData: {
    ...state.registration.beeInfo,
    companyId: state.currentCompanyId,
  },
  beeLvls: state.options.beeLvls,
  beeSectors: state.options.beeSectors,
  documentTypes: state.options.documentTypes,
  allowedFeatures: state.loggedInUserDetails.allowedFeatures,
  sectors: state.options.sectors,
  turnoverBands: state.options.turnoverBands,
  sectorTurnoverBands: state.options.sectorTurnoverBands,
  sectorTurnoverBandStatuses: state.options.sectorTurnoverBandStatuses,
});

const mapDispatchToProps = (dispatch) => ({
  onInputChange: (ev) => {
    dispatch(onRegistrationInputChange("beeInfo", ev));
  },
  setOption: (optionName, optionValue) => {
    dispatch(setOption(optionName, optionValue));
  },
  setBeeInfo: (beeInfo) => {
    dispatch(setRegistrationObject("beeInfo", beeInfo));
  },
  setDocuments: (documents) => {
    dispatch(setRegistrationObject("documents", documents));
  },
  showLoadingScreen: (msg) => {
    dispatch(showLoadingScreen(msg));
  },
  hideLoadingScreen: () => {
    dispatch(hideLoadingScreen());
  },
  showInfoMsg: (heading, subheading, msgs) => {
    dispatch(showInfoPopup(heading, subheading, msgs));
  },
  hideInfoMsg: () => {
    dispatch(hideInfoPopup());
  },
  showErrorDialog: () => {
    dispatch(showErrorDialog(true));
  },
});

class BeePractice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msgs: {},
      originalData: null,
      originalDocuments: null,
      showTurnoverModal: false,
      isVatExempted: true,
    };
  }

  filterDocumentsBasedOnBee = (documents) => {
    const sectorTurnoverBand = this.props.sectorTurnoverBands
      .find((entry) => entry.sectorId === this.props.data.sector
        && entry.turnoverBandId === this.props.data.turnoverBand);

    if (sectorTurnoverBand == null) {
      return [];
    }

    let certificateType;
    if (sectorTurnoverBand.beeCertificateType === BeeCertificateType.Affidavit) {
      certificateType = BeeCertificateType.Affidavit;
    } else if (sectorTurnoverBand.beeCertificateType === BeeCertificateType.Certificate) {
      certificateType = BeeCertificateType.Certificate;
    } else {
      certificateType = (this.props.data.boPercentage >= 51)
        ? BeeCertificateType.Affidavit
        : BeeCertificateType.Certificate;
    }

    return documents
      .filter((document) => {
        const documentClassification = this.props.documentTypes
          .find((d) => d.id === document.documentTypeId)
          ?.documentClassification;

        switch (certificateType) {
          case BeeCertificateType.Affidavit:
            if (documentClassification === DocumentClassification.BeeAffidavit) {
              return true;
            }
            return false;
          case BeeCertificateType.Certificate:
            if (documentClassification === DocumentClassification.BeeCertificate) {
              return true;
            }
            return false;
          default:
            return false;
        }
      });
  };

  updateDocuments = (documents) => {
    // add back any missing documents
    const consolidatedDocuments = this.props.documents
      .map((c) => documents
        .find((d) => d.documentTypeId === c.documentTypeId)
          ?? c);
    this.props.setDocuments(consolidatedDocuments);
  }

  performSave = () => {
    this.props.showLoadingScreen("saving data...");

    const documentsShown = this.filterDocumentsBasedOnBee(this.props.data.documents);
    const newData = {
      ...this.props.data,
      boPercentage: Number(this.props.data.boPercentage),
      bwoPercentage: Number(this.props.data.bwoPercentage),
      registrationStepCompleted: this.props.data.registrationStepCompleted,
      beeDocumentId: documentsShown[0]?.documentId ?? null,
    };

    saveBeeCertificationInfo(
      newData,
      (newValidationStatus) => {
        this.props.onInputChange({
          target: {
            name: "validationStatus",
            value: newValidationStatus,
          },
        });
        const documentSummary = {
          companyId: this.props.data.companyId,
          companyDocuments: documentsShown,
          registrationStepCompleted: this.props.data.registrationStepCompleted,
        };
        saveDocumentsInfo(
          documentSummary,
          () => {
            this.props.hideLoadingScreen();
            this.props.hideInfoMsg();
            this.setState({
              originalData: newData,
              originalDocuments: this.props.documents,
            });
            this.props.showToast("B-BBEE information saved");
          },
          () => {
            this.props.hideLoadingScreen();
            this.props.showErrorDialog();
          }
        );
      },
      () => {
        this.props.hideLoadingScreen();
        this.props.showErrorDialog();
      }
    );
    this.setState({
      msgs: [],
    });
  }

  setTurnoverModal = (newValue) => {
    this.setState(() => ({
      showTurnoverModal: newValue,
    }));
  }

  saveAndContinueToStep = (step) => {
    this.performSave();
    this.props.history.push(step);
  }

  onClickSave = () => {
    const boPercentage = Number(this.props.data.boPercentage);
    const bwoPercentage = Number(this.props.data.bwoPercentage);
    const newDate = addDays(365, new Date());
    const isBOValid = boPercentage >= bwoPercentage;

    const isBeeStatusSelected = this.props.data.beeStatusId !== null
    && this.props.data.beeStatusId > 0;
    const expiryDate = new Date(this.props.data.certificateExpiryDate);
    const isCertificateValid = this.props.data.certificateExpiryDate
      && isBefore(expiryDate, newDate);

    const isSectorSelected = this.props.data.sector !== null && this.props.data.sector > 0;

    // only validate turnover if sector selected
    let isTurnoverSelected = true;
    if (isSectorSelected) {
      isTurnoverSelected = this.props.data.turnoverBand !== null
        && this.props.data.turnoverBand > 0;
    }

    // check if all documents were added
    let allDocumentsAdded = true;
    const documentsShown = this.filterDocumentsBasedOnBee(this.props.data.documents);
    for (let i = 0; i < documentsShown.length; i += 1) {
      if (!documentsShown[i].filename && !documentsShown[i].optional) {
        allDocumentsAdded = false;
      }
    }

    // check if new document is required
    const beeDataChanged = // eslint-disable-line operator-linebreak
      this.state.originalData.hasBeeCertificate !== this.props.data.hasBeeCertificate
      || this.state.originalData.boPercentage !== Number(this.props.data.boPercentage)
      || this.state.originalData.bwoPercentage !== Number(this.props.data.bwoPercentage)
      || this.state.originalData.beeStatusId !== this.props.data.beeStatusId
      || this.state.originalData.certificateExpiryDate !== this.props.data.certificateExpiryDate
      || this.state.originalData.sector !== this.props.data.sector
      || this.state.originalData.turnoverBand !== this.props.data.turnoverBand;

    const newDocumentRequired = beeDataChanged
    && this.props.data.validationStatus === BeeValidationStatus.Validated
    && this.filterDocumentsBasedOnBee(this.state.originalDocuments).some((originalDocument) => {
      const currentDocument = this.props.data.documents
        .find((c) => c.documentTypeId === originalDocument.documentTypeId);

      const documentHasChanged = currentDocument != null
        && originalDocument.filename !== currentDocument.filename;

      const documentClassification = this.props.documentTypes
        .find((d) => d.id === originalDocument.documentTypeId)
        ?.documentClassification;

      const isBeeDocument = // eslint-disable-line operator-linebreak
      documentClassification === DocumentClassification.BeeCertificate
      || documentClassification === DocumentClassification.BeeAffidavit;

      return isBeeDocument && !documentHasChanged && !originalDocument.optional;
    });

    if (
      (this.props.data.hasBeeCertificate === 0)
      || (
        isBOValid
        && isCertificateValid
        && isBeeStatusSelected
        && isSectorSelected
        && isTurnoverSelected
        && allDocumentsAdded
        && !newDocumentRequired
      )
    ) {
      // check hasVatNumber if selected turnover band is not vat exempted
      if (!this.props.data.hasVatNumber && !this.state.isVatExempted) {
        // show modal
        this.setTurnoverModal(true);
      } else {
        this.performSave();
      }
    } else {
      const boMsgs = !isBOValid
        ? {
          boPercentage:
              "Black ownership should be higher than black woman ownership",
          bwoPercentage:
              "Black woman ownership should be lower than black ownership",
        }
        : {};
      const certMsgs = !isCertificateValid
        ? {
          certificateExpiryDate: "Certificate expiry date is invalid",
        }
        : {};

      const beeStatusMsgs = !isBeeStatusSelected
        ? {
          beeStatusId: "Bee level is required",
        }
        : {};

      const sectorMsgs = !isSectorSelected
        ? {
          sector: "Sector is required",
        }
        : {};

      const turnoverMsgs = !isTurnoverSelected
        ? {
          turnoverBand: "Turnover band is required",
        }
        : {};

      let documentMessage = {};
      if (!allDocumentsAdded) {
        documentMessage = {
          beeDocuments: "Please ensure all required documents are uploaded",
        };
      } else if (newDocumentRequired) {
        documentMessage = {
          beeDocuments: "Upload new BEE documentation to support the changes made to your bee certification.",
        };
      }

      this.setState(
        {
          msgs: {
            ...boMsgs,
            ...certMsgs,
            ...beeStatusMsgs,
            ...sectorMsgs,
            ...turnoverMsgs,
            ...documentMessage,
          },
        },
        () => {
          this.props.showInfoMsg(
            "There are invalid fields on this form",
            "please correct them accordingly",
            Object.keys(this.state.msgs).map((key) => this.state.msgs[key])
          );
          window.scrollTo({ top: 0 });
        }
      );
    }
  };

  onHasBeeCertificateChangeHandler = (newVal) => {
    if (newVal === 0) {
      this.setState(() => ({
        isVatExempted: true,
      }));
    }
  }

  componentDidMount = async () => {
    loadBeePracticeOptions(this.props);
    const data = await Promise.all([
      loadDocuments(this.props),
      loadBeePracticeData(this.props),
    ]);
    const originalDocuments = data[0];
    const originalData = data[1];
    this.setState({
      originalData,
      originalDocuments,
    });
  }

  render() {
    const canEdit = hasFeature(
      this.props.allowedFeatures,
      SystemFeatures.CompanyProfileEdit
    );

    return (
      <section id="isuppli-supplier-info">
        <InfoPopup />

        <Loading />

        {
          this.state.showTurnoverModal
          && (
            <ModalPopup
              heading="B-BBEE Turnover Band Selection"
              subheading="You have selected a B-BBEE turnover band in excess of R1m,
                while also indicating in the Company Information section that you are NOT registered for VAT.
                Please return to the Company Information section to amend your VAT information."
              hasCancel
              onClose={
                () => {
                  this.setTurnoverModal(false);
                }
              }
              buttons={[
                {
                  key: "proceed",
                  label: "Save and return to company information",
                  onClick: () => { this.saveAndContinueToStep("/mycompany/profile/supplier"); },
                  color: "attention",
                }]}
            />
          )
        }

        <div className="padding-lr-10vw" style={{ paddingTop: "20px" }}>
          <PageHeading
            heading="Summary of your business information"
            alertText="It is important to keep your information up to date. Not updating your information may result in your business being removed from the portal."
            menuItems={navMenuItems}
          />
          <NavTabs />
        </div>

        <div>
          <div className="padding-lr-10vw" style={{ marginTop: "36px" }}>
            <BeeStatus
              hasBeeCertificate={this.props.data.hasBeeCertificate}
              validationStatus={this.props.data.validationStatus}
              hasBeeValidationMessage={this.state.msgs}
              onInputChange={this.props.onInputChange}
              onHasBeeCertificateChange={this.onHasBeeCertificateChangeHandler}
              readOnly={!canEdit}
              companyGroupingType={this.props.data.companyGroupingType}
            />
          </div>
        </div>

        {this.props.data.hasBeeCertificate === 1 && (
          <div>
            <div
              className="padding-lr-5vw"
              style={{ marginTop: "36px", marginBottom: "36px" }}
            >
              <hr />
            </div>
            <div className="padding-lr-10vw" style={{ marginTop: "36px" }}>
              <SectorTurnoverInfo
                sector={this.props.data.sector}
                turnoverBand={this.props.data.turnoverBand}
                readOnly={!canEdit}
                additional={this.state.msgs}
                onInputChange={this.props.onInputChange}
                onVatExemptedChange={(newValue) => this.setState({
                  isVatExempted: newValue,
                })}
              />
            </div>
            <div
              className="padding-lr-5vw"
              style={{ marginTop: "36px", marginBottom: "36px" }}
            >
              <hr />
            </div>
            <div className="padding-lr-10vw">
              <BlackOwnership
                readOnly={!canEdit}
                data={this.props.data}
                additional={this.state.msgs}
                onInputChangeCallback={this.props.onInputChange}
              />
            </div>
            <div className="padding-lr-5vw" style={{ marginTop: "36px" }}>
              <hr />
            </div>
            <div className="padding-lr-10vw">
              <BeeCertification
                readOnly={!canEdit}
                data={this.props.beeCerificateData}
                turnoverband={this.props.turnoverband}
                beeLvls={this.props.beeLvls ?? []}
                beeSectors={this.props.beeSectors}
                sectorTurnoverBands={this.props.sectorTurnoverBands}
                sectorTurnoverBandStatuses={this.props.sectorTurnoverBandStatuses}
                additional={this.state.msgs}
                onInputChangeCallback={this.props.onInputChange}
              />
            </div>
          </div>
        )}
        {this.props.data.hasBeeCertificate === 1 && (
          <Fragment>
            <div
              className="padding-lr-5vw"
              style={{ marginTop: "36px", marginBottom: "36px" }}
            >
              <hr />
            </div>

            <div className="padding-lr-10vw">
              <DocumentUploader
                header="UPLOAD BEE DOCUMENTS"
                readOnly={!canEdit}
                uploadUrl="api/documents/"
                downloadUrl="api/documents/"
                documentTypes={this.props.documentTypes}
                documents={this.filterDocumentsBasedOnBee(this.props.documents)}
                onDocumentsChanges={(newDocuments) => this.updateDocuments(newDocuments)}
              />
            </div>
          </Fragment>
        )}
        <div
          className="padding-lr-5vw"
          style={{ paddingBottom: "76px", marginTop: "60px" }}
        >
          <div className="justify-end row">
            <Line color="secondary" />
            {canEdit
            && (
              <UtilButton
                color="primary"
                location="bottom"
                onClick={this.onClickSave}
              >
                SAVE
              </UtilButton>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default withToast(withRouter(connect(mapStateToProps, mapDispatchToProps)(BeePractice)));
