const onboardingStatuses = {
  New: 0,

  invitationPending: 10,
  awaitingRegistration: 11,
  awaitingCompliance: 12,
  complianceFailure: 13,
  profileIncomplete: 14,

  pendingApproval1: 20,
  pendingApproval2: 21,
  PendingAddSupplierApproval: 22,

  declined: 30,
  suspended: 31,

  awaitingContractApprovals: 39,
  awaitingVendorNumber: 40,
  manualSupplierInvitationPending: 43,
  manualSupplierAwaitingRegistration: 44,
  networkVendorInvitationPending: 45,
  networkVendorAwaitingRegistration: 46,

  completed: 50,

  toString: (value) => {
    switch (value) {
      case onboardingStatuses.New:
        return "New";
      case onboardingStatuses.invitationPending:
      case onboardingStatuses.manualSupplierInvitationPending:
      case onboardingStatuses.networkVendorInvitationPending:
        return "Awaiting Supplier Invitation Acceptance";
      case onboardingStatuses.awaitingRegistration:
      case onboardingStatuses.manualSupplierAwaitingRegistration:
      case onboardingStatuses.networkVendorAwaitingRegistration:
        return "Awaiting Supplier Registration";
      case onboardingStatuses.awaitingCompliance:
        return "Awaiting Compliance Checks";
      case onboardingStatuses.complianceFailure:
        return "Compliance Failure";
      case onboardingStatuses.profileIncomplete:
        return "Profile Incomplete";
      case onboardingStatuses.pendingApproval1:
        return "Pending approval 1";
      case onboardingStatuses.pendingApproval2:
        return "Pending approval 2";
      case onboardingStatuses.PendingAddSupplierApproval:
        return "Pending approval";
      case onboardingStatuses.declined:
        return "Declined";
      case onboardingStatuses.suspended:
        return "Suspended";
      case onboardingStatuses.awaitingContractApprovals:
        return "Awaiting Contract Approval";
      case onboardingStatuses.awaitingVendorNumber:
        return "Awaiting Vendor Number";
      case onboardingStatuses.completed:
        return "Completed";
      default:
        return `Supplier status ${value}`;
    }
  },
};

export default onboardingStatuses;
