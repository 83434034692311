import React, { useState, useEffect, useCallback } from "react";

import {
  PageHeading, SearchInput,
  useRouteState, ConfirmationModal,
  SearchFiltersContainer, FilterTypes, FilterValue, // eslint-disable-line no-unused-vars
} from "isuppli-react-components";

import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import navMenuItems from "../../../Util/menuItems";
import UserStatus from "../../../Util/Enumerators/userStatus";

import UserSearchResults from "./UserSearchResults/UserSearchResults";
import AddUserModal from "./AddUserModal/AddUserModal";
// eslint-disable-next-line no-unused-vars
import {
  UserMessage, // eslint-disable-line no-unused-vars
  UsersMessage, // eslint-disable-line no-unused-vars
  getUsers,
  deactivateUser, activateUser, deleteUser,
} from "../../../http/UserManagement/userManagementApi";
import useShowError from "../../../Hooks/useShowError";
// eslint-disable-next-line no-unused-vars
import { ReduxState } from "../../../Store/ReduxState";
import { UserSearchCriteria } from "../../../http/UserManagement/Models/UserSearchCriteria"; // eslint-disable-line no-unused-vars
import { StaticCompanyDataTypes } from "../../../Util/Enumerators/staticCompanyDataTypes";
import useStaticCompanyData, { TypedStaticOption } from "../../../Hooks/useStaticCompanyData"; // eslint-disable-line no-unused-vars
import UserSortOption from "../../../Util/Enumerators/userSortOption";

const UserManagement = () => {
  const [[rolesTemp, businessUnitStaticDataTemp]] = useStaticCompanyData(
    [StaticCompanyDataTypes.roles, StaticCompanyDataTypes.businessUnit]
  );
  const businessUnitStaticData = businessUnitStaticDataTemp as Array<TypedStaticOption<number>>;
  const roles = rolesTemp as Array<TypedStaticOption<string>>;

  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showUserLimitModal, setShowUserLimitModal] = useState(false);
  const [showDeactivateUserModal, setShowDeactivateUserModal] = useState(false);
  const [showActivateUserModal, setShowActivateUserModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [userIdToAction, setUserIdToAction] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [routeSearchTerm, setRouteSearchTerm] = useRouteState("q", "");
  const [sortOption, setSortOption] = useRouteState(
    "s",
    UserSortOption.Name
  );
  const [filterOption, setFilterOption] = useRouteState(
    "f",
    UserStatus.None
  );
  const [routeFilterValues, setRouteFilterValues] = useRouteState<
    FilterValue[]
  >("fv", []);
  const [filterValues, setFilterValues] = useState<FilterValue[]>(
    routeFilterValues
  );
  const [page, setPage] = useRouteState("p", { l: 20, o: 0 });
  const [editUser, setEditUser] = useState<UserMessage>();
  const [userResults, setUserResults] = useState<UsersMessage>({
    items: [],
    totalItems: 0,
    totalActiveUsers: 0,
  });
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const showError = useShowError();

  const userLimit = useSelector((state: ReduxState) => state.loggedInUserDetails.userLimit);

  const addNewUserHandler = () => {
    setEditUser(undefined);

    // check user limit
    if (userLimit != null && userResults.totalActiveUsers >= userLimit) {
      setShowUserLimitModal(true);
      return;
    }
    setShowAddUserModal(true);
  };

  const onEditUserHandler = (user: UserMessage) => {
    setEditUser(user);
    setShowAddUserModal(true);
  };

  const onDeactivateUserClickHandler = (userId: string) => {
    setUserIdToAction(userId);
    setShowDeactivateUserModal(true);
  };

  const onActivateUserClickHandler = (userId: string) => {
    // check user limit
    if (userLimit != null && userResults.totalActiveUsers >= userLimit) {
      setShowUserLimitModal(true);
      return;
    }

    setUserIdToAction(userId);
    setShowActivateUserModal(true);
  };

  const onDeleteUserClickHandler = (userId: string) => {
    setUserIdToAction(userId);
    setShowDeleteUserModal(true);
  };

  const onDeactivateUserProceedHandler = async () => {
    setLoading(true);
    try {
      // API Call
      await deactivateUser(userIdToAction);

      // update user item in state
      setUserResults((prevState) => ({
        ...prevState,
        totalActiveUsers: prevState.totalActiveUsers - 1,
        items: prevState?.items.map((user) => {
          if (user.id === userIdToAction) {
            return { ...user, isDeactivated: true };
          }
          return user;
        }),
      }));
    } catch (error) {
      showError();
    }
    setLoading(false);
  };

  const onActivateUserProceedHandler = async () => {
    setLoading(true);
    try {
      // API Call
      await activateUser(userIdToAction);

      // update user item in state
      setUserResults((prevState) => ({
        ...prevState,
        totalActiveUsers: prevState.totalActiveUsers + 1,
        items: prevState?.items.map((user) => {
          if (user.id === userIdToAction) {
            return { ...user, isDeactivated: false };
          }
          return user;
        }),
      }));
    } catch (error) {
      showError();
    }
    setLoading(false);
  };

  const onDeleteUserProceedHandler = async () => {
    setLoading(true);
    try {
      // API Call
      await deleteUser(userIdToAction);

      // update user item in state

      setUserResults((prevState) => {
        const deletedUser = prevState?.items.find((user) => {
          if (user.id !== userIdToAction) {
            return true;
          }
          return false;
        });
        return {
          totalItems: (prevState.totalItems - 1),
          items: prevState?.items.filter((user) => {
            if (user.id !== userIdToAction) {
              return true;
            }
            return false;
          }),
          totalActiveUsers: prevState.totalActiveUsers - (deletedUser?.isDeactivated ? 0 : 1),
        };
      });
    } catch (error) {
      showError();
    }
    setLoading(false);
  };

  const executeSearch = useCallback(async () => {
    setLoading(true);
    try {
      const selectedRoleIds: any = routeFilterValues[0];
      const selectedBusinessUnitIds: any = routeFilterValues[1];

      const searchCriteria: UserSearchCriteria = {
        limit: page.l,
        offset: page.o,
        searchTerm: routeSearchTerm || undefined,
        statusFilter: filterOption,
        sortOption,
        selectedRoleIds,
        selectedBusinessUnitIds,
      };

      const data = await getUsers(searchCriteria);
      setUserResults(data);
    } catch (error) {
      showError();
    }
    setLoading(false);
  }, [page, showError, routeSearchTerm, routeFilterValues, filterOption, sortOption]);

  const clearSearch = () => {
    setRouteSearchTerm("");
    setSearchTerm("");
    setRouteFilterValues([]);
    setFilterValues([]);
    setFilterOption(UserStatus.None);
    setSortOption(UserSortOption.Name);
  };

  useEffect(() => {
    setSearchTerm(routeSearchTerm);
    setFilterValues(routeFilterValues);
    executeSearch();
  }, [routeSearchTerm, routeFilterValues, executeSearch, history.location]);

  const filters = [
    {
      filterName: "Role",
      options: roles,
      filterType: FilterTypes.MultiSelectDropDown,
    },
    {
      filterName: "Business Unit",
      options: businessUnitStaticData,
      filterType: FilterTypes.MultiSelectDropDown,
    },
  ];

  return (
    <div className="container-fluid">
      {showAddUserModal && (
        <AddUserModal
          user={editUser}
          onRefresh={() => {
            executeSearch();
          }}
          onClose={() => {
            setShowAddUserModal(false);
            setEditUser(undefined);
          }}
        />
      )}
      {showDeactivateUserModal && (
        <ConfirmationModal
          heading="Deactivate User"
          description="You are about to deactivate this account. The user will not be allowed to log in and use the system while the account is deactivated."
          hasCancel
          proceedButtonText="Deactivate"
          onProceedClick={() => { onDeactivateUserProceedHandler(); }}
          onToggleModal={() => setShowDeactivateUserModal(false)}
        />
      )}
      {showActivateUserModal && (
        <ConfirmationModal
          heading="Activate User"
          description="You are about to activate this account. The user will be allowed to log in and use the system."
          hasCancel
          proceedButtonText="Activate"
          onProceedClick={() => { onActivateUserProceedHandler(); }}
          onToggleModal={() => setShowActivateUserModal(false)}
        />
      )}
      {showDeleteUserModal && (
        <ConfirmationModal
          heading="Delete User"
          description="You are about to delete this account from the system. This process cannot be reversed."
          hasCancel
          proceedButtonText="Delete"
          onProceedClick={() => { onDeleteUserProceedHandler(); }}
          onToggleModal={() => setShowDeleteUserModal(false)}
        />
      )}
      {showUserLimitModal && (
        <ConfirmationModal
          heading="User Limit Reached"
          description="You have reached the maximum number of users for your current subscription package. Review your subscription for more details."
          hasCancel
          proceedButtonText="View Subscription"
          onProceedClick={() => { history.push("/mycompany/subscription"); }}
          onToggleModal={() => setShowUserLimitModal(false)}
        />
      )}
      <div className="row">
        <div className="col-10 offset-1">

          <PageHeading
            heading="Manage Users"
            alertText="Manage your company users"
            menuItems={navMenuItems}
          />
          <SearchInput
            searchTerm={searchTerm}
            onSearch={() => {
              setPage({ l: page.l, o: 0 });
              setRouteSearchTerm(searchTerm);
              setRouteFilterValues(filterValues);
            }}
            onChange={(value: string) => { setSearchTerm(value); }}
            searchPlaceholder="Search by username"
            buttons={[{
              key: "add", label: "Add a new user", onClick: addNewUserHandler, color: "primary",
            }]}
            className="mb-4"
          />

          <SearchFiltersContainer
            buttons={[
              {
                key: "clear",
                label: "Clear",
                onClick: clearSearch,
                color: "secondary",
              },
              {
                key: "search",
                label: "Search",
                onClick: () => {
                  setPage({ l: page.l, o: 0 });
                  setRouteSearchTerm(searchTerm);
                  setRouteFilterValues(filterValues);
                },
                color: "primary",
              },
            ]}
            className="mb-4"
            onChange={setFilterValues}
            filterValues={filterValues ?? []}
            filters={filters}
          />

          <UserSearchResults
            searchResults={userResults}
            loading={loading}
            limit={page.l}
            offset={page.o}
            onPageChange={(l, o) => setPage({ l, o })}
            onItemSelected={onEditUserHandler}
            onDeactivate={(userId: string) => { onDeactivateUserClickHandler(userId); }}
            onActivate={(userId: string) => { onActivateUserClickHandler(userId); }}
            onDelete={(userId: string) => { onDeleteUserClickHandler(userId); }}
            sortOption={sortOption}
            setSortOption={setSortOption}
            filterOption={filterOption}
            setFilterOption={setFilterOption}
          />
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
