enum StaticDataTypes {
  BeeClassification = 1, // eslint-disable-line no-unused-vars
  BeeStatus = 2, // eslint-disable-line no-unused-vars
  CompanyType = 3, // eslint-disable-line no-unused-vars
  Country = 4, // eslint-disable-line no-unused-vars
  Province = 5, // eslint-disable-line no-unused-vars
  Sector = 6, // eslint-disable-line no-unused-vars
  TurnoverBand = 7, // eslint-disable-line no-unused-vars
  SectorTurnoverBand = 8, // eslint-disable-line no-unused-vars
  Bank = 9, // eslint-disable-line no-unused-vars
  BankAccountType = 10, // eslint-disable-line no-unused-vars
  SubscriptionTier = 11, // eslint-disable-line no-unused-vars
  UnspscSegment = 12, // eslint-disable-line no-unused-vars
  UnspscFamily = 13, // eslint-disable-line no-unused-vars
  DocumentTypes = 14, // eslint-disable-line no-unused-vars
  BusinessTypes = 15, // eslint-disable-line no-unused-vars
  ContactPerson = 16, // eslint-disable-line no-unused-vars
  SectorTurnoverBandStatus = 17, // eslint-disable-line no-unused-vars
  PaymentTerms = 18 // eslint-disable-line no-unused-vars
}

export default StaticDataTypes;
