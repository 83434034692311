/* eslint-disable no-bitwise */
import { ConfirmationModal, Spinner, DataFeatures } from "isuppli-react-components";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import useShowError from "../../../Hooks/useShowError";
import {
  // eslint-disable-next-line import/named
  getContractAgreements,
  submitOnboardingRequest,
} from "../../../http";

import MTPProgress from "../../../Sections/Marketplace/MTPProgress";
import { setOnboardingRequestObject } from "../../../Store/onboardingRequestActions";
// eslint-disable-next-line no-unused-vars
import { ReduxState } from "../../../Store/ReduxState";
import CompanyConfigurationFeatures from "../../../Util/Enumerators/CompanyConfigurationFeatures";
import onboardingStatuses from "../../../Util/Enumerators/onboardingStatuses";

import WizzardBtns from "../../WizzardBtns";
import useStaticCompanyData from "../../../Hooks/useStaticCompanyData";
import { StaticCompanyDataTypes } from "../../../Util/Enumerators/staticCompanyDataTypes";

const OnboardingWizard = ({
  children,
  currentStep,
  lastStepCompleted,
  isManualSupplier,
  onboardingId,
  onClickSaveAndContinue,
  onClickNext,
}: {
  children: React.ReactNode,
  currentStep: number,
  lastStepCompleted: number,
  isManualSupplier: boolean,
  onboardingId: number,
  onClickSaveAndContinue: () => void,
  onClickNext: (nextUrl: string) => Promise<void>,
}) => {
  const history = useHistory();
  const allowedCompanyFeatures = useSelector(
    (state: ReduxState) => (state.companyConfiguration.allowedCompanyFeatures)
  );
  const dispatch = useDispatch();
  const showError = useShowError();
  const [loading, setLoading] = useState(false);
  const [showMTOCompletedModal, setShowMTOCompletedModal] = useState(false);
  const [showManualSuppierModal, setShowManualSupplierModal] = useState(false);
  const onboardingData = useSelector((state: ReduxState) => state.onboardingRequest);

  const [[supplierTypes], isReady] = useStaticCompanyData(
    [StaticCompanyDataTypes.supplierType]
  );
  const supplierTypeDataFeatures: DataFeatures = isReady
    ? supplierTypes
      .find((supplierType) => supplierType.id === onboardingData?.supplierTypeId)?.dataFeatures
    : DataFeatures.None;

  const steps = [
    "COMPANY DETAILS",
  ];

  const hasBankStep = (isReady && (supplierTypeDataFeatures & DataFeatures.BankingDetails));

  if (isManualSupplier) {
    steps.push("ADDRESS & CONTACT DETAILS");
    steps.push("B-BBEE PRACTICES & CERTIFICATION");
    if (hasBankStep) {
      steps.push("BANK DETAILS");
    }
    steps.push("SUPPORTING DOCUMENTS & AGREEMENTS");
  }

  if ((allowedCompanyFeatures & CompanyConfigurationFeatures.MTOProductAndSegments) > 0) {
    steps.push("PRODUCT SEGMENTS & FAMILIES");
  } else
  if ((allowedCompanyFeatures & CompanyConfigurationFeatures.MTOCompanyProductsAndFamilies) > 0) {
    steps.push("COMPANY PRODUCTS & FAMILIES");
  }

  if ((allowedCompanyFeatures & CompanyConfigurationFeatures.MTORestrictions) > 0) {
    steps.push("RESTRICTIONS");
  }
  if ((allowedCompanyFeatures & CompanyConfigurationFeatures.MTOMotivation) > 0) {
    steps.push("MOTIVATION TO ONBOARD");
  }
  const urlForStep = (stepIndex: number) => {
    const baseUrl = `/myoffice/onboardingrequest/${onboardingId}/${isManualSupplier ? "m-" : ""}`;
    return `${baseUrl}${stepIndex + 1}`;
  };

  const backUrl = currentStep === 0 ? null
    : urlForStep(currentStep - 1);

  const nextUrl = currentStep === steps.length - 1 ? ""
    : urlForStep(currentStep + 1);

  const isFinalStep = currentStep === steps.length - 1;
  const nextText = isFinalStep
    ? "SUBMIT MOTIVATION TO ONBOARD AND COMPLETE"
    : steps[currentStep + 1];

  const performSubmitOnboardingRequest = async () => {
    try {
      setLoading(true);
      if (!onboardingData.manualSupplier) {
        if (onboardingData.contractingTierId > 0) {
          const contractAgreements = await getContractAgreements(onboardingData.contractingTierId);
          onboardingData.contractTierAgreements = contractAgreements.map((c) => ({
            id: c.id,
            agreementName: c.agreementName,
          }));
        }
      }
      const submittedOnboardingRequest = await submitOnboardingRequest(onboardingData);
      setShowMTOCompletedModal(true);
      dispatch(setOnboardingRequestObject(submittedOnboardingRequest));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError();
    }
  };

  const confirmationModalDescription = onboardingData?.onboardingStatus === onboardingStatuses.invitationPending
    ? "Thank you for completing the Motivation to Onboard."
    + "An invitation email has been sent to the company to complete the registration process."
    : "Thank you for completing the Motivation to Onboard.";

  return (
    <Fragment>
      {loading && <Spinner />}
      { showManualSuppierModal && (
        <ConfirmationModal
          heading="Manual supplier registration"
          onProceedClick={performSubmitOnboardingRequest}
          onToggleModal={() => { setShowManualSupplierModal(false); }}
          proceedButtonText="OK"
          description={`You are about to create a 'Manual Supplier' record.
                            This company record will be visible only to your company users
                            in the Directory, and the company will not have access to maintain
                            their profile. The record will also bypass any contracting workflows
                            which may apply. Do you wish to Proceed?`}
        />
      )}
      {
        showMTOCompletedModal && (
          <ConfirmationModal
            hasCancel={false}
            size="large"
            image="laptop"
            heading="MOTIVATION TO ONBOARD COMPLETED!"
            description={confirmationModalDescription}
            proceedButtonText="GO TO ONBOARDING REQUESTS"
            onToggleModal={() => setShowMTOCompletedModal(false)}
            onProceedClick={() => history.push("/myoffice/onboardingrequest")}
          />
        )
      }
      <MTPProgress
        steps={steps}
        currentStep={currentStep}
        lastStepCompleted={lastStepCompleted}
        onClick={(stepIndex: number) => {
          history.push(urlForStep(stepIndex));
        }}
      />
      {children}
      <WizzardBtns
        history={history}
        backUrl={backUrl}
        nextUrl={nextUrl}
        nextText={nextText}
        onClickSaveAndContinue={onClickSaveAndContinue}
        onClickNext={async (url: string) => {
          if (isFinalStep) {
            await onClickNext("");
            onboardingData.manualSupplier === true ? setShowManualSupplierModal(true)
              : performSubmitOnboardingRequest();
          } else {
            onClickNext(url);
          }
        }}
      />
    </Fragment>
  );
};

export default OnboardingWizard;
