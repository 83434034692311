import axios from "axios";

import {
  CloseAccountFeedbackMessage, // eslint-disable-line no-unused-vars,
} from "./Models/Models";

import { makeRequestAsync } from "../util";

export async function convertToNonDirectoryListing() {
  return makeRequestAsync(() => axios.post("/api/subscription/closeaccount/nondirectorylisting"));
}

export async function unsubscribeEntirely() {
  return makeRequestAsync(() => axios.post("/api/subscription/closeaccount/unsubscribe"));
}

export async function saveCloseAccountFeedback(
  closeAccountFeedbackMessage: CloseAccountFeedbackMessage
) {
  return makeRequestAsync(() => axios.post("/api/subscription/closeaccount/feedback", closeAccountFeedbackMessage));
}
