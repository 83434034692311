enum ComplianceCheckType {
  None = 0, // eslint-disable-line no-unused-vars
  CIPC = 1, // eslint-disable-line no-unused-vars
  PropertyInformation = 2, // eslint-disable-line no-unused-vars
  NonPreferredListing = 3, // eslint-disable-line no-unused-vars
  SARS = 4, // eslint-disable-line no-unused-vars
  Judgements = 5, // eslint-disable-line no-unused-vars
  SAFPS = 6, // eslint-disable-line no-unused-vars
  LexisDiligence = 7, // eslint-disable-line no-unused-vars
  CreditInformation = 8, // eslint-disable-line no-unused-vars
  BankVerification = 9, // eslint-disable-line no-unused-vars
  Persal = 11, // eslint-disable-line no-unused-vars
}

export const complianceCheckTypeToString = (value: ComplianceCheckType) => {
  switch (value) {
    case ComplianceCheckType.None:
      return "None";
    case ComplianceCheckType.CIPC:
      return "CIPC";
    case ComplianceCheckType.PropertyInformation:
      return "Property Information";
    case ComplianceCheckType.NonPreferredListing:
      return "Non-Preferred Listing";
    case ComplianceCheckType.SARS:
      return "SARS";
    case ComplianceCheckType.Judgements:
      return "Judgments";
    case ComplianceCheckType.SAFPS:
      return "SAFPS";
    case ComplianceCheckType.LexisDiligence:
      return "Lexis Diligence";
    case ComplianceCheckType.CreditInformation:
      return "Credit Information";
    case ComplianceCheckType.BankVerification:
      return "Bank Verification";
    case ComplianceCheckType.Persal:
      return "Persal";
    default:
      return "Unknown";
  }
};

export default ComplianceCheckType;
