import React, { useState, Fragment } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Input,
  DropdownItem,
} from "reactstrap";
import DropDownButton from "../DropDownButton/DropDownButton";

import Styles from "./ActionsDropdown.module.scss";

const ActionsDropdown = ({
  disabled = false,
  className = "",
  buttonClassName = "",
  label,
  iconSvg,
  styleVal,
  badge,
  children,
  scrollingMenu,
  searchQuery,
  onSearchQueryChange,
}: {
  disabled?: boolean,
  className?: string,
  buttonClassName?: string,
  label: string,
  iconSvg?: SvgrComponent,
  styleVal?: "dark" | "light" | "default",
  badge?: string,
  children: React.ReactNode
  scrollingMenu?: boolean,
  searchQuery?: string,
  onSearchQueryChange? : (searchQuery: string) => void
}) => {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <Dropdown
      direction="down"
      disabled={disabled}
      isOpen={showOptions}
      toggle={() => setShowOptions((value) => !value)}
      className={`${Styles.ActionsDropdown} ${className}`}
    >
      <DropdownToggle tag="div">
        <div className={Styles.DropDownButton}>
          <DropDownButton
            disabled={disabled}
            label={label}
            iconSvg={iconSvg}
            styleVal={styleVal}
            up={showOptions}
            badge={badge}
            className={buttonClassName}
          />
        </div>
      </DropdownToggle>
      <DropdownMenu className={scrollingMenu ? Styles.ScrollingMenu : ""}>
        {onSearchQueryChange
          && (
            <Fragment>
              <Input
                autoFocus
                type="search"
                placeholder="Search for an item"
                className={`${Styles.SearchInput} border-0 rounded-0`}
                value={searchQuery}
                onChange={(e) => {
                  onSearchQueryChange(e.target.value);
                }}
              />
              <DropdownItem divider />
            </Fragment>
          )}

        {children}
      </DropdownMenu>
    </Dropdown>
  );
};

export default ActionsDropdown;
