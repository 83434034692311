import axios, {
  // eslint-disable-next-line no-unused-vars
  AxiosRequestConfig,
} from "axios";
import { makeRequestAsync } from "../util";

import {
  AddAttachmentMessage, // eslint-disable-line no-unused-vars
  BroadcastMessage, // eslint-disable-line no-unused-vars
  Message, // eslint-disable-line no-unused-vars
  ChannelInfoMessages, // eslint-disable-line no-unused-vars
  ChannelMessage, // eslint-disable-line no-unused-vars
  DraftMessage, // eslint-disable-line no-unused-vars
  SearchMessage, // eslint-disable-line no-unused-vars
  PageTypes, // eslint-disable-line no-unused-vars
} from "./Models/Models";

export * from "./Models/Models";

export async function uploadAttachments(
  channelId : number,
  messageId : number,
  files: FormData,
  config : AxiosRequestConfig | undefined
) {
  const uploadUrl = `/api/messaging/channels/${channelId}/messages/${messageId}/attachments`;
  return makeRequestAsync(() => axios
    .post(uploadUrl, files, config)) as Promise<AddAttachmentMessage>;
}

export async function broadcastMessage(
  channelId : number,
  messageId : number,
  message: BroadcastMessage
) {
  const uploadUrl = `/api/messaging/channels/${channelId}/messages/${messageId}/broadcast`;
  return makeRequestAsync(() => axios
    .post(uploadUrl, message)) as Promise<void>;
}

export async function getChannelList(
  {
    limit = 20,
    pageId,
    pageType,
    nameFilter,
    config,
    unreadFilter,
  }:{
    limit:number,
    pageId?:number
    pageType?: PageTypes
    nameFilter?: string
    config?: AxiosRequestConfig | undefined
    unreadFilter?:boolean
  }
) {
  const pageIdQuery = pageId != null ? `&pageId=${pageId}` : "";
  const pageTypeQuery = pageType != null ? `&pageType=${pageType}` : "";
  const nameFilterQuery = nameFilter != null ? `&nameFilter=${nameFilter}` : "";
  const unreadFilterQuery = unreadFilter ? `&unreadFilter=${unreadFilter}` : "";
  const getChannelListUrl = `/api/messaging/Channels?limit=${limit}${pageIdQuery}${pageTypeQuery}
                            ${nameFilterQuery}${unreadFilterQuery}`;
  return makeRequestAsync(
    () => axios.get(getChannelListUrl, config)
  ) as Promise<Array<ChannelMessage>>;
}

export async function getChannelMessages(
  {
    channelId,
    pageId,
    pageType,
    limit = 20,
    config,
  }:{
    channelId:number,
    pageId?:number,
    pageType?: PageTypes,
    limit?:number
    config?: AxiosRequestConfig,
  }
) {
  // query string parameters
  const qsAfter = pageId != null ? `&pageId=${pageId}` : "";
  const qsPageType = pageType != null ? `&pageType=${pageType}` : "";
  const getChannelMessagesUrl = `/api/messaging/Channels/${channelId}?limit=${limit}${qsAfter}${qsPageType}`;
  return makeRequestAsync(() => axios.get(
    getChannelMessagesUrl,
    config
  )) as Promise<ChannelInfoMessages>;
}

export async function searchMessages(
  search: string,
  limit: number,
  config: AxiosRequestConfig,
  after?: number,
  unreadFilter?: boolean
) {
  const afterQueryString = after == null ? "" : `&after=${after}`;
  const filterQueryString = unreadFilter ? `&unreadFilter=${unreadFilter}` : "";
  const searchUrl = `/api/messaging/messages/search?search=${search}&limit=${limit}${afterQueryString}${filterQueryString}`;
  return makeRequestAsync(() => axios.get(searchUrl, config)) as Promise<SearchMessage>;
}

export async function createDraftMessage(
  channelId: number,
  config?: AxiosRequestConfig
) {
  const createDraftUrl = `/api/messaging/Channels/${channelId}`;
  return makeRequestAsync(() => axios
    .post(createDraftUrl, {}, config)) as Promise<DraftMessage>;
}

export async function updateDraftMessage(
  channelId: number,
  messageId: number,
  content : string
) {
  const updateDraftUrl = `/api/messaging/Channels/${channelId}/messages/${messageId}`;
  return makeRequestAsync(() => axios.put(updateDraftUrl, { content }));
}

export async function deleteMessageAttachment(
  channelId:number,
  messageId:number,
  attachmentId:number
) {
  const deleteAttachmentUrl = `/api/messaging/channels/${channelId}/messages/${messageId}/attachments/${attachmentId}`;
  return makeRequestAsync(() => axios.delete(deleteAttachmentUrl));
}

export async function sendMessage(
  channelId: number,
  messageId: number
) {
  const sendMessageUrl = `/api/messaging/Channels/${channelId}/messages/${messageId}/send`;
  return makeRequestAsync(() => axios.put(sendMessageUrl)) as Promise<Message>;
}

export async function readMessage(
  channelId: number,
  messageId: number
) {
  const readMessageUrl = `/api/messaging/channels/${channelId}/messages/${messageId}/read`;
  return makeRequestAsync(() => axios.put(readMessageUrl)) as Promise <void>;
}
