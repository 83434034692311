// eslint-disable-next-line no-unused-vars
import { StaticDataTypes } from "../Util/Enumerators/staticDataTypes";
import useStaticData from "./useStaticData";

const StaticData = (
  { optionsToLoad }
    :
    {
      optionsToLoad: StaticDataTypes[],
    }
) => {
  useStaticData(optionsToLoad);
  return null;
};

export default StaticData;
