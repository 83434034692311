import { dateTimeDayMonthLabelFormat } from "isuppli-react-components";
import React from "react";
import ChatChannelCard from "../ChatChannelCard/ChatChannelCard";
// eslint-disable-next-line no-unused-vars
import { ChannelMessage } from "../../../http/Messaging/Models/Models";
import Styles from "./ChatChannelListSection.module.scss";
import { dateTimeLabelFormat } from "../../../Util/valueFormatter";

const ChatChannelListSection = (
  {
    messageCardList,
    selectedContent,
    sentMessageChannelId,
    scrollRef,
    onCardClickHandler,
    timestampFormat = "dd MMM",
  }
  :
  {
    messageCardList: ChannelMessage[],
    selectedContent?: {
      channelId: number,
      messageId?: number
    },
    sentMessageChannelId?:number,
    scrollRef?: React.RefObject<HTMLDivElement>,
    onCardClickHandler ?: (cardId:number) => void,
    timestampFormat ?: "dd MMM" | "dd MMM-hh:mm",
  }
) => {
  const cardClickHandler = (cardId:number) => {
    if (onCardClickHandler != null) {
      return onCardClickHandler(cardId);
    }
    return {};
  };
  const chatChannelList = messageCardList.map((card, index) => {
    const dateTimeString = timestampFormat === "dd MMM-hh:mm"
      ? dateTimeLabelFormat(card.updatedDate)
      : dateTimeDayMonthLabelFormat(card.updatedDate);
    const cardItem = (
      <ChatChannelCard
        active={card.id === selectedContent?.channelId}
        companyName={card.name}
        unreadMessageCount={card.unreadMessageCount}
        messageSnippet={card.lastMessage?.content ?? ""}
        onClickCallback={() => cardClickHandler(card.id)}
        messageDate={dateTimeString}
        className={Styles.ChatChannelCard}
      />
    );

    if (card.id === sentMessageChannelId) {
      return (
        <div key={index} ref={scrollRef}>
          {cardItem}
        </div>
      );
    }
    return (
      <div key={index}>
        {cardItem}
      </div>
    );
  });
  return chatChannelList as Array<React.ReactElement>;
};

export default ChatChannelListSection;
