import React from "react";
import { ReactComponent as BellSvg } from "./not-bell.svg";

function NotificationButton(props) {
  return (
    <div
      className="notification-button text-center clickable d-flex align-items-center justify-center"
      onClick={props.onClickCallback}
    >
      <BellSvg className="text-white" />
      {props.notifications > 0 && (
        <span className="badge badge-pill badge-danger">
          {props.notifications}
        </span>
      )}
    </div>
  );
}

export default NotificationButton;
