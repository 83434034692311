import axios from "axios";

import {
  SupplierContractInfoMessage, // eslint-disable-line no-unused-vars
} from "./Models/Models";

import { makeRequestAsync } from "../util";

export const getSupplierContractInfoMessage = async (supplierCompanyId: number) => {
  const url = `/api/motivation/suppliercontractinfo/${supplierCompanyId}`;
  const supplierContractInfoMessage = makeRequestAsync(
    () => axios.get(url)
  ) as Promise<SupplierContractInfoMessage>;
  return supplierContractInfoMessage;
};

export const x = "";
