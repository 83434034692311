/* eslint-disable no-unused-vars */
export enum ComplianceCheckType
{
  None = 0,
  CIPC = 1,
  SARS = 2,
}

export enum ComplianceCheckStatus
{
  None = 0,
  Valid = 1,
  Invalid = 2,
  Pending = 3,
  NA = 4,
}
/* eslint-enable no-unused-vars */

export const complianceCheckTypeToString = (value: ComplianceCheckType) => {
  switch (value) {
    case ComplianceCheckType.None:
      return "None";
    case ComplianceCheckType.CIPC:
      return "CIPC";
    case ComplianceCheckType.SARS:
      return "SARS";
    default:
      return "Unknown";
  }
};

export const complianceCheckStatusToString = (value: ComplianceCheckStatus) => {
  switch (value) {
    case ComplianceCheckStatus.None:
      return "None";
    case ComplianceCheckStatus.Valid:
      return "Valid";
    case ComplianceCheckStatus.Invalid:
      return "Invalid";
    case ComplianceCheckStatus.Pending:
      return "Pending";
    case ComplianceCheckStatus.NA:
      return "N/A";
    default:
      return "Unknown";
  }
};
