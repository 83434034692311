import axios from "axios";
import {
  ComplianceDetailedChecksMessage, // eslint-disable-line no-unused-vars
} from "./Models/Models";
import { makeRequestAsync } from "../util";

export async function getCompanyCompliance(
  companyId: number
) {
  const uploadUrl = `/api/compliance/query/getCompanyCompliance?companyId=${companyId}`;
  return makeRequestAsync(() => axios
    .get(uploadUrl)) as Promise<ComplianceDetailedChecksMessage>;
}

export async function getCurrentCompanyCompliance() {
  const uploadUrl = "/api/compliance/query/getCurrentCompanyCompliance";
  return makeRequestAsync(() => axios
    .get(uploadUrl)) as Promise<ComplianceDetailedChecksMessage>;
}

export const temp = 1;
