import React, { Fragment, useState, useEffect } from "react";
import {
  CheckboxInput, ModalPopup, Spinner, FilterInput,
} from "isuppli-react-components";

import { SupplierCategoryMessage } from "../../../http/SupplierCategory/Models/SupplierCategoryMessage";// eslint-disable-line no-unused-vars
import {
  getBuyerProductCategoriesOptions,
  saveClientSpecificProductCategories,
} from "../../../http/SupplierCategory/supplierCategoryAPI";

import { ClientSupplierCategoryMessage } from "../../../http/SupplierCategory/Models/ClientSupplierCategoryMessage";// eslint-disable-line no-unused-vars
import {
  ClientSpecificSupplierSubCategoriesMessage, // eslint-disable-line no-unused-vars
} from "../../../http/SupplierCategory/Models/ClientSpecificSupplierSubCategoriesMessage";
import useShowError from "../../../Hooks/useShowError";

const BuyerOfferingSelector = ({
  selection,
  setSelection,
  onCloseCallback,
  buyerCompanyId,
}: {
  selection: ClientSupplierCategoryMessage[];
  setSelection: (value: ClientSupplierCategoryMessage[], selectionIdList : number[]) => void;
  onCloseCallback: () => void;
  buyerCompanyId: number,
}) => {
  const [internalSelection, setInternalSelection] = useState<number[]>([]);
  const showError = useShowError();

  useEffect(() => {
    const selectionList: Array<number> = [];
    // eslint-disable-next-line array-callback-return
    selection.map((x) => {
      const result = x.subCategories.map((subCat) => subCat.id);
      selectionList.push(...result);
    });
    setInternalSelection(selectionList);
  }, [selection]);

  const [supplierCategories, setSupplierCategories] = useState<Array<SupplierCategoryMessage>>([]);
  const [isReady, setIsReady] = useState(false);
  const [filterTerm, setFilterTerm] = useState("");
  useEffect(() => {
    setIsReady(false);
    const loadData = async () => {
      try {
        const productCategories = await getBuyerProductCategoriesOptions(buyerCompanyId);
        setSupplierCategories(productCategories);
        setIsReady(true);
      } catch (error) {
        setIsReady(true);
        showError();
      }
    };

    loadData();
  }, [buyerCompanyId, showError]);
  const removeSupplierCategory = (item: number) => {
    setInternalSelection((prevState) => prevState.filter((sel) => sel !== item));
  };

  const addSupplierCategory = (item: number) => {
    setInternalSelection((prevState) => [...prevState, item]);
  };

  const toggleItem = (item: number, isSelected: boolean) => {
    if (isSelected) {
      removeSupplierCategory(item);
    } else {
      addSupplierCategory(item);
    }
  };

  const buildSelection = () => {
    const optionsToShow = supplierCategories.map((cat, i) => {
      const optionRows: JSX.Element[][] = [];
      let index = -1;
      cat.supplierSubCategories
        .filter((subCat: any) => (filterTerm.length === 0
          ? true
          : cat.name
            .toLocaleLowerCase()
            .includes(filterTerm.toLocaleLowerCase())
          || subCat.name
            .toLowerCase()
            .includes(filterTerm.toLocaleLowerCase())))
        .forEach((subCat: any, j: number) => {
          if (j % 3 === 0) {
            index += 1;
            optionRows.push([]);
          }
          const isSelected = !!internalSelection.find(
            (s) => s === subCat.id
          );
          optionRows[index].push(
            <div key={j} className="col-12 col-sm-4">
              <CheckboxInput
                value={isSelected}
                label={subCat.name}
                onChange={() => {
                  toggleItem(subCat.id, isSelected);
                }}
              />
            </div>
          );
        });
      const options = optionRows.map((row, k) => (
        <div key={k} className="row" style={{ paddingBottom: "17px" }}>
          {row}
        </div>
      ));
      return options.length > 0 ? (
        <section key={i}>
          <div
            className="bg-primary text-white text-center"
            style={{ borderRadius: "7px", marginBottom: "17px" }}
          >
            <h2 className="SelectionSummary">{cat.name}</h2>
          </div>
          <div>{options}</div>
        </section>
      ) : null;
    });
    return optionsToShow;
  };

  const onApplyClick = () => {
    const categorySelection = supplierCategories.map((x) => {
      const result = {
        id: x.id,
        name: x.name,
        subCategories: x.supplierSubCategories.filter((y) => internalSelection.includes(y.id)) ?? [],
      };
      if (result.subCategories.length > 0) { return (result); }
      return undefined;
    }).filter((res) => res !== undefined) as Array<ClientSupplierCategoryMessage>;
    setSelection(categorySelection, internalSelection);
    onCloseCallback();

    const saveData = async () => {
      const input: ClientSpecificSupplierSubCategoriesMessage = { buyerCompanyId, selectedSubCategoryIdList: internalSelection };
      try {
        await saveClientSpecificProductCategories(input);
      } catch (error) {
        showError();
      }
    };
    saveData();
  };

  const onFilter = (term: string) => {
    setFilterTerm(term);
  };

  const onFilterChange = (ev: React.FormEvent<HTMLInputElement>) => {
    setFilterTerm(ev.currentTarget.value);
  };

  return (
    <Fragment>
      <ModalPopup
        stickyButtons
        onClose={() => onCloseCallback()}
        hasCancel
        heading="SELECT PRODUCT / SERVICE CATEGORIES"
        subheading="Please indicate your primary product and/or service offering only.
            Keep in mind that buyers may be wary of a supplier who claims to do everything."
        size="large"
        buttons={[
          {
            key: "ok",
            label: "Save",
            onClick: () => {
              onApplyClick();
            },
            color: "primary",
          },
        ]}
      >
        <p className="mx-2">
          B1LINK is authorized to adopt The United Nations Standard Products and
          Services Code (UNSPSC).
        </p>
        <div className="row pt-3 px-2">
          <div className="col-12">
            <FilterInput
              onFilterCallback={onFilter}
              onChangeCallback={onFilterChange}
              placeholder="search for products or services"
            />
          </div>
        </div>
        <div className="row pt-3 px-2">
          <div className="col-12">
            {isReady ? buildSelection() : <Spinner inline />}
          </div>
        </div>
      </ModalPopup>
    </Fragment>
  );
};

export default BuyerOfferingSelector;
