/* eslint-disable no-unused-vars */
import axios from "axios";
import { EditShareholderMessage } from "./EditShareholderMessage";
import { makeRequestAsync } from "../util";
import { ShareholdersViewMessage } from "./ShareholdersViewMessage";

export const GetShareholders = async () => {
  const url = "api/shareholders";
  return await makeRequestAsync(() => axios
    .get(url)) as Promise<Array<EditShareholderMessage>>;
};

export const getSupplierTypeShareholdersConfigStatus = async (buyerCompanyId : number) => {
  const url = `api/shareholders/clientstab/${buyerCompanyId}`;
  return await makeRequestAsync(() => axios
    .get(url)) as Promise<boolean>;
};

export const saveShareholders = async (shareholders : Array<EditShareholderMessage>) => {
  const url = "api/shareholders";
  return await makeRequestAsync(() => axios.post(url, shareholders)) as Promise<void>;
};

export const deleteShareholder = async (shareholderId: number) => {
  const url = `api/shareholders/${shareholderId}`;
  return await makeRequestAsync(() => axios.delete(url)) as Promise<void>;
};

export const getSupplierShareholders = async (supplierCompanyId: number) => {
  const url = `api/shareholders/supplier/${supplierCompanyId}`;
  return await makeRequestAsync(() => axios
    .get(url)) as Promise<ShareholdersViewMessage>;
};
