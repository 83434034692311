import React, { Component } from "react";
import { connect } from "react-redux";

import {
  PageHeading,
  SystemFeatures,
  hasFeature,
  withToast,
  UtilButton,
  Line,
  GlobalOfferingSelector,
  GlobalProductsAndServicesList,
} from "isuppli-react-components";
import NavTabs from "./NavTabs";
import Loading from "../Loading";

import InfoPopup from "../../Containers/InfoPopup";
import navMenuItems from "../../Util/menuItems";
import OfferingSelectionSummary from "../../Containers/Supplier/OfferingSelectionSummary";

import validateStep from "../../Validation/onSubmitValidations";
import { requiredFieldsStep4 } from "../../Validation/validation";

import { loadProductServiceOptions } from "../../Util/optionsLoader";

import { setOption } from "../../Store/optionActions";
import {
  setRegistrationObject,
  onRegistrationInputChange,
  setSupplierCategories,
  setUnspscFamilies,
} from "../../Store/registrationActions";
import {
  hideLoadingScreen,
  showLoadingScreen,
  showInfoPopup,
  hideInfoPopup,
  showErrorDialog,
} from "../../Store/actions";
import { saveProductServiceOffering } from "../../http/posts";

import staticCompanyDataTypes from "../../Util/Enumerators/staticCompanyDataTypes";
import StaticCompanyData from "../../Hooks/StaticCompanyData";

import { SearchUsnpscFamilies } from "../../http/Search/searchApi";
import useStaticDataCustom from "../../Hooks/useStaticDataCustom";

const mapStateToProps = (state) => ({
  data: { ...state.registration.prdInfo, companyId: state.currentCompanyId },
  supplierCategories: state.staticCompanyData[staticCompanyDataTypes.supplierCategory] ?? [],
  allowedFeatures: state.loggedInUserDetails.allowedFeatures,
  invitationBuyerCompanyId: state.registration.supplierInfo.invitationBuyerCompanyId,
});

const mapDispatchToProps = (dispatch) => ({
  setOption: (optionName, optionValue) => {
    dispatch(setOption(optionName, optionValue));
  },
  setPrdInfo: (prdInfo) => {
    dispatch(setRegistrationObject("prdInfo", prdInfo));
  },
  onInputChange: (ev) => {
    dispatch(onRegistrationInputChange("prdInfo", ev));
  },
  showLoadingScreen: (msg) => {
    dispatch(showLoadingScreen(msg));
  },
  hideLoadingScreen: () => {
    dispatch(hideLoadingScreen());
  },
  showInfoMsg: (heading, subheading, msgs) => {
    dispatch(showInfoPopup(heading, subheading, msgs));
  },
  hideInfoMsg: () => {
    dispatch(hideInfoPopup());
  },
  setSelection: (newSelection) => {
    dispatch(setSupplierCategories(newSelection));
  },
  setGlobalSelection: (newSelection) => {
    dispatch(setUnspscFamilies(newSelection));
  },
  showErrorDialog: () => {
    dispatch(showErrorDialog(true));
  },
});

class ProductService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showGlobalProductsModal: false,
    };
    this.maxSelection = 3;
  }

  toggleGlobalProductsModal = () => {
    this.setState((prevState) => ({
      showGlobalProductsModal: !prevState.showGlobalProductsModal,
    }));
  };

  onClickSave = () => {
    const validation = validateStep(this.props.data, requiredFieldsStep4);

    if (validation.isValid) {
      this.props.showLoadingScreen("saving data...");

      const productServiceOffering = {
        tradeDescription: this.props.data.tradeDescription,
        selectedUnspscFamilyIds: this.props.data.selectedUnspscFamilyIds,
        registrationStepCompleted: this.props.data.registrationStepCompleted,
        notifyNewOpportunities: this.props.data.notifyNewOpportunities,
      };
      saveProductServiceOffering(
        productServiceOffering,
        () => {
          this.props.hideLoadingScreen();
          this.props.hideInfoMsg();
          this.props.showToast("Product and services saved");
        },
        () => {
          this.props.hideLoadingScreen();
          this.props.showErrorDialog();
        }
      );
    } else {
      this.props.showInfoMsg(
        "This page contains missing information.",
        "To continue please amend/complete the following:",
        Object.keys(validation.msgs).map((key) => validation.msgs[key])
      );
    }
  };

  componentDidMount() {
    loadProductServiceOptions(this.props);
  }

  render() {
    const canEdit = hasFeature(
      this.props.allowedFeatures,
      SystemFeatures.CompanyProfileEdit
    );

    return (
      <section id="isuppli-supplier-info" style={{ minHeight: "80vh" }}>

        {this.state.showGlobalProductsModal && (
          <GlobalOfferingSelector
            setSelection={this.props.setGlobalSelection}
            selection={this.props.data.selectedUnspscFamilyIds ?? []}
            onCloseCallback={this.toggleGlobalProductsModal}
            onSearchUsnpscFamiliesCallback={SearchUsnpscFamilies}
            staticDataCallback={useStaticDataCustom}
          />
        )}

        <StaticCompanyData optionsToLoad={[staticCompanyDataTypes.supplierCategory]} />

        <InfoPopup />

        <Loading />

        <div className="padding-lr-10vw" style={{ paddingTop: "20px" }}>
          <PageHeading
            heading="Summary of your business information"
            alertText="It is important to keep your information up to date. Not updating your information may result in your business being removed from the portal."
            menuItems={navMenuItems}
          />

          <NavTabs />

          <div style={{ paddingTop: "49px" }} />

          <div>
            <h2 className="Main">GLOBAL PRODUCT OFFERINGS</h2>
          </div>

          <div className="mt-3" />

          <div>
            <div className="col-12 col-xl-5">
              <GlobalProductsAndServicesList
                selectedUnspscFamilyIds={this.props.data.selectedUnspscFamilyIds}
                getStaticDataCallback={useStaticDataCustom}
              />
              <UtilButton
                color="primary"
                onClick={() => this.toggleGlobalProductsModal()}
              >
                SELECT PRODUCTS AND/OR SERVICES
              </UtilButton>
            </div>
          </div>

          <div className="mt-5" />

          <OfferingSelectionSummary
            readOnly={!canEdit}
            data={this.props.data}
            onInputChangeCallback={this.props.onInputChange}
          />
        </div>

        <div
          className="padding-lr-5vw"
          style={{ paddingBottom: "76px", marginTop: "60px" }}
        >
          <div className="justify-end row">
            <Line color="secondary" />

            {canEdit && (
              <UtilButton
                color="primary"
                location="bottom"
                onClick={this.onClickSave}
              >
                SAVE
              </UtilButton>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default withToast(connect(mapStateToProps, mapDispatchToProps)(ProductService));
