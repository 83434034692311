import React, {
  useState, useEffect, Fragment, useCallback,
} from "react";
import {
  Table,
  // eslint-disable-next-line no-unused-vars
  TableConfig,
  // eslint-disable-next-line no-unused-vars
  TableData,
  PageHeading,
  SystemFeatures,
  WithFeature,
} from "isuppli-react-components";

import {
  Button, Alert, UncontrolledTooltip,
} from "reactstrap";

import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import navMenuItems from "../../Util/menuItems";
import useRouteState from "../../Hooks/useRouteState";
import DetailsItem from "../../Controls/DetailsItem";
import { formatDate, formatCurrency } from "../../Util/valueFormatter";

import Styles from "./Subscription.module.scss";

import {
  InvoicesMessage, // eslint-disable-line no-unused-vars
  InvoiceMessage, // eslint-disable-line no-unused-vars
  BillingSubscriptionStatusMessage, // eslint-disable-line no-unused-vars
} from "../../http/Billing/Models/Models";
import { getSubscriptionDetail, getUserBasicDetails } from "../../http/index";
import { StaticDataTypes } from "../../Util/Enumerators/staticDataTypes";
import useStaticData from "../../Hooks/useStaticData";
import {
  getInvoices, retrySubscriptionPayment,
  getSubscriptionStatus,
} from "../../http/Billing/billingApi";
import Spinner from "../../Containers/Spinner/Spinner";
import useShowError from "../../Hooks/useShowError";
import { checkHttpStatus } from "../../http/httpHelpers";
import { ReduxState } from "../../Store/ReduxState"; // eslint-disable-line no-unused-vars
import { setUserBasicDetails } from "../../Store/actions";
import CancelSubscriptionModal from "./CancelSubscriptionModal/CancelSubscriptionModal";
// eslint-disable-next-line no-unused-vars
import CompanyGroupingType from "../../Util/Enumerators/CompanyGroupingType";

export interface SubscriptionDetails {
  deactivationDate?: Date,
  nextBillingDate: Date,
  nextBillingAmount: number,
  subscriptionTierId: number,
  subscriptionTierName: string,
  pendingSubscriptionTier?: number,
  pendingSubscriptionId?: number,
  sponsored: boolean,
  sponsorName: string,
  sponsoredSubscriptionTierId: number,
  buyerCompanyNames: string[],
  disabledSubscriptionTierIds: number[],
  companyGroupingType: CompanyGroupingType,
  recommendedSubscriptionTierId: number,
}

const Subscription = () => {
  const [loading, setLoading] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [subscriptionDetails, setSubscriptionDetails] = useState<SubscriptionDetails>();
  const [subscriptionStatus, setSubscriptionStatus] = useState<BillingSubscriptionStatusMessage>();
  const [loadingInvoices, setLoadingInvoices] = useState(true);
  const [invoices, setInvoices] = useState<InvoicesMessage>();
  const [pageInfo, setPageInfo] = useRouteState("p", { l: 20, o: 0 });
  const [sortOption, setSortOption] = useRouteState("s", 0);
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false);
  const showError = useShowError();
  const [[
    subscriptionTiers,
  ], staticDataReady] = useStaticData([StaticDataTypes.SubscriptionTier]);

  const currentSubscription = useSelector(
    (state: ReduxState) => state.loggedInUserDetails.subscriptionTierId
  );

  const dispatch = useDispatch();

  const loadSubscriptionDetails = useCallback(async () => {
    setLoadingDetails(true);
    try {
      const [
        loadedDetails,
        loadedSubscriptionStatus,
      ] = await Promise.all([
        getSubscriptionDetail() as Promise<SubscriptionDetails>,
        getSubscriptionStatus(),
      ]);
      setSubscriptionDetails(loadedDetails);
      setSubscriptionStatus(loadedSubscriptionStatus);

      if (currentSubscription !== loadedDetails.subscriptionTierId) {
        // reload subscription details
        const userBasicDetails = await getUserBasicDetails();
        dispatch(setUserBasicDetails(userBasicDetails));
      }

      setLoadingDetails(false);
    } catch (error) {
      showError();
    }
  }, [currentSubscription, dispatch, showError]);

  useEffect(() => {
    // load subscription details
    loadSubscriptionDetails();
  }, [setLoadingDetails, loadSubscriptionDetails]);

  useEffect(() => {
    // load invoices
    const loadInvoices = async () => {
      setLoadingInvoices(true);
      try {
        const invoicesMessage = await getInvoices(pageInfo.l, pageInfo.o);
        setInvoices(invoicesMessage);
      } catch (error) {
        showError();
      }

      setLoadingInvoices(false);
    };

    loadInvoices();
  }, [pageInfo, sortOption, showError]);

  const tableConfig : TableConfig<number, InvoiceMessage> = {
    columns: [{
      key: "invoiceNo",
      heading: "Invoice No.",
      valueTemplate: (item) => <span>{item.invoiceNumber}</span>,
      size: "auto",
      align: "left",
    },
    {
      key: "date",
      heading: "Date",
      valueTemplate: (item) => <span>{formatDate(item.invoiceDate)}</span>,
      size: "auto",
      align: "left",
    },
    {
      key: "description",
      heading: "Description",
      size: "1fr",
      align: "left",
      valueTemplate: (row) => (
        <a
          href={`download/downloadfile?redirectUrl=/api/billing/invoices/${row.id}`}
          download="invoice.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-link w-100 text-left px-0 text-capitalize"
          onClick={() => {
          }}
        >
          {row.description}
        </a>
      ),
    },
    {
      key: "amount",
      heading: "Amount (incl. VAT)",
      valueTemplate: (item) => <span>{formatCurrency(item.totalAmount)}</span>,
      size: "auto",
      align: "left",
    }],
  };

  const RetryPayment = async () => {
    if (subscriptionDetails == null
      || subscriptionDetails.pendingSubscriptionId == null
      || subscriptionDetails.pendingSubscriptionTier == null) {
      return;
    }
    setLoading(true);
    try {
      const redirectUrl = await retrySubscriptionPayment(
        subscriptionDetails?.pendingSubscriptionTier ?? 0,
        subscriptionDetails?.pendingSubscriptionId ?? 0
      );
      if (redirectUrl.length > 0) {
        window.location.href = redirectUrl;
      }
    } catch (error) {
      if (checkHttpStatus(error, 403)) {
        // forbidden means payment was already processed
        // now reload subscription details and user basic details
        await loadSubscriptionDetails();
      } else {
        showError();
      }
    }

    setLoading(false);
  };

  const tableData: TableData<InvoiceMessage> = {
    limit: pageInfo.l,
    offset: pageInfo.o,
    totalItems: invoices?.totalItems ?? 0,
    items: invoices?.items ?? [],
  };

  const downgradePending = subscriptionDetails?.pendingSubscriptionTier != null
    && subscriptionDetails?.deactivationDate != null;

  const upgradePending = subscriptionDetails?.pendingSubscriptionTier != null
    && subscriptionDetails?.deactivationDate == null;

  const pendingSubscription = subscriptionTiers == null ? null : subscriptionTiers
    .find((c) => c.value === subscriptionDetails?.pendingSubscriptionTier);

  const newSubscriptionName = (staticDataReady
    && subscriptionDetails?.pendingSubscriptionTier != null
    && pendingSubscription?.display)
    || "";

  const downgradeToFree = downgradePending && pendingSubscription?.monthlyPriceInclVAT === 0;

  const disableChangeSubscriptionButton = upgradePending || downgradeToFree;

  const disableCancelSubscriptionButton = downgradeToFree;

  const hideRetryButtons = upgradePending
    && pendingSubscription?.monthlyPriceInclVAT === null;

  const hasNextBillingDate = subscriptionDetails?.nextBillingDate != null;

  const currentSubscriptionTier = subscriptionTiers == null ? null : subscriptionTiers
    .find((c) => c.value === subscriptionDetails?.subscriptionTierId);

  // eslint-disable-next-line no-nested-ternary
  const pendingSubscriptionChangeMessage = upgradePending ? "A subscription upgrade is already pending"
    : downgradeToFree ? "Your subscription has been cancelled and cannot be altered at this moment." : "";

  return (
    <section>
      <div className="container-fluid">
        {loading && <Spinner />}
        <div className="row">
          <div className="col-12 offset-0 col-lg-10 offset-lg-1">
            <PageHeading
              heading="My Subscription"
              alertText="Manage your B1LINK Subscription"
              menuItems={navMenuItems}
            />

            {
              showCancelSubscriptionModal
              && (
                <CancelSubscriptionModal
                  onClose={() => setShowCancelSubscriptionModal(false)}
                  freeTierId={subscriptionTiers?.find((tier) => tier?.
                    monthlyPriceInclVAT === 0)?.value}
                  showDowngradeToFreeOption={!subscriptionDetails?.sponsored
                    && currentSubscriptionTier?.monthlyPriceInclVAT > 0}
                  buyerCompanyNames={subscriptionDetails?.buyerCompanyNames ?? []}
                />
              )
            }

            {/* Current Subscription Details */}
            <div className="shadow-card p-3 mb-3">
              <div className="row">
                <div className="col-12">
                  <h3 className="font-weight-bold">Subscription details</h3>
                  {
                    downgradePending
                    && (
                      <Alert color="info">
                        Your account will be downgraded to a
                        {` ${newSubscriptionName} `}
                        subscription on
                        {` ${formatDate(subscriptionDetails?.deactivationDate ?? new Date())}`}
                      </Alert>
                    )
                  }
                  {
                    upgradePending
                    && (
                      <Alert color="info">
                        <div className={Styles.AlertContainer}>
                          <div>
                            Your account will be upgraded to a
                            {` ${newSubscriptionName} `}
                            subscription when payment is received
                          </div>
                          {
                            !hideRetryButtons
                            && (
                              <div>
                                <Button onClick={() => RetryPayment()} className={Styles.RetryButton} color="attention">
                                  {subscriptionStatus?.hasActiveAdhocSubscription ? "Check for payment" : "Retry payment"}
                                </Button>
                              </div>
                            )
                          }
                        </div>
                      </Alert>
                    )
                  }
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                  <DetailsItem loading={loadingDetails} label="Current subscription" value={subscriptionDetails?.subscriptionTierName ?? ""} />
                  {
                    (pendingSubscription != null)
                    && (
                      <DetailsItem loading={loadingDetails} label="New subscription" value={pendingSubscription.display ?? ""} />
                    )
                  }
                  {
                    (hasNextBillingDate && !(currentSubscriptionTier?.value === 0))
                    && (
                      <Fragment>
                        <DetailsItem
                          loading={loadingDetails}
                          label="Next payment date"
                          value={formatDate(subscriptionDetails?.nextBillingDate ?? new Date())}
                        />
                        <DetailsItem
                          loading={loadingDetails}
                          label="Next billing amount"
                          value={formatCurrency(subscriptionDetails?.nextBillingAmount ?? 0)}
                        />
                      </Fragment>
                    )
                  }
                </div>
                <WithFeature feature={SystemFeatures.SubscriptionsEdit}>
                  <div className="col-12 pt-3 col-sm-6 pt-sm-0 col-md-8 col-xl-9 d-flex justify-content-end align-items-center">
                    <Link to="subscription/edit">
                      <div id="btnChangeSubscription" className="divChangeSubscriptionBtn">
                        <Button
                          style={{ pointerEvents: "none" }}
                          className={Styles.UpgradeButton}
                          color="primary"
                          disabled={loadingDetails || disableChangeSubscriptionButton}
                        >
                          Change subscription
                        </Button>
                      </div>
                    </Link>
                    <div className={Styles.CancelSubscriptionBtn}>
                      <Button
                        className={Styles.UpgradeButton}
                        color="danger"
                        disabled={loadingDetails || disableCancelSubscriptionButton}
                        onClick={() => setShowCancelSubscriptionModal(true)}
                      >
                        Cancel subscription
                      </Button>
                    </div>
                    {
                      disableChangeSubscriptionButton
                       && (
                         <UncontrolledTooltip placement="right" target="btnChangeSubscription">
                           {pendingSubscriptionChangeMessage}
                         </UncontrolledTooltip>
                       )
                    }
                  </div>
                </WithFeature>
              </div>
            </div>

            {/* Invoice Table */}
            <Table
              title="Invoices"
              loading={loadingInvoices}
              tableConfig={tableConfig}
              data={tableData}
              onPageChange={(l: number, o: number) => setPageInfo({ l, o })}
              tableStyle="basic"
              onSortOptionChange={(newValue: number) => {
                setSortOption(newValue);
              }}
            />
          </div>
        </div>
      </div>
    </section>

  );
};

export default Subscription;
