import React from "react";
import {
  DropdownItem,
  // eslint-disable-next-line no-unused-vars
  DropdownItemProps,
} from "reactstrap";
import Styles from "./TableActionsItem.module.scss";
import { useSystemFeatureCheck } from "../../../Hooks/useSystemFeatureCheck";
// eslint-disable-next-line no-unused-vars
import SystemFeatures from "../../../utils/enums/SystemFeatures";
import { useShowNotAllowedError } from "../../../Hooks/useNotAllowedError";
// eslint-disable-next-line no-unused-vars
import { TableItem } from "../Table/Interfaces/TableItem";

export interface TableActionsItemProps<TIdType, TDataType extends TableItem<TIdType>> extends Omit<DropdownItemProps, "onClick"> {
  shouldDisable?: () => boolean,
  shouldDisplay?: (item: TDataType) => boolean,
  availableWhenAllSelected?: boolean,
  availableWhenPageSelected?: boolean,
  hideInTableHeader?: boolean,
  onClick?: (items: Array<TIdType>, isAllSelected: boolean, itemObjects?: Array<TDataType>) => void
  children: React.ReactNode,
  feature?: SystemFeatures,
}
const TableActionsItem = <TIdType, TDataType extends TableItem<TIdType>>({
  shouldDisable,
  shouldDisplay,
  availableWhenAllSelected,
  availableWhenPageSelected,
  hideInTableHeader,
  feature,
  onClick,
  ...o // eslint-disable-line react/jsx-props-no-spreading
} : TableActionsItemProps<TIdType, TDataType>) => {
  const featureCheck = useSystemFeatureCheck();
  const showNotAllowedError = useShowNotAllowedError();
  const allowed = feature == null || featureCheck(feature);
  const appearDisabled = !allowed;
  const disabled = shouldDisable != null && shouldDisable();

  return (
    <DropdownItem
      className={`${appearDisabled ? Styles.AppearDisabled : ""} ${Styles.DropDownItem}`}
      disabled={disabled}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...o}
      onClick={onClick == null ? undefined : (e: any) => {
        if (!allowed) {
          showNotAllowedError(feature);
          return;
        }
        onClick(e, false);
      }}
    />
  );
};

export default TableActionsItem;
