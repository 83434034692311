import React from "react";

import {
  PageHeading,
} from "isuppli-react-components";
import Loading from "../Loading";

import InfoPopup from "../../Containers/InfoPopup";
import navMenuItems from "../../Util/menuItems";
import CommunicationPreferenceSummary from "../../Containers/User/CommunicationPreferenceSummary";

const CommunicationPreference = () => (
  <section id="isuppli-supplier-info" style={{ minHeight: "80vh" }}>

    <InfoPopup />
    <Loading />

    <div className="padding-lr-10vw" style={{ paddingTop: "20px" }}>
      <PageHeading
        heading="Communication Preferences"
        alertText="Manage your communication preferences"
        menuItems={navMenuItems}
      />
      <div className="mt-5" />
    </div>

    <CommunicationPreferenceSummary />

  </section>
);

export default CommunicationPreference;
