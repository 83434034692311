import React, {
  Fragment, useEffect, useRef, useState,
} from "react";
import { ModalPopup, DragDropFileUpload } from "isuppli-react-components";
import { Alert, Progress } from "reactstrap";
import axios, { CancelTokenSource } from "axios";// eslint-disable-line no-unused-vars
import useShowError from "../../Hooks/useShowError";
import { supplierModalHeading, supplierModalPostUploadText, supplierModalPreUploadText } from "./SupplierModalStaticContent";
// eslint-disable-next-line no-unused-vars
import useSignalR, { SignalRNotifications, SupplierImportStatus } from "../../Hooks/useSignalR";
import { getSupplierImport, resolveSupplierImport } from "../../http/SupplierImport/supplierImportAPI";
import Spinner from "../Spinner/Spinner";
import CompleteScreen from "./SupplierImportCompleteScreen";
// eslint-disable-next-line no-unused-vars
import { SupplierImportMessage } from "../../http/SupplierImport/Models/SupplierImportMessage";

const SupplierImportModal = ({
  onCloseModal,
}:
  {
    onCloseModal: () => void,
  }) => {
  const [file, setFile] = useState<File | null>();
  const [fileUploaded, setFileUploaded] = useState(false);
  const [busyUploadingFile, setBusyUploadingFile] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [progressBarPercentage, setProgressBarPercentage] = useState<number>(0);
  const [importProgressMessage, setImportProgressMessage] = useState<SupplierImportMessage | null>(); // eslint-disable-line max-len
  const [loading, setLoading] = useState(false);
  const showError = useShowError();
  const httpCancelToken = useRef<CancelTokenSource>();

  // setup SignalR connection
  const disconnect = useSignalR<SupplierImportMessage>(
    SignalRNotifications.SupplierImportProgressUpdates,
    async (data) => {
      if (showProgressBar) {
        setProgressBarPercentage(50 + (data.progressPercentage / 2));
        setImportProgressMessage(data);
      }
    }
  );

  // cleanup when component is removed
  useEffect(() => () => {
    if (httpCancelToken.current != null) {
      httpCancelToken.current.cancel();
    }
    disconnect();
  }, [disconnect]);

  useEffect(() => {
    const loader = async () => {
      const supplierImport = await getSupplierImport();
      setImportProgressMessage(supplierImport);
      switch (supplierImport.status) {
        case SupplierImportStatus.Enqued:
          setProgressBarPercentage(50);
          setShowProgressBar(true);
          break;
        case SupplierImportStatus.BusyProcessing:
          setProgressBarPercentage(50);
          setShowProgressBar(true);
          break;
        case SupplierImportStatus.Complete:
          setProgressBarPercentage(100);
          setShowProgressBar(true);
          break;
        default:
          break;
      }
    };
    loader();
  }, []);

  const handleFileNewFileUpload = (newFile: File | Array<File>) => {
    if (!Array.isArray(newFile)) {
      setFile(newFile);
    }
  };
  const handleRemoveFile = () => {
    setFile(null);
  };

  const uploadFile = (targetFile: File) => {
    setShowProgressBar(true);
    const formData = new FormData();
    formData.append(targetFile.name, targetFile);
    axios
      .post("api/supplierImport/", formData, {
        onUploadProgress: (progEv) => {
          const progress = (() => {
            const val = Math.round((progEv.loaded / targetFile.size) * 50);
            return val <= 50 ? val : 50;
          })();
          setProgressBarPercentage(progress);
        },
      })
      .then(() => {
        setFileUploaded(true);
        setBusyUploadingFile(false);
        setFile(null);
      })
      .catch(() => {
        setShowProgressBar(false);
        showError();
      });
  };

  const getProgressBarText = () => {
    if (fileUploaded
      || importProgressMessage?.status === SupplierImportStatus.Enqued
      || importProgressMessage?.status === SupplierImportStatus.BusyProcessing) {
      return "Uploading suppliers, please be patient as this could take a few minutes.";
    }
    return "Uploading file";
  };

  const getUploadButtonText = () => {
    if (importProgressMessage?.status === SupplierImportStatus.Complete) {
      return "Upload another file";
    }
    return "Upload suppliers";
  };

  const resolveImportCompletion = async () => {
    try {
      setLoading(true);
      await resolveSupplierImport();
    } catch {
      setLoading(false);
      showError();
    }
    setImportProgressMessage((prevState) => {
      if (prevState != null) {
        return (
          {
            ...prevState,
            status: SupplierImportStatus.Finalized,
          }
        );
      }
      return prevState;
    });
    setFile(null);
    setImportProgressMessage(null);
    setProgressBarPercentage(0);
    setShowProgressBar(false);
    setLoading(false);
  };

  return (
    <Fragment>
      {
        loading && <Spinner />
      }
      <ModalPopup
        heading={supplierModalHeading}
        onClose={onCloseModal}
        hasCancel
        buttons={[
          {
            key: "submit",
            label: getUploadButtonText(),
            color: "primary",
            disabled: (
              importProgressMessage?.status !== SupplierImportStatus.Complete && (
                file == null
                || busyUploadingFile === true
                || importProgressMessage?.status === SupplierImportStatus.BusyProcessing
                || importProgressMessage?.status === SupplierImportStatus.Enqued
              )
            ),
            onClick: () => {
              if (importProgressMessage?.status === SupplierImportStatus.Complete) {
                resolveImportCompletion();
              }

              if (file != null) {
                uploadFile(file);
              }
            },
          },
        ]}
      >
        {
          // eslint-disable-next-line no-nested-ternary
          importProgressMessage?.status === SupplierImportStatus.Complete
            ? (
              <div className="m-4">
                <CompleteScreen
                  message={importProgressMessage}
                />
              </div>
            )
            : showProgressBar ? (
              <Fragment>
                <div className="m-3 p-5">
                  <Progress className="w-100" value={progressBarPercentage} />
                  <div className="col-12 text-center p-3 m-2">
                    <h3>{getProgressBarText()}</h3>
                    { (importProgressMessage?.totalRecords ?? 0) > 0
                      ? (
                        <p>
                          {`Processed ${
                            (importProgressMessage?.processedRecords ?? 0)
                        + (importProgressMessage?.failedRecords ?? 0)
                          } of ${
        importProgressMessage?.totalRecords
                          }`}
                        </p>
                      )
                      : null}

                  </div>
                  <div />
                </div>
              </Fragment>
            )
              : (
                <Fragment>
                  <Alert fade={false} color="info" className="mb-0">
                    {file == null ? supplierModalPreUploadText : supplierModalPostUploadText}
                  </Alert>
                  <div className="m-3 text-center">
                    <a
                      href="api/supplierImport/supplierImportTemplate/download"
                      download="SupplierImportTemplate.xlsx"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-primary"
                      onClick={() => {
                      }}
                    >
                      <b className="mx-4 px-4">DOWNLOAD TEMPLATE</b>
                    </a>
                  </div>
                  <div>
                    <DragDropFileUpload
                      acceptedFormats=".xlsx"
                      onUpdateFilesCallback={handleFileNewFileUpload}
                      onRemoveFileCallback={handleRemoveFile}
                    />
                  </div>
                </Fragment>
              )
        }
      </ModalPopup>
      <div>
        <div className="col-12">
          Upload your suppliers to invite them
        </div>
      </div>

    </Fragment>
  );
};

export default SupplierImportModal;
