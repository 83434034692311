import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { DelegateOnboardingRequestApprovalMessage } from "./DelegateOnboardingRequestApprovalMessage";
// eslint-disable-next-line no-unused-vars
import { AddOrUpdateVendorNumberMessage } from "./AddOrUpdateVendorNumberMessage";
import { makeRequestAsync } from "../util";
// eslint-disable-next-line no-unused-vars
import { OnboardingConfigurationMessage } from "../EnterpriseConfiguration/enterpriseConfiguration";

export async function addOrUpdateVendorNumber(message: AddOrUpdateVendorNumberMessage) {
  const url = "/api/motivation/vendorNumber";
  return makeRequestAsync(
    () => axios.post(url, message)
  ) as Promise<any>;
}

export async function delegateOnboardingRequestApproval(message : DelegateOnboardingRequestApprovalMessage) {
  const url = "/api/motivation/delegation";
  return makeRequestAsync(
    () => axios.post(url, message)
  ) as Promise<any>;
}

export async function getOnboardingReqeustComments(onboardingRequestId : number) {
  const url = `/api/motivation/comments?onboardingRequestId=${onboardingRequestId}`;
  return makeRequestAsync(
    () => axios.get(url)
  ) as Promise<any>;
}

export async function getOnboardingConfiguration() {
  const url = "/api/enterprise/onboarding";
  return makeRequestAsync(
    () => axios.get(url)
  ) as Promise<OnboardingConfigurationMessage>;
}

export async function getSupplierPaymentTermsId(supplierCompanyId : number) {
  const url = `/api/motivation/supplierPaymentTerms?SupplierCompanyId=${supplierCompanyId}`;
  return makeRequestAsync(
    () => axios.get(url)
  ) as Promise<number>;
}

export async function updateSupplierPaymentTerms(supplierCompanyId : number, paymentTermsId : number, comment : string) {
  const url = "/api/motivation/supplierPaymentTerms";
  return makeRequestAsync(
    () => axios.post(url, { supplierCompanyId, paymentTermsId, comment })
  ) as Promise<any>;
}

export async function bulkEmailUpdate(onboardingRequestId : number, email : string) {
  const url = "/api/motivation/bulkEmailUpdate";
  return makeRequestAsync(
    () => axios.post(url, { onboardingRequestId, email })
  ) as Promise<any>;
}
