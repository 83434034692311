import React from "react";
import { Button } from "reactstrap";
// eslint-disable-next-line no-unused-vars
import { SystemFeatures } from "../..";
import { useShowNotAllowedError } from "../../Hooks/useNotAllowedError";
import Styles from "./UtilButton.module.scss";

function UtilButton({
  disabled = false,
  color = "primary",
  outline = false,
  size,
  location,
  className = "",
  children,
  feature,
  onClick,
}
:{
  disabled?: boolean,
  color? : "primary" | "secondary",
  outline?: boolean,
  size?: "small" | "extra-small" | "default",
  location?: "bottom" | "top",
  className?: string,
  children: React.ReactNode,
  feature?: SystemFeatures,
  onClick: () => void,
}) {
  const showNotAllowedError = useShowNotAllowedError();
  const classes = [Styles.UtilButton, className];
  if (disabled) { classes.push(Styles.DisabledButton); }

  switch (location) {
    case "bottom":
      classes.push(Styles.Bottom);
      break;
    case "top":
      classes.push(Styles.Top);
      break;
    default:
      break;
  }

  switch (size) {
    case "small":
      classes.push(Styles.Small);
      break;
    case "extra-small":
      classes.push(Styles.ExtraSmall);
      break;
    default:
      break;
  }

  return (
    <Button
      className={classes.join(" ")}
      color={color}
      outline={outline}
      onClick={(ev) => {
        ev.preventDefault();
        if (!disabled) {
          onClick();
        } else if (feature != null) {
          showNotAllowedError(feature);
        }
      }}
    >
      {children}
    </Button>
  );
}

export default UtilButton;
