enum BeeValidationStatus {
  /* eslint-disable no-unused-vars */
  None = 0,
  Uploaded = 1,
  PendingValidation = 2,
  Validated = 3,
  Historical = 4,
  Revoked = 5,
  /* eslint-enable no-unused-vars */
}

export const beeValidationStatusToString = (value: BeeValidationStatus) => {
  switch (value) {
    case BeeValidationStatus.Validated:
      return "Valid";
    case BeeValidationStatus.Revoked:
      return "Invalid";
    default:
      return "Validating";
  }
};

export const getBeeValidationStatusBadgeColor = (
  value: BeeValidationStatus
):string => {
  switch (value) {
    case BeeValidationStatus.Validated:
      return "success";
    case BeeValidationStatus.Revoked:
      return "danger";
    default:
      return "secondary";
  }
};

export default BeeValidationStatus;
