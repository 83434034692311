import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Table, TableActionsItem, SystemFeatures, ConfirmationModal,
  SearchFiltersContainer,
  FilterTypes,
  hasFeature,
  HelpIcon,
} from "isuppli-react-components";

import { getStaticValue, formatDate } from "../../Util/valueFormatter";
import OnboardingRequestOrigin from "../../Util/Enumerators/onboardingRequestOrigin";

import OnboardingSearch from "../OnboardingSearch/OnboardingSearch";
import SupplierCheckModal from "../../Containers/Marketplace/SupplierChecksModal/SupplierCheckModal";
import AgreementsModal from "../../Containers/AgreementsModal/AgreementsModal";

import { overallComplianceStatus, overallComplianceStatusToString } from "../../Util/Enumerators/overallComplianceStatus";
import { setOption } from "../../Store/optionActions";

import Loading from "../Loading";

import ComposeMessageModal from "../../Containers/SupplierSearch/CompseMessageModal/ComposeMessageModal";

import {
  showLoadingScreen,
  hideLoadingScreen,
  showErrorDialog,
  setStaticCompanyData,
} from "../../Store/actions";
import { setOnboardingRequestObject } from "../../Store/onboardingRequestActions";

import onboardingStatuses from "../../Util/Enumerators/onboardingStatuses";
import onboardingFilterOptions from "../../Util/Enumerators/onboardingFilterOptions";

import {
  sortingValues,
  getTableConfig,
} from "./OnboardingRequestConfiguration";

import {
  getOnboardingRequests,
  deleteOnboardingRequest,
  approveOnboardingRequest,
  declineOnboardingRequest,
  sendOnboardingRequestEmail,
  retractOnboardingRequest,
  acceptOnboardingRequest,
  updateOnboardingRequestDelegationStatus,
  suspendOnboardingRequest,
  addComment,
} from "../../http/index";
import SupplierDetailsModal from "../SupplierDetailsModal";
import {
  StaticCompanyDataTypes, // eslint-disable-line no-unused-vars
} from "../../Util/Enumerators/staticCompanyDataTypes";

import StaticCompanyData from "../../Hooks/StaticCompanyData";
import StaticData from "../../Hooks/StaticData";

import { createProductServiceSummary } from "../../Util/dataTransform";
import UpdateVendorNumberModal from "../../Containers/UpdateVendorNumberModal/UpdateVendorNumberModal";
import { getOnboardingConfiguration } from "../../http/EnterpriseConfiguration/enterpriseConfiguration";
import VendorNumberOrigin from "../../Util/Enumerators/vendorNumberOriginEnum";
import DelegateMTOApprovalModal from "../OnboardingSearch/DelegateMTOApprovalModal";
import onboardingDelegationStatus from "../../Util/Enumerators/OnboardingDelegationStatus";
import { getContractTiers } from "../../http/Contracting/contractingApi";
import { StaticDataTypes } from "../../Util/Enumerators/staticDataTypes";
import CommentModal from "./CommentModal/CommentModal";
import PaymentTermsUpdate from "./PaymentTermsUpdate/PaymentTermsUpdate";
import BulkEmailUpdate from "./BulkEmailUpdate/BulkEmailUpdate";

const mapStateToProps = (state) => ({
  data: { ...state.registration.prdInfo, companyId: state.currentCompanyId },
  businessUnits: (state.staticCompanyData[StaticCompanyDataTypes.businessUnit] ?? []),
  supplierCategories: (state.staticCompanyData[StaticCompanyDataTypes.supplierCategory] ?? []),
  userBusinessUnitIds: state.loggedInUserDetails.businessUnitIds,
  allowedFeatures: state.loggedInUserDetails.allowedFeatures,
  roles: state.staticCompanyData[StaticCompanyDataTypes.roles] ?? [],
  contractTiers: state.staticCompanyData[StaticCompanyDataTypes.contractTiers],
  paymentTerms: state.staticCompanyData[StaticCompanyDataTypes.paymentTerms] ?? [],
});

const mapDispatchToProps = (dispatch) => ({
  setOption: (optionName, optionValues) => {
    dispatch(setOption(optionName, optionValues));
  },
  showLoadingScreen: (msg) => {
    dispatch(showLoadingScreen(msg));
  },
  hideLoadingScreen: () => {
    dispatch(hideLoadingScreen());
  },
  setOnboardingRequestToEdit: (onbordingRequest) => {
    dispatch(setOnboardingRequestObject(onbordingRequest));
  },
  showErrorDialog: () => {
    dispatch(showErrorDialog(true));
  },
  setContractTiers: (contractTiers) => {
    dispatch(setStaticCompanyData([{
      type: StaticCompanyDataTypes.contractTiers,
      value: contractTiers,
    }], true));
  },
});

class OnboardingRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onboardingRequests: {
        items: [],
        totalItems: 0,
        limit: 20,
        offset: 0,
      },
      limit: 20,
      offset: 0,
      showSupplierStatus: false,
      supplierStatusCompany: {},
      searchTerm: "",
      showDeleteConfirm: false,
      showApproveConfirm: false,
      showApproveDelegatedReviewConfirm: false,
      showDeclineDelegatedReviewConfirm: false,
      showDeclineConfirm: false,
      showSuspendConfirm: false,
      showSendEmailConfirm: false,
      showRetractConfirm: false,
      showAcceptWithExceptionConfirm: false,
      showComplianceDeclineConfirm: false,
      showMessageModal: false,
      showOnboardingApprovalCommentModal: false,
      showAddCommentModal: false,
      showUpdatePaymentTermsModal: false,
      showUpdateBulkEmailModal: false,
      filterValues: [],
      sortOption: sortingValues.newestFirst,
      filterOption: onboardingFilterOptions.Active,
      showViewMoreModal: false,
      company: null,
      onboardingRequestId: null,
      dataLoading: true,
      showUpdateVendorNumberModal: false,
      showDelegateApprovalModal: false,
      manullyAssignVendorNumber: false,
      companyIdToPerformAction: null,
    };
  }

  findOnboardingRequest(onboardingId) {
    return this.state.onboardingRequests.items.find(
      (item) => item.onboardingId === onboardingId
    );
  }

  featureCheck(feature) {
    if (typeof (feature) === "bigint") {
      return hasFeature(
        this.props.allowedFeatures,
        feature
      );
    }
    return false;
  }

  canSeeApproveButton(item) {
    return ((this.featureCheck(SystemFeatures.OnboardingApprove1) && item.onboardingStatus === onboardingStatuses.pendingApproval1)
      || (this.featureCheck(SystemFeatures.OnboardingApprove2) && item.onboardingStatus === onboardingStatuses.pendingApproval2)
      || (this.featureCheck(SystemFeatures.OnboardingAddSupplierApproval)
        && item.onboardingStatus === onboardingStatuses.PendingAddSupplierApproval))
       && item.delegationStatus !== onboardingDelegationStatus.PendingReview
      && item.delegationStatus !== onboardingDelegationStatus.Declined;
  }

  static canSeeSendMessageOption(item) {
    return (item.onboardingStatus === onboardingStatuses.awaitingCompliance
    || item.onboardingStatus === onboardingStatuses.complianceFailure
    || item.onboardingStatus === onboardingStatuses.profileIncomplete
    || item.onboardingStatus === onboardingStatuses.pendingApproval1
    || item.onboardingStatus === onboardingStatuses.pendingApproval2
    || item.onboardingStatus === onboardingStatuses.PendingAddSupplierApproval
    || item.onboardingStatus === onboardingStatuses.declined
    || item.onboardingStatus === onboardingStatuses.suspended
    || item.onboardingStatus === onboardingStatuses.awaitingContractApprovals
    || item.onboardingStatus === onboardingStatuses.awaitingVendorNumber
    || item.onboardingStatus === onboardingStatuses.completed);
  }

  canSeeSuspendButton(item) {
    return this.featureCheck(SystemFeatures.CanSuspendMTO)
      && (item.onboardingStatus === onboardingStatuses.pendingApproval1
        || item.onboardingStatus === onboardingStatuses.pendingApproval2
        || item.onboardingStatus === onboardingStatuses.PendingAddSupplierApproval);
  }

  canSeeUpdatePaymentTermsButton() {
    return (this.props?.paymentTerms?.length > 0);
  }

  static canSeeComments(item) {
    return item.onboardingStatus !== onboardingStatuses.New;
  }

  tableConfig = getTableConfig([
    <TableActionsItem
      shouldDisplay={
        (item) => item.onboardingStatus !== onboardingStatuses.New
          && item.onboardingStatus !== onboardingStatuses.invitationPending
      }
      key="viewSupplier"
      onClick={async (items) => {
        const companyId = this.findOnboardingRequest(items[0]).supplierCompanyId;
        if (!companyId) {
          // null supplier id
        } else {
          this.toggleViewMoreModal({ companyId });
        }
      }}
    >
      View Supplier Details
    </TableActionsItem>,
    <TableActionsItem
      shouldDisplay={
        (item) => item.onboardingStatus !== onboardingStatuses.New
          && item.onboardingStatus !== onboardingStatuses.invitationPending
      }
      shouldDisable={() => this.props.contractTiers?.length <= 0}
      key="agreements"
      onClick={async (items) => {
        const onboardingRequest = this.state.onboardingRequests.items.find(
          (item) => item.onboardingId === items[0]
        );
        if (!onboardingRequest) {
          // null supplier id
        } else {
          this.toggleAgreementsModal({
            companyId: onboardingRequest.supplierCompanyId,
            registeredName: onboardingRequest.registeredName,
          });
        }
      }}
    >
      View Agreements
    </TableActionsItem>,
    <TableActionsItem
      shouldDisplay={this.canSeeComments}
      key="comments"
      onClick={async (items) => {
        const onboardingRequest = this.state.onboardingRequests.items.find(
          (item) => item.onboardingId === items[0]
        );
        if (onboardingRequest) {
          this.toggleCommentModal(onboardingRequest.id);
        }
      }}
    >
      View Comments
    </TableActionsItem>,
    <TableActionsItem
      shouldDisplay={(item) => item.onboardingStatus !== onboardingStatuses.New
        && item.onboardingStatus !== onboardingStatuses.invitationPending
        && item.onboardingStatus !== onboardingStatuses.awaitingRegistration}
      key="viewChecks"
      feature={SystemFeatures.SupplierChecks}
      onClick={(ids) => {
        const item = this.state.onboardingRequests.items.find((c) => c.id === ids[0]);
        this.toggleSupplierStatus(item);
      }}
    >
      View Compliance Management Details
    </TableActionsItem>,
    <TableActionsItem
      shouldDisplay={(item) => item.onboardingStatus === onboardingStatuses.New
        || item.onboardingStatus === onboardingStatuses.declined

        // bulk import supplier
        || ((item.onboardingStatus === onboardingStatuses.manualSupplierInvitationPending
          || item.onboardingStatus === onboardingStatuses.invitationPending)
          && item.origin === OnboardingRequestOrigin.BulkSupplierImport
        )}
      key="delete"
      onClick={(items) => this.toggleDeleteConfirm(items[0])}
    >
      Delete Onboarding Request
    </TableActionsItem>,
    <TableActionsItem
      shouldDisplay={(item) => item.onboardingStatus === onboardingStatuses.New
        || item.onboardingStatus === onboardingStatuses.declined}
      key="edit"
      onClick={async (items) => {
        this.props.history.push(`${this.props.location.pathname}/${items[0]}`);
      }}
    >
      Edit Onboarding Request
    </TableActionsItem>,
    <TableActionsItem
      key="updatePaymentTerms"
      shouldDisplay={() => this.canSeeUpdatePaymentTermsButton()}
      onClick={(item) => {
        const companyId = this.findOnboardingRequest(item[0]).supplierCompanyId;
        if (!companyId) {
          // null supplier id
        } else {
          this.toggleUpdatePaymentTermsModal(companyId);
        }
      }}
    >
      Update Payment Terms
    </TableActionsItem>,
    <TableActionsItem
      key="updateBulkEmail"
      shouldDisplay={(item) => item.origin === OnboardingRequestOrigin.BulkSupplierImport
        && (item.onboardingStatus === onboardingStatuses.New
          || item.onboardingStatus === onboardingStatuses.invitationPending
          || item.onboardingStatus === onboardingStatuses.manualSupplierInvitationPending
          || item.onboardingStatus === onboardingStatuses.networkVendorInvitationPending)}
      onClick={(item) => this.toggleUpdateBulkEmailModal(item[0])}
    >
      Update Email
    </TableActionsItem>,
    <TableActionsItem
      shouldDisplay={(item) => item.onboardingStatus === onboardingStatuses.pendingApproval1
        && item.delegationStatus === onboardingDelegationStatus.PendingReview}
      key="approveDelegatedPendingReview"
      feature={SystemFeatures.ApproveDelegatedOnboardingRequestApproval1}
      onClick={(items) => {
        this.toggleApproveDelegateConfirm(items[0]);
      }}
    >
      Approve Onboarding Request (Preliminary Review)
    </TableActionsItem>,
    <TableActionsItem
      shouldDisplay={(item) => item.delegationStatus === onboardingDelegationStatus.PendingReview}
      key="declineDelegatedPendingReview"
      feature={SystemFeatures.ApproveDelegatedOnboardingRequestApproval1}
      onClick={(items) => {
        this.toggleDeclineDelegateConfirm(items[0]);
      }}
    >
      Decline Onboarding Request (Preliminary Review)
    </TableActionsItem>,
    <TableActionsItem
      shouldDisplay={(item) => this.canSeeApproveButton(item)}
      key="approve"
      feature={
        // eslint-disable-next-line no-bitwise
        SystemFeatures.OnboardingApprove1
          | SystemFeatures.OnboardingApprove2
          | SystemFeatures.OnboardingAddSupplierApproval
      }
      onClick={(items) => {
        this.toggleApproveConfirm(items[0]);
      }}
    >
      Approve Onboarding Request
    </TableActionsItem>,
    <TableActionsItem
      shouldDisplay={(item) => item.onboardingStatus === onboardingStatuses.pendingApproval2
        && item.delegationStatus === onboardingDelegationStatus.PendingReview}
      key="approveDelegatedPendingReview"
      feature={SystemFeatures.ApproveDelegatedOnboardingRequestApproval2}
      onClick={(items) => {
        this.toggleApproveDelegateConfirm(items[0]);
      }}
    >
      Approve Onboarding Request (Preliminary Review)
    </TableActionsItem>,
    <TableActionsItem
      shouldDisplay={(item) => item.delegationStatus === onboardingDelegationStatus.PendingReview}
      key="declineDelegatedPendingReview"
      feature={SystemFeatures.ApproveDelegatedOnboardingRequestApproval2}
      onClick={(items) => {
        this.toggleDeclineDelegateConfirm(items[0]);
      }}
    >
      Decline Onboarding Request (Preliminary Review)
    </TableActionsItem>,
    <TableActionsItem
      shouldDisplay={(item) => item.onboardingStatus === onboardingStatuses.awaitingVendorNumber
        && this.state.manullyAssignVendorNumber
        && this.featureCheck(SystemFeatures.AssignManualManualVendorNumber)}
      key="allocateVendorNumber"
      feature={SystemFeatures.AssignManualManualVendorNumber}
      onClick={(items) => {
        this.toggleUpdateVendorNumberModal(items[0]);
      }}
    >
      Allocate Vendor Number
    </TableActionsItem>,
    <TableActionsItem
      shouldDisplay={(item) => item.onboardingStatus === onboardingStatuses.pendingApproval2
        && item.delegationStatus === onboardingDelegationStatus.None
        && this.featureCheck(SystemFeatures.DelegateOnboardingRequestApproval2)}
      key="delegateOnboardingApproval2"
      feature={SystemFeatures.DelegateOnboardingRequestApproval2}
      onClick={(items) => {
        this.toggleDelegateApprovalModal(items[0]);
      }}
    >
      Delegate Approval
    </TableActionsItem>,
    <TableActionsItem
      shouldDisplay={(item) => item.onboardingStatus === onboardingStatuses.pendingApproval1
        || item.onboardingStatus === onboardingStatuses.pendingApproval2
        || item.onboardingStatus === onboardingStatuses.PendingAddSupplierApproval}
      key="decline"
      feature={
        // eslint-disable-next-line no-bitwise
        SystemFeatures.OnboardingApprove1
          | SystemFeatures.OnboardingApprove2
          | SystemFeatures.OnboardingAddSupplierApproval
      }
      onClick={(items) => this.toggleDeclineConfirm(items[0])}
    >
      Decline Onboarding Request
    </TableActionsItem>,
    <TableActionsItem
      shouldDisplay={(item) => this.canSeeSuspendButton(item)}
      key="suspend"
      feature={
        // eslint-disable-next-line no-bitwise
        SystemFeatures.CanSuspendMTO
      }
      onClick={(items) => this.toggleSuspendConfirm(items[0])}
    >
      Suspend Onboarding Request
    </TableActionsItem>,
    <TableActionsItem
      shouldDisplay={(item) => item.onboardingStatus === onboardingStatuses.suspended}
      key="amend"
      feature={
        // eslint-disable-next-line no-bitwise
        SystemFeatures.OnboardingCreateRequest
        | SystemFeatures.OnboardingApprove1
        | SystemFeatures.OnboardingApprove2
        | SystemFeatures.OnboardingAddSupplier
        | SystemFeatures.OnboardingAddSupplierApproval
      }
      onClick={async (items) => {
        this.props.history.push(`${this.props.location.pathname}/${items[0]}`);
      }}
    >
      Amend Request
    </TableActionsItem>,
    <TableActionsItem
      shouldDisplay={(item) => (
        item.onboardingStatus === onboardingStatuses.invitationPending
        || item.onboardingStatus === onboardingStatuses.manualSupplierInvitationPending
      )
        || item.onboardingStatus === onboardingStatuses.networkVendorInvitationPending}
      key="sendEmail"
      onClick={(items) => this.toggleSendEmailConfirm(items[0])}
    >
      Resend Onboarding Request Email
    </TableActionsItem>,
    <TableActionsItem
      shouldDisplay={(item) => (item.onboardingStatus === onboardingStatuses.invitationPending
        || item.onboardingStatus === onboardingStatuses.awaitingRegistration
        || item.onboardingStatus === onboardingStatuses.awaitingCompliance
        || item.onboardingStatus === onboardingStatuses.complianceFailure
        || item.onboardingStatus === onboardingStatuses.profileIncomplete
        || item.onboardingStatus === onboardingStatuses.pendingApproval1
        || item.onboardingStatus === onboardingStatuses.pendingApproval2
        || item.onboardingStatus === onboardingStatuses.awaitingContractApprovals)}
      key="retract"
      // eslint-disable-next-line no-bitwise
      feature={SystemFeatures.OnboardingCreateRequest}
      onClick={(items) => this.toggleRetractConfirm(items[0])}
    >
      Retract Onboarding Request
    </TableActionsItem>,
    <TableActionsItem
      shouldDisplay={(item) => item.canAcceptWithException}
      key="accept-with-exception"
      onClick={(items) => this.toggleAcceptWithExceptionConfirm(items[0])}
    >
      Accept With Exception
    </TableActionsItem>,
    <TableActionsItem
      shouldDisplay={(item) => item.onboardingStatus === onboardingStatuses.complianceFailure}
      key="compliance-error-decline"
      onClick={(items) => this.toggleComplianceDeclineConfirm(items[0])}
    >
      Decline Onboarding Request
    </TableActionsItem>,
    <TableActionsItem
      shouldDisplay={(item) => OnboardingRequest.canSeeSendMessageOption(item)}
      key="message"
      onClick={(items) => {
        const company = this.state.onboardingRequests.items.find(
          (item) => item.onboardingId === items[0]
        );
        this.toggleMessageModal(company);
      }}
    >
      Send Message to Supplier
    </TableActionsItem>,
    <TableActionsItem
      shouldDisplay={this.canSeeComments}
      key="add-comment"
      onClick={(items) => {
        this.toggleAddComment(items[0]);
      }}
    >
      Add Comment
    </TableActionsItem>,
  ]);

  toggleSupplierStatus = (item) => {
    this.setState((prevState) => ({
      showSupplierStatus: !prevState.showSupplierStatus,
      supplierStatusCompany: {
        id: item?.supplierCompanyId,
        name: item?.registeredName,
      },
    }));
  };

  updateOnboardingLists(onboardingId) {
    this.setState((prevState) => {
      // update onboarding list
      const newOnboarding = prevState.onboardingRequests.items.filter((item) => {
        if (item.onboardingId === onboardingId) {
          return false;
        }
        return true;
      });

      const onboardingRequests = {
        ...prevState.onboardingRequests,
        items: newOnboarding,
        totalItems: newOnboarding.length,
      };
      return {
        onboardingRequests,
      };
    });
  }

  deleteOnboarding = async (onboardingId) => {
    try {
      this.props.showLoadingScreen("saving data...");

      await deleteOnboardingRequest(onboardingId);

      this.updateOnboardingLists(onboardingId);

      this.props.hideLoadingScreen();
    } catch (error) {
      this.props.showErrorDialog();
      this.props.hideLoadingScreen();
    }
  };

  approveOnboarding = async (onboardingId, reason) => {
    try {
      this.props.showLoadingScreen("saving data...");

      await approveOnboardingRequest(onboardingId, reason);

      // will change this back after user roles are in effect
      // this.updateOnboardingLists(onboardingId);
      await this.loadOnboardingRequests();

      this.props.hideLoadingScreen();
    } catch (error) {
      this.props.showErrorDialog();
      this.props.hideLoadingScreen();
    }
  };

  declineOnboarding = async (onboardingId, reason) => {
    try {
      this.props.showLoadingScreen("saving data...");

      await declineOnboardingRequest(onboardingId, reason);

      // this.updateOnboardingLists(onboardingId);
      // will change this back after user roles are in effect
      await this.loadOnboardingRequests();

      this.props.hideLoadingScreen();
    } catch (error) {
      this.props.showErrorDialog();
      this.props.hideLoadingScreen();
    }
  };

  // Suspends an Onboarding request, and saves the reason.
  suspendOnboarding = async (onboardingId, reason) => {
    try {
      this.props.showLoadingScreen("saving data...");

      await suspendOnboardingRequest(onboardingId, reason);

      // Leaving this comment here (in case it is relevant to this function):
      // this.updateOnboardingLists(onboardingId);
      // will change this back after user roles are in effect
      await this.loadOnboardingRequests();

      this.props.hideLoadingScreen();
    } catch (error) {
      this.props.showErrorDialog();
      this.props.hideLoadingScreen();
    }
  };

  sendOnboardingEmail = async (onboardingId) => {
    try {
      this.props.showLoadingScreen("sending email...");

      await sendOnboardingRequestEmail(onboardingId);

      this.props.hideLoadingScreen();
    } catch (error) {
      this.props.showErrorDialog();
      this.props.hideLoadingScreen();
    }
  };

  retractOnboarding = async (onboardingId) => {
    try {
      this.props.showLoadingScreen("saving data...");

      await retractOnboardingRequest(onboardingId);

      // this.updateOnboardingLists(onboardingId);
      // will change this back after user roles are in effect
      await this.loadOnboardingRequests();

      this.props.hideLoadingScreen();
    } catch (error) {
      this.props.showErrorDialog();
      this.props.hideLoadingScreen();
    }
  };

  acceptOnboarding = async (onboardingId, reason) => {
    try {
      this.props.showLoadingScreen("saving data...");

      await acceptOnboardingRequest(onboardingId, reason);

      // this.updateOnboardingLists(onboardingId);
      // will change this back after user roles are in effect
      await this.loadOnboardingRequests();

      this.props.hideLoadingScreen();
    } catch (error) {
      this.props.showErrorDialog();
      this.props.hideLoadingScreen();
    }
  };

  preliminiaryAcceptOnboarding = async (onboardingId) => {
    try {
      this.props.showLoadingScreen("saving data...");
      await updateOnboardingRequestDelegationStatus(onboardingId, onboardingDelegationStatus.Accepted);
      await this.loadOnboardingRequests();

      this.props.hideLoadingScreen();
    } catch (error) {
      this.props.showErrorDialog();
      this.props.hideLoadingScreen();
    }
  };

  preliminiaryDeclineOnboarding = async (onboardingId, reason) => {
    try {
      this.props.showLoadingScreen("saving data...");
      await updateOnboardingRequestDelegationStatus(onboardingId, onboardingDelegationStatus.Declined, reason);
      await this.loadOnboardingRequests();

      this.props.hideLoadingScreen();
    } catch (error) {
      this.props.showErrorDialog();
      this.props.hideLoadingScreen();
    }
  };

  addComment = async (onboardingId, comment) => {
    try {
      this.props.showLoadingScreen("saving data...");
      await addComment({
        onboardingRequestId: onboardingId,
        comment,
      });
      await this.loadOnboardingRequests();

      this.props.hideLoadingScreen();
    } catch (error) {
      this.props.showErrorDialog();
      this.props.hideLoadingScreen();
    }
  };

  toggleViewMoreModal = (company) => {
    this.setState((prevState) => ({
      showViewMoreModal: !prevState.showViewMoreModal,
      company,
    }));
  };

  toggleAgreementsModal = (company) => {
    this.setState((prevState) => ({
      showAgreementsModal: !prevState.showAgreementsModal,
      company,
    }));
  };

  toggleMessageModal = () => {
    this.setState((prevState) => ({
      showMessageModal: !prevState.showMessageModal,
    }));
  };

  toggleCommentModal = (onboardingRequestId) => {
    this.setState((prevState) => ({
      showOnboardingApprovalCommentModal: !prevState.showOnboardingApprovalCommentModal,
      onboardingRequestId,
    }));
  };

  toggleAddCommentModal = (onboardingRequestId) => {
    this.setState((prevState) => ({
      showAddCommentModal: !prevState.showAddCommentModal,
      onboardingRequestId,
    }));
  };

  // state toggle methods
  toggleStateProp = (currentItemForAction = null, statePropName = null) => {
    if (statePropName != null) {
      this.setState((prevState) => ({
        [statePropName]: !prevState[statePropName],
        currentItemForAction: currentItemForAction || -1,
      }));
    }
  };

  toggleDeleteConfirm = (currentItemForAction = null) => {
    this.toggleStateProp(currentItemForAction, "showDeleteConfirm");
  };

  toggleApproveConfirm = (currentItemForAction = null) => {
    this.toggleStateProp(currentItemForAction, "showApproveConfirm");
  };

  toggleApproveDelegateConfirm = (currentItemForAction = null) => {
    this.toggleStateProp(currentItemForAction, "showApproveDelegatedReviewConfirm");
  };

  toggleUpdateVendorNumberModal = (currentItemForAction = null) => {
    this.toggleStateProp(currentItemForAction, "showUpdateVendorNumberModal");
  };

  toggleDelegateApprovalModal = (currentItemForAction = null) => {
    this.toggleStateProp(currentItemForAction, "showDelegateApprovalModal");
  };

  toggleDeclineConfirm = (currentItemForAction = null) => {
    this.toggleStateProp(currentItemForAction, "showDeclineConfirm");
  };

  toggleDeclineDelegateConfirm = (currentItemForAction = null) => {
    this.toggleStateProp(currentItemForAction, "showDeclineDelegatedReviewConfirm");
  };

  // Display or hide the Suspend Opportunity modal
  toggleSuspendConfirm = (currentItemForAction = null) => {
    this.toggleStateProp(currentItemForAction, "showSuspendConfirm");
  };

  toggleRetractConfirm = (currentItemForAction = null) => {
    this.toggleStateProp(currentItemForAction, "showRetractConfirm");
  };

  toggleAcceptWithExceptionConfirm = (currentItemForAction = null) => {
    this.toggleStateProp(currentItemForAction, "showAcceptWithExceptionConfirm");
  };

  toggleComplianceDeclineConfirm = (currentItemForAction = null) => {
    this.toggleStateProp(currentItemForAction, "showComplianceDeclineConfirm");
  };

  toggleSendEmailConfirm = (currentItemForAction = null) => {
    this.toggleStateProp(currentItemForAction, "showSendEmailConfirm");
  };

  toggleAddComment = (currentItemForAction = null) => {
    this.toggleStateProp(currentItemForAction, "showAddCommentModal");
  };

  async onNewOnboardingRequestHandler() {
    this.props.history.push(`${this.props.location.pathname}/new`);
  }

  // eslint-disable-next-line class-methods-use-this
  getOboardingStatusDisplay(item) {
    if (item.onboardingStatus === onboardingStatuses.pendingApproval2 && item.delegationStatus > onboardingDelegationStatus.None) {
      let userRoleName = this.props.roles.find((x) => x.roleId === item.approval2DelegatedRoleId)?.display;
      if (userRoleName.length > 23) { userRoleName = `${userRoleName.substring(0, 20)}...`; }
      return (
        <div>
          {onboardingDelegationStatus.toString(item.delegationStatus, userRoleName)}
          {
            item.delegationStatus === onboardingDelegationStatus.Declined && (
              <HelpIcon tooltipMessage={`Reason: ${item?.preliminaryDeclinedReason ?? "N/A"}`} />
            )
          }
        </div>
      );
    }

    return onboardingStatuses.toString(
      item.onboardingStatus
    );
  }

  loadOnboardingRequests = async () => {
    this.setState({
      dataLoading: true,
    });
    try {
      const dateFilter = this.state.filterValues[0];
      let startDate = null;
      let endDate = null;
      if (dateFilter != null) {
        startDate = dateFilter[0];
        endDate = dateFilter[1];
      }

      const businessUnitIds = this.state.filterValues[1] ?? [];
      const overallComplianceStatuses = this.state.filterValues[2] ?? [];
      const { items, totalItems } = await getOnboardingRequests({
        limit: this.state.limit,
        offset: this.state.offset,
        businessUnitIds: businessUnitIds.length > 0 ? businessUnitIds : undefined,
        startDate: startDate ?? undefined,
        endDate: endDate ?? undefined,
        registeredName: this.state.searchTerm ?? undefined,
        overallComplianceStatuses: overallComplianceStatuses.length > 0
          ? overallComplianceStatuses
          : undefined,
        newestFirst: this.state.sortOption === sortingValues.newestFirst,
        onboardingRequestSearchFilter: this.state.filterOption,
      });

      this.setState({
        dataLoading: false,
      });

      this.setState((prevState, prevProps) => {
        // Convert enum to string value
        const formattedItems = items.map((item) => ({
          ...item,
          onboardingStatusDisplay: this.getOboardingStatusDisplay(item),
          dateRequestedDisplay:
            item.onboardingStatus === onboardingStatuses.New
              ? "N/A"
              : formatDate(item.dateRequested),
          businessUnit:
            (item.businessUnitIds != null && item.businessUnitIds.length > 0)
              ? item.businessUnitIds.map((businessUnitId) => (
                getStaticValue(businessUnitId, prevProps.businessUnits)
              )).join(", ")
              : "Not set",
          generalEmail: (
            item.generalEmail ?? "N/A"
          ),
          subcategories:
            (item.supplierSubCategoryIds != null && item.supplierSubCategoryIds.length > 0)
              ? createProductServiceSummary(
                item.supplierSubCategoryIds, prevProps.supplierCategories
              )
                ?.map((iSum, i) => (
                  <span key={i}>
                    {i !== 0
                      && (
                        <span className="px-2">
                          |
                        </span>
                      )}
                    <span>
                      {`${iSum.heading}: ${iSum.items.join(", ")}`}
                    </span>
                  </span>
                ))
              : "Not set",
          id: item.onboardingId,
        }));

        const onboardingRequests = {
          items: formattedItems,
          totalItems,
          limit: prevState.limit,
          offset: prevState.offset,
        };

        return {
          onboardingRequests,
        };
      });
    } catch (error) {
      this.props.showErrorDialog();
      throw error;
    }
  };

  searchTermChangeHandler(searchTerm) {
    this.setState({
      searchTerm,
    }, async () => {
      await this.loadOnboardingRequests();
    });
  }

  onSearch = async () => {
    await this.loadOnboardingRequests();
  }

  onClear() {
    this.setState(
      {
        searchTerm: "",
        filterValues: [],
      },
      () => {
        this.onSearch();
      }
    );
  }

  onSortChangeHandler(newSortOption) {
    this.setState({
      sortOption: newSortOption,
    }, async () => {
      await this.loadOnboardingRequests();
    });
  }

  onStatusFilterChangeHandler(newStatusFilterOption) {
    this.setState({
      filterOption: newStatusFilterOption,
    }, async () => {
      await this.loadOnboardingRequests();
    });
  }

  toggleUpdatePaymentTermsModal(companyId) {
    this.setState((prevState) => ({
      ...prevState,
      showUpdatePaymentTermsModal: !prevState.showUpdatePaymentTermsModal,
      companyIdToPerformAction: companyId,
    }));
  }

  toggleUpdateBulkEmailModal(onboardingRequestId) {
    this.setState((prevState) => ({
      ...prevState,
      showUpdateBulkEmailModal: !prevState.showUpdateBulkEmailModal,
      onboardingRequestId,
    }));
  }

  async componentDidMount() {
    const loadContractTiers = async () => {
      // load contracting tiers
      if (this.props.contractTiers == null || this.props.contractTiers.length === 0) {
        try {
          const contractTiers = await getContractTiers();
          const formattedContractTiers = contractTiers.map(
            (c) => ({ ...c, value: c.id, display: c.name })
          );
          this.props.setContractTiers(formattedContractTiers);
        } catch {
          this.props.setContractTiers([]);
        }
      }
    };
    loadContractTiers();
    await this.loadOnboardingRequests();
    const onboardingConfig = await getOnboardingConfiguration();
    this.setState({
      manullyAssignVendorNumber: onboardingConfig?.vendorNumberOrigin === VendorNumberOrigin.ManualAssignment,
    });
  }

  render() {
    const supplierChecks = [
      {
        value: overallComplianceStatus.Outstanding,
        display: overallComplianceStatusToString(overallComplianceStatus.Outstanding),
      }, {
        value: overallComplianceStatus.Success,
        display: overallComplianceStatusToString(overallComplianceStatus.Success),
      }, {
        value: overallComplianceStatus.Failure,
        display: overallComplianceStatusToString(overallComplianceStatus.Failure),
      },
    ];

    const filters = [
      {
        filterName: "Date Requested",
        propName: "dateRequested",
        filterType: FilterTypes.DateRangeInput,
      },
      {
        filterName: "Compliance Management Status",
        propName: "supplierChecksStatus",
        options: supplierChecks,
        filterType: FilterTypes.MultiSelectDropDown,
      },
    ];

    if (this.props.businessUnits.length > 0) {
      filters.splice(1, 0, {
        filterName: "Business Unit",
        propName: "businessUnitId",
        options: this.props.businessUnits.filter(
          (businessUnit) => this.props.userBusinessUnitIds.includes(businessUnit.id)
        ),
        filterType: FilterTypes.MultiSelectDropDown,
      });
    }

    const { tableConfig } = this;
    tableConfig.sortOptions.selectedSortOption = this.state.sortOption;
    tableConfig.statusFilterOptions.selectedStatusFilterOption = this.state.filterOption;
    tableConfig.statusFilterOptions.options = tableConfig.statusFilterOptions
      .options
      .filter((x) => (
        // Filter delegate option
        ((x.value === onboardingFilterOptions.PendingDelegatedReview
          || x.value === onboardingFilterOptions.DelegatedApproved
          || x.value === onboardingFilterOptions.DelegatedDeclined)
            && (hasFeature(this.props.allowedFeatures, SystemFeatures.ApproveDelegatedOnboardingRequestApproval2)
              || hasFeature(this.props.allowedFeatures, SystemFeatures.DelegateOnboardingRequestApproval2))
        )
        // Filter subscription tier
        || ((x.value === onboardingFilterOptions.New
          || x.value === onboardingFilterOptions.AwaitingSupplierInvitationAcceptance
          || x.value === onboardingFilterOptions.AwaitingSupplierRegistration
          || x.value === onboardingFilterOptions.ProfileIncomplete
          || x.value === onboardingFilterOptions.AwaitingComplianceChecks
          || x.value === onboardingFilterOptions.ComplianceFailure
          || x.value === onboardingFilterOptions.PendingApproval1
          || x.value === onboardingFilterOptions.PendingApproval2)
          && hasFeature(this.props.allowedFeatures, SystemFeatures.OnboardingCreateRequest))
        // Filter contract agreements
        // || (x.value === onboardingFilterOptions.AwaitingContractApproval && this.props.contractTiers?.length > 0)
        // Include defaults
        || x.value === onboardingFilterOptions.AwaitingContractApproval
        || x.value === onboardingFilterOptions.Active
        || x.value === onboardingFilterOptions.All
        || x.value === onboardingFilterOptions.Declined
        || x.value === onboardingFilterOptions.Suspended
        || x.value === onboardingFilterOptions.Completed));

    if (this.props.contractTiers?.length <= 0) {
      tableConfig.statusFilterOptions.options = tableConfig.statusFilterOptions
        .options
        .filter((x) => (x.value !== onboardingFilterOptions.AwaitingContractApproval));
    }

    return (
      <section id="isuppli-supplier-search" className="container-fluid">
        <StaticCompanyData
          optionsToLoad={[
            StaticCompanyDataTypes.businessUnit,
            StaticCompanyDataTypes.supplierCategory,
            StaticCompanyDataTypes.roles,
            StaticCompanyDataTypes.paymentTerms,
          ]}
        />
        <StaticData
          optionsToLoad={[
            StaticDataTypes.Municipality,
          ]}
        />

        {this.state.showViewMoreModal && (
          <SupplierDetailsModal
            company={this.state.company}
            onCloseCallback={() => this.toggleViewMoreModal(null)}
          />
        )}

        {this.state.showAgreementsModal && (
          <AgreementsModal
            supplierCompanyId={this.state.company.companyId}
            supplierCompanyRegisteredName={this.state.company.registeredName}
            onClose={() => this.toggleAgreementsModal(null)}
          />
        )}

        <Loading />

        {this.state.showDeleteConfirm && (
          <ConfirmationModal
            hasCancel
            heading="DELETE ONBOARDING REQUEST"
            description="Are you sure you want to delete this onboarding request?"
            proceedButtonText="Delete"
            onToggleModal={this.toggleDeleteConfirm}
            onProceedClick={() => this.deleteOnboarding(this.state.currentItemForAction)}
          />
        )}

        {this.state.showSupplierStatus && (
          <SupplierCheckModal
            onClose={this.toggleSupplierStatus}
            company={this.state.supplierStatusCompany}
          />
        )}

        {this.state.showApproveConfirm && (
          <ConfirmationModal
            hasCancel
            heading="APPROVE ONBOARDING REQUEST"
            description="Are you sure you want to approve this onboarding request?"
            proceedButtonText="Approve"
            onToggleModal={this.toggleApproveConfirm}
            onProceedClick={
              (reason) => this.approveOnboarding(this.state.currentItemForAction, reason)
            }
            captureType="text"
            capturePlaceholder="Enter a reason for approving the request"
          />
        )}

        {this.state.showApproveDelegatedReviewConfirm && (
          <ConfirmationModal
            hasCancel
            heading="PRELIMINARILY APPROVE ONBOARDING REQUEST"
            description="Are you sure you want to approve this onboarding request?"
            proceedButtonText="Approve"
            onToggleModal={this.toggleApproveDelegateConfirm}
            onProceedClick={() => this.preliminiaryAcceptOnboarding(this.state.currentItemForAction)}
          />
        )}

        {this.state.showDeclineConfirm && (
          <ConfirmationModal
            hasCancel
            heading="DECLINE ONBOARDING REQUEST"
            description="Are you sure you want to decline this onboarding request?"
            proceedButtonText="Decline"
            onToggleModal={this.toggleDeclineConfirm}
            onProceedClick={
              (reason) => this.declineOnboarding(this.state.currentItemForAction, reason)
            }
            captureType="text"
            capturePlaceholder="Enter a reason for declining the request"
          />
        )}

        {this.state.showDeclineDelegatedReviewConfirm && (
          <ConfirmationModal
            hasCancel
            heading="PRELIMINARILY DECLINE ONBOARDING REQUEST"
            description="Are you sure you want to decline this onboarding request?"
            proceedButtonText="Decline"
            onToggleModal={this.toggleDeclineDelegateConfirm}
            onProceedClick={
              (reason) => this.preliminiaryDeclineOnboarding(this.state.currentItemForAction, reason)
            }
            captureType="text"
            capturePlaceholder="Enter a reason for declining the request"
          />
        )}

        {this.state.showSuspendConfirm && (
          <ConfirmationModal
            hasCancel
            heading="SUSPEND ONBOARDING REQUEST"
            description="Are you sure you want to suspend this onboarding request?"
            proceedButtonText="Suspend"
            onToggleModal={this.toggleSuspendConfirm}
            onProceedClick={
              (reason) => this.suspendOnboarding(this.state.currentItemForAction, reason)
            }
            captureType="text"
            capturePlaceholder="Enter a reason for suspending the request"
          />
        )}

        {this.state.showSendEmailConfirm && (
          <ConfirmationModal
            hasCancel
            heading="RESEND ONBOARDING REQUEST EMAIL"
            description="Are you sure you want to resend the onboarding request email?"
            proceedButtonText="Resend"
            onToggleModal={this.toggleSendEmailConfirm}
            onProceedClick={
              () => this.sendOnboardingEmail(this.state.currentItemForAction)
            }
          />
        )}

        {this.state.showRetractConfirm && (
          <ConfirmationModal
            hasCancel
            heading="RETRACT ONBOARDING REQUEST"
            description="Are you sure you want to retract this onboarding request?"
            proceedButtonText="Confirm"
            onToggleModal={this.toggleRetractConfirm}
            onProceedClick={() => this.retractOnboarding(this.state.currentItemForAction)}
          />
        )}

        {this.state.showMessageModal && (
          <ComposeMessageModal
            sendTo="single"
            recipientDetails={[
              { name: this.state.company.registeredName, id: this.state.company.supplierCompanyId },
            ]}
            onClose={() => this.toggleMessageModal()}
          />
        )}

        {this.state.showAcceptWithExceptionConfirm && (
          <ConfirmationModal
            hasCancel
            heading="ACCEPT ONBOARDING REQUEST WITH EXCEPTION"
            description="Are you sure you want to accept this onboarding request?"
            proceedButtonText="Accept"
            onToggleModal={this.toggleAcceptWithExceptionConfirm}
            onProceedClick={
              (reason) => this.acceptOnboarding(this.state.currentItemForAction, reason)
            }
            captureType="text"
            capturePlaceholder="Enter a reason for accepting the request"
          />
        )}

        {this.state.showComplianceDeclineConfirm && (
          <ConfirmationModal
            hasCancel
            heading="DECLINE ONBOARDING REQUEST"
            description="Are you sure you want to decline this onboarding request?"
            proceedButtonText="Decline"
            onToggleModal={this.toggleComplianceDeclineConfirm}
            onProceedClick={
              (reason) => this.declineOnboarding(this.state.currentItemForAction, reason)
            }
            captureType="text"
            capturePlaceholder="Enter a reason for declining the request"
          />
        )}

        {
          this.state.showUpdateVendorNumberModal && (
            <UpdateVendorNumberModal
              onboardingRequestId={this.state.currentItemForAction}
              onCloseCallback={() => this.toggleUpdateVendorNumberModal()}
              onSubmitCallback={async () => {
                // to-do: update this to load list in front-end
                await this.loadOnboardingRequests();
              }}
            />
          )
        }

        {
          this.state.showDelegateApprovalModal && (
            <DelegateMTOApprovalModal
              onboardingRequestId={this.state.currentItemForAction}
              roleId={
                this.findOnboardingRequest(this.state.currentItemForAction)?.approval2DelegatedRoleId ?? ""
              }
              onCloseCallback={() => this.toggleDelegateApprovalModal()}
              onSubmitCallback={async () => {
                // to-do: update this to load list in front-end
                await this.loadOnboardingRequests();
              }}
            />
          )
        }

        {
          this.state.showOnboardingApprovalCommentModal && (
            <CommentModal
              onboardingRequestId={this.state.onboardingRequestId}
              onClose={() => this.toggleCommentModal()}
            />
          )
        }

        {
          this.state.showAddCommentModal && (
            <ConfirmationModal
              hasCancel
              heading="ADD COMMENT"
              description="Add a comment to this onboarding request"
              proceedButtonText="Comment"
              onToggleModal={this.toggleAddCommentModal} // to-do: update this to toggleAddCommentModal
              onProceedClick={
                (reason) => this.addComment(this.state.currentItemForAction, reason)
              }
              captureType="text"
              capturePlaceholder="Enter a comment here"
            />
          )
        }

        {this.state.showUpdatePaymentTermsModal && (
          <PaymentTermsUpdate
            companyId={this.state.companyIdToPerformAction}
            onCloseCallback={() => this.toggleUpdatePaymentTermsModal()}
          />
        )}

        {this.state.showUpdateBulkEmailModal && (
          <BulkEmailUpdate
            onboardingRequestId={this.state.onboardingRequestId}
            onCloseCallback={() => this.toggleUpdateBulkEmailModal(null)}
            onUpdatedCallback={() => {
              this.toggleUpdateBulkEmailModal(null);
              this.loadOnboardingRequests();
            }}
          />
        )}

        <div className="row">
          <div className="col-10 offset-1">
            <OnboardingSearch
              searchTerm={this.state.searchTerm}
              onSearchTermChange={(searchTerm) => this.setState({ searchTerm })}
              onSearchCallback={() => this.loadOnboardingRequests()}
              onNewOnboardingRequest={() => this.onNewOnboardingRequestHandler()}
            />
            <div className="pb-4" />
            <SearchFiltersContainer
              filterValues={this.state.filterValues}
              buttons={[{
                key: "clear", label: "Clear", onClick: () => this.onClear(), color: "secondary",
              }, {
                key: "search", label: "Search", onClick: () => this.onSearch(), color: "primary",
              }]}
              onChange={(f) => this.setState({ filterValues: f })}
              filters={filters}
            />
            <div className="pb-4" />
            <Table
              onPageChange={(limit, offset) => {
                this.setState({
                  limit,
                  offset,
                }, async () => { await this.loadOnboardingRequests(); });
              }}
              tableConfig={tableConfig}
              data={this.state.onboardingRequests}
              tableStyle="compact"
              title="LATEST ONBOARDING REQUESTS"
              onSortOptionChange={(newSortOption) => this.onSortChangeHandler(newSortOption)}
              onStatusFilterChange={
                (newStatusFilterOption) => this.onStatusFilterChangeHandler(newStatusFilterOption)
              }
              loading={this.state.dataLoading}
            />
          </div>
        </div>
        <div style={{ paddingTop: "111px" }} />
      </section>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OnboardingRequest)
);
