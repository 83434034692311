import React, {
  useRef, useState, Fragment, useEffect,
} from "react";
import UtilButton from "../UtilButton/UtilButton";
import Styles from "./DragDropFileUpload.module.scss";
import { ReactComponent as ExcelLogoSvg } from "./solid_file_excel.svg";
import { ReactComponent as CloudLogoSvg } from "./cloud_upload.svg";
import { ReactComponent as UnknownFileLogoSvg } from "./file-regular.svg";

export const excelFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
export const pdfFileTypes = ["application/pdf", "application/x-pdf", "application/x-bzpdf", "application/x-gzpdf"];

const DragDropFileUpload = ({
  multiple = false, // mutiple files ?
  acceptedFormats,
  labelText = "Drop file(s) here",
  onUpdateFilesCallback,
  onRemoveFileCallback,
}: {
    multiple?: boolean,
    acceptedFormats: ".xlsx" | ".pdf" | undefined,
    labelText?: string
    onUpdateFilesCallback: (input : Array<File> | File) => void,
    onRemoveFileCallback?: (input: File) => void,
}) => {
  const [files, setFiles] = useState<Array<File>>([]);
  const fileInputField = useRef<HTMLInputElement>(null);
  const [dragDetected, setDragDetected] = useState(false);
  const [invalidFileDetected, setInvalidFileDetected] = useState(false);

  const handleDragLeave = () => {
    setDragDetected(false);
  };

  const handleDragOver = () => {
    setDragDetected(true);
  };

  const validateFileType = (file: File) => {
    let result = true;
    switch (acceptedFormats) {
      case ".xlsx":
        result = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
      case ".pdf":
        result = pdfFileTypes.includes(file.type);
        break;
      default:
        result = true;
    }
    return result;
  };

  const handleNewFileUpload = (newFiles: Array<File>) => {
    setInvalidFileDetected(false);
    const invalidFilesFound = newFiles.find((file) => validateFileType(file) === false);
    if (invalidFilesFound) {
      setInvalidFileDetected(true);
      return;
    }

    if (newFiles.length > 0) {
      // add new files or delete based on mutiple input
      if (multiple) {
        setFiles((prevState) => [...prevState, ...newFiles]);
        onUpdateFilesCallback(newFiles);
      } else {
        setFiles([newFiles[0]]);
        onUpdateFilesCallback(newFiles[0]);
      }
    }
  };

  const handleUploadBtnClick = () => {
    if (fileInputField.current != null) {
      fileInputField.current.click();
    }
  };

  const onHandleRemoveFile = (file : File) => {
    multiple
      ? setFiles((prevState) => prevState.filter((f) => f !== file))
      // clear all if multiple
      : setFiles([]);

    if (onRemoveFileCallback != null) {
      onRemoveFileCallback(file);
    }
  };

  const getFileLogoSrc = (fileType: string, className: string) => {
    if (excelFileTypes.includes(fileType)) { return <ExcelLogoSvg className={className} />; }
    return <UnknownFileLogoSvg className={className} />;
  };

  useEffect(() => {
  }, [fileInputField, files, invalidFileDetected]);

  useEffect(() => {
  }, [onHandleRemoveFile]);

  return (
    <Fragment>
      <div className={`text-center ${Styles.dragDropContainer}`}>
        <div className={`${Styles.dragDropBackground} ${dragDetected ? Styles.dragDetected : ""}`}>
          <input
            className={Styles.dragDropFileInput}
            type="file"
            ref={fileInputField}
            onDragLeave={handleDragLeave}
            onDragEnd={handleDragLeave}
            onDrop={handleDragLeave}
            accept={acceptedFormats}
            onDragOver={handleDragOver}
            onChange={(e) => handleNewFileUpload(Array.from(e.target.files ?? []))}
            multiple={multiple}
          />
        </div>
        <div className="m-3 p-4">
          <CloudLogoSvg className="mx-2 text-primary" />
          <b className="mx-2">
            {labelText}
          </b>
          <UtilButton className="mx-2" onClick={handleUploadBtnClick}>BROWSE</UtilButton>
        </div>
      </div>
      {
        invalidFileDetected && (
          <div className="m-2 px-3">
            <b className="text-danger">
              Error: Attempted to upload an invalid file type.
            </b>
          </div>
        )
      }
      <div className="m-2 px-3">
        {files?.map((file, index) => (
          <div className="my-2" key={index}>
            {getFileLogoSrc(file.type, `mx-2 ${Styles.FileIcon} text-secondary`)}
            <a
              key={index}
              href={URL.createObjectURL(file)}
              download={file.name}
            >
              <b>
                {file.name}
              </b>
            </a>
            {" "}
            <a
              key={`remove-${index}`}
              href="/"
              onClick={(e) => {
                e.preventDefault();
                onHandleRemoveFile(file);
              }}
            >
              <b className="text-danger">REMOVE</b>
            </a>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default DragDropFileUpload;
