/* eslint-disable react/no-array-index-key */
import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";

import axios, {
  AxiosRequestConfig, // eslint-disable-line no-unused-vars
  CancelTokenSource, // eslint-disable-line no-unused-vars
} from "axios";

// eslint-disable-next-line no-unused-vars
import { getUnspscInfo, UnspscSegmentWithFamilies } from "../../utils/dataTransform";
import StaticDataTypes from "../../utils/enums/StaticDataTypes";
import { StaticOption } from "../../Hooks/staticData/interfaces/StaticDataOption";// eslint-disable-line no-unused-vars
import { UnspscSegment } from "../../Hooks/staticData/interfaces/UnspscSegment";// eslint-disable-line no-unused-vars
import { UnspscFamily } from "../../Hooks/staticData/interfaces/UnspscFamily";// eslint-disable-line no-unused-vars
import useDebounce from "../../Hooks/useDebounce";
import CheckboxInput from "../CheckboxInput/CheckboxInput";
import ListEmptyState from "../../Containers/ListEmptyState/ListEmptyState";
import ModalPopup from "../../Containers/ModalPopup/ModalPopup";
import FilterInput from "../FilterInput/FilterInput";
import Spinner from "../Spinner/Spinner";
// eslint-disable-next-line no-unused-vars

const GlobalOfferingSelector = ({
  selection,
  maxItems,
  subheading,
  setSelection,
  onCloseCallback,
  onSearchUsnpscFamiliesCallback,
  staticDataCallback,
}: {
  selection: number[];
  maxItems?: number,
  setSelection: (value: number[]) => void;
  onCloseCallback: () => void;
  onSearchUsnpscFamiliesCallback:
  (searchTerm: string, config: AxiosRequestConfig | undefined) => Promise<number[]>;
  staticDataCallback: (optionsToLoad: StaticDataTypes[]) => [StaticOption[][], boolean]
  subheading?: string,
}) => {
  const [internalSelection, setInternalSelection] = useState<number[]>([]);

  const [filterTerm, setFilterTerm] = useState("");
  const [searching, setSearching] = useState(false);
  const [searchResult, setSearchResults] = useState<Array<number> | null>(null);
  const searchCancelToken = useRef<CancelTokenSource>(
    axios.CancelToken.source()
  );

  useEffect(() => {
    setInternalSelection(selection);
  }, [selection]);

  const [[unspscSegments, unspscFamilies], isReady] = staticDataCallback(
    [StaticDataTypes.UnspscSegment, StaticDataTypes.UnspscFamily]
  );

  const [unspscInfo, setUnspscInfo] = useState<Array<UnspscSegmentWithFamilies>>();

  useEffect(() => {
    const info = getUnspscInfo(
      unspscSegments as any as Array<UnspscSegment>,
      unspscFamilies as any as Array<UnspscFamily>
    );
    setUnspscInfo(info);
  }, [unspscSegments, unspscFamilies]);

  const removeSegment = useCallback(
    (item: number) => {
      setInternalSelection((prevState) => prevState.filter((sel) => sel !== item));
    },
    [setInternalSelection]
  );

  const addSegment = useCallback(
    (item: number) => {
      setInternalSelection((prevState) => [...prevState, item]);
    },
    [setInternalSelection]
  );

  const toggleItem = useCallback(
    (item: number, isSelected: boolean) => {
      if (isSelected) {
        removeSegment(item);
      } else {
        addSegment(item);
      }
    },
    [removeSegment, addSegment]
  );

  const onApplyClick = () => {
    setSelection(internalSelection);
    onCloseCallback();
  };

  const [executeSearch] = useDebounce<string>(async (term: string) => {
    // cancel running search
    searchCancelToken.current.cancel();

    setSearching(true);
    if (filterTerm.length === 0) {
      setSearchResults(null);
      setSearching(false);
    } else {
      try {
        searchCancelToken.current = axios.CancelToken.source();
        const result = await onSearchUsnpscFamiliesCallback(term, {
          cancelToken: searchCancelToken.current.token,
        });
        setSearchResults(result);
        setSearching(false);
      } catch (error) {
        if (!axios.isCancel(error)) {
          setSearchResults(null);
          setSearching(false);
        }
      }
    }
  }, 300);

  useEffect(() => {
    // execute search
    executeSearch(filterTerm);
    return () => {
      searchCancelToken.current.cancel();
    };
  }, [filterTerm, executeSearch]);

  const selectionList = useMemo(() => {
    const optionsToShow = unspscInfo
      ?.map((segment, i) => {
        const optionRows: JSX.Element[][] = [];
        let index = -1;
        segment.families
          .filter((family) => (searchResult == null ? true : searchResult.includes(family.value)))
          .forEach((family, j: number) => {
            if (j % 3 === 0) {
              index += 1;
              optionRows.push([]);
            }
            const isSelected = !!internalSelection?.find(
              (s) => s === family.value
            );
            optionRows[index].push(
              <div key={j} className="col-12 col-sm-4">
                <CheckboxInput
                  disabled={maxItems != null && internalSelection.length >= maxItems
                     && internalSelection.find((val) => val === family.value) == null}
                  value={isSelected}
                  label={family.display}
                  onChange={() => {
                    toggleItem(family.value, isSelected);
                  }}
                />
              </div>
            );
          });
        const options = optionRows.map((row, k) => (
          <div key={k} className="row" style={{ paddingBottom: "17px" }}>
            {row}
          </div>
        ));
        return options.length > 0 ? (
          <section key={i}>
            <div
              className="bg-primary text-white text-center"
              style={{ borderRadius: "7px", marginBottom: "17px" }}
            >
              <h2 className="SelectionSummary">{segment.display}</h2>
            </div>
            <div>{options}</div>
          </section>
        ) : null;
      })
      .filter((c) => c != null);

    if (optionsToShow == null || optionsToShow?.length === 0) {
      return (
        <ListEmptyState heading="No results found.">
          <p>
            No product/service categories found matching your search request...
          </p>
        </ListEmptyState>
      );
    }
    return optionsToShow;
  }, [searchResult, internalSelection, unspscInfo, toggleItem]);

  const onFilter = (term: string) => {
    setFilterTerm(term);
  };

  const onFilterChange = (ev: React.FormEvent<HTMLInputElement>) => {
    setFilterTerm(ev.currentTarget.value);
  };

  return (
    <Fragment>
      <ModalPopup
        stickyButtons
        onClose={() => onCloseCallback()}
        hasCancel
        heading="SELECT PRODUCT / SERVICE CATEGORIES"
        subheading={subheading ?? `Please indicate your primary product and/or service offering only.
            Keep in mind that buyers may be wary of a supplier who claims to do everything.`}
        size="large"
        buttons={[
          {
            key: "ok",
            label: "Save",
            onClick: () => {
              onApplyClick();
            },
            color: "primary",
          },
        ]}
      >
        <Fragment>
          <p className="mx-2">
            B1LINK is authorized to adopt The United Nations Standard Products
            and Services Code (UNSPSC).
          </p>
          <div className="row pt-3 px-2">
            <div className="col-12">
              <FilterInput
                onFilterCallback={onFilter}
                onChangeCallback={onFilterChange}
                placeholder="search for products or services"
              />
            </div>
          </div>
          <div className="row pt-3 px-2">
            <div className="col-12">
              {isReady && !searching ? selectionList : <Spinner inline />}
            </div>
          </div>
        </Fragment>
      </ModalPopup>
    </Fragment>
  );
};

export default GlobalOfferingSelector;
