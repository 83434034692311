import React from "react";
// eslint-disable-next-line no-unused-vars
import { ClientSupplierCategoryMessage } from "../../../http/SupplierCategory/Models/Models";

const ClientOfferingSummaryDisplay = ({
  clientProductOfferings,
}: {
    clientProductOfferings: Array<ClientSupplierCategoryMessage>,
}) => (
  <div>
    {
        clientProductOfferings?.map((offering) => (
          <div key={offering.id} className="ml-4">
            <h2>{offering.name}</h2>
            <ul>
              {
                offering.subCategories
                  .map((subCategory) => <li key={subCategory.id}>{subCategory.name}</li>)
              }
            </ul>
          </div>
        ))
    }
    {clientProductOfferings.length === 0 && <label>No products or services selected</label>}
  </div>
);

export default ClientOfferingSummaryDisplay;
