import React from "react";

import {
  PageHeading, UtilButton, SearchInput, SystemFeatures, useSystemFeatureCheck,
} from "isuppli-react-components";
import { useLocation, useHistory } from "react-router";
import navMenuItems from "../../Util/menuItems";
import SupplierImportModal from "../../Containers/SupplierImportModal/SupplierImportModal";

function OnboardingSearch(props : any) {
  const featureCheck = useSystemFeatureCheck();

  // supplier modal is toggle via URL parameters
  const location = useLocation();
  const history = useHistory();
  const urlParams = new URLSearchParams(location.search);
  const showSupplierImportModal = urlParams.get("supplierImportModal") != null;
  const setShowSupplierImportModal = (show : boolean = false) => {
    const paramVal = show ? "1" : "0";
    if (show) {
      urlParams.append("supplierImportModal", paramVal);
    } else {
      urlParams.delete("supplierImportModal");
    }
    history.replace({
      ...location,
      search: decodeURIComponent(urlParams.toString()),
    });
  };

  return (
    <div>
      <PageHeading
        heading="Onboarding"
        alertText="Manage your onboarding requests here."
        menuItems={navMenuItems}
      />

      <SearchInput
        searchTerm={props.searchTerm}
        onChange={props.onSearchTermChange}
        onSearch={props.onSearchCallback}
        searchPlaceholder="Search by company name"
      />

      <div className="justify-end">
        <UtilButton
          color="secondary"
          location="bottom"
          onClick={() => {
            setShowSupplierImportModal(true);
          }}
          size="small"
          disabled={!featureCheck(SystemFeatures.SupplierImport)}
          feature={SystemFeatures.SupplierImport}
        >
          UPLOAD  MY SUPPLIERS
        </UtilButton>

        <div className="left-margin-10px" />

        <UtilButton
          color="secondary"
          location="bottom"
          onClick={() => props.onNewOnboardingRequest()}
          size="small"
          disabled={!featureCheck(SystemFeatures.OnboardingNewCompany)}
          feature={SystemFeatures.OnboardingNewCompany}
        >
          ONBOARD NEW COMPANY
        </UtilButton>

        <UtilButton
          color="primary"
          location="bottom"
          onClick={() => {}}
          size="small"
          disabled={!featureCheck(SystemFeatures.OnboardingCreateRequest)}
          feature={SystemFeatures.OnboardingCreateRequest}
        >
          COMPLIANCE MANAGEMENT
        </UtilButton>

        {
          showSupplierImportModal
          && (
            <SupplierImportModal
              onCloseModal={() => {
                setShowSupplierImportModal(false);
              }}
            />
          )
        }

      </div>
    </div>
  );
}

export default OnboardingSearch;
