import React, { useState, useRef } from "react";
import {
  ModalPopup, TextInput, Spinner, Form, FormRef, // eslint-disable-line no-unused-vars
} from "isuppli-react-components";
import { addSubAccount, editSubAccount } from "../../http/posts";
import useShowError from "../../Hooks/useShowError";

export enum SubAccountOperation {
  Add = 0, // eslint-disable-line no-unused-vars
  Update = 1, // eslint-disable-line no-unused-vars
}

const SubAccount = (
  {
    operation,
    companyId,
    originalTradingName = "",
    setNewTradingName,
    onClose,
  }:
    {
      operation: SubAccountOperation,
      companyId?: number,
      originalTradingName?: string,
      setNewTradingName: (newVal: string) => void,
      onClose: () => void,
    }
) => {
  const formRef = useRef<FormRef>(null);
  const [loading, setLoading] = useState(false);
  const [tradingName, setTradingName] = useState(originalTradingName ?? "");
  const showError = useShowError();

  const addSubAccountHandler = async () => {
    if (!(formRef.current?.isValid() ?? false)) {
      formRef.current?.showValidation();
      return;
    }

    setLoading(true);
    try {
      // build message
      const message = {
        parentCompanyId: companyId,
        tradingName,
      };

      // api call
      await addSubAccount(message);
    } catch (error) {
      showError();
    }
    setLoading(false);
    onClose();
  };

  const editSubAccountHandler = async () => {
    if (!(formRef.current?.isValid() ?? false)) {
      formRef.current?.showValidation();
      return;
    }

    setLoading(true);
    try {
      // build message
      const message = {
        tradingName,
      };

      // api call
      await editSubAccount(companyId, message);
    } catch (error) {
      showError();
    }
    // update trading name in search results
    setNewTradingName(tradingName);
    setLoading(false);
    onClose();
  };

  return (
    <ModalPopup
      onClose={onClose}
      hasCancel
      heading={operation === SubAccountOperation.Update
        ? "EDIT TRADING NAME"
        : "ADD SUB-ACCOUNT"}
      subheading="Please enter the trading name of the sub-account"
      size="medium"
      buttons={[{
        key: "ok",
        label: "Save",
        onClick: () => {
          operation === SubAccountOperation.Update
            ? editSubAccountHandler()
            : addSubAccountHandler();
        },
        color: "primary",
      }]}
    >
      <div className="row pt-3 px-2">
        {loading && <Spinner />}
        <Form className="col-12" ref={formRef} openValidationIn={document.getElementById("validation-root") ?? undefined}>
          <TextInput
            placeholder="Trading name"
            label=""
            value={tradingName}
            onChange={(value) => setTradingName(value)}
            required
            minLength={1}
            validationMessage="Please enter a valid trading name"
          />
        </Form>
      </div>
    </ModalPopup>
  );
};

export default SubAccount;
