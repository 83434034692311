import React from "react";
import
// eslint-disable-next-line no-unused-vars
DataFeatures,
{ hasDataFeature, hasSomeDataFeature } from "../utils/enums/DataFeature";

let allowedDataFeatures = 0;

export const setupAllowedDataFeatures = (newAllowedDataFeatures: DataFeatures) => {
  allowedDataFeatures = newAllowedDataFeatures;
};

export const useDataFeatureCheck = (strict: boolean = false) => (
  feature: DataFeatures
) => (strict ? hasDataFeature(allowedDataFeatures, feature)
  : hasSomeDataFeature(allowedDataFeatures, feature));

// Higher order component for legacy components (class based components)
interface DataFeaturesProps {
  dataFeatureCheck: (feature: DataFeatures) => boolean
}

export const withDataFeatureCheck = (
  WrappedComponent: React.ComponentType<DataFeaturesProps>
  // eslint-disable-next-line react/prefer-stateless-function
) => class extends React.Component {
  render() {
    return (
      <WrappedComponent
        dataFeatureCheck={
          (feature: DataFeatures) => hasSomeDataFeature(allowedDataFeatures, feature)
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...this.props}
      />
    );
  }
};
