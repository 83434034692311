import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isMandatory, MTOMandatoryField } from "isuppli-react-components";
import Loading from "../Loading";
import OnboardingWizard from "../../Containers/Marketplace/OnboardingWizard/OnboardingWizard";

import InfoPopup from "../../Containers/InfoPopup";

import TextArea from "../../Controls/TextArea";

import {
  setOnboardingRequestObject,
  onOnboardingInputChange,
} from "../../Store/onboardingRequestActions";
import {
  setStoreProperty,
  showLoadingScreen,
  hideLoadingScreen,
  hideInfoPopup,
  showInfoPopup,
  showErrorDialog,
} from "../../Store/actions";
import {
  saveOnboardingRequest,
} from "../../http/posts";
import validateStep from "../../Validation/onSubmitValidations";
import { requiredFieldsMTP5 } from "../../Validation/validation";
import SaveAndContinueConfirm from "../../Containers/Supplier/SaveAndContinueConfirm";
import SuspensionMessageBanner from "./SuspensionMessageBanner/SuspensionMessageBanner";

const mapStateToProps = (state) => ({
  data: state.onboardingRequest,
});

const mapDispatchToProps = (dispatch) => ({
  setMTPLastStep: (onboardingLastStepCompleted) => {
    dispatch(
      setStoreProperty(
        "onboardingLastStepCompleted",
        onboardingLastStepCompleted
      )
    );
  },
  onOnboardingInputChange: (ev) => {
    dispatch(onOnboardingInputChange(ev));
  },
  showLoadingScreen: (msg) => {
    dispatch(showLoadingScreen(msg));
  },
  hideLoadingScreen: () => {
    dispatch(hideLoadingScreen());
  },
  showInfoMsg: (heading, subheading, msgs) => {
    dispatch(showInfoPopup(heading, subheading, msgs));
  },
  hideInfoMsg: () => {
    dispatch(hideInfoPopup());
  },
  updateOnboardingRequest: (onbordingRequest) => {
    dispatch(setOnboardingRequestObject(onbordingRequest));
  },
  showErrorDialog: () => {
    dispatch(showErrorDialog(true));
  },
});

class MTPRestrictions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msgs: [],
      showConfirmMsg: false,
    };
  }

  performSave = async (onboardingRequest, saveOnly) => {
    try {
      this.props.showLoadingScreen("saving data...");

      const savedOnboardingRequest = await saveOnboardingRequest(
        onboardingRequest,
        saveOnly
      );
      this.props.updateOnboardingRequest(savedOnboardingRequest);

      this.props.hideLoadingScreen();
    } catch (error) {
      this.props.showErrorDialog();
      this.props.hideLoadingScreen();
      throw error;
    }
  };

  onNextClick = async (nextUrl) => {
    const validation = validateStep(this.props.data, requiredFieldsMTP5);

    // Overrides validation if not mandatory
    const mtoMandatoryField = this.props.data.manualSupplier ? this.props.data.manualMTOMandatoryField : this.props.data.mtoMandatoryField;
    if (!isMandatory(mtoMandatoryField, MTOMandatoryField.Restrictions)) {
      validation.isValid = true;
      validation.msgs = [];
    }

    this.setState({
      msgs: validation.msgs,
    });
    if (validation.isValid) {
      const onboardingRequest = {
        ...this.props.data,
        lastStepCompleted:
          this.props.stepNumber > this.props.data.lastStepCompleted
            ? this.props.stepNumber
            : this.props.data.lastStepCompleted,
      };
      await this.performSave(onboardingRequest, true);
      // next step
      if (nextUrl.length > 0) {
        this.props.history.push(nextUrl);
      }
    } else {
      window.scrollTo({ top: 0 });
      this.props.showInfoMsg(
        "This page contains missing information.",
        "To continue please amend/complete the following:",
        Object.keys(validation.msgs).map((key) => validation.msgs[key])
      );
    }
  };

  toggleConfirmSaveAndContinue = () => {
    this.setState((prevState) => ({
      showConfirmMsg: !prevState.showConfirmMsg,
    }));
  };

  saveAndContinueLater = async () => {
    await this.performSave(this.props.data, true);
    this.props.history.length = 1;
    this.props.history.replace("/myoffice/onboardingrequest");
  };

  componentDidMount() {
    this.props.hideInfoMsg();
  }

  render() {
    return (
      <section id="isuppli-marketplace-restrictions">
        {this.state.showConfirmMsg && (
          <SaveAndContinueConfirm
            toggleModalCallback={this.toggleConfirmSaveAndContinue}
            saveAndContinueCallback={this.saveAndContinueLater}
          />
        )}
        <Loading />

        <InfoPopup />

        <OnboardingWizard
          isManualSupplier={this.props.data.manualSupplier}
          currentStep={this.props.stepNumber - 1}
          lastStepCompleted={this.props.data.lastStepCompleted}
          onClickSaveAndContinue={this.toggleConfirmSaveAndContinue}
          onClickNext={this.onNextClick}
          onboardingId={this.props.data.onboardingId}
        >

          <div className="padding-lr-10vw">
            {this.props.data.suspensionReason
              && (
                <SuspensionMessageBanner suspensionReason={this.props.data.suspensionReason} />
              )}
            <div className="row top-margin-90px">
              <div className="col-12">
                <div>
                  <h3 className="margin-null">{`STEP 0${this.props.stepNumber}`}</h3>
                </div>
                <div>
                  <h1>RESTRICTIONS</h1>
                </div>
              </div>

              <p>
                {isMandatory(this.props.data.mtoMandatoryField, MTOMandatoryField.Restrictions) && "*required"}
              </p>

              <div
                className="col-12 justify-center flex-column"
                style={{ paddingTop: "20px" }}
              >
                <h3>
                  Agrimark has business operations in both South Africa and Namibia.
                  Please provide details of any legislative or contractual restrictions impacting
                  the distribution and/or sale of the products in any such territories.
                </h3>
              </div>
            </div>

            <div className="row" style={{ paddingTop: "36px" }} />
          </div>

          <section className="padding-lr-10vw">
            <TextArea
              label="What legislative or contractual restrictions?"
              name="restrictions"
              value={this.props.data.restrictions}
              additional={this.state.msgs.restrictions}
              onChangeCallback={this.props.onOnboardingInputChange}
            />
          </section>

        </OnboardingWizard>

      </section>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MTPRestrictions)
);
