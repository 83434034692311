export const SET_REFERENCES = "SET_REFERENCES";

export const SET_REGISTRATION_INFO = "SET_REGISTRATION_INFO";

export const SET_SUPPLIER_CATEGORIES = "SET_SUPPLIER_CATEGORIES";

export const SET_UNSPSC_FAMILIES = "SET_UNSPSC_FAMILIES";

export const SET_POSTAL_ADDRESS = "SET_POSTAL_ADDRESS";

export const SET_SHAREHOLDER_DETAILS = "SET_SHAREHOLDER_DETAILS";

export const ON_REGISTRATION_INPUT_CHANGE = "ON_REGISTRATION_INPUT_CHANGE";

export function setReferences(references) {
  return {
    type: SET_REFERENCES,
    payload: {
      references,
    },
  };
}

export function setRegistrationObject(objName, objVal) {
  return {
    type: SET_REGISTRATION_INFO,
    payload: {
      objName,
      objVal,
    },
  };
}

export function setSupplierCategories(newSelection) {
  return {
    type: SET_SUPPLIER_CATEGORIES,
    payload: {
      newSelection,
    },
  };
}

export function setUnspscFamilies(newSelection) {
  return {
    type: SET_UNSPSC_FAMILIES,
    payload: {
      newSelection,
    },
  };
}

export function setPostalAddress(physicalAddress) {
  return {
    type: SET_POSTAL_ADDRESS,
    payload: {
      physicalAddress,
    },
  };
}

export function setShareholderDetails(shareholderDetails) {
  return {
    type: SET_SHAREHOLDER_DETAILS,
    payload: {
      shareholderDetails,
    },
  };
}

export function onRegistrationInputChange(stage, ev) {
  return {
    type: ON_REGISTRATION_INPUT_CHANGE,
    payload: {
      stage,
      fieldName: ev.target.name,
      fieldValue: ev.target.value,
    },
  };
}
