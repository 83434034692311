import {
  // eslint-disable-next-line no-unused-vars
  FormRef,
  Spinner,
  WithFeature,
  SystemFeatures,
  Form,
  RadioButtonInput,
} from "isuppli-react-components";
import React, {
  forwardRef,
  // eslint-disable-next-line no-unused-vars
  ForwardRefRenderFunction,
  Fragment,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FormGroup } from "reactstrap";
import useShowError from "../../../../Hooks/useShowError";
import {
  getCompanyInformationConfiguration,
  saveCompanyInformationConfiguration,
} from "../../../../http/EnterpriseConfiguration/enterpriseConfiguration";
import OnboardingBeeRequirement from "../../../../Util/Enumerators/OnboardingBeeRequirement";

export interface CompanyInformationTabRef {
  save: () => Promise<void>
}

export interface CompanyInformationTabProps {

}

const CompanyInformationTab: ForwardRefRenderFunction<CompanyInformationTabRef, CompanyInformationTabProps> = (
  _: CompanyInformationTabProps, ref
) => {
  const showError = useShowError();
  const [loading, setLoading] = useState(true);
  const formRef = useRef<FormRef>(null);
  const [beeRequiredForOnboarding, setBeeRequiredForOnboarding] = useState<boolean>();
  const [validatedBeeRequired, setValidatedBeeRequired] = useState(false);

  useEffect(() => {
    const loader = async () => {
      setLoading(true);

      try {
        const apiData = await getCompanyInformationConfiguration();
        switch (apiData.onboardingBeeRequirements) {
          case OnboardingBeeRequirement.BeeRequired:
            setBeeRequiredForOnboarding(true);
            setValidatedBeeRequired(false);
            break;
          case OnboardingBeeRequirement.ValidatedBeeRequired:
            setBeeRequiredForOnboarding(true);
            setValidatedBeeRequired(true);
            break;
          case OnboardingBeeRequirement.None:
          default:
            setBeeRequiredForOnboarding(false);
            setValidatedBeeRequired(false);
            break;
        }
      } catch (error) {
        showError();
      }

      setLoading(false);
    };
    loader();
  }, [showError, setLoading]);

  useImperativeHandle(ref, () => ({
    save: async () => {
      if (!(formRef.current?.isValid())) {
        formRef.current?.showValidation();
        return;
      }

      setLoading(true);
      try {
        let onboardingBeeRequirements = OnboardingBeeRequirement.None;
        if (beeRequiredForOnboarding && validatedBeeRequired) {
          onboardingBeeRequirements = OnboardingBeeRequirement.ValidatedBeeRequired;
        } else if (beeRequiredForOnboarding) {
          onboardingBeeRequirements = OnboardingBeeRequirement.BeeRequired;
        }
        await saveCompanyInformationConfiguration({
          onboardingBeeRequirements,
        });
      } catch (error) {
        showError();
      }

      setLoading(false);
    },
  }));

  return (
    <Fragment>
      <h2 className="mt-5 text-uppercase">Company Information</h2>
      {loading && <Spinner />}
      <WithFeature feature={SystemFeatures.ConfigurationCompanyInfoOnboardingBee} keepVisible>
        <Form ref={formRef}>

          <FormGroup tag="fieldset" className="mt-5">
            <legend>
              <p className="font-weight-bold mb-2">
                Will B-BBEE be required for onboarding purposes,
                applicable to your South African suppliers?
              </p>
              <p className="mb-2">
                B-BBEE can be defined as a mandatory requirement for the purposes of your
                onboarding process, whereby your suppliers will be required to be compliant
                with the requirements of B-BBEE
              </p>
              Is B-BBEE required as a mandatory onboarding requirement?
            </legend>
            <RadioButtonInput
              inline
              checked={beeRequiredForOnboarding === true}
              onChange={() => {
                setBeeRequiredForOnboarding(true);
              }}
              name="bee-required"
              label="Yes"
            />
            <RadioButtonInput
              inline
              checked={beeRequiredForOnboarding === false}
              onChange={() => {
                setBeeRequiredForOnboarding(false);
                setValidatedBeeRequired(false);
              }}
              name="bee-required"
              label="No"
            />
          </FormGroup>
          {beeRequiredForOnboarding === true
            ? (
              <FormGroup tag="fieldset" className="">
                <legend>
                  Validated B-BBEE documents only?
                </legend>
                <RadioButtonInput
                  inline
                  checked={validatedBeeRequired === true}
                  onChange={() => { setValidatedBeeRequired(true); }}
                  name="bee-validated"
                  label="Yes"
                />
                <RadioButtonInput
                  inline
                  checked={validatedBeeRequired === false}
                  onChange={() => { setValidatedBeeRequired(false); }}
                  name="bee-validated"
                  label="No"
                />
              </FormGroup>
            )
            : null}

        </Form>
      </WithFeature>
    </Fragment>
  );
};

export default forwardRef<CompanyInformationTabRef, CompanyInformationTabProps>(CompanyInformationTab);
