import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Alert } from "reactstrap";

import Styles from "./ValidationAlert.module.scss";

const ValidationAlert = (
  {
    openValidationIn,
    isOpen,
    onToggle,
  }
  :
  {
    openValidationIn?: Element,
    isOpen: boolean,
    onToggle: () => void,
  }
) => {
  const [errorList, setErrorList] = useState<Array<string>>([]);

  useEffect(() => {
    if (isOpen) {
      const invalidElements = document.querySelectorAll(".invalid,:invalid,.is-invalid");
      const errorMessages: Array<string> = [];
      for (let i = 0; i < invalidElements.length; i += 1) {
        const parent = invalidElements[i].closest(".form-group");
        const errors = parent?.querySelectorAll(".invalid-feedback") ?? [];

        for (let j = 0; j < errors.length; j += 1) {
          const sibling = errors[j] as HTMLElement;
          // make sure the item is visible on the screen
          // https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetParent
          if (sibling.offsetParent != null) {
            errorMessages.push(sibling.innerText);
          }
        }
      }

      // get unique list of errors
      const uniqueList = errorMessages
        .filter((error, index) => errorMessages
          .findIndex((c) => c === error) === index);
      setErrorList(uniqueList);
    }
  }, [isOpen]);

  const alert = (
    <Alert
      isOpen={isOpen}
      toggle={onToggle}
      color="danger"
      className={`${Styles.ErrorAlert} p-4 ${openValidationIn == null ? Styles.LocalPlacement : ""}`}
    >
      <h4 className="mb-0">This page contains missing information</h4>
      <p>To continue please amend / complete the following</p>
      <ul className="mb-0">
        {/* eslint-disable-next-line react/no-array-index-key */}
        {errorList.map((c, i) => (<li key={i}>{c}</li>))}
      </ul>
    </Alert>
  );

  if (openValidationIn != null) {
    return ReactDOM.createPortal(
      alert,
      openValidationIn
    );
  }
  return alert;
};

export default ValidationAlert;
