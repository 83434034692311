enum SubCheckType {
  None, // eslint-disable-line no-unused-vars
  One, // eslint-disable-line no-unused-vars
  Two, // eslint-disable-line no-unused-vars
  Three, // eslint-disable-line no-unused-vars
  Four, // eslint-disable-line no-unused-vars
  Five, // eslint-disable-line no-unused-vars
  Six, // eslint-disable-line no-unused-vars
  Seven, // eslint-disable-line no-unused-vars
  Eight, // eslint-disable-line no-unused-vars
  Nine, // eslint-disable-line no-unused-vars
  Ten, // eslint-disable-line no-unused-vars
  Eleven, // eslint-disable-line no-unused-vars
  Twelve, // eslint-disable-line no-unused-vars
  Thirteen, // eslint-disable-line no-unused-vars
  Fourteen, // eslint-disable-line no-unused-vars
  Fifteen, // eslint-disable-line no-unused-vars
  Sixteen, // eslint-disable-line no-unused-vars
  Seventeen, // eslint-disable-line no-unused-vars
  Eighteen, // eslint-disable-line no-unused-vars
  Nineteen, // eslint-disable-line no-unused-vars
  Twenty, // eslint-disable-line no-unused-vars
  TwentyOne, // eslint-disable-line no-unused-vars
  TwentyTwo, // eslint-disable-line no-unused-vars
  TwentyThree, // eslint-disable-line no-unused-vars
  TwentyFour, // eslint-disable-line no-unused-vars
  TwentyFive, // eslint-disable-line no-unused-vars
  TwentySix, // eslint-disable-line no-unused-vars
  TwentySeven, // eslint-disable-line no-unused-vars
  TwentyEight, // eslint-disable-line no-unused-vars
  TwentyNine, // eslint-disable-line no-unused-vars
  Thirty, // eslint-disable-line no-unused-vars
}

export const subCheckTypeToString = (value: SubCheckType) => {
  switch (value) {
    case SubCheckType.None:
      return "Unknown Check";
    case SubCheckType.One:
      return "Company name matches CIPC";
    case SubCheckType.Two:
      return "Vendor directors are one of clients active employees";
    case SubCheckType.Three:
      return "Family of a Vendor directors are one of the Clients employee";
    case SubCheckType.Four:
      return "Family member of a Vendor directors are a director of a company which one of the directors are one of the Clients employees";
    case SubCheckType.Five:
      return "Vendor directors are directors of other companies which the Clients employee is a director of";
    case SubCheckType.Six:
      return "Vendor directors or members are directors of other active Vendors";
    case SubCheckType.Seven:
      return "Vendor directors are one of clients inactive employees";
    case SubCheckType.Eight:
      return "Company Business Status with CIPC";
    case SubCheckType.Nine:
      return "Vendor directors purchased a property from the Client employee";
    case SubCheckType.Ten:
      return "Vendor directors sold a property to one of the Client Employees";
    case SubCheckType.Eleven:
      return "Vendor directors coowns property with one of the Client’s Employees";
    case SubCheckType.Twelve:
      return "Vendor directors (or CIPRO associations) co-owns property with an Employee’s coowner of property and co directors";
    case SubCheckType.Thirteen:
      return "Vendor directors (or CIPRO associations) sold property to an Employee’s coowner of property and co directors";
    case SubCheckType.Fourteen:
      return "Vendor directors (or CIPRO associations) purchase property from an Employee’s coowner of property and codirectors";
    case SubCheckType.Fifteen:
      return "Family member of a Vendor’s directors/members co-owns property with one of the Client’s Employees";
    case SubCheckType.Sixteen:
      return "Vendor Blacklisted";
    case SubCheckType.Seventeen:
      return "Vendor directors co-directors are directors of a blacklisted vendor";
    case SubCheckType.Eighteen:
      return "Vendor directors co-directors are blacklisted employees";
    case SubCheckType.Nineteen:
      return "Vendor directors are directors of a blacklisted vendor";
    case SubCheckType.Twenty:
      return "Vendor directors are blacklisted employees";
    case SubCheckType.TwentyOne:
      return "Vendor found in National Treasury Non-Preferred list";
    case SubCheckType.TwentyTwo:
      return "Company VAT Number confirmed with SARS";
    case SubCheckType.TwentyThree:
      return "Vendor has Judgements";
    case SubCheckType.TwentyFour:
      return "Alert detected on fraud database for vendor";
    case SubCheckType.TwentyFive:
      return "Negative News";
    case SubCheckType.TwentySix:
      return "Credit Information check";
    case SubCheckType.TwentySeven:
      return "Vendors Banking Details Are Valid";
    case SubCheckType.TwentyEight:
      return "Persal";
    case SubCheckType.TwentyNine:
      return "Vendor has single VAT number";
    case SubCheckType.Thirty:
      return "VAT number found and/or validated for vendor";
    default:
      return "Unknown Check";
  }
};

export default SubCheckType;
