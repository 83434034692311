import React, { useState } from "react";
import { PageHeading, UtilButton } from "isuppli-react-components";
import Styles from "./MessengerHeader.module.scss";

import NewMessageModal from "../NewMessageModal/NewMessageModal";
import navMenuItems from "../../../Util/menuItems";
import {
  // eslint-disable-next-line no-unused-vars
  Message,
} from "../../../http/Messaging/messagesApi";

export default function MessengerHeader(
  { onMessageSent } : {onMessageSent : (message:Message, channelId:number) => void}
) {
  const [showComposeModal, setShowComposeModal] = useState(false);
  const composeMessageButton = (
    <UtilButton
      color="secondary"
      outline
      className="full-width"
      size="extra-small"
      location="top"
      onClick={() => setShowComposeModal(true)}
    >
      COMPOSE NEW
    </UtilButton>
  );
  return (
    <div className={Styles.HeaderContainer}>
      {showComposeModal && (
        <NewMessageModal
          onClose={() => setShowComposeModal(false)}
          onMessageSent={onMessageSent}
        />
      )}

      <div className={[Styles.Header, "flex-fill"].join(" ")}>
        <PageHeading
          heading="Messaging"
          menuItems={navMenuItems}
        />
      </div>
      <div className={Styles.btnComposeMessage}>
        <div>
          {composeMessageButton}
        </div>
      </div>
    </div>
  );
}
