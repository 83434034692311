import React, { Component } from "react";

import ValidationError from "./ValidationError/ValidationError";

class DropdownInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasFocus: false,
      msgs: [],
    };
    this.showError = true;
  }

  onChange = (ev) => {
    const event = {
      target: {
        name: ev.target.name,
        value: Number(ev.target.value),
      },
    };

    this.props.onChangeCallback(event);
    this.showError = event.target.value <= 0;
  };

  onFocus = () => {
    this.setState({
      hasFocus: true,
    });
  };

  onBlur = () => {
    if (this.state.hasFocus) {
      this.setState({
        hasFocus: false,
      });
    }
  };

  render() {
    const options = [
      this.props.noPrompt ? null : (
        <option key={-1} value={-1}>
          please select
        </option>
      ),
    ].concat(
      this.props.options.map((option, i) => (
        option.value < 0 ? (
          <optgroup label={option.display} />
        ) : (
          <option key={i} value={option.value}>
            {option.display}
          </option>
        )
      ))
    );
    const additional = this.state.msgs.map((msg, i) => (
      <label className="validation-label" key={i}>
        {msg}
      </label>
    ));
    return (
      <section
        className={`user-input bottom-margin-20px ${
          this.props.inline ? "user-input-inline align-center" : ""
        } ${this.props.className}`}
      >
        <div className={`${this.props.inline ? "pr-4" : ""}`}>
          <label>{this.props.label}</label>
        </div>
        <div className="round-borders align-center padding-8px center-text">
          <select
            disabled={this.props.disabled}
            className="full-width bg-transparent"
            name={this.props.name}
            value={this.props.value}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          >
            {options}
          </select>
        </div>
        {!!this.props.additional
          && this.props.additional.length > 0
          && this.showError && (
          <ValidationError>
            <label className="validation-label">
              {this.props.additional}
            </label>
          </ValidationError>
        )}
        {additional.length !== 0 && (
          <ValidationError>{additional}</ValidationError>
        )}
      </section>
    );
  }
}

export default DropdownInput;
