import axios from "axios";
import store from "../Store/index";
import { setNotAuthenticated, setTokenDates, forceLogout } from "../Store/actions";
import { checkHttpStatus } from "./httpHelpers";

export async function makeRequest(
  reqFunction,
  onSuccess,
  onError = null,
  bypassAuthCheck = false
) {
  // check token for expiry
  const { tokenCreationDate, tokenExpirationDate } = store.getState().authentication;
  if (tokenCreationDate != null && tokenExpirationDate != null) {
    // check if we are half way to expiration
    const remainingTime = tokenExpirationDate - new Date();
    const totalWindow = tokenExpirationDate - tokenCreationDate;

    if (remainingTime < 0 || remainingTime < totalWindow / 2) {
      // refresh token
      try {
        const response = await axios.get("/api/token/refresh");
        store.dispatch(setTokenDates(new Date(), new Date(response.data)));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Failed to refresh token", error);
      }
    }
  }

  reqFunction()
    .then((res) => {
      try {
        onSuccess(res.data);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    })
    .catch((err) => {
      if (!bypassAuthCheck
        && ((err && err.response && checkHttpStatus(err, 401))
        || (err
          && err.response
          && err.response.data
          && err.response.data.status === 401)
        )) {
        store.dispatch(setNotAuthenticated(() => {
          // authentication request has finished
          makeRequest(reqFunction, onSuccess, onError);
        }));
        return;
      }
      // eslint-disable-next-line no-console
      console.log(err);

      if (onError) {
        onError(err);
      }
    });
}

export async function makeRequestAsync(reqFunction, bypassAuthCheck = false) {
  return new Promise((
    resolve, reject
  ) => makeRequest(reqFunction, resolve, reject, bypassAuthCheck));
}

export async function refreshTokenInternal(url) {
  try {
    await axios.get(url);
  } catch (error) {
    // refreshing failed, set session as expired and force refreshing cookies
    store.dispatch(setNotAuthenticated(async () => {
      try {
        await axios.get(url);
      } catch (retryError) {
        // refreshing does not work, now logout
        // eslint-disable-next-line no-console
        console.error("Could not refresh token ", retryError);
        store.dispatch(forceLogout());
      }
    }));
  }
}
