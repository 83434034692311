import { Spinner } from "isuppli-react-components";
import React from "react";
import { useHistory } from "react-router";
import Styles from "./DashboardSuppliersSummary.module.scss";

const DashboardSupplierSummaryCard = ({
  title,
  value,
  changePercentage,
  background = "attention",
  linkUrl,
  loading = false,
}: {
    title:string,
    value: number,
    changePercentage: number | null | undefined,
    background?: "attention" | "info",
    linkUrl: string,
    loading?: boolean,
}) => {
  const history = useHistory();
  const onClickHandler = () => history.push(linkUrl);

  return (
    <div
      onClick={() => onClickHandler()}
      className={`p-3 d-flex flex-column rounded
      ${background === "info" ? Styles.Info : Styles.Attention}
      ${Styles.height}`}
    >
      <div>
        <p>{title}</p>
      </div>
      <div>
        {loading ? <Spinner inline /> : <h1>{value}</h1>}
      </div>
      <div>
        {
          changePercentage == null
            ? (
              <p>
                <span className={Styles.Percentage}>-</span>
              </p>
            ) : (
              <p>
                <span className={Styles.Percentage}>{`${changePercentage}% `}</span>
                From last week
              </p>
            )
        }
      </div>
    </div>
  );
};

export default DashboardSupplierSummaryCard;
