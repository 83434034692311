import React, { Fragment } from "react";

import { connect } from "react-redux";
import { Spinner } from "isuppli-react-components";

const mapStateToProps = (state) => ({
  showLoadingModal: state.showLoadingModal,
  loadingScreenMsg: state.loadingScreenMsg,
});

function Loading({ showLoadingModal }) {
  return <Fragment>{showLoadingModal && <Spinner />}</Fragment>;
}

export default connect(mapStateToProps)(Loading);
