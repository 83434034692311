import React, { Component } from "react";
import { connect } from "react-redux";
import {
  WithFeature, SystemFeatures, ActionsDropDownItem, ModalPopup, hasFeature,
} from "isuppli-react-components";
import {
  getSupplierKeyContactDetailsAsync,
  getDocumentsAsync,
  getSupplierInfoAsync,
  getCompanySubAccountsAsync,
  getAddressInfoAsync,
  getBeeDetailsAsync,
  getBankingDetailsAsync,
} from "../http/index";

import { getContractTiers } from "../http/Contracting/contractingApi";
import {
  getCompanyCompliance,
} from "../http/Compliance/complianceApi";

import { setOption } from "../Store/optionActions";
import { loadSupplierSearchDetailsOptions } from "../Util/optionsLoader";

import DocumentViewer from "../Containers/Supplier/DocumentViewer";
import KeyContactsTable from "../Containers/SupplierSearch/KeyContactsTable";
import SupplierSearchDetails from "../Containers/SupplierSearch/SupplierSearchDetails";
import SupplierSearchRating from "../Containers/SupplierSearch/SupplierSearchRating";
import BankDetailsTable from "../Containers/SupplierSearch/BankDetailsTable/BankDetailsTable";
import AgreementsTable from "../Containers/AgreementsTable/AgreementsTable";
import SubAccountsTable from "../Containers/SupplierSearch/SubAccountsTable/SubAccountsTable";

import { ReactComponent as DownloadSvg } from "../Images/download.svg";
import ShareholderTable from "../Containers/ShareholdersTable/ShareholderTable";
import { StaticCompanyDataTypes } from "../Util/Enumerators/staticCompanyDataTypes";
import { setStaticCompanyData } from "../Store/actions";
import { StaticDataTypes } from "../Util/Enumerators/staticDataTypes";
import { getClientSpecificInfoAsync } from "../http/Client/ClientApi";
import { getSupplierCategoriesSelectionAsBuyer } from "../http/SupplierCategory/supplierCategoryAPI";

const mapStateToProps = (state) => ({
  provinces: state.options.provinces,
  beeLevels: state.options.beeLvls,
  countries: state.options.countries,
  companyTypes: state.options.companyTypes,
  sectors: state.options.sectors,
  turnoverBands: state.options.turnoverBands,
  beeSectors: state.options.beeSectors,
  contractTiers: state.staticCompanyData[StaticCompanyDataTypes.contractTiers],
  supplierTransactionStatuses: state.staticCompanyData[StaticCompanyDataTypes.supplierTransactionStatuses],
  municipalityOptions: state.staticData[StaticDataTypes.Municipality] ?? [],
  allowedFeatures: state.loggedInUserDetails.allowedFeatures,
});
const mapDispatchToProps = (dispatch) => ({
  setOption: (optionName, optionValues) => {
    dispatch(setOption(optionName, optionValues));
  },
  setContractTiers: (contractTiers) => {
    dispatch(setStaticCompanyData([{
      type: StaticCompanyDataTypes.contractTiers,
      value: contractTiers,
    }], true));
  },
});

class SupplierDetailsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingCompanyDetails: true,
      companyDetails: {},
      clientSpecificInfo: {},
      loadingKeyContacts: true,
      keyContacts: [],

      loadingDocuments: true,
      documents: [],
      bankDetails: {},

      subAccounts: [],
      loadingSubAccounts: true,

      supplierSubCategories: [],
    };
  }

  async loadContacts() {
    const keyContacts = await getSupplierKeyContactDetailsAsync(this.props.company.companyId);

    this.setState({
      loadingKeyContacts: false,
      keyContacts,
    });
  }

  async loadClientSpecificInfo() {
    const clientSpecificInfo = await getClientSpecificInfoAsync(this.props.company.companyId);

    this.setState({
      clientSpecificInfo,
    });
  }

  async loadSupplierSubCategories() {
    const supplierSubCategories = await getSupplierCategoriesSelectionAsBuyer(this.props.company.companyId);

    this.setState({
      supplierSubCategories,
    });
  }

  componentDidMount() {
    const loadContractTiers = async () => {
      // load contracting tiers
      if (this.props.contractTiers == null || this.props.contractTiers.length === 0) {
        try {
          const contractTiers = await getContractTiers();
          const formattedContractTiers = contractTiers.map(
            (c) => ({ ...c, value: c.id, display: c.name })
          );
          this.props.setContractTiers(formattedContractTiers);
        } catch {
          this.props.setContractTiers([]);
        }
      }
    };
    // load company details
    this.loadContacts();
    this.loadDocuments();
    this.loadDetails();
    this.loadClientSpecificInfo();
    loadContractTiers();
    this.loadSupplierSubCategories();
  }

  async loadDocuments() {
    const documents = await getDocumentsAsync(this.props.company.companyId, true);

    this.setState({
      loadingDocuments: false,
      documents: documents.companyDocuments,
    });
  }

  async loadDetails() {
    const id = this.props.company.companyId;

    const getDataPromises = [
      getSupplierInfoAsync(id),
      getAddressInfoAsync(id),
      getBeeDetailsAsync(id),
      getCompanyCompliance(id),
      getBankingDetailsAsync(id),
      loadSupplierSearchDetailsOptions(this.props),
    ];

    const results = await Promise.all(getDataPromises.map((p) => p.catch((e) => e)));
    const companyDetails = results[0] instanceof Error ? {} : results[0];
    const addressResults = results[1] instanceof Error ? {} : results[1];
    const beeInfo = results[2] instanceof Error ? {} : results[2];
    const complianceDetails = results[3] instanceof Error ? {} : results[3];
    const bankDetails = results[4] instanceof Error ? {} : results[4];

    this.setState({
      loadingCompanyDetails: false,
      companyDetails: {
        ...companyDetails,
        addresses: addressResults.addresses,
        beeInfo,
      },
      complianceDetails,
      bankDetails,
    });

    let subAccounts = [];
    if (companyDetails?.hasSubAccounts) {
      subAccounts = await getCompanySubAccountsAsync(id);
    }

    this.setState({
      subAccounts,
      loadingSubAccounts: false,
    });
  }

  render() {
    const {
      loadingCompanyDetails,
      companyDetails = {},
      clientSpecificInfo = {},
      complianceDetails = null,
      loadingKeyContacts,
      keyContacts = [],
      loadingSubAccounts,
      subAccounts = [],
      loadingDocuments,
      documents = [],
      bankDetails = {},
    } = this.state;

    const disableDownloadButton = loadingCompanyDetails
      || loadingDocuments
      || loadingSubAccounts
      || loadingKeyContacts;

    const {
      provinces = [],
      beeLevels = [],
      countries = [],
      companyTypes = [],
      sectors = [],
      turnoverBands = [],
      beeSectors = [],
      supplierTransactionStatuses = [],
      municipalityOptions = [],
    } = this.props;

    const loadingMessage = <label>Loading...</label>;
    return (
      <>
        <ModalPopup
          onClose={this.props.onCloseCallback}
          heading={this.props.company.registeredName}
          subheading={companyDetails.parentAccountId == null
            ? ""
            : `Parent Account Trading Name: ${companyDetails.parentAccountTradingName}`}
          buttons={[
            {
              key: "close",
              label: "CLOSE",
              color: "primary",
              onClick: () => this.props.onCloseCallback(),
            },
          ]}
          actionItems={this.props.isPotentialSupplier && this.props.hasActionItems ? [
            <ActionsDropDownItem
              key="onboard"
              feature={SystemFeatures.OnboardingCreateRequest}
              onClick={() => {
                this.props.onCloseCallback();
                this.props.onboardSupplierCallback();
              }}
            >
              Onboard supplier
            </ActionsDropDownItem>,
          ] : []}
        >
          <div className="row px-2">
            <div className="col-12 pt-2">
              <h2>COMPANY DETAILS</h2>
            </div>
          </div>
          <SupplierSearchDetails
            loading={loadingCompanyDetails}
            companyDetails={companyDetails}
            clientSpecificInfo={clientSpecificInfo}
            provinces={provinces}
            beeLevels={beeLevels}
            countries={countries}
            companyTypes={companyTypes}
            sectors={sectors}
            turnoverBands={turnoverBands}
            beeSectors={beeSectors}
            complianceDetails={complianceDetails}
            supplierTransactionStatuses={supplierTransactionStatuses}
            municipalities={municipalityOptions}
            supplierSubCategories={this.state.supplierSubCategories}
          />

          <div className="row px-2">

            <div className="col-12 pt-5">
              <WithFeature feature={SystemFeatures.ViewSupplierShareholderDetails}>
                <ShareholderTable
                  supplierCompanyId={this.props.company.companyId}
                  readOnly
                />
              </WithFeature>
            </div>

            <div className="col-12 pt-5">
              <h2>KEY CONTACT DETAILS</h2>
              {loadingKeyContacts ? (
                loadingMessage
              ) : (
                <KeyContactsTable keyContacts={keyContacts} />
              )}
            </div>

            <div className="col-12 pt-5">
              <h2>SUPPORTING DOCUMENTS</h2>
              {loadingDocuments ? (
                loadingMessage
              ) : (
                <DocumentViewer
                  displayBanking={companyDetails?.isSupplierOfCurrentCompany
                     && hasFeature(this.props.allowedFeatures, SystemFeatures.ViewSupplierBankDetails)}
                  downloadUrl="api/documents/"
                  documents={documents}
                />
              )}
            </div>
            {companyDetails?.isSupplierOfCurrentCompany
              && (
                <WithFeature feature={SystemFeatures.ViewSupplierBankDetails}>
                  <div className="col-12 pt-5">
                    <h2>BANK DETAILS</h2>
                    <BankDetailsTable
                      loading={loadingCompanyDetails}
                      bankDetails={bankDetails}
                    />
                  </div>
                </WithFeature>
              )}
            {
              this.props.contractTiers?.length > 0
                && (
                  <div className="col-12 pt-5">
                    <h2>AGREEMENTS</h2>
                    <AgreementsTable
                      signatoryCompanyId={this.props.company.companyId}
                      contractTiersList={this.props.contractTiers}
                      currentUserIsBuyer
                    />
                  </div>
                )
            }

            {companyDetails?.hasSubAccounts
              && (
                <div className="col-12 pt-5">
                  <h2>SUB ACCOUNTS</h2>
                  <SubAccountsTable
                    subAccounts={subAccounts}
                    loading={loadingSubAccounts}
                  />
                </div>
              )}
          </div>
          <SupplierSearchRating
            rating={companyDetails?.companyRating ?? 0}
            totalRatings={companyDetails?.totalCompanyRatings ?? 0}
          />
          <div className="px-2 justify-content-end">
            <a
              href={`/api/registration/supplierinfo/${companyDetails.companyId}/export`}
              download="supplierinfo.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className={`btn btn-link w-100 text-right px-0 text-capitalize
                ${disableDownloadButton
        ? "disabled" : ""}`}
              onClick={() => {
              }}
            >
              <DownloadSvg />
            </a>
          </div>
        </ModalPopup>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplierDetailsModal);
