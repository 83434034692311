/* eslint-disable no-bitwise */
const selectedFlagEnumToString = <T extends string, TEnumValue extends number>(
  {
    value,
    enumToUse,
    defaultEnumValueForEmtpySelection,
    enumDisplayValues,
  }:{
  value: TEnumValue,
  enumToUse: { [key in T]: TEnumValue },
  defaultEnumValueForEmtpySelection : TEnumValue
  enumDisplayValues?: { [index: number]: string; }
}) => {
  const enumLabels = enumDisplayValues == null
    ? Object.values(enumToUse)
      .filter((v) => typeof v === "string")
      .map((v) => v as string)
    : Object.values(enumToUse)
      .filter((v) => typeof v === "number")
      .map((v) => enumDisplayValues[v as TEnumValue]);

  // return default value or "none" when no values are selected
  if (value <= 0) {
    return enumDisplayValues != null
      ? enumDisplayValues[defaultEnumValueForEmtpySelection as TEnumValue]
      : "None";
  }

  const enumValues = Object.values(enumToUse)
    .filter((v) => typeof v === "number")
    .map((v) => v as TEnumValue);

  const selectedFlags = enumLabels.map((enumLabel, index) => {
    const enumValue = enumValues[index];
    // filter out the default empty value
    if ((enumValue & defaultEnumValueForEmtpySelection) === enumValue) {
      return null;
    }
    const isSelected = (enumValue & value) === enumValue;
    return isSelected ? enumLabel : null;
  });

  const result = selectedFlags.filter((x) => x != null).join(", ");
  return result;
};

export default selectedFlagEnumToString;
