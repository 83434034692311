import React from "react";
import { useSelector } from "react-redux";

import { DataFeatures, ProgressIndicator, useDataFeatureCheck } from "isuppli-react-components";
import { useHistory } from "react-router";
// eslint-disable-next-line no-unused-vars
import { ReduxState } from "../../Store/ReduxState";

function RegistrationProgress(
  { currentStep }
  :
  {currentStep: number }
) {
  const lastStepCompleted = useSelector((state: ReduxState) => state.lastStepCompleted);
  const history = useHistory();

  const dataFeatureCheck = useDataFeatureCheck();

  const hasBanking = dataFeatureCheck(DataFeatures.BankingDetails);

  const links = ["supinfo",
    "coninfo",
    "beeinfo",
    "prdinfo",
    "bnkinfo",
    "documents",
    "prtinfo"];
  const steps = [
    "SUPPLIER INFORMATION",
    "ADDRESS & CONTACT DETAILS",
    "B-BBEE PRACTICES & CERTIFICATION",
    "PRODUCT OFFERINGS",
    "BANK DETAILS",
    "SUPPORTING DOCUMENTS & AGREEMENTS",
    "SUBSCRIPTION PACKAGE",
  ];

  if (!hasBanking) {
    links.splice(4, 1);
    steps.splice(4, 1);
  }
  return (
    <section id="isuppli-registration-progress" className="bg-light">
      <div className="padding-lr-10vw">
        <div style={{ paddingTop: "83px" }}>
          <h1>YOUR ACCOUNT HAS BEEN CREATED.</h1>
        </div>

        <div style={{ paddingBottom: "41px" }}>
          <h3>
            Please help us and businesses understand your business by providing
            the
            <br />
            information required on each of the steps.
          </h3>
        </div>

        <ProgressIndicator
          currentStep={currentStep - 1}
          stepsCompleted={lastStepCompleted}
          onClick={(stepIndex) => {
            history.push(links[stepIndex]);
          }}
          steps={steps}
        />
      </div>
    </section>
  );
}

export default RegistrationProgress;
