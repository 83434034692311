enum BeeOwnership {
  None = 0, // eslint-disable-line no-unused-vars
  Flowthrough = 1, // eslint-disable-line no-unused-vars
  Modified = 2, // eslint-disable-line no-unused-vars
}

export const beeOwnershipToString = (
  value: BeeOwnership
) => {
  switch (value) {
    case BeeOwnership.None:
      return "None";
    case BeeOwnership.Flowthrough:
      return "Flowthrough";
    case BeeOwnership.Modified:
      return "Modified";
    default:
      return "Others";
  }
};

export default BeeOwnership;
