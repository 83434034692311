import React, {
  Fragment,
  // eslint-disable-next-line no-unused-vars
  ReactElement, useEffect, useMemo,
} from "react";
import UtilButton from "../../Components/UtilButton/UtilButton";
import useRouteState from "../../Hooks/useRouteState";
import { useSystemFeatureCheck } from "../../Hooks/useSystemFeatureCheck";
import Tab, {
  // eslint-disable-next-line no-unused-vars
  TabProps,
} from "./Tab/Tab";

const Tabs = (
  {
    children,
    onTabChange,
  }
  :
  {
    children: ReactElement<TabProps> | ReactElement<TabProps>[],
    onTabChange?: (tabSlug: string) => void,
  }
) => {
  const featureCheck = useSystemFeatureCheck();
  const [currentTab, setCurrentTab] = useRouteState("tab", "");

  if (!React.Children.toArray(children).every((c: ReactElement) => c.type === Tab)) {
    throw new Error("Tabs can only have Tab children");
  }

  const tabsProps = useMemo(
    () => React.Children.map(children, (c) => c.props), [children]
  );
  const tabsContent = React.Children.toArray(children);

  const isTabAllowed = (props?: TabProps) => props?.feature == null || featureCheck(props.feature);

  const notAllowed = useMemo(
    () => tabsProps.find((props) => isTabAllowed(props)) == null,
    [tabsProps]
  );

  useEffect(() => {
    const currentTabProps = tabsProps.find((p) => p.slug === currentTab);
    if (currentTabProps == null || currentTab === "") {
      setCurrentTab(tabsProps[0].slug);
      return;
    }

    // make sure tab is allowed, otherwise find first allowed tab
    if (!isTabAllowed(currentTabProps)) {
      // first allowed tab
      const firstAllowedTab = tabsProps.find((props) => isTabAllowed(props));
      if (firstAllowedTab != null) {
        setCurrentTab(firstAllowedTab?.slug);
      }
    }
  }, [currentTab, setCurrentTab, children, tabsProps]);

  const currentTabContent = useMemo(() => {
    const index = tabsProps.findIndex((p) => p.slug === currentTab);
    return tabsContent[index];
  }, [currentTab, children]);

  useEffect(() => {
    if (onTabChange == null) {
      return;
    }

    onTabChange(currentTab);
  }, [currentTab]);

  return (
    <Fragment>
      {notAllowed ? <h1>ACCESS DENIED</h1>
        : (
          <Fragment>
            <div className="justify-center">
              <div>
                {tabsProps.map((props) => (
                  <UtilButton
                    key={props.slug}
                    size="extra-small"
                    location="top"
                    outline={props.slug !== currentTab}
                    onClick={() => setCurrentTab(props.slug)}
                    disabled={!isTabAllowed(props)}
                    feature={props.feature}
                  >
                    {props.name}
                  </UtilButton>
                ))}
              </div>
            </div>
            <div>
              {currentTabContent}
            </div>
          </Fragment>
        )}
    </Fragment>
  );
};

export * from "./Tab/Tab";
export { default as Tab } from "./Tab/Tab";
export default Tabs;
