import {
  DataFeatures,
  FlagEnumSelectorInput,
  Form,
  // eslint-disable-next-line no-unused-vars
  FormRef,
  ModalPopup, Spinner, TextInput,
  DataFeaturesDisplay,
} from "isuppli-react-components";
import React, {
  Fragment, useEffect, useRef, useState,
} from "react";
import useShowError from "../../../Hooks/useShowError";
import { ConfigurationSupplierTypeMessage } from "../../../http/SelfConfiguration/ConfigurationSupplierTypeMessage";// eslint-disable-line no-unused-vars
import { addOrUpdateConfigurationSupplierType, getConfigurationSuppilerType } from "../../../http/SelfConfiguration/SupplierTypesConfigApi";
import OnboardingApproverLevels, { OnboardingApproverLevelsDisplay } from "../../../Util/Enumerators/onboardingApproverLevel";

const AddOrEditSupplierTypeModal = ({
  supplierTypeId,
  onCloseCallback,
  onSubmitCallback,
  isBankingGloballyRequired,
}: {
  supplierTypeId?: number,
  onCloseCallback: () => void,
  onSubmitCallback: (updatedData: ConfigurationSupplierTypeMessage) => void,
  isBankingGloballyRequired: boolean,
}) => {
  const [supplierName, setSupplierName] = useState("");
  const [mtoApproverLevels, setMtoApproverLevels] = useState(OnboardingApproverLevels.None);
  const [dataFeatures, setDataFeatures] = useState(DataFeatures.None);
  const [loading, setLoading] = useState(false);
  const showError = useShowError();

  useEffect(() => {
    const loader = async () => {
      setLoading(true);
      try {
        if (supplierTypeId != null && supplierTypeId > 0) {
          const data = await getConfigurationSuppilerType(supplierTypeId);
          setSupplierName(data.name);
          setMtoApproverLevels(data.onboardingApproverLevels);
          setDataFeatures(data.dataFeatures);
        }
        setLoading(false);
      } catch {
        setLoading(false);
        showError();
      }
    };

    loader();
  }, [supplierTypeId, showError]);

  const formRef = useRef<FormRef>(null);
  const handleSubmitConfigSupplierType = async () => {
    if (!formRef.current?.isValid()) {
      formRef.current?.showValidation();
      return;
    }

    setLoading(true);
    try {
      const data = {
        id: supplierTypeId,
        name: supplierName,
        onboardingApproverLevels: mtoApproverLevels,
        dataFeatures,
      };
      const savedSupplierTypeId = await addOrUpdateConfigurationSupplierType(data);
      setLoading(false);
      data.id = savedSupplierTypeId;
      onSubmitCallback(data as ConfigurationSupplierTypeMessage);
    } catch {
      setLoading(false);
      showError();
    }
  };

  const disabledDataFeatures: { [index: number]: string } = {
  };
  if (isBankingGloballyRequired) {
    disabledDataFeatures[DataFeatures.BankingDetails] = "Banking details are already required for all supplier types.";
  }

  return (
    <Fragment>
      <ModalPopup
        size="large"
        onClose={onCloseCallback}
        hasCancel
        heading={supplierTypeId != null && supplierTypeId > 0 ? "Edit supplier type" : "Add a new supplier type"}
        buttons={
          [
            {
              key: "submit",
              label: "Submit",
              onClick: () => handleSubmitConfigSupplierType(),
              color: "primary",
            },
          ]
        }
      >
        {
          loading ? <Spinner inline />
            : (
              <Fragment>
                <Form ref={formRef}>
                  <div className="row px-2 py-3">
                    <div className="col-12">
                      <TextInput
                        label="Supplier type name"
                        required
                        type="text"
                        value={supplierName}
                        placeholder="Enter the supplier type name"
                        validationMessage="You have to enter the a supplier type name"
                        onChange={(name) => setSupplierName(name)}
                      />
                    </div>
                    <div className="col-12 mt-4">
                      <FlagEnumSelectorInput
                        label="MTO Approver levels"
                        enumToUse={OnboardingApproverLevels}
                        value={mtoApproverLevels}
                        enumValuesToFilter={OnboardingApproverLevels.None}
                        enumDisplayValues={OnboardingApproverLevelsDisplay}
                        onChange={(selectedApproverLevels) => setMtoApproverLevels(selectedApproverLevels)}
                      />
                    </div>
                    <div className="col-12 mt-4">
                      <FlagEnumSelectorInput
                        label="Additional supplier data"
                        enumToUse={DataFeatures}
                        value={dataFeatures}
                        enumValuesToFilter={DataFeatures.None}
                        enumDisplayValues={DataFeaturesDisplay}
                        onChange={(selectedDataFeatures) => setDataFeatures(selectedDataFeatures)}
                        enumValuesToDisable={disabledDataFeatures}
                      />
                    </div>
                  </div>

                </Form>
              </Fragment>
            )
        }

      </ModalPopup>
    </Fragment>
  );
};

export default AddOrEditSupplierTypeModal;
