import React from "react";

import { Line, UtilButton } from "isuppli-react-components";
import { useDispatch } from "react-redux";
import { hideInfoPopup } from "../Store/actions";

function WizzardBtns(props) {
  const dispatch = useDispatch();
  return (
    <section>
      <div className="desktop-version">
        <div className="padding-lr-5vw" style={{ marginTop: "137px" }}>
          <Line color="secondary" />
          <div className="row">
            <div className="col-12 col-sm-5 block-sm justify-start">
              {!!props.backUrl && (
                <UtilButton
                  color="secondary"
                  outline
                  location="bottom"
                  onClick={() => {
                    props.history.replace(props.backUrl);
                    dispatch(hideInfoPopup());
                  }}
                >
                  Back
                </UtilButton>
              )}
            </div>
            <div className="col-12 col-sm-7 block-sm justify-end">
              <UtilButton
                color="secondary"
                location="bottom"
                onClick={props.onClickSaveAndContinue}
              >
                SAVE AND CONTINUE LATER
              </UtilButton>

              <div className="left-margin-10px" />

              <UtilButton
                color="primary"
                location="bottom"
                onClick={() => props.onClickNext(props.nextUrl)}
              >
                {`NEXT - ${props.nextText}`}
              </UtilButton>
            </div>
          </div>

          <div style={{ paddingTop: "44px" }} />
        </div>
      </div>

      <div className="mobile-version">
        <div className="padding-lr-5vw" style={{ marginTop: "137px" }}>
          <div className="row">
            <div className="col-12">
              <UtilButton
                className="w-100"
                color="secondary"
                location="bottom"
                onClick={props.onClickSaveAndContinue}
              >
                SAVE AND CONTINUE LATER
              </UtilButton>
            </div>
          </div>
          <div className="row mt-3">
            {!!props.backUrl && (
              <div className="col-6">
                <UtilButton
                  className="w-100"
                  color="secondary"
                  outline
                  location="bottom"
                  onClick={() => props.history.replace(props.backUrl)}
                >
                  BACK
                </UtilButton>
              </div>
            )}
            <div className={`col-${props.backUrl ? 6 : 12}`}>
              <UtilButton
                className="w-100"
                color="primary"
                location="bottom"
                onClick={() => props.onClickNext(props.nextUrl)}
              >
                Next
              </UtilButton>
            </div>
          </div>
          <div style={{ paddingTop: "44px" }} />
        </div>
      </div>
    </section>
  );
}

export default WizzardBtns;
