import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line no-unused-vars
import { ReduxState } from "../Store/ReduxState";
import {
  setCompanyOutstandingInfo,
} from "../Store/actions";
import {
  getCompanyOutstandingInformation,
} from "../http/MyCompany/myCompanyAPI";

const useOutstandingInformationLoader = () => {
  const dispatch = useDispatch();

  const accountSuspended = useSelector(
    (state: ReduxState) => state.loggedInUserDetails.accountSuspended
  );

  return async () => {
    if (!accountSuspended) {
      const companyOutstandingInfo = await getCompanyOutstandingInformation();
      dispatch(setCompanyOutstandingInfo(companyOutstandingInfo));
    }
  };
};

export default useOutstandingInformationLoader;
