import React, { useState } from "react";
import { ReactComponent as MagnifyingGlassSvg } from "../../images/magnifying-glass.svg";

const FilterInput = (props: any) => {
  const [value, setValue] = useState("");

  const onSubmit = (ev: any) => {
    ev.preventDefault();
    props.onFilterCallback(value);
  };

  const onInputChange = (ev: any) => {
    setValue(ev.target.value);
    if (props.onChangeCallback) {
      props.onChangeCallback(ev);
    }
  };

  return (
    <section className="filter-input">
      <div>
        <form
          onSubmit={onSubmit}
          autoComplete="off"
          className="align-center"
        >
          <MagnifyingGlassSvg />
          <input
            name="value"
            // eslint-disable-next-line react/destructuring-assignment
            placeholder={props.placeholder || "enter text here..."}
            value={value}
            onChange={onInputChange}
            style={{ paddingLeft: "26px" }}
          />
        </form>
      </div>
    </section>
  );
};

export default FilterInput;
