import React, { Fragment, useEffect, useState } from "react";

import { ShadowCard, Spinner } from "isuppli-react-components";
import { getDashboardContractAgreementsSummary } from "../../../http/Contracting/contractingApi";
// eslint-disable-next-line no-unused-vars
import { DashboardContractReportSummary } from "../../../http/Contracting/Models/DashboardContractReportSummary";
import DashboardSuppliersSummaryTable from "../DashboardSuppliersSummaryTable/DashboardSuppliersSummaryTable";
// eslint-disable-next-line no-unused-vars
import { DashboardSummaryItem } from "../DashboardSummaryItem/DashboardSummaryItem";
import useShowError from "../../../Hooks/useShowError";

const SupplierContractAgreementSummary = () => {
  const [contractAgreementsSummaryData,
    setContractAgreementsSummaryData] = useState<DashboardContractReportSummary>({ items: [] });
  const [loading, setLoading] = useState(false);
  const showError = useShowError();

  useEffect(() => {
    const loader = async () => {
      setLoading(true);
      try {
        // api calls
        const dashboardContractAgreementsSummarySummaryData = await getDashboardContractAgreementsSummary();
        setContractAgreementsSummaryData(dashboardContractAgreementsSummarySummaryData);
      } catch (error) {
        showError();
      }
      setLoading(false);
    };
    loader();
  }, []);

  if (!loading && (contractAgreementsSummaryData?.items?.length ?? 0) === 0) {
    return null;
  }

  const columns = [
    "",
    "Approved",
    "Pending Approval",
  ];

  const rows = contractAgreementsSummaryData?.items?.map((x) => [
    { key: `${x.id}`, value: x.name },
    { key: `${x.id}_approved`, value: x.approvedCount, linkUrl: `/marketplace/search?f=[[],[],[],[],[],[],null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,${x.id},true]&sf=1` },
    { key: `${x.id}_pending`, value: x.pendingApprovalCount, linkUrl: `/marketplace/search?f=[[],[],[],[],[],[],null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,${x.id},false]&sf=1` },
  ] as DashboardSummaryItem[]) ?? [];

  return (
    <Fragment>
      <div className="row mt-4">
        <div className="col-12">
          <h2>MY SUPPLIERS AGREEMENTS</h2>
        </div>
      </div>
      <ShadowCard>
        {loading ? <Spinner inline /> : (
          contractAgreementsSummaryData.items.length > 0 && (
            <div className="row">
              <div className="col-12">
                <DashboardSuppliersSummaryTable
                  title=""
                  columns={columns}
                  rows={rows}
                />
              </div>
            </div>
          )
        )}
      </ShadowCard>
    </Fragment>
  );
};

export default SupplierContractAgreementSummary;
