import React, { useState } from "react";
import Styles from "./SearchFiltersContainer.module.scss";
import { ReactComponent as SvgSortSizeDown } from "./sort-size-down.svg";
import UtilButton from "../../UtilButton/UtilButton";
import SearchFilters from "../SearchFilters/SearchFilters";
import {
  // eslint-disable-next-line no-unused-vars
  FilterInfo,
} from "../Interfaces/FilterInfo";

import AdvancedFilters from "../AdvancedFilters/AdvancedFilters";
// eslint-disable-next-line no-unused-vars
import { AdvancedFilterInfo } from "../Interfaces/AdvancedFilterInfo";
// eslint-disable-next-line no-unused-vars
import { ButtonInfo } from "../../../utils/interfaces/ButtonInfo";
import DropDownButton from "../../DropDownButton/DropDownButton";
// eslint-disable-next-line no-unused-vars
import { SystemFeatures } from "../../..";

export type FilterValue = number | number[] | string | string[] | Date | Date[] | undefined;

const SearchFiltersContainer = ({
  filters = [],
  advancedFilters = [],
  buttons = [],
  filterValues,
  className,
  advancedFiltersSystemFeature,
  onChange,
}: {
  filters?: FilterInfo[],
  advancedFilters?: AdvancedFilterInfo[],
  buttons?: ButtonInfo[],
  filterValues: FilterValue[],
  className?: string,
  advancedFiltersSystemFeature?: SystemFeatures
  onChange: (filterValues: FilterValue[]) => void
  }) => {
  const [isOpen, setIsOpen] = useState(false);

  const filterValuesToUse = (filterValues.length === 0)
    ? filters.map(() => [])
    : filterValues.slice(0, filters.length);

  const advancedFilterValues = filterValues == null
    ? []
    : filterValues.slice(filters.length);

  const onFilterChangeHandler = (newFilterValues: number[][]) => {
    onChange([...newFilterValues, ...advancedFilterValues]);
  };

  const onAdvancedFiltersChangeHandler = (newValues: FilterValue[]) => {
    onChange([...filterValuesToUse, ...newValues]);
  };

  const buttonComponents = buttons.map((button: ButtonInfo) => (
    <div className={`${Styles.Button} mr-2`} key={button.key}>
      <UtilButton
        color={button.color}
        location="bottom"
        onClick={button.onClick}
        size="small"
        className="w-100"
      >
        {button.label}
      </UtilButton>
    </div>
  ));

  // filterValues is an array of arrays
  // count the number of filter values where any items in the array
  // has a value i.e. is truthy
  let totalFilterSelected = 0;
  filterValues.forEach((filter) => {
    if (filter == null) {
      return;
    }
    if (typeof filter === "string") {
      totalFilterSelected += (filter ? 1 : 0);
      return;
    }

    if (typeof filter === "number") {
      totalFilterSelected += filter > 0 ? 1 : 0;
    }

    if ((filter as number[]).length > 0) {
      totalFilterSelected += (filter as number[]).length;
    }
  });

  const mobileSearchHeadingBadgeValue = totalFilterSelected > 0 ? `${totalFilterSelected} filters` : undefined;

  return (
    <div className={`${Styles.SearchFiltersContainer} ${Styles.ShadowCard} ${className}`}>
      <h3 className="heading desktop-version">
        <SvgSortSizeDown className="mr-2" />
        Search by:
      </h3>
      <DropDownButton
        className={`mobile-version ${Styles.SearchToggleButton}`}
        label="Search by:"
        iconSvg={SvgSortSizeDown}
        styleVal="light"
        labelClassName={Styles.SearchToggleButton}
        onClick={() => setIsOpen((v) => !v)}
        up={isOpen}
        badge={isOpen ? undefined : mobileSearchHeadingBadgeValue}
      />
      <div
        className={`${Styles.PrimaryFilters} ${
          isOpen ? "" : Styles.FiltersClosed
        }`}
      >
        <SearchFilters
          filters={filters}
          filterValues={filterValuesToUse}
          onChangeCallback={(newValues: number[][]) => onFilterChangeHandler(newValues)}
        />
      </div>

      {advancedFilters != null
        && advancedFilters.length > 0
        ? (
          <AdvancedFilters
            className={isOpen ? "" : Styles.FiltersClosed}
            filters={advancedFilters}
            filterValues={advancedFilterValues}
            onChangeCallback={(newValues) => onAdvancedFiltersChangeHandler(newValues)}
            advancedFiltersSystemFeature={advancedFiltersSystemFeature}
          />
        )
        : null}
      <div className={`${Styles.SearchFiltersContainerFooter} ${isOpen ? "" : Styles.FooterClosed}`}>
        <div className="d-flex justify-content-end">
          {buttonComponents}
        </div>
      </div>

    </div>
  );
};

export default SearchFiltersContainer;
export * from "../Interfaces/FilterInfo";
export * from "../Interfaces/FilterOption";
