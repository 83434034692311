import React, { useEffect, useState } from "react";
import { ModalPopup } from "isuppli-react-components";

import AgreementsTable from "../AgreementsTable/AgreementsTable";
import useShowError from "../../Hooks/useShowError";
import { StaticCompanyDataTypes } from "../../Util/Enumerators/staticCompanyDataTypes";
import useStaticCompanyData from "../../Hooks/useStaticCompanyData";
import { getSupplierContractInfoMessage } from "../../http/Marketplace/onboardingAPI";
// eslint-disable-next-line no-unused-vars
import { ContractTierMessage, getContractTiers } from "../../http/Contracting/contractingApi";
// eslint-disable-next-line no-unused-vars
import { SupplierContractInfoMessage } from "../../http/Marketplace/Models/SupplierContractInfoMessage";

const AgreementsModal = (
  {
    supplierCompanyId,
    supplierCompanyRegisteredName,
    onClose,
  }
  :
  {
    supplierCompanyId: number,
    supplierCompanyRegisteredName: string,
    onClose: () => void,
  }
) => {
  const [[supplierTypes], isReady] = useStaticCompanyData(
    [StaticCompanyDataTypes.supplierType]
  );

  const [supplierContractDetails, setSupplierContractDetails] = useState<SupplierContractInfoMessage|undefined>();
  const [supplierTypeName, setSupplierTypeName] = useState<string>("...");
  const [contractTierName, setContractTierName] = useState<string>("...");
  const [contractTiers, setContractTiers] = useState<Array<ContractTierMessage>|undefined>();
  const showError = useShowError();

  useEffect(() => {
    const loader = async () => {
      try {
        const result = await getSupplierContractInfoMessage(supplierCompanyId);
        setSupplierContractDetails(result);

        const contractTierResults = await getContractTiers();
        setContractTiers(contractTierResults);
      } catch (error) {
        showError();
      }
    };

    loader();
  }, [supplierCompanyId, showError]);

  useEffect(() => {
    // set supplierTypeName
    const nameSupplierType = isReady && supplierContractDetails
      ? supplierTypes?.find(
        (supplierType: any) => supplierType.id === supplierContractDetails?.supplierTypeId
      )?.name ?? "N/A"
      : "...";
    setSupplierTypeName(nameSupplierType);

    // set contractTierName
    const contractTier = contractTiers?.find(
      (ct) => ct.externalContractTierId === supplierContractDetails?.externalContractTierId
    );
    const nameContractTier = contractTier != null
      ? `${contractTier.externalContractTierId} - ${contractTier.name}`
      : "N/A";
    setContractTierName(contractTiers ? nameContractTier : "...");
  }, [isReady, supplierTypes, contractTiers, supplierCompanyId, supplierContractDetails]);

  return (
    <ModalPopup
      size="extra-large"
      onClose={onClose}
      hasCancel
      heading="Agreements"
      subheading={`Contracting agreements between you and ${supplierCompanyRegisteredName ?? ""}`}
    >
      <div className="row px-2 py-3">
        <div className="col-6 col-sm-5 col-md-4 col-lg-3 col-xl-2 font-weight-bold mb-1">
          Supplier Type:
        </div>
        <div className="col-6 col-sm-7 col-md-8 col-lg-9 col-xl-10">
          {supplierTypeName}
        </div>
        <div className="col-6 col-sm-5 col-md-4 col-lg-3 col-xl-2 font-weight-bold">
          Supplier Contract Tier:
        </div>
        <div className="col-6 col-sm-7 col-md-8 col-lg-9 col-xl-10">
          {contractTierName}
        </div>
      </div>
      <div className="row px-2 py-3">
        <div className="col-12">
          <AgreementsTable
            signatoryCompanyId={supplierCompanyId}
            currentUserIsBuyer
            contractTiersList={contractTiers}
          />
        </div>
      </div>
    </ModalPopup>
  );
};

export default AgreementsModal;
