import React from "react";
import { ReactComponent as RedTriangleSvg } from "./red-triangle.svg";
import { ReactComponent as WarningSvg } from "./warning.svg";

function ValidationError({ children }) {
  return (
    <section>
      <div>
        <RedTriangleSvg className="validation-error-indicator" />
      </div>
      <div className="validation-error">
        <WarningSvg className="validation-error-warning" />
        <span>{children}</span>
      </div>
    </section>
  );
}

export default ValidationError;
