import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert } from "reactstrap";

import { withRouter } from "react-router-dom";

import { DataFeatures, useDataFeatureCheck } from "isuppli-react-components";
import RegistrationProgress from "./RegistrationProgress";
import TermsConditions from "./TermsConditions";
import Loading from "../Loading";
import InfoPopup from "../../Containers/InfoPopup";
import SaveAndContinueConfirm from "../../Containers/Supplier/SaveAndContinueConfirm";
import WizzardBtns from "../../Containers/WizzardBtns";
import ProductTierSelector from "../../Containers/ProductTierSelector/ProductTierSelector";
import CompanyGroupingType from "../../Util/Enumerators/CompanyGroupingType";
import staticDataTypes, { StaticDataTypes } from "../../Util/Enumerators/staticDataTypes";
import StaticData from "../../Hooks/StaticData";

import {
  logOut,
  showLoadingScreen,
  hideLoadingScreen,
  setLastStepCompleted,
} from "../../Store/actions";
import { setOption } from "../../Store/optionActions";
import {
  setRegistrationObject,
  onRegistrationInputChange,
} from "../../Store/registrationActions";

import {
  saveCurrentSubscription,
  getTermsAndConditions,
  getRegistrationSelectedSubscription,
  sendSubscriptionEnquirySupportEmail,
} from "../../http/index";

import { addNewSubscription } from "../../http/Billing/billingApi";

import { loadSubscriptionTiers } from "../../Util/optionsLoader";
import { loadAddressDataAsync } from "../../Util/dataLoader";

import SendSubscriptionEnquiryModal from "../Subscription/SendSubscriptionEnquiryModal/SendSubscriptionEnquiryModal";

const mapStateToProps = (state) => ({
  companyTypes: state.staticData[StaticDataTypes.CompanyType] ?? [],
  data: {
    ...state.registration.prtInfo,
    ...state.registration.supplierInfo,
    companyId: state.currentCompanyId,
    addressInfo: state.registration.addressInfo,
  },
  subscriptions: state.options.subscriptions,
  userDetails: state.loggedInUserDetails,
});

const mapDispatchToProps = (dispatch) => ({
  setOption: (optionName, optionValue) => {
    dispatch(setOption(optionName, optionValue));
  },
  setPrtInfo: (prtInfo) => {
    dispatch(setRegistrationObject("prtInfo", prtInfo));
  },
  setPrtInfoField: (ev) => {
    dispatch(onRegistrationInputChange("prtInfo", ev));
  },
  showLoadingScreen: (msg) => {
    dispatch(showLoadingScreen(msg));
  },
  hideLoadingScreen: () => {
    dispatch(hideLoadingScreen());
  },
  setLastStepCompleted: (value) => {
    dispatch(setLastStepCompleted(value));
  },
  logOut: () => {
    dispatch(logOut());
  },
});

class RegistrationStep06 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTermsConditions: false,
      sponsored: false,
      showOnEnquireModal: false,
      selectedTierName: "",
      sponsorName: "",
      fundCompliance: false,
      sponsoredSubscriptionTierId: null,
      disabledSubscriptionTierIds: [],
      addressInfo: null,
    };
  }

  performSave = (onSuccess = null, stepNumber) => {
    // eslint-disable-next-line no-unused-vars
    this.props.showLoadingScreen();
    saveCurrentSubscription(
      {
        ...this.props.data,
        registrationStepCompleted:
          this.props.data.registrationStepCompleted > stepNumber
            ? this.props.data.registrationStepCompleted
            : stepNumber,
      },
      (subDetails) => {
        this.setState((prevState) => ({
          ...prevState,
          companySubscription: { ...subDetails },
        }));
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        this.props.hideLoadingScreen();
      }
    );
  };

  toggleConfirmSaveAndContinue = () => {
    this.setState((prevState) => ({
      showConfirmMsg: !prevState.showConfirmMsg,
    }));
  };

  onEnquireHandler = async (selectedTierId) => {
    const selectedTier = this.props.subscriptions.find((tier) => tier.id === selectedTierId);
    this.setState((prevState) => ({
      selectedTierName: selectedTier.name,
      showOnEnquireModal: !prevState.showOnEnquireModal,
    }));

    // send email
    await sendSubscriptionEnquirySupportEmail(selectedTierId);
  }

  saveAndContinueLater = () => {
    this.performSave(() => {
      this.props.logOut();
      this.props.hideLoadingScreen();
    }, 6);
  };

  toggleTermsConditions = () => {
    this.performSave(() => {
      this.props.hideLoadingScreen();
      this.setState((prevState) => ({
        showTermsConditions: !prevState.showTermsConditions,
      }));
    });
  };

  setSelected = (index) => {
    this.selectionMade = true;
    const ev = {
      target: {
        name: "subscriptionTierId",
        value: index,
      },
    };
    this.props.setPrtInfoField(ev);
  };

  componentDidMount = async () => {
    const prtInfo = {};
    await getRegistrationSelectedSubscription((resTiers) => {
      prtInfo.subscriptionTierId = resTiers.subscriptionTierId;
      this.setState({
        sponsored: resTiers.sponsored,
        sponsorName: resTiers.sponsorName,
        fundCompliance: resTiers.fundCompliance,
        sponsoredSubscriptionTierId: resTiers.sponsoredSubscriptionTierId,
        disabledSubscriptionTierIds: resTiers.disabledSubscriptionTierIds,
        recommendedSubscriptionTierId: resTiers.recommendedSubscriptionTierId,
      });
      getTermsAndConditions(this.props.data.companyId, (resTandC) => {
        prtInfo.termsConditionsId = resTandC.termsConditionsId;
        this.props.setPrtInfo(prtInfo);
        this.props.setLastStepCompleted(resTandC.registrationStepCompleted);
      });
      const sponsoredTier = this.state.sponsoredSubscriptionTierId;

      if (this.state.sponsored) {
        const ev = {
          target: {
            name: "subscriptionTierId",
            value: sponsoredTier,
          },
        };
        this.props.setPrtInfoField(ev);
      }
    });

    loadSubscriptionTiers(this.props.subscriptions, this.props.setOption);

    if (this.props.data.addressInfo?.physicalAddress == null) {
      const addressInfo = await loadAddressDataAsync(this.props.data.companyId);
      this.setState({
        addressInfo: addressInfo.physicalAddress,
      });
    } else {
      this.setState({
        addressInfo: this.props.data.addressInfo.physicalAddress,
      });
    }
  }

  onAcceptHandler = async () => {
    this.setState((prevState) => ({
      showTermsConditions: !prevState.showTermsConditions,
    }));
    this.props.showLoadingScreen();
    // retrieve selected subscription
    const selected = this.props.subscriptions.find(
      (sub) => sub.id === this.props.data.subscriptionTierId
    );

    // send message to API
    const subscription = {
      registrationNumber: this.props.data.registrationNumber,
      submitterName: `${this.props.data.registeredName} t/a ${this.props.data.tradingName} [#${this.props.data.companyId}]`,
      emailAddress: this.props.userDetails.emailAddress,
      address: this.state.addressInfo,
      vatNumber: this.props.data.vatNumber,
      subscriptionName: `${selected.name}`,
      subscriptionTierId: selected.id,
      SubscriptionId: this.state.companySubscription.companySubscriptionId,
      price: selected.monthlyPriceInclVAT,
    };

    // redirect to checkout or registration complete page
    if ((this.state.sponsored && this.state.sponsoredSubscriptionTierId === selected.id)
      || selected.monthlyPriceInclVAT === 0
      || selected.monthlyPriceInclVAT == null) {
      window.location.href = "/Account/RegistrationComplete";
    } else {
      const result = await addNewSubscription(subscription);
      window.location.href = result;
    }
  };

  render() {
    const dataFeatureCheck = useDataFeatureCheck();
    const currentCompanyType = this.props.companyTypes?.find(
      (companyType) => companyType.value === this.props.data.companyType);

    const isForeignCompany = currentCompanyType?.companyGroupingType === CompanyGroupingType.Foreign ?? false;

    return (
      <section id="isuppli-registration-step-06">
        <StaticData
          optionsToLoad={[
            staticDataTypes.CompanyType,
          ]}
        />
        <Loading />

        <InfoPopup />

        {this.state.showTermsConditions && (
          <TermsConditions
            // eslint-disable-next-line no-unneeded-ternary
            sponsored={(this.state.sponsored && this.props.data.subscriptionTierId === this.state.sponsoredSubscriptionTierId) ? true : false}
            // eslint-disable-next-line no-unneeded-ternary, max-len
            freeTier={(!this.state.sponsored && this.props.subscriptions.find((x) => x.id === this.props.data.subscriptionTierId).monthlyPriceInclVAT === 0) ? true : false}
            toggleModalCallback={() => {
              this.setState((prevState) => ({
                showTermsConditions: !prevState.showTermsConditions,
              }));
            }}
            onAccepted={this.onAcceptHandler}
          />
        )}
        {this.state.showOnEnquireModal && (
          <SendSubscriptionEnquiryModal
            Onclose={() => this.setState({
              showOnEnquireModal: false,
            })}
            selectedTierName={this.state.selectedTierName}
          />
        )}

        {this.state.showConfirmMsg && (
          <SaveAndContinueConfirm
            toggleModalCallback={this.toggleConfirmSaveAndContinue}
            saveAndContinueCallback={this.saveAndContinueLater}
          />
        )}

        <RegistrationProgress currentStep={7} />

        <div className="padding-lr-10vw">
          <div className="row top-margin-90px">
            <div className="col-12 col-lg-7">
              <div>
                <h3 className="margin-null">
                  {dataFeatureCheck(DataFeatures.BankingDetails)
                    ? "STEP 07"
                    : "STEP 06"}
                </h3>
              </div>
              <div>
                <h1>SUBSCRIPTION PACKAGE</h1>
              </div>
            </div>
          </div>
          <div className="row" style={{ paddingTop: "36px" }}>
            <div className="col-12 col-lg-9">
              {
                isForeignCompany && (
                  <Alert color="info">
                    We notice that you are a non South African registered company.
                    Our global application is in development for release in the near future,
                    and you are currently limited to the free B1LINK package.
                    <br />
                    <br />
                    We will notify you as soon as the option to upgrade your package becomes
                    available, and trust that you will still be able to make use of the platform
                    until that time. Thank you for your patience and understanding.
                  </Alert>
                )
              }
              {
                !isForeignCompany && (
                  <div>
                    <Alert color="info">
                      We wish to provide you with the system benefits fit for your
                      business needs. Please select a subscription package.
                    </Alert>
                  </div>
                )
              }
            </div>
          </div>
          <div className="top-margin-30px">&nbsp;</div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-12 offset-0 col-lg-10 offset-lg-1">
              <ProductTierSelector
                value={this.props.data.subscriptionTierId}
                onChange={(value) => { this.setSelected(value); }}
                onEnquire={((value) => this.onEnquireHandler(value))}
                sponsored={this.state.sponsored}
                sponsorName={this.state.sponsorName}
                fundCompliance={this.state.fundCompliance}
                sponsoredSubscriptionTierId={this.state.sponsoredSubscriptionTierId}
                disabledSubscriptionTierIds={this.state.disabledSubscriptionTierIds}
                recommendedSubscriptionTierId={this.state.recommendedSubscriptionTierId}
              />
            </div>
          </div>
        </div>

        <WizzardBtns
          history={this.props.history}
          backUrl="/registration/documents"
          nextUrl=""
          nextText="COMPLETE"
          onClickSaveAndContinue={this.toggleConfirmSaveAndContinue}
          onClickNext={() => this.toggleTermsConditions()}
        />

        <div style={{ paddingTop: "44px" }} />
      </section>
    );
  }
}

const mappedRegistrationStep06 = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationStep06);
export default withRouter(mappedRegistrationStep06);
