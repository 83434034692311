import React from "react";

const CheckBoxInput = (
  {
    label,
    onChange,
    checked,
    required,
    validationMessage,
    disabled,
  }
  :
  {
    checked: boolean,
    onChange: () => void,
    label: string,
    required?: boolean,
    validationMessage?: string,
    disabled?: boolean,
  }
) => {
  const id = Math.random().toString(36);
  return (
    <div className="custom-control custom-checkbox">
      <input
        disabled={disabled}
        type="checkbox"
        id={id}
        className="custom-control-input"
        checked={checked}
        onChange={() => onChange()}
        required={required}
      />
      <label className="custom-control-label" htmlFor={id}>{label}</label>
      {validationMessage && <div className="invalid-feedback">{validationMessage}</div>}
    </div>
  );
};

export default CheckBoxInput;
