import React, {
  useState, useEffect, useRef,
} from "react";
import axios, {
  CancelTokenSource, // eslint-disable-line no-unused-vars
} from "axios";
import { useHistory } from "react-router";
import { ListEmptyState, useDebounce } from "isuppli-react-components";
import InfiniteScroll from "../../Controls/InfiniteScroll/InfiniteScroll";
import MessengerHeader from "./MessengerHeader/MessengerHeader";
import ChatMessageHeader from "./ChatMessageHeader/ChatMessageHeader";
import ChatChannelCard from "./ChatChannelCard/ChatChannelCard";
import Styles from "./Messaging.module.scss";
import SecondarySearchInput from "../../Controls/SecondarySearchInput/SecondarySearchInput";
import MessageContent from "../Messaging/ChatMessageContent/ChatMessageContent";
import { getChannelList, searchMessages } from "../../http/Messaging/messagesApi";
// eslint-disable-next-line no-unused-vars
import { ChannelMessage, SearchItemMessage } from "../../http/Messaging/Models/Models";
import { dateTimeDayMonthLabelFormat } from "../../Util/valueFormatter";
import useSignalR, {
  SignalRNotifications,
  // eslint-disable-next-line no-unused-vars
  NewMessageNotification,
} from "../../Hooks/useSignalR";
import PageTypes from "../../http/Messaging/Models/PageTypes";
import useShowError from "../../Hooks/useShowError";
import ChatChannelListSection from "./ChatChannelListSection/ChatChannelListSection";

export default function Messaging() {
  const history = useHistory();
  // initalizing states
  const [hasMore, setHasMore] = useState(true);
  const [cardList, setCardList] = useState<Array<ChannelMessage>>([]);
  const [selectedContent, setSelectedContent] = useState<{
    channelId: number,
    messageId?: number
  }>();
  const [activeChannelInfo, setActiveChannelInfo] = useState<ChannelMessage>();
  const [showMessenger, setShowMessenger] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchHasMore, setSearchHasMore] = useState(true);
  const [searchResults, setSearchResults] = useState<Array<SearchItemMessage>>([]);
  const [unreadFilter, setUnreadFilter] = useState(false);
  const [sentMessageChannelId, setSentMessageChannelId] = useState<number>();
  const showError = useShowError();
  const scrollRef = useRef<HTMLDivElement>(null);
  const httpCancelToken = useRef<CancelTokenSource>();

  const rearrangeItems = (items: Array<any>, moveFromIndex: number, moveToIndex: number) => {
    const movingItem = items[moveFromIndex];
    items.splice(moveFromIndex, 1);
    items.splice(moveToIndex, 0, movingItem);
    return items;
  };

  const scrollToRef = () => {
    if (scrollRef.current != null) {
      scrollRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    setSentMessageChannelId(0);
  };

  const updateLastMessage = (lastMessage:string, channelId:number) => {
    const channelSlice = cardList.slice();
    const indexOfChannel = channelSlice.findIndex(
      (channel: { id: number; }) => channel.id === channelId
    );
    const oldMessage = channelSlice[indexOfChannel].lastMessage;
    channelSlice[indexOfChannel].lastMessage = oldMessage === undefined ? oldMessage
      : { ...oldMessage, content: lastMessage };
    channelSlice[indexOfChannel].updatedDate = (new Date()).toString();

    // Only update unreadMessageCount if not currently selected channel:
    if (channelId !== selectedContent?.channelId) {
      channelSlice[indexOfChannel].unreadMessageCount += 1;
    }

    const output = rearrangeItems(channelSlice, indexOfChannel, 0);
    setSentMessageChannelId(channelId);
    setCardList(output);
    scrollToRef();
  };

  const onMessageSentHandler = (lastMessage: string, channelId: number) => {
    // Clear search term
    setSearchTerm("");

    setSelectedContent({
      channelId,
    });
    updateLastMessage(lastMessage, channelId);
  };

  const clearUnreadMessageCounter = (id:number) => {
    setCardList((prevList) => {
      const channelSlice = [...prevList];
      const indexOfChannel = channelSlice.findIndex(
        (channel: { id: number; }) => channel.id === id
      );
      channelSlice[indexOfChannel].unreadMessageCount = 0;
      return channelSlice;
    });
  };

  // setup SignalR connection
  const disconnect = useSignalR<NewMessageNotification>(
    SignalRNotifications.ChannelUpdates,
    async (data) => {
      // check if the channel is in the current list
      if (cardList.some((c) => c.id === data.channelId)) {
        updateLastMessage(data.newMessage.content, data.channelId);
        return;
      }

      // ignore changes to current selected channel
      if (selectedContent?.channelId !== data.channelId) {
        // channel is not in the list
        // get channel info
        const channelInfo = await getChannelList({
          limit: 1,
          pageId: data.channelId,
          pageType: PageTypes.From,
          unreadFilter,
        });
        setCardList((prevList) => [...channelInfo, ...prevList]);
      }
    }
  );

  // cleanup when component is removed
  useEffect(() => () => {
    if (httpCancelToken.current != null) {
      httpCancelToken.current.cancel();
    }
    disconnect();
  }, [disconnect]);

  const loadMoreHandler = async () => {
    try {
      if (httpCancelToken.current != null) {
        httpCancelToken.current.cancel();
      }
      httpCancelToken.current = axios.CancelToken.source();

      const limit = 20;
      const lastId = cardList.length > 0
        ? cardList[cardList.length - 1].id
        : undefined;

      const moreChannels = await getChannelList({
        limit,
        pageId: lastId,
        unreadFilter,
        config: {
          cancelToken: httpCancelToken.current.token,
        },
      });
      if (moreChannels.length < limit) {
        setHasMore(false);
      }

      setCardList((prevState) => [...prevState, ...moreChannels]);

      return true;
    } catch (error) {
      httpCancelToken.current = undefined;
      if (!axios.isCancel(error)) {
        showError();
      }
      return false;
    }
  };

  useEffect(() => {
    // select the first card by default
    if (selectedContent?.channelId == null && cardList.length > 0) {
      setSelectedContent({
        channelId: cardList[0].id,
      });
      clearUnreadMessageCounter(cardList[0].id);

      setActiveChannelInfo(cardList[0]);
      return;
    }

    setActiveChannelInfo(cardList.find((c) => c.id === selectedContent?.channelId));
  }, [selectedContent, cardList]);

  const onCardClickHandler = (id: number) => {
    setSelectedContent({
      channelId: id,
    });
    setShowMessenger(true);
    clearUnreadMessageCounter(id);
  };

  const onSearchCardClickHandler = (messageId: number, channelId: number) => {
    setSelectedContent({
      channelId,
      messageId,
    });
  };

  const channelCardItems = ChatChannelListSection(
    {
      messageCardList: cardList,
      selectedContent,
      onCardClickHandler,
      sentMessageChannelId,
      scrollRef,
    }
  );

  const searchCardItems = searchResults.map((searchItem, index) => (
    <div key={index}>
      <ChatChannelCard
        active={searchItem.messageId === selectedContent?.messageId}
        companyName={`${searchItem.userDisplayName} (${searchItem.channelName})`}
        messageSnippet={searchItem.messageContent}
        onClickCallback={() => onSearchCardClickHandler(searchItem.messageId, searchItem.channelId)}
        messageDate={dateTimeDayMonthLabelFormat(searchItem.sentDate)}
        className={Styles.ChatChannelCard}
      />
    </div>
  ));

  const showMessengerContent = showMessenger ? Styles.ShowMessenger : Styles.ShowChannel;

  const debouncing = useRef(false);

  const [queSearch] = useDebounce(() => {
    debouncing.current = false;

    setSearchHasMore(false);
    setSearchHasMore(true);
  }, 500);

  const onSearchChangeHandler = (newValue: string) => {
    setSearchTerm(newValue);
    queSearch();
    // set list into loading state
    setSearchHasMore(true);
    setSearchResults([]);
    debouncing.current = true;

    if (newValue.length === 0 && cardList.length > 0 && selectedContent?.messageId != null) {
      setSelectedContent({
        channelId: cardList[0].id,
      });
    }
  };

  const loadMoreSearchHandler = async () => {
    if (httpCancelToken.current != null) {
      httpCancelToken.current.cancel();
    }
    if (debouncing.current) {
      return false;
    }

    try {
      httpCancelToken.current = axios.CancelToken.source();

      const limit = 20;

      const searchAfter = searchResults.length > 0
        ? searchResults[searchResults.length - 1].messageId
        : undefined;
      const searchMoreResults = await searchMessages(searchTerm, limit, {
        cancelToken: httpCancelToken.current.token,
      }, searchAfter, unreadFilter);
      httpCancelToken.current = undefined;
      if (searchMoreResults.searchItems.length < limit) {
        setSearchHasMore(false);
      }

      setSearchResults((prevState) => [...prevState, ...searchMoreResults.searchItems]);

      return true;
    } catch (error) {
      httpCancelToken.current = undefined;
      if (!axios.isCancel(error)) {
        showError();
      }
      return false;
    }
  };

  const onFilterChangeHandler = (readOnly : boolean) => {
    setUnreadFilter(readOnly);
  };

  useEffect(() => {
    setCardList([]);
    setHasMore(true);
  }, [unreadFilter]);

  const emptySearchList = !searchHasMore && searchCardItems.length === 0;
  const emptyChannelList = !hasMore && cardList.length === 0;

  return (
    <section className={[Styles.Messaging, showMessengerContent, "d-flex"].join(" ")}>
      <div className="container-fluid d-flex flex-column">
        <div className="row">
          <div className="col-12 offset-0 col-lg-10 offset-lg-1">
            <div className={Styles.Header}>
              <MessengerHeader
                onMessageSent={(message, channel) => {
                  if (selectedContent?.channelId !== channel) {
                    setSelectedContent({
                      channelId: channel,
                    });
                  }
                  updateLastMessage(message.content, channel);
                }}
              />
            </div>
          </div>
        </div>
        <div className="row flex-fill pb-5">
          <div className="col-12 offset-0 col-lg-10 offset-lg-1 d-flex">
            <div className={`${Styles.MessengerContent} flex-fill d-flex`}>
              <div className={`${Styles.ChannelSide} flex-column`}>
                <div className={Styles.ChatChannelSearch}>
                  <SecondarySearchInput
                    value={searchTerm}
                    onChange={onSearchChangeHandler}
                    placeholder="Search Messages..."
                    onFilterChange={(selectedValue) => onFilterChangeHandler(selectedValue === 1)}
                    selectedFilterValue={unreadFilter ? 1 : 0}
                    filterOptions={[{ displayValue: "All Messages", value: 0 }, { displayValue: "Unread Messages", value: 1 }]}
                  />
                </div>
                <div className={` ${Styles.ChatChannelList} flex-fill`}>
                  <div className="position-relative w-100 h-100">
                    <div className="position-absolute w-100 h-100 d-flex">
                      <div className="overflow-auto flex-fill">
                        {searchTerm.length === 0
                            && (
                              <InfiniteScroll
                                loadMore={loadMoreHandler}
                                hasMore={hasMore}
                              >
                                {channelCardItems}
                              </InfiniteScroll>
                            )}
                        {
                          searchTerm.length > 0 && !emptySearchList
                               && (
                                 <InfiniteScroll
                                   loadMore={loadMoreSearchHandler}
                                   hasMore={searchHasMore}
                                 >
                                   {searchCardItems}
                                 </InfiniteScroll>
                               )
                        }
                        {searchTerm.length > 0 && emptySearchList
                          && (
                            <div className={Styles.EmptyListState}>
                              <ListEmptyState
                                fullWidth
                                heading="NO RESULTS FOUND"
                              >
                                <p>
                                  Your search did not match any results...
                                </p>
                                <p className="text-left mx-3">
                                  <b>Suggestions:</b>
                                </p>
                                <ol className="text-left mx-3">
                                  <li>Make sure that all words are spelled correctly</li>
                                  <li>Try different keywords</li>
                                  <li>Try fewer keywords</li>
                                </ol>

                              </ListEmptyState>
                            </div>
                          )}
                        {unreadFilter && emptyChannelList && searchTerm.length === 0
                            && (
                              <div className={Styles.EmptyListState}>
                                <ListEmptyState
                                  fullWidth
                                  heading="NO RESULTS FOUND"
                                >
                                  <p>
                                    Your search did not match any results...
                                  </p>
                                  <p>
                                    No unread messages found
                                  </p>
                                </ListEmptyState>
                              </div>
                            )}
                        {!unreadFilter && emptyChannelList && searchTerm.length === 0
                            && (
                              <div className={Styles.EmptyListState}>
                                <ListEmptyState
                                  heading="NO MESSAGES FOUND"
                                  button={
                                    {
                                      text: "Search supplier database",
                                      onClick: () => history.push("/marketplace/search"),
                                    }
                                  }
                                >
                                  You can engage with suppliers directly
                                  from the supplier search page...
                                </ListEmptyState>
                              </div>
                            )}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className={`${Styles.Message} position-relative`}>
                {activeChannelInfo && selectedContent
                  && (
                    <div className="position-absolute w-100 h-100 d-flex flex-column">
                      <div className={`${Styles.ChatCompanyDetailsHeader}`}>
                        <ChatMessageHeader
                          companyName={activeChannelInfo.name}
                        />
                      </div>
                      <div className="flex-fill d-flex position-relative">
                        <div className="position-absolute w-100 h-100 d-flex flex-column">
                          <MessageContent
                            selected={selectedContent}
                            onMessageSent={
                              (newMessage) => onMessageSentHandler(
                                newMessage,
                                activeChannelInfo.id
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
