export const SET_OPTION = "SET_OPTION";

export function setOption(optionName, optionValues) {
  return {
    type: SET_OPTION,
    payload: {
      optionName,
      optionValues,
    },
  };
}
