import axios from "axios";
import { makeRequestAsync } from "../util";
// eslint-disable-next-line no-unused-vars
import { UsersMessage } from "./Models/UsersMessage";

// eslint-disable-next-line no-unused-vars
import { EditUserMessage } from "./Models/EditUserMessage";

// eslint-disable-next-line no-unused-vars
import { NewUserMessage } from "./Models/NewUserMessage";
import { UserSearchCriteria } from "./Models/UserSearchCriteria"; // eslint-disable-line no-unused-vars

export * from "./Models/UserMessage";
export * from "./Models/UsersMessage";
export * from "./Models/NewUserMessage";
export * from "./Models/EditUserMessage";

export async function getUsers(searchCriteria: UserSearchCriteria) {
  // build url parameters
  const urlParameters = [];
  if (searchCriteria.limit != null) {
    urlParameters.push(`limit=${searchCriteria.limit}`);
  }
  if (searchCriteria.offset != null) {
    urlParameters.push(`offset=${searchCriteria.offset}`);
  }
  if (searchCriteria.searchTerm != null) urlParameters.push(`fullName=${searchCriteria.searchTerm}`);
  if (searchCriteria.statusFilter != null) {
    urlParameters.push(`statusFilter=${searchCriteria.statusFilter}`);
  }
  if (searchCriteria.sortOption != null) {
    urlParameters.push(`sortOption=${searchCriteria.sortOption}`);
  }
  if (searchCriteria.selectedRoleIds != null) {
    searchCriteria.selectedRoleIds.forEach((selectedRoleId) => {
      urlParameters.push(`selectedRoleIds=${selectedRoleId}`);
    });
  }
  if (searchCriteria.selectedBusinessUnitIds != null) {
    searchCriteria.selectedBusinessUnitIds.forEach((selectedBusinessUnitId) => {
      urlParameters.push(`selectedBusinessUnitIds=${selectedBusinessUnitId}`);
    });
  }

  const url = `/api/user/users?${urlParameters.join("&")}`;
  return makeRequestAsync(() => axios
    .get(url)) as Promise<UsersMessage>;
}

export async function addUser(newUser: NewUserMessage) {
  const url = "/api/user/users";
  return makeRequestAsync(() => axios.post(url, newUser)) as Promise<void>;
}

export async function editUser(userId: string, user: EditUserMessage) {
  const url = `/api/user/users/${userId}`;
  return makeRequestAsync(() => axios.put(url, user)) as Promise<void>;
}

export async function deactivateUser(userId: string) {
  const url = `/api/user/users/${userId}/deactivate`;
  return makeRequestAsync(() => axios.put(url)) as Promise<void>;
}

export async function activateUser(userId: string) {
  const url = `/api/user/users/${userId}/activate`;
  return makeRequestAsync(() => axios.put(url)) as Promise<void>;
}

export async function deleteUser(userId: string) {
  const url = `/api/user/users/${userId}/delete`;
  return makeRequestAsync(() => axios.delete(url)) as Promise<void>;
}

export async function resendInvite(userId: string) {
  const url = `/api/user/users/${userId}/sendInvite`;
  return makeRequestAsync(() => axios.post(url)) as Promise<void>;
}
