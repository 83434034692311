import axios from "axios";
import { AddOrUpdateConfigurationSupplierTypeMessage } from "./AddOrUpdateConfigurationSupplierTypeMessage";// eslint-disable-line no-unused-vars
import { makeRequestAsync } from "../util";
import { ConfigurationSupplierTypeMessage } from "./ConfigurationSupplierTypeMessage";// eslint-disable-line no-unused-vars

export const getConfigurationSupplierTypes = async () => {
  const url = "api/enterprise/supplierTypes";
  return await makeRequestAsync(() => axios
    .get(url)) as Promise<Array<ConfigurationSupplierTypeMessage>>;
};

export const getConfigurationSuppilerType = async (supplierTypeId: number) => {
  const url = `api/enterprise/supplierTypes/${supplierTypeId}`;
  return await makeRequestAsync(() => axios
    .get(url)) as Promise<ConfigurationSupplierTypeMessage>;
};

export const addOrUpdateConfigurationSupplierType = async (message : AddOrUpdateConfigurationSupplierTypeMessage) => {
  const url = "api/enterprise/supplierTypes";
  return await makeRequestAsync(() => axios
    .post(url, message)) as Promise<number>;
};

export const deleteConfigurationSuppilerType = async (supplierTypeId: number) => {
  const url = `api/enterprise/supplierTypes/${supplierTypeId}`;
  return await makeRequestAsync(() => axios.delete(url)) as Promise<void>;
};
