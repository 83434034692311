import React from "react";
import { Progress } from "reactstrap";
import ImageButton from "../../ImageButton/ImageButton";
import Styles from "./MessageAttachment.module.scss";
import { ReactComponent as SvgClose } from "../../../images/close.svg";

const MessageAttachment = ({
  fileName,
  progress,
  onDelete,
  canClose,
  link,
  className = "",
  uploadError,
}: {
  fileName: string;
  progress: number;
  canClose: boolean;
  onDelete?: () => void;
  link: string;
  className?: string;
  uploadError?: string;
}) => {
  const completed = progress === 1 || progress === -1;
  const failed = progress === -1;

  let cardPaddingClasses = "p-2";
  if (completed) {
    cardPaddingClasses = canClose ? "pl-2 py-2" : "p-2";
  } else {
    // in progress
    cardPaddingClasses = "px-2 pt-2";
  }
  return (
    <div
      className={`${Styles.MessageAttachment} rounded ${className} d-flex flex-column`}
    >
      <div className="flex-fill d-flex align-items-center">

        {failed
          ? (
            <div className={cardPaddingClasses}>
              <div>{fileName}</div>
              <div className={`${Styles.Error} text-center`}>
                {uploadError !== ""
                  ? uploadError
                  : "Upload Failed. Please try again."}
              </div>
            </div>
          )
          : (
            <a className={`d-block ${cardPaddingClasses}`} href={link} download={fileName}>
              {fileName}
            </a>
          )}
        {completed && canClose ? (
          <ImageButton
            svg={SvgClose}
            onClick={() => (onDelete == null ? null : onDelete())}
            alt="Delete attachment"
            className="text-danger"
          />
        )
          : null}

      </div>

      {!completed ? (
        <Progress className={`${Styles.Progress} m-2`} value={progress * 100} />
      ) : null}
    </div>
  );
};

export default MessageAttachment;
