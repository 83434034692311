import React, { useState, useEffect, Fragment } from "react";
import {
  PageHeading,
  SystemFeatures, WithFeature, useSystemFeatureCheck,
} from "isuppli-react-components";
import DashboardSuppliersSummaryCard from "./DashboardSuppliersSummaryCard/DashboardSuppliersSummaryCard";
import useShowError from "../../Hooks/useShowError";
import {
  getDashboardSuppliersSummary,
} from "../../http/Dashboard/dashboardApi";
import navMenuItems from "../../Util/menuItems";
// eslint-disable-next-line no-unused-vars
import { DashboardSupplierSummaryMessage } from "../../http/Dashboard/Models/Models";
import supplierFilterOptions from "../../Util/Enumerators/supplierFilterOptions";
import SearchSupplierDatabaseSection from "./SearchSupplierDatabaseSection/SearchSupplierDatabaseSection";
import DashboardOverallRatingCard from "./OverallRatingCard/OverallRatingCard";
import MessagesSummaryCard from "./MessagesSummaryCard/MessagesSummaryCard";
import BulletinBoardSummaryCard from "./BulletinBoardSummaryCard/BulletinBoardSummaryCard";
import ProfileStatusSummary from "./ProfileStatusSummary/ProfileStatusSummay";
import SupplierTypeSummary from "./SupplierTypeSummary/SupplierTypeSummary";
import SupportingDocumentsSummary from "./SupportingDocumentsSummary/SupportingDocumentsSummary";
import SupplierContractAgreementSummary from "./SupplierContractAgreementSummary/SupplierContractAgreementSummary";
import SupplierPackagesSummary from "./SupplierPackagesSummary/SupplierPackagesSummary";
import SupplierStatusSummary from "./SupplierStatusSummary/SupplierStatusSummary";
import SupplierBEEComplianceSummary from "./SupplierBEEComplianceSummary/SupplierBEEComplianceSummary";
import SupplierRegulatoryComplianceSummary from "./SupplierRegulatoryComplianceSummary/SupplierRegulatoryComplianceSummary";
import OnboardingStatusSummary from "./OnboardingStatusSummary/OnboardingStatusSummary";

import { getMyCompanyRating } from "../../http/MyCompany/myCompanyAPI";

const Dashboard = () => {
  const featureCheck = useSystemFeatureCheck();
  const [loading, setLoading] = useState(true);
  const [suppliersSummary, setSuppliersSummary] = useState<DashboardSupplierSummaryMessage>();
  const [rating, setRating] = useState(0);
  const [totalRatings, setTotalRatings] = useState(0);

  const showError = useShowError();

  useEffect(() => {
    const loader = async () => {
      setLoading(true);
      try {
        // api calls
        const supplierSummaryData = await getDashboardSuppliersSummary();
        setSuppliersSummary(supplierSummaryData);

        const result = await getMyCompanyRating();
        setRating(result?.rating ?? 0);
        setTotalRatings(result?.totalRatings ?? 0);
      } catch (error) {
        showError();
      }
      setLoading(false);
    };
    loader();
  }, [setSuppliersSummary, setRating, setTotalRatings, setLoading, showError]);

  return (
    <div className="container mb-5">
      <div className="row">
        <div className="col-12">
          <PageHeading
            heading="Dashboard"
            menuItems={navMenuItems}
          />
        </div>
      </div>
      {/* Supplier stats */}
      <div className="row">
        <WithFeature feature={SystemFeatures.DashboardSupplierCompaniesSummaries}>
          <div className="col-sm-12  col-md-6 col-lg-3 mb-3">
            <DashboardSuppliersSummaryCard
              loading={loading}
              title="Total Existing Suppliers"
              background="info"
              value={suppliersSummary?.totalExistingSuppliers || 0}
              changePercentage={suppliersSummary?.percentageChangeExistingSuppliers}
              linkUrl={`/marketplace/search?sf=${supplierFilterOptions.Existing}`}
            />
          </div>
        </WithFeature>
        <div className="col-sm-12  col-md-6 col-lg-3 mb-3">
          <DashboardSuppliersSummaryCard
            loading={loading}
            title={
              featureCheck(SystemFeatures.DashboardSupplierCompaniesSummaries)
                ? "Total Potential Suppliers"
                : "Total Directory Companies"
            }
            value={suppliersSummary?.totalPotentialSuppliers || 0}
            changePercentage={suppliersSummary?.percentageChangePotentialSuppliers}
            linkUrl={`/marketplace/search?sf=${supplierFilterOptions.Potential}`}
          />
        </div>
        <div className="col-12 col-lg-6 mb-3">
          <ProfileStatusSummary />
        </div>
      </div>
      <WithFeature feature={SystemFeatures.SupplierSearch}>
        <SearchSupplierDatabaseSection />
      </WithFeature>
      <WithFeature feature={SystemFeatures.DashboardBulletinBoardSummary}>
        <Fragment>
          <BulletinBoardSummaryCard />
        </Fragment>
      </WithFeature>
      <WithFeature feature={SystemFeatures.DashboardSupplierCompaniesSummaries}>
        <Fragment>
          <SupplierStatusSummary />
          <SupplierTypeSummary />
          <SupplierPackagesSummary />
          <SupplierRegulatoryComplianceSummary />
          <SupplierBEEComplianceSummary />
          <SupportingDocumentsSummary />
          <SupplierContractAgreementSummary />
          <OnboardingStatusSummary />
        </Fragment>
      </WithFeature>
      <div className="row mt-4">
        {/* Messaging */}
        <WithFeature feature={SystemFeatures.Messaging}>
          <div className="col-12 col-lg-6">
            <MessagesSummaryCard />
          </div>
        </WithFeature>
        {/* Star rating */}
        <div className="col-12 col-lg-6">
          <DashboardOverallRatingCard
            rating={rating}
            totalRatings={totalRatings}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
