import React, { Fragment } from "react";
import UtilButton from "../UtilButton/UtilButton";
// eslint-disable-next-line no-unused-vars
import { PageButton } from "./Interfaces/PageButton";
import Line from "../Line/Line";
import Styles from "./PageButtons.module.scss";

const PageButtons = (
  {
    buttons,
    hasBack = true,
    onBackClick,
    className = "",
  }
  :
  {
    buttons: Array<PageButton>,
    hasBack?: boolean,
    onBackClick?: () => void,
    className? : string,
  }
) => {
  const backButton = (
    <UtilButton
      className={`${Styles.BackButton} w-100`}
      color="primary"
      onClick={() => { if (onBackClick != null) { onBackClick(); } }}
      location="bottom"
      outline
    >
      Back
    </UtilButton>
  );

  const utilButtons = buttons.map((button, index) => {
    const classes: string[] = [];
    classes.push(index === 0 ? "ml-xl-auto" : "ml-xl-3");
    classes.push(index === buttons.length - 1 ? Styles.NextButton : "mb-3 mb-xl-0");

    return (
      <UtilButton
        className={classes.join(" ")}
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        color={button.color}
        onClick={button.onClick}
        location="bottom"
      >
        <Fragment>
          <span className="desktop-version">{button.text}</span>
          <span className="mobile-version">{button.mobileText ?? button.text}</span>
        </Fragment>
      </UtilButton>
    );
  });
  return (
    <div className={`${className} row`}>
      <div className="col-xl-12 col-10 offset-1 offset-xl-0">
        <div className="px-xl-5 pb-4">
          <div>
            <Line color="primary" className="desktop-version" />
            <div className="d-flex flex-column flex-xl-row">
              {
                hasBack
                && (<div className="desktop-version">{backButton}</div>)
              }
              {/* All but the last button */}
              {utilButtons.filter((_, i) => i < utilButtons.length - 1)}

              <Line className="mobile-version" color="primary" />
              <div className={`${Styles.BottomButtonContainer} ${!hasBack && buttons.length === 1 ? "ml-xl-auto" : ""}`}>
                {
                  hasBack
                  && (<div className={`${Styles.BackButton} mobile-version`}>{backButton}</div>)
                }
                {/* Last button */}
                {utilButtons.filter((_, i) => i === utilButtons.length - 1)}
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default PageButtons;
export * from "./Interfaces/PageButton";
