import React, { Component } from "react";

import FileUploader from "../../Controls/FileUploader";

class TableActions extends Component {
  onDocumentUploading = () => {};

  onDocumentUploaded = () => {};

  render() {
    const btns = [];
    if (this.props.onViewCallback) {
      btns.push(
        <span
          key={1}
          className="clickable"
          onClick={() => {}}
        >
          <img src="/svg/view-icon.svg" alt="" />
          <span className="text-primary font-underline"> view</span>
        </span>
      );
    }
    if (this.props.onDeleteCallback) {
      btns.push(
        <span
          key={0}
          className="clickable"
          onClick={() => {}}
        >
          <img src="/svg/trash-icon.svg" alt="" />
          <span className="text-primary font-underline"> delete</span>
        </span>
      );
    }
    if (this.props.onUploadCallback) {
      btns.push(
        <span key={2} className="justify-center">
          <img src="/svg/upload.svg" alt="" />
          <span className="text-primary font-underline">
            <FileUploader
              index={0}
              labelText="upload"
              uploadUrl=""
              labelClass="font-underline"
              onFileUploadingCallback={this.onDocumentUploading}
              onFileUploadedCallback={this.onDocumentUploaded}
            />
          </span>
        </span>
      );
    }
    return <section className="full-width flex-around">{btns}</section>;
  }
}

export default TableActions;
