enum pendingVendorDetailChangeType {
  /* eslint-disable no-unused-vars */
  CompanyData,
  VendorDetails,
  BankAccounts,
  ContactDetails,
  General,
}

export const pendingVendorDetailToString = (
  value: pendingVendorDetailChangeType
): string => {
  switch (value) {
    case pendingVendorDetailChangeType.CompanyData:
      return "Company Data";
    case pendingVendorDetailChangeType.VendorDetails:
      return "Vendor Details";
    case pendingVendorDetailChangeType.BankAccounts:
      return "Banking Account Details";
    case pendingVendorDetailChangeType.ContactDetails:
      return "Contact Details";
    case pendingVendorDetailChangeType.General:
      return "General";
    default:
      return "unknown";
  }
};

export default pendingVendorDetailChangeType;
