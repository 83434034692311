import React, { Fragment } from "react";
import {
  FormGroup,
  InputGroup,
  Label,
  Input,
} from "reactstrap";
import InputGroupImageAddon from "../InputGroupImageAddon/InputGroupImageAddon";

export type InputType =
  | "text"
  | "email"
  | "select"
  | "file"
  | "radio"
  | "checkbox"
  | "textarea"
  | "button"
  | "reset"
  | "submit"
  | "date"
  | "datetime-local"
  | "hidden"
  | "image"
  | "month"
  | "number"
  | "range"
  | "search"
  | "tel"
  | "url"
  | "week"
  | "password"
  | "datetime"
  | "time"
  | "color";

const TextInput = ({
  label,
  icon,
  readOnly,
  type,
  placeholder,
  value,
  onChange,
  required,
  className = "",
  validationMessage,
  formatValue,
  min,
  max,
  maxLength,
  minLength,
  disabled = false,
  invalid,
}
:
{
  label: string,
  icon?: "building" | "envelope" | "phone" | "person",
  readOnly?: boolean,
  type?: InputType,
  placeholder?: string,
  value: string,
  onChange: (newValue: string) => void,
  required?: boolean,
  className? : string,
  validationMessage?: string,
  formatValue?: (value: string) => string,
  min?: number,
  max?: number,
  maxLength?: number,
  minLength?: number,
  disabled?: boolean,
  invalid?: boolean,
}) => {
  const inputId = `${label}-${Math.random().toString(36).substring(2)}`;
  const input = (
    <Fragment>
      <Input
        invalid={invalid}
        readOnly={readOnly}
        type={type}
        name={inputId}
        id={inputId}
        placeholder={placeholder}
        value={value}
        min={min}
        max={max}
        maxLength={maxLength}
        minLength={minLength}
        disabled={disabled}
        onChange={(e) => {
          if (formatValue != null) {
            const newValue = formatValue(e.target.value);
            onChange(newValue);
            return;
          }
          onChange(e.target.value);
        }}
        required={required}
      />
      {validationMessage
        && (
          <div className="invalid-feedback">
            {validationMessage}
          </div>
        )}
    </Fragment>
  );

  return (
    <FormGroup className={className}>
      <Label for={inputId}>{label}</Label>
      {icon != null ? (
        <InputGroup>
          {icon && <InputGroupImageAddon icon={icon} addonType="prepend" />}
          {input}
        </InputGroup>
      )
        : input}

    </FormGroup>
  );
};

export default TextInput;
