import
// eslint-disable-next-line no-unused-vars
SystemFeatures,
{ hasFeature, hasSomeFeature } from "../utils/enums/SystemFeatures";

let allowedFeatures = SystemFeatures.None;

export const setupAllowedFeatures = (newAllowedFeatures: SystemFeatures) => {
  allowedFeatures = newAllowedFeatures;
};

export const useSystemFeatureCheck = (strict: boolean = false) => (
  feature: SystemFeatures
) => (strict ? hasFeature(allowedFeatures, feature) : hasSomeFeature(allowedFeatures, feature));
