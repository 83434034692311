import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ModalPopup,
  SystemFeatures,
  useSystemFeatureCheck,
  Spinner,
  useToast,
  WithDataFeature,
  DataFeatures,
  hasDataFeature,
} from "isuppli-react-components";
import { getCompanyOptionsAsync, getKeyContactDetailsAsync } from "../../http/gets";
// eslint-disable-next-line no-unused-vars
import { ContactPersonMessage, CompanyContactsMessage } from "../../http/MyCompany/Models/Models";
import { saveKeyStaffContactDetails } from "../../http/posts";
import AgreementsTable from "../AgreementsTable/AgreementsTable";
import KeyContactStaff from "./KeyContactStaff";
import useShowError from "../../Hooks/useShowError";

import ClientOfferingSummary from "./ClientOfferingSummary/ClientOfferingSummary";
import { getSupplierTypeShareholdersConfigStatus as getBuyersSupplierTypeShareholdersConfigStatus, saveShareholders } from "../../http/Shareholders/ShareholderApi";
import ShareholderTable from "../ShareholdersTable/ShareholderTable";
// eslint-disable-next-line no-unused-vars
import { ReduxState } from "../../Store/ReduxState";
import InfoPopup from "../InfoPopup";
import { hideInfoPopup, showInfoPopup } from "../../Store/actions";
import DropdownInput from "../../Controls/DropdownInput";
import staticCompanyDataTypes from "../../Util/Enumerators/staticCompanyDataTypes";
import { objectToDisplayObject } from "../../Hooks/useStaticCompanyData";
import { getClientSpecificInfoAsTheSupplierAsync, saveClientSpecificInfoAsTheSupplierAsync } from "../../http/Client/ClientApi";

const SupplierClientDetailsModal = (
  {
    onClose,
    selectedCompanyId,
  }
    :
    {
      onClose: () => void,
      selectedCompanyId: number
    }
) => {
  const featureCheck = useSystemFeatureCheck();
  const canEdit = featureCheck(SystemFeatures.CompanyProfileEdit);

  const topRef = useRef<null | HTMLDivElement>(null);
  const scrollIntoView = () => topRef.current?.scrollIntoView();
  const [loading, setLoading] = useState(false);
  const [showShareHoldersTable, setShowShareHoldersTable] = useState(false);
  const [businessTypeOptions, setBusinessTypeOtions] = useState<Array<any>>();
  const [businessType, setBusinessType] = useState(-1);
  const [hasBusinessType, setHasBusinessType] = useState(false); // [TODO] remove this, Business Type bug
  const showError = useShowError();
  const showToast = useToast();
  const dispatch = useDispatch();

  const [keyContactDetails, setKeyContactDetails] = useState<CompanyContactsMessage>({
    registrationStepCompleted: 0,
    contactPersons: [],
    hasTelephoneValidation: false,
  });

  const loadData = useCallback(async () => {
    const result = await getKeyContactDetailsAsync(selectedCompanyId);

    setKeyContactDetails({
      registrationStepCompleted: result.registrationStepCompleted,
      contactPersons: result.contactPersons,
      hasTelephoneValidation: result.hasTelephoneValidation,
    });

    const isShareholdersRequirementConfigured = await getBuyersSupplierTypeShareholdersConfigStatus(selectedCompanyId);
    setShowShareHoldersTable(isShareholdersRequirementConfigured);

    const buyerOptions = await getCompanyOptionsAsync(
      [
        staticCompanyDataTypes.businessTypes,
      ],
      selectedCompanyId
    );
    setBusinessTypeOtions(buyerOptions.businessTypes.map((item : any) => ({ ...objectToDisplayObject(item) })));
    const clientSpecificData = await getClientSpecificInfoAsTheSupplierAsync(selectedCompanyId);
    setBusinessType(clientSpecificData?.businessType?.id ?? -1);
    setHasBusinessType(hasDataFeature(clientSpecificData?.supplierType?.dataFeatures, DataFeatures.BusinessTypes));
  }, [selectedCompanyId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const shareholdersData = useSelector((state: ReduxState) => (state.registration.supplierInfo.shareholders?.shareholderDetails || []));

  const onClickSave = async () => {
    const validation : {
      isValid: boolean,
      msgs: { [key: string]: string },
    } = { isValid: true, msgs: {} };

    // validate business type
    if (businessTypeOptions != null
        && businessTypeOptions.length > 0
        && businessType <= 0
        && hasBusinessType) {
      validation.isValid = false;
      validation.msgs = {
        ...validation.msgs,
        shareholders: "Please select a valid Business Type",
      };
    }

    // validate shareholder data
    if (shareholdersData.length > 0) {
      let totalSharePercentageCount = 0;
      shareholdersData.forEach((sh) => {
        totalSharePercentageCount += sh.sharePercentage ?? 0;
      });

      let allShareholdersHaveDocs = false;
      allShareholdersHaveDocs = shareholdersData.every((shm) => shm.shareholderIdentityDocumentMessage
                                                      && shm.shareholderIdentityDocumentMessage.filename !== null);

      if (totalSharePercentageCount !== 100) {
        validation.isValid = false;
        validation.msgs = {
          ...validation.msgs,
          shareholders: "Shareholders' cumulative shares % must equal 100",
        };
      }

      if (allShareholdersHaveDocs === false) {
        validation.isValid = false;
        validation.msgs = {
          ...validation.msgs,
          shareholders: "All shareholders require Identity/Registration documents",
        };
      }
    } else if (showShareHoldersTable) {
      // shareholder details are manadtory
      validation.isValid = false;
      validation.msgs = {
        ...validation.msgs,
        shareholders: "Please include a shareholder",
      };
    }

    if (validation.isValid) {
      setLoading(true);
      await saveClientSpecificInfoAsTheSupplierAsync(
        {
          buyerCompanyId: selectedCompanyId,
          businessTypeId: businessType,
        }
      );

      if (shareholdersData.length > 0) {
        await saveShareholders(shareholdersData);
      }

      saveKeyStaffContactDetails(
        keyContactDetails,
        () => {
          setLoading(false);
          showToast("My Clients information saved");
          dispatch(hideInfoPopup());
          onClose();
        }, () => {
          setLoading(false);
          showError();
        }
      );
    } else {
      scrollIntoView();
      dispatch(showInfoPopup(
        "This page contains missing information.",
        "To continue please amend/complete the following:",
        Object.keys(validation.msgs).map((key) => validation.msgs[key])
      ));
    }
  };

  return (
    <ModalPopup
      heading="SUPPLIER DETAILS"
      onClose={onClose}
      hasCancel
      buttons={[
        {
          key: "save",
          label: "Save",
          onClick: () => onClickSave(),
          color: "primary",
        }]}
    >
      <InfoPopup />
      <div ref={topRef} className="row px-2">
        {
          businessTypeOptions
          && (
            <WithDataFeature dataFeature={DataFeatures.BusinessTypes}>
              <div className="col-12 mt-3">
                <h2>CLIENT SPECIFIC COMPANY INFORMATION</h2>
                <div className="col-12 col-md-5">
                  <DropdownInput
                    label="Business Type"
                    name="businessTypeId"
                    value={businessType}
                    options={businessTypeOptions ?? []}
                    onChangeCallback={(ev: any) => setBusinessType(ev.target.value)}
                  />
                </div>
              </div>
            </WithDataFeature>
          )
        }

        {
          showShareHoldersTable && (

            <div className="col-12 mt-3">
              <WithDataFeature dataFeature={DataFeatures.ShareholdersDetails}>
                <ShareholderTable />
              </WithDataFeature>
            </div>
          )
        }

        <div className="col-12 mt-3">
          <h2>KEY CONTACT DETAILS</h2>
          <KeyContactStaff
            readOnly={!canEdit}
            generalReadOnly
            details={keyContactDetails?.contactPersons || []}
            hasTelNumValidation={keyContactDetails?.hasTelephoneValidation}
            onInputChangeCallback={(newEntry: ContactPersonMessage) => {
              const newContactPersons = keyContactDetails
                ?.contactPersons.map((contactPerson: ContactPersonMessage) => {
                  if (contactPerson.contactPersonTypeId === newEntry.contactPersonTypeId) {
                    return newEntry;
                  }
                  return contactPerson;
                });

              const newKeyContactDetails = {
                ...keyContactDetails,
                contactPersons: newContactPersons ?? [],
              };

              setKeyContactDetails(newKeyContactDetails);
            }}
          />
          <h2 className="mt-3">AGREEMENTS</h2>
          <AgreementsTable
            currentUserIsBuyer={false}
            signatoryCompanyId={selectedCompanyId}
          />

          <h2 className="mt-3">CLIENT PRODUCT OFFERINGS</h2>
          <div className="col-12 col-xl-5">
            <ClientOfferingSummary
              buyerCompanyId={selectedCompanyId}
            />
          </div>
        </div>
      </div>
      {loading && <Spinner />}
    </ModalPopup>
  );
};

export default SupplierClientDetailsModal;
