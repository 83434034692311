import React, { Component } from "react";
import { CheckboxInput } from "isuppli-react-components";

class WorkFlows extends Component {
  onInputChange = () => {

  };

  render() {
    return (
      <section>
        <div className="row">
          <div className="col-12 col-sm-4">
            <h2>WORKFLOWS</h2>
            <CheckboxInput
              label="Approver 1"
              onInputChangeCallback={this.onInputChange}
            />
            <CheckboxInput
              label="Approver 2"
              onInputChangeCallback={this.onInputChange}
            />
          </div>

          <div className="col-12 col-sm-8" />
        </div>
      </section>
    );
  }
}

export default WorkFlows;
