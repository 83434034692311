/* eslint-disable no-undef */
const MTOMandatoryField = {
  /* eslint-disable no-unused-vars */
  None: BigInt(0),
  ManualSupplier: BigInt(0x1),
  RegistrationNumber: BigInt(0x2),
  RegisteredCompanyName: BigInt(0x4),
  TradingName: BigInt(0x8),
  BusinessUnit: BigInt(0x10),
  CategoryManager: BigInt(0x20),
  CompanyType: BigInt(0x40),
  VatNumber: BigInt(0x80),
  SupplierType: BigInt(0x100),
  ContractingTier: BigInt(0x200),
  PhysicalAddress: BigInt(0x400),
  PostalAddress: BigInt(0x800),
  PrimaryContactPerson: BigInt(0x1000),
  AllContactPersons: BigInt(0x2000),
  BEE: BigInt(0x4000),
  Banking: BigInt(0x8000),
  SupportingDocument: BigInt(0x10000),
  ProductSegmentAndFamilies: BigInt(0x20000),
  Restrictions: BigInt(0x40000),
  Motivation: BigInt(0x80000),
  PaymentTerms: BigInt(0x100000),
  EmailAddress: BigInt(0x200000),
};

type MTOMandatoryField = bigint;

/* eslint-disable no-bitwise */

// Used to perform a bit wise AND and OR operations on a 64bit integer
// To-do: Delete this at a later stage
const bitWiseOperation = (v1: bigint = BigInt(0), v2: bigint = BigInt(0), operationType: "AND" | "OR") => {
  if (v1 == null || v2 == null || operationType == null) return false;
  const result = operationType === "AND" ? v1 & v2 : v2 | v1;
  return result;
};

export const isMandatory = (
  mandatoryFields?: number,
  field: MTOMandatoryField = MTOMandatoryField.None
) => field === MTOMandatoryField.None
    // eslint-disable-next-line eqeqeq
    || (bitWiseOperation(BigInt(mandatoryFields ?? 0), field, "AND")) == field;

/* eslint-disable no-bitwise */

export default MTOMandatoryField;
