import React, { useState } from "react";
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from "reactstrap";
import Styles from "./SecondarySearchInput.module.scss";
import { ReactComponent as MagnifyingGlassSvg } from "../../Images/magnifying-glass.svg";
import { ReactComponent as FiltersSvg } from "./noun-filters.svg";

function SecondarySearchInput({
  value,
  onChange,
  placeholder = "Search...",
  hideFilter = false,
  onFilterChange,
  filterOptions,
  selectedFilterValue,
}:{
  value:string,
  onChange: (newValue: string) => void,
  placeholder?:string,
  hideFilter?: boolean,
  onFilterChange?: (filterValue: number) => void,
  filterOptions?: Array<{displayValue: string, value: number}>,
  selectedFilterValue?: number
}) {
  const [isOptionsOpen, setIsOptionsOption] = useState(false);

  const changeFilterValue = (newFilterValue: number) => {
    if (onFilterChange) {
      onFilterChange(newFilterValue);
      if (value !== "") {
        onChange(value);
      }
    }
  };

  return (
    <div className={Styles.searchInput}>
      <MagnifyingGlassSvg className="mr-2" />
      <input
        placeholder={placeholder}
        name="searchTerm"
        value={value}
        onChange={(ev) => onChange(ev.target.value)}
      />
      {hideFilter === true ? null
        : (
          <Dropdown
            isOpen={isOptionsOpen}
            toggle={() => setIsOptionsOption((previousValue) => !previousValue)}
          >
            <DropdownToggle tag="button" className={Styles.filterButton}>
              <FiltersSvg className="text-action" />
            </DropdownToggle>
            <DropdownMenu>
              {filterOptions?.map((option) => (
                <DropdownItem
                  key={option.value}
                  onClick={() => changeFilterValue(option.value)}
                  active={selectedFilterValue === option.value}
                >
                  {option.displayValue}
                </DropdownItem>
              ))}
              <DropdownItem />

            </DropdownMenu>
          </Dropdown>
        )}
    </div>
  );
}

export default SecondarySearchInput;
