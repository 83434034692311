import React from "react";
import { Switch } from "react-router-dom";
import { SystemFeatures, FeatureRoute } from "isuppli-react-components";

import SupplierSearch from "../Sections/SupplierSearch/SupplierSearch";
import Messaging from "../Sections/Messaging/Messaging.tsx";

function MarketPlaceView() {
  return (
    <section>
      <Switch>
        <FeatureRoute path="/marketplace/search" feature={SystemFeatures.SupplierSearch}>
          <SupplierSearch />
        </FeatureRoute>
        <FeatureRoute
          path="/marketplace/messaging"
          feature={SystemFeatures.Messaging}
        >
          <Messaging />
        </FeatureRoute>
      </Switch>
    </section>
  );
}

export default MarketPlaceView;
