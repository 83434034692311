import React, { Component } from "react";

class CategoryCreator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [
        {
          title: "Animal feeds",
          subCategories: [{ title: "Balance feeds" }, { title: "Concentrates" }],
        },
        {
          title: "some caytegory",
          subCategories: [],
        },
      ],
    };
  }

  render() {
    const addSubcategory = (
      <span
        className="text-primary font-underline clickable"
        onClick={() => {}}
      >
        + Add New Sub-Category
      </span>
    );
    const rows = [];
    this.state.categories.forEach((cat, i) => {
      rows.push(
        <tr key={`${i}-0`}>
          <td>{cat.title}</td>
          <td>
            {cat.subCategories.length === 0
              ? addSubcategory
              : cat.subCategories[0].title}
          </td>
        </tr>
      );
      cat.subCategories.forEach((subCat, j) => {
        if (j === 0) {
          return;
        }
        rows.push(
          <tr key={`${i}-${j}`}>
            <td />
            <td>{subCat.title}</td>
          </tr>
        );
      });
      if (cat.subCategories.length !== 0) {
        rows.push(
          <tr key={cat.subCategories.length}>
            <td />
            <td>{addSubcategory}</td>
          </tr>
        );
      }
    });
    return (
      <section>
        <table className="isuppli-table category-creator-table">
          <thead>
            <tr>
              <th>Category</th>
              <th>Sub-Category</th>
            </tr>
          </thead>
          <tbody>
            {rows}
            <tr>
              <td colSpan="2">
                <span
                  className="text-primary font-underline clickable"
                  onClick={() => {}}
                >
                  + Add New Category
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    );
  }
}

export default CategoryCreator;
