import React, { useState, Fragment, useEffect } from "react";
import { useHistory } from "react-router";
import { ModalBackground } from "isuppli-react-components";
import Notifications from "./Notifications";
import NotificationButton from "../../Controls/NotificationButton/NotificationButton";
import useSignalR, {
  SignalRNotifications,
  // eslint-disable-next-line no-unused-vars
  UnreadNotificationsUpdate,
} from "../../Hooks/useSignalR";

import { getNotifications, getNotificationCount, dismissNotification } from "../../http/index";
import NotificationTypes from "../../Util/Enumerators/notificationTypes";

interface notificationMessage { id: number, seen: boolean, message: string }
interface notificationsMessage { totalNotifications: number, latest: notificationMessage[] }

const NotificationMenuItem = () => {
  const history = useHistory();

  const [showNotifications, setShowNotifications] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState<notificationMessage[]>([]);
  const [showLoading, setLoading] = useState(false);

  const disconnect = useSignalR<UnreadNotificationsUpdate>(
    SignalRNotifications.UnreadNotifications,
    async (data) => {
      setNotificationCount(data.totalUnreadNotifications);
      // if notification menu is open, update notification content
      if (showNotifications) {
        setLoading(true);

        const notificationsMessage: notificationsMessage = await getNotifications();
        setNotifications(notificationsMessage.latest);

        setLoading(false);
      }
    }
  );
  // register SignalR updates
  useEffect(() => () => {
    disconnect();
  }, [disconnect]);

  useEffect(() => {
    async function updateNotificationCount() {
      const updatedNotificationCount = await getNotificationCount();
      setNotificationCount(updatedNotificationCount);
    }
    updateNotificationCount();
  }, []);

  const notificationMenuClickHandler = async () => {
    setShowNotifications((prevShowNotifications) => !prevShowNotifications);

    // check showNotifications (only call when opening notification popup)
    if (!showNotifications) {
      setLoading(true);

      const notificationsMessage: notificationsMessage = await getNotifications();
      setNotifications(notificationsMessage.latest);

      setLoading(false);
    }
  };

  const notificationItemClickHandler = async (
    notificationType: NotificationTypes,
    linkUrl: string
  ) => {
    // close notifications menu
    setShowNotifications(false);

    // navigate
    switch (notificationType) {
      case NotificationTypes.Messaging:
        history.push(linkUrl ?? "/marketplace/messaging");
        break;
      case NotificationTypes.BeeCompliance:
        history.push(linkUrl ?? "/myCompany/profile/bee");
        break;
      case NotificationTypes.Payments:
        history.push(linkUrl ?? "/myCompany/subscription");
        break;
      case NotificationTypes.Opportunity:
      case NotificationTypes.NewOpportunity:
        const url = linkUrl ?? "/redirect/opportunities";
        window.open(url, "_blank"); // to open new page
        break;
      case NotificationTypes.RegulatoryCompliance:
        history.push(linkUrl ?? "/myCompany/profile/supplier");
        break;
      case NotificationTypes.SuppierBeeCompliance:
        history.push(linkUrl ?? "/marketplace/search");
        break;
      case NotificationTypes.SupplierRegulatoryCompliance:
        history.push(linkUrl ?? "/marketplace/search");
        break;
      case NotificationTypes.Onboarding:
        history.push(linkUrl ?? "/myoffice/onboardingrequest");
        break;
      default:
        history.push(linkUrl ?? "/dashboard");
        break;
    }
  };

  const onDismissClickHandler = async (notificationId: number) => {
    // pop from list in state
    setNotifications((prevNotifications) => prevNotifications
      .filter((n) => n.id !== notificationId));
    // update count
    setNotificationCount((prevNotificationCount) => prevNotificationCount - 1);

    // call api
    await dismissNotification(notificationId);
  };

  return (
    <Fragment>
      <NotificationButton
        notifications={notificationCount}
        onClickCallback={notificationMenuClickHandler}
      />

      {showNotifications && (
        <ModalBackground onClick={notificationMenuClickHandler} transparent>
          <Notifications
            showLoading={showLoading}
            notifications={notifications}
            onDismiss={onDismissClickHandler}
            onItemClick={notificationItemClickHandler}
          />
        </ModalBackground>
      )}
    </Fragment>
  );
};

export default NotificationMenuItem;
