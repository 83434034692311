import React, { useState, useRef } from "react";

import UtilButton from "../UtilButton/UtilButton";
import ModalCloseButton from "../ModalCloseButton/ModalCloseButton";
import ModalBackground from "../../Containers/ModalBackground/ModalBackground";
import ModalContainer from "../../Containers/ModalContainer/ModalContainer";
// eslint-disable-next-line no-unused-vars
import Form, { FormRef } from "../Forms/Form/Form";
import Styles from "./ConfirmationModal.module.scss";

import Line from "../Line/Line";
import DateTimeInput from "../Forms/DateTimeInput/DateTimeInput";
import MultiSelectDropDownInput from "../Forms/MultiSelectDropDownInput/MultiSelectDropDownInput";

import { ReactComponent as ErrorSvg } from "../../images/error.svg";
import { ReactComponent as LaptopSvg } from "../../images/laptop.svg";

export interface staticOption {
  value: number,
  display: string,
  [key: string]: any,
}

const ConfirmationModal = ({
  heading,
  subHeading,
  description,
  proceedButtonText,
  image,
  hasCancel = true,
  captureType,
  capturePlaceholder,
  min,
  validationMessage,
  onToggleModal,
  onProceedClick,
  size = "medium",
  multiDropdownOptions,
}
:
{
  heading: string,
  subHeading?: string,
  description: string,
  proceedButtonText: string,
  image?: "error" | "laptop",
  hasCancel?: boolean,
  captureType?: "none" | "text" | "datetime" | "multiselectdropdown"
  capturePlaceholder?: string,
  min?: Date,
  validationMessage?: string,
  onToggleModal: () => void,
  onProceedClick: (capturedData?: string | Date | number[]) => void,
  size?: "small" | "medium" | "large" | "extra-large",
  multiDropdownOptions?: staticOption[]
}) => {
  const [text, setText] = useState("");
  const [date, setDate] = useState<Date | undefined>();
  const [multiDropdownValues, setMultiDropdownValues] = useState<number[]>([]);

  const formRef = useRef<FormRef>(null);

  let captureContent = null;
  switch (captureType) {
    case "text":
      captureContent = (
        <div className="row py-3 px-4">
          <div className="col-12">
            <textarea
              className={`${Styles.TextArea} w-100 p-2`}
              value={text}
              onChange={(ev) => setText(ev.target.value)}
              placeholder={capturePlaceholder}
            />
          </div>
        </div>
      );
      break;
    case "datetime":
      captureContent = (
        <Form className="row px-4 pb-3" ref={formRef}>
          <DateTimeInput
            className="col-12"
            value={date}
            onChange={(v) => setDate(v)}
            key="dateTime"
            min={min}
            required
            validationMessage={validationMessage}
          />
        </Form>
      );
      break;
    case "multiselectdropdown":
      captureContent = (
        <div className="row py-3 px-4">
          <MultiSelectDropDownInput
            className="col-12"
            value={multiDropdownValues}
            onChange={setMultiDropdownValues}
            required
            validationMessage="validationMessage"
            options={multiDropdownOptions ?? []}
            disabled={false}
            dropdownDirection="up"
          />
        </div>
      );
      break;
    default:
      break;
  }

  let imageElement = null;
  switch (image) {
    case "error":
      imageElement = <ErrorSvg className="text-danger" style={{ height: "76px", width: "auto" }} />;
      break;
    case "laptop":
      imageElement = <LaptopSvg className="text-primary" style={{ width: "auto" }} />;
      break;
    default:
      break;
  }

  return (
    <ModalBackground>
      <ModalContainer className="py-3 w-100 container-fluid" size={size}>
        <div className="d-flex justify-content-end pb-4">
          {hasCancel
            && (
              <ModalCloseButton onClick={onToggleModal} />
            )}
        </div>

        {imageElement != null ? (
          <div className="row text-center pb-4 justify-content-center align-items-center text-center">
            <div className="col-12 col-lg-6">
              { imageElement }
            </div>
          </div>
        ) : null}

        <div className="row text-center px-4">
          <div className="col-12 col-lg-10 offset-lg-1">
            <div>
              <h1 className="text-uppercase">{heading}</h1>
            </div>
          </div>
        </div>

        {
          subHeading
          && (
            <div className="row text-center px-4 py-1">
              <div className="col-12 pb-25">
                <p className={`${Styles.SubHeading}`}>{subHeading}</p>
              </div>
            </div>
          )
        }

        <div className={`row ${captureType == null ? "py-3" : "pt-3"} px-4 text-center`}>
          <div className="col-12 col-lg-8 offset-lg-2">
            <div>
              <p>{description}</p>
            </div>
          </div>
        </div>
        {
          captureContent
        }
        <div className="row">
          <Line color="primary" />
        </div>
        <div className="row px-xl-3">
          <div className="col-12 col-lg-6 d-flex">
            {hasCancel && (
              <UtilButton
                className={`
                  ${Styles.CancelButton}
                  w-100 
                  d-flex justify-content-center align-items-center
                `}
                color="secondary"
                location="bottom"
                onClick={onToggleModal}
              >
                Cancel
              </UtilButton>
            )}
          </div>

          <div className={`col-12 ${hasCancel ? "col-lg-6" : 0} d-flex`}>
            <UtilButton
              className={`w-100
                ${Styles.ProceedButton}
                d-flex justify-content-center align-items-center
                ${hasCancel ? "mt-2 mt-lg-0" : ""}
              `}
              color="primary"
              location="bottom"
              onClick={() => {
                // check validation
                if (captureType != null && formRef != null) {
                  if (!(formRef.current?.isValid() ?? true)) {
                    formRef.current?.showValidation();
                    return;
                  }
                }

                // get captureValue
                let captureValue: string | Date | number[] | undefined;

                switch (captureType) {
                  case "text":
                    captureValue = text;
                    break;
                  case "datetime":
                    captureValue = date;
                    break;
                  case "multiselectdropdown":
                    captureValue = multiDropdownValues;
                    break;
                  default:
                    break;
                }
                // call proceed
                if (captureValue != null) {
                  onProceedClick(captureValue);
                } else {
                  onProceedClick();
                }

                // close modal
                onToggleModal();
              }}
            >
              {proceedButtonText}
            </UtilButton>
          </div>
        </div>
      </ModalContainer>
    </ModalBackground>
  );
};

export default ConfirmationModal;
