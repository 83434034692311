import React, {
  // eslint-disable-next-line no-unused-vars
  CSSProperties,
} from "react";
import Styles from "./ShadowCard.module.scss";

const ShadowCard = (
  { children, className, style }
  :
  {children: React.ReactNode, className?: string, style?: CSSProperties}
) => (
  <div className={`${Styles.ShadowCard} ${className ?? ""}`} style={style}>
    {children}
  </div>
);

export default ShadowCard;
