import axios from "axios";

import {
  PendingVendorEventsMessage, // eslint-disable-line no-unused-vars
  SupplierUpdatesSearchCriteria, // eslint-disable-line no-unused-vars
  PendingEventDetailMessage, // eslint-disable-line no-unused-vars
  ApprovedEventItemMessage, // eslint-disable-line no-unused-vars
} from "./Models/Models";

import { makeRequestAsync } from "../../util";

// eslint-disable-next-line import/prefer-default-export
export async function getPendingEvents(searchCriteria: SupplierUpdatesSearchCriteria) {
  const urlParameters = [];
  if (searchCriteria.limit != null) {
    urlParameters.push(`limit=${searchCriteria.limit}`);
  }
  if (searchCriteria.offset != null) {
    urlParameters.push(`offset=${searchCriteria.offset}`);
  }
  if (searchCriteria.searchTerm != null) {
    urlParameters.push(`searchTerm=${searchCriteria.searchTerm}`);
  }
  if (searchCriteria.newestFirst != null) {
    urlParameters.push(`newestFirst=${searchCriteria.newestFirst}`);
  }
  if (searchCriteria.supplierCategoryManagerCodes != null) {
    searchCriteria.supplierCategoryManagerCodes.forEach((supplierCategoryManagerCode) => {
      urlParameters.push(`supplierCategoryManagerCodes=${supplierCategoryManagerCode}`);
    });
  }
  if (searchCriteria.supplierCategories != null) {
    searchCriteria.supplierCategories.forEach((supplierCategory) => {
      urlParameters.push(`supplierCategories=${supplierCategory}`);
    });
  }
  if (searchCriteria.supplierSubcategories != null) {
    searchCriteria.supplierSubcategories.forEach((supplierSubcategory) => {
      urlParameters.push(`supplierSubcategories=${supplierSubcategory}`);
    });
  }

  const url = `/api/erp/events?${urlParameters.join("&")}`;

  const pendingEventsMessage = makeRequestAsync(
    () => axios.get(url)
  ) as Promise<PendingVendorEventsMessage>;
  return pendingEventsMessage;
}

export async function getPendingEventDetails(eventId: number) {
  const url = `/api/erp/events/${eventId}`;
  return makeRequestAsync(
    () => axios.get(url)
  ) as Promise<Array<PendingEventDetailMessage>>;
}

export async function releaseEvent(eventId: number,
  releaseEventDetailItemMessages: Array<ApprovedEventItemMessage>) {
  const url = `/api/erp/events/${eventId}/release`;
  return makeRequestAsync(
    () => axios.put(url, releaseEventDetailItemMessages)
  ) as Promise<any>;
}
