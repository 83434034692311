import React from "react";
import Styles from "./ModalContainer.module.scss";

const ModalContainer = ({
  children,
  className = "",
  size,
}
:
{
  children : React.ReactNode,
  className? : string,
  size?: "small" | "medium" | "large" | "extra-large" | "full-width",
}) => {
  let sizeClass = "";
  switch (size) {
    case "medium":
      sizeClass = Styles.SizeMedium;
      break;
    case "small":
      sizeClass = Styles.SizeSmall;
      break;
    case "large":
      sizeClass = Styles.SizeLarge;
      break;
    case "extra-large":
      sizeClass = Styles.SizeExtraLarge;
      break;
    default:
      break;
  }
  return (
    <div className={`${Styles.ModalContainer} ${className} ${sizeClass}`}>
      {children}
    </div>
  );
};

export default ModalContainer;
