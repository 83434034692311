// eslint-disable-next-line no-unused-vars
import React, { Fragment } from "react";
// eslint-disable-next-line no-unused-vars
import SystemFeatures from "../../../utils/enums/SystemFeatures";

export interface TabProps {
  name: string,
  slug: string,
  feature?: SystemFeatures
  children: React.ReactNode,
}

const Tab = ({ children }: TabProps) => (
  <Fragment>
    {children}
  </Fragment>
);

export default Tab;
