import React, { Component } from "react";
import { CheckboxInput, TextAreaInput } from "isuppli-react-components";

class OfferingSelectionSummary extends Component {
  constructor() {
    super();
    this.state = {};
  }

  onInputChange = (ev) => {
    this.props.onInputChangeCallback(ev);
  };

  render() {
    return (
      <section>
        <section>
          <CheckboxInput
            disabled={this.props.readOnly}
            className="pb-3"
            label="Notify me about new opportunities for my products and services"
            value={this.props.data.notifyNewOpportunities ?? false}
            onChange={(value) => this.onInputChange({
              target: {
                name: "notifyNewOpportunities",
                value,
              },
            })}
          />
          <TextAreaInput
            className="col-12"
            label="Please provide a summary of the nature of your business products and/or services."
            placeHolder="Please explain what makes your product or service unique."
            maxLength={1000}
            value={this.props.data.tradeDescription ?? ""}
            onChange={(value) => this.onInputChange({
              target: {
                name: "tradeDescription",
                value,
              },
            })}
          />

        </section>
      </section>
    );
  }
}

export default OfferingSelectionSummary;
