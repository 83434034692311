import axios, {
  AxiosRequestConfig, // eslint-disable-line no-unused-vars
} from "axios";
import { makeRequestAsync } from "../util";

// eslint-disable-next-line no-unused-vars
import { ContractTierMessage } from "./Models/ContractTierMessage";
// eslint-disable-next-line no-unused-vars
import { ContractsMessage } from "./Models/ContractsMessage";
// eslint-disable-next-line no-unused-vars
import { ContractTemplateMessage } from "./Models/ContractTemplateMessage";
// eslint-disable-next-line no-unused-vars
import { DashboardContractReportSummary } from "./Models/DashboardContractReportSummary";

export * from "./Models/ContractTierMessage";
export * from "./Models/ContractsMessage";
export * from "./Models/ContractMessage";
export * from "./Models/ContractTemplateMessage";

export const getContractTiers = async () => {
  const url = "/api/contracting/options/contractTiers";
  return makeRequestAsync(() => axios
    .get(url)) as Promise<Array<ContractTierMessage>>;
};

export const getContractAgreements = async (contractTierId:number) => {
  const url = `/api/contracting/contracts/contractAgreements/${contractTierId}`;
  return makeRequestAsync(() => axios.get(url)) as Promise<ContractTemplateMessage[]>;
};

export const getContracts = async (buyerCompanyId: number, populateContracts: boolean) => {
  const url = `/api/contracting/contracts?SignatoryExternalAccountId=${buyerCompanyId}&populateContracts=${populateContracts}`;
  return makeRequestAsync(() => axios
    .get(url)) as Promise<ContractsMessage>;
};

export const uploadContract = async (
  contractId: number,
  files: FormData,
  config : AxiosRequestConfig | undefined
) => {
  const url = `/api/contracting/contracts/${contractId}`;
  return makeRequestAsync(() => axios
    .post(url, files, config)) as Promise<void>;
};

export const uploadContractForTemplate = async (
  supplierCompanyId: number,
  contractTemplateId: number,
  contractTierId: number,
  files: FormData,
  config: AxiosRequestConfig | undefined
) => {
  const urlParameters = [];
  urlParameters.push(`SignatoryExternalAccountId=${supplierCompanyId}`);
  urlParameters.push(`ContractTemplateId=${contractTemplateId}`);
  urlParameters.push(`ContractTierId=${contractTierId}`);

  const url = `/api/contracting/contracts/template?${urlParameters.join("&")}`;

  return makeRequestAsync(() => axios
    .post(url, files, config)) as Promise<number>;
};

export const approveContract = async (
  contractId: number
) => {
  const url = `/api/contracting/contracts/${contractId}/approve`;
  return makeRequestAsync(() => axios
    .put(url)) as Promise<void>;
};

export async function getDashboardContractAgreementsSummary() {
  const url = "api/contracting/summary";
  return makeRequestAsync(() => axios
    .get(url)) as Promise<DashboardContractReportSummary>;
}
