import { hasFeature, SystemFeatures } from "isuppli-react-components";

enum ComplianceStatus {
  None = 0, // eslint-disable-line no-unused-vars
  AwaitingResults = 1, // eslint-disable-line no-unused-vars
  ReceivedResults = 2, // eslint-disable-line no-unused-vars
  Complete = 3, // eslint-disable-line no-unused-vars
  Disabled = 4, // eslint-disable-line no-unused-vars
  Error = 100 // eslint-disable-line no-unused-vars
}

export const complianceStatusToString = (value: ComplianceStatus) => {
  switch (value) {
    case ComplianceStatus.None:
      return "None";
    case ComplianceStatus.AwaitingResults:
      return "Outstanding";
    case ComplianceStatus.ReceivedResults:
      return "Results received";
    case ComplianceStatus.Complete:
      return "Passed";
    case ComplianceStatus.Disabled:
      return "N/A";
    case ComplianceStatus.Error:
      return "Failed";
    default:
      return "Unknown Status";
  }
};

export const getComplianceStatusTextClass = (
  status: ComplianceStatus = ComplianceStatus.None, feature?: SystemFeatures
):string => {
  if (feature != null && !hasFeature(feature, SystemFeatures.ColourIndicators)) {
    return "";
  }
  switch (status) {
    case ComplianceStatus.None:
      return "text-body";
    case ComplianceStatus.AwaitingResults:
      return "text-body";
    case ComplianceStatus.ReceivedResults:
      return "text-body";
    case ComplianceStatus.Complete:
      return "text-success";
    case ComplianceStatus.Error:
      return "text-danger";
    default:
      return "text-body";
  }
};

export const getComplianceStatusBadgeColor = (
  value: ComplianceStatus
): string => {
  switch (value) {
    case ComplianceStatus.None:
      return "secondary";
    case ComplianceStatus.AwaitingResults:
      return "secondary";
    case ComplianceStatus.ReceivedResults:
      return "secondary";
    case ComplianceStatus.Complete:
      return "success";
    case ComplianceStatus.Error:
      return "danger";
    default:
      return "secondary";
  }
};

export default ComplianceStatus;
