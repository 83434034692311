import React from "react";
import { PageHeading } from "isuppli-react-components";
import { connect } from "react-redux";
import navMenuItems from "../../Util/menuItems";
import SupplierCheckDetails from "../../Containers/Supplier/SupplierCheckDetails/SupplierCheckDetails";

const mapStateToProps = (state: any) => ({
  data: {
    ...state.registration.prdInfo,
    companyId: state.currentCompanyId,
  },
});

const Compliance = (props: any) => (
  <div style={{ paddingTop: "20px" }}>
    <div className="row padding-lr-10vw">
      <PageHeading
        heading="My Compliance"
        noBottomMargin=""
        menuItems={navMenuItems}
      />

      <div className="col-12 px-0">
        <SupplierCheckDetails
          companyId={props.data.companyId}
        />
      </div>
    </div>
  </div>
);

export default connect(mapStateToProps, null)(Compliance);
