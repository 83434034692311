import axios from "axios";
import { makeRequest, makeRequestAsync, refreshTokenInternal } from "./util";

export function getUserBasicDetails() {
  return makeRequestAsync(() => axios.get("/api/user/userBasicDetails"));
}

export function getCompanyConfiguration() {
  return makeRequestAsync(() => axios.get("/api/registration/companyConfiguration"));
}

export function refreshToken() {
  return refreshTokenInternal("/api/token/refresh");
}

export function getTokenExpiry({ bypassAuthCheck = false }) {
  return makeRequestAsync(() => axios.get("/api/token/expiresAt"), bypassAuthCheck);
}

export function getOptions(filters, onSuccess) {
  const filtersStr = filters.length > 0 ? `/${filters.join(":")}` : "";
  makeRequest(() => axios.get(`/api/options${filtersStr}`), onSuccess);
}

export async function getOptionsAsync(filters) {
  const filtersStr = filters.length > 0 ? `/${filters.join(":")}` : "";
  return makeRequestAsync(() => axios.get(`/api/options${filtersStr}`));
}

export async function getCompanyOptionsAsync(filters, companyId) {
  const filtersStr = filters.length > 0 ? `/${filters.join(":")}` : "";
  const companyIdQueryStr = companyId > 0 ? `?companyId=${companyId}` : "";
  return makeRequestAsync(() => axios.get(`/api/options/company${filtersStr}${companyIdQueryStr}`));
}

export function getSupplierInfo(companyId, onSuccess) {
  makeRequest(
    () => axios.get(`/api/registration/supplierinfo/${companyId}`),
    onSuccess
  );
}

export function getSupplierInfoAsync(companyId) {
  return makeRequestAsync(() => axios.get(`/api/registration/supplierinfo/${companyId}`));
}

export function getAddressInfo(companyId, onSuccess) {
  makeRequest(
    () => axios.get(`/api/registration/addressinfo/${companyId}`),
    onSuccess
  );
}

export function getAddressInfoAsync(companyId) {
  return makeRequestAsync(() => axios.get(`/api/registration/addressinfo/${companyId}`));
}

export function getKeyContactDetails(buyerCompanyId, onSuccess) {
  let url = "/api/registration/keycontactstaff";
  if (buyerCompanyId != null) {
    url += `?buyerId=${buyerCompanyId}`;
  }
  makeRequest(
    () => axios.get(url),
    onSuccess
  );
}

export function getKeyContactDetailsAsync(buyerCompanyId) {
  let url = "/api/registration/keycontactstaff";
  if (buyerCompanyId != null) {
    url += `?buyerId=${buyerCompanyId}`;
  }
  return makeRequestAsync(() => axios.get(url));
}

export function getSupplierKeyContactDetailsAsync(companyId) {
  return makeRequestAsync(() => axios.get(`/api/registration/supplierContactPersons/${companyId}`));
}

export function getBeeDetails(companyId, onSuccess) {
  makeRequest(
    () => axios.get(`/api/registration/beeratings/${companyId}`),
    onSuccess
  );
}

export function getBeeDetailsAsync(companyId) {
  return makeRequestAsync(() => axios.get(`/api/registration/beeratings/${companyId}`));
}

export function getDocuments(companyId, onSuccess) {
  makeRequest(
    () => axios.get(`/api/registration/documents/${companyId}`),
    onSuccess
  );
}

export function getDocumentsAsync(companyId, filterByBeeRating) {
  return makeRequestAsync(() => axios.get(`/api/registration/documents/${companyId}?filterByBeeRating=${filterByBeeRating}`));
}

export function getBankingDetails(companyId, onSuccess) {
  makeRequest(
    () => axios.get(`/api/registration/bankdetails/${companyId}`),
    onSuccess
  );
}

export function getBankingDetailsAsync(companyId) {
  return makeRequestAsync(
    () => axios.get(`/api/registration/bankdetails/${companyId}`)
  );
}

export function getSupplierCategories(onSuccess) {
  makeRequest(
    () => axios.get("/api/registration/offerings"),
    onSuccess
  );
}

export function getRegistrationSelectedSubscription(onSuccess) {
  makeRequest(
    () => axios.get("/api/registration/producttier"),
    onSuccess
  );
}

export function getTermsAndConditions(companyId, onSuccess) {
  makeRequest(
    () => axios.get(`/api/registration/termsconditions/${companyId}`),
    onSuccess
  );
}

export function getTermsAndConditionsAsync(companyId) {
  return makeRequestAsync(() => axios.get(`/api/registration/termsconditions/${companyId}`));
}

export async function getNotifications() {
  return makeRequestAsync(() => axios.get("/api/notifications"));
}

export async function getNotificationCount() {
  return makeRequestAsync(() => axios.get("/api/notifications/count"));
}

export async function getOnboardingRequest(onboardingRequestId) {
  return makeRequestAsync(() => axios.get(`/api/motivation/onboardingrequests/${onboardingRequestId}`));
}

export async function getOnboardingRequestExceptionReason(supplierCompanyId) {
  return makeRequestAsync(() => axios.get(`/api/motivation/onboardingrequestexceptionreason/${supplierCompanyId}`));
}

export function newOnboardingRequest() {
  const url = "/api/motivation/new";
  return makeRequestAsync(() => axios.get(url));
}

export async function getDuplicateRequests(
  companyTypeId,
  registrationNumber,
  onboardingRequestId
) {
  const companyTypeQueryString = companyTypeId == null ? "" : `&companyTypeId=${companyTypeId}`;

  const registrationNumberQueryString = registrationNumber == null
    ? ""
    : `&registrationNumber=${encodeURIComponent(registrationNumber)}`;

  const onboardingRequestIdQueryString = `onboardingId=${
    onboardingRequestId == null ? 0 : onboardingRequestId
  }`;
  const url = `/api/motivation/duplicates?${onboardingRequestIdQueryString}${registrationNumberQueryString}${companyTypeQueryString}`;
  return makeRequestAsync(() => axios.get(url));
}

export async function getOnboardingInvitation() {
  const url = "/api/motivation/invitation";
  return makeRequestAsync(() => axios.get(url));
}

export async function getSubscriptionDetail() {
  const url = "/api/manage/producttier";
  return makeRequestAsync(() => axios.get(url));
}

export async function getSubscriptionUpgradeFee(newSubscriptionTierId) {
  const url = `/api/manage/producttier/${newSubscriptionTierId}/upgradeFee`;
  return makeRequestAsync(() => axios.get(url));
}

export async function getCompanyDataFeatures() {
  const result = makeRequestAsync(() => axios.get("/api/company/datafeatures"));
  return result;
}

export async function getCompanySubAccountsAsync(companyId) {
  return makeRequestAsync(() => axios.get(`/api/registration/subaccounts/${companyId}`));
}

export async function getAllSubscriptionsAllowedFeatures() {
  const result = makeRequestAsync(() => axios.get("/api/manage/allSubscriptionsAllowedFeatures"));
  return result;
}

export function getCommunicationPreference() {
  const result = makeRequestAsync(() => axios.get("/api/user/communicationpreferences"));
  return result;
}

export async function getCompanyCategoryManagers() {
  const result = await makeRequestAsync(() => axios.get("/api/company/categoryManagers"));
  return result;
}

export async function getDuplicateCompanies(companyType, registrationNumber) {
  return makeRequestAsync(() => axios.get(`/api/motivation/duplicate/companies?CompanyType=${companyType}&RegistrationNumber=${registrationNumber}`));
}

export function getSupplierExportStatus() {
  return makeRequestAsync(() => axios.get("api/reporting/supplierExport/status"));
}
