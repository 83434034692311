import React from "react";
import { Alert } from "reactstrap";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
// eslint-disable-next-line no-unused-vars
import { MenuItem } from "../..";
import Styles from "./PageHeading.module.scss";

const PageHeader = (
  {
    heading,
    subHeading,
    alertText,
    noBottomMargin,
    breadcrumbAdditionalItem,
    menuItems,
  }
    :
    {
      heading: string,
      subHeading?: string,
      alertText?: string | string[],
      noBottomMargin?: any,
      breadcrumbAdditionalItem?: string,
      menuItems: Array<MenuItem>
    }
) => {
  let alert;
  if (typeof alertText === "string") {
    alert = alertText;
  } else { // array
    alert = alertText?.map((line, index) => (
      <div>
        {index > 0 && <br />}
        <p>{line}</p>
      </div>
    ));
  }

  return (
    <div className={`${noBottomMargin != null ? "" : "mb-5"} mt-5`}>
      <BreadCrumbs menuItems={menuItems} additional={breadcrumbAdditionalItem} />
      <div className="pt-2 mb-3">
        <h1>{heading.toUpperCase()}</h1>
      </div>
      {subHeading
        && <div className={Styles.subHeading}>{subHeading}</div>}
      {alertText && <Alert fade={false} color="info" className="mb-0">{alert}</Alert>}
    </div>
  );
};

export default PageHeader;
