import React, { useState, useEffect } from "react";
import { DataFeatures, Spinner, useDataFeatureCheck } from "isuppli-react-components";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Alert } from "reactstrap";
import RegistrationProgress from "../RegistrationProgress";
import InfoPopup from "../../../Containers/InfoPopup";
import SaveAndContinueConfirm from "../../../Containers/Supplier/SaveAndContinueConfirm";
import DocumentUploader from "../../../Containers/Supplier/DocumentUploader";
import WizzardBtns from "../../../Containers/WizzardBtns";

import {
  hideInfoPopup, logOut, showInfoPopup, setLastStepCompleted,
} from "../../../Store/actions";
import { setRegistrationObject } from "../../../Store/registrationActions";

import { getDocumentsAsync, saveDocumentsInfo } from "../../../http/index";
import AgreementsTable from "../../../Containers/AgreementsTable/AgreementsTable";
import useStaticData from "../../../Hooks/useStaticData";
import { StaticDataTypes } from "../../../Util/Enumerators/staticDataTypes";

const RegistrationStepDocuments = () => {
  const [showSaveAndContinue, setShowSaveAndContinue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [[documentTypes], isReady] = useStaticData([StaticDataTypes.DocumentTypes]);
  const history = useHistory();
  const dispatch = useDispatch();
  const dataFeatureCheck = useDataFeatureCheck();
  const [approvals, setApprovals] = useState<Array<boolean>>([]);

  const data = useSelector((state: any) => (
    {
      ...state.registration.beeInfo,
      documents: state.registration.documents,
      companyId: state.currentCompanyId,
      invitationBuyerCompanyId: state.registration.supplierInfo.invitationBuyerCompanyId,
    }));

  const documents = useSelector((state: any) => state.registration.documents);

  const performSave = (onSuccess : () =>void, stepNumber: number) => {
    setLoading(true);
    const documentSummary = {
      companyId: data.companyId,
      companyDocuments: documents,
      registrationStepCompleted:
            data.registrationStepCompleted > stepNumber
              ? data.registrationStepCompleted
              : stepNumber,
    };
    saveDocumentsInfo(
      documentSummary,
      () => {
        onSuccess();
        dispatch(hideInfoPopup());
      },
      () => {
        setLoading(false);
      }
    );
  };

  const onNextClick = async (arg: string) => {
    // check if all documents were added - special case
    const validation : {
      isValid: boolean,
      msgs: { [key: string]: string },
    } = { isValid: true, msgs: {} };
    for (let i = 0; i < data.documents.length; i += 1) {
      if (!data.documents[i].optional && !data.documents[i].filename) {
        validation.isValid = false;
        validation.msgs = {
          ...validation.msgs,
          documents: "Please ensure all required documents are uploaded",
        };
      }
    }

    // agreement approval validation
    if (approvals.length > 0) {
      for (let i = 0; i < approvals.length; i += 1) {
        if (!approvals[i]) {
          validation.isValid = false;
          validation.msgs = {
            ...validation.msgs,
            agreements: "Please ensure all agreements are approved",
          };
        }
      }
    }

    if (validation.isValid) {
      performSave(() => {
        history.push(arg);
        setLoading(false);
      }, 6);
    } else {
      window.scrollTo({ top: 0 });
      dispatch(showInfoPopup(
        "This page contains missing information.",
        "To continue please amend/complete the following:",
        Object.keys(validation.msgs).map((key) => validation.msgs[key])
      ));
    }
  };

  const setDocuments = (newDocuments: any) => {
    dispatch(setRegistrationObject("documents", newDocuments));
  };

  const saveAndContinueLater = () => {
    performSave(() => {
      dispatch(logOut());
      setLoading(false);
    }, 5);
  };

  useEffect(() => {
    const loader = async () => {
      const res = await getDocumentsAsync(data.companyId, true);
      dispatch(setRegistrationObject("documents", res.companyDocuments));
      dispatch(setLastStepCompleted(res.registrationStepCompleted));
    };

    loader();
  }, [data.companyId, dispatch]);

  return (
    <section className="bg-white">
      {loading && <Spinner />}
      <InfoPopup />
      {showSaveAndContinue && (
        <SaveAndContinueConfirm
          toggleModalCallback={() => setShowSaveAndContinue(false)}
          saveAndContinueCallback={saveAndContinueLater}
        />
      )}

      <RegistrationProgress currentStep={6} />

      <div className="padding-lr-10vw">
        <div className="row top-margin-90px">
          <div className="col-12 col-lg-7">
            <div>
              <h3 className="margin-null">
                {dataFeatureCheck(DataFeatures.BankingDetails)
                  ? "STEP 06"
                  : "STEP 05"}
              </h3>
            </div>
            <div>
              <h1>SUPPORTING DOCUMENTS & AGREEMENTS</h1>
            </div>
          </div>

          <div
            className="col-12 col-lg-5 justify-center flex-dir-column"
            style={{ paddingTop: "20px" }}
          >
            <h3>
              We need a little bit more information about your business, to
              ensure we know what your company has to offer.
            </h3>
          </div>
        </div>

        <div className="row" style={{ paddingTop: "36px" }}>
          <div className="col-12 col-lg-9">
            <div>
              <Alert color="info">
                This section will remain incomplete until you upload your
                latest and up-to-date BEE rating. Over and above this, your
                profile will only be available for on-boarding when B1LINK
                has validated you BEE rating.
              </Alert>
            </div>
          </div>
        </div>

        <div className="top-margin-30px">&nbsp;</div>

      </div>

      <div className="padding-lr-5vw" style={{ marginTop: "36px" }}>
        <hr />
      </div>

      <div className="padding-lr-10vw" style={{ marginTop: "36px" }}>
        <DocumentUploader
          documentTypes={isReady ? documentTypes : []}
          uploadUrl="api/documents/"
          downloadUrl="api/documents/"
          documents={documents}
          onDocumentsChanges={(newDocuments: any) => setDocuments(newDocuments)}
        />
      </div>

      <div
        className="padding-lr-5vw"
        style={{ marginTop: "36px", marginBottom: "36px" }}
      >
        <hr />
      </div>

      <div className="padding-lr-10vw">
        <h2>AGREEMENTS</h2>
      </div>
      <div className="padding-lr-10vw">
        <div className="row" style={{ paddingBottom: "21px" }}>
          <div className="col-12 col-lg-8">
            View, update and approve agreements.
          </div>
        </div>

        <AgreementsTable
          currentUserIsBuyer={false}
          signatoryCompanyId={data.invitationBuyerCompanyId}
          onContractApproved={(a: Array<boolean>) => setApprovals(a)}
        />
      </div>
      <WizzardBtns
        history={history}
        backUrl={dataFeatureCheck(DataFeatures.BankingDetails)
          ? "/registration/bnkinfo"
          : "/registration/documents"}
        nextUrl="/registration/prtinfo"
        nextText="SUBSCRIPTION PACKAGE"
        onClickSaveAndContinue={() => setShowSaveAndContinue(true)}
        onClickNext={onNextClick}
      />
    </section>
  );
};

export default RegistrationStepDocuments;
