import React, { Fragment, useState } from "react";
import { ConfirmationModal, ModalPopup, Spinner } from "isuppli-react-components";
import PendingEventDetailTable from "../PendingEventDetailTable/PendingEventDetailTable";
// eslint-disable-next-line no-unused-vars
import { ApprovedEventItemMessage } from "../../../../http/MyOffice/SupplierUpdates/Models/ApprovedEventItemMessage";
import useShowError from "../../../../Hooks/useShowError";
import { releaseEvent } from "../../../../http/MyOffice/SupplierUpdates/supplierUpdatesApi";
import Styles from "./PendingEventDetailModal.module.scss";

const PendingEventDetailModal = (
  {
    deleteEvent,
    eventId,
    onClose,
    registeredName,
    tradingName,
    removeReleasedEventFromResult,
  }
    :
    {
      deleteEvent: boolean,
      eventId: number,
      onClose: () => void,
      registeredName?: string,
      tradingName?: string,
      removeReleasedEventFromResult: (eventId: number) => void,
    }
) => {
  const [loading, setLoading] = useState(false);
  const showError = useShowError();
  const [approvedChangeEvents, setApprovedChangeEvent] = useState<
  Array<ApprovedEventItemMessage>>([]);
  const [showReleaseEventModal, setShowReleaseEventModal] = useState(false);
  const [showDeleteEventModal, setShowDeleteEventModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);

  const releaseEventHandler = async () => {
    setLoading(true);
    // api call
    try {
      await releaseEvent(eventId, approvedChangeEvents);
      removeReleasedEventFromResult(eventId);
      setSuccessModal(true);
    } catch {
      showError();
    }
    setLoading(false);
  };

  const deleteEventHandler = async () => {
    setLoading(true);
    // api call
    try {
      await releaseEvent(eventId, []);
      removeReleasedEventFromResult(eventId);
      onClose();
    } catch {
      showError();
    }
    setLoading(false);
  };

  return (
    <Fragment>
      { loading && <Spinner />}
      {showReleaseEventModal && (
        <div className={Styles.ConfirmationModal}>
          <ConfirmationModal
            hasCancel
            heading="RELEASE UPDATE TO ERP"
            description={`You have approved ${approvedChangeEvents?.length} event items, do you want to continue and release these updates ERP?`}
            proceedButtonText="Release"
            onToggleModal={() => setShowReleaseEventModal(false)}
            onProceedClick={async () => { await releaseEventHandler(); }}
          />
        </div>
      )}
      {showDeleteEventModal && (
        <div className={Styles.ConfirmationModal}>
          <ConfirmationModal
            hasCancel
            heading="DELETE EVENT"
            description="Are you sure you want to delete this event?"
            proceedButtonText="Delete"
            onToggleModal={() => setShowDeleteEventModal(false)}
            onProceedClick={async () => { await deleteEventHandler(); }}
          />
        </div>
      )}
      {showSuccessModal && (
        <div className={Styles.ConfirmationModal}>
          <ConfirmationModal
            heading="SUPPLIER UPDATES RELEASED!"
            description="The approved supplier updates have been successfully released to your ERP"
            proceedButtonText="Continue"
            image="laptop"
            onProceedClick={() => {}}
            onToggleModal={() => {
              setSuccessModal(false);
              onClose();
            }}
          />
        </div>
      )}

      <ModalPopup
        size="extra-large"
        onClose={onClose}
        hasCancel
        heading={`Pending changes for ${registeredName}`}
        subheading={`Trading Name: ${tradingName}`}
        buttons={[
          {
            key: "ReleaseApprovedEvents",
            label: deleteEvent ? "Delete" : "Release updates to ERP",
            color: "primary",
            onClick: () => {
              if (deleteEvent) {
                setShowDeleteEventModal(true);
              } else {
                setShowReleaseEventModal(true);
              }
            },
          },
        ]}
      >
        <div className="row px-2 py-3">
          <div className="col-12">
            <PendingEventDetailTable
              deleteEvent={deleteEvent}
              eventId={eventId}
              approvedChangeEvents={approvedChangeEvents}
              setApprovedChangeEvent={setApprovedChangeEvent}
            />
          </div>
        </div>
      </ModalPopup>
    </Fragment>
  );
};

export default PendingEventDetailModal;
