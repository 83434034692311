import axios from "axios";

import {
  ClientSpecificSupplierSubCategoriesMessage, // eslint-disable-line no-unused-vars
  ClientSupplierCategoryMessage, SupplierCategoryMessage, // eslint-disable-line no-unused-vars
} from "./Models/Models";

import { makeRequestAsync } from "../util";

export const getClientSupplierCategories = async (buyerCompanyId: number) => makeRequestAsync(
  () => axios.get(`/api/suppliercategory/client/${buyerCompanyId}`)
) as Promise<Array<ClientSupplierCategoryMessage>>;

export const getSupplierCategoriesSelectionAsBuyer = async (supplierCompanyId: number) => makeRequestAsync(
  () => axios.get(`/api/suppliercategory?supplierCompanyId=${supplierCompanyId}`)
) as Promise<Array<number>>;

export const getBuyerProductCategoriesOptions = async (buyerCompanyId: number) => makeRequestAsync(
  () => axios.get(`/api/suppliercategory/options?buyerCompanyId=${buyerCompanyId}`)
) as Promise<Array<SupplierCategoryMessage>>;

export const saveClientSpecificProductCategories = async (message: ClientSpecificSupplierSubCategoriesMessage) => makeRequestAsync(
  () => axios.post("/api/suppliercategory/client", message)
) as Promise<void>;

export const canSupplierEditClientSpecificProductCategories = async (buyerCompanyId: number) => makeRequestAsync(
  () => axios.get(`/api/suppliercategory/edit?buyerCompanyId=${buyerCompanyId}`)
) as Promise<boolean>;
