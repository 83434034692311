import React from "react";
import { BasicTable } from "isuppli-react-components";
import { formatTelNumber } from "../../Validation/onChangeValidations";

const ClientBaseTable = (props:
  {
    updateClientBaseHandler: (ev: { target: { name: string; value: any[] }}) => void,
    readOnly: boolean,
    data: {
      clientBase: {
        name: string | undefined,
        phone: string | undefined,
        periodInBusiness: string | undefined}[]
    },
}) => (
  <div>
    <div className="my-2">
      <h4>CLIENTBASE</h4>
      <p>
        Please capture your current client base.
      </p>
    </div>
    <BasicTable
      columns={[{
        key: "name",
        text: "Name",
        align: "left",
        size: "1fr",
      },
      {
        key: "telephone",
        text: "Tel.Num",
        size: "1fr",
        align: "left",
      },
      {
        key: "period",
        text: "Period in business",
        size: "1fr",
        align: "left",
      },
      ]}
      rows={
        props.data.clientBase?.map((input, index) => [
          {
            key: `name_${index}`,
            fullSize: true,
            valueTemplate: () => (
              <input
                className="position-static border-0"
                type="text"
                name={`${index}`}
                placeholder={`${index + 1}. Client name`}
                value={input.name ?? ""}
                maxLength={200}
                onChange={(e) => {
                  // update client base name
                  const updatedClientBase = [...props.data.clientBase];
                  updatedClientBase[index] = { ...input, name: e.target.value };
                  props.updateClientBaseHandler({
                    target: {
                      name: "clientBase",
                      value: updatedClientBase,
                    },
                  });
                }}
                readOnly={props.readOnly}
                required
              />
            ),
          },
          {
            key: `telNum_${index}`,
            fullSize: true,
            valueTemplate: () => (
              <input
                className="position-static border-0"
                type="text"
                name={`${index}-{telephone}`}
                placeholder={`${index + 1}. Telephone`}
                value={input.phone ?? ""}
                maxLength={50}
                onChange={(e) => {
                  // update client telephone details
                  const updatedClientBase = [...props.data.clientBase];
                  updatedClientBase[index] = { ...input, phone: formatTelNumber(e.target.value) };
                  props.updateClientBaseHandler({
                    target: {
                      name: "clientBase",
                      value: updatedClientBase,
                    },
                  });
                }}
                readOnly={props.readOnly}
                required
              />
            ),
          },
          {
            key: `period_${index}`,
            fullSize: true,
            valueTemplate: () => (
              <input
                className="position-static border-0"
                type="text"
                name={`${index}-{period}`}
                placeholder={`${index + 1}. Period in business`}
                value={input.periodInBusiness ?? ""}
                onChange={(e) => {
                  // update client preiod in business field
                  const updatedClientBase = [...props.data.clientBase];
                  updatedClientBase[index] = { ...input, periodInBusiness: e.target.value };
                  props.updateClientBaseHandler({
                    target: {
                      name: "clientBase",
                      value: updatedClientBase,
                    },
                  });
                }}
                readOnly={props.readOnly}
                required
              />
            ),
          },
        ])
      }
    />
  </div>
);

export default ClientBaseTable;
