import {
  getAddressInfo,
  getKeyContactDetails,
  getDocumentsAsync,
  getBankingDetails,
  getBeeDetailsAsync,
  getAddressInfoAsync,
} from "../http/gets";

import addressTypes from "./Enumerators/addressTypes";

export function loadAddressContactData(props, invitationBuyerCompanyId = null) {
  getAddressInfo(props.data.companyId, (resAddress) => {
    const physicalAddress = resAddress.addresses.find(
      (address) => address.type === addressTypes.Physical
    );
    const postalAddress = resAddress.addresses.find(
      (address) => address.type === addressTypes.Postal
    );
    const { registrationStepCompleted } = resAddress;
    if (props.setLastStepCompleted) {
      props.setLastStepCompleted(registrationStepCompleted);
    }
    getKeyContactDetails(invitationBuyerCompanyId, (resContactPersons) => {
      props.setAddressInfo({
        physicalAddress,
        postalAddress,
        contactPersons: resContactPersons.contactPersons,
        registrationStepCompleted,
        hasTelNumberValidation: resContactPersons.hasTelephoneValidation,
      });
    });
  });
}

export async function loadAddressDataAsync(companyId) {
  const res = await getAddressInfoAsync(companyId);
  const physicalAddress = res.addresses.find(
    (address) => address.type === addressTypes.Physical
  );
  const postalAddress = res.addresses.find(
    (address) => address.type === addressTypes.Postal
  );
  return { physicalAddress, postalAddress };
}

export async function getBeePracticeData(props) {
  const res = await getBeeDetailsAsync(props.data.companyId);
  // convert C# empty date to JS empty date
  const date = res.certificateExpiryDate === "0001-01-01T00:00:00+00:00"
    ? null
    : new Date(res.certificateExpiryDate);

  const beeInfo = {
    ...res,
    certificateExpiryDate: date,
  };
  return beeInfo;
}

export async function loadBeePracticeData(props) {
  const beeInfo = await getBeePracticeData(props);
  props.setBeeInfo(beeInfo);
  if (props.setLastStepCompleted) {
    props.setLastStepCompleted(beeInfo.registrationStepCompleted);
  }

  return beeInfo;
}

export async function loadDocuments(props) {
  const res = await getDocumentsAsync(props.data.companyId, false);
  props.setDocuments(res.companyDocuments);
  return res.companyDocuments;
}

export function loadBankingDetailsData(props) {
  getBankingDetails(props.data.companyId, (res) => {
    props.setBankingDetails(res);
    if (props.setLastStepCompleted) {
      props.setLastStepCompleted(res.registrationStepCompleted);
    }
  });
}
