import React from "react";

export const supplierModalHeading = "UPLOAD SUPPLIERS";

export const supplierModalPreUploadText = (
  <>
    <p>Please upload your suppliers, and invite them to join B1LINK</p>
    <div className="col-12 m-2">
      <b>Steps to follow:</b>
      <ol>
        <li>Download the template below</li>
        <li>Populate your supplier details into the template</li>
        <li>Upload the populated file and continue</li>
      </ol>
    </div>
    <div className="col-12 m-2">
      <b>Do&#39;s and Don&#39;ts:</b>
      <ol>
        <li>Please do not alter the template in any way.</li>
        <li>Please ensure that all mandatory fields are captured</li>
        <li>
          Please ensure that all data is in a valid format for the data type,
          ie: email addresses
        </li>
        <li>Please ensure only one unique record per supplier company</li>
        <li>
          Please limit your file to a maximum of 20 000 records.
          You can upload multiple files if required
        </li>
      </ol>
    </div>
  </>
);

export const supplierModalPostUploadText = (
  <>
    <p>On uploading the file, the system will:</p>
    <div className="col-12 m-2">
      <ol>
        <li>
          Send an automated email to your suppliers,
          containing a unique link from which to register.
          This link will complete the connection between the
          supplier and your company upon registration
        </li>
        <li>Generate a file of rejected records (if any), which you can edit and re-upload</li>
      </ol>
    </div>
    <p>
      On successful upload of records, these will be immediately
      visible in your Onboarding page
    </p>
  </>
);
