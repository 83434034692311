import React, { useState, useCallback, useEffect } from "react";
import {
  PageHeading,
  SearchInput,
  useRouteState,
  Table,
  TableConfig, // eslint-disable-line no-unused-vars
  TableData, // eslint-disable-line no-unused-vars
  TableActionsItem,
  SearchFiltersContainer,
  FilterInfo, // eslint-disable-line no-unused-vars
  FilterTypes,
  FilterValue, // eslint-disable-line no-unused-vars
  FilterOption, // eslint-disable-line no-unused-vars
} from "isuppli-react-components";
import { useHistory } from "react-router";
import navMenuItems from "../../../Util/menuItems";
import { formatDate } from "../../../Util/valueFormatter";

import {
  getPendingEvents,
} from "../../../http/MyOffice/SupplierUpdates/supplierUpdatesApi";

import {
  PendingVendorEventsMessage, // eslint-disable-line no-unused-vars
  PendingVendorEventMessage, // eslint-disable-line no-unused-vars
  SupplierUpdatesSearchCriteria, // eslint-disable-line no-unused-vars
} from "../../../http/MyOffice/SupplierUpdates/Models/Models";
import useStaticCompanyData from "../../../Hooks/useStaticCompanyData";
import { StaticCompanyDataTypes } from "../../../Util/Enumerators/staticCompanyDataTypes";
import SupplierDetailsModal from "../../SupplierDetailsModal";
import useShowError from "../../../Hooks/useShowError";
import PendingEventDetailModal from "./PendingEventDetailModal/PendingEventDetailModal";
import { getCompanyCategoryManagers, getUserBasicDetails } from "../../../http/gets";

interface SupplierUpdateTableItem extends PendingVendorEventMessage {
  formattedDate: string
}

interface Company {
  companyId?: number,
  registeredName?: string
}

interface SupplierCategoryManager{
  value: string,
  display: string
}

const SupplierUpdates = () => {
  const [
    [supplierCategories],
    staticDataReady,
  ] = useStaticCompanyData([
    StaticCompanyDataTypes.supplierCategory,
  ]);
  const [userDetails, setUserDetails] = useState<any>({});
  const [searchTerm, setSearchTerm] = useRouteState("q", "");
  const [currentSearchTerm, setCurrentSearchTerm] = useState(searchTerm);
  const [pageInfo, setPageInfo] = useRouteState("p", { l: 20, o: 0 });
  const [loading, setLoading] = useState(true);
  const [sortOption, setSortOption] = useRouteState("s", 0);
  const [routeFilterValues, setRouteFilterValues] = useRouteState<FilterValue[]>("fv", []);
  const [filterValues, setFilterValues] = useState<FilterValue[]>(routeFilterValues);
  const history = useHistory();

  const [searchResults, setSearchResults] = useState<{
    totalItems: number,
    items: SupplierUpdateTableItem[]
  } | null>(null);

  const [currentEventId, setCurrentEventId] = useState(0);
  const [categoryManagers, setCategoryManagers] = useState<SupplierCategoryManager[]>();
  const [showEventChangesModal, setShowEventChangesModal] = useState(false);
  const [showSupplierDetailsModal, setShowSupplierDetailsModal] = useState(false);
  const [supplierDetailsCompany, setSupplierDetailsCompany] = useState<Company>();

  const showError = useShowError();

  const loadData = useCallback(async (searchInfo: {
    sortOption: number;
    searchTerm: string;
    pageInfo: { l: number; o: number };
    filterValues: FilterValue[];
  }) => {
    try {
      setLoading(true);

      const details = await getUserBasicDetails();
      setUserDetails(details);

      const searchCriteria: SupplierUpdatesSearchCriteria = {
        newestFirst: searchInfo.sortOption === 0,
        searchTerm: searchInfo.searchTerm,
        limit: searchInfo.pageInfo.l,
        offset: searchInfo.pageInfo.o,
        supplierCategoryManagerCodes: searchInfo.filterValues[0] as string[],
        supplierCategories: searchInfo.filterValues[1] as number[],
        supplierSubcategories: searchInfo.filterValues[2] as number[],
      };

      // api call
      const results: PendingVendorEventsMessage = await getPendingEvents(searchCriteria);

      // format date
      const formattedItems = results.items.map((item) => ({
        ...item,
        formattedDate: formatDate(item.dateLastUpdated),
      } as SupplierUpdateTableItem));

      setSearchResults({
        totalItems: results.totalItems,
        items: formattedItems,
      });
    } catch (error) {
      showError();
    }

    setLoading(false);
  }, [setLoading, showError]);

  useEffect(() => {
    setFilterValues(routeFilterValues);

    if (!staticDataReady) {
      return;
    }

    loadData({
      sortOption,
      searchTerm,
      pageInfo,
      filterValues: routeFilterValues,
    });
  }, [searchTerm,
    sortOption,
    pageInfo,
    routeFilterValues,
    staticDataReady,
    loadData,
    history.location,
  ]);

  useEffect(() => {
    const dataLoader = async () => {
      const categoryManagersMessage = await getCompanyCategoryManagers();
      setCategoryManagers(categoryManagersMessage.categoryManagers.map((manager: any) => ({
        value: manager.code,
        display: manager.fullName,
      })));
    };
    dataLoader();
  }, []);

  const onSearchHandler = () => {
    setPageInfo({ l: pageInfo.l, o: 0 });
    setSearchTerm(currentSearchTerm);
    setRouteFilterValues(filterValues ?? []);
  };

  const clearSearch = () => {
    setCurrentSearchTerm("");
    setSearchTerm("");
    setRouteFilterValues([]);
    setFilterValues([]);
  };

  const onFilterValuesChangeHandler = (values: FilterValue[]) => {
    setFilterValues(values);
  };

  const supplierDetailsHandler = (eventId: number) => {
    const rowItem = searchResults?.items.find((item) => item.id === eventId);
    setSupplierDetailsCompany({
      companyId: rowItem?.companyId,
      registeredName: rowItem?.registeredName,
    });
    setShowSupplierDetailsModal(true);
  };

  const tableConfig: TableConfig<number, SupplierUpdateTableItem> = {
    columns: [
      {
        key: "supName",
        heading: "Supplier Name",
        propName: "registeredName",
        size: "1fr",
      },
      {
        key: "tradingName",
        heading: "Trading Name",
        propName: "tradingName",
        size: "1fr",
      },
      {
        key: "vendorNumber",
        heading: "Vendor Number",
        propName: "vendorNumber",
        size: "1fr",
      },
      {
        key: "dateLastUpdated",
        heading: "Date Last Updated",
        propName: "formattedDate",
        size: "1fr",
      },
    ],
    sortOptions: {
      selectedSortOption: sortOption,
      options: [
        { value: 0, display: "Newest first" },
        { value: 1, display: "Oldest first" },
      ],
    },
    actionOptions: [
      <TableActionsItem
        key="supplierDetails"
        onClick={(items: number[]) => supplierDetailsHandler(items[0])}
      >
        View supplier details
      </TableActionsItem>,
      <TableActionsItem
        key="eventChanges"
        onClick={(items: number[]) => {
          setCurrentEventId(items[0]);
          setShowEventChangesModal(true);
        }}
      >
        Show pending changes
      </TableActionsItem>],
  };

  const tableData: TableData<SupplierUpdateTableItem> = {
    limit: pageInfo.l,
    offset: pageInfo.o,
    totalItems: searchResults?.totalItems ?? 0,
    items: searchResults?.items ?? [],
  };

  const selectedCategories = filterValues[1] as number[] ?? [];
  let selectedCategory = 0;
  if (selectedCategories.length === 1) {
    selectedCategory = selectedCategories[0];
  }

  const filters: FilterInfo[] = [
    {
      filterName: "Supplier Category Managers",
      filterType: FilterTypes.MultiSelectDropDown,
      options: categoryManagers as Array<FilterOption>,
    },
    {
      filterName: "Company Product Segment",
      filterType: FilterTypes.MultiSelectDropDown,
      options: supplierCategories as Array<FilterOption>,
    },
    {
      filterName: "Company Product Family",
      filterType: FilterTypes.MultiSelectDropDown,
      options: (supplierCategories
        ?.find((c) => c.value === selectedCategory)
          ?.supplierSubCategories) as Array<FilterOption>,
    },
  ];

  const removeReleasedEventFromResults = (eventId:number) => {
    if (searchResults != null) {
      setSearchResults({
        ...searchResults,
        items: searchResults.items.filter((x) => x.id !== eventId),
      });
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-10 offset-1">

          {showEventChangesModal && (
            <PendingEventDetailModal
              deleteEvent={userDetails?.companyId !== 1}
              removeReleasedEventFromResult={removeReleasedEventFromResults}
              eventId={currentEventId}
              onClose={() => setShowEventChangesModal(false)}
              registeredName={searchResults?.items
                .find((item) => item.id === currentEventId)?.registeredName}
              tradingName={searchResults?.items
                .find((item) => item.id === currentEventId)?.tradingName}
            />
          )}

          {showSupplierDetailsModal && (
            <SupplierDetailsModal
              hasActionItems
              onCloseCallback={() => setShowSupplierDetailsModal(false)}
              isPotentialSupplier={false}
              isSupplierSearch
              company={supplierDetailsCompany}
              onboardSupplierCallback={() => { }}
            />
          )}

          <PageHeading
            heading="Supplier Updates"
            alertText="Review supplier updates."
            menuItems={navMenuItems}
          />
          <SearchInput
            searchTerm={currentSearchTerm}
            onSearch={onSearchHandler}
            onChange={setCurrentSearchTerm}
            searchPlaceholder="Search by company name"
          />

          <div className="mt-4" />

          <SearchFiltersContainer
            filters={filters}
            buttons={[{
              key: "clear", label: "Clear", onClick: clearSearch, color: "secondary",
            }, {
              key: "search", label: "Search", onClick: onSearchHandler, color: "primary",
            }]}
            filterValues={filterValues ?? []}
            onChange={onFilterValuesChangeHandler}
          />

          <div className="mt-4" />

          <Table
            loading={loading}
            tableConfig={tableConfig}
            data={tableData}
            onPageChange={(l: number, o: number) => setPageInfo({ l, o })}
            tableStyle="compact"
            onSortOptionChange={(newValue: number) => {
              setSortOption(newValue);
              setSearchTerm(currentSearchTerm);
            }}
          />
        </div>
      </div>
      <div className="mt-5" />
    </div>
  );
};

export default SupplierUpdates;
