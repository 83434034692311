import React from "react";

const RadioButtonInput = (
  {
    label,
    onChange,
    checked,
    inline = false,
    required,
    validationMessage,
    name,
  }
  :
  {
    inline?: boolean
    checked: boolean,
    onChange: () => void,
    label: string,
    required?: boolean,
    validationMessage?: string,
    name: string,
  }
) => {
  const id = Math.random().toString(36);
  return (
    <div className={`custom-control custom-radio ${inline ? "custom-control-inline" : ""}`}>
      <input
        type="radio"
        id={id}
        name={name}
        className="custom-control-input"
        checked={checked}
        onChange={() => onChange()}
        required={required}
      />
      <label className="custom-control-label" htmlFor={id}>{label}</label>
      {validationMessage && <div className="invalid-feedback">{validationMessage}</div>}
    </div>
  );
};

export default RadioButtonInput;
