import React, { Fragment, useState, useEffect } from "react";
import {
  CheckboxInput, ModalPopup, Spinner, FilterInput,
} from "isuppli-react-components";

import StaticCompanyData from "../../../Hooks/StaticCompanyData";
import staticCompanyDataTypes, {
  StaticCompanyDataTypes,
} from "../../../Util/Enumerators/staticCompanyDataTypes";
import useStaticCompanyData from "../../../Hooks/useStaticCompanyData";

const OfferingSelector = ({
  selection,
  setSelection,
  onCloseCallback,
}: {
  selection: number[];
  setSelection: (value: number[]) => void;
  onCloseCallback: () => void;
}) => {
  const [internalSelection, setInternalSelection] = useState<number[]>([]);

  useEffect(() => {
    setInternalSelection(selection);
  }, [selection]);

  const [[supplierCategories], isReady] = useStaticCompanyData([
    StaticCompanyDataTypes.supplierCategory,
  ]);

  const [filterTerm, setFilterTerm] = useState("");

  const removeSupplierCategory = (item: number) => {
    setInternalSelection((prevState) => prevState.filter((sel) => sel !== item));
  };

  const addSupplierCategory = (item: number) => {
    setInternalSelection((prevState) => [...prevState, item]);
  };

  const toggleItem = (item: number, isSelected: boolean) => {
    if (isSelected) {
      removeSupplierCategory(item);
    } else {
      addSupplierCategory(item);
    }
  };

  const buildSelection = () => {
    const optionsToShow = supplierCategories.map((cat, i) => {
      const optionRows: JSX.Element[][] = [];
      let index = -1;
      cat.supplierSubCategories
        .filter((subCat: any) => (filterTerm.length === 0
          ? true
          : cat.display
            .toLocaleLowerCase()
            .includes(filterTerm.toLocaleLowerCase())
              || subCat.display
                .toLowerCase()
                .includes(filterTerm.toLocaleLowerCase())))
        .forEach((subCat: any, j: number) => {
          if (j % 3 === 0) {
            index += 1;
            optionRows.push([]);
          }
          const isSelected = !!internalSelection.find(
            (s) => s === subCat.value
          );
          optionRows[index].push(
            <div key={j} className="col-12 col-sm-4">
              <CheckboxInput
                value={isSelected}
                label={subCat.display}
                onChange={() => {
                  toggleItem(subCat.value, isSelected);
                }}
              />
            </div>
          );
        });
      const options = optionRows.map((row, k) => (
        <div key={k} className="row" style={{ paddingBottom: "17px" }}>
          {row}
        </div>
      ));
      return options.length > 0 ? (
        <section key={i}>
          <div
            className="bg-primary text-white text-center"
            style={{ borderRadius: "7px", marginBottom: "17px" }}
          >
            <h2 className="SelectionSummary">{cat.display}</h2>
          </div>
          <div>{options}</div>
        </section>
      ) : null;
    });
    return optionsToShow;
  };

  const onApplyClick = () => {
    setSelection(internalSelection);
    onCloseCallback();
  };

  const onFilter = (term: string) => {
    setFilterTerm(term);
  };

  const onFilterChange = (ev: React.FormEvent<HTMLInputElement>) => {
    setFilterTerm(ev.currentTarget.value);
  };

  return (
    <Fragment>
      <StaticCompanyData
        optionsToLoad={[staticCompanyDataTypes.supplierCategory]}
      />
      <ModalPopup
        stickyButtons
        onClose={() => onCloseCallback()}
        hasCancel
        heading="SELECT PRODUCT / SERVICE CATEGORIES"
        subheading="Please indicate your primary product and/or service offering only.
            Keep in mind that buyers may be wary of a supplier who claims to do everything."
        size="large"
        buttons={[
          {
            key: "ok",
            label: "Save",
            onClick: () => {
              onApplyClick();
            },
            color: "primary",
          },
        ]}
      >
        <p className="mx-2">
          B1LINK is authorized to adopt The United Nations Standard Products and
          Services Code (UNSPSC).
        </p>
        <div className="row pt-3 px-2">
          <div className="col-12">
            <FilterInput
              onFilterCallback={onFilter}
              onChangeCallback={onFilterChange}
              placeholder="search for products or services"
            />
          </div>
        </div>
        <div className="row pt-3 px-2">
          <div className="col-12">
            {isReady ? buildSelection() : <Spinner inline />}
          </div>
        </div>
      </ModalPopup>
    </Fragment>
  );
};

export default OfferingSelector;
