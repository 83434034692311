/* eslint-disable no-unused-vars */
enum CompanyConfigurationFeatures {
  None = 0,
  Contracting = 0x1,
  MTOCompanyProductsAndFamilies = 0x2,
  MTOProductAndSegments = 0x4,
  MTORestrictions = 0x8,
  MTOMotivation = 0x10,
}
export default CompanyConfigurationFeatures;
