import React, { Component } from "react";
import ValidationError from "../ValidationError/ValidationError";
import { ReactComponent as CalenderSvg } from "./calender.svg";

class DateInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasFocus: false,
      msgs: [],
      localValue: "",
    };
  }

  static getDerivedStateFromProps(props, state) {
    const dateToLocalValue = (date) => {
      if (date == null || date === "") {
        // An empty string is equivalent to a empty date value for date inputs
        return "";
      }

      const dateObject = new Date(date);
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };

      return dateObject.toLocaleDateString("en-ZA", options).replaceAll("/", "-");// date.toString().substr(0, 10);
    };
    const newValue = dateToLocalValue(props.value);
    if (!state.hasFocus) {
      return {
        localValue: newValue,
      };
    }

    return null;
  }

  onChange = (ev) => {
    const newStringValue = ev.target.value?.replaceAll("/", "-");

    const event = {
      target: {
        name: ev.target.name,
        // empty string (unselected date) needs to be kept.
        value: newStringValue === "" ? null : newStringValue,
      },
    };
      // valid date
    this.props.onChangeCallback(event);

    this.setState({
      localValue: newStringValue,
    });
  };

  onFocus = () => {
    this.setState({
      hasFocus: true,
    });
  };

  onBlur = () => {
    if (this.state.hasFocus) {
      this.setState({
        hasFocus: false,
      });
    }
  };

  render() {
    const additional = this.state.msgs.map((msg, i) => (
      <label className="validation-label" key={i}>
        {msg}
      </label>
    ));

    const inputClassName = "full-width user-input-border";

    const getDateString = (dateTimeValue) => {
      if (dateTimeValue == null) {
        // If min date not provided, then set mininum to Today's Date
        const today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1; // January is 0!
        const yyyy = today.getFullYear();
        if (dd < 10) {
          dd = `0${dd}`;
        }
        if (mm < 10) {
          mm = `0${mm}`;
        }
        const todayString = `${yyyy}-${mm}-${dd}`;
        return todayString;
      }

      // Set minimum to provided min date
      const monthNumber = dateTimeValue.slice(5, 7);

      const dayNumber = dateTimeValue.slice(8, 10);

      const year = dateTimeValue.slice(0, 4);

      const month = `${monthNumber}`.slice(-2);
      const day = `${dayNumber}`.slice(-2);
      return `${year}-${month}-${day}`;
    };

    const minDateValue = getDateString(this.props.min);

    return (
      <section className="user-input bottom-margin-20px">
        <div>
          <label>{this.props.label}</label>
        </div>
        <div className="round-borders align-center padding-8px center-text">
          <CalenderSvg className="user-input-img" />
          <input
            type="date"
            min={minDateValue}
            max="2099-12-31"
            placeholder="yyyy-mm-dd"
            className={inputClassName}
            name={this.props.name}
            value={this.state.localValue}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            disabled={this.props.disabled}
          />
        </div>
        {!!this.props.additional && this.props.additional.length > 0 && (
          <ValidationError>
            <label className="validation-label">{this.props.additional}</label>
          </ValidationError>
        )}
        {additional.length !== 0 && (
          <ValidationError>{additional}</ValidationError>
        )}
      </section>
    );
  }
}

export default DateInput;
