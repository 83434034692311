import React, { Fragment, useMemo } from "react";
import DropdownInput from "../../Controls/DropdownInput";
import DateInput from "../../Controls/DateInput/DateInput";

import filterBeeStatuses from "../../Validation/businessRules";

const BeeCertification = (props) => {
  const filteredBeeLevels = useMemo(() => filterBeeStatuses(
    props.beeLvls,
    props.sectorTurnoverBands,
    props.sectorTurnoverBandStatuses,
    props.data.sector,
    props.data.turnoverBand,
    props.data.boPercentage
  ), [
    props.beeLvls,
    props.sectorTurnoverBands,
    props.sectorTurnoverBandStatuses,
    props.data.sector,
    props.data.turnoverBand,
    props.data.boPercentage,
  ]);

  return (
    <Fragment>
      <div>
        <h2>
          CERTIFICATE DETAILS
        </h2>
      </div>
      <div className="row">
        <div className="col-12 col-sm-5">
          <DropdownInput
            name="beeStatusId"
            label="B-BBEE Level"
            value={props.data.beeStatusId ?? 0}
            options={filteredBeeLevels}
            additional={props.additional?.beeStatusId || null}
            onChangeCallback={props.onInputChangeCallback}
            disabled={props.readOnly}
          />
        </div>

        <div className="col-12 col-sm-2" />

        <div className="col-12 col-sm-5">
          <DateInput
            min="1900-01-01"
            label="Expiry Date of Certificate"
            name="certificateExpiryDate"
            value={props.data.certificateExpiryDate}
            additional={props.additional?.certificateExpiryDate || null}
            onChangeCallback={props.onInputChangeCallback}
            disabled={props.readOnly}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default BeeCertification;
