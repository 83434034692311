import { formatDate } from "isuppli-react-components";
import {
  // eslint-disable-next-line no-unused-vars
  ComplianceDetailedChecksMessage,
  // eslint-disable-next-line no-unused-vars
  OnboardingComplianceExceptionMessage,
  // eslint-disable-next-line no-unused-vars
  ComplianceResultMessage,
} from "../http/Compliance/Models/Models";
import ComplianceStatus from "./Enumerators/ComplianceStatus";

export interface DetailsCheckDisplayItem extends ComplianceResultMessage {
  dateCheckedDisplay: string;
}

export interface DetailsDisplay extends ComplianceDetailedChecksMessage {
  complianceResults: DetailsCheckDisplayItem[];
  reason?: string;
}

export const getCheckStatus = (check: ComplianceResultMessage) => {
  let status = ComplianceStatus.AwaitingResults;

  if (check.totalFailedChecks > 0) {
    status = ComplianceStatus.Error;
  } else if (
    check.totalPassedChecks > 0
    && (check.procureCheckResultItems == null
      || check.procureCheckResultItems.length === check.totalPassedChecks)
  ) {
    status = ComplianceStatus.Complete;
  }

  return status;
};

export const getFormattedComplianceDetails = (
  details: ComplianceDetailedChecksMessage,
  exceptionReason: OnboardingComplianceExceptionMessage
) => {
  let overAllStatus = ComplianceStatus.AwaitingResults;
  if (
    details.status === ComplianceStatus.Disabled
  ) {
    overAllStatus = ComplianceStatus.Disabled;
  } else if (
    details.status === ComplianceStatus.Error
    || details.complianceResults.some(
      (c) => getCheckStatus(c) === ComplianceStatus.Error
    )
  ) {
    overAllStatus = ComplianceStatus.Error;
  } else if (
    details.complianceResults.every(
      (c) => getCheckStatus(c) === ComplianceStatus.Complete
    )
  ) {
    overAllStatus = ComplianceStatus.Complete;
  }

  const formattedDetails = {
    ...details,
    status: overAllStatus,
    reason: exceptionReason?.reason,
    complianceResults: [
      ...details.complianceResults.map(
        (item) => ({
          ...item,
          dateCheckedDisplay: formatDate(item.dateModified),
        } as DetailsCheckDisplayItem)
      ),
    ],
  };

  return formattedDetails;
};
