import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

import Styles from "./TextAreaInput.module.scss";

const TextAreaInput = (
  {
    label,
    required,
    maxLength,
    value,
    onChange,
    className,
    validationMessage,
    placeholder,
  }
  :
  {
    label: string,
    value: string,
    onChange: (value: string) => void,
    required?: boolean,
    maxLength?: number,
    className?: string,
    validationMessage?: string,
    placeholder?: string,
  }
) => {
  const inputId = `${label}-${Math.random().toString(36).substring(2)}`;
  return (
    <FormGroup className={`${className} ${Styles.FormGroup}`}>
      <Label for={inputId}>{label}</Label>
      <Input
        type="textarea"
        name={inputId}
        id={inputId}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        required={required}
        maxLength={maxLength}
      />
      {maxLength && (
        <div className={`${Styles.Characters} d-flex justify-content-end mt-2`}>
          <span className="mr-2">Characters left</span>
          <span className={Styles.Count}>
            {`(${maxLength > value.length ? maxLength - value.length : 0})`}
          </span>
        </div>
      )}
      { validationMessage && (
        <div className="invalid-feedback">
          {validationMessage}
        </div>
      )}
    </FormGroup>
  );
};

export default TextAreaInput;
