import React from "react";

import KeyContactStaffOptions from "./KeyContactStaffOptions";

import { formatTelNumber, filterOutNumbers, filterOutLetters } from "../../Validation/onChangeValidations";
import useStaticData from "../../Hooks/useStaticData";
import { StaticDataTypes } from "../../Util/Enumerators/staticDataTypes";

const KeyContactStaff = (props) => {
  const [[contactPersonTypes], staticDataReady] = useStaticData(
    [StaticDataTypes.ContactPerson]
  );
  const onDetailsChange = (ev, contactPerson, role, filters) => {
    let { value } = ev.target;
    filters.forEach((filter) => {
      value = filter(value);
    });

    const newDetailsEntry = {
      ...contactPerson,
      contactPersonTypeId: role,
      [ev.target.name]: value,
    };
    props.onInputChangeCallback(newDetailsEntry);
  };

  const setRole = (targetRole, id, value, isGeneral) => {
    const newEntry = {
      ...value,
      id,
      contactPersonTypeId: targetRole,
      isGeneral,
    };
    props.onInputChangeCallback(newEntry);
  };

  const getDetailsCell = (name, value, contactPerson, role, filters = []) => (
    <td>
      <input
        readOnly={props.readOnly
          || (props.generalReadOnly && contactPerson.isGeneral)}
        name={name}
        value={value || ""}
        onChange={(ev) => onDetailsChange(ev, contactPerson, role, filters)}
        autoComplete="nofill"
        maxLength={40}
      />
    </td>
  );

  const getTableContent = () => {
    const headerRow = (
      <tr>
        <th>Designation</th>
        <th>Name</th>
        <th>ID Number</th>
        <th>Tel. Number</th>
        <th>Email Address</th>
        {props.readOnly ? null : <th>Options</th>}
      </tr>
    );
    const dataRows = !staticDataReady ? null : props.details.map((contactPerson) => {
      const contactPersonType = contactPersonTypes.find(
        (c) => c.id === contactPerson.contactPersonTypeId
      );
      let contactPersonTypeDisplay = contactPersonType.name;
      if (props.allContactsMandatory
        || (contactPersonType.name === "Primary Contact" && props.primaryContactMandatory)) {
        contactPersonTypeDisplay = `*${contactPersonTypeDisplay}`;
      }
      return (
        <tr key={contactPersonType.id}>
          <td>{contactPersonTypeDisplay}</td>
          {getDetailsCell(
            "fullName",
            contactPerson?.fullName,
            contactPerson,
            contactPersonType.id,
            [filterOutNumbers]
          )}
          {getDetailsCell("idNumber", contactPerson?.idNumber, contactPerson, contactPersonType.id)}
          {getDetailsCell(
            "contactNumber",
            contactPerson?.contactNumber,
            contactPerson,
            contactPersonType.id,
            props.hasTelNumValidation ? [formatTelNumber] : [filterOutLetters]
          )}
          {getDetailsCell("email", contactPerson?.email, contactPerson, contactPersonType.id)}
          {props.readOnly || (props.generalReadOnly && contactPerson.isGeneral)
            ? null
            : (
              <td>
                <KeyContactStaffOptions
                  readOnly={props.readOnly}
                  role={contactPersonType.id}
                  contacts={props.details}
                  setRoleCallback={
                    (selectedValue) => setRole(
                      contactPersonType.id,
                      contactPerson?.id ?? 0,
                      selectedValue,
                      contactPerson?.isGeneral ?? false
                    )
                  }
                  contactPersonTypes={staticDataReady ? contactPersonTypes : []}
                />
              </td>
            )}
        </tr>
      );
    });
    return (
      <table className="full-width isuppli-table">
        <thead>{headerRow}</thead>
        <tbody>{dataRows}</tbody>
      </table>
    );
  };

  return <section>{getTableContent()}</section>;
};

export default KeyContactStaff;
