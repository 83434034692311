import React, { Fragment } from "react";
import { HelpIcon, UtilButton } from "isuppli-react-components";

import Styles from "./PlanOption.module.scss";
import { formatCurrency } from "../../../Util/valueFormatter";
import { ReactComponent as CheckMarkSvg } from "../../../Images/check-mark.svg";

const PlanOption = (props) => {
  const propFeatures = props.config.features;

  const features = propFeatures.map((feature, i) => (
    <div key={i} className={`${Styles.OptionTierFeature} d-flex`}>
      {(props.config.features.length > 1)
        ? (
          <Fragment>
            <CheckMarkSvg className="image-drop-shadow text-primary" />
            <div className="flex-fill ml-2">{feature.name}</div>
          </Fragment>
        )
        : <div className="flex-fill">{feature.name}</div>}
    </div>
  ));

  let priceValue = "";
  if (props.config.monthlyPriceInclVAT === null) {
    priceValue = "ON ENQUIRY";
  } else if (props.config.monthlyPriceInclVAT === 0) {
    priceValue = "FREE";
  } else {
    priceValue = formatCurrency(props.config.monthlyPriceInclVAT);
  }

  const planName = props.config.display.trim();

  const buttonText = props.disabled
    ? "ENQUIRE NOW"
    : "SELECT";

  // TODO make custom sponsored message, not hard coded
  const date = new Date();
  date.setFullYear(date.getFullYear() + 1);
  // eslint-disable-next-line no-nested-ternary
  const message = props.sponsored && !props.disabled
    ? props.sponsorName === "FRASER ALEXANDER"
      ? `Sponsored until ${date.toLocaleDateString()} by Fraser Alexander. For qualifying beneficiaries, acceptance of this sponsorship constitutes a valid Enterprise and Supplier Development relationship between the parties`
      : `Sponsored by ${props.sponsorName}`
    : "Per month VAT incl.";

  const fundedComplianceMessage = props.fundCompliance && !props.disabled
    ? "Compliance will be funded by Fraser Alexander upon successful completion of the onboarding process. For qualifying beneficiaries, acceptance of this funded compliance constitutes a valid Enterprise & Supplier Development agreement between the parties."
    : "";

  return (
    <div className={`${props.className} ${Styles.Container}`}>
      <div
        className={`${Styles.TierOption} h-100 w-100 d-flex flex-column ${
          props.isSelected ? Styles.TierOptionSelected : ""
        } ${
          props.disabled
            ? Styles.TierOptionDisabled
            : ""
        }`}
      >
        <div className="flex-fill">
          <div className={`${Styles.TierOptionHeading} d-flex align-items-center`}>
            <h2 className={Styles.Tier}>{planName}</h2>
            <HelpIcon tooltipMessage={props.config.description} />
          </div>
          <div className="my-4">
            {features}
          </div>
        </div>

        <div className={`${Styles.TierOptionFooter} d-flex flex-column justify-content-end`}>
          <div className="flex-fill">
            <h2 className="mb-0">PRICE</h2>
            <h1 className={`margin-null ${props.sponsored
              && !props.disabled
              ? Styles.TierOptionSponsored
              : ""}`}
            >
              {priceValue}
            </h1>
            {props.fundCompliance && props.config.monthlyPriceInclVAT === 0 && (
              <h3 className="mb5">
                {fundedComplianceMessage}
              </h3>
            )}
            {props.config.monthlyPriceInclVAT > 0 && props.config.monthlyPriceInclVAT < 10000 && (
              <h3 className="mb5">
                {message}
              </h3>
            )}
          </div>

          <div>
            <UtilButton
              className="w-100"
              color={props.isSelected ? "primary" : "secondary"}
              outline={!props.isSelected}
              location="top"
              onClick={() => {
                if (!props.disabled) {
                  props.onActionCallback();
                } else {
                  props.onEnquire();
                }
              }}
            >
              {buttonText}
            </UtilButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanOption;
