enum CloseAccountReasons {
  None = 0, // eslint-disable-line no-unused-vars
  NoBusinessValue = 1, // eslint-disable-line no-unused-vars
  ManagingProfileFrustrating = 2, // eslint-disable-line no-unused-vars
  BusinessNoLongerTrading = 4, // eslint-disable-line no-unused-vars
  FoundBetterSolution = 8, // eslint-disable-line no-unused-vars
  Other = 16 // eslint-disable-line no-unused-vars
}

export const closeAccountReasonToString = (value: CloseAccountReasons) => {
  switch (value) {
    case CloseAccountReasons.NoBusinessValue:
      return "I received little or no business value from the platform.";
    case CloseAccountReasons.ManagingProfileFrustrating:
      return "Managing my profile was frustrating and not worth the effort.";
    case CloseAccountReasons.BusinessNoLongerTrading:
      return "Sadly, my business will no longer be trading.";
    case CloseAccountReasons.FoundBetterSolution:
      return "I have found a better solution for my needs.";
    case CloseAccountReasons.Other:
      return "Other";
    default:
      return "";
  }
};

export default CloseAccountReasons;
