import React, { useEffect, useState } from "react";
import {
  BasicTable,
  ModalPopup,
} from "isuppli-react-components";
import { getOnboardingReqeustComments } from "../../../http/Onboarding/OnboardingApi";
// eslint-disable-next-line no-unused-vars
import CommentMessage from "./CommentMessage";
import useShowError from "../../../Hooks/useShowError";

const CommentModal = ({
  onClose,
  onboardingRequestId = -1,
}
:
{
  onClose: () => void,
  onboardingRequestId: number
}) => {
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState<CommentMessage[]>();

  const showError = useShowError();

  useEffect(() => {
    const load = async () => {
      const results: { comments: CommentMessage[] } = await getOnboardingReqeustComments(onboardingRequestId);
      setComments(results.comments);
    };

    setLoading(true);

    try {
      load();
    } catch (error) {
      showError();
    }

    setLoading(false);
  }, [setLoading, setComments, getOnboardingReqeustComments]);

  return (
    <ModalPopup
      heading="Comments"
      subheading="Onboarding request comments"
      hasCancel
      cancelButtonText="Back"
      onClose={onClose}
    >
      <BasicTable
        emptyMessage="No comments to view"
        loading={loading}
        columns={[{
          key: "actionType",
          text: "Type",
          align: "left",
          size: "1fr",
        },
        {
          key: "date",
          text: "Date",
          align: "left",
          size: "1fr",
        },
        {
          key: "user",
          text: "User",
          align: "left",
          size: "1fr",
        },
        {
          key: "reason",
          text: "Message",
          align: "left",
          size: "1fr",
        }]}
        rows={comments?.map((comment) => [
          {
            key: `type${comment.onboardingId}`,
            value: comment.commentType,
          },
          {
            key: `date${comment.onboardingId}`,
            value: comment.date,
          },
          {
            key: `user${comment.onboardingId}`,
            value: comment.user,
          },
          {
            key: `reason${comment.onboardingId}`,
            value: comment.reason,
          },
        ]) ?? []}
      />
    </ModalPopup>
  );
};

export default CommentModal;
