import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Badge } from "reactstrap";
import {
  DataFeatures,
  hasFeature,
  SystemFeatures,
} from "isuppli-react-components";
import { withRouter } from "react-router-dom";
import DateInput from "../../Controls/DateInput/DateInput";
import TextInput from "../../Controls/TextInput/TextInput";
import RadioSelector from "../../Controls/RadioSelector";
import DropdownInput from "../../Controls/DropdownInput";
import CompanyGroupingType from "../../Util/Enumerators/CompanyGroupingType";
import
ComplianceStatus, {
  complianceStatusToString,
  getComplianceStatusBadgeColor,
} from "../../Util/Enumerators/ComplianceStatus";
import {
  validateVatNumber,
  maxLength,
  filterOutLetters,
  filterOutSpecialCharactersAndWhiteSpace,
  companyNameFilter,
  minLength,
  getRegNumberConfig,
  onCompanyTypeChangeHandler,
} from "../../Validation/onChangeValidations";
import { getCompanyDataFeatures } from "../../http";

const mapStateToProps = (state) => ({
  allowedFeatures: state.loggedInUserDetails.allowedFeatures,
});
class RegistrationCompanyInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFeatures: 0,
    };
  }

  async componentDidMount() {
    const companyDataFeatures = await getCompanyDataFeatures();
    this.setState({ dataFeatures: companyDataFeatures });
  }

  onTurnoverChangeHandler(selectedBand) {
    if (!selectedBand.vatExempted && (
      this.props.data.hasVatNumber === 0
      || (this.props.data.vatNumber == null || this.props.data.vatNumber.trim().length <= 0)
    )) {
      // vat number is required. Set redux state
      this.props.onInputChangeCallback({
        target: {
          name: "hasVatNumber",
          value: 1,
        },
      });
    } else if (selectedBand.vatExempted && (this.props.data.vatNumber == null || this.props.data.vatNumber.trim().length <= 0)) {
      // if the user has not filled in a vat number, assume that has vat number is false
      this.props.onInputChangeCallback({
        target: {
          name: "hasVatNumber",
          value: 0,
        },
      });
    }
  }

  render() {
    const currentCompanyType = this.props.companyTypes.filter(
      (c) => c.id === this.props.data.companyType
    )[0];

    const selectedCompanyTypeName = currentCompanyType?.name;

    const isForeignCompany = currentCompanyType?.companyGroupingType === CompanyGroupingType.Foreign ?? false;
    const regNumberConfig = getRegNumberConfig(selectedCompanyTypeName, this.props.setStepValid);
    return (
      <Fragment>
        <div>
          <h2 className="Main">
            COMPANY REGISTRATION INFORMATION
            {hasFeature(this.props.allowedFeatures, SystemFeatures.OngoingCompliance)
              && (this.props.validationStatus !== null
                && this.props.validationStatus !== ComplianceStatus.None)
              ? (
                <Badge
                  href="/mycompany/compliance"
                  className="ml-2"
                  color={getComplianceStatusBadgeColor(this.props.validationStatus)}
                  onClick={(ev) => {
                    ev.preventDefault();
                    this.props.history.push("/mycompany/compliance");
                  }}
                >
                  {complianceStatusToString(this.props.validationStatus)}
                </Badge>
              )
              : null}
          </h2>
        </div>

        <div className="row">
          <div className="col-12 col-lg-8">
            Please capture your company&apos;s registration details
            as they appear on your Certificate of Incorporation,
            relevant Company Registration Document or ID document.
          </div>
        </div>

        <section
          id="isuppli-registration-company-info"
          className="top-margin-30px"
        >
          <div className="row">
            <div className="col-12 col-md-5">
              <TextInput
                label="Registered Company Name"
                placeholder="as registered with the CIPC"
                name="registeredName"
                value={this.props.data.registeredName}
                iconName="document"
                additional={this.props.additional.registeredName}
                onChangeCallback={this.props.onInputChangeCallback}
                filters={[companyNameFilter]}
                maxLength={100}
                validations={[maxLength(100, this.props.setStepValid)]}
                disabled={this.props.readOnly
                  || this.props.disableEditRegistrationName}
              />
            </div>

            <div className="col-md-2" />

            <div className="col-12 col-md-5">
              <DropdownInput
                label="Company Type"
                name="companyType"
                value={this.props.data ? this.props.data.companyType || -1 : -1}
                options={this.props.companyTypes}
                additional={this.props.additional.companyType}
                onChangeCallback={(ev) => {
                  this.props.onInputChangeCallback(ev);
                  onCompanyTypeChangeHandler(
                    ev,
                    this.props.companyTypes,
                    this.props.onInputChangeCallback
                  );
                }}
                disabled={this.props.readOnly
                  || this.props.regNumberReadOnly}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-5">
              <TextInput
                label="Company Trading Name (optional)"
                placeholder="trading name"
                name="tradingName"
                value={this.props.data.tradingName}
                iconName="document"
                onChangeCallback={this.props.onInputChangeCallback}
                maxLength={100}
                validations={[maxLength(100, this.props.setStepValid)]}
                disabled={this.props.readOnly && !this.props.isSubAccount}
              />
            </div>

            <div className="col-md-2" />

            <div className="col-12 col-md-5">
              <TextInput
                label={regNumberConfig.idNumValidation
                  ? "South African ID Number"
                  : "Company Registration Number"}
                placeholder={regNumberConfig.regNumberPlaceholder}
                maxLength={regNumberConfig.maxLengthValue}
                name="registrationNumber"
                value={this.props.data.registrationNumber}
                iconName="document"
                filters={regNumberConfig.regNumberFilterConfig}
                validations={regNumberConfig.regNumberValidationsConfig}
                additional={this.props.additional.registrationNumber}
                onChangeCallback={this.props.onInputChangeCallback}
                disabled={this.props.readOnly
                  || this.props.regNumberReadOnly}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-5">
              <TextInput
                label="Holding Company Name (optional)"
                placeholder="holding name"
                name="holdingCompanyName"
                value={this.props.data.holdingCompanyName}
                iconName="document"
                onChangeCallback={this.props.onInputChangeCallback}
                filters={[companyNameFilter]}
                maxLength={100}
                validations={[maxLength(100, this.props.setStepValid)]}
                disabled={this.props.readOnly}
              />
            </div>
          </div>

          <div>
            <div style={{ paddingTop: "50px" }}>
              <h2>TAX INFORMATION</h2>
            </div>
            <div className="row">
              <div className="col-12 col-lg-8">
                <p className="spaced-description">
                  In accordance with South African Tax regulations,
                  unless specifically exempt, any business with annual turnover
                  in excess of R1m is required to register for VAT.
                </p>
              </div>
            </div>

            <RadioSelector
              className="mt-4 mb-3"
              name="hasVatNumber"
              label="Is your company registered for VAT"
              value={this.props.data.hasVatNumber}
              options={[
                { value: 1, display: "yes" },
                { value: 0, display: "no" },
              ]}
              additional={this.props.additional.hasVatNumber}
              onChangeCallback={this.props.onInputChangeCallback}
              disabled={this.props.readOnly}
            />

            <div className="row">
              {this.props.data.hasVatNumber === 1 && (
                <Fragment>
                  <div className="col-12 col-md-5">
                    <TextInput
                      label="Company VAT Number"
                      placeholder="VAT number"
                      name="vatNumber"
                      value={this.props.data.vatNumber}
                      iconName="open-envelope"
                      additional={this.props.additional.vatNumber}
                      filters={isForeignCompany ? [] : [filterOutLetters]}
                      maxLength={isForeignCompany ? 50 : 10}
                      validations={isForeignCompany ? [] : [
                        minLength(10, this.props.setStepValid),
                        maxLength(10, this.props.setStepValid),
                        validateVatNumber(this.props.setStepValid),
                      ]}
                      onChangeCallback={this.props.onInputChangeCallback}
                      disabled={this.props.readOnly}
                    />
                  </div>

                  <div className="col-md-2" />
                </Fragment>
              )}
            </div>

            <div className="row">
              <div className="col-12 col-md-5">
                <TextInput
                  label={`Tax Clearance Pin Number${hasFeature(this.state.dataFeatures, DataFeatures.MandatoryTaxClearancePinNumber) ? " (optional)" : ""}`}
                  placeholder="clearance pin"
                  name="taxClearanceNumber"
                  value={this.props.data.taxClearanceNumber}
                  iconName="open-envelope"
                  additional={this.props.additional.taxClearanceNumber}
                  filters={[filterOutSpecialCharactersAndWhiteSpace]}
                  maxLength={15}
                  validations={[maxLength(15, this.props.setStepValid)]}
                  onChangeCallback={this.props.onInputChangeCallback}
                  disabled={this.props.readOnly}
                />
              </div>

              <div className="col-md-2" />

              <div className="col-md-5" />
            </div>

            <div className="row">
              <div className="col-12 col-md-5">
                <DateInput
                  label={`Tax Clearance Pin Number Expiry Date${hasFeature(this.state.dataFeatures, DataFeatures.MandatoryTaxClearancePinNumber) ? " (optional)" : ""}`}
                  name="taxClearanceNumberExpiryDate"
                  value={new Date(`${this.props.data.taxClearanceNumberExpiryDate}`)}
                  onChangeCallback={this.props.onInputChangeCallback}
                />
              </div>

              <div className="col-md-2" />

              <div className="col-md-5" />
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps, null)(RegistrationCompanyInfo));

export const WrappedRegistrationCompanyInfo = (props) => (
  <RegistrationCompanyInfo
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);
