import React, {
  Fragment,
  // eslint-disable-next-line no-unused-vars
  CSSProperties,
} from "react";
import CheckboxInput from "../../../CheckboxInput/CheckboxInput";
import UtilButton from "../../../UtilButton/UtilButton";
import ActionsDropdown from "../../../ActionsDropDown/ActionsDropDown";
// eslint-disable-next-line no-unused-vars
import { TableColumn } from "../Interfaces/TableColumn";
// eslint-disable-next-line no-unused-vars
import { FooterItem } from "../Interfaces/FooterItem";
import ShadowCard from "../../../../Containers/ShadowCard/ShadowCard";
import Line from "../../../Line/Line";

import Styles from "./TableRowItem.module.scss";
// eslint-disable-next-line no-unused-vars
import { TableActionsItemProps } from "../../TableActionsItem/TableActionsItem";
// eslint-disable-next-line no-unused-vars
import { TableItem } from "../Interfaces/TableItem";
// eslint-disable-next-line no-unused-vars
import { SystemFeatures } from "../../../..";

const TableRowItem = <TIdType, TDataType extends TableItem<TIdType>>(
  {
    columns,
    data,
    footerItems,
    actionOptions,
    gridTemplateColumns,
    selected,
    onSelectedChanged,
    canSelect,
    compact,
    moreButtonText = "View more details",
    onViewMore,
    onViewMoreDisabled,
    disabled = false,
    requiredItemSelectFeature,
    requiredViewMoreFeature,
  }
    :
    {
      columns: Array<TableColumn<TDataType>>,
      data: TDataType,
      footerItems?: Array<FooterItem<TDataType>>,
      actionOptions?: Array<React.ReactElement<TableActionsItemProps<TIdType, TDataType>>>,
      gridTemplateColumns: CSSProperties,
      selected: boolean,
      onSelectedChanged: (value: boolean) => void,
      canSelect: boolean,
      compact: boolean,
      moreButtonText: string | undefined,
      onViewMore?: (data: TDataType) => void,
      onViewMoreDisabled?: boolean
      disabled?: boolean,
      requiredItemSelectFeature?: SystemFeatures
      requiredViewMoreFeature?: SystemFeatures,
    }
) => {
  if (data.id == null) {
    // eslint-disable-next-line no-console
    console.error("You have to specify property ID on all items in a table");
  }

  const moreDetailsClickHandler = () => {
    if (onViewMore != null) {
      onViewMore(data);
    }
  };

  const getDisplayValue = (
    footerItem: {
      valueTemplate?: (value: TDataType) => JSX.Element | string, propName?: keyof TDataType
    },
    itemData: TDataType
  ) => {
    if (footerItem.valueTemplate != null) {
      return footerItem.valueTemplate(itemData);
    }

    if (footerItem.propName == null) {
      return null;
    }

    return itemData[footerItem.propName];
  };

  const tableCells = columns.map((column, index) => {
    const style : CSSProperties = {
      gridColumn: compact && index === 0 ? `span ${columns.length - 1}` : undefined,
    };

    return (
      <Fragment key={column.key}>
        {(!compact || canSelect || index !== 0) && (
          <div className={`mobile-version ${Styles.TableCell} ${!canSelect ? Styles.NoCheckbox : ""}`}>
            <label className={Styles.CellLabel} htmlFor="nothing">{column.heading}</label>
          </div>
        )}

        <div className={`${Styles.TableCell} ${!canSelect ? Styles.NoCheckbox : ""}`} style={style}>
          {compact && index !== 0 && (
            <label className={`desktop-version ${Styles.CompactTableCellHeading} pr-1`} htmlFor="nothing">
              {column.heading}
              :
            </label>
          )}

          <label className={`${index === 0 ? Styles.FirstValue : ""} w-100`} htmlFor="nothing">
            {getDisplayValue(column, data)}
          </label>
        </div>
      </Fragment>
    );
  });

  const footerHtmlItems = footerItems?.map((footerItem) => {
    const displayValue = getDisplayValue(footerItem, data);
    if (footerItem.label == null && displayValue == null) {
      return null;
    }
    return (
      <span key={footerItem.key} className={Styles.FooterItemSpan}>
        {footerItem.label && <label htmlFor="nothing">{footerItem.label}</label>}
        {getDisplayValue(footerItem, data)}
      </span>
    );
  });

  let footerColumnSpanStyle;
  if (compact) {
    // first item takes up it's own row
    footerColumnSpanStyle = {
      gridColumn: `span ${columns.length - 1}`,
    };
  } else if (canSelect) {
    // figure in checkbox
    footerColumnSpanStyle = {
      gridColumn: `span ${columns.length + 1}`,
    };
  } else {
    footerColumnSpanStyle = {
      gridColumn: `span ${columns.length}`,
    };
  }

  const rowItemActionOptions = actionOptions?.map((item) => React.cloneElement(item, {
    onClick: () => {
      if (!item.props.onClick) {
        return;
      }

      item.props.onClick([data.id], false);
    },
  }));

  const rowActionItemsToDisplay = rowItemActionOptions != null
    ? rowItemActionOptions
      .filter((i) => (i.props.shouldDisplay == null
        ? true
        : i.props.shouldDisplay(data)))
    : null;

  const hasActionsDropDown = rowActionItemsToDisplay && rowActionItemsToDisplay.length > 0;
  const hasViewMore = !compact && onViewMore;
  const hasActions = hasActionsDropDown || hasViewMore;

  return (
    <ShadowCard className={`${Styles.SearchTableRow} ${compact ? Styles.SearchTableRowCompact : ""}`} style={gridTemplateColumns}>
      {!compact && canSelect && (
        <div className={`${Styles.RowCheckbox} ${Styles.TableCell}`}>
          <CheckboxInput
            disabled={disabled}
            feature={requiredItemSelectFeature}
            value={selected}
            onChange={(newValue) => {
              onSelectedChanged(newValue);
            }}
          />
        </div>
      )}

      {tableCells}

      <div className={Styles.RowFooter} style={footerColumnSpanStyle}>
        <div className={`${Styles.RowFooterData} ${!canSelect ? Styles.NoCheckbox : ""} ${!hasActions ? Styles.NoActions : ""}`}>
          {footerHtmlItems}
        </div>
        <div className={Styles.RowFooterActions}>
          {hasActionsDropDown
          && (
            <Fragment>
              <div className={Styles.ActionsDropdownContainer}>
                <ActionsDropdown label="Available actions">
                  {
                    rowActionItemsToDisplay?.map((i) => i)
                  }
                </ActionsDropdown>
              </div>
            </Fragment>
          )}

          {
            hasActionsDropDown && hasViewMore && <Line color="secondary" className="mobile-version" />
          }

          {hasViewMore && (
            <div className={Styles.MoreButton}>
              <UtilButton
                disabled={onViewMoreDisabled}
                location="bottom"
                color="primary"
                onClick={moreDetailsClickHandler}
                size="small"
                feature={requiredViewMoreFeature}
              >
                {moreButtonText}
              </UtilButton>
            </div>
          )}
        </div>
      </div>
    </ShadowCard>
  );
};
export default TableRowItem;
