import { useLocation, useHistory } from "react-router-dom";
import { useRef, useCallback } from "react";

const changeQue: Array<{ shortName: string, jsonString: string | null }> = [];
const forceQue: { [key: string]: boolean | undefined } = {};
let newSearchParam: URLSearchParams | null = null;

const useRouteState = <T extends {}>(shortName: string, defaultValue: T)
  : [T, (newValue: T, config?: { forceUpdate: boolean }) => void, () => void] => {
  const currentValue = useRef<T>();
  const currentStringValue = useRef<string | null>(null);

  const location = useLocation();

  const history = useHistory();

  const params = new URLSearchParams(location.search);

  const stringValue = params.get(shortName);
  const value: T = stringValue == null ? defaultValue : JSON.parse(stringValue);

  const forceLoad = forceQue[shortName];
  // clear force que
  forceQue[shortName] = undefined;

  if (forceLoad || currentValue.current == null || currentStringValue.current !== stringValue) {
    currentValue.current = value;
    currentStringValue.current = stringValue;
  }

  const processQue = useCallback(() => {
    // now handle que
    if (changeQue.length > 0) {
      const toApply = changeQue.pop();
      if (toApply != null) {
        if (toApply.jsonString == null) {
          newSearchParam?.delete(toApply.shortName);
        } else {
          newSearchParam?.set(toApply.shortName, toApply.jsonString);
        }
      }
    }

    if (changeQue.length === 0) {
      // last call changes the route
      history.push({
        pathname: history.location.pathname,
        search: newSearchParam?.toString(),
      });
    }
  }, [history]);

  const setFunction = useCallback(async (
    newValue: T,
    { forceUpdate }: { forceUpdate?: boolean } = {}
  ) => {
    newSearchParam = new URLSearchParams(history.location.search);

    const jsonString = JSON.stringify(newValue);
    changeQue.push({ shortName, jsonString });
    if (forceUpdate) {
      forceQue[shortName] = true;
    }

    // break thread
    // this is to allow multiple calls to update at the same time
    // everything before this will be called, and then everything after
    await new Promise<void>((r) => r());

    processQue();
  }, [shortName, history, processQue]);

  const clearFunction = useCallback(async () => {
    newSearchParam = new URLSearchParams(history.location.search);
    changeQue.push({ shortName, jsonString: null });

    // break thread
    // this is to allow multiple calls to update at the same time
    // everything before this will be called, and then everything after
    await new Promise<void>((r) => r());

    processQue();
  }, [shortName, history, processQue]);

  return [currentValue.current, setFunction, clearFunction];
};

export default useRouteState;
