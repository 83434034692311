export const getStaticValue = (
  value: number,
  options: { value: number, display: string }[] = [],
  defaultValue: string | null = null
) => {
  const referencedOption = options.find((v) => v.value === value);
  const nullValue = defaultValue ?? `${value}`;
  return referencedOption == null ? nullValue : referencedOption.display;
};

export const formatDate = (date: Date | string | number) => {
  const dateObject = new Date(date);
  return `${dateObject.getDate()} ${dateObject.toLocaleString("default", {
    month: "short",
  })} ${dateObject.getFullYear()}`;
};

export const formatDateTime = (date: Date | string | number) => {
  const dateObject = new Date(date);
  return `${dateObject.getDate()} ${dateObject.toLocaleString("default", {
    month: "short",
  })} ${dateObject.getFullYear()} 
  ${dateObject.toLocaleTimeString().replace(/(.*)\D\d+/, "$1")}`;
  // return "dd mmm yyyy hh:mm AM/PM" e.g. 12 Aug 2019 12:00AM
};

export const formatCurrency = (amount: number) => {
  const stringValue = amount.toFixed(2);
  const withThousandSeparator = stringValue.replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );

  return `R${withThousandSeparator}`;
};

export const formatPercentage = (value: number) => `${value} %`;

export const dateTimeLabelFormat = (date: Date | string | number) => {
  // returns "mmm dd - hh:mm:pp" e.g: Apr 02 - 12:05pm
  const dateObject = new Date(date);
  const monthShortString = dateObject.toLocaleDateString("default", { month: "short" });
  const dayString = dateObject.getDate() < 10
    ? `0${dateObject.getDate().toString()}`
    : dateObject.getDate().toString();
  const hh = dateObject.getHours();
  const hhString = hh > 12 ? (hh - 12).toString() : hh.toString();
  const mm = dateObject.getMinutes();
  const mmString = mm < 10 ? `0${mm.toString()}` : mm.toString();
  const timePeriod = hh > 12 ? "pm" : "am";
  const datetimeFormat = `${monthShortString} ${dayString} - ${hhString}:${mmString} ${timePeriod}`;

  return (datetimeFormat);
};

export const dateTimeDayMonthLabelFormat = (date: Date | string | number) => {
  // returns "mmm dd" e.g: 02 Apr
  const dateObject = new Date(date);
  const monthShortString = dateObject.toLocaleDateString("default", { month: "short" });
  const dayString = dateObject.getDate() < 10
    ? `0${dateObject.getDate().toString()}`
    : dateObject.getDate().toString();
  const datetimeFormat = `${dayString} ${monthShortString}`;

  return (datetimeFormat);
};

export const formatTelephoneNumber = (value: string) => {
  const number = [];
  const v = value.replace(/\D/g, "");
  for (let i = 0; i < v.length; i += 1) {
    if (i > 0 && i % (i <= 6 ? 3 : 5) === 0) {
      number.push(" ");
    }
    number.push(v[i]);
  }
  return number.join("");
};
