import React, {
  Fragment, useRef, useState,
} from "react";
import {
  PageHeading,
  PageContentWithButtons,
  Page,
  Tabs,
  Tab,
  SystemFeatures,
  useToast,
} from "isuppli-react-components";
import CompanyInformationTab, {
  // eslint-disable-next-line no-unused-vars
  CompanyInformationTabRef,
} from "./CompanyInformationTab/CompanyInformationTab";
import ComplianceTab from "./ComplianceTab/ComplianceTab";
import ManageUsersTab from "./ManageUsersTab/ManageUsersTab";
import OnboardingTab, {
  // eslint-disable-next-line no-unused-vars
  OnboardingTabRef,
} from "./OnboardingTab/OnboardingTab";
import navMenuItems from "../../../Util/menuItems";

const Configuration = () => {
  const onboardingTabRef = useRef<OnboardingTabRef>(null);
  const companyInformationTabRef = useRef<CompanyInformationTabRef>(null);

  const [currentTab, setCurrentTab] = useState<string>("");
  const showToast = useToast();

  const onSave = async () => {
    // trigger save in the given tab contents
    if (currentTab === "onboarding") {
      await onboardingTabRef.current?.save();
      showToast("Onboarding configuration saved");
    }
    if (currentTab === "company-information") {
      await companyInformationTabRef.current?.save();
      showToast("Company information saved");
    }
  };
  return (
    <Fragment>
      <Page noPadding>
        <PageHeading
          heading="Self Configuration"
          alertText="Configure B1 LINK to support and enhance your business processes."
          menuItems={navMenuItems}
        />
      </Page>
      <PageContentWithButtons
        hasBack={false}
        buttons={[{
          text: "Save",
          onClick: onSave,
          color: "primary",
        }]}
      >
        <Tabs onTabChange={setCurrentTab}>
          <Tab
            slug="company-information"
            name="Company Information"
            feature={SystemFeatures.ConfigurationCompanyInfo}
          >
            <CompanyInformationTab ref={companyInformationTabRef} />
          </Tab>
          <Tab
            slug="onboarding"
            name="Onboarding"
            feature={SystemFeatures.ConfigurationOnboarding}
          >
            <OnboardingTab ref={onboardingTabRef} />
          </Tab>
          <Tab
            slug="manage-users"
            name="Manage Users"
            feature={SystemFeatures.ConfigurationUsers}
          >
            <ManageUsersTab />
          </Tab>
          <Tab
            slug="compliance"
            name="Compliance"
            feature={SystemFeatures.ConfigurationCompliance}
          >
            <ComplianceTab />
          </Tab>
        </Tabs>
      </PageContentWithButtons>
    </Fragment>
  );
};

export default Configuration;
