import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Alert, Progress } from "reactstrap";
import { ModalPopup } from "isuppli-react-components";
import {
  ReduxState, // eslint-disable-line no-unused-vars
} from "../../../Store/ReduxState";

import Styles from "./ComposeMessageModal.module.scss";
import {
  broadcastMessage,
  BroadcastMessage, // eslint-disable-line no-unused-vars
  CompanySearchCriteria, // eslint-disable-line no-unused-vars
  DraftMessage, // eslint-disable-line no-unused-vars
} from "../../../http/Messaging/messagesApi";
import Spinner from "../../Spinner/Spinner";
import MessageComposer from "../../../Sections/Messaging/MessageComposer/MessageComposer";
import useSignalR, { SignalRNotifications } from "../../../Hooks/useSignalR";

export enum SendTo {
  searchQuery = "searchQuery", // eslint-disable-line no-unused-vars
  single = "single", // eslint-disable-line no-unused-vars
  multiple = "multiple", // eslint-disable-line no-unused-vars
}

const ComposeMessageModal = ({
  sendTo,
  recipientDetails,
  // search query will be used to send message to all search results
  searchQuery,
  searchQueryResultCount,
  onClose,
} :
{
  sendTo : SendTo,
  recipientDetails?: {name: string, id: number}[] | undefined,
  searchQuery?: CompanySearchCriteria,
  searchQueryResultCount?: number,
  onClose: () => void,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [forceUpdate, setForceUpdate] = useState<() => Promise<void>>();
  const [draftMessageId, setDraftMessageId] = useState<number>();
  const [isSendDisabled, setIsSendDisabled] = useState<boolean>(true);
  const [showSendingProgress, setShowSendingProgress] = useState<boolean>(false);
  const [sendingProgress, setSendingProgress] = useState<number>(0);
  const [sendingTotal, setSendingTotal] = useState<number | null>(null);

  const userBasicDetails = useSelector((state: ReduxState) => state.loggedInUserDetails);

  // listen for bulk message progress
  const disconnect = useSignalR<{progress: number, totalMessages: number}>(
    SignalRNotifications.BulkMessageProgress,
    async (data) => {
      if (showSendingProgress) {
        setSendingProgress(data.progress);
        if (data.progress >= 1) {
          onClose();
        }
        if (data.totalMessages > 0) {
          setSendingTotal(data.totalMessages);
        }
      }
    }
  );

  // cleanup signalR listener when disposing
  useEffect(() => () => {
    disconnect();
  }, [disconnect]);

  const onDraftChangedHandler = useCallback((newDraft: DraftMessage) => {
    setShowSendingProgress(newDraft.sending);
  }, [setShowSendingProgress]);

  const onSendHandler = async () => {
    if (draftMessageId == null) {
      onClose();
      return;
    }
    setIsLoading(true);

    if (forceUpdate != null) {
      await forceUpdate();
    }

    setHasError(false);

    // send message to API
    const model: BroadcastMessage = {
      myCompany: {
        registeredName: userBasicDetails.companyName,
        companyId: userBasicDetails.companyId,
      },
    };

    switch (sendTo) {
      case SendTo.single:
      case SendTo.multiple:
        model.recipientCompanies = recipientDetails?.map((c) => ({
          registeredName: c.name,
          companyId: c.id,
        }));
        break;
      case SendTo.searchQuery:
        model.searchCriteria = searchQuery;
        break;
      default:
    }

    try {
      await broadcastMessage(0, draftMessageId, model);
      setShowSendingProgress(true);
      setIsLoading(false);
    } catch (error) {
      setHasError(true);
      setIsLoading(false);
    }
  };

  const getForceUpdateHandler = useCallback((handler: () => Promise<void>) => {
    setForceUpdate(() => handler);
  }, [setForceUpdate]);

  let subheading = "";
  switch (sendTo) {
    case SendTo.single:
      subheading = `Sending message to ${recipientDetails?.[0]?.name}`;
      break;
    case SendTo.multiple:
      subheading = `Sending message to ${recipientDetails?.length} recipients`;
      break;
    case SendTo.searchQuery:
      subheading = `Sending message to ${searchQueryResultCount} recipients`;
      break;
    default:
      subheading = "";
  }

  if (showSendingProgress) {
    // when sending bulk, override the heading to prevent incorrect data when opening the
    // modal later
    subheading = sendingTotal == null
      ? "Message broadcast in progress."
      : `Message broadcast to ${sendingTotal} recipients in progress.`;
  }

  return (
    <ModalPopup
      buttons={[{
        key: "Send",
        label: "Send",
        color: "primary",
        onClick: onSendHandler,
        disabled: showSendingProgress || isSendDisabled,
      }]}
      heading="New Message"
      subheading={subheading}
      onClose={onClose}
      hasCancel
      cancelButtonText="Close"
    >
      <div className="row px-2">
        {hasError
          ? (
            <div className="col-12">
              <Alert fade={false} color="danger">
                Could not send message. Please try again or contact your system administrator
              </Alert>
            </div>
          )
          : null}
        <div className={` ${Styles.MessageComposer} col-12`}>
          <div className="w-100 h-100 position-relative">
            {isLoading
              ? <Spinner inline />
              : null}
            {!isLoading && !showSendingProgress
              ? (
                <MessageComposer
                  onMessageIdChange={setDraftMessageId}
                  channelId={0}
                  alwaysOpen
                  hideSendButton
                  getForceUpdate={getForceUpdateHandler}
                  onDraftChanged={onDraftChangedHandler}
                  onDisableSendChanged={(value) => {
                    setIsSendDisabled(value);
                  }}
                />
              )
              : null}
            {
              showSendingProgress
                ? (
                  <div className="h-100 d-flex flex-column justify-content-center">
                    <Progress
                      color="primary"
                      max={1}
                      // while 0, assume it's busy processing and show indeterminate progress bar
                      value={sendingProgress <= 0 ? 100 : sendingProgress}
                      animated={sendingProgress <= 0}
                    >
                      {sendingProgress <= 0
                        ? "Preparing messages..."
                        : `${Math.floor(sendingProgress * 100)}%`}
                    </Progress>
                    <p className="mt-3 text-center">Sending messages</p>
                  </div>
                )
                : null
            }
          </div>
        </div>
      </div>
    </ModalPopup>
  );
};

export default ComposeMessageModal;
