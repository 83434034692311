import React from "react";
import { ProgressIndicator, PageHeading } from "isuppli-react-components";

import navMenuItems from "../../Util/menuItems";

function MTPProgress({
  currentStep,
  lastStepCompleted,
  steps,
  onClick,
}) {
  return (
    <section id="isuppli-motivationtopurchase-progress" className="bg-light">
      <div className="padding-lr-10vw">
        <PageHeading
          heading="Motivation to Onboard"
          alertText="Please complete the Motivation to Onboard Process."
          menuItems={navMenuItems}
          breadcrumbAdditionalItem="Motivation to Onboard"
        />

        <ProgressIndicator
          currentStep={currentStep}
          stepsCompleted={lastStepCompleted}
          onClick={onClick}
          steps={steps}
        />
      </div>
    </section>
  );
}

export default MTPProgress;
