export const PUSH_BREAD_CRUMB = "PUSH_BREAD_CRUMB";

export function pushBreadCrumb(newBreadCrumb) {
  return {
    action: PUSH_BREAD_CRUMB,
    payload: {
      newBreadCrumb,
    },
  };
}
