import React, { Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import SystemFeatures from "../../utils/enums/SystemFeatures";
import { useShouldShowNotAllowedError, useHideNotAllowedError, setupSubscriptionFeaturesInfo } from "../../Hooks/useNotAllowedError";
import ConfirmationModal from "../../Components/ConfirmationModal/ConfirmationModal";
import { setupAllowedFeatures, useSystemFeatureCheck } from "../../Hooks/useSystemFeatureCheck";
// eslint-disable-next-line no-unused-vars
import { SubscriptionAllowedFeatures } from "../../utils/interfaces/SubscriptionAllowedFeatures";

const ISuppliAuthentication = (
  {
    allowedFeatures,
    subscriptionEditUrl,
    subscriptionFeaturesInfo,
    children,
  }
  :
  {
    allowedFeatures: SystemFeatures,
    subscriptionEditUrl?: string,
    children: React.ReactNode,
    subscriptionFeaturesInfo: Array<SubscriptionAllowedFeatures>,
  }
) => {
  const featureCheck = useSystemFeatureCheck();
  const shouldShowError = useShouldShowNotAllowedError();
  const hideError = useHideNotAllowedError();
  const history = useHistory();

  setupSubscriptionFeaturesInfo(subscriptionFeaturesInfo);
  setupAllowedFeatures(allowedFeatures);
  const canEditSubscription = featureCheck(SystemFeatures.SubscriptionsEdit);

  useEffect(() => {
  }, [shouldShowError, children]);

  const minimumRequiredSubscriptionText = shouldShowError.minimumRequiredSubscription != null
    ? `You will need to upgrade your subscription package to the 
    ${shouldShowError.minimumRequiredSubscription?.subscriptionTierName} tier in order to access this feature.`
    : "";
  return (
    <Fragment>
      {children}
      {shouldShowError.currentValue
      && canEditSubscription
      && (
        <ConfirmationModal
          heading="Not Allowed"
          description={`Your current subscription does not allow this feature.
          ${minimumRequiredSubscriptionText} Review your subscription for more details.`}
          hasCancel
          onProceedClick={() => {
            if (subscriptionEditUrl != null) {
              history.push(subscriptionEditUrl);
            }
          }}
          proceedButtonText={subscriptionEditUrl == null ? "OK" : "View subscription"}
          onToggleModal={hideError}
        />
      )}
      {shouldShowError.currentValue
      && !canEditSubscription
      && (
        <ConfirmationModal
          heading="Not Allowed"
          description="You do not have access to this feature. Contact your system administrator to change your access level."
          hasCancel={false}
          onProceedClick={() => {
          }}
          proceedButtonText="OK"
          onToggleModal={hideError}
        />
      )}
    </Fragment>
  );
};

export default ISuppliAuthentication;
