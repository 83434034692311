import staticDataTypes from "./Enumerators/staticDataTypes";

import {
  getOptions,
  getOptionsAsync,
  getSupplierCategories,
} from "../http/gets";

const subscriptionTierTransform = (subscriptionTierDataItem) => ({
  ...subscriptionTierDataItem,

  // for sub options display
  title: subscriptionTierDataItem.name,
  msg: "",
  features: subscriptionTierDataItem.features.map((feature) => feature.name),
  monthlyPriceInclVAT: subscriptionTierDataItem.monthlyPriceInclVAT,

  // for drop downs
  value: subscriptionTierDataItem.id,
  display: subscriptionTierDataItem.name,
});

export async function loadSupplierInfoOptions(props) {
  const filters = [];
  if (props.companyTypes.length === 0) {
    filters.push(staticDataTypes.CompanyType);
  }

  if (props.businessTypes == null || props.businessTypes.length === 0) {
    filters.push(staticDataTypes.BusinessTypes);
  }

  if (filters.length > 0) {
    const options = await getOptionsAsync(filters);
    if (props.companyTypes.length === 0) {
      const companyTypes = options.companyTypeData.map((type) => ({
        ...type,
        value: type.id,
        display: type.name,
      }));
      props.setOption("companyTypes", companyTypes);
    }

    if (props.businessTypes == null || props.businessTypes.length === 0) {
      const businessTypes = options.businessTypeData?.map((type) => ({
        ...type,
        value: type.id,
        display: type.name,
      }));
      props.setOption("businessTypes", businessTypes);
    }
  }
}

export function loadAddressContactOptions(props) {
  const filters = [];
  if (props.provinceOptions.length === 0) {
    filters.push(staticDataTypes.Province);
  }
  if (props.countryOptions.length === 0) {
    filters.push(staticDataTypes.Country);
  }
  if (filters.length > 0) {
    getOptions(filters, (options) => {
      if (props.provinceOptions.length === 0) {
        const provinces = options.provinceData.map((option) => ({
          ...option,
          value: option.id,
          display: option.name,
        }));
        props.setOption("provinces", provinces);
      }
      if (props.countryOptions.length === 0) {
        const countries = options.countryData.map((option) => ({
          ...option,
          value: option.id,
          display: option.name,
        }));
        props.setOption("countries", countries);
      }
    });
  }
}

export function loadBeePracticeOptions(props) {
  const filters = [];
  if (props.beeLvls.length === 0) {
    filters.push(staticDataTypes.BeeStatus);
  }
  if (props.beeSectors.length === 0) {
    filters.push(staticDataTypes.BeeClassification);
  }
  if (props.documentTypes.length === 0) {
    filters.push(staticDataTypes.DocumentTypes);
  }
  if (props.sectors.length === 0) {
    filters.push(staticDataTypes.Sector);
  }
  if (props.turnoverBands.length === 0) {
    filters.push(staticDataTypes.TurnoverBand);
    filters.push(staticDataTypes.SectorTurnoverBand);
    filters.push(staticDataTypes.SectorTurnoverBandStatus);
  }

  if (filters.length > 0) {
    getOptions(filters, (options) => {
      if (props.beeLvls.length === 0) {
        props.setOption(
          "beeLvls",
          options.beeStatusData.map((entry) => ({
            ...entry,
            value: entry.id,
            display: entry.name,
          }))
        );
      }
      if (props.beeSectors.length === 0) {
        props.setOption(
          "beeSectors",
          options.beeClassificationData.map((entry) => ({
            ...entry,
            value: entry.id,
            display: entry.name,
          }))
        );
      }

      if (props.documentTypes.length === 0) {
        props.setOption(
          "documentTypes",
          options.documentTypes.map((entry) => ({
            ...entry,
            value: entry.id,
            display: entry.name,
          }))
        );
      }

      if (props.sectors.length === 0) {
        const sectors = options.sectorData.map((sector) => ({
          ...sector,
          value: sector.id,
          display: sector.name,
        }));
        props.setOption("sectors", sectors);
      }
      if (props.turnoverBands.length === 0) {
        const turnoverBands = options.turnoverBandData.map((turnoverBand) => ({
          ...turnoverBand,
          value: turnoverBand.id,
          display: turnoverBand.name,
          heading: (
            options.beeClassificationData.find(
              (cls) => cls.id
                === (
                  options.sectorTurnoverBandData.find(
                    (stbd) => stbd.turnoverBandId === turnoverBand.id
                  ) || {}
                ).beeClassificationId
            ) || {}
          ).name,
        }));
        props.setOption("turnoverBands", turnoverBands);
        const sectorTurnoverBands = options.sectorTurnoverBandData.map(
          (sectorTurnoverBand) => ({
            ...sectorTurnoverBand,
            value: sectorTurnoverBand.id,
            display: sectorTurnoverBand.name,
          })
        );

        props.setOption("sectorTurnoverBands", sectorTurnoverBands);
        props.setOption("sectorTurnoverBandStatuses", options.sectorTurnoverBandStatusData);
      }
    });
  }
}

export async function loadSupplierSearchDetailsOptions(props) {
  const filters = [];
  if (props.beeLevels.length === 0) {
    filters.push(staticDataTypes.BeeStatus);
  }
  if (props.provinces.length === 0) {
    filters.push(staticDataTypes.Province);
  }
  if (props.countries.length === 0) {
    filters.push(staticDataTypes.Country);
  }
  if (props.companyTypes.length === 0) {
    filters.push(staticDataTypes.CompanyType);
  }
  if (props.sectors.length === 0) {
    filters.push(staticDataTypes.Sector);
  }
  if (props.turnoverBands.length === 0) {
    filters.push(staticDataTypes.TurnoverBand);
    filters.push(staticDataTypes.SectorTurnoverBand);
    filters.push(staticDataTypes.BeeClassification);
  }
  if (filters.length > 0) {
    const options = await getOptionsAsync(filters);

    if (props.beeLevels.length === 0) {
      props.setOption(
        "beeLvls",
        options.beeStatusData.map((entry) => ({
          ...entry,
          value: entry.id,
          display: entry.name,
        }))
      );
    }
    if (props.provinces.length === 0) {
      props.setOption(
        "provinces",
        options.provinceData.map((entry) => ({
          ...entry,
          value: entry.id,
          display: entry.name,
        }))
      );
    }

    if (props.countries.length === 0) {
      const countries = options.countryData.map((option) => ({
        ...option,
        value: option.id,
        display: option.name,
      }));
      props.setOption("countries", countries);
    }

    if (props.companyTypes.length === 0) {
      const companyTypes = options.companyTypeData.map((type) => ({
        ...type,
        value: type.id,
        display: type.name,
      }));
      props.setOption("companyTypes", companyTypes);
    }

    if (props.sectors.length === 0) {
      const sectors = options.sectorData.map((sector) => ({
        ...sector,
        value: sector.id,
        display: sector.name,
      }));
      props.setOption("sectors", sectors);
    }

    if (props.turnoverBands.length === 0) {
      const turnoverBands = options.turnoverBandData.map((turnoverBand) => ({
        ...turnoverBand,
        value: turnoverBand.id,
        display: turnoverBand.name,
        heading: (
          options.beeClassificationData.find(
            (cls) => cls.id
              === (
                options.sectorTurnoverBandData.find(
                  (stbd) => stbd.turnoverBandId === turnoverBand.id
                ) || {}
              ).beeClassificationId
          ) || {}
        ).name,
      }));
      props.setOption("turnoverBands", turnoverBands);
      const sectorTurnoverBands = options.sectorTurnoverBandData.map(
        (sectorTurnoverBand) => ({
          ...sectorTurnoverBand,
          value: sectorTurnoverBand.id,
          display: sectorTurnoverBand.name,
        })
      );
      props.setOption("sectorTurnoverBands", sectorTurnoverBands);
    }

    if (props.beeSectors.length === 0) {
      props.setOption(
        "beeSectors",
        options.beeClassificationData.map((entry) => ({
          ...entry,
          value: entry.id,
          display: entry.name,
        }))
      );
    }
  }
}

export function loadProductServiceOptions(props) {
  getSupplierCategories((res) => {
    const prdInfo = {
      ...res,
    };
    props.setPrdInfo(prdInfo);
    if (props.setLastStepCompleted) {
      props.setLastStepCompleted(res.registrationStepCompleted);
    }
  });
}

export function loadBankingDetailOptions(props) {
  const filters = [];
  if (props.bankAccountTypes.length === 0) {
    filters.push(staticDataTypes.BankAccountType);
  }
  if (props.banks.length === 0) {
    filters.push(staticDataTypes.Bank);
  }
  if (filters.length > 0) {
    getOptions(filters, (res) => {
      if (props.bankAccountTypes.length === 0) {
        props.setOption(
          "bankAccountTypes",
          res.bankAccountTypeData.map((acType) => ({
            ...acType,
            value: acType.id,
            display: acType.name,
          }))
        );
      }
      if (props.banks.length === 0) {
        props.setOption(
          "banks",
          res.bankData.map((bank) => ({
            ...bank,
            value: bank.id,
            display: bank.name,
          }))
        );
      }
    });
  }
}

export async function loadAddOnboardingRequestOptions(
  companyTypes,
  setOption
) {
  const filters = [];

  if (companyTypes.length === 0) {
    filters.push(staticDataTypes.CompanyType);
  }

  if (filters.length === 0) {
    return;
  }

  const options = await getOptionsAsync(filters);

  if (options.companyTypeData != null) {
    setOption(
      "companyTypes",
      options.companyTypeData.map((entry) => ({
        ...entry,
        value: entry.id,
        display: entry.name,
      }))
    );
  }
}

export async function loadSubscriptionTiers(subscriptions, setOption) {
  if (subscriptions.length > 0) {
    return;
  }

  const options = await getOptionsAsync([staticDataTypes.SubscriptionTier]);

  if (options.subscriptionTierData != null) {
    setOption(
      "subscriptions",
      options.subscriptionTierData.map(subscriptionTierTransform)
    );
  }
}

export async function loadAuditTrailOptions() {
  // get options needed for audit trail search
}
