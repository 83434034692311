/* eslint no-nested-ternary: 0 */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  CheckboxInput,
  ConfirmationModal,
  SystemFeatures,
  WithFeature,
  MultiSelectDropDownInput,
  DropDownInput,
} from "isuppli-react-components";

import {
  UncontrolledTooltip,
} from "reactstrap";
import TextInput from "../../Controls/TextInput/TextInput";
import RadioSelector from "../../Controls/RadioSelector";
import DropdownInput from "../../Controls/DropdownInput";
import {
  isValidEmail,
  filterOutLetters,
  companyNameFilter,
  minLength,
  validateVatNumber,
  maxLength,
  getRegNumberConfig,
  onCompanyTypeChangeHandler,
} from "../../Validation/onChangeValidations";
import CompanyGroupingType from "../../Util/Enumerators/CompanyGroupingType";

import { setStaticCompanyData, showErrorDialog } from "../../Store/actions";
import {
  StaticCompanyDataTypes, // eslint-disable-line no-unused-vars
} from "../../Util/Enumerators/staticCompanyDataTypes";

import { getContractTiers, getContractAgreements } from "../../http/Contracting/contractingApi";
import StaticCompanyData from "../../Hooks/StaticCompanyData";
import CompanyConfigurationFeatures from "../../Util/Enumerators/CompanyConfigurationFeatures";

const mapStateToProps = (state) => ({
  contractTiers: state.staticCompanyData[StaticCompanyDataTypes.contractTiers],
  supplierTypes: state.staticCompanyData[StaticCompanyDataTypes.supplierType],
  paymentTerms: state.staticCompanyData[StaticCompanyDataTypes.paymentTerms],
  allowedCompanyFeatures: state.companyConfiguration.allowedCompanyFeatures,
  currentUserEmail: state.loggedInUserDetails.email,
});

const mapDispatchToProps = (dispatch) => ({
  setContractTiers: (contractTiers) => {
    dispatch(setStaticCompanyData([{
      type: StaticCompanyDataTypes.contractTiers,
      value: contractTiers,
    }], true));
  },
  setPaymentTerms: (paymentTerms) => {
    dispatch(setStaticCompanyData([{
      type: StaticCompanyDataTypes.paymentTerms,
      value: paymentTerms,
    }], true));
  },
  showErrorDialog: () => {
    dispatch(showErrorDialog(true));
  },
});

class CompanyDetails extends Component {
  resetVatNumber = (isVatRegistered) => {
    if (isVatRegistered) {
      const event = {
        target: {
          name: "isVatRegistered",
          value: "",
        },
      };
      this.props.onInputChangeCallback(event);
    }
  };

  // eslint-disable-next-line no-bitwise
  hasContractingFeature = (this.props.allowedCompanyFeatures
    & CompanyConfigurationFeatures.Contracting > 0);

  onSupplierTypeChange = () => {
    // reset contracting tier when supplier type is changed
    this.props.onInputChangeCallback({
      target: {
        name: "contractingTierId",
        value: null,
      },
    });

    // clear contract agreement options
    this.props.onInputChangeCallback({
      target: {
        name: "contractAgreements",
        value: [],
      },
    });
  }

  setContractingApplies = (selectedContractAgreements) => {
    if (selectedContractAgreements.length > 0) {
      this.props.onInputChangeCallback({
        target: {
          name: "contractingApplies",
          value: true,
        },
      });
    } else {
      this.props.onInputChangeCallback({
        target: {
          name: "contractingApplies",
          value: false,
        },
      });
    }
  }

  onContractTierChange = async (selectedContractTierId) => {
    // clear contract agreement options
    this.props.onInputChangeCallback({
      target: {
        name: "contractAgreements",
        value: [],
      },
    });

    // set selected contract tier
    const contractTier = this.props.contractTiers?.filter(
      (c) => c.id === selectedContractTierId
    )[0];
    this.props.onInputChangeCallback({
      target: {
        name: "externalContractTierId",
        value: contractTier?.externalContractTierId,
      },
    });

    // load and set contract agreement options
    try {
      if (!this.props.data.manualSupplier) { // contracting doesnt apply for manual supplier
        const contractAgreements = await this.loadContractAgreements(selectedContractTierId);

        // select all contracts by default
        const allContractAgreementsSelected = contractAgreements.map((c) => c.id);
        this.props.onInputChangeCallback({
          target: {
            name: "contractAgreements",
            value: allContractAgreementsSelected,
          },
        });

        // toggle contracting applies based on selected contract agreements
        this.setContractingApplies(allContractAgreementsSelected);
      } else {
        this.setContractingApplies(false);
      }
    } catch { this.props.showErrorDialog(); }
  }

  onPaymentTermsChange = (paymentTermsId) => {
    this.props.onInputChangeCallback({
      target: {
        name: "paymentTermsId",
        value: paymentTermsId,
      },
    });
  }

  onContractAgreementsChange = (selectedContractAgreements) => {
    this.props.onInputChangeCallback({
      target: {
        name: "contractAgreements",
        value: selectedContractAgreements,
      },
    });

    // toggle contracting applies based on selected contract agreements
    this.setContractingApplies(selectedContractAgreements);
  }

  loadContractAgreements = async (contractTierId) => {
    if (contractTierId > 0) {
      try {
        const contractAgreements = await getContractAgreements(contractTierId);
        const formattedContractAgreements = contractAgreements.map(
          (c) => ({ ...c, value: c.id, display: c.agreementName })
        );
        this.setState(({ contractAgreementsOptions: formattedContractAgreements }));
        return formattedContractAgreements;
      } catch {
        this.props.showErrorDialog();
        this.setState(({ contractAgreementsOptions: [] }));
        return [];
      }
    }
    return [];
  };

  onCategoryManagerChange = (userId) => {
    if (this.props.data.categoryManagerUserId !== this.props.categoryManagerInitiator
      && (this.props.data.includeInitiator ?? true)) {
      this.onIncludeInitiatorChange(true);
    }
    this.props.onInputChangeCallback({
      target: {
        name: "categoryManagerUserId",
        value: userId,
      },
    });
  };

  componentDidMount() {
    const loadContractTiers = async () => {
      // load contracting tiers
      if (this.props.contractTiers == null || this.props.contractTiers.length === 0) {
        try {
          const contractTiers = await getContractTiers();
          const formattedContractTiers = contractTiers.map(
            (c) => ({ ...c, value: c.id, display: c.name })
          );
          this.props.setContractTiers(formattedContractTiers);
        } catch {
          this.props.showErrorDialog();
          this.props.setContractTiers([]);
        }
      }
    };
    // eslint-disable-next-line no-bitwise
    if (this.hasContractingFeature) {
      loadContractTiers();
    }

    const useProps = this.props.data.lastStep === 1;

    let isVatRegistered = 0;
    if (useProps) {
      isVatRegistered = this.props.data.isVatRegistered;
    } else if (!!this.props.data.vatNumber && this.props.data.vatNumber.length > 0) {
      isVatRegistered = 1;
    }

    // load contract agreements if contractTier has been set/saved
    const loadContractAgreementList = async () => {
      const contractTier = this.props.data.contractingTierId;
      if (contractTier > 0) {
        await this.loadContractAgreements(contractTier);
      }
    };
    loadContractAgreementList();

    this.props.onInputChangeCallback({
      target: {
        name: "isVatRegistered",
        value: isVatRegistered,
      },
    });

    this.props.onInputChangeCallback({
      target: {
        name: "contractingApplies",
        value: this.props.data.contractingApplies,
      },
    });

    this.props.onInputChangeCallback({
      target: {
        name: "externalContractTierId",
        value: this.props.data.externalContractTierId,
      },
    });

    this.props.onInputChangeCallback({
      target: {
        name: "contractAgreements",
        value: this.props.data.contractAgreements,
      },
    });

    this.props.onInputChangeCallback({
      target: {
        name: "manualSupplier",
        value: this.props.data.manualSupplier || false,
      },
    });

    if (this.props.supplierTypes && this.props.supplierTypes.length <= 0) {
      this.props.onInputChangeCallback({
        target: {
          name: "supplierTypeId",
          value: null,
        },
      });
    }

    this.props.onInputChangeCallback({
      target: {
        name: "paymentTermsId",
        value: this.props.data.paymentTermsId,
      },
    });
  }

  onIncludeInitiatorChange = (includeInitiator) => {
    if (includeInitiator) {
      this.props.onInputChangeCallback({
        target: {
          name: "categoryManagerInitiatorUserId",
          value: this.props.categoryManagerInitiator,
        },
      });
    } else {
      this.props.onInputChangeCallback({
        target: {
          name: "categoryManagerInitiatorUserId",
          value: null,
        },
      });
    }
    this.props.onInputChangeCallback({
      target: {
        name: "includeInitiatorInCommunications",
        value: includeInitiator,
      },
    });
  }

  onSponsorChange = (isSelected) => {
    this.props.onInputChangeCallback({
      target: {
        name: "sponsored",
        value: isSelected,
      },
    });
    // deselect manual supplier if sponsored is selected
    if (isSelected === true) {
      this.props.onInputChangeCallback({
        target: {
          name: "manualSupplier",
          value: false,
        },
      });
    }
  }

  // Handle the fundCompliance tickbox selection event:
  onFundComplianceChange = (isSelected) => {
    this.props.onInputChangeCallback({
      target: {
        name: "fundCompliance",
        value: isSelected,
      },
    });

    // Deselect manual supplier if compliance funding is selected
    if (isSelected === true) {
      this.props.onInputChangeCallback({
        target: {
          name: "manualSupplier",
          value: false,
        },
      });
    }
  }

  onWorkManCompChange = (isSelected) => {
    this.props.onInputChangeCallback({
      target: {
        name: "workMan",
        value: isSelected,
      },
    });
  }

  handleShowManualSupplierModal = (showModal) => {
    this.setState({ showManualSupplierConfirmationModal: showModal });
  };

  onManualSupplierChange = (isSelected) => {
    if (isSelected) {
      this.handleShowManualSupplierModal(true);
    } else {
      this.setManualSupplier(false);
    }
  }

  setManualSupplier = (isSelected) => {
    this.props.onInputChangeCallback({
      target: {
        name: "manualSupplier",
        value: isSelected,
      },
    });
    if (isSelected) {
      this.props.onInputChangeCallback({
        target: {
          name: "sponsored",
          value: false,
        },
      });
      this.props.onInputChangeCallback({
        target: {
          name: "fundCompliance",
          value: false,
        },
      });
      this.props.onInputChangeCallback({
        target: {
          name: "fundCompliance",
          value: false,
        },
      });
      this.props.onInputChangeCallback({
        target: {
          name: "contractingApplies",
          value: false,
        },
      });
      this.props.onInputChangeCallback({
        target: {
          name: "workManComp",
          value: false,
        },
      });
    }
  }

  render() {
    let disableFields = false;
    let disableCheckbox = false;
    if (this.props.data.supplierCompanyId != null) {
      disableCheckbox = true;
      // disable fields if not manual supplier
      if (!this.props.data.manualSupplier) { disableFields = true; }
    }

    const currentCompanyType = this.props.companyTypes.filter(
      (c) => c.id === this.props.data.companyTypeId
    )[0];

    const selectedCompanyTypeName = currentCompanyType?.name;
    const isForeignCompany = currentCompanyType?.companyGroupingType === CompanyGroupingType.Foreign;
    const regNumberConfig = getRegNumberConfig(selectedCompanyTypeName, this.props.setStepValid);

    return (
      <section className="padding-lr-10vw">
        {this.state && this.state.showManualSupplierConfirmationModal
          && (
            <ConfirmationModal
              heading="Manual supplier registration"
              onProceedClick={() => { this.setManualSupplier(true); }}
              onToggleModal={() => { this.handleShowManualSupplierModal(false); }}
              proceedButtonText="OK"
              description="You are about to create a 'Manual Supplier' record.
                        This company record will be visible only to your company users
                        in the Directory, and the company will not have access to maintain
                        their profile. The record will also bypass any contracting workflows
                        which may apply. Do you wish to Proceed?"
            />
          )}
        <StaticCompanyData
          optionsToLoad={[StaticCompanyDataTypes.supplierType]}
        />
        <WithFeature feature={SystemFeatures.MTOSupplierSponsorship}>
          <div className="row">
            <div className="col-12 col-sm-5 pb-3">
              <CheckboxInput
                label="Sponsor For iSupply Tier?"
                name="sponsored"
                value={this.props.data.sponsored}
                onChange={(selected) => this.onSponsorChange(selected)}
              />
            </div>
          </div>
        </WithFeature>
        <WithFeature feature={SystemFeatures.SupplierComplianceFunding}>
          <div className="row">
            <div className="col-12 col-sm-5 pb-3">
              <div className="d-flex justify-content-start">
                <span id="cbFundCompliance">
                  <CheckboxInput
                    label="Fund Compliance?"
                    name="fundCompliance"
                    disabled={this.props.data.alreadySponsored}
                    value={this.props.data.fundCompliance}
                    onChange={(selected) => this.onFundComplianceChange(selected)}
                  />
                </span>
                <span>
                  {
                    this.props.data.alreadySponsored
                    && (
                      <UncontrolledTooltip placement="right" target="cbFundCompliance">
                        This supplier is already sponsored for compliance
                      </UncontrolledTooltip>
                    )
                  }
                </span>
              </div>
            </div>
          </div>
        </WithFeature>
        <WithFeature feature={SystemFeatures.SupplierComplianceFunding}>
          <div className="row">
            <div className="col-12 col-sm-5 pb-3">
              <div className="d-flex justify-content-start">
                <span id="cbWorkManComp">
                  <CheckboxInput
                    label="Request Workmen's Compensation Document"
                    name="workManComp"
                    disabled={this.props.data.manualSupplier}
                    value={this.props.data.workManComp}
                    onChange={(selected) => this.onWorkManCompChange(selected)}
                  />
                </span>
              </div>
            </div>
          </div>
        </WithFeature>
        <WithFeature feature={SystemFeatures.ManualSuppliers}>
          <div className="row">
            <div id="cbManualSupplier" className="col-12 col-sm-5 pb-3">
              <CheckboxInput
                label="Manual supplier"
                name="manualSupplier"
                disabled={disableCheckbox}
                value={this.props.data.manualSupplier}
                onChange={(selected) => this.onManualSupplierChange(selected)}
              />
            </div>
            <span>
              {
                this.props.data.alreadySponsored
                && (
                  <UncontrolledTooltip placement="right" target="cbManualSupplier">
                    Cannot create as manual record because the company already exists.
                  </UncontrolledTooltip>
                )
              }
            </span>
          </div>
        </WithFeature>
        <div className="row">
          <div className="col-12 col-sm-5">
            <TextInput
              disabled={disableFields}
              label="Registered Company Name"
              name="registeredName"
              value={this.props.data.registeredName}
              iconName="buildings"
              maxLength={100}
              additional={this.props.additional.registeredName}
              onChangeCallback={this.props.onInputChangeCallback}
              filters={[companyNameFilter]}
            />
          </div>

          <div className="col-12 col-sm-2" />

          <div className="col-12 col-sm-5">
            <DropdownInput
              disabled={disableFields}
              label="Company Type"
              name="companyTypeId"
              value={this.props.data ? this.props.data.companyTypeId || -1 : -1}
              options={this.props.companyTypes}
              additional={this.props.data.manualSupplier === true ? "" : this.props.additional.companyTypeId}
              onChangeCallback={(ev) => {
                this.props.onInputChangeCallback(ev);
                onCompanyTypeChangeHandler(
                  ev,
                  this.props.companyTypes,
                  this.props.onInputChangeCallback
                );
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-sm-5">
            <TextInput
              disabled={disableFields}
              label="Trading As Name"
              name="tradingName"
              maxLength={100}
              value={this.props.data.tradingName}
              iconName="buildings"
              additional={this.props.additional.tradingName}
              onChangeCallback={this.props.onInputChangeCallback}
            />
          </div>

          <div className="col-12 col-sm-2" />

          <div className="col-12 col-sm-5">
            <TextInput
              disabled={disableFields}
              label={regNumberConfig.idNumValidation
                ? "South African ID Number"
                : "Company Registration Number"}
              placeholder={regNumberConfig.regNumberPlaceholder}
              maxLength={regNumberConfig.maxLengthValue}
              name="registrationNumber"
              value={this.props.data.registrationNumber}
              iconName="buildings"
              additional={this.props.data.manualSupplier === true ? "" : this.props.additional.registrationNumber}
              filters={regNumberConfig.regNumberFilterConfig}
              validations={regNumberConfig.regNumberValidationsConfig}
              onChangeCallback={this.props.onInputChangeCallback}
            />
          </div>
        </div>

        <div className="row">
          {this.props.businessUnits.length > 0
            ? (
              <Fragment>
                <div className="col-12 col-sm-5">
                  <MultiSelectDropDownInput
                    label="Business Unit"
                    value={this.props.data.businessUnitIds || []}
                    options={this.props.businessUnits}
                    validationMessage={this.props.additional.businessUnitIds}
                    onChange={(selectedItems) => {
                      const newEvent = {
                        target: {
                          name: "businessUnitIds",
                          value: selectedItems,
                        },
                      };
                      this.props.onInputChangeCallback(newEvent);
                    }}
                  />
                </div>
                <div className="col-12 col-sm-2" />
              </Fragment>
            )
            : null}
          {
            this.props.data.manualSupplier !== true && (
              <div className="col-12 col-sm-5">
                <TextInput
                  label="Email Address"
                  name="generalEmail"
                  value={this.props.data.generalEmail}
                  iconName="open-envelope"
                  validations={this.props.data.manualSupplier === true
                    ? []
                    : [isValidEmail(this.props.setStepValid)]}
                  additional={this.props.data.manualSupplier === true ? "" : this.props.additional.generalEmail}
                  onChangeCallback={this.props.onInputChangeCallback}
                />
              </div>
            )
          }
        </div>

        <div className="row">
          {
            this.props.supplierCategoryManagers?.length > 0 && (
              <Fragment>
                <div className="col-12 col-sm-5">
                  <DropDownInput
                    label="Category Manager"
                    name="categoryManager"
                    value={(this.props.data.categoryManagerUserId)}
                    options={this.props.supplierCategoryManagers.map((val) => ({
                      value: val.userId,
                      display: val.fullName,
                    }))}
                    onChange={(val) => { this.onCategoryManagerChange(val); }}
                    required
                  />
                </div>
                <div className="col-12 col-sm-2" />
              </Fragment>
            )
          }
        </div>

        <div className="row mt-4">
          {
            ((this.props.categoryManagerInitiator ?? false)
            && this.props.categoryManagerInitiator !== this.props.data.categoryManagerUserId
            && (this.props.data.categoryManagerUserId ?? false))
            && (
              <CheckboxInput
                className="col-1 col-md-5"
                label="Include initiator in MTO communications."
                name="IncludeInitiator"
                value={this.props.data.includeInitiatorInCommunications ?? true}
                onChange={(selected) => this.onIncludeInitiatorChange(selected)}
              />
            )
          }
        </div>

        <div className="row mt-4">
          <div className="col-12 col-sm-12">
            <RadioSelector
              disabled={disableFields}
              label="VAT Registered"
              name="isVatRegistered"
              value={this.props.data.isVatRegistered}
              options={[
                { value: 1, display: "yes" },
                { value: 0, display: "no" },
              ]}
              additional={this.props.additional.isVatRegistered}
              onChangeCallback={this.props.onInputChangeCallback}
            />
          </div>
        </div>

        {Number(this.props.data.isVatRegistered) === 1 && (
          <div className="row">
            <div className="col-12 col-sm-12">
              <TextInput
                disabled={disableFields}
                label="VAT Number"
                name="vatNumber"
                value={this.props.data.vatNumber}
                iconName="buildings"
                additional={this.props.additional.vatNumber}
                filters={[filterOutLetters]}
                maxLength={(this.props.data.manualSupplier === true || isForeignCompany) ? 50 : 10}
                validations={(this.props.data.manualSupplier === true || isForeignCompany === true) ? [] : [
                  minLength(10, this.props.setStepValid),
                  maxLength(10, this.props.setStepValid),
                  validateVatNumber(this.props.setStepValid),
                ]}
                onChangeCallback={this.props.onInputChangeCallback}
              />
            </div>
          </div>
        )}
        {
          (this.props.supplierTypes && this.props.supplierTypes.length > 0) && (
            <div className="row mt-4">
              <div className="col-12">
                <DropdownInput
                  label="Supplier Type"
                  name="supplierTypeId"
                  value={this.props.data ? this.props.data.supplierTypeId || -1 : -1}
                  options={this.props.supplierTypes.filter((x) => x.isHiddenFromView !== true)}
                  additional={this.props.data.manualSupplier === true ? "" : this.props.additional.supplierTypeId}
                  onChangeCallback={(ev) => {
                    const newEvent = { ...ev };
                    newEvent.target.value = ev.target.value < 0 ? null : ev.target.value;
                    this.props.onInputChangeCallback(newEvent);
                    this.onSupplierTypeChange();
                  }}
                />
              </div>
            </div>
          )
        }

        {
          (this.props.paymentTerms && this.props.paymentTerms.length > 0) && (
            <div className="row">
              <div className="col-12 col-sm-12">
                <DropdownInput
                  label="Payment Terms"
                  name="paymentTermsId"
                  value={this.props.data ? this.props.data.paymentTermsId || -1 : -1}
                  options={this.props.paymentTerms}
                  additional={this.props.data.manualSupplier === true ? "" : this.props.additional.supplierTypeId}
                  onChangeCallback={(ev) => {
                    const newEvent = { ...ev };
                    newEvent.target.value = ev.target.value < 0 ? null : ev.target.value;
                    this.props.onInputChangeCallback(newEvent);
                    this.onPaymentTermsChange(ev.target.value);
                  }}
                />
              </div>
            </div>
          )
        }

        {
          // eslint-disable-next-line no-bitwise
          ((this.props.allowedCompanyFeatures & this.hasContractingFeature) > 0)
          && (
            <div className="row">
              <div className="col-12 col-sm-12">
                <DropdownInput
                  label="Contracting Tier"
                  name="contractingTierId"
                  value={this.props.data.contractingTierId || -1}
                  options={this.props
                    .contractTiers?.filter(
                      (contractTier) => contractTier.supplierTypeId === this.props.data.supplierTypeId
                    ).map(
                      (contractTier) => ({ ...contractTier, display: `${contractTier.externalContractTierId} - ${contractTier.display}` })
                    ) ?? []}
                  additional={this.props.data.manualSupplier === true ? "" : this.props.additional.contractingTierId}
                  onChangeCallback={(ev) => {
                    const newEvent = { ...ev };
                    newEvent.target.value = ev.target.value < 0 ? null : ev.target.value;
                    this.props.onInputChangeCallback(newEvent);
                    this.onContractTierChange(ev.target.value);
                  }}
                  disabled={
                    this.props.data.supplierTypeId == null
                    || this.props.data.supplierTypeId === -1
                  }
                />
              </div>
              {
                this.props.data.manualSupplier !== true && (
                  <div className="col-12 col-sm-12">
                    <MultiSelectDropDownInput
                      label="Contract Agreements"
                      name="contractingAgreements"
                      value={
                        this.props.data.manualSupplier === true ? []
                          : this.props.data.contractAgreements || []
                      }
                      options={this.state != null && this.state.contractAgreementsOptions
                        ? this.state.contractAgreementsOptions : []}
                      disabled={
                        this.props.data.contractingTierId == null
                        || this.props.data.contractingTierId === -1
                      }
                      onChange={(selectedItems) => {
                        this.onContractAgreementsChange(selectedItems);
                      }}
                    />
                  </div>
                )
              }
            </div>
          )
        }
      </section>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);
