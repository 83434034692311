import React, { Component } from "react";
import { ActionsDropDown, ActionsDropDownItem } from "isuppli-react-components";

class KeyContactStaffOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
    };
  }

  toggleOptions = () => {
    this.setState((prevState) => ({
      showOptions: !prevState.showOptions,
    }));
  };

  setContact = (role) => {
    this.props.setRoleCallback(role);
    this.toggleOptions();
  };

  getOptions = () => this.props.contacts.map((contactPerson) => {
    if (
      this.props.role === contactPerson.contactPersonTypeId
        || !contactPerson.fullName
        || contactPerson.fullName.length === 0
        || !contactPerson.contactNumber
        || contactPerson.contactNumber.length === 0
        || !contactPerson.email
        || contactPerson.email.length === 0
    ) {
      return null;
    }
    const type = this.props
      .contactPersonTypes.find((c) => c.id === contactPerson.contactPersonTypeId);

    return (
      <ActionsDropDownItem
        key={type.id}
        className="clickable"
        onClick={() => this.setContact(contactPerson)}
      >
        {
          type.name
        }
      </ActionsDropDownItem>
    );
  });

  render() {
    const options = this.getOptions();
    return (
      <ActionsDropDown
        disabled={options.every((option) => option == null)}
        label="Same as"
      >
        {options}
      </ActionsDropDown>
    );
  }
}

export default KeyContactStaffOptions;
