import React, { Component } from "react";

// import FileUploader from '../../Controls/FileUploader';

class TermsAndConditionsUploader extends Component {
  constructor(props) {
    super(props);
    this.termsAndConditions = ["Consignment agreement"];
  }

  render() {
    const rows = [];
    this.termsAndConditions.forEach((tnc, i) => {
      rows.push(
        <tr key={i}>
          <td>{tnc}</td>
          <td>Actions view, delete</td>
        </tr>
      );
    });
    rows.push(
      <tr key={this.termsAndConditions.length}>
        <td>
          <span className="text-primary font-underline clickable">
            + Add New Terms & Conditions
          </span>
        </td>
      </tr>
    );
    return (
      <section>
        <table className="isuppli-table">
          <thead>
            <tr>
              <th>Terms & Conditions</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </section>
    );
  }
}

export default TermsAndConditionsUploader;
