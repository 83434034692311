import React, { useEffect } from "react";

import {
  PageHeading, hasFeature, SystemFeatures, useToast,
  UtilButton, Line,
} from "isuppli-react-components";
import { useSelector, useDispatch } from "react-redux";
import NavTabs from "./NavTabs";
import Loading from "../Loading";

import navMenuItems from "../../Util/menuItems";

// eslint-disable-next-line no-unused-vars
import { ReduxState } from "../../Store/ReduxState";
import useStaticData from "../../Hooks/useStaticData";
import { StaticDataTypes } from "../../Util/Enumerators/staticDataTypes";
import { getDocumentsAsync, saveDocumentsInfo } from "../../http";
import { setRegistrationObject } from "../../Store/registrationActions";
import {
  setLastStepCompleted,
  showLoadingScreen,
  hideLoadingScreen,
  hideInfoPopup,
  showErrorDialog,
  showInfoPopup,
  setCompanyOutstandingInfo,
} from "../../Store/actions";
import DocumentUploader from "../../Containers/Supplier/DocumentUploader";
import InfoPopup from "../../Containers/InfoPopup";

const Agreements = () => {
  const [[documentTypes], isReady] = useStaticData([StaticDataTypes.DocumentTypes]);

  const dispatch = useDispatch();
  const showToast = useToast();
  const data = useSelector((state: any) => (
    {
      ...state.registration.beeInfo,
      documents: state.registration.documents,
      companyId: state.currentCompanyId,
    }));

  const companyOutstandingInfo = useSelector((state: any) => (state.companyOutstandingInfo));

  useEffect(() => {
    const loader = async () => {
      const res = await getDocumentsAsync(data.companyId, true);
      dispatch(setRegistrationObject("documents", res.companyDocuments));
      dispatch(setLastStepCompleted(res.registrationStepCompleted));
    };

    loader();
  }, [data.companyId, dispatch]);

  const setDocuments = (newDocuments: any) => {
    dispatch(setRegistrationObject("documents", newDocuments));
  };

  const allowedFeatures = useSelector(
    (state: ReduxState) => state.loggedInUserDetails.allowedFeatures
  );
  const canEdit = hasFeature(
    allowedFeatures,
    SystemFeatures.CompanyProfileEdit
  );

  const onClickSave = () => {
    // check if all documents were added
    let allDocumentsAdded = true;

    for (let i = 0; i < data.documents.length; i += 1) {
      if (!data.documents[i].filename && !data.documents[i].optional) {
        allDocumentsAdded = false;
      }
    }

    if (allDocumentsAdded) {
      dispatch(showLoadingScreen());

      const documentSummary = {
        companyId: data.companyId,
        companyDocuments: data.documents,
        registrationStepCompleted: data.registrationStepCompleted,
      };
      saveDocumentsInfo(
        documentSummary,
        () => {
          dispatch(setCompanyOutstandingInfo({
            ...companyOutstandingInfo,
            supportingDocuments: false,
          }));
          dispatch(hideLoadingScreen());
          dispatch(hideInfoPopup());
          showToast("Supporting documents saved");
        },
        () => {
          dispatch(hideLoadingScreen());
          dispatch(showErrorDialog(true));
        }
      );
    } else {
      const messages = [];
      if (!allDocumentsAdded) {
        messages.push("Please ensure all required documents are uploaded");
      }

      dispatch(showInfoPopup(
        "There are invalid fields on this form",
        "please correct them accordingly",
        messages
      ));
      window.scrollTo({ top: 0 });
    }
  };

  return (
    <section id="isuppli-supplier-agreements">
      <Loading />
      <InfoPopup />
      <div className="padding-lr-10vw" style={{ paddingTop: "20px" }}>
        <PageHeading
          heading="Summary of your business information"
          alertText="It is important to keep your information up to date. Not updating your information may result in your business being removed from the portal."
          menuItems={navMenuItems}
        />

        <NavTabs />
        <div style={{ paddingTop: "49px" }} />
        <DocumentUploader
          documentTypes={isReady ? documentTypes : []}
          uploadUrl="api/documents/"
          downloadUrl="api/documents/"
          documents={data.documents}
          onDocumentsChanges={(newDocuments: any) => setDocuments(newDocuments)}
          readOnly={!canEdit}
        />
      </div>
      <div
        className="padding-lr-5vw"
        style={{ paddingBottom: "76px", marginTop: "60px" }}
      >
        <div className="justify-end row">
          <Line color="secondary" />
          {canEdit
            && (
              <UtilButton
                color="primary"
                location="bottom"
                onClick={onClickSave}
              >
                SAVE
              </UtilButton>
            )}
        </div>
      </div>
    </section>
  );
};

export default Agreements;
