import React, { useState } from "react";

import { NavLink } from "react-router-dom";
import UserMaintenance from "../UserMaintenance/UserMaintenance";

import Styles from "./HeaderNavBar.module.scss";
import { ReactComponent as SvgTriangle } from "../../../images/triangle.svg";

// eslint-disable-next-line no-unused-vars
import SystemFeatures, { hasSomeFeature } from "../../../utils/enums/SystemFeatures";
// eslint-disable-next-line no-unused-vars
import { MenuItem } from "../../../utils/interfaces/MenuItem";
import { ReactComponent as ExternalLinkSvg } from "./external-link.svg";
import { useShowNotAllowedError } from "../../../Hooks/useNotAllowedError";

const HeaderNavBar = (
  {
    setOpenSubMenu,
    openSubMenu,
    allowedFeatures,
    menuItems,
    onLogout,
    userGreeting,
    companyName,
    userBadge,
    isAuthenticated,
    helpLink,
  }
  :
  {
    setOpenSubMenu: (subMenu: string) => void,
    openSubMenu: string,
    allowedFeatures: SystemFeatures,
    menuItems: Array<MenuItem>,
    onLogout: () => void,
    userGreeting: string,
    companyName: string,
    userBadge: string | null,
    isAuthenticated: boolean,
    helpLink: string
  }
) => {
  const [, forceReload] = useState<boolean>();
  const showNotAllowedError = useShowNotAllowedError();

  const createChildMenuItem = (parentItem: MenuItem, childItem: MenuItem) => (
    <li key={childItem.title + childItem.to} className="nav-item">
      <NavLink
        className={`${!hasSomeFeature(allowedFeatures, childItem.feature) ? "disabledLink" : ""}`}
        to={`${childItem.redirect ? childItem.to : `${parentItem.to}/${childItem.to}`}`}
        target={childItem.redirect ? "_blank" : undefined}
        onClick={(ev) => {
          if (!hasSomeFeature(allowedFeatures, childItem.feature)) {
            ev.preventDefault();
            showNotAllowedError(childItem.feature);
          }
          setOpenSubMenu("");
        }}
      >
        <span className={`${Styles.NavLinkSpan} d-flex align-items-center`}>
          {childItem.title}
          { childItem.redirect
                        && <ExternalLinkSvg />}
        </span>
      </NavLink>
    </li>
  );

  return (
    <ul className="d-flex h-100 w-100 p-0 m-0 align-items-stretch flex-column flex-xl-row">
      {menuItems.filter((c) => !c.isUserMenu && !c.hidden).map((menuItem) => {
        const classNames = ["nav-item", Styles.TopNavItem];
        if (menuItem.children != null) {
          classNames.push("caret");
        }

        return (
          <li key={menuItem.title + menuItem.to} className={classNames.join(" ")}>
            {menuItem.children == null ? (
              <NavLink
                className={`${!hasSomeFeature(allowedFeatures, menuItem.feature) ? "disabled" : ""}`}
                to={menuItem.to}
                target={menuItem.redirect ? "_blank" : undefined}
                activeClassName="active"
                onClick={() => {
                  setOpenSubMenu("");
                  forceReload(true);
                }}
              >
                {menuItem.title}
              </NavLink>
            ) : (
              <NavLink
                to={menuItem.to}
                target={menuItem.redirect ? "_blank" : undefined}
                className={`${!hasSomeFeature(allowedFeatures, menuItem.feature) ? "disabled" : ""}`}
                activeClassName="active"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenSubMenu(menuItem.to);
                }}
              >
                <span>
                  {menuItem.title}
                  <SvgTriangle className="ml-1" />
                </span>
              </NavLink>
            )}
            {menuItem.to !== openSubMenu ? null : (
              <ul className={`${Styles.SubMenu} p-0 d-flex flex-wrap flex-xl-nowrap`}>
                {menuItem.children != null
                  && menuItem.subMenuOffset != null
                  && menuItem.subMenuOffset > 0
                  ? (
                    <div className={Styles.LeftSubMenuItems}>
                      {menuItem.children
                        ?.filter((_, i) => i < (menuItem.subMenuOffset ?? 0))
                        ?.map((childItem) => createChildMenuItem(menuItem, childItem))}
                    </div>
                  )
                  : null }

                {menuItem.children
                  ?.filter((_, i) => i >= (menuItem.subMenuOffset ?? 0))
                  ?.map((childItem) => (createChildMenuItem(menuItem, childItem)))}
              </ul>
            )}
          </li>
        );
      })}
      <li className={`nav-item flex-fill d-flex justify-content-start justify-content-xl-end ${Styles.TopNavItem}`}>
        <NavLink
          to={helpLink}
          activeClassName="active"
          onClick={() => {
            setOpenSubMenu("");
            forceReload(true);
          }}
          target="_blank"
        >
          Help & Support
        </NavLink>
      </li>
      <li className="nav-item mobile-version my-0">
        {isAuthenticated && (
          <UserMaintenance
            setOpenSubMenu={setOpenSubMenu}
            openSubMenu={openSubMenu}
            menuItems={menuItems}
            onLogout={onLogout}
            userGreeting={userGreeting}
            companyName={companyName}
            userBadge={userBadge}
            allowedFeatures={allowedFeatures}
          />
        )}
      </li>
    </ul>
  );
};

export default HeaderNavBar;
