import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  Line, PageHeading, UtilButton, ConfirmationModal,
} from "isuppli-react-components";
import navMenuItems from "../../../Util/menuItems";
import ProductTierSelector from "../../../Containers/ProductTierSelector/ProductTierSelector";

import {
  getSubscriptionDetail,
  getSubscriptionUpgradeFee,
  sendSubscriptionEnquirySupportEmail,
} from "../../../http/index";
import useShowError from "../../../Hooks/useShowError";
import {
  // eslint-disable-next-line no-unused-vars
  ReduxState,
} from "../../../Store/ReduxState";
import Spinner from "../../../Containers/Spinner/Spinner";
import useStaticData from "../../../Hooks/useStaticData";
import { StaticDataTypes } from "../../../Util/Enumerators/staticDataTypes";
// eslint-disable-next-line no-unused-vars
import { SubscriptionDetails } from "../Subscription";
import { formatDate, formatCurrency } from "../../../Util/valueFormatter";
import { updateSubscription, getSubscriptionStatus } from "../../../http/Billing/billingApi";

import SendSubscriptionEnquiryModal from "../SendSubscriptionEnquiryModal/SendSubscriptionEnquiryModal";

// eslint-disable-next-line no-unused-vars
import { BillingSubscriptionStatusMessage } from "../../../http/Billing/Models/Models";
// eslint-disable-next-line no-unused-vars
import CompanyGroupingType from "../../../Util/Enumerators/CompanyGroupingType";

const EditSubscription = () => {
  const [selectedTier, setSelectedTier] = useState(0);
  const [selectedTierName, setSelectedTierName] = useState("");
  const [loading, setLoading] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState<SubscriptionDetails>();
  const [subscriptionStatus, setSubscriptionStatus] = useState<BillingSubscriptionStatusMessage>();
  const [showOnEnquireModal, setshowOnEnquireModal] = useState(false);

  const [
    effectiveCurrentSubscriptionTier,
    setEffectiveCurrentSubscriptionTier,
  ] = useState<number>();
  const [showDowngradeModal, setShowDowngradeModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showNewPurchaseModal, setShowNewPurchaseModal] = useState(false);
  const [showQuotePurchaseModal, setShowQuotePurchaseModal] = useState(false);

  const [upgradeFee, setUpgradeFee] = useState(0);

  const [[
    subscriptionTiers,
  ], staticDataReady] = useStaticData([StaticDataTypes.SubscriptionTier]);

  const currentSubscription = useSelector(
    (state: ReduxState) => (state.loggedInUserDetails.accountSuspended
      ? -1
      : state.loggedInUserDetails.subscriptionTierId)
  );

  const accountSuspended = useSelector(
    (state: ReduxState) => state.loggedInUserDetails.accountSuspended
  );

  const onSubscriptionChangeHandler = (value : number) => {
    setSelectedTier(value);
  };

  const onEnquireHandler = async (selectedTierId: number) => {
    const selectedSubscriptionTier = subscriptionTiers.find(
      (tier) => tier.id === selectedTierId
    );
    setSelectedTierName(selectedSubscriptionTier?.name);
    setshowOnEnquireModal(!showOnEnquireModal);

    // send email
    await sendSubscriptionEnquirySupportEmail(selectedTierId);
  };

  const history = useHistory();

  useEffect(() => {
    if (staticDataReady && subscriptionDetails != null
      && subscriptionDetails.pendingSubscriptionTier != null) {
      const currentTier = subscriptionTiers?.find(
        (c) => c.value === currentSubscription);
      const pendingTier = subscriptionTiers?.find(
        (c) => c.value === subscriptionDetails.pendingSubscriptionTier);

      if (currentTier == null || pendingTier == null) {
        setEffectiveCurrentSubscriptionTier(currentSubscription);
        return;
      }

      if (pendingTier.monthlyPriceInclVAT === 0) {
        // cancel pending
        history.replace("/myCompany/subscription");
        return;
      }

      if (pendingTier.monthlyPriceInclVAT < currentTier.monthlyPriceInclVAT) {
        // downgrade pending
        setEffectiveCurrentSubscriptionTier(subscriptionDetails.pendingSubscriptionTier);
        setSelectedTier(subscriptionDetails.pendingSubscriptionTier);
        return;
      }

      if (pendingTier.monthlyPriceInclVAT > currentTier.monthlyPriceInclVAT) {
        // upgrade pending
        history.replace("/myCompany/subscription");
        return;
      }

      setEffectiveCurrentSubscriptionTier(currentSubscription);
    } else {
      setEffectiveCurrentSubscriptionTier(currentSubscription);
    }
  }, [
    subscriptionDetails,
    subscriptionTiers,
    staticDataReady,
    currentSubscription,
    effectiveCurrentSubscriptionTier,
    history]);

  useEffect(() => {
    // load subscription details
    const loadDetails = async () => {
      setLoading(true);
      const [
        loadedDetails,
        loadedSubscriptionStatus,
      ] = await Promise.all([
        getSubscriptionDetail() as Promise<SubscriptionDetails>,
        getSubscriptionStatus(),
      ]);

      setSubscriptionDetails(loadedDetails);
      setSubscriptionStatus(loadedSubscriptionStatus);
      setLoading(false);
    };

    loadDetails();
  }, [setLoading]);

  useEffect(() => {
    setSelectedTier(currentSubscription ?? 0);
  }, [currentSubscription]);

  const showError = useShowError();

  const onSubmitHandler = async () => {
    setLoading(true);
    try {
      const model = {
        newSubscriptionTierId: selectedTier,
      };
      const response = await updateSubscription(model);
      if (typeof response === "string" && response.trim()) {
        window.location.href = response;
      } else {
        history.goBack();
      }
    } catch (error) {
      showError();
    }
    setLoading(false);
  };

  const showConfirmModal = async () => {
    if (!staticDataReady) {
      return;
    }

    if (effectiveCurrentSubscriptionTier === selectedTier) {
      return;
    }
    // determine if it's a upgrade or downgrade

    const currentTier = subscriptionTiers?.find((c) => c.value === currentSubscription);
    const newTier = subscriptionTiers?.find((c) => c.value === selectedTier);

    if (newTier == null) return;

    if (!accountSuspended) {
      if (currentTier == null) {
        return;
      }

      if (newTier.monthlyPriceInclVAT == null) {
        // upgrading to quoted package
        setShowQuotePurchaseModal(true);
        return;
      }

      if (currentTier.monthlyPriceInclVAT === 0) {
        // new purchase
        setShowNewPurchaseModal(true);
        return;
      }

      if (currentTier.monthlyPriceInclVAT > newTier.monthlyPriceInclVAT) {
        setShowDowngradeModal(true);
        return;
      }
    }

    setLoading(true);
    try {
      const upgradeFeeFromApi = await getSubscriptionUpgradeFee(newTier.value);
      setUpgradeFee(upgradeFeeFromApi);
      setShowUpgradeModal(true);
    } catch (error) {
      showError();
    }
    setLoading(false);
  };

  const newTier = subscriptionTiers?.find((c) => c.value === selectedTier);
  const currentTier = subscriptionTiers?.find(
    (c) => c.value === effectiveCurrentSubscriptionTier);

  const downgradeDate = subscriptionDetails?.nextBillingDate ?? new Date();

  const downgradeMessage = `Your current subscription will remain active until ${formatDate(downgradeDate)}. Thereafter you will be downgraded to a ${newTier?.display} package`;
  const upgradeMessage = subscriptionStatus?.hasActiveAdhocSubscription
    ? `You will be charged a pro-rata fee of ${formatCurrency(upgradeFee)} for this upgrade. We will charge the amount immediately using the payment details you provided previously.`
    : `You will be charged a pro-rata fee of ${formatCurrency(upgradeFee)} for this upgrade. You will be redirected to our payment provider to provide your payment details.`;

  const newSubscriptionMessage = "You are upgrading from a free subscription to a paid subscription. You will be now be redirected to our payment provider's website.";
  const newQuoteMessage = `Thank you for your interest in the ${newTier?.display} package. One of our sales consultants will be in contact soon.`;

  const isForeignCompany = subscriptionDetails?.companyGroupingType === CompanyGroupingType.Foreign
    ?? false;

  const alertText = isForeignCompany
    ? ["We notice that you are a non South African registered company. "
      + "Our global application is in development for release in the near "
      + "future, and you are currently limited to the free B1LINK package.",

    "We will notify you as soon as the option to upgrade your package becomes "
      + "available, and trust that you will still be able to make use of the "
      + "platform until that time. Thank you for your patience and understanding."]

    : "Upgrade or downgrade your subscription at any time.";

  return (
    <div className="container-fluid">
      {showDowngradeModal && (
        <ConfirmationModal
          hasCancel
          size="large"
          heading={`Downgrade from ${currentTier?.display} to ${newTier?.display}`}
          proceedButtonText="Confirm"
          description={downgradeMessage}
          onProceedClick={onSubmitHandler}
          onToggleModal={() => setShowDowngradeModal(false)}
        />
      )}
      {showUpgradeModal && (
        <ConfirmationModal
          hasCancel
          size="large"
          heading={
            accountSuspended
              ? `Reactivate account and subscribe to ${newTier?.display}`
              : `Upgrade from ${currentTier?.display} to ${newTier?.display}`
          }
          proceedButtonText={
            subscriptionStatus?.hasActiveAdhocSubscription
              ? "Confirm & Pay"
              : "Redirect to payment provider"
          }
          description={upgradeMessage}
          onProceedClick={onSubmitHandler}
          onToggleModal={() => setShowUpgradeModal(false)}
        />
      )}
      {showNewPurchaseModal && (
        <ConfirmationModal
          hasCancel
          size="large"
          heading={`New ${newTier?.display} Subscription`}
          proceedButtonText="Redirect to payment provider"
          description={newSubscriptionMessage}
          onProceedClick={onSubmitHandler}
          onToggleModal={() => setShowNewPurchaseModal(false)}
        />
      )}
      {showQuotePurchaseModal && (
        <ConfirmationModal
          hasCancel
          heading="Request for Quote"
          proceedButtonText="Confirm"
          description={newQuoteMessage}
          onProceedClick={onSubmitHandler}
          onToggleModal={() => setShowQuotePurchaseModal(false)}
        />
      )}

      {showOnEnquireModal && (
        <SendSubscriptionEnquiryModal
          Onclose={() => setshowOnEnquireModal(false)}
          selectedTierName={selectedTierName}
        />
      )}

      {loading && <Spinner />}
      <div className="row">
        <div className="col-12 offset-0 col-lg-10 offset-lg-1">
          <PageHeading
            heading="Change Subscription"
            alertText={alertText}
            menuItems={navMenuItems}
            breadcrumbAdditionalItem="Change subscription"
          />
          <ProductTierSelector
            value={selectedTier}
            onChange={onSubscriptionChangeHandler}
            sponsored={subscriptionDetails?.sponsored ?? false}
            sponsorName={subscriptionDetails?.sponsorName ?? ""}
            fundCompliance
            onEnquire={(selectedTierId: number) => onEnquireHandler(selectedTierId)}
            sponsoredSubscriptionTierId={
              subscriptionDetails?.sponsoredSubscriptionTierId ?? 0
            }
            disabledSubscriptionTierIds={
              subscriptionDetails?.disabledSubscriptionTierIds
            }
            recommendedSubscriptionTierId={subscriptionDetails?.recommendedSubscriptionTierId ?? 0}
          />
          <div className="py-4 row">
            <Line color="secondary" />
            <div className="col-12 justify-end">
              <UtilButton
                color="primary"
                location="bottom"
                onClick={showConfirmModal}
              >
                Submit & Make Payment
              </UtilButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSubscription;
