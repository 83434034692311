import React, { Component } from "react";

import ValidationError from "./ValidationError/ValidationError";

class OptionSelector extends Component {
  constructor(props) {
    super(props);
    this.showError = true;
  }

  onOptionClick = (value) => {
    this.showError = false;
    const ev = {
      target: {
        name: this.props.name,
        value,
      },
    };
    this.props.onChangeCallback(ev);
  };

  render() {
    const btns = this.props.options.map((option, i) => {
      const className = option.value === this.props.value
        ? "active-option "
        : "inactive-option ";
      return (
        <div
          key={i}
          className={`${className}inline-block ${this.props.disabled ? "" : "clickable"} text-center`}
          onClick={() => (this.props.disabled ? null : this.onOptionClick(option.value))}
        >
          {!!option.heading && <div>{option.heading}</div>}
          <div>{option.display}</div>
        </div>
      );
    });
    return (
      <section className="user-input">
        <div>
          <label>{this.props.label}</label>
        </div>
        <div>{btns}</div>
        {!!this.props.additional
          && this.props.additional.length > 0
          && this.showError && (
          <ValidationError>
            <label className="validation-label">
              {this.props.additional}
            </label>
          </ValidationError>
        )}
      </section>
    );
  }
}

export default OptionSelector;
