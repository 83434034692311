enum UserStatus {
  None = 0, // eslint-disable-line no-unused-vars
  Active = 1, // eslint-disable-line no-unused-vars
  Deactivated = 2, // eslint-disable-line no-unused-vars
  InvitePending = 3, // eslint-disable-line no-unused-vars
}

export const userStatusToString = (value: UserStatus) => {
  switch (value) {
    case UserStatus.None:
      return "None";
    case UserStatus.Active:
      return "Active";
    case UserStatus.Deactivated:
      return "Deactivated";
    case UserStatus.InvitePending:
      return "Invite Pending";
    default:
      return "Unknown Status";
  }
};

export default UserStatus;
