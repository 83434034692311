/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import UtilButton from "../../Components/UtilButton/UtilButton";
import Styles from "./ListEmptyState.module.scss";
import { ReactComponent as MagnifyingGlassCircleSvg } from "./magnifying-glass-circle.svg";

const ListEmptyState = ({
  heading,
  button,
  children,
  fullWidth = false,
} :
  {
    heading : string,
    children: React.ReactChild | React.ReactChild[],
    button? :{text: string, onClick: () => void},
    fullWidth? : boolean
  }) => {
  const content = (
    <div className={`d-flex justify-content-center flex-column text-center ${fullWidth ? "" : "row"}`}>
      <h1 className="mb-3 mt-5">{heading}</h1>
      <MagnifyingGlassCircleSvg className="text-primary align-self-center" />
      <label className={`mt-3 ${fullWidth ? "" : "offset-0 offset-md-2 offset-lg-3 col-12 col-md-8 col-lg-6"}`}>
        {children}
      </label>
      {button && (
        <div className={`${Styles.ButtonContainer} mx-auto mt-5`}>
          <UtilButton
            location="bottom"
            color="primary"
            onClick={button.onClick}
          >
            {button.text}
          </UtilButton>
        </div>
      )}
    </div>
  );

  return content;
};

/*
disabled = false,
  background,
  size,
  location,
  className = "",
  text,
  actionArg = null,
  actionCallback,
*/

export default ListEmptyState;
