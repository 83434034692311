import React, { Component } from "react";
import { connect } from "react-redux";

import {
  PageHeading, SystemFeatures, hasFeature, withToast,
  UtilButton, Line,
} from "isuppli-react-components";
import { Alert } from "reactstrap";
import NavTabs from "./NavTabs";
import Loading from "../Loading";

import navMenuItems from "../../Util/menuItems";
import BankingDetails from "../../Containers/Supplier/BankingDetails";

import {
  onRegistrationInputChange,
  setRegistrationObject,
} from "../../Store/registrationActions";
import { setOption } from "../../Store/optionActions";

import { loadBankingDetailOptions } from "../../Util/optionsLoader";
import { loadBankingDetailsData } from "../../Util/dataLoader";

import { saveBankingDetails } from "../../http/posts";

import {
  showLoadingScreen,
  hideLoadingScreen,
  hideInfoPopup,
  showInfoPopup,
  showErrorDialog,
  setCompanyOutstandingInfo,
} from "../../Store/actions";

import InfoPopup from "../../Containers/InfoPopup";
import { requiredFieldsStep5, getRequiredBankAccountFields } from "../../Validation/validation";
import validateStep from "../../Validation/onSubmitValidations";
import { getCompanyOutstandingInformation } from "../../http/MyCompany/myCompanyAPI";

const mapStateToProps = (state) => ({
  data: {
    ...state.registration.bankDetails,
    companyId: state.currentCompanyId,
  },
  bankAccountTypes: state.options.bankAccountTypes,
  banks: state.options.banks,
  allowedFeatures: state.loggedInUserDetails.allowedFeatures,
  companyOutstandingInfo: state.companyOutstandingInfo,
});

const mapDispatchToProps = (dispatch) => ({
  onInputChange: (ev) => {
    dispatch(onRegistrationInputChange("bankDetails", ev));
  },
  setBankingDetails: (bankDetails) => {
    dispatch(setRegistrationObject("bankDetails", bankDetails));
  },
  setCompanyOutstandingInfo: (companyOutstandingInfo) => {
    dispatch(setCompanyOutstandingInfo(companyOutstandingInfo));
  },
  setOption: (optionName, optionValue) => {
    dispatch(setOption(optionName, optionValue));
  },
  showLoadingScreen: (msg) => {
    dispatch(showLoadingScreen(msg));
  },
  hideLoadingScreen: () => {
    dispatch(hideLoadingScreen());
  },
  showInfoMsg: (heading, subheading, msgs) => {
    dispatch(showInfoPopup(heading, subheading, msgs));
  },
  hideInfoMsg: () => {
    dispatch(hideInfoPopup());
  },
  showErrorDialog: () => {
    dispatch(showErrorDialog(true));
  },
});

class BankInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msgs: [],
    };
  }

  performSave = async () => {
    this.props.showLoadingScreen("saving data...");
    saveBankingDetails(
      this.props.data,
      async (res) => {
        this.props.setBankingDetails(res);
        const outstandingInfo = await getCompanyOutstandingInformation();
        this.props.setCompanyOutstandingInfo({
          ...outstandingInfo,
          bankInformation: false,
        });
        this.props.hideLoadingScreen();
        this.props.showToast("Banking information saved");
      },
      () => {
        this.props.hideLoadingScreen();
        this.props.showErrorDialog();
      }
    );
  };

  onClickSave = () => {
    const validation = validateStep(this.props.data, requiredFieldsStep5);
    if (validation.isValid) {
      this.props.data.bankAccounts.forEach((account, index) => {
        const requiredFields = getRequiredBankAccountFields(account.useCustomFields
          ?? this.props.data.useCustomFields, index);
        const validate = validateStep(account, requiredFields);
        if (!validate.isValid) {
          const messages = {};
          // make each message unique for the specific bank account
          Object.keys(validate.msgs)
            .forEach((a) => {
              messages[`${a}${index}`] = validate.msgs[a];
            });

          validation.isValid = false;
          validation.msgs = {
            ...validation.msgs,
            ...messages,
          };
        }
      });
    }
    this.setState({
      msgs: validation.msgs,
    });
    if (validation.isValid) {
      this.props.hideInfoMsg();
      this.performSave();
    } else {
      window.scrollTo({ top: 0 });
      this.props.showInfoMsg(
        "This page contains missing information.",
        "To continue please amend/complete the following:",
        Object.keys(validation.msgs).map((key) => validation.msgs[key])
      );
    }
  }

  componentDidMount() {
    loadBankingDetailOptions(this.props);
    loadBankingDetailsData(this.props);
  }

  render() {
    const canEdit = hasFeature(
      this.props.allowedFeatures,
      SystemFeatures.CompanyProfileEdit
    );

    return (
      <section id="isuppli-supplier-bankdetails">
        <Loading />
        <InfoPopup />
        <div className="padding-lr-10vw" style={{ paddingTop: "20px" }}>
          <PageHeading
            heading="Summary of your business information"
            alertText="It is important to keep your information up to date. Not updating your information may result in your business being removed from the portal."
            menuItems={navMenuItems}
          />

          <NavTabs />

          <div style={{ paddingTop: "49px" }} />
          <h2>BANK DETAILS</h2>
          <div className="row" style={{ paddingTop: "36px" }}>
            <div className="col-12 col-lg-9">
              <div>
                <Alert color="info">
                  Your banking information will only be visible to the relevant users
                  within the companies you have been onboarded to via B1LINK. These users
                  cannot edit your information in any way. If you would like further
                  information regarding the security of your banking information,
                  please contact
                  {" "}
                  <u>support@b1link.co.za</u>
                  {" "}
                  or contact 011 455 0033
                </Alert>
              </div>
            </div>
          </div>

          <BankingDetails
            readOnly={!canEdit}
            data={this.props.data}
            additional={this.state.msgs}
            onInputChangeCallback={this.props.onInputChange}
          />
        </div>

        <div
          className="padding-lr-5vw"
          style={{ paddingBottom: "76px", marginTop: "60px" }}
        >
          <div className="justify-end row">
            <Line color="secondary" />
            {canEdit
            && (
              <UtilButton
                color="primary"
                location="bottom"
                onClick={this.onClickSave}
              >
                SAVE
              </UtilButton>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default withToast(connect(mapStateToProps, mapDispatchToProps)(BankInfo));
