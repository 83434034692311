import { MTOMandatoryField } from "isuppli-react-components";

export const requiredFieldsStep1 = [
  { key: "submitterFullName", display: "Submitter full name" },
  { key: "submitterRole", display: "Submitter role" },
  { key: "generalEmail", display: "Supplier email address" },
  { key: "generalPhone", display: "Supplier telephone number" },
  { key: "registeredName", display: "Company registered name" },
  { key: "registrationNumber", display: "Company registration number" },
  { key: "companyType", display: "Company type" },
  { key: "hasVatNumber", display: "VAT registered option" },
];

export const requiredFieldsStep2 = [
  {
    key: "physicalAddress.streetAddressL1",
    display: "Physical address: street address first line",
  },
  { key: "physicalAddress.city", display: "Physical address: city" },
  { key: "physicalAddress.country", display: "Physical address: country" },
  {
    key: "physicalAddress.postalCode",
    display: "Physical address: postal code / zip",
  },

  {
    key: "postalAddress.streetAddressL1",
    display: "Postal address: street address first line",
  },
  { key: "postalAddress.city", display: "Postal address: city" },
  { key: "postalAddress.country", display: "Postal address: country" },
  { key: "postalAddress.postalCode", display: "Postal address: postal code / zip" },
];

export const requiredFieldsStep3 = [
  { key: "hasBeeCertificate", display: "Certification option" },
  { key: "boPercentage", display: "Exact percentage" },
  { key: "bwoPercentage", display: "Exact percentage" },
];

export const requiredFieldsStep4 = [
  { key: "tradeDescription", display: "Trade description" },
];

export const requiredFieldsStep5 = [
  { key: "bankAccounts.0", display: "A bank account" },
];

export function getRequiredBankAccountFields(useCustomFields, index) {
  let fields = [];
  if (useCustomFields) {
    fields.push({ key: "customBank", display: `Account ${index + 1} - Bank name` });
    fields.push({ key: "customBankAccountType", display: `Account ${index + 1} - Account type` });
  } else {
    fields.push({ key: "bankId", display: `Account ${index + 1} - Bank name`, checkPositive: true });
    fields.push({ key: "bankAccountTypeId", display: `Account ${index + 1} - Account type`, checkPositive: true });
  }
  fields = [
    ...fields,
    { key: "accountName", display: `Account ${index + 1} - Account name` },
    { key: "accountNumber", display: `Account ${index + 1} - Account number` },
    { key: "branchCode", display: `Account ${index + 1} - Branch code` },
  ];
  return fields;
}

export const requiredFieldsMTP1 = [
  { key: "registeredName", display: "Registered name" },
  { key: "registrationNumber", display: "Registration number" },
  { key: "businessUnitIds", display: "Business unit" },
  { key: "isVatRegistered", display: "Vat registered option" },
  { key: "generalEmail", display: "Email address" },
  { key: "companyTypeId", display: "Company type" },
];

export const requiredFieldsManualSupplierMTP1 = [
  { key: "registeredName", display: "Registered name" },
];

export const requiredFieldsMTP2 = [
  { key: "paymentTerms", display: "Payment terms selection" },
];

export const requiredFieldsMTP5 = [
  { key: "restrictions", display: "Restrictions" },
];

export const requiredMandatoryFieldsStep1 = [
  { key: "registrationNumber", display: "Company registration number", field: MTOMandatoryField.RegistrationNumber },
  { key: "registeredName", display: "Company registered name", field: MTOMandatoryField.RegisteredCompanyName },
  { key: "generalEmail", display: "Email address", field: MTOMandatoryField.EmailAddress },
  { key: "tradingName", display: "Trading As Name", field: MTOMandatoryField.TradingName },
  { key: "companyTypeId", display: "Company type", field: MTOMandatoryField.CompanyType },
];

export const requiredMandatoryFieldsStep2 = [
  { key: "physicalAddress.streetAddressL1", display: "Physical address: street address first line", field: MTOMandatoryField.PhysicalAddress },
  { key: "physicalAddress.city", display: "Physical address: city", field: MTOMandatoryField.PhysicalAddress },
  { key: "physicalAddress.country", display: "Physical address: country", field: MTOMandatoryField.PhysicalAddress },
  { key: "physicalAddress.postalCode", display: "Physical address: postal code / zip", field: MTOMandatoryField.PhysicalAddress },
  { key: "postalAddress.streetAddressL1", display: "Postal address: street address first line", field: MTOMandatoryField.PostalAddress },
  { key: "postalAddress.city", display: "Postal address: city", field: MTOMandatoryField.PostalAddress },
  { key: "postalAddress.country", display: "Postal address: country", field: MTOMandatoryField.PostalAddress },
  { key: "postalAddress.postalCode", display: "Postal address: postal code / zip", field: MTOMandatoryField.PostalAddress },
];

export const requiredMandatoryFieldsStep5 = [
  { key: "bankAccounts.0", display: "A bank account", field: MTOMandatoryField.Banking },
];

export class Validation {
  constructor(isValid, msg) {
    this.isValid = isValid;
    this.msg = msg;
  }
}

export function trueArray(arr) {
  let isTrue = true;
  for (let i = 0; i < arr.length; i += 1) {
    isTrue = isTrue && !!arr[i];
    if (!isTrue) {
      return isTrue;
    }
  }
  return isTrue;
}

export function stringNullOrEmpty(value) {
  return !value || value.length === 0;
}

export function valueNullOrEmpty(value) {
  switch (typeof value) {
    case "number": {
      return value === undefined || value === null;
    }
    case "string": {
      return stringNullOrEmpty(value);
    }
    case "object": {
      return !value;
    }
    default: {
      break;
    }
  }
  return true;
}

export function isEmailInvalid(email) {
  if (stringNullOrEmpty(email)) {
    return true;
  }
  // eslint-disable-next-line no-useless-escape
  const regex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,13})$/;
  return !regex.test(email);
}

export function validIdNumber(idNo) {
  if (idNo == null) return false;
  // Luhn formula (checksum)
  let tempTotal = 0;
  let checkSum = 0;
  let multiplier = 1;
  for (let i = 0; i < 13; i += 1) {
    tempTotal = parseInt(idNo.charAt(i), 10) * multiplier;
    if (tempTotal > 9) {
      tempTotal = parseInt(tempTotal.toString().charAt(0), 10)
       + parseInt(tempTotal.toString().charAt(1), 10);
    }
    checkSum += tempTotal;
    multiplier = (multiplier % 2 === 0) ? 1 : 2;
  }
  if ((checkSum % 10) !== 0) {
    return false; // not valid
  }

  // Check individual digits
  const regex = /(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)([01]8((( |-)\d{1})|\d{1}))|(\d{4}[01]8\d{1}))$/;
  return regex.test(idNo);
}

export const checkIfValidUUID = (str) => {
  // Regular expression to check if string is a valid UUID
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(str);
};
