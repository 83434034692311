import React from "react";

import { Route } from "react-router-dom";

import RegistrationStep01 from "../Sections/Registration/RegistrationStep01";
import RegistrationStep02 from "../Sections/Registration/RegistrationStep02";
import RegistrationStep03 from "../Sections/Registration/RegistrationStep03";
import RegistrationStepDocuments from "../Sections/Registration/RegistrationStepDocuments/RegistrationStepDocuments";
import RegistrationStep04 from "../Sections/Registration/RegistrationStep04";
import RegistrationStep05 from "../Sections/Registration/RegistrationStep05";
import RegistrationStep06 from "../Sections/Registration/RegistrationStep06";

function RegistrationView() {
  return (
    <section>
      <Route
        path="/registration/supinfo"
        component={() => <RegistrationStep01 />}
      />
      <Route
        path="/registration/coninfo"
        component={() => <RegistrationStep02 />}
      />
      <Route
        path="/registration/beeinfo"
        component={() => <RegistrationStep03 />}
      />
      <Route
        path="/registration/documents"
        component={() => <RegistrationStepDocuments />}
      />
      <Route
        path="/registration/prdinfo"
        component={() => <RegistrationStep04 />}
      />
      <Route
        path="/registration/bnkinfo"
        component={() => <RegistrationStep05 />}
      />
      <Route
        path="/registration/prtinfo"
        component={() => <RegistrationStep06 />}
      />
    </section>
  );
}

export default RegistrationView;
