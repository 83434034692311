import React from "react";
import { BasicTable } from "isuppli-react-components";
// eslint-disable-next-line no-unused-vars
import { CompanyGetBankDetailsMessage } from "../../../http/CompanyDetails/CompanyGetBankDetailsMessage";

import useStaticData from "../../../Hooks/useStaticData";
import { StaticDataTypes } from "../../../Util/Enumerators/staticDataTypes";
import { getStaticValue } from "../../../Util/valueFormatter";

const BankDetailsTable = (
  {
    bankDetails,
    loading,
  }
  :
  {
    bankDetails: CompanyGetBankDetailsMessage,
    loading: boolean,
  }
) => {
  const [[banks, bankAccountTypes], isReady] = useStaticData(
    [StaticDataTypes.Bank, StaticDataTypes.BankAccountType]
  );

  return (
    <BasicTable
      emptyMessage="No bank accounts available"
      loading={!isReady || loading}
      columns={[
        { key: "Name", text: "Account Name", align: "left" },
        { key: "Number", text: "Account Number", align: "left" },
        { key: "Bank", text: "Bank Name", align: "left" },
        { key: "Type", text: "Account Type", align: "left" },
        { key: "BranchName", text: "Branch Name", align: "left" },
        { key: "Branch", text: "Branch Code", align: "left" },
      ]}
      rows={bankDetails?.bankAccounts == null ? [] : bankDetails.bankAccounts.map((bankAccount) => [
        {
          key: `${bankAccount.bankAccountDetailsId}-name`,
          value: bankAccount.accountName,
        },
        {
          key: `${bankAccount.bankAccountDetailsId}-number`,
          value: bankAccount.accountNumber,
        },
        {
          key: `${bankAccount.bankAccountDetailsId}-bank`,
          value: bankDetails.useCustomFields
            ? bankAccount.customBank
            : getStaticValue(bankAccount.bankId, banks),
        },
        {
          key: `${bankAccount.bankAccountDetailsId}-type`,
          value: bankDetails.useCustomFields
            ? bankAccount.customBankAccountType
            : getStaticValue(bankAccount.bankAccountTypeId, bankAccountTypes),
        },
        {
          key: `${bankAccount.branchName}-branchName`,
          value: bankAccount.branchName,
        },
        {
          key: `${bankAccount.bankAccountDetailsId}-branch`,
          value: bankAccount.branchCode,
        },
      ])}
    />
  );
};

export default BankDetailsTable;
