import React from "react";
import { connect } from "react-redux";

import { hideInfoPopup } from "../Store/actions";

const mapStateToProps = (state) => ({
  showInfoPopup: state.showInfoPopup,
  infoPopupHeader: state.infoPopupHeader,
  infoPopupContent: state.infoPopupContent,
  infoPopupSubheading: state.infoPopupSubheading,
});

const mapDispatchToProps = (dispatch) => ({
  hideInfoPopup: () => {
    dispatch(hideInfoPopup());
  },
});

function InfoPopup(props) {
  const msgs = props.infoPopupContent.map((msg, i) => <li key={i}>{msg}</li>);
  return (
    <section>
      {props.showInfoPopup && (
        <div className="info-popup">
          <div className="row">
            <div className="col-8">
              <h2>{props.infoPopupHeader}</h2>
              <h3>{props.infoPopupSubheading}</h3>
            </div>
            <div className="col-4 justify-end">
              <button
                type="button"
                alt="close"
                className="clickable btn btn-link text-decoration-none m-0 p-0"
                onClick={() => props.hideInfoPopup()}
              >
                <h2 className="close-button">✕</h2>
              </button>
            </div>
          </div>
          <ul>{msgs}</ul>
        </div>
      )}
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoPopup);
