export enum overallComplianceStatus {
  None = 0, // eslint-disable-line no-unused-vars
  Outstanding = 1, // eslint-disable-line no-unused-vars
  Failure = 2, // eslint-disable-line no-unused-vars
  Success = 3, // eslint-disable-line no-unused-vars
}

export const overallComplianceStatusToString = (value: overallComplianceStatus) => {
  switch (value) {
    case overallComplianceStatus.None:
      return "None";
    case overallComplianceStatus.Outstanding:
      return "Outstanding";
    case overallComplianceStatus.Failure:
      return "Failure";
    case overallComplianceStatus.Success:
      return "Success";
    default:
      return "Unknown Status";
  }
};
