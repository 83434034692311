import React, { Fragment } from "react";

interface Item {
  heading: string,
  items: string[]
}

const ProductsAndServicesSummary = (
  {
    itemSummary,
  }
  :
  {
    itemSummary: Array<Item>
  }
) => {
  const summary = itemSummary.map((iSum) => (
    <div>
      <div>
        <h3 className="Subheading">{iSum.heading}</h3>
      </div>
      <ul>
        {iSum.items.map((item) => (
          <li>{item}</li>
        ))}
      </ul>
    </div>
  ));
  return (
    <Fragment>
      {summary}
    </Fragment>
  );
};

export default ProductsAndServicesSummary;
