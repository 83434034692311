import { Validation, isEmailInvalid, validIdNumber } from "./validation";

export function validateVatNumber(callback) {
  return (value) => {
    const args = {};
    if (value === "N/A") {
      args.isValid = true;
    } else {
      let aErrorCount = 0;
      let strVat1;
      let strVat2;
      let strVat3;
      let strVat4;
      let strVat5;
      let strVat6;
      let strVat7;
      let strVat8;
      let strVat9;
      let strVat11;
      let strVat12;
      let strVat31;
      let strVat32;
      let strVat51;
      let strVat52;
      let strVat71;
      let strVat72;
      let strVat91;
      let strVat92;
      let strVatTotal;
      let strRnd;

      const strVat = value;

      if (value.length !== 10) {
        aErrorCount = 1;
      }

      strVat1 = strVat.substr(0, 1);
      strVat2 = strVat.substr(1, 1);
      strVat3 = strVat.substr(2, 1);
      strVat4 = strVat.substr(3, 1);
      strVat5 = strVat.substr(4, 1);
      strVat6 = strVat.substr(5, 1);
      strVat7 = strVat.substr(6, 1);
      strVat8 = strVat.substr(7, 1);
      strVat9 = strVat.substr(8, 1);
      const strVat10 = strVat.substr(9, 1);

      if (strVat1 !== "4") {
        aErrorCount = 1;
      }

      strVat1 *= 2;
      strVat3 *= 2;
      strVat5 *= 2;
      strVat7 *= 2;
      strVat9 *= 2;

      strVat1 += "";
      strVat11 = strVat1.substr(0, 1);
      strVat12 = strVat1.substr(1, 1);

      strVat11 *= 1;
      strVat12 *= 1;

      strVat1 = strVat11 + strVat12;

      strVat3 += "";
      strVat31 = strVat3.substr(0, 1);
      strVat32 = strVat3.substr(1, 1);

      strVat31 *= 1;
      strVat32 *= 1;

      strVat3 = strVat31 + strVat32;

      strVat5 += "";
      strVat51 = strVat5.substr(0, 1);
      strVat52 = strVat5.substr(1, 1);

      strVat51 *= 1;
      strVat52 *= 1;

      strVat5 = strVat51 + strVat52;

      strVat7 += "";
      strVat71 = strVat7.substr(0, 1);
      strVat72 = strVat7.substr(1, 1);

      strVat71 *= 1;
      strVat72 *= 1;

      strVat7 = strVat71 + strVat72;

      strVat9 += "";
      strVat91 = strVat9.substr(0, 1);
      strVat92 = strVat9.substr(1, 1);

      strVat91 *= 1;
      strVat92 *= 1;

      strVat9 = strVat91 + strVat92;

      strVat1 *= 1;
      strVat2 *= 1;
      strVat3 *= 1;
      strVat4 *= 1;
      strVat5 *= 1;
      strVat6 *= 1;
      strVat7 *= 1;
      strVat8 *= 1;
      strVat9 *= 1;

      strVatTotal = strVat1
        + strVat2
        + strVat3
        + strVat4
        + strVat5
        + strVat6
        + strVat7
        + strVat8
        + strVat9;

      strVatTotal += "";

      strVatTotal = strVatTotal.substr(1, 1);

      strVatTotal *= 1;

      if (strVatTotal === 0) {
        strRnd = 0;
      } else {
        strRnd = 10;
      }

      strVatTotal = strRnd - strVatTotal;

      if (Number(strVat10) !== strVatTotal) {
        aErrorCount = 1;
      }
      if (aErrorCount === 1) {
        args.isValid = false;
      } else {
        args.isValid = true;
      }
    }

    if (args.isValid) {
      if (callback) {
        callback(true);
      }
      return new Validation(true, "");
    }
    if (callback) {
      callback(false);
    }
    return new Validation(false, "VAT number is not valid");
  };
}

export function filterOutLetters(value) {
  return value.replace(/\D/g, "");
}

export function filterOutNumbers(value) {
  return value.replace(/\d/g, "");
}

export function filterOutSpecialCharactersExceptFullstops(value) {
  return value.replace(/[^\d,.]+/g, "");
}

export function filterOutExtraFullstops(str) {
  return str.replace(/^([^.]*\.)(.*)$/, (a, b, c) => b + c.replace(/\./g, ""));
}

export function formatTelNumber(value) {
  const number = [];
  const v = filterOutLetters(value);
  for (let i = 0; i < v.length; i += 1) {
    if (i > 0 && i % (i <= 6 ? 3 : 5) === 0) {
      number.push(" ");
    }
    number.push(v[i]);
  }
  return number.join("").slice(0, 12);
}

export function formatRegNumber(value) {
  const v = filterOutLetters(value);

  // check if user entered a slash
  let slashDetected = false;
  if (value.slice(-1) === "/") slashDetected = true;

  // before and up till first slash
  const fourNum = v.match(/^(\d{4})$/);

  // if all 4 numbers, and user entered a slash, add slash
  if (fourNum && slashDetected) {
    return `${fourNum[1]}/`;
  }
  const noSlash = v.match(/^(\d{0,4})$/); // if 0-4 numbers, no slash
  if (noSlash) return noSlash[1];

  // after first slash
  const sixNum = v.match(/^(\d{4})(\d{6})$/);

  // if all 6 numbers, and user entered a slash, add slash
  if (sixNum && slashDetected) {
    return `${sixNum[1]}/${sixNum[2]}/`;
  }
  const firstSlash = v.match(/^(\d{4})(\d{0,6})$/); // if 0-6 numbers, no slash
  if (firstSlash) return `${firstSlash[1]}/${firstSlash[2]}`;

  // after second slash
  const secondSlash = v.match(/^(\d{4})(\d{6})(\d{0,2})$/);
  if (secondSlash) return `${secondSlash[1]}/${secondSlash[2]}/${secondSlash[3]}`;

  return v;
}

export function clampNumber(min, max, decimalPlace = 0) {
  return (value) => {
    const internalVal = filterOutExtraFullstops(value.replace(",", "."));

    if (internalVal < min) {
      return min;
    }
    if (internalVal > max) {
      return max;
    }

    const num = parseFloat(internalVal);
    const fixedNum = parseFloat(num.toFixed(decimalPlace));

    if (Number.isNaN(num) || fixedNum === num) {
      return internalVal;
    }
    return fixedNum;
  };
}

export function minLength(minLengthVal, callback) {
  return (value) => {
    if (value.length < minLengthVal) {
      callback(false);
      return new Validation(false, "Minimum characters not reached");
    }
    callback(true);
    return new Validation(true, "");
  };
}

export function maxLength(maxLengthVal, callback) {
  return (value) => {
    if (value.length > maxLengthVal) {
      callback(false);
      return new Validation(false, "Maximum characters exceeded");
    }
    callback(true);
    return new Validation(true, "");
  };
}

export function isValidEmail(callback) {
  return (value) => {
    if (isEmailInvalid(value)) {
      callback(false);
      return new Validation(false, "please enter a valid email");
    }
    callback(true);
    return new Validation(true, "");
  };
}

export function isIdNoValid(callback) {
  return (value) => {
    if (!validIdNumber(value)) {
      callback(false);
      return new Validation(false, "please enter a valid South African ID Number");
    }
    callback(true);
    return new Validation(true, "");
  };
}

export function filterOutSpecialCharacters(value) {
  return value.replace(/[^\w\s]/gi, "");
}

export function filterOutSpecialCharactersAndWhiteSpace(value) {
  return value.replace(/[^\w]/gi, "");
}

export function companyNameFilter(value) {
  return value.replace(/[^\w@&)(-/\s]/gi, "");
}

export function getRegNumberConfig(selectedCompanyTypeName, setStepValid) {
  const regNumValidation = selectedCompanyTypeName === "Private Company"
    || selectedCompanyTypeName === "Public Listed Company"
    || selectedCompanyTypeName === "Close Corporation";

  const idNumValidation = selectedCompanyTypeName === "Sole Proprietary"
    || selectedCompanyTypeName === "Incorporated Joint Venture"
    || selectedCompanyTypeName === "Partnership";

  let regNumberValidationsConfig = [];
  let regNumberFilterConfig = [];
  let regNumberPlaceholder = "";
  let maxLengthValue = 40;
  if (regNumValidation === true) {
    regNumberValidationsConfig = [
      minLength(14, setStepValid),
      maxLength(14, setStepValid),
    ];
    regNumberFilterConfig = [formatRegNumber];
    regNumberPlaceholder = "twelve digit number e.g. 2011/123456/12";
    maxLengthValue = 14;
  } else if (idNumValidation === true) {
    regNumberValidationsConfig = [
      minLength(13, setStepValid),
      maxLength(13, setStepValid),
      isIdNoValid(setStepValid),
    ];
    regNumberFilterConfig = [filterOutLetters];
    regNumberPlaceholder = "South African ID Number";
    maxLengthValue = 13;
  } else {
    regNumberValidationsConfig = [
      maxLength(40, setStepValid),
    ];
    regNumberFilterConfig = [];
    regNumberPlaceholder = "Registration number";
    maxLengthValue = 40;
  }

  return {
    regNumberValidationsConfig,
    regNumberFilterConfig,
    regNumberPlaceholder,
    regNumValidation,
    idNumValidation,
    maxLengthValue,
  };
}

export function onCompanyTypeChangeHandler(
  selectedCompany,
  companyTypes,
  onInputChangeCallback,
  shouldClearRegNumber = true
) {
  const selectedCompanyTypeName = companyTypes.filter(
    (c) => c.id === selectedCompany.target.value
  )[0]?.name;

  const idNumValidationRequired = selectedCompanyTypeName === "Sole Proprietary"
    || selectedCompanyTypeName === "Incorporated Joint Venture"
    || selectedCompanyTypeName === "Partnership";

  onInputChangeCallback({
    target: {
      name: "requiredIdNumValidation",
      value: idNumValidationRequired,
    },
  });

  if (shouldClearRegNumber) {
    // Reset registration number when changing company type
    // This will force the validation checks
    onInputChangeCallback({
      target: {
        name: "registrationNumber",
        value: "",
      },
    });
  }

  // check if vat exempted (foreign company)
  // const vatExempted = selectedCompanyTypeName === "Foreign Entitys";
  const vatExempted = false;

  // reset fields if vat exempted
  if (vatExempted) {
    onInputChangeCallback({
      target: {
        name: "isVatRegistered",
        value: 0,
      },
    });
    onInputChangeCallback({
      target: {
        name: "hasVatNumber",
        value: 0,
      },
    });
    onInputChangeCallback({
      target: {
        name: "vatNumber",
        value: "",
      },
    });
    onInputChangeCallback({
      target: {
        name: "taxClearanceNumber",
        value: "",
      },
    });
  }

  onInputChangeCallback({
    target: {
      name: "vatExempted",
      value: vatExempted,
    },
  });
}

export function onHasBeeCertificateChangeHandler(hasBeeCertificate, onInputChangeCallback) {
  if (hasBeeCertificate === 0) {
    onInputChangeCallback({
      target: {
        name: "beeStatusId",
        value: null,
      },
    });
    onInputChangeCallback({
      target: {
        name: "certificateExpiryDate",
        value: null,
      },
    });
    onInputChangeCallback({
      target: {
        name: "boPercentage",
        value: 0,
      },
    });
    onInputChangeCallback({
      target: {
        name: "bwoPercentage",
        value: 0,
      },
    });
    onInputChangeCallback({
      target: {
        name: "sector",
        value: null,
      },
    });
    onInputChangeCallback({
      target: {
        name: "turnoverBand",
        value: null,
      },
    });
  }
}
