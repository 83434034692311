import { UtilButton } from "isuppli-react-components";
import React, { Fragment } from "react";
import { useHistory } from "react-router";

import { ReactComponent as MagnifyingGlassWhiteSvg } from "./magnifying-glass-white.svg";

const SearchSupplierDatabaseSection = () => {
  const history = useHistory();

  return (
    <Fragment>
      <UtilButton
        color="primary"
        className="rounded w-100"
        onClick={() => { history.push("/marketplace/search"); }}
      >
        <MagnifyingGlassWhiteSvg className="text-white" />
        {" "}
        Search supplier database
      </UtilButton>
    </Fragment>
  );
};

export default SearchSupplierDatabaseSection;
