import React, { Component, Fragment } from "react";
import ValidationError from "../ValidationError/ValidationError";
import { ReactComponent as BuildingsSvg } from "./buildings.svg";
import { ReactComponent as EnvelopeSvg } from "./open-envelope.svg";
import { ReactComponent as DocumentSvg } from "./document.svg";
import { ReactComponent as UserSvg } from "./user-bg-dark.svg";
import { ReactComponent as UserGroupSvg } from "./user-group.svg";
import { ReactComponent as EmailSvg } from "./email.svg";
import { ReactComponent as PlanetSvg } from "./planet.svg";

class TextInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasFocus: false,
      value: "",
      msgs: [],
    };
    this.showError = true;
  }

  applyFilters = (val) => {
    let returnVal = val;
    if (this.props.filters) {
      this.props.filters.forEach((fun) => {
        returnVal = fun(returnVal);
      });
    }
    return returnVal;
  };

  onChange = (ev) => {
    const val = this.applyFilters(ev.target.value);
    const newEv = { ...ev };
    newEv.target.value = val;
    this.props.onChangeCallback(newEv);
    this.setState(
      {
        value: val,
        msgs: [],
      },
      () => {
        if (this.props.validations) {
          const msgs = [];
          this.props.validations.map((fun) => {
            const validation = fun(val);
            if (!validation.isValid) {
              msgs.push(validation.msg);
            }
            return validation.isValid;
          });
          this.setState({
            msgs,
          });
        }
      }
    );
  };

  onFocus = () => {
    this.setState({
      hasFocus: true,
    });
  };

  onBlur = () => {
    if (this.state.hasFocus) {
      this.setState({
        hasFocus: false,
      });
      this.showError = this.state.value.length === 0;
    }
  };

  render() {
    const inputType = this.props.type ? this.props.type : "text";
    const additional = this.state.msgs.map((msg, i) => (
      <ValidationError key={i}>
        <label className="validation-label">{msg}</label>
      </ValidationError>
    ));
    const inputClassName = `full-width${
      this.props.iconName ? " user-input-border" : ""
    }`;

    let image = null;
    switch (this.props.iconName) {
      case "buildings":
        image = <BuildingsSvg className="user-input-img" />;
        break;
      case "open-envelope":
        image = <EnvelopeSvg className="user-input-img" />;
        break;
      case "document":
        image = <DocumentSvg className="user-input-img" />;
        break;
      case "user-bg-dark":
        image = <UserSvg className="user-input-img" />;
        break;
      case "user-group":
        image = <UserGroupSvg className="user-input-img" />;
        break;
      case "email":
        image = <EmailSvg className="user-input-img" />;
        break;
      case "planet":
        image = <PlanetSvg className="user-input-img" />;
        break;
      default:
        break;
    }

    return (
      <section className="user-input bottom-margin-20px">
        {!!this.props.label && (
          <div>
            <label>{this.props.label}</label>
          </div>
        )}
        <div className="round-borders align-center padding-8px">
          {!!this.props.iconName && image}
          <input
            className={inputClassName}
            type={inputType}
            maxLength={this.props.maxLength}
            name={this.props.name}
            value={this.props.value == null ? "" : this.props.value}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            placeholder={this.props.placeholder}
            style={{ paddingLeft: "20px" }}
            disabled={this.props.disabled}
          />
        </div>
        {!!this.props.additional
          && this.props.additional.length > 0
          && !this.state.hasFocus
          && this.showError && (
          <ValidationError>
            <label className="validation-label">
              {this.props.additional}
            </label>
          </ValidationError>
        )}
        {additional.length !== 0 && <Fragment>{additional}</Fragment>}
      </section>
    );
  }
}

export default TextInput;
