enum NotificationTypes {
  General = 0, // eslint-disable-line no-unused-vars
  Messaging = 1, // eslint-disable-line no-unused-vars
  BeeCompliance = 2, // eslint-disable-line no-unused-vars
  Payments = 3, // eslint-disable-line no-unused-vars
  NewOpportunity = 4, // eslint-disable-line no-unused-vars
  Opportunity = 5, // eslint-disable-line no-unused-vars
  SuppierBeeCompliance = 6, // eslint-disable-line no-unused-vars
  SupplierRegulatoryCompliance = 7, // eslint-disable-line no-unused-vars
  RegulatoryCompliance = 8, // eslint-disable-line no-unused-vars
  Account = 9, // eslint-disable-line no-unused-vars
  Onboarding = 10, // eslint-disable-line no-unused-vars
}

export default NotificationTypes;
