import React from "react";

function InfoIcon(props) {
  return (
    <section>
      <img src="/svg/info-icon.svg" alt="" title={props.msg} />
    </section>
  );
}

export default InfoIcon;
