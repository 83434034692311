import { useRef, useCallback, useEffect } from "react";

const useDebounce = <T extends {}>(
  handler: (arg: T) => void | Promise<void>,
  ms: number)
  : [(arg?: T) => void, () => Promise<void>] => {
  const debounceTimeout = useRef<NodeJS.Timeout>();
  const latestArg = useRef<T>();
  const latestHandler = useRef<(arg: T) => void | Promise<void>>(handler);
  useEffect(() => {
    latestHandler.current = handler;
  }, [handler]);
  const forceFunction = useCallback(async () => {
    if (debounceTimeout.current != null) {
      clearTimeout(debounceTimeout.current);
      const promise = latestHandler.current(latestArg.current as T);
      if (promise != null) {
        await promise;
      }
    }
  }, []);

  const bouncer = useCallback(() => {
    if (debounceTimeout.current != null) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      latestHandler.current(latestArg.current as T);
    },
    ms);
  }, [ms]);

  const returnHandler = useCallback((arg?: T) => {
    latestArg.current = arg;
    bouncer();
  }, [bouncer]);
  return [returnHandler, forceFunction];
};

export default useDebounce;
