/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import {
  Form, ModalPopup, FormRef, ButtonInfo, Spinner, SystemFeatures, hasFeature, DropDownInput, // eslint-disable-line no-unused-vars
} from "isuppli-react-components";
import React, { useEffect, useRef, useState } from "react";
import useShowError from "../../Hooks/useShowError";
import useStaticCompanyData from "../../Hooks/useStaticCompanyData";
import { delegateOnboardingRequestApproval } from "../../http/Onboarding/OnboardingApi";
import { StaticCompanyDataTypes } from "../../Util/Enumerators/staticCompanyDataTypes";
import { checkIfValidUUID } from "../../Validation/validation";

interface RoleMessage {
  display: string,
  id: string,
  value: string,
  allowedFeatures: bigint,
}

const DelegateMTOApprovalModal = (
  {
    onboardingRequestId,
    roleId,
    onSubmitCallback,
    onCloseCallback,
  }:
    {
      onboardingRequestId: number,
      roleId: string,
      onSubmitCallback?: () => void,
      onCloseCallback: () => void
    }
) => {
  const formRef = useRef<FormRef>(null);
  const [[roles], isCompanyStaticDataReady] = useStaticCompanyData(
    [StaticCompanyDataTypes.roles]
  );
  const [selectedUserRole, setSelectedUserRole] = useState<string>();
  const showError = useShowError();

  useEffect(() => {
    if (checkIfValidUUID(roleId)) {
      setSelectedUserRole(roleId);
    }
  }, [onboardingRequestId, roleId]);

  const [rolesThatCanApproveOnDelegation, setRolesThatCanApproveOnDelegation] = useState<Array<RoleMessage>>([]);
  useEffect(() => {
    setRolesThatCanApproveOnDelegation(
      roles?.map((role: any) => ({
        ...role, display: role.name, value: role.id, allowedFeatures: BigInt(role.allowedFeaturesString ?? SystemFeatures.None),
      }))
      .filter((r) => hasFeature(r.allowedFeatures, SystemFeatures.ApproveDelegatedOnboardingRequestApproval2))
    );
  }, [isCompanyStaticDataReady, roles]);

  const saveData = async () => {
    if (!(formRef.current?.isValid() ?? false)) {
      formRef.current?.showValidation();
    } else {
      try {
        if (onSubmitCallback != null) {
          await delegateOnboardingRequestApproval({ onboardingRequestId, roleId: selectedUserRole ?? "" });
          onSubmitCallback();
          onCloseCallback();
        }
      } catch (ex) {
        showError();
      }
    }
  };
  const buttons = [
    {
      key: "Submit",
      label: "Delegate Approval",
      onClick: saveData,
      color: "primary",
    },
  ] as ButtonInfo[];

  return (
    <ModalPopup
      onClose={onCloseCallback}
      buttons={buttons}
      size="extra-large"
      heading="Delegate onboarding request approval"
      subheading="Upon submission of this MTO approval delegation form, the users with the selected role will be granted approval rights and notified of this.
You cannot reverse or modify this action once it has been submitted. In the event that the MTO is delegated to another role, you still have the option of approving it"
    >
      <Form ref={formRef} className="mx-1 my-2 p-2" key="DelegateMTOApprovalModalForm">
        <div className="col-12">
          {
            isCompanyStaticDataReady ? (
              <DropDownInput
                label="Delegate to user role:"
                options={rolesThatCanApproveOnDelegation ?? []}
                value={selectedUserRole}
                required
                validationMessage="You have to select a user role to delegate the approval rights to."
                onChange={(val) => setSelectedUserRole(val)}
              />
            )
              : <Spinner inline />
          }
        </div>
      </Form>
    </ModalPopup>
  );
};

export default DelegateMTOApprovalModal;
