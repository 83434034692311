import React, { useEffect, useRef } from "react";
// eslint-disable-next-line no-unused-vars
import { SystemFeatures } from "../..";
import { useShowNotAllowedError } from "../../Hooks/useNotAllowedError";
import Styles from "./CheckboxInput.module.scss";

function CheckboxInput({
  value,
  label,
  onChange,
  size,
  className,
  disabled = false,
  feature,
}
:
{
  value: boolean,
  label?: string,
  onChange: (value: boolean) => void,
  size?: "small" | "default",
  className?: string,
  disabled?: boolean,
  feature?: SystemFeatures,
  }) {
  const input = useRef<HTMLInputElement>(null);
  const showNotAllowedError = useShowNotAllowedError();
  const onClickHandler = () => {
    if (onChange == null) {
      return;
    }
    onChange(input.current?.checked ?? false);
  };
  let sizeClass = "";
  switch (size) {
    case "small":
      sizeClass = Styles.CheckBoxSizeSmall;
      break;
    default:
      sizeClass = "";
  }

  const id = Math.random().toString(36);

  useEffect(() => {
  }, [value]);

  return (
    <label
      htmlFor={id}
      className={`${Styles.CheckboxContainer} ${sizeClass} ${className} ${disabled ? Styles.DisabledCheckBox : ""}`}
    >
      <input
        id={id}
        type="checkbox"
        onChange={(ev) => {
          if (disabled === false || disabled == null) {
            onClickHandler();
          } else if (feature) {
            ev.preventDefault();
            showNotAllowedError(feature);
          }
        }}
        checked={value}
        ref={input}
      />
      <span className={Styles.Checkmark} />
      {label && <span className={`${Styles.CheckboxLabel}`}>{label}</span>}
    </label>
  );
}

export default CheckboxInput;
