import React, {
  useRef,
  // eslint-disable-next-line no-unused-vars
  ForwardRefRenderFunction,
  useState,
  useImperativeHandle,
  forwardRef,
  Fragment,
} from "react";
import { Form } from "reactstrap";

import ValidationAlert from "./ValidationAlert/ValidationAlert";

export interface FormRef {
  isValid: () => boolean,
  showValidation: () => void,
}

export interface FormProps {
  children: React.ReactNode,
  className?: string,
  openValidationIn?: Element,
}

const ISuppliForm: ForwardRefRenderFunction<FormRef, FormProps> = (
  {
    children,
    className,
    openValidationIn,
  }
  : FormProps, ref
) => {
  const form = useRef<HTMLFormElement>(null);
  const [showValidation, setShowValidation] = useState<boolean>(false);
  const [showValidationAlert, setShowValidationAlert] = useState(false);

  useImperativeHandle(ref, () => ({
    isValid: () => {
      const isValid = form.current?.checkValidity() ?? true;
      if (isValid) {
        setShowValidation(false);
        setShowValidationAlert(false);
      }
      return isValid;
    },
    showValidation: async () => {
      setShowValidation(true);
      setShowValidationAlert(false);
      setTimeout(() => {
        setShowValidationAlert(true);
      }, 0);
    },
  }));

  return (
    <Fragment>
      {
        showValidation
        && (
          <ValidationAlert
            openValidationIn={openValidationIn}
            isOpen={showValidationAlert}
            onToggle={() => setShowValidationAlert(false)}
          />
        )
      }

      <Form
        className={`${showValidation ? "was-validated" : ""} ${className}`}
        innerRef={form}
        noValidate
      >
        {children}
      </Form>
    </Fragment>
  );
};

export default forwardRef<FormRef, FormProps>(ISuppliForm);
