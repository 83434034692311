import React from "react";
import { useHistory } from "react-router";
import Styles from "./DashboardSummaryItem.module.scss";

export interface DashboardSummaryItem {
    key:string;
    title?: string;
    value: number;
    linkUrl?: string;
    redirect?:boolean;
    newTab?:boolean;
}

const DashboardSummaryCard = ({
  heading,
  summaryItems = [],
  className = "col-lg-6",
  borderRight = false,
  borderBottom = false,
}: {
    heading: string,
    summaryItems: DashboardSummaryItem[]
    className ?: string
    borderRight?: boolean,
    borderBottom?:boolean,
}) => {
  const history = useHistory();
  const singleItem = summaryItems.length === 1 && summaryItems[0].title == null;
  const layout = summaryItems.map((item) => {
    if (item.linkUrl != null) {
      return (
        <div className="col" key={item.key}>
          <p>{item.title}</p>
          <div className={singleItem ? "p-2" : ""} />
          <a
            href={item.linkUrl}
            onClick={(ev) => {
              ev.preventDefault();
              if (item.linkUrl !== "" && item.linkUrl != null) {
                if (item.newTab) {
                  window.open(item.linkUrl);
                } else {
                  item.redirect === true
                    ? window.location.href = item.linkUrl
                    : history.push(item.linkUrl ?? "");
                }
              }
            }}
          >
            <h1>
              <span className={`${Styles.DrillDownSummaryItem} ${singleItem ? "" : Styles.SmallerItemFont}`}>
                {item.value ?? "-"}
              </span>
            </h1>
          </a>
        </div>
      );
    }
    return (
      <div className="col" key={item.key}>
        <p>{item.title}</p>
        <div className={singleItem ? "p-2" : ""} />
        <h1>{item.value ?? "-"}</h1>
      </div>
    );
  });
  return (
    <div className={`${className} p-4 ${borderRight ? Styles.SummaryBlock_BorderRight : ""} ${borderBottom ? Styles.SummaryBlock_BorderBottom : ""}`}>
      <div className="row align-items-end m-0">
        <div className="col-lg-12">
          <p className={Styles.SummaryBlockHeading}>{heading}</p>
        </div>
        {layout}
      </div>
    </div>
  );
};

export default DashboardSummaryCard;
