import React, { useRef, useEffect, useCallback } from "react";
import Axios, {
  // eslint-disable-next-line no-unused-vars
  AxiosRequestConfig,
} from "axios";
import {
  // eslint-disable-next-line no-unused-vars
  AddAttachmentMessage,
} from "../../http/Messaging/Models/Models";

export type Attachment = {
  id: number,
  fileId: number | null,
  fileUrl:string,
  filename: string,
  progress: number,
  uploadError?: string
}

const FileInput = (
  {
    getFileSelectHandler,
    uploadHandler,
    onUploadProgress,
    onUploadDone,
    allowMultiple = false,
  } :
  {
    getFileSelectHandler : (triggerHandler: () => void) => void,
    uploadHandler: (
      formData: FormData,
      config: AxiosRequestConfig
    ) => Promise<AddAttachmentMessage>,
    onUploadProgress: (files: Attachment[]) => void,
    onUploadDone: (files: Attachment[]) => void,
    allowMultiple?: boolean,
  }
) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const triggerFileSelect = useCallback(() => {
    const currentInput = fileInput?.current ?? { dispatchEvent: () => {} };
    currentInput.dispatchEvent(new MouseEvent("click"));
  }, [fileInput]);

  useEffect(() => {
    getFileSelectHandler(triggerFileSelect);
  }, [getFileSelectHandler, triggerFileSelect]);

  const fileChangeHandler = async () => {
    try {
      const fileCount = fileInput?.current?.files?.length ?? 0;
      if (fileCount === 0) {
        return;
      }
      const formData = new FormData();
      let uploadProgress = 0;
      let uploadError = "";

      let progress: Attachment[] = [];
      if (allowMultiple) {
        for (let i = 0; i < fileCount; i += 1) {
          const files = fileInput?.current?.files ?? [];
          const file = files[i];

          // check size
          const fileSize = file.size / 1024 / 1024; // convert to MB
          if (fileSize < 10) {
            formData.append(file.name, file);
          } else {
            uploadProgress = -1;
            uploadError = "File size exceeds 10 MB";
          }
          progress.push({
            id: Date.now() + i, fileId: null, filename: file.name, progress: uploadProgress, fileUrl: "", uploadError,
          });
        }
      } else {
        const file = (fileInput?.current?.files ?? [])[0];

        // check size
        const fileSize = file.size / 1024 / 1024; // convert to MB
        if (fileSize < 10) {
          formData.append(file.name, file);
        } else {
          uploadProgress = -1;
          uploadError = "File size exceeds 10 MB";
        }
        progress.push({
          id: Date.now(), fileId: null, filename: file.name, progress: uploadProgress, fileUrl: "", uploadError,
        });
      }

      // clear input to allow file with same name to be uploaded again
      if (fileInput.current != null) {
        fileInput.current.value = "";
      }

      try {
        const result = await uploadHandler(formData, {
          onUploadProgress: (progressEvent) => {
            const progressFraction = (progressEvent.loaded * 1.0) / progressEvent.total;
            progress = progress.map((p) => ({ ...p, progress: progressFraction }));
            onUploadProgress(progress);
          },
        });

        // save file ids
        progress = progress.map((p) => ({
          ...p,
          fileId: result.files.find(
            (f) => f.filename === p.filename
          )?.id ?? null,
          fileUrl: `api/messaging/${result.files.find(
            (f) => f.filename === p.filename
          )?.fileUrl}` ?? null,
          progress: result.files.find(
            (f) => f.filename === p.filename
          )?.success === true ? 1 : -1,
        }));
        onUploadProgress(progress);
        onUploadDone(progress);
      } catch (error) {
        if (!Axios.isCancel(error)) {
          progress = progress.map((p) => ({ ...p, progress: -1 }));
          onUploadProgress(progress);
          onUploadDone(progress);
        }
      }
    } catch (ex) {
      onUploadDone([]);
    }
  };

  return (
    <form className="invisible position-absolute">
      <input type="file" ref={fileInput} onChange={fileChangeHandler} multiple={allowMultiple} />
    </form>
  );
};
export default FileInput;
