import React from "react";
import { BasicTable } from "isuppli-react-components";
// eslint-disable-next-line no-unused-vars

interface CompanySubAccount {
  tradingName: string
}

const SubAccountsTable = (
  {
    subAccounts,
    loading,
  }
    :
    {
      subAccounts: Array<CompanySubAccount>,
      loading: boolean,
    }
) => (
  <BasicTable
    emptyMessage="No sub accounts available"
    loading={loading}
    columns={[
      { key: "Name", text: "Trading Name", align: "left" },
    ]}
    rows={subAccounts?.map((subAccount) => [
      {
        key: `${subAccount.tradingName}-name`,
        value: subAccount.tradingName,
      },
    ])}
  />
);

export default SubAccountsTable;
