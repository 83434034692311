import React from "react";
import SupplierCheckStatus from "../OnboardingSearch/SupplierCheckStatus";
import onboardingFilterOptions from "../../Util/Enumerators/onboardingFilterOptions";

export const sortingValues = {
  newestFirst: 0,
  oldestFirst: 1,
};

export const getTableConfig = (actionOptions) => ({
  columns: [
    {
      key: "registeredName",
      heading: "Registered Name",
      size: "1fr",
      propName: "registeredName",
    },
    {
      key: "dateRequestedDisplay",
      heading: "Date Requested",
      size: "1fr",
      propName: "dateRequestedDisplay",
    },
    {
      key: "onboardingStatusDisplay",
      heading: "Approval Stage",
      size: "1fr",
      propName: "onboardingStatusDisplay",
    },
    {
      key: "businessUnit",
      heading: "Business Unit",
      size: "1fr",
      propName: "businessUnit",
    },
    {
      key: "invitationEmail",
      heading: "Invitation email",
      size: "1fr",
      propName: "generalEmail",
    },
  ],
  footerItems: [
    {
      key: "footer-checks",
      label: <SupplierCheckStatus />,
      propName: "supplierChecks",
    },
    {
      key: "subcategories",
      label: "Product Segments & Families",
      propName: "subcategories",
    },
  ],
  actionOptions,
  sortOptions: {
    selectedSortOption: 0,
    options: [
      {
        display: "Newest first",
        value: sortingValues.newestFirst,
      },
      {
        display: "Oldest first",
        value: sortingValues.oldestFirst,
      },
    ],
  },
  statusFilterOptions: {
    selectedStatusFilterOption: 0,
    options: [
      {
        display: onboardingFilterOptions
          .toString(onboardingFilterOptions.Active),
        value: onboardingFilterOptions.Active,
      },
      {
        display: onboardingFilterOptions
          .toString(onboardingFilterOptions.All),
        value: onboardingFilterOptions.All,
      },
      {
        display: onboardingFilterOptions
          .toString(onboardingFilterOptions.Completed),
        value: onboardingFilterOptions.Completed,
      },
      {
        display: onboardingFilterOptions
          .toString(onboardingFilterOptions.Declined),
        value: onboardingFilterOptions.Declined,
      },
      {
        display: onboardingFilterOptions
          .toString(onboardingFilterOptions.Suspended),
        value: onboardingFilterOptions.Suspended,
      },
      {
        display: onboardingFilterOptions
          .toString(onboardingFilterOptions.New),
        value: onboardingFilterOptions.New,
      },
      {
        display: onboardingFilterOptions
          .toString(onboardingFilterOptions.AwaitingSupplierInvitationAcceptance),
        value: onboardingFilterOptions.AwaitingSupplierInvitationAcceptance,
      },
      {
        display: onboardingFilterOptions
          .toString(onboardingFilterOptions.AwaitingSupplierRegistration),
        value: onboardingFilterOptions.AwaitingSupplierRegistration,
      },
      {
        display: onboardingFilterOptions
          .toString(onboardingFilterOptions.ProfileIncomplete),
        value: onboardingFilterOptions.ProfileIncomplete,
      },
      {
        display: onboardingFilterOptions
          .toString(onboardingFilterOptions.AwaitingComplianceChecks),
        value: onboardingFilterOptions.AwaitingComplianceChecks,
      },
      {
        display: onboardingFilterOptions
          .toString(onboardingFilterOptions.ComplianceFailure),
        value: onboardingFilterOptions.ComplianceFailure,
      },
      {
        display: onboardingFilterOptions
          .toString(onboardingFilterOptions.AwaitingContractApproval),
        value: onboardingFilterOptions.AwaitingContractApproval,
      },
      {
        display: onboardingFilterOptions
          .toString(onboardingFilterOptions.PendingApproval1),
        value: onboardingFilterOptions.PendingApproval1,
      },
      {
        display: onboardingFilterOptions
          .toString(onboardingFilterOptions.PendingApproval2),
        value: onboardingFilterOptions.PendingApproval2,
      },
      {
        display: onboardingFilterOptions
          .toString(onboardingFilterOptions.PendingDelegatedReview),
        value: onboardingFilterOptions.PendingDelegatedReview,
      },
      {
        display: onboardingFilterOptions
          .toString(onboardingFilterOptions.DelegatedApproved),
        value: onboardingFilterOptions.DelegatedApproved,
      },
      {
        display: onboardingFilterOptions
          .toString(onboardingFilterOptions.DelegatedDeclined),
        value: onboardingFilterOptions.DelegatedDeclined,
      },
      {
        display: onboardingFilterOptions
          .toString(onboardingFilterOptions.AwaitingVendorNumber),
        value: onboardingFilterOptions.AwaitingVendorNumber,
      },
    ],
  },
});
