import React, { Component } from "react";

import TableActions from "./TableActions";

class SupportingDocumentsConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supportingDocs: [{ name: "Cash flow statements", isMandatory: true }],
    };
  }

  toggleIsMandatory = (index) => {
    this.setState((prevState) => {
      const newDocument = {
        ...this.state.supportingDocs[index],
        isMandatory: !prevState.supportingDocs[index].isMandatory,
      };
      const newSupportingDocs = prevState.supportingDocs; // .filter((doc, i) => i !== index);
      newSupportingDocs[index] = newDocument;

      return {
        supportingDocs: newSupportingDocs,
      };
    });
  };

  render() {
    const rows = this.state.supportingDocs.map((document, i) => {
      const imageName = document.isMandatory
        ? "/svg/checked.svg"
        : "/svg/unchecked.svg";
      return (
        <tr key={i}>
          <td>{document.name}</td>

          <td className="justify-center">
            <img
              className="clickable"
              src={imageName}
              alt=""
              onClick={() => this.toggleIsMandatory(i)}
            />
          </td>

          <td>
            <TableActions
              onViewCallback="asd"
              onDeleteCallback="asd"
              onUploadCallback="asd"
            />
          </td>
        </tr>
      );
    });
    rows.push(
      <tr key={this.state.supportingDocs.length}>
        <td>
          <span className="text-primary font-underlined clickable">
            + Add New Required Supporting Document
          </span>
        </td>
      </tr>
    );
    return (
      <section>
        <table className="isuppli-table">
          <thead>
            <tr>
              <th>Supporting Document</th>
              <th>Mandatorty</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </section>
    );
  }
}

export default SupportingDocumentsConfig;
