/* eslint-disable keyword-spacing */
import React, {
  useState, useRef, useEffect, Fragment,
} from "react";

import {
  ModalPopup,
  Form,
  TextInput,
  FormRef, // eslint-disable-line no-unused-vars
  MultiSelectDropDownInput,
  Spinner,
  // eslint-disable-next-line no-unused-vars
  ButtonInfo,
} from "isuppli-react-components";

// eslint-disable-next-line no-unused-vars
import { useSelector } from "react-redux";
// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  UserMessage,
  addUser, editUser,
  resendInvite,
} from "../../../../http/UserManagement/userManagementApi";

import useStaticCompanyData, {
  TypedStaticOption, // eslint-disable-line no-unused-vars
} from "../../../../Hooks/useStaticCompanyData";
import { StaticCompanyDataTypes } from "../../../../Util/Enumerators/staticCompanyDataTypes";

import Styles from "./AddUserModal.module.scss";
import useShowError from "../../../../Hooks/useShowError";
import { checkHttpStatus } from "../../../../http/httpHelpers";
// eslint-disable-next-line no-unused-vars
import { ReduxState } from "../../../../Store/ReduxState";

const AddUserModal = (
  {
    onClose,
    onRefresh,
    user,
  }
  :
  {
    onClose: () => void,
    onRefresh: () => void,
    user?: UserMessage,
  }
) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [businessUnits, setBusinessUnits] = useState<number[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>();

  const [duplicateEmail, setDuplicateEmail] = useState(false);
  const currentUserEmailAddress = useSelector(
    (state: ReduxState) => state.loggedInUserDetails.emailAddress
  );

  const [loading, setLoading] = useState(false);
  const showError = useShowError();

  const [[rolesTemp, businessUnitStaticDataTemp], isCompanyStaticDataReady] = useStaticCompanyData(
    [StaticCompanyDataTypes.roles, StaticCompanyDataTypes.businessUnit]
  );

  const businessUnitStaticData = businessUnitStaticDataTemp as Array<TypedStaticOption<number>>;
  const roles = rolesTemp as Array<TypedStaticOption<string>>;

  useEffect(() => {
    setName(user?.fullName ?? "");
    setEmail(user?.email ?? "");

    if(isCompanyStaticDataReady) {
      // filter down to valid business units
      const selectedBusinessUnits = user?.businessUnits ?? [];

      setBusinessUnits(selectedBusinessUnits.filter(
        (b) => businessUnitStaticData.some((c) => c.value === b)
      ));
    }

    setSelectedRoles(user?.roles ?? undefined);
  }, [user, isCompanyStaticDataReady, businessUnitStaticData]);

  const formRef = useRef<FormRef>(null);

  const onSaveHandler = async () => {
    if (!formRef.current?.isValid()) {
      formRef.current?.showValidation();
      return;
    }

    setLoading(true);

    if(user == null) {
      // add
      try {
        await addUser({
          businessUnits,
          email,
          fullName: name,
          roles: selectedRoles ?? [],
        });

        onRefresh();
        onClose();
      } catch (error) {
        if(checkHttpStatus(error, 409)) {
          setDuplicateEmail(true);
          formRef.current?.showValidation();
        } else {
          showError();
        }
      }
    } else{
      try {
        // edit
        await editUser(user.id, {
          businessUnits,
          fullName: name,
          roles: selectedRoles ?? [],
        });

        onRefresh();
        onClose();
      } catch (error) {
        showError();
      }
    }

    setLoading(false);
  };

  const buttons : ButtonInfo[] = [
    {
      key: "Save",
      label: user == null ? "Add" : "Save",
      onClick: onSaveHandler,
      color: "primary",
      disabled: loading,
    },
  ];
  if(user != null && !user.emailConfirmed) {
    buttons.unshift({
      key: "Invite",
      label: "Resend Invite",
      onClick: async () => {
        setLoading(true);
        try {
          await resendInvite(user.id);
        } catch (error) {
          showError();
        }
        setLoading(false);
      },
      color: "primary",
      disabled: loading,
    });
  }
  return (
    <Fragment>
      <ModalPopup
        size="large"
        onClose={onClose}
        hasCancel={!loading}
        heading={user == null ? "Add a new user" : `Edit ${name}'s details`}
        buttons={buttons}
      >
        <Form ref={formRef} className="row px-2">
          <TextInput
            className="col-12 col-xl-6"
            type="text"
            label="Full Names"
            value={name}
            onChange={setName}
            icon="person"
            required
            placeholder="Enter user's name and surname"
            validationMessage="You have to enter the user's name"
          />
          <TextInput
            className="col-12 col-xl-6"
            type="email"
            label="Email Address"
            value={email}
            onChange={setEmail}
            icon="person"
            required
            placeholder="Enter user's email address"
            invalid={duplicateEmail}
            validationMessage={duplicateEmail
              ? "A user with this email address already exist. Please use another email address."
              : "Please enter a valid email address"}
            disabled={user != null}
          />
          {isCompanyStaticDataReady
            ? (
              <Fragment>
                {businessUnitStaticData.length > 0
                  ? (
                    <MultiSelectDropDownInput
                      className="col-12 col-xl-6"
                      dropdownClassName={Styles.MultiSelectDropdown}
                      label="Business Unit"
                      value={businessUnits}
                      onChange={setBusinessUnits}
                      required
                      disabled={!isCompanyStaticDataReady}
                      validationMessage="Please select atleast one business unit"
                      options={isCompanyStaticDataReady ? businessUnitStaticData : []}
                    />
                  )
                  : null}
                <MultiSelectDropDownInput
                  className="col-12 col-xl-6"
                  dropdownClassName={Styles.MultiSelectDropdown}
                  label="User Role"
                  value={selectedRoles ?? []}
                  onChange={setSelectedRoles}
                  required
                  disabled={!isCompanyStaticDataReady || currentUserEmailAddress === user?.email}
                  validationMessage="Please select atleast one user role"
                  options={isCompanyStaticDataReady ? roles : []}
                  hideSelectedItemsList
                />
              </Fragment>
            )
            : <Spinner inline />}
        </Form>

        <div className={Styles.RolesContainer}>
          {selectedRoles
            && selectedRoles.map((role) => (
              <div className="px-2" key={role}>
                <h3>
                  {`${roles.find((c) => c.value === role)?.description} Role Access`}
                </h3>
                <p className={`${Styles.Description} ml-3`}>
                  {roles.find((c) => c.value === role)?.accessDescription}
                </p>
              </div>
            ))}
        </div>

        {loading && <Spinner />}
      </ModalPopup>
    </Fragment>
  );
};

export default AddUserModal;
