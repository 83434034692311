import React from "react";
import Styles from "./StarRatingInput.module.scss";
import { ReactComponent as StarFilledSvg } from "./star-filled.svg";
import { ReactComponent as StarEmptySvg } from "./star-empty.svg";

const StarRatingInput = (
  {
    value,
    totalRatings,
    onChange,
    size,
    readonly,
    showToolTips,
    showTotalRatings,
  }
  :
  {
    value: number,
    totalRatings?: number,
    onChange?: (newVal: number) => void,
    size: "small" | "large",
    readonly?: boolean,
    showToolTips?: boolean,
    showTotalRatings?: boolean,
  }
) => {
  const starSelectedHandler = (starValue: number) => {
    if (onChange == null) {
      return;
    }
    onChange(starValue);
  };

  const isStarFilledArray = Array(5)
    .fill(false)
    .map((_, index) => index < value);

  const starClass = readonly ? "" : "clickable";
  const imgClass = size === "large"
    ? `${Styles.LargeRating}`
    : Styles.SmallRating;
  const toolTipClass = showToolTips
    ? `${Styles.Tooltip}`
    : "";

  const toolTipText = [
    "Consistently fails to meet my expectation",
    "Occasionally fails to meet my expectation",
    "Meets my expectation",
    "Occasionally exceeds my expectation",
    "Consistently exceeds my expectation",
  ];

  return (
    <div className={Styles.StarRatingInput}>
      {isStarFilledArray.map((filled, index) => (
        <button
          type="button"
          key={index}
          className={`${starClass} ${toolTipClass}`}
          disabled={readonly}
          onClick={() => {
            starSelectedHandler(index + 1);
          }}
        >
          {filled
            ? <StarFilledSvg className={`${imgClass} ${Styles.Star}`} />
            : <StarEmptySvg className={`${imgClass} ${Styles.Star}`} />}

          {showToolTips && <span className={Styles.Tooltiptext}>{toolTipText[index]}</span>}
        </button>
      ))}
      {showTotalRatings
          && (
            <p className={Styles.TotalRatings}>
              {`(${totalRatings})`}
            </p>
          )}
    </div>
  );
};

export default StarRatingInput;
