import React from "react";
import Styles from "./Spinner.module.scss";

function Spinner(props: { inline?:boolean }) {
  const spinnerType = props.inline ? Styles.loading_inline : "";
  return (
    <div className={["loading", spinnerType].join(" ")}>Loading &#8230;</div>
  );
}
export default Spinner;
