import React, { Component } from "react";

class TextArea extends Component {
  onInputChange = (ev) => {
    this.props.onChangeCallback(ev);
  };

  render() {
    return (
      <section className="text-area">
        <div>
          <label>{this.props.label}</label>
        </div>
        <textarea
          name={this.props.name}
          value={this.props.value || ""}
          onChange={this.onInputChange}
          placeholder={this.props.placeHolder}
          disabled={this.props.disabled}
        />
        {!!this.props.limit && (
          <div className="justify-end">
            <div className="characters-left">Characters left</div>
            <span className="characters-left orange">
              {" "}
              (
              {this.props.limit - (this.props.value || "").length}
              )
            </span>
          </div>
        )}
      </section>
    );
  }
}

export default TextArea;
