import React from "react";
import { BasicTable } from "isuppli-react-components";
import useStaticData from "../../Hooks/useStaticData";
import { StaticDataTypes } from "../../Util/Enumerators/staticDataTypes";
import { getStaticValue } from "../../Util/valueFormatter";

const DocumentViewer = (props) => {
  const [[documentTypes], isReady] = useStaticData([StaticDataTypes.DocumentTypes]);

  const rows = props.documents.filter((doc) => {
    if (isReady ? (documentTypes.find((element) => doc.documentTypeId === element.id)?.documentClassification === 4
      && !props.displayBanking) : false) {
      return false;
    }
    return doc.filename != null;
  }).map((doc) => {
    const documentName = (doc.displayPrefix ? `${doc.displayPrefix} - ` : "")
      + getStaticValue(doc.documentTypeId, isReady ? documentTypes : []);
    return [
      {
        key: documentName,
        value: documentName,
      },
      {
        key: doc.filename,
        valueTemplate: () => (
          <a
            href={`${props.downloadUrl}${doc.filename}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            View
            {" "}
          </a>
        ),
      },
    ];
  });
  return (
    <BasicTable
      columns={[
        {
          key: "name",
          size: "1fr",
          text: "Document name",
          align: "left",
        },
        {
          key: "actions",
          size: "auto",
          text: "Options",
          align: "left",
        },
      ]}
      rows={rows}
      emptyMessage="No documents available"
    />
  );
};

export default DocumentViewer;
