import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { ModalBackground } from "isuppli-react-components";
import { Alert } from "reactstrap";
import EnterpriseConfigProgress from "./EnterpriseConfigProgress";

import SupportingDocumentsConfig from "../../Containers/EnterpriseConfig/SupportingDocumentsConfig";

import UtilButton from "../../Controls/UtilButton";

class EnterpriseConfigStep04 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCompletionModal: true,
    };
  }

  onNextClick = (arg) => {
    this.props.history.replace(arg);
  };

  render() {
    return (
      <section id="isuppli-enterpriseconfig-step-04">
        <EnterpriseConfigProgress currentStep={4} />

        {this.state.showCompletionModal && (
          <ModalBackground>
            <section className="modal-content-container width-33">
              <div className="padding-50px">
                <div className="padding-lr-20 text-center">
                  <img src="/images/config-complete.png" alt="" width="166px" />

                  <div className="top-margin-20px">&nbsp;</div>

                  <h1 className="text-center">
                    ENTERPRISE CONFIGURATION
                    {" "}
                    <span className="font-weight-bold">COMPLETE!</span>
                  </h1>

                  <div className="top-margin-20px">&nbsp;</div>

                  <h2 className="text-center">You are all set</h2>

                  <div className="top-margin-20px">&nbsp;</div>
                </div>

                <p className="text-center">
                  Thank you for filling in your business details. The
                  information you entered has been sent. Suppliers and buyers
                  will be in touch if and when they become interested in your
                  products or services.
                </p>

                <p className="text-center">
                  You can always come back to your Buyer Profile, to update your
                  information if it changes.
                </p>
              </div>

              <hr className="margin-null" />

              <div className="padding-lr-20">
                <UtilButton
                  text="GO TO DASHBOARD"
                  background="active"
                  actionArg="s"
                  location="bottom"
                  actionCallback={() => this.setState({ showCompletionModal: false })}
                />
              </div>

              <div className="bottom-margin-20px">&nbsp;</div>
            </section>
          </ModalBackground>
        )}

        <div className="padding-lr-10vw">
          <div className="row top-margin-90px">
            <div className="col-12 col-sm-5">
              <div>
                <h3 className="margin-null">STEP 04</h3>
              </div>
              <div>
                <h1>SUPPORTING DOCUMENTS</h1>
              </div>
            </div>

            <div className="col-12 col-sm-7 justify-center flex-column">
              Information blurp about why they need to provide information for
              “supporting documents”.
            </div>
          </div>

          <div className="top-margin-30px">
            <Alert color="info">
              Please fill in the required fileds below. All fields are mandatory
              unless stated otherwise.
            </Alert>
          </div>

          <SupportingDocumentsConfig />

          <hr className="margin-null" />

          <div className="row">
            <div className="col-12 col-sm-5 block-sm justify-start">
              <UtilButton
                text="BACK"
                background="clear"
                actionArg="/mycompany/configuration/tacinfo"
                location="bottom"
                actionCallback={(arg) => this.props.history.replace(arg)}
              />
            </div>

            <div className="col-12 col-sm-7 block-sm justify-end">
              <UtilButton
                text="SAVE AND CONTINUE LATER"
                background="active"
                actionArg="s"
                location="bottom"
                actionCallback={() => {}}
              />

              <div className="left-margin-10px" />

              <UtilButton
                text="COMPLETE"
                background="attention"
                actionArg=""
                location="bottom"
                actionCallback={() => this.setState({ showCompletionModal: true })}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(EnterpriseConfigStep04);
