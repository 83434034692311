import React from "react";
import Styles from "./SearchFilters.module.scss";
import MultiSelectDropDown from "../../MultiSelectDropDown/MultiSelectDropDown";
import DateRangeDropDown from "../../DateRangeDropDown/DateRangeDropDown";
import FilterTypes from "../../../utils/enums/FilterTypes";
import {
  // eslint-disable-next-line no-unused-vars
  FilterInfo,
} from "../Interfaces/FilterInfo";
// eslint-disable-next-line no-unused-vars
import { FilterValue } from "../SearchFiltersContainer/SearchFiltersContainer";

const SearchFilters = ({
  filters = [],
  filterValues = [],
  onChangeCallback,
}: {
  filters?: FilterInfo[],
  filterValues?: FilterValue[],
  onChangeCallback: (newValues: FilterValue[]) => void
}) => {
  const onChangeHandler = (index: number, newValue: FilterValue) => {
    const newValues = [...filterValues];
    newValues[index] = newValue;
    onChangeCallback(newValues);
  };

  const data = filters.map((filter, index) => {
    switch (filter.filterType) {
      case FilterTypes.DateRangeInput:
        return (
          <DateRangeDropDown
            key={index} // eslint-disable-line react/no-array-index-key
            className={`flex-fill ${Styles.ActionsDropdown}`}
            buttonClassName="w-100"
            label={filter.filterName}
            values={filterValues[index] as Date[]}
            onChange={(newValue: Date[]) => onChangeHandler(index, newValue)}
          />
        );
      case FilterTypes.MultiSelectDropDown:
        return (
          <MultiSelectDropDown
            key={index} // eslint-disable-line react/no-array-index-key
            className={`flex-fill ${Styles.ActionsDropdown}`}
            buttonClassName="w-100"
            label={filter.filterName}
            items={filter.options}
            values={filterValues[index] as number[] || []}
            onChange={(newValue: number[]) => onChangeHandler(index, newValue)}
            maxSelectedItems={filter.maxSelectedItems}
            disabled={filter.disabled}
            scrollingMenu
            canSearch={filter.canSearch}
          />
        );
      default:
        return null;
    }
  });

  return <div className={`${Styles.SearchFilters}`}>{data}</div>;
};

export default SearchFilters;
