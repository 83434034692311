import React from "react";
import {
  ModalPopup,
} from "isuppli-react-components";
import SupplierCheckDetails from "../../Supplier/SupplierCheckDetails/SupplierCheckDetails";

const SupplierCheckModal = (
  {
    company,
    onClose,
  }
  :
  {
    company: { id: number, name: string, vendorNumber: string },
    onClose: () => void
  }
) => (
  <ModalPopup
    heading={company.name}
    subheading="Compliance Management"
    onClose={onClose}
    hasCancel
  >
    <div className="row px-2">
      <div className="col-12">
        <SupplierCheckDetails
          companyId={company.id}
          vendorNumber={company.vendorNumber}
        />
      </div>
    </div>
  </ModalPopup>
);
export default SupplierCheckModal;
