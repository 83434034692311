import { ShadowCard, Spinner } from "isuppli-react-components";
import React, { Fragment, useEffect, useState } from "react";
import useShowError from "../../../Hooks/useShowError";
import { getDashboardOnboardingStatusSummary } from "../../../http/Dashboard/dashboardApi";
// eslint-disable-next-line no-unused-vars
import { DashboardSupplierStatusSummary } from "../../../http/Dashboard/Models/Models";
// eslint-disable-next-line no-unused-vars
import DashboardSummaryCard, { DashboardSummaryItem } from "../DashboardSummaryItem/DashboardSummaryItem";

const OnboardingStatusSummary = () => {
  const [onboardingStatusSummaryData,
    setOnboardingStatusSummaryData] = useState<DashboardSupplierStatusSummary>();
  const showError = useShowError();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loader = async () => {
      setLoading(true);
      try {
        // api calls
        const dashboardOnboardingStatusSummaryData = await getDashboardOnboardingStatusSummary();
        setOnboardingStatusSummaryData(dashboardOnboardingStatusSummaryData);
      } catch (error) {
        showError();
      }
      setLoading(false);
    };
    loader();
  }, [showError]);

  const items = onboardingStatusSummaryData?.items?.map<DashboardSummaryItem>((i) => ({
    key: i.name,
    title: i.name,
    value: i.count,
    linkUrl: i.status >= 0 ? `/marketplace/search?f=[[],[],[],[],[],[],null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,${i.status}]&sf=0` : null,
  }) as DashboardSummaryItem) ?? [];

  return (
    <Fragment>
      {/* Suppliers */}
      <div className="row mt-4">
        <div className="col-12">
          <h2>MY SUPPLIERS BY ONBOARDING STATUS</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <ShadowCard>

            {loading ? <Spinner inline /> : (
              <div>
                <DashboardSummaryCard
                  heading=""
                  className="col-lg"
                  summaryItems={items.slice(0, 8)}
                />
                <DashboardSummaryCard
                  heading=""
                  className="col-lg"
                  summaryItems={items.slice(8, 16)}
                />
              </div>
            )}

          </ShadowCard>
        </div>
      </div>
    </Fragment>
  );
};

export default OnboardingStatusSummary;
