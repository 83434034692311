// eslint-disable-next-line no-unused-vars
const filterBeeStatuses = (beeLevels, sectorTurnoverBands, sectorTurnoverBandStatuses,
  selectedSectorId, selectedTurnoverBandId, boPercentage) => {
  // Get sector turnover band
  const sectorTurnoverBand = sectorTurnoverBands
    .find((stb) => stb.sectorId === selectedSectorId
      && stb.turnoverBandId === selectedTurnoverBandId);

  // get all sector turnover band statuses for given id
  const filteredSectorTurnoverBandStatuses = sectorTurnoverBandStatuses
    .filter((stbs) => (stbs.sectorTurnoverBandId === sectorTurnoverBand?.id));

  // find sector turnover band status that matches current BO value
  const sectorTurnoverBandStatus = filteredSectorTurnoverBandStatuses
    .find((stbs) => (boPercentage < stbs.maxBOPercentage
        && boPercentage >= stbs.minBOPercentage));

  // only filter bee levels if there is a rule found
  if (sectorTurnoverBandStatus != null) {
    return beeLevels.filter((beeLevel) => (beeLevel.id === sectorTurnoverBandStatus.beeStatusId));
  }

  return [...beeLevels];
};

export default filterBeeStatuses;
