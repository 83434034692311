import axios from "axios";

import {
  OutstandingInformationMessage, CompanyRatingMessage, // eslint-disable-line no-unused-vars
  CompanyEditRatingMessage, // eslint-disable-line no-unused-vars
  MyCompanyRatingMessage, // eslint-disable-line no-unused-vars
  CompanyTotalRatingMessage, // eslint-disable-line no-unused-vars
} from "./Models/Models";

import { makeRequestAsync } from "../util";

export const getCompanyOutstandingInformation = async () => {
  const url = "/api/company/outstandinginfo";
  const outstandingInfoMessage = makeRequestAsync(
    () => axios.get(url)
  ) as Promise<OutstandingInformationMessage>;
  return outstandingInfoMessage;
};

export const getMyCompanyRating = async () => {
  const url = "/api/company/rating";
  const companyRatingMessage = makeRequestAsync(
    () => axios.get(url)
  ) as Promise<MyCompanyRatingMessage>;
  return companyRatingMessage;
};

export const getCompanyRating = async (companyId: number) => {
  const url = `/api/company/rating/${companyId}`;
  const companyRatingMessage = makeRequestAsync(
    () => axios.get(url)
  ) as Promise<CompanyRatingMessage>;
  return companyRatingMessage;
};

export const saveCompanyRating = async (message: CompanyEditRatingMessage) => {
  const url = "/api/company/rating";
  return makeRequestAsync(
    () => axios.post(url, message)
  ) as Promise<CompanyTotalRatingMessage>;
};

export const modifyCompanyName = async (newName: string) => {
  const url = `/api/company/ModifyCompanyName/${newName}`;
  return makeRequestAsync(
    () => axios.post(url)
  ) as Promise<boolean>;
};
