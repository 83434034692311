/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { makeRequestAsync } from "../util";
import {
  InitiateOpportunityMessage, // eslint-disable-line no-unused-vars
  ExportCSVMessage, // eslint-disable-line no-unused-vars
} from "./Models/Models";

export async function initiateOpportunity(companyIds: Array<number>) {
  const url = "api/opportunities/opportunities/initiate";
  const message: InitiateOpportunityMessage = {
    companyIds,
  };
  return makeRequestAsync(() => axios
    .post(url, message)) as Promise<string>;
}

export async function exportCSV(companyIds: Array<number>) {
  const url = "api/reporting/exportCSV";
  const message = {
    companyIds,
  } as ExportCSVMessage;
  return makeRequestAsync(() => axios
    .post(url, message)) as Promise<string>;
}
