import React, { Component } from "react";
import { connect } from "react-redux";

import {
  PageHeading, SystemFeatures, hasFeature, withToast,
  UtilButton, Line, DataFeatures,
} from "isuppli-react-components";

import GeneralBusinessInformation from "../../Containers/Supplier/GeneralBusinessInformation";

import navMenuItems from "../../Util/menuItems";

import InfoPopup from "../../Containers/InfoPopup";

import { getSupplierInfo, refreshToken, getCompanyDataFeatures } from "../../http/gets";

import { loadSupplierInfoOptions } from "../../Util/optionsLoader";

import validateStep from "../../Validation/onSubmitValidations";
import { requiredFieldsStep1, isEmailInvalid, validIdNumber } from "../../Validation/validation";

import RegistrationCompanyInfo from "../../Containers/Supplier/RegistrationCompanyInfo";
import NavTabs from "./NavTabs";

import {
  setRegistrationObject,
  onRegistrationInputChange,
} from "../../Store/registrationActions";
import { setOption } from "../../Store/optionActions";
import {
  showLoadingScreen,
  hideLoadingScreen,
  showInfoPopup,
  hideInfoPopup,
  setStoreProperty,
  showErrorDialog,
  setCompanyOutstandingInfo,
} from "../../Store/actions";
import { saveSupplierInfo } from "../../http/posts";
import {
  getCurrentCompanyCompliance,
} from "../../http/Compliance/complianceApi";

import Loading from "../Loading";

import { onCompanyTypeChangeHandler } from "../../Validation/onChangeValidations";

import ComplianceStatus from "../../Util/Enumerators/ComplianceStatus";

import {
  getFormattedComplianceDetails,
} from "../../Util/complianceFormatter";
import { getCompanyOutstandingInformation } from "../../http/MyCompany/myCompanyAPI";

const mapStateToProps = (state) => ({
  data: {
    ...state.registration.supplierInfo,
    companyId: state.currentCompanyId,
  },
  isStepValid: state.isValidStep1,
  isVATNumberValid: state.isVATNumberValid,
  companyTypes: state.options.companyTypes,
  allowedFeatures: state.loggedInUserDetails.allowedFeatures,
  companyOutstandingInfo: state.companyOutstandingInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setSupplierInfo: (supplierInfo) => {
    dispatch(setRegistrationObject("supplierInfo", supplierInfo));
  },
  onInputChange: (ev) => {
    dispatch(onRegistrationInputChange("supplierInfo", ev));
  },
  setOption: (optionName, optionValue) => {
    dispatch(setOption(optionName, optionValue));
  },
  showLoadingScreen: (msg) => {
    dispatch(showLoadingScreen(msg));
  },
  hideLoadingScreen: () => {
    dispatch(hideLoadingScreen());
  },
  showInfoMsg: (heading, subheading, msgs) => {
    dispatch(showInfoPopup(heading, subheading, msgs));
  },
  hideInfoMsg: () => {
    dispatch(hideInfoPopup());
  },
  setStepValid: (stepName) => (isValid) => {
    dispatch(setStoreProperty(stepName, isValid));
  },
  showErrorDialog: () => {
    dispatch(showErrorDialog(true));
  },
  updateOutstandingInformation: (outstandingInformation) => {
    dispatch(setCompanyOutstandingInfo(outstandingInformation));
  },
});

class SupplierInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msgs: {},
      complianceStatus: ComplianceStatus.None,
    };
  }

  onClickSave = async () => {
    const companyDataFeatures = await getCompanyDataFeatures();
    let validation = validateStep(this.props.data, requiredFieldsStep1);

    if (this.props.data.vatExempted !== true) {
      // vat number
      if (this.props.data.hasVatNumber != null) {
        if (Number(this.props.data.hasVatNumber) === 1) {
          if (!this.props.data.vatNumber) {
            validation.isValid = false;
            validation.msgs = {
              ...validation.msgs,
              vatNumber: "VAT number is required",
            };
          } else if (!this.props.isVATNumberValid) {
            validation.isValid = false;
            validation.msgs = {
              ...validation.msgs,
              vatNumber: "VAT number is invalid",
            };
          }
        }
      }
    }

    if (hasFeature(companyDataFeatures.dataFeatures, DataFeatures.AdditionalCompanyData)) {
      // royalbafokeng
      const royalbafokeng = this.props.data.additionalCompanyData?.royalBafokengNationalResident ?? null;

      if (royalbafokeng == null) {
        validation.isValid = false;
        validation.msgs = {
          ...validation.msgs,
          royalBafokengNationalResident: "Please indicate whether the company is a resident of the Royal Bafokeng Nation.",
          numberOfEmployees: "Please indicate the Number of employees",
        };
      }
    }

    if (hasFeature(companyDataFeatures.dataFeatures, DataFeatures.BusinessRegions)) {
      const validBusinessRegions = this.props.data.businessRegionProvinceIds?.length > 0;
      if (!validBusinessRegions) {
        validation.isValid = false;
        validation.msgs = {
          ...validation.msgs,
          businessRegions: "Please indicate the Geographical Region(s) for conducting your primary business",
        };
      }
    }

    if (isEmailInvalid(this.props.data.generalEmail)) {
      validation.isValid = false;
      validation.msgs = {
        ...validation.msgs,
        generalEmail: "General business email address is invalid",
      };
    }

    if (this.props.data.requiredIdNumValidation != null
      && this.props.data.requiredIdNumValidation === true
      && !validIdNumber(this.props.data.registrationNumber)) {
      validation.isValid = false;
      validation.msgs = {
        ...validation.msgs,
        registrationNumber: "South African ID Number is invalid",
      };
    }

    if (this.props.data.companyType <= 0) {
      validation = {
        isValid: false,
        msgs: {
          ...validation.msgs,
          companyType: "Company type is required",
        },
      };
    }

    if (hasFeature(companyDataFeatures.dataFeatures, DataFeatures.MandatoryTaxClearancePinNumber)) {
      if (this.props.data.taxClearanceNumber === null) {
        validation.isValid = false;
        validation.msgs = {
          ...validation.msgs,
          taxClearanceNumber: "Tax Clearance Number is required",
        };
      }
      if (this.props.data.taxClearanceNumberExpiryDate === null) {
        validation.isValid = false;
        validation.msgs = {
          ...validation.msgs,
          taxClearanceNumberExpiryDate: "Tax Clearance Expiry Date is required",
        };
      }
    }

    this.setState({
      msgs: validation.msgs,
    });

    if (validation.isValid && this.props.isStepValid) {
      this.props.hideInfoMsg();
      this.props.showLoadingScreen();
      saveSupplierInfo(
        this.props.data,
        async () => {
          const outstandingInfo = await getCompanyOutstandingInformation();
          this.props.updateOutstandingInformation({
            ...outstandingInfo,
            companyInformation: false,
          });

          this.props.showToast("Company information saved");
          await refreshToken();
          this.props.hideLoadingScreen();
        },
        () => {
          this.props.showErrorDialog();
          this.props.hideLoadingScreen();
        }
      );
    } else {
      window.scrollTo({ top: 0 });
      this.props.showInfoMsg(
        "This page contains missing information.",
        "To continue please amend/complete the following:",
        Object.keys(validation.msgs).map((key) => validation.msgs[key])
      );
    }
  };

  async componentDidMount() {
    try {
      await loadSupplierInfoOptions(this.props, this.props.setOption);

      if (this.props.data.companyId !== 0) {
        getSupplierInfo(this.props.data.companyId, (res) => {
          this.props.setSupplierInfo(res);

          // Populate vat exempted
          onCompanyTypeChangeHandler({ target: { value: res.companyType } },
            this.props.companyTypes, this.props.onInputChange, false);
        });
      }

      // load compliance status after supplier info so if an error occurs
      // the supplier data is still loaded
      const hasCompliance = hasFeature(
        this.props.allowedFeatures,
        SystemFeatures.OngoingCompliance
      );

      if (hasCompliance) {
        const result = await getCurrentCompanyCompliance();

        const formattedDetails = getFormattedComplianceDetails(result, "");
        this.setState({ complianceStatus: formattedDetails.status });
      }
    } catch (error) {
      this.props.showErrorDialog();
    }
  }

  render() {
    const canEdit = hasFeature(
      this.props.allowedFeatures,
      SystemFeatures.CompanyProfileEdit
    );

    return (
      <section id="isuppli-supplier-info" style={{ paddingTop: "20px" }}>
        <InfoPopup />
        <Loading />

        <div className="padding-lr-10vw">

          <PageHeading
            heading="Company Profile"
            alertText="Please keep your company information up to date to improve your opportunities.
              The term 'Company' is used generically in referencing any entity on the platform, whether a registered company or an individual/sole trader."
            menuItems={navMenuItems}
          />

          <NavTabs />

          <GeneralBusinessInformation
            readOnly={!canEdit}
            data={this.props.data}
            additional={this.state.msgs}
            onInputChangeCallback={this.props.onInputChange}
            setStepValid={this.props.setStepValid("isValidStep1")}
          />
        </div>

        <div
          className="padding-lr-5vw"
          style={{ marginTop: "36px", marginBottom: "36px" }}
        >
          <hr />
        </div>
        <div className="padding-lr-10vw">
          <RegistrationCompanyInfo
            readOnly={!canEdit}
            validationStatus={this.state.complianceStatus}
            data={this.props.data}
            companyTypes={this.props.companyTypes}
            additional={this.state.msgs}
            onInputChangeCallback={this.props.onInputChange}
            setStepValid={this.props.setStepValid("isVATNumberValid")}
            disableEditRegistrationName={this.props.data.disableEditRegistrationName}
            regNumberReadOnly={this.props.data.disableEditRegistrationInfo}
          />
        </div>

        <div
          className="padding-lr-5vw"
          style={{ paddingBottom: "76px", marginTop: "60px" }}
        >
          <div className="justify-end row">
            <Line color="secondary" />

            {canEdit
            && (
              <UtilButton
                color="primary"
                location="bottom"
                onClick={this.onClickSave}
              >
                SAVE
              </UtilButton>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default withToast(connect(mapStateToProps, mapDispatchToProps)(SupplierInfo));
