import React, { Fragment } from "react";
import { Badge } from "reactstrap";
import BeeValidationStatus, { getBeeValidationStatusBadgeColor, beeValidationStatusToString } from "../../Util/Enumerators/beeValidationStatus";

const BeeStatusBadge = (
  {
    size = "large",
    hasBeeCertificate,
    validationStatus,
  }:{
    size?: "small"|"large",
    hasBeeCertificate?: boolean,
    validationStatus?: BeeValidationStatus,
  }
) => {
  const badgeContent = (
    <div>
      B-BBEE
      {validationStatus != null
        && validationStatus !== BeeValidationStatus.None
        && hasBeeCertificate
        ? (
          <Badge
            className="ml-2"
            color={getBeeValidationStatusBadgeColor(validationStatus)}
          >
            {beeValidationStatusToString(validationStatus)}
          </Badge>
        )
        : (
          <Badge
            className="ml-2"
            color="secondary"
          >
            n/a
          </Badge>
        )}
    </div>
  );
  return (
    <Fragment>
      {
        size === "small" ? (<h3>{badgeContent}</h3>) : (<h2>{badgeContent}</h2>)
      }
    </Fragment>
  );
};

export default BeeStatusBadge;
